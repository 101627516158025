import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import DealDetailIcon from 'src/components/img/svg/DealIconDetail';
import {DealDescriberData} from 'src/components/screens/inbox/descriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import {Platform, View} from 'react-native';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {Dimensions} from 'react-native';

const width = Dimensions.get('window').width;

export function dealDescDescriptor(props: DealDescriberData): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      style={props.styles.legalese}
      accessibilityLabel={props.deal.legalese}
    >
      {props.deal.legalese}
    </AVText>
  );
}
export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    expiration: {
      fontSize: Styles.Fonts.f0,
      marginBottom: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m2,
      textAlign: 'center',
    },
    content: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    topRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m3,
    },
  };
}

export function titleDescriptor(props?: DealDescriberData): string {
  return props.deal.promotionText;
}

export function dealIconDescriptor(size): JSX.Element {
  return (
    <DealDetailIcon
      width={size}
      height={size}
      bgColor={Styles.darkGreenRevolve}
    />
  );
}

export function dealDefaultIconDescriptor(): JSX.Element {
  return dealIconDescriptor(Styles.Heights.h4);
}

export function dealAppliedBtn() {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Labels.applied}
      color={'#707070'}
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      textStyle={getDealDetailStyleDescriptor().appliedButtonText}
      onPress={() => {
        return false;
      }}
    />
  );
}

export function dealApplyBtn(applyDeal: () => void) {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Solid}
      onPress={applyDeal}
      buttonViewStyle={{
        width: 92,
      }}
      textStyle={{fontSize: Styles.Fonts.f1}}
      buttonContainerStyle={{alignSelf: 'flex-end'}}
      titleText={Localized.Buttons.apply}
    />
  );
}

export function dealAppliedContainer() {
  return (
    <View style={getDealDetailStyleDescriptor()['alreadyClaimedOffer']}>
      {dealIconDescriptor(Styles.Heights.h2)}
      <AVText style={getDealDetailStyleDescriptor()['alreadyClaimedOfferText']}>
        {Localized.Labels.offer_already_claimed}
      </AVText>
    </View>
  );
}

export function dealValidityKey() {
  return Localized.Labels.ends_on;
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor:
      Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
  };
}

export function getDealEndDateTime(dealEndDateTime) {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      accessibilityLabel={dealEndDateTime}
      style={getDealDetailStyleDescriptor()['expiration']}
    >
      {dealEndDateTime}
    </AVText>
  );
}

export function getDealDetailStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topView: {
      marginTop: Styles.Spacing.m3,
    },
    image: {
      width: width / 2.4,
      height: width / 2.4,
      borderRadius: Styles.Spacing.m2,
    },
    iconView: {
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    dealTitleRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    redeemLimit: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      alignSelf: 'center',
      color: Styles.Colors.PayNew.darkHuesBase04,
    },
    validUntil: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      alignSelf: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    alreadyClaimedOffer: {
      marginTop: Styles.Spacing.m3,
      padding: Styles.Spacing.m3,
      flex: 1,
      flexDirection: 'row',
      borderRadius: Styles.Spacing.m2,
      backgroundColor: '#006DFF26',
    },
    alreadyClaimedOfferText: {
      fontSize: Styles.Fonts.f8,
      alignSelf: 'center',
      flex: 1,
      marginLeft: Styles.Spacing.m2,
      textAlignVertical: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    description: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    expiration: {
      fontSize: Styles.Fonts.f8,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    name: {
      color: Styles.black,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      flex: 1,
      fontSize: 20,
      position: 'absolute',
      justifyContent: 'center',
    },
    appliedButton: {
      alignItems: 'center',
      alignSelf: 'center',
      padding: Styles.Heights.h1,
      paddingHorizontal: 8,
      paddingVertical: 8,
      justifyContent: 'center',
    },
    appliedButtonText: {
      color: Styles.white,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      textAlign: 'center',
      marginLeft: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    content: {
      paddingVertical: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      flex: 1,
      backgroundColor:
        Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
    },
  };
}

export function dealAppliedBtnIcon() {
  return null;
}

export default {
  dealDescDescriptor,
  getStyleDescriptor,
  titleDescriptor,
  dealIconDescriptor,
  dealApplyBtn,
  dealValidityKey,
  dealAppliedBtn,
  dealAppliedBtnIcon,
  dealAppliedContainer,
  getDealDetailStyleDescriptor,
  backHeaderStyleDescriptor,
  getDealEndDateTime,
  dealDefaultIconDescriptor,
};
