import {StyleSheet, View, Platform} from 'react-native';
import LightBulb from 'src/components/img/svg/LightBulb';
import Styles from 'src/components/Styles';
import {
  OfferStylesDescriptor,
  OfferDetailsArrowGifProps,
} from 'src/components/elements/offers/descriptor/DescriptorType';
import Video from 'react-native-video';

const background = require('../../../img/offerarrow.mp4');

export function offerNoteIcon(): JSX.Element {
  return <LightBulb />;
}

export function offerStyles(): OfferStylesDescriptor {
  return StyleSheet.create({
    offerNoteContainer: {
      width: '100%',
      minHeight: 57,
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderWidth: 1,
      borderRadius: 4,
      borderColor: '#2C6CD3',
      backgroundColor: 'rgba(44, 108, 211, 0.15)',
      marginVertical: Styles.Spacing.m3,
    },
    noteTextStyle: {
      fontWeight: '400',
      fontSize: 14,
      paddingRight: Styles.Heights.h2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    noteStyle: {
      fontWeight: '700',
      fontSize: 14,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    offerExpiry: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f8,
      fontWeight: '600',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      minHeight: 17,
    },
    appliedIcon: {marginTop: 0},
    offerTitleinModal: {
      left: 1,
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    offersWithImageText: {right: 10},
    validityText: {right: 10, bottom: -5},
    offersWithImageView: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: 10,
      paddingLeft: 10,
    },
    backgroundVideo: {
      position: 'absolute',
      alignItems: 'center',
      justifyContent: 'center',
      left: -5,
      right: -5,
    },
  });
}

export function getTextColor(): string {
  return Styles.darkColor;
}
export function primaryColor(): string {
  return Styles.Colors.Revolve.navy;
}
export function deafultIconInnerColor(): string {
  return Styles.lightGreenRevolve;
}
export function offerFontFamily(): string {
  return Styles.FontFamily.figtreeRegular;
}
export function buttonAndIconColor(): string {
  return Styles.darkGreenRevolve;
}
export function addIconColor(): string {
  return Styles.darkGreenRevolve;
}
export function checkIconSize(): number {
  return Styles.Spacing.m3 - 2;
}
export function getOfferModalTextColor(): string {
  return Styles.black;
}
export function offerDetailsArrowGif({
  checkTopSpacing,
  videoRef,
  onBuffer,
  onError,
  isImage,
}: OfferDetailsArrowGifProps): JSX.Element {
  return (
    <View
      style={{
        top: checkTopSpacing(),
        marginLeft: 15,
      }}
    >
      <Video
        // Can be a URL or a local file.
        source={background}
        ref={videoRef}
        // Callback when remote video is buffering
        onBuffer={onBuffer}
        // Callback when video cannot be loaded
        onError={onError}
        resizeMode="cover"
        style={[
          offerStyles().backgroundVideo,
          isImage
            ? {
                top: Platform.OS === 'ios' ? -100 : -70,
              }
            : {
                top: -45,
              },
          {
            bottom: -80,
            width: '95%',
            height: 125,
          },
        ]}
      />
    </View>
  );
}

export function descNumberofLines(): number | null {
  return 8;
}
export function legaleseNumberofLines(): number | null {
  return 7;
}

export default {
  offerNoteIcon,
  offerStyles,
  getTextColor,
  primaryColor,
  deafultIconInnerColor,
  offerFontFamily,
  buttonAndIconColor,
  addIconColor,
  checkIconSize,
  getOfferModalTextColor,
  offerDetailsArrowGif,
  descNumberofLines,
  legaleseNumberofLines,
};
