import React from 'react';
import {View, StyleSheet, Image, ViewStyle, StyleProp} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {getDescriber} from './descriptor/DescriptorType';

const {offerFontFamily, getTextColor, buttonAndIconColor} = getDescriber();

type OffersErrorViewProps = {
  errorText: string;
  buttonLabel: string;
  onPress: () => void;
  viewStyle?: StyleProp<ViewStyle>;
};

const OffersErrorView: React.FC<OffersErrorViewProps> = ({
  errorText,
  buttonLabel,
  onPress,
  viewStyle,
}) => {
  return (
    <View style={[styles.container, viewStyle]}>
      <Image
        source={require('../../img/Alert-Circled.png')}
        resizeMode="contain"
        accessible
        accessibilityLabel="Error Icon"
        aria-label="Error Icon"
      />
      <AVText
        style={styles.errorText}
        accessible
        accessibilityLabel={errorText}
        aria-label={errorText}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      >
        {errorText}
      </AVText>
      <RoundedAppButton
        accessibilityLabelValue={buttonLabel}
        aria-label={buttonLabel}
        titleText={buttonLabel}
        buttonType={RoundedAppButtonType.Outline}
        buttonViewStyle={styles.buttonViewStyle}
        onPress={onPress}
        textStyle={styles.buttonTextStyle}
        buttonContainerStyle={{alignSelf: 'center'}}
      />
    </View>
  );
};

export default OffersErrorView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: Styles.Spacing.m3,
  },
  errorText: {
    fontSize: Styles.Fonts.f7,
    textAlign: 'center',
    color: getTextColor(),
    marginBottom: Styles.Spacing.m3,
    fontWeight: '400',
    fontFamily: offerFontFamily(),
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
  buttonViewStyle: {
    height: 42,
    minWidth: 120,
    overflow: 'hidden',
    borderWidth: 2,
  },
  buttonTextStyle: {
    fontFamily: offerFontFamily(),
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
    color: buttonAndIconColor(),
  },
});
