import moment from 'moment';
import * as React from 'react';
import {
  FlatList,
  Image,
  Platform,
  RefreshControl,
  StyleSheet,
  View,
} from 'react-native';
import {Avatar} from 'react-native-elements';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Header from '../../elements/Header';
import ShadowContainer from '../../elements/ShadowContainer';
import DefaultLogo from '../../img/svg/365Logo';
import SFLogo from '../../img/svg/SFLogo';
import Star from '../../img/svg/inbox/Star';
import Water from '../../img/svg/inbox/Water';
import Styles from '../../Styles';
import type {MessageType} from 'src/types/MessageType';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Deal from '../../elements/promotions/Deal';
import SwitchSelector from '../../elements/SwitchSelector';
import DealService from 'src/services/DealService';
import MarketLogo from '../../img/svg/MarketLogo';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AVText from '../../elements/AVText';
import ReferralItem from '../../elements/ReferralItem';
import type {Referral} from 'src/types/Referral';
import {ReferralStatus} from 'src/types/Referral';
import Events from 'src/logging/Events';
import ReferralActions from 'src/actions/ReferralActions';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import HttpClient from 'src/api/HttpClient';
import Util from 'src/Util';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess, confirm} from '../../helpers/AlertHelper';
import type {GiftItem, GiftItem as GiftItemType} from 'src/types/Snack';
import SnackItem from '../../elements/SnackItem';
import DealModel from 'src/models/Moblico/Deal';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {loadAllMessages, LoadSnacksParams} from 'src/redux/thunks/snackThunks';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Chat from 'src/components/img/svg/inbox/Chat';
import Logger from 'src/logging/Logger';
import NotifyChatIcon from 'src/components/img/svg/NotifyChatIcon';
import ReadRightMark from 'src/components/img/svg/ReadRightMark';
import Snackbar from 'src/components/dialogs/Snackbar';
import {
  markAllMessagesAsRead,
  NotificationMessage,
} from 'src/redux/thunks/notificationThunks/allMessagesRead';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import Tag from 'src/components/img/svg/inbox/Tag';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';

type InboxScreenProps = {
  navigation: NavigationProp<InboxScreen>;
  //loadSnacks(params: LoadSnacksParams): Promise<Array<GiftItemType>>;
  loadAllMessages(params: LoadSnacksParams): Promise<Array<GiftItemType>>;
  markAllMessagesAsRead(params: NotificationMessage): Promise<boolean>;
  filterType: filterTypes;
  unreadMessagesCount: number;
};
type InboxScreenState = {
  messages: Array<GiftItemType>;
  activeDeals: Array<GiftItemType>;
  pastDeals: Array<GiftItemType>;
  refreshing: boolean;
  filterType: filterTypes;
  snackbarVisible: boolean;
  snacksRefreshing: boolean;
  snackBarMessage: string;
  unreadMessagesCount: number;
};

export enum filterTypes {
  all = 'all',
  active = 'active',
  past = 'past',
}

export enum filterPositions {
  all = 0,
  active = 1,
  past = 2,
}
const TOTAL_UNREAD_MSG_COUNT = 'TOTAL_UNREAD_MESSAGES_COUNT';
const MARK_ALL_MSG_READ = 'MARK_ALL_MESSAGES_READ';

class InboxScreen extends React.Component<InboxScreenProps, InboxScreenState> {
  _focusListener;
  didFocusSubscrptn;
  didBlurSubscrptn;
  isDlsRefreshed = false;
  declare unreadNotification: number;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  declare static readMessagesObj: Map<string, boolean>;

  constructor(props: InboxScreenProps) {
    super(props);
    this.state = {
      messages: [],
      activeDeals: [],
      pastDeals: [],
      refreshing: true,
      snacksRefreshing: false,
      filterType: filterTypes.all,
      snackbarVisible: false,
      snackBarMessage: Localized.Success.notificationNoUnReadMessages,
      unreadMessagesCount: 0,
    };
    InboxScreen.readMessagesObj = new Map();
    this.renderItem = this.renderItem.bind(this);
    this.onMessagePress = this.onMessagePress.bind(this);
    //this.onDealPress = this.onDealPress.bind(this);
    this.renderIcon = this.renderIcon.bind(this);
    this.onDeletePressed = this.onDeletePressed.bind(this);
    this._reload = this._reload.bind(this);
    this.updateReferral = this.updateReferral.bind(this);
    //this.loadSnacks = this.loadSnacks.bind(this);
    this.onSnackDismiss = this.onSnackDismiss.bind(this);
    this._getKey = this._getKey.bind(this);
    this.onMarkAllAsReadPressed = this.onMarkAllAsReadPressed.bind(this);
  }

  componentDidMount() {
    if (Platform.OS === 'web') {
      ActionsFactory.getAccountActions().refreshMessages().catch();
      this.loadMessages().catch();
    }
    if (!Settings.isNewUI()) {
      this.didFocusSubscrptn = this.props.navigation.addListener(
        'focus',
        () => {
          this.loadMessages().catch();
        },
      );
    } else {
      this.loadAllData();
    }
    this._focusListener = this.props.navigation.addListener('focus', () => {
      if (this.state.messages.length == 0) {
        return;
      }
      this.updateUnreadMsgesCount();
    });

    this.props.navigation.addListener('state', () => {
      // Need this for refreshing messages based on TAB CHANGE and Opening notification detail
      this.localRefreshReadMessages();
    });

    this.didBlurSubscrptn = this.props.navigation.addListener('blur', () => {
      this.context.actions.resetFilterType();
    });
  }

  localRefreshReadMessages() {
    switch (this.props.filterType) {
      case filterTypes.all:
        this.updateReadMessages(this.state.messages);
        break;
      case filterTypes.active:
        this.updateReadMessages(this.state.activeDeals);
        break;
      case filterTypes.past:
        this.updateReadMessages(this.state.pastDeals);
    }
  }

  checkMessageRead(messages) {
    for (let i = 0; i < messages.length; i++) {
      const extServiceId = messages[i].externalServiceId;
      if (InboxScreen.readMessagesObj[extServiceId] != undefined) {
        if (messages[i].isRead != InboxScreen.readMessagesObj[extServiceId]) {
          return i;
        }
      }
    }
    return -1;
  }

  updateReadMessages(notificationMsgs) {
    if (InboxScreen.readMessagesObj[MARK_ALL_MSG_READ]) {
      this.updateAllMessagesAsRead(notificationMsgs);
    } else {
      this.updateSingleMessage(notificationMsgs);
    }

    this.setState({
      unreadMessagesCount: InboxScreen.readMessagesObj[TOTAL_UNREAD_MSG_COUNT],
      messages: notificationMsgs,
    });
  }

  updateSingleMessage(notificationMsgs) {
    const itemIndex = this.checkMessageRead(notificationMsgs);
    if (itemIndex != -1) {
      const selectedObj = notificationMsgs[itemIndex] as GiftItemType;
      if (selectedObj.isRead == false) {
        selectedObj.isRead = true;
        const unReadMessages = this.state.unreadMessagesCount - 1;
        notificationMsgs[itemIndex] = selectedObj;
        this.setGlobalUnreadMessageCount(unReadMessages);
      }
    }
  }

  updateAllMessagesAsRead(notificationMsgs) {
    notificationMsgs.forEach((element) => {
      element.isRead = true;
    });
  }

  async loadAllData() {
    this.loadMessages().catch();
  }

  componentWillUnmount() {
    if (!Settings.isNewUI) {
      this.didFocusSubscrptn();
      this.didBlurSubscrptn();
    }
    InboxScreen.readMessagesObj = new Map<string, boolean>();
  }

  isSendASnackObj(item: GiftItemType) {
    return item.type == 'SendaSnack';
  }

  updateUnreadMsgesCount() {
    const filterType = this.getFilterType();
    let tempMsges = this.state.messages;
    if (filterType === filterTypes.all) {
      tempMsges = this.state.messages;
    } else if (filterType === filterTypes.active) {
      tempMsges = this.state.activeDeals;
    } else if (filterType === filterTypes.past) {
      tempMsges = this.state.pastDeals;
    } else {
      tempMsges = this.state.messages;
    }
    this.updateReadMessages(tempMsges);
  }

  setGlobalUnreadMessageCount(msgCount) {
    InboxScreen.readMessagesObj[TOTAL_UNREAD_MSG_COUNT] = msgCount;
  }

  filterMsgBasedOnAdmFeatureFlag(
    _messages: Array<GiftItemType>,
  ): Array<GiftItemType> {
    let filteredMsgs = _messages;
    const shouldLoadSncks =
      AccountStore.isSendSnackEnabledForLocation() &&
      AccountStore.isConsumerSendSnackEnabled();
    if (!shouldLoadSncks) {
      filteredMsgs = filteredMsgs.filter(
        (item: GiftItemType) => item.type === 'Deal',
      );
    }
    return filteredMsgs;
  }

  isDealActive(_deal: GiftItem): boolean {
    if (_deal) {
      const _now = moment();
      return (
        _now.isBefore(_deal.endDate) &&
        (_deal.numberOfUsesPerCode === 0 ||
          _deal.redeemedCount < _deal.numberOfUsesPerCode)
      );
    }

    return false;
  }

  filterMessagesBasedOnType(type: string, _msges: Array<GiftItem>) {
    // type:"Deal" or "SendASnack"
    const filteredMsgs = _msges.filter((item: GiftItem) => item.type == 'Deal');
    return filteredMsgs;
  }

  async updateInbox(unreadNotification: number, _messages: Array<GiftItem>) {
    const messages = [..._messages];
    let dealsOnly: GiftItem[] = [];
    try {
      if (
        AccountStore.getConsumerEngagementId() &&
        AccountStore.isConsumerEngagementEnabled()
      ) {
        dealsOnly = this.filterMessagesBasedOnType('Deal', _messages);
      }
      const _activeDeals = dealsOnly.filter((deal) => this.isDealActive(deal));
      const _pastDeals = dealsOnly.filter((deal) => {
        return !_activeDeals.some(
          (activeDeal) =>
            activeDeal.externalServiceId === deal.externalServiceId,
        );
      });
      const allMessages = [...messages, ..._activeDeals] as Array<
        MessageType & DealModel & Referral & GiftItemType
      >;

      allMessages.sort((a, b) => {
        const _bDate = this.getSortDate(b);
        const _aDate = this.getSortDate(a);
        return _bDate - _aDate;
      });

      _activeDeals.sort(
        (
          a: MessageType & Referral & GiftItemType & DealModel,
          b: MessageType & Referral & GiftItemType & DealModel,
        ) => {
          const _bDate = this.getSortDate(b);
          const _aDate = this.getSortDate(a);
          return _aDate - _bDate;
        },
      );
      this.setGlobalUnreadMessageCount(unreadNotification);
      this.setState({
        messages: allMessages,
        activeDeals: _activeDeals,
        pastDeals: _pastDeals,
        refreshing: false,
        snacksRefreshing: false,
        unreadMessagesCount: unreadNotification,
      });
      FirebaseAnalytic.trackEvent('updateInbox', 'InboxScreen: updateInbox', {
        ...this.props,
        ...this.state,
      });
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'InboxScreen:updateInbox',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'InboxScreen:updateInbox',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getSortDate(_item: MessageType & Referral & GiftItemType & DealModel) {
    if (_item.subject) {
      return moment(_item.date).valueOf();
    }

    if (this.isSendASnackObj(_item)) {
      return moment(_item.snackSent).valueOf();
    }

    if (_item.type === 'Referral') {
      return moment(_item.dateCreated).valueOf();
    }

    return moment(_item.endDate).valueOf();
  }

  async loadMessages() {
    this.setState({
      refreshing: true,
    });
    const _messages = await this.props.loadAllMessages({
      locationId: AccountStore.getLocationId(),
    });
    const _unreadMessages = _messages.filter((item) => !item.isRead);
    let unreadNotification = 0;
    if (_unreadMessages.length > 0) {
      unreadNotification = _unreadMessages.length;
    }
    if (_messages) {
      FirebaseAnalytic.trackEvent('loadMessages', 'InboxScreen: loadMessages', {
        ...this.props,
        ...this.state,
      });
    }

    this.updateInbox(unreadNotification, _messages);
  }

  onMessagePress(message: MessageType) {
    ActionsFactory.getAccountActions().messageRead(message).catch();
    FirebaseAnalytic.trackEvent(
      'onMessagePress',
      'InboxScreen: onMessagePress',
      {
        ...this.props,
        ...this.state,
      },
    );

    NavActions.push(AppRoutes.MessageDetail, {
      message,
      renderIcon: this.renderIcon,
    });
  }

  async onReferralClaim(referral: Referral, index: number) {
    this.context.actions.showSpinner();
    let redeemReferralResponse;
    try {
      redeemReferralResponse = await ReferralActions.redeemReferral(
        AccountStore.getAccountId(),
        referral.referralId,
        Util.getCurrentDate(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(redeemReferralResponse),
      );
      this.updateReferral(referral, index, ReferralStatus.Claimed);
      alertSuccess(Localized.Labels.claimed_successfully);
      FirebaseAnalytic.trackEvent(
        'onReferralClaim',
        'InboxScreen: onReferralClaim',
        {
          ...this.props,
          ...this.state,
        },
      );
      this._reload().catch();
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());
      let message = Localized.Labels.unable_to_redeem;

      if (error?.code === PlatformApiErrors.CampaignAlreadyExpired) {
        message = Localized.Labels.campaign_already_expired;
      } else if (error?.code === PlatformApiErrors.ReferralAlreadyRedeemed) {
        message = Localized.Labels.reward_already_redeemed;
      }

      this.updateReferral(referral, index, ReferralStatus.Expired);
      alertError(message);
      CrashlyticsEvents.log(
        'Exception',
        'InboxScreen:onReferralClaim',
        error,
        redeemReferralResponse,
      );
      Events.Error.trackEvent(
        'Exception',
        'InboxScreen:onReferralClaim',
        error,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  updateReferral(referral, index, status) {
    const {messages} = this.state;
    const field =
      referral.sender === AccountStore.getAccountId()
        ? 'senderRedeem'
        : 'receiverRedeem';
    referral[field] = status;
    messages[index] = referral;
    this.setState({
      messages,
    });
    FirebaseAnalytic.trackEvent(
      'updateReferral',
      'InboxScreen: updateReferral',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  async _reload() {
    const accountId = AccountStore.getAccountId();
    const accountBalanceId = AccountStore.getAccountBalanceId();
    const email = AccountStore.getEmail();
    await ActionsFactory.getAccountActions().reloadConsumerData({
      accountId,
      accountBalanceId,
      email,
      userInitiated: true,
    });
  }

  onDeletePressed(message: MessageType) {
    confirm(Localized.Labels.confirm_delete_message, () => {
      ActionsFactory.getAccountActions().deleteMessage(message).catch();
    });
    FirebaseAnalytic.trackEvent(
      'onDeletePressed',
      'InboxScreen: onDeletePressed',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  renderAppLogo() {
    if (Settings.buildType === BuildTypes.default) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_365Pay}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_365Pay}
        >
          <DefaultLogo size={Styles.Fonts.f4} />
        </View>
      );
    } else if (Settings.buildType === BuildTypes.social) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_social_feedia}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_social_feedia}
        >
          <SFLogo size={Styles.Fonts.f4} />
        </View>
      );
    } else if (Settings.buildType === BuildTypes.market) {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_market_connect}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_market_connect}
        >
          <MarketLogo size={Styles.Fonts.f4} fillColor={Styles.black} />
        </View>
      );
    } else {
      return (
        <View
          accessible={true}
          accessibilityLabel={Localized.Labels.logo_canteen}
          accessibilityRole="image"
          role="img"
          aria-label={Localized.Labels.logo_canteen}
        >
          <Image
            style={styles.icon}
            source={require('../../img/canteen-icon.png')}
          />
        </View>
      );
    }
  }

  getStarIcon() {
    return <Star width={Styles.Fonts.f4} height={Styles.Fonts.f4} />;
  }

  getWaterIcon() {
    return <Water width={Styles.Fonts.f4} height={Styles.Fonts.f4} />;
  }

  renderIcon(item: MessageType) {
    if (Settings.isRevolve()) {
      switch (item.icon) {
        case 'app':
          return this.renderAppLogo();

        case 'star':
          return this.getStarIcon();

        case 'water':
          return this.getWaterIcon();

        default:
          return (
            <View
              accessible={true}
              accessibilityLabel={this.getMessageTitle(item)}
              accessibilityRole="image"
              role="img"
              aria-label={this.getMessageTitle(item)}
            >
              <Avatar
                rounded
                title={this.getMessageTitle(item)}
                size={Styles.Fonts.f4}
              />
            </View>
          );
      }
    } else {
      return <NotifyChatIcon width={50} height={50} bgColor={'#003349'} />;
    }
  }

  getMessageTitle(_message: MessageType) {
    let title = '365';

    if (_message.from) {
      const _pieces = _message.from.split(' ');

      if (_pieces.length === 1) {
        title = _pieces[0].charAt(0).toUpperCase();
      } else {
        title =
          _pieces[0].charAt(0).toUpperCase() +
          _pieces[1].charAt(0).toUpperCase();
      }
    }

    return title;
  }

  _onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    ActionsFactory.getAccountActions().refreshMessages().catch();
    this.loadMessages().catch();
    //this.loadReferrals().catch();
  };

  getDataSource(filterType: filterTypes) {
    if (Settings.is365Pay()) {
      return this.state.messages;
    }
    if (filterType === filterTypes.all) {
      return this.state.messages;
    }

    if (filterType === filterTypes.active) {
      return this.state.activeDeals;
    }

    return this.state.pastDeals;
  }

  _getKey(_item) {
    if (_item.subject) {
      return `${_item.id}${_item.date}`;
    }

    if (_item.snackId) {
      return `${_item.snackId}${_item.snackSent}`;
    }

    if (_item.campaign) {
      return `${_item.campaignId}${_item.dateCreated}`;
    }

    return `${_item.id}${_item.endDate}_${this.props.filterType}`;
  }

  renderItem({
    item,
    index,
  }: {
    item: MessageType & Referral & GiftItemType & DealModel;
    index: number;
  }) {
    if (this.isSendASnackObj(item)) {
      return this.getSnackCmpnt(item, index);
    }

    if (item.type === 'Referral') {
      return (
        <ReferralItem
          referral={item}
          onPress={() => this.getReferralCmpnt(item)}
          {...this.context}
        />
      );
    }

    return this.getDealComponent(item, index);
  }

  getDealComponent(item, selectedItemIndex) {
    return (
      <Deal
        giftItem={item}
        selectedItemIndex={selectedItemIndex}
        key={item.externalServiceId}
        {...this.context}
        onPress={() => {
          InboxScreen.readMessagesObj[item.externalServiceId] = true;
          FirebaseAnalytic.trackEvent(
            'onDealPress',
            'InboxScreen: onDealPress',
            {
              ...this.props,
              ...this.state,
              selectedItemIndex,
            },
          );
          NavActions.push(AppRoutes.DealDetail, {
            deal: item,
          });
        }}
      />
    );
  }

  getReferralCmpnt(item: Referral) {
    InboxScreen.readMessagesObj[item.externalServiceId] = true;
    NavActions.push(AppRoutes.ReferralDetail, {
      referralId: item?.referralId,
      selectedReferItem: item,
    });
  }

  gotoSnackDtl(_item: GiftItemType) {
    NavActions.push(AppRoutes.SnackDetail, {
      snackId: _item.externalServiceId,
      snackLocationId: _item.locationId,
      snackType: _item.type,
      snackDetails: _item,
    });
  }

  getSnackCmpnt(item, index) {
    return (
      <SnackItem
        snack={item}
        {...this.context}
        key={item.externalServiceId}
        onPress={() => {
          InboxScreen.readMessagesObj[item.externalServiceId] = true;
          this.setState(() => {
            this.gotoSnackDtl(item);
          });
        }}
      />
    );
  }

  getFilterType() {
    const _filterType = this.props.filterType;
    return _filterType;
  }

  getNewDataSource() {
    const filterType = this.getFilterType();
    const _data = this.getDataSource(filterType) as Array<
      MessageType & Referral & GiftItemType & DealModel
    >;
    return _data.reduce((_acc, _obj) => {
      if (!_acc.some((o) => o.externalServiceId === _obj.externalServiceId)) {
        _acc.push(_obj);
      }
      // We are implimenting the referal Inbox once API changes are done
      // if (!_acc.some((o) => o.referralId === _obj.referralId)) {
      //   _acc.push(_obj);
      // }

      return _acc;
    }, []);
  }

  checkIsRefreshing() {
    return this.state.refreshing;
  }

  isDealsRefreshing() {
    let isDealsRefreshing = false;
    if (DealService.getDeals().length == 0) {
      if (!this.isDlsRefreshed) {
        isDealsRefreshing = true;
      } else {
        isDealsRefreshing = false;
      }
    } else {
      isDealsRefreshing = false;
    }
    this.isDlsRefreshed = false;
    return isDealsRefreshing;
  }

  getLoader() {
    return (
      <View style={styles.emptyMessageContainer}>
        <AVText
          style={
            getDescriber().getNotificationScreenStyles()['emptyMessageText']
          }
        >
          {Localized.Labels.loading}
        </AVText>
      </View>
    );
  }

  renderEmptyMessage() {
    const listData = this.getNewDataSource();
    if (!this.checkIsRefreshing() && listData.length == 0) {
      if (Platform.OS === 'web') {
        return this.getNewEmptyMessageUI();
      }
      return Settings.is365Pay()
        ? this.getNewEmptyMsgUI()
        : this.getNewEmptyMessageUI();
    }
    return null;
  }

  getNewEmptyMsgUI() {
    return (
      <View style={styles.emptyMessageContainer}>
        <View style={{alignSelf: 'center'}}>
          <Chat />
        </View>
        <AVText
          style={
            getDescriber().getNotificationScreenStyles()['emptyMessageText']
          }
        >
          {Localized.Labels.no_messages}
        </AVText>
      </View>
    );
  }
  getNewEmptyMessageUI() {
    if (this.getEmptyMessage() == Localized.Labels.no_messages)
      return (
        <View style={styles.emptyMessageContainer}>
          <View style={{alignSelf: 'center'}}>
            {Settings.is365Pay() ? (
              <Chat />
            ) : (
              <NotifyChatIcon width={117} height={117} bgColor={'#727179'} />
            )}
          </View>
          <AVText
            style={
              getDescriber().getNotificationScreenStyles()['emptyMessageText']
            }
          >
            {this.getEmptyMessage()}
          </AVText>
        </View>
      );
    else {
      return (
        <View style={styles.emptyMessageContainer}>
          <View style={{alignSelf: 'center'}}>
            <Tag />
          </View>
          <AVText
            style={
              getDescriber().getNotificationScreenStyles()['emptyMessageText']
            }
          >
            {this.getEmptyMessage()}
          </AVText>
        </View>
      );
    }
  }
  getEmptyMessage() {
    const filterType = !Settings.is365Pay()
      ? this.props.filterType
      : this.state.filterType;
    switch (filterType) {
      case filterTypes.all:
        return Localized.Labels.no_messages;
      case filterTypes.active:
        return Localized.Labels.no_current_offers;
      case filterTypes.past:
        return Localized.Labels.no_past_offers;
    }
  }

  async onMarkAllAsReadPressed() {
    if (this.state.unreadMessagesCount == 0) {
      const message = this.getSnackMsg(true);
      this.setState({
        snackbarVisible: true,
        snackBarMessage: message,
      });
    } else {
      const markMessagesAsRead = await this.props.markAllMessagesAsRead({
        locationId: AccountStore.getLocationId(),
      });

      const message = this.getSnackMsg(false);
      if (markMessagesAsRead) {
        this.setAllMessagesAsRead();
        this.loadAllData();
        setTimeout(() => {
          this.setState({
            snackbarVisible: true,
            snackBarMessage: message,
          });
        }, 3500);
      }
    }
  }

  setAllMessagesAsRead() {
    InboxScreen.readMessagesObj[MARK_ALL_MSG_READ] = true;
  }

  getSnackMsg(_allMessageRead: boolean) {
    let snackMsg = '';
    if (_allMessageRead) {
      snackMsg = Localized.Success.notificationNoUnReadMessages;
    } else {
      snackMsg = Localized.Labels.formatString(
        Localized.Success.notificationMarkedAllAsRead,
        this.state.unreadMessagesCount,
      ).toString();
    }
    return snackMsg;
  }

  getRenderComponent() {
    const listData = this.getNewDataSource();
    return Settings.isNewUI() || Platform.OS === 'web' ? (
      <>
        <ShadowContainer style={styles.content}>
          {listData.length != 0 && (
            <View
              style={[
                !Settings.isRevolveAnd365Pay() && Styles.Style.shadow,
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: Styles.Spacing.m3,
                  paddingBottom: Styles.Spacing.m2,
                },
              ]}
            >
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                style={
                  getDescriber().getNotificationScreenStyles()['unreadNumText']
                }
              >
                {Localized.Labels.formatString(
                  this.state.unreadMessagesCount === 1
                    ? Localized.Labels.unreadNotificationsCount.slice(0, -1)
                    : Localized.Labels.unreadNotificationsCount,
                  String(this.state.unreadMessagesCount || 0),
                ).toString()}
              </AVText>
              <View style={{flexDirection: 'row'}}>
                <ReadRightMark />
                <AVTouchableOpacity
                  accessible={true}
                  accessibilityLabel={Localized.Labels.mark_all_as_read}
                  accessibilityRole="link"
                  accessibilityHint="Double tap to mark all as read"
                  aria-label={Localized.Labels.mark_all_as_read}
                  role="link"
                  style={{marginLeft: Styles.Spacing.m1}}
                  onPress={this.onMarkAllAsReadPressed}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      borderBottomColor:
                        getDescriber().getStyleWithColorOnly()[
                          'allReadTextUnderLine'
                        ],
                      borderBottomWidth: 2,
                    }}
                  >
                    <AVText
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                      style={[
                        getDescriber().renderReferralStyleDescriptor()[
                          'allReadText'
                        ],
                      ]}
                    >
                      {Localized.Labels.mark_all_as_read}
                    </AVText>
                  </View>
                </AVTouchableOpacity>
              </View>
            </View>
          )}
          {listData.length != 0 && (
            <>
              <FlatList
                refreshControl={
                  <RefreshControl
                    refreshing={this.checkIsRefreshing()}
                    onRefresh={this._onRefresh}
                  />
                }
                data={listData}
                renderItem={this.renderItem}
                ListFooterComponent={
                  <View
                    style={{backgroundColor: 'transparent', paddingBottom: 16}}
                  />
                }
              />
            </>
          )}
          {listData.length == 0 && this.checkIsRefreshing() && this.getLoader()}
          {listData.length == 0 &&
            !this.checkIsRefreshing() &&
            Settings.isRevolveAnd365Pay() &&
            this.getNewEmptyMsgUI()}
          {listData.length == 0 &&
            !this.checkIsRefreshing() &&
            !Settings.isRevolveAnd365Pay() &&
            this.getNewEmptyMessageUI()}
        </ShadowContainer>
        {this.state.snackbarVisible && (
          <Snackbar
            message={this.state.snackBarMessage}
            duration={4000}
            backgroundColor={
              getDescriber().getStyleWithColorOnly()['backgroundColor']
            }
            textColor={getDescriber().getStyleWithColorOnly()['color']}
            onDismiss={this.onSnackDismiss}
          />
        )}
      </>
    ) : (
      <Header
        title={Localized.Labels.inbox}
        bottomView={
          <SwitchSelector
            value={this.context.state.filterType}
            onPress={(value: filterTypes) => this.setState({filterType: value})}
            options={[
              {
                text: Localized.Labels.messages,
                label: Localized.Labels.messages,
                value: 'all',
              },
              {
                text: Localized.Labels.active_offers,
                label: Localized.Labels.active_offers,
                value: 'active',
              },
              {
                text: Localized.Labels.past_offers,
                label: Localized.Labels.past_offers,
                value: 'past',
              },
            ]}
          />
        }
      >
        <ShadowContainer style={styles.content}>
          {listData.length != 0 && (
            <FlatList
              refreshControl={
                <RefreshControl
                  refreshing={this.state.refreshing}
                  onRefresh={this._onRefresh}
                />
              }
              data={listData}
              keyExtractor={(item) => this._getKey(item)}
              renderItem={this.renderItem}
              ListFooterComponent={
                <View
                  style={{backgroundColor: 'transparent', paddingBottom: 16}}
                />
              }
            />
          )}
          {this.checkIsRefreshing() && listData.length == 0 && this.getLoader()}
          {!this.checkIsRefreshing() &&
          listData.length == 0 &&
          Settings.is365Pay()
            ? this.getNewEmptyMsgUI()
            : this.getNewEmptyMessageUI()}
        </ShadowContainer>
      </Header>
    );
  }
  onSnackDismiss() {
    this.setState({
      snackbarVisible: false,
    });
  }
  render() {
    return this.getRenderComponent();
  }
}

const styles = StyleSheet.create({
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  borderContainer: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  content: {
    backgroundColor: getDescriber().activeTabShadowColor(),
  },
  emptyMessageContainer: {
    fontSize: Styles.Fonts.f7,
    alignSelf: 'center',
    textAlign: 'center',
    flex: 1,
    textAlignVertical: 'center',
    justifyContent: 'center',
  },
  from: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
  },
  icon: {
    borderRadius: Styles.Fonts.f4 / 2,
    height: Styles.Fonts.f4,
    resizeMode: 'contain',
    width: Styles.Fonts.f4,
  },
  message: {
    fontSize: Styles.Fonts.f8,
    marginBottom: Styles.Spacing.m1,
    color: Styles.lightGray,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    padding: Styles.Spacing.m3,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  messageLeft: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
  },
  subject: {
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
    fontWeight: '400',
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  topRight: {
    flexDirection: 'row',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default compose(
  withForwardedNavigationParams<InboxScreenProps>(),
  connect(null, (dispatch: AppDispatch) => ({
    markAllMessagesAsRead: (params) =>
      dispatch(markAllMessagesAsRead(params)).unwrap(),
    loadAllMessages: (params) => dispatch(loadAllMessages(params)).unwrap(),
  })),
)(InboxScreen);
