import React from 'react';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import MainConsumerContext from '../../MainConsumerContext';
import BackSubheader from '../../elements/BackSubheader';
import {CartItem, TransactionDetail} from 'src/types/TransactionDetail';
import {LocationType} from 'src/types/Location';
import Localized from 'src/constants/AppStrings';
import {
  AccessibilityInfo,
  Platform,
  StyleSheet,
  View,
  Text,
  ScrollView,
} from 'react-native';
import CartTypes from 'src/constants/cart/CartTypes';
import {TimeSlotType} from 'src/types/Menu';
import CartStore, {PaymentType} from 'src/stores/CartStore';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import {AppDispatch, RootState} from 'src/redux/store';
import {connect} from 'react-redux';
import {CreditCard} from 'src/models/CreditCard';

import {Balance} from 'src/models/Balance';
import {BalanceTypes, Identifier} from 'src/types/serverTypes/Account';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Styles from '../../Styles';
import SearchAndBalanceAmount from './SearchAndBalanceAmount';
import ScanArea from '../../elements/cart/ScanArea';
import Util, {getPreviousRouteName, getTotalDisplayAmount} from '../../../Util';
import AppRoutes from '../../../AppRoutes';
import TransactionStore from '../../../stores/TransactionStore';
import CrashlyticsEvents from '../../../logging/Crashlytics';
import {generateErrorMessage} from '../../../logging/generateErrorMessage';
import Events from '../../../logging/Events';
import PromotionTypes from '../../../constants/cart/PromotionTypes';
import AccountStore from '../../../stores/AccountStore';
import CartService from '../../../services/CartService';
import ListScanItems from './ListScanItems';
import MenuService from '../../../services/MenuService';
import MenuActions from '../../../actions/MenuActions';
import NavActions from '../../../actions/NavActions';
import uuid from '../../../nativeModules/UUID';
import {alertError, alertSuccess, confirm} from '../../helpers/AlertHelper';
import moment from 'moment/moment';
import ActionsFactory from '../../../actions/ActionsFactory';
import Logger from '../../../logging/Logger';
import TransactionActions from '../../../actions/TransactionActions';
import LocationFeatures from '../../../models/LocationFeatures';
import Settings from 'src/Settings';
import {
  fetchAccountBalanceCreditCards,
  fetchAccountBalances,
} from '../../../redux/slices/accountSlice';
import PaymentTypes from 'src/constants/cart/PaymentTypes';
import PaymentStatus from 'src/constants/cart/PaymentStatus';
import BuildTypeConstants from '../../../constants/BuildTypeConstants';
import AppApi from '../../../api/AppApi';
import PurchaseTypes from '../../../constants/cart/PurchaseTypes';
import LockerTypes from '../../../constants/LockerTypes';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import AVText from 'src/components/elements/AVText';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import PreparationMethods from './ordearahead/PreparationMethodNewUI';
import ListCartEmpty from 'src/components/screens/scanAndPay/ListCartEmpty';
import TagManager from 'src/components/utils/TagManager';
import AppLifecycleTracker from 'src/components/utils/AppLifecycleTracker';

type ScanScreenNewUIProps = WithGlobalizeProps & {
  navigation: NavigationProp<object>;
  locationId: string;
  locationType: string;
  marketName: string;
  beaconId: string;
  promotionDescription: string;
  applePayAvailable: boolean;
  googlePayAvailable: boolean;
  location?: LocationType;
  cartType: CartTypes;
  isPayrollAvailable: boolean;
  payrollIdentifierId: string;
  creditCards: Array<CreditCard>;
  accountId: string;
  defaultBalanceId: string;
  balances: Array<Balance>;
  dispatch: AppDispatch;
  discountCode?: string;
  shouldRefresh?: boolean;
};
type ScanScreenNewUIState = {
  balance: number;
  checkout: boolean;
  checkingOut: boolean;
  transaction: TransactionDetail | null;
  preparationMethod: PreparationMethodValues;
  pickupTime?: TimeSlotType;
  displayItems: Array<CartItem>;
  togoAvailable: boolean;
  dineInAvailable: boolean;
  lockerAvailable: boolean;
  hasOrderNote: boolean;
  orderNote: string | null;
  isFocused: boolean;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  unlockDiscountAvailable?: boolean;
  consumerRoleIdentifier: Identifier | null;
  discountCodeError?: boolean;
  discountCodeSuccess?: boolean;
  showDiscountCodeSuccess?: boolean;
  changeToDefaultDiscount?: boolean;
  checkoutSuccess?: boolean;
  orderAheadBackSelected?: boolean;
};

class ScanScreenNewUI extends React.Component<
  ScanScreenNewUIProps,
  ScanScreenNewUIState
> {
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  scanArea: ScanArea | null;
  declare pageTitle: string;
  declare willFocusSubscription: unknown;

  constructor(props: ScanScreenNewUIProps) {
    super(props);

    const transaction = TransactionStore.getTransaction();
    const displayItems = TransactionStore.getDisplayItems();
    const checkout = displayItems.length > 0;
    const defaults = TransactionStore.getPreparationMethodDefaults(
      props.cartType === CartTypes.ScanAndPay ? null : props.location,
    );
    const locationFeatures = new LocationFeatures(
      props.location?.locationFeatures,
    );
    const hasOrderNote = locationFeatures.HasOrderNote;
    const hasConsumerRoles = locationFeatures?.HasConsumerRoles;
    const consumerRoleButton = locationFeatures?.consumerRoleButton;
    let titleScreen = this.props.marketName;
    if (Platform.OS !== 'web' && this.props.location.displayName) {
      titleScreen = this.props.location.displayName;
    }
    this.pageTitle =
      this.props.cartType === CartTypes.ScanAndPay
        ? titleScreen
        : Localized.Buttons.checkout;
    this.state = {
      balance: AccountStore.getAccountBalance(),
      transaction,
      checkout,
      preparationMethod: defaults.selectedMethod,
      togoAvailable: defaults.togoAvailable,
      dineInAvailable: defaults.dineInAvailable,
      lockerAvailable: defaults.lockerAvailable,
      pickupTime: TransactionStore.getPickupTime(),
      displayItems,
      hasOrderNote,
      orderNote: null,
      checkingOut: false,
      isFocused: false,
      hasConsumerRoles,
      consumerRoleButton,
      unlockDiscountAvailable: false,
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
      discountCodeError: false,
      discountCodeSuccess: false,
      changeToDefaultDiscount: false,
      checkoutSuccess: false,
      orderAheadBackSelected: false,
    };
    this.onPreparationMethodSelected =
      this.onPreparationMethodSelected.bind(this);
    this.scanArea = null;
    this.saveDiscountCode = this.saveDiscountCode.bind(this);
    this.goToPickupLocationScreen = this.goToPickupLocationScreen.bind(this);
  }

  componentDidMount() {
    this.willFocusSubscription = this.props.navigation.addListener(
      'focus',
      () => {
        if (this.props.shouldRefresh) {
          this.setState({
            isFocused: true,
            changeToDefaultDiscount: false,
            discountCodeSuccess: true,
          });
        } else {
          this.setState({isFocused: true});
        }
      },
    );
    if (this.state.consumerRoleIdentifier) {
      this.validateDiscountCode();
    }
    AccountStore.addChangeListener(this.onAccountStoreChanged);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
    TransactionStore.addPickupLocationCHangedListener(
      this.onPickupLocationChanged,
    );
    TransactionStore.addPickupTimeChangedListener(this.onPickupTimeChanged);
    FirebaseAnalytic.trackEvent('componentDidMount', 'ScanScreenNewUI', {
      ...this.props,
      ...this.state,
    });

    CartStore.initializeSession(
      this.props.beaconId,
      this.props.locationId,
      this.props.marketName,
      this.props.location.displayName,
    );
    const canCheckout = TransactionStore.getDisplayItems().length > 0;
    if (canCheckout) {
      AppLifecycleTracker.updateCartState(true);
    }
  }

  async componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
    TransactionStore.removeChangeListener(this.onPickupLocationChanged);
    TransactionStore.removePickupTimeChangedListener(this.onPickupTimeChanged);
    if (
      !this.state.checkoutSuccess &&
      !this.state.orderAheadBackSelected &&
      this.state.displayItems.length > 0
    ) {
      try {
        await TagManager.updateDynamicTags('cart_abandoned');
      } catch (error) {
        console.error('Error updating cancel tag:', error);
      }
    }
    const routeName = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    if (
      this.state.displayItems.length === 0 ||
      routeName !== AppRoutes.NewPickupTime
    ) {
      AppLifecycleTracker.clearCartState();
    }
  }

  onPickupLocationChanged = () => {
    try {
      const defaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );
      FirebaseAnalytic.trackEvent('onPickupLocationChanged', 'ScanScreen', {
        ...this.props,
        ...this.state,
        preparationMethod: defaults.selectedMethod,
        togoAvailable: defaults.togoAvailable,
        dineInAvailable: defaults.dineInAvailable,
        lockerAvailable: defaults.lockerAvailable,
      });
      if (!this.state.checkingOut) {
        this.setState({
          preparationMethod: defaults.selectedMethod,
          togoAvailable: defaults.togoAvailable,
          dineInAvailable: defaults.dineInAvailable,
          lockerAvailable: defaults.lockerAvailable,
        });
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:onPickupLocationChanged',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onPickupLocationChanged',
        generateErrorMessage(error),
      );
    }
  };

  onTransactionStoreChanged = () => {
    try {
      const transaction = TransactionStore.getTransaction();
      const displayItems = TransactionStore.getDisplayItems();
      const canCheckout = displayItems.length > 0;
      FirebaseAnalytic.trackEvent('onTransactionStoreChanged', 'ScanScreen', {
        transaction,
        checkout: canCheckout,
        pickupTime: TransactionStore.getPickupTime(),
        displayItems,
      });
      if (!this.state.checkingOut) {
        this.setState({
          transaction,
          checkout: canCheckout,
          pickupTime: TransactionStore.getPickupTime(),
          displayItems,
        });
      }
      if (canCheckout) {
        AppLifecycleTracker.updateCartState(true);
      } else {
        AppLifecycleTracker.clearCartState();
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:onTransactionStoreChanged',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onTransactionStoreChanged',
        generateErrorMessage(error),
      );
    }
  };

  onPickupTimeChanged = () => {
    this.addDiscountToCart();
  };

  onAccountStoreChanged = () => {
    FirebaseAnalytic.trackEvent('onAccountStoreChanged', 'ScanScreen', {
      balance: AccountStore.getAccountBalance(),
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
    });
    if (!this.state.checkingOut) {
      this.setState({
        balance: AccountStore.getAccountBalance(),
        consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
      });
    }
  };

  updateCart = async (
    items: CartItem[],
    barcode?: string,
    discountCode?: string,
  ) => {
    FirebaseAnalytic.trackEvent('updateCart', 'ScanScreen', {
      items,
      discountCode,
    });
    const response = await CartService.updateCart(
      this.props.locationId,
      AccountStore.getAccountId(),
      [],
      items,
      this.props.locationType,
      barcode,
      this.state.pickupTime,
      this.state.preparationMethod,
      this.context,
      discountCode,
    );
    FirebaseAnalytic.trackEvent('updateCart response', 'ScanScreen', {
      items,
      discountCode,
      response,
    });
  };

  getDiscountCode = () => {
    if (
      this.props.location.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
    ) {
      return PromotionTypes.AfterTaxPromo;
    } else {
      return this.state.unlockDiscountAvailable
        ? AccountStore.getConsumerIdentifier()?.value
        : null;
    }
  };

  barcodeScanned = async (barcode: string) => {
    try {
      this.context.actions.showSpinner();
      const items = [...TransactionStore.getAllItems()];
      items.unshift({
        BarCode: barcode,
      });
      FirebaseAnalytic.trackEvent('barcodeScanned', 'ScanScreenNewUI', {
        items,
      });
      await this.updateCart(items, barcode, this.getDiscountCode());
      this.scanArea?.handleScanNewItem();
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:barcodeScanned',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:barcodeScanned',
        generateErrorMessage(error),
      );
    }
  };

  handleConfirmTrashClick = (itemNumbers: Array<number>) => {
    confirm(
      Localized.Labels.do_you_want_remove_cart_item,
      () => {
        this.handleTrashClick(itemNumbers);
      },
      null,
      Localized.Labels.remove_cart_item,
      Localized.Buttons.cancel,
      Localized.Buttons.ok,
    );
  };

  handleTrashClick = async (itemNumbers: Array<number>) => {
    this.context.actions.showSpinner();
    const updatedItems = TransactionStore.getAllItems().filter(
      (item) => !itemNumbers.includes(item.LineNumber),
    );

    FirebaseAnalytic.trackEvent('handleTrashClick', 'ScanScreen', {
      updatedItems,
    });

    try {
      await this.updateCart(updatedItems, null, this.getDiscountCode());

      AccessibilityInfo.announceForAccessibility('Item deleted!');
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:HandleTrashClick',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleTrashClick',
        e.message ? e.message : e.toString(),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  };

  handleEditItemClicked = async (item: CartItem) => {
    FirebaseAnalytic.trackEvent('handleEditItemClicked', 'ScanScreen', {
      item,
    });
    if (item.Id) {
      const product = MenuService.getProduct(item.Id);
      try {
        this.context.actions.showSpinner();
        const response = await MenuActions.getModifiers(
          this.props.location.locationId,
          product,
        );

        FirebaseAnalytic.trackEvent(
          'handleEditItemClicked getModifiers',
          'ScanScreen',
          {
            response,
            item,
            navigate: AppRoutes.ProductDetail,
          },
        );
        NavActions.push(AppRoutes.ProductDetail, {
          edit: true,
          location: this.props.location,
          product,
          lineNumber: item.LineNumber,
          modifierSelections: item.Modifiers,
        });
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ScanScreen:handleEditItemClicked',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'ScanScreen:handleEditItemClicked',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.internet_issue, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  };

  onBackSelect = async () => {
    FirebaseAnalytic.trackEvent('onBackSelect', 'ScanScreen');
    try {
      if (this.props.cartType !== CartTypes.OrderAhead) {
        if (this.state.displayItems && this.state.displayItems.length > 0) {
          confirm(
            Localized.Labels.cancel_order_confirm,
            () => {
              CartService.clearCart(moment());
              NavActions.pop();
            },
            undefined,
            Localized.Labels.cancel_order,
            Localized.Labels.no,
            Localized.Labels.yes,
          );
        } else {
          CartService.clearCart(moment());
          NavActions.pop();
        }
      } else {
        this.setState({orderAheadBackSelected: true});
        NavActions.pop();
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:onBackSelect',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:onBackSelect',
        generateErrorMessage(error),
      );
    }
  };

  validateDiscountCode = async () => {
    this.context.actions.showSpinner();
    const items = [...TransactionStore.getAllItems()];

    FirebaseAnalytic.trackEvent('validateDiscountCode', 'ScanScreen', {
      items,
    });
    try {
      const response =
        await ActionsFactory.getAccountActions().validateDiscountCode(
          this.props.locationId,
          this.state.consumerRoleIdentifier?.value,
        );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'ValidateDiscountCode',
        JSON.stringify({
          locationId: this.props.locationId,
          scanCode: this.state.consumerRoleIdentifier?.value,
        }),
        JSON.stringify(response),
      );
      if (response?.statusCode === 404) {
        throw response;
      }

      FirebaseAnalytic.trackEvent(
        'validateDiscountCode response',
        'ScanScreen',
        {
          items,
          response,
        },
      );

      await this.updateCart(
        items,
        null,
        this.state.consumerRoleIdentifier?.value,
      );

      this.setState({
        unlockDiscountAvailable: true,
        changeToDefaultDiscount: false,
      });
    } catch (error) {
      this.setState({
        unlockDiscountAvailable: false,
        changeToDefaultDiscount: false,
      });

      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:validateDiscountCode',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:validateDiscountCode',
        generateErrorMessage(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  };

  addDiscountToCart = async () => {
    this.context.actions.showSpinner();
    const items = [...TransactionStore.getAllItems()];
    await ActionsFactory.getAccountActions().reloadConsumerData({
      accountId: AccountStore.getAccountId(),
      accountBalanceId: AccountStore.getAccountBalanceId(),
      email: AccountStore.getEmail(),
      userInitiated: true,
    });
    let discountCode: string;
    if (this.props.location.locationFeatures.is365PayAfterTaxPromo) {
      discountCode = PromotionTypes.AfterTaxPromo;
      TransactionActions.afterTaxPromoUpdated();
      if (
        TransactionStore.isRefreshAfterTaxPromotion() &&
        TransactionStore.isAfterTaxPromoApplied()
      ) {
        TransactionStore.afterTaxPromotionRefreshed();
      } else {
        alertSuccess(
          Localized.Success.any_applicable_promotions_will_now_apply,
          null,
          Localized.Success.discount_recognized,
        );
      }
    } else {
      discountCode =
        this.state.consumerRoleIdentifier?.value ?? this.props.discountCode;
    }

    FirebaseAnalytic.trackEvent('addDiscountToCart', 'ScanScreen', {
      items,
      discountCode,
    });

    try {
      await this.updateCart(items, null, discountCode);
      this.setState({
        unlockDiscountAvailable: true,
      });
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:HandleAddDiscountToCart',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleAddDiscountToCart',
        e.message ? e.message : e.toString(),
      );
      this.setState({
        unlockDiscountAvailable: false,
      });
    } finally {
      this.context.actions.hideSpinner();
    }
  };

  handleAddDiscountCodeClick = () => {
    try {
      this.setState({isFocused: false}, () => {
        FirebaseAnalytic.trackEvent(
          'handleAddDiscountCodeClick',
          'ScanScreen',
          {
            navigate: AppRoutes.ScanDiscountCode,
          },
        );

        NavActions.navigate(AppRoutes.ScanDiscountCode, {
          locationId: this.props.locationId,
          consumerRoleIdentifier: this.state.consumerRoleIdentifier,
          addDiscountToCart: this.addDiscountToCart,
          location: this.props.location,
          cartType: this.props.cartType,
        });
      });
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:handleAddDiscountCodeClick',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleAddDiscountCodeClick',
        e.message ? e.message : e.toString(),
      );
    }
  };

  getPayment(due: number, balance: number, balanceId: string) {
    FirebaseAnalytic.trackEvent('getPayment', 'ScanScreen', {
      balance: balance,
      amount: due,
      type: PaymentTypes.Account,
      status: PaymentStatus.Accepted,
      GMABalanceId: balanceId,
    });
    return {
      balance: balance,
      amount: due,
      type: PaymentTypes.Account,
      status: PaymentStatus.Accepted,
      GMABalanceId: balanceId,
    };
  }

  applyBalance = (balance: Balance, applied = 0) => {
    // 0.75
    const amountDue = TransactionStore.getDueAmount() - applied;

    FirebaseAnalytic.trackEvent('applyBalance', 'ScanScreen', {
      ...this.props,
      ...this.state,
      amountDue,
      applied,
      balance,
    });

    if (amountDue > 0) {
      CartStore.addPayment(
        this.getPayment(
          Math.min(amountDue, balance.balance),
          balance.balance,
          balance.id,
        ),
      );
    }
  };

  handleCheckoutPantryChargeSuccess = async (
    addFundsResponse: PaymentType,
    balance: number,
    balanceId: string,
    balanceType: string,
  ) => {
    FirebaseAnalytic.trackEvent(
      'handleCheckoutPantryChargeSuccess',
      'ScanScreen',
      {
        balance,
        balanceId,
        balanceType,
        addFundsResponse,
      },
    );
    try {
      if (balance > 0) {
        CartStore.addPayment(this.getPayment(balance, 0, balanceId));
      }

      CartStore.addPayment({
        ...addFundsResponse,
        type: PaymentTypes.Credit,
        balance: 0,
        amount: TransactionStore.getRemainingDue(balance),
      });
      await this.checkout(balanceType);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:handleCheckoutPantryChargeSuccess',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleCheckoutPantryChargeSuccess',
        generateErrorMessage(error),
      );
    }
  };

  handleCheckoutPantryWithCard = async (
    cards: Array<CreditCard>,
    balance: number,
    balanceId: string,
    balanceType: string,
  ) => {
    const defaultCard = cards[0];

    FirebaseAnalytic.trackEvent('handleCheckoutPantryWithCard', 'ScanScreen', {
      cards,
      balance,
      balanceId,
      balanceType,
    });
    try {
      const addFundsResponse =
        await ActionsFactory.getAccountActions().chargeCard(
          AccountStore.getAccountId(),
          TransactionStore.getRemainingDue(balance),
          this.props.locationId,
          AccountStore.getAccountBalanceId(),
          defaultCard?.id,
        );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'ChargeCard',
        JSON.stringify({
          accountId: AccountStore.getAccountId(),
          locationId: this.props.locationId,
          accountBalanceId: AccountStore.getAccountBalanceId(),
          tokenId: defaultCard?.id,
        }),
        JSON.stringify(addFundsResponse),
      );
      FirebaseAnalytic.trackEvent(
        'handleCheckoutPantryWithCard chargeCard',
        'ScanScreen',
        {
          ...this.props,
          ...this.state,
          cards,
          balance,
          balanceId,
          balanceType,
          addFundsResponse,
        },
      );
      if (addFundsResponse) {
        this.handleCheckoutPantryChargeSuccess(
          addFundsResponse,
          balance,
          balanceId,
          balanceType,
        );
      } else {
        alertError(Localized.Errors.error_finalizing_order);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:HandleCheckoutPantryWithCard',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:HandleCheckoutPantryWithCard',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.error_finalizing_order);
    }
  };

  cardAdded = async () => {
    try {
      await this.props.dispatch(
        fetchAccountBalanceCreditCards({
          accountId: this.props.accountId,
          accountBalanceId: this.props.defaultBalanceId,
        }),
      );

      FirebaseAnalytic.trackEvent('cardAdded', 'ScanScreen', {
        ...this.props,
        ...this.state,
      });

      NavActions.pop();
      this.handleCheckoutClick();
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:cardAdded',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:cardAdded',
        e.message ? e.message : e.toString(),
      );
    }
  };

  handleCheckoutPantryNoCards = (balance: number) => {
    this.context.actions.hideSpinner();

    FirebaseAnalytic.trackEvent('handleCheckoutPantryNoCards', 'ScanScreen', {
      marketName: this.props.marketName,
      balance,
      cardAdded: this.cardAdded,
      navigate: AppRoutes.InsufficientCredit,
    });

    NavActions.push(AppRoutes.InsufficientCredit, {
      marketName: this.props.marketName,
      balance,
      cardAdded: this.cardAdded,
    });
  };

  handleCheckoutRemainingDuePantry = async (
    balance: number,
    balanceId: string,
    balanceType: string,
  ) => {
    const cards: Array<CreditCard> = this.props.creditCards;
    FirebaseAnalytic.trackEvent(
      'handleCheckoutRemainingDuePantry',
      'ScanScreen',
      {
        cards,
        balance,
        balanceId,
        balanceType,
      },
    );
    if (TransactionStore.getRemainingDue(balance) > 0 && cards.length > 0) {
      await this.handleCheckoutPantryWithCard(
        cards,
        balance,
        balanceId,
        balanceType,
      );
    } else if (cards.length === 0) {
      this.handleCheckoutPantryNoCards(balance);
    }
  };

  getDefaultPreparationMethod = (lockerType = LockerTypes.None) => {
    try {
      const defaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );

      FirebaseAnalytic.trackEvent('getDefaultPreparationMethod', 'ScanScreen', {
        defaults,
        lockerType,
      });

      return lockerType === LockerTypes.DC &&
        defaults.selectedMethod === PreparationMethodValues.Locker
        ? PreparationMethodValues.ToGo
        : defaults.selectedMethod;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:getDefaultPreparationMethod',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:getDefaultPreparationMethod',
        generateErrorMessage(error),
      );
    }
  };

  goToPickupTimeScreen(availableTimesFromServer?: Array<TimeSlotType>) {
    const availableTimes = MenuService.getAvailableTimeSlotsFromList(
      availableTimesFromServer ??
        TransactionStore.getAvailableTimesFromServer(),
      this.props.location?.onlineOrderConfig?.kitchenSchedule,
    );
    FirebaseAnalytic.trackEvent('goToPickupTimeScreen', 'ScanScreen', {
      ...this.props,
      ...this.state,
      availableTimes,
      navigate: AppRoutes.PickupTime,
      param: {
        strings: Localized,
        location: this.props.location,
        availableTimes,
        fromCart: true,
      },
      pickUpLocation: TransactionStore.getPickupLocationName(),
      pickUpTime: TransactionStore.getPickupTime(),
    });
    NavActions.push(
      AppRoutes.PickupTime,
      {
        strings: Localized,
        location: this.props.location,
        availableTimes,
        fromCart: true,
      },
      'cart-pickup',
    );
  }

  handleTimeUnvailable(availableTimes: Array<string>) {
    try {
      const availableTimeSlots = MenuService.getTimesSlotsFromTimes(
        availableTimes,
        MenuService.getLocationDayOfWeek(),
        MenuService.getPickupDate(),
      );
      FirebaseAnalytic.trackEvent('handleTimeUnvailable', 'ScanScreen', {
        availableTimeSlots,
      });

      alertError(Localized.Errors.pickup_time_not_available, null, () =>
        this.goToPickupTimeScreen(availableTimeSlots),
      );
      TransactionActions.availableTimesUpdated(availableTimeSlots);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:handleTimeUnvailable',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleTimeUnvailable',
        generateErrorMessage(error),
      );
    }
  }

  checkout = async (balanceType: string) => {
    try {
      const defaultPreparationMethod = this.getDefaultPreparationMethod(
        AccountStore.getLocationLockerType(),
      );

      FirebaseAnalytic.trackEvent('checkout', 'ScanScreen', {
        ...this.props,
        ...this.state,
        defaultPreparationMethod,
      });
      let saleContext: PurchaseTypes;
      if (this.props.cartType === CartTypes.OrderAhead) {
        saleContext = PurchaseTypes.OrderAhead;
      } else if (this.props.cartType === CartTypes.ScanAndPay) {
        saleContext = PurchaseTypes.ScanAndPay;
      } else if (this.props.cartType === CartTypes.TouchlessCoffee) {
        saleContext = PurchaseTypes.TouchlessCoffee;
      }

      const response = await CartService.checkout(
        this.context,
        this.props.locationType,
        saleContext,
        this.state.pickupTime,
        this.state.preparationMethod,
        this.props.location && this.props.cartType !== CartTypes.ScanAndPay
          ? this.props.location.onlineOrderConfig.pickupInstruction
          : null,
        this.handleTimeUnvailable,
        this.state.orderNote,
        defaultPreparationMethod,
        balanceType,
        this.getDiscountCode(),
      );

      FirebaseAnalytic.trackEvent('checkout response', 'ScanScreen', {
        ...this.props,
        ...this.state,
        defaultPreparationMethod,
        response,
      });

      await TagManager.updateDynamicTags('purchase_completed');
      this.setState({checkoutSuccess: true});

      //Link location if order is placed from nearby location (Geolocation).
      if (
        !AccountStore.getLinkedLocations().some(
          (loc) => loc.id === this.props.locationId,
        ) &&
        AccountStore.getNearbyLocations().some(
          (loc) => loc.id === this.props.locationId,
        )
      ) {
        ActionsFactory.getAccountActions().addLocationLink(
          AccountStore.getAccountId(),
          this.props.locationId,
        );
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:checkout',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:checkout',
        generateErrorMessage(error),
      );
    }
  };

  fundAdded = () => {
    try {
      FirebaseAnalytic.trackEvent('fundAdded', 'ScanScreen', {
        reason: Localized.Labels.add_funds,
      });

      this.internalCheckout(false);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:fundAdded',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:fundAdded',
        generateErrorMessage(error),
      );
    }
  };
  navigateToFunding = (balance: number) => {
    FirebaseAnalytic.trackEvent('navigateToFunding', 'ScanScreen', {
      remainingDue: TransactionStore.getRemainingDue(balance),
      addFundsSuccess: this.fundAdded,
      transDate: CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
    });
    try {
      this.context.actions.navigateToFunding(true, {
        remainingDue: TransactionStore.getRemainingDue(balance),
        addFundsSuccess: this.fundAdded,
        transDate: CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:navigateToFunding',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:navigateToFunding',
        generateErrorMessage(error),
      );
    }
  };

  checkoutPayrollAutoComplete = async (
    balance: number,
    balanceType: string,
  ) => {
    FirebaseAnalytic.trackEvent('checkoutPayrollAutoComplete', 'ScanScreen', {
      ...this.props,
      ...this.state,
      balance,
    });

    try {
      const remainderDue = TransactionStore.getRemainingDue(balance);
      const fundResponse = await AppApi.addFundsPayrollDeduct(
        AccountStore.getAccountId(),
        AccountStore.getAccountBalanceId(),
        remainderDue,
        CartStore.SessionStartTime.format('YYYY-MM-DD HH:mm:ss'),
        this.props.payrollIdentifierId,
        CartStore.TransactionId,
      );
      FirebaseAnalytic.trackEvent(
        'checkoutPayrollAutoComplete addFundsPayrollDeduct',
        'ScanScreen',
        {
          balance,
          remainderDue,
          fundResponse,
        },
      );
      if (fundResponse.status === 'ok') {
        await this.checkout(balanceType);
      } else if (fundResponse.statusCode !== 409) {
        this.navigateToFunding(balance);
      }
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'AddFundsPayrollDeduct',
        JSON.stringify({
          accountId: AccountStore.getAccountId(),
          accountBalanceId: AccountStore.getAccountBalanceId(),
          cartStoreTransId: CartStore.TransactionId,
          remainderDue,
        }),
        JSON.stringify(fundResponse),
      );
    } catch (error) {
      const reason = error.message ?? error.toString();
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:CheckoutPayrollAutoComplete',
        reason,
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:CheckoutPayrollAutoComplete',
        reason,
      );
      if (reason !== 'Duplicate entry') {
        this.navigateToFunding(balance);
      }
    }
  };

  handleRemainingDue = async (
    balance: number,
    balanceId: string,
    balanceType: string,
  ) => {
    FirebaseAnalytic.trackEvent('handleRemainingDue', 'ScanScreen', {
      buildType: Settings.buildType,
      balance,
      balanceId,
      balanceType,
    });
    try {
      if (Settings.buildType === BuildTypeConstants.pantry) {
        await this.handleCheckoutRemainingDuePantry(
          balance,
          balanceId,
          balanceType,
        );
      } else if (
        this.props.isPayrollAvailable &&
        AccountStore.getPayrollAutoComplete()
      ) {
        await this.checkoutPayrollAutoComplete(balance, balanceType);
      } else {
        this.navigateToFunding(balance);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:handleRemainingDue',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:handleRemainingDue',
        generateErrorMessage(error),
      );
    }
  };

  internalCheckout = async (fetchBalance = true) => {
    Events.Scan.trackEvent('Start internalCheckout', {
      accountId: this.props.accountId,
    });
    this.context.actions.showSpinner(`${Localized.Labels.processing_sale}...`);
    this.setState({checkingOut: true});

    FirebaseAnalytic.trackEvent('internalCheckout', 'ScanScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      let latestBalances = this.props.balances;
      if (fetchBalance) {
        // Get the most-recent balances
        latestBalances = await this.props
          .dispatch(fetchAccountBalances(this.props.accountId))
          .unwrap();
      }

      // Make sure there aren't any lingering payments from a previous failed attempt.
      CartStore.clearPayments();

      // subsidy => $8.50
      const subsidyBalance = latestBalances.find(
        (balance) => balance.isSubsidy,
      );
      let remainingDue = TransactionStore.getDueAmount();

      FirebaseAnalytic.trackEvent(
        'internalCheckout latestBalances',
        'ScanScreen',
        {
          latestBalances,
          subsidyBalance,
        },
      );

      if (
        subsidyBalance &&
        subsidyBalance.balance &&
        subsidyBalance.balance > 0 &&
        !subsidyBalance.isDisabled
      ) {
        this.applyBalance(subsidyBalance);
        remainingDue -= subsidyBalance.balance;
      }

      const defaultBalance = latestBalances.find(
        (balance) => balance.isDefault,
      );

      this.applyBalance(
        defaultBalance,
        TransactionStore.getDueAmount() - remainingDue,
      );
      remainingDue -= defaultBalance.balance;

      let balanceType = null;
      if (
        subsidyBalance === undefined ||
        subsidyBalance?.isDisabled ||
        subsidyBalance?.balance === 0 ||
        TransactionStore.getDueAmount() - subsidyBalance?.balance ===
          defaultBalance.balance
      ) {
        balanceType = BalanceTypes.Default;
      } else if (
        subsidyBalance &&
        subsidyBalance.balance >= TransactionStore.getDueAmount()
      ) {
        balanceType = BalanceTypes.TopOff;
      } else {
        balanceType = BalanceTypes.Split;
      }

      // due to issues with floating point values
      if (remainingDue >= 0.01) {
        await this.handleRemainingDue(
          TransactionStore.getDueAmount() - remainingDue,
          defaultBalance.id,
          balanceType,
        );
      } else if (this.props.cartType === CartTypes.TouchlessCoffee) {
        NavActions.push(AppRoutes.BunnDispense, {
          locationName: this.props.marketName,
        });
      } else {
        await this.checkout(balanceType);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ScanScreen:InternalCheckout',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreen:InternalCheckout',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.error_finalizing_order, guid, () =>
        this.setState({checkingOut: false}),
      );
    } finally {
      this.context.actions.hideSpinner();
      this.setState({checkingOut: false});
    }
  };

  stopScanning = () => {
    if (this.scanArea) {
      FirebaseAnalytic.trackEvent('stopScanning', 'ScanScreen', {
        ...this.props,
        ...this.state,
      });

      this.scanArea.stopScanning();
    }
  };
  handleCheckoutClick = async () => {
    FirebaseAnalytic.trackEvent('handleCheckoutClick', 'ScanScreen', {
      pickUpLocation: TransactionStore.getPickupLocationName(),
      pickUpTime: TransactionStore.getPickupTime(),
    });

    if (this.props.cartType === CartTypes.OrderAhead) {
      const pickupTime = TransactionStore.getPickupTime();
      const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
      const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
      const now = moment()
        .add(leadTime, 'minutes')
        .add(timeZoneOffsetMinutes, 'minutes');
      if (!moment(pickupTime.date).isAfter(now)) {
        try {
          this.context.actions.showSpinner();
          const availableTimes = await MenuActions.getAvailableTimeSlots(
            this.props.location,
            TransactionStore.getPickupLocationId(),
          );
          alertError(
            Localized.Errors.pickup_time_not_available,
            undefined,
            () => {
              TransactionActions.shoppingCartTransactionsCleared();
              if (Settings.isNewUI()) {
                NavActions.replace(AppRoutes.NewPickupTime, {
                  location: this.props.location,
                  availableTimes: MenuService.getAvailableTimeSlotsFromList(
                    availableTimes,
                    this.props.location.onlineOrderConfig.kitchenSchedule,
                  ),
                  selectedPickupLocation: TransactionStore.getPickupLocation(),
                });
              } else {
                NavActions.replace(AppRoutes.PickupTime, {
                  strings: Localized,
                  location: this.props.location,
                  availableTimes,
                });
              }
            },
          );
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'ScanScreen:PickupTime',
            generateErrorMessage(error),
            guid,
          );
          Events.Error.trackEvent(
            'Exception',
            'ScanScreen:PickupTime',
            generateErrorMessage(error),
            guid,
          );
        } finally {
          this.context.actions.hideSpinner();
        }
      } else {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        if (availableTimes && availableTimes.length > 0) {
          const isTimeSlotPresent = availableTimes.some(
            (obj) =>
              obj.timeString === pickupTime.timeString &&
              moment(pickupTime.date).isSame(moment(obj.date), 'day'),
          );
          if (moment(pickupTime.date).isAfter(now) && isTimeSlotPresent) {
            try {
              this.context.actions.hideSpinner();
              const allCartItemsAvailable =
                this.props.cartType === CartTypes.OrderAhead
                  ? await CartService.checkCartForZeroInventory(
                      this.context,
                      this.props.locationId,
                      this.state.displayItems,
                    )
                  : true;

              if (allCartItemsAvailable) {
                if (this.state.checkout && !this.state.checkingOut) {
                  this.stopScanning();
                  this.internalCheckout();
                }
              } else {
                alertError(
                  Localized.Errors.sold_out_remove_item,
                  null,
                  undefined,
                  Localized.Labels.sold_out,
                );
              }
            } catch (error) {
              const guid = await uuid.getRandomUUID();
              CrashlyticsEvents.log(
                'Exception',
                'ScanScreen:HandleCheckoutClick',
                generateErrorMessage(error),
                guid,
              );
              Events.Error.trackEvent(
                'Exception',
                'ScanScreen:HandleCheckoutClick',
                generateErrorMessage(error),
                guid,
              );
              alertError(Localized.Errors.error_finalizing_order, guid);
            }
          } else {
            this.context.actions.hideSpinner();
            alertError(
              Localized.Errors.order_limit_reached_for_pickup_time,
              undefined,
              () => {
                TransactionActions.shoppingCartTransactionsCleared();
                if (Settings.isNewUI()) {
                  NavActions.replace(AppRoutes.NewPickupTime, {
                    location: this.props.location,
                    availableTimes: MenuService.getAvailableTimeSlotsFromList(
                      availableTimes,
                      this.props.location.onlineOrderConfig.kitchenSchedule,
                    ),
                    selectedPickupLocation:
                      TransactionStore.getPickupLocation(),
                  });
                } else {
                  NavActions.replace(AppRoutes.PickupTime, {
                    strings: Localized,
                    location: this.props.location,
                    availableTimes,
                  });
                }
              },
            );
          }
        } else {
          this.context.actions.hideSpinner();
          const guid = await uuid.getRandomUUID();
          alertError(Localized.Errors.error_finalizing_order, guid);
        }
      }
    } else {
      try {
        if (this.state.checkout && !this.state.checkingOut) {
          this.stopScanning();
          this.internalCheckout();
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ScanScreen:HandleCheckoutClick',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'ScanScreen:HandleCheckoutClick',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.error_finalizing_order, guid);
      }
    }
  };

  /** save discount via manual entry */
  async saveDiscountCode(discountCode: string | null) {
    try {
      this.context.actions.showSpinner();
      FirebaseAnalytic.trackEvent('saveDiscountCode', 'ScanScreenNewUI', {
        ...this.props,
      });
      const discountResponse =
        await ActionsFactory.getAccountActions().validateDiscountCode(
          this.props.locationId,
          discountCode,
        );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'ValidateDiscountCode',
        JSON.stringify(this.props.locationId),
        JSON.stringify(discountResponse),
      );
      //api returns success statusCode = undefined
      if (discountResponse?.statusCode !== undefined) {
        throw discountResponse.message;
      }
      if (this.state.consumerRoleIdentifier?.value) {
        const deleteDiscountResponse =
          await ActionsFactory.getAccountActions().deleteAssociatedDiscountCode(
            this.state.consumerRoleIdentifier?.accountIdentifierId,
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'DeleteAssociatedDiscountCode',
          JSON.stringify({
            IdentifierID:
              this.state.consumerRoleIdentifier?.accountIdentifierId,
          }),
          JSON.stringify(deleteDiscountResponse),
        );
        if (
          deleteDiscountResponse &&
          typeof deleteDiscountResponse === 'string' &&
          deleteDiscountResponse.includes('Error')
        ) {
          throw new Error(`Delete API failed: ${deleteDiscountResponse}`);
        }
      }
      const associateDiscountresponse =
        await ActionsFactory.getAccountActions().associateDiscountCode(
          discountCode,
        );
      this.setState({
        discountCodeSuccess: true,
        changeToDefaultDiscount: false,
      });
      this.addDiscountToCart();
      FirebaseAnalytic.trackEvent('saveDiscountCode', 'ScanScreenNewUI', {
        ...this.props,
        associateDiscountresponse,
      });

      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });
    } catch (error) {
      this.setState({
        discountCodeError: true,
      });
      ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });

      CrashlyticsEvents.log(
        'Exception',
        'ScanScreenNewUI:saveDiscountCode',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ScanScreenNewUI:onSave',
        generateErrorMessage(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  /** revert back to textinput and apply btn*/
  onChangeBtnPress = async () => {
    this.setState({
      changeToDefaultDiscount: true,
      discountCodeError: false,
    });
  };

  goToPickupLocationScreen() {
    const pickupLocationsProduct = MenuService.getPickupLocations();
    FirebaseAnalytic.trackEvent('goToPickupLocationScreen', 'ScanScreen', {
      ...this.props,
      ...this.state,
      navigate: Settings.isNewUI()
        ? AppRoutes.NewPickupLocation
        : AppRoutes.PickupLocation,
      param: {
        strings: Localized,
        location: this.props.location,
        fromCart: true,
        pickupLocationsProduct,
        pickUpLocation: TransactionStore.getPickupLocationName(),
        pickUpTime: TransactionStore.getPickupTime(),
      },
    });
    if (Settings.isNewUI()) {
      NavActions.push(
        AppRoutes.NewPickupLocation,
        {
          strings: Localized,
          location: this.props.location,
          fromCart: true,
          pickupLocationsProduct,
        },
        'cart-pickup-location',
      );
    } else {
      NavActions.push(
        AppRoutes.PickupLocation,
        {
          strings: Localized,
          location: this.props.location,
          fromCart: true,
          pickupLocationsProduct,
        },
        'cart-pickup-location',
      );
    }
  }

  getPickupLocationComponent(): React.ReactNode | null {
    let pickupLocation = null;
    const pickupLocationName = TransactionStore.getPickupLocationName();
    const address = Util.getLocationAddress(this.props.location);
    const fullPickupAddress = address
      ? pickupLocationName + ', ' + address
      : pickupLocationName;
    if (pickupLocationName) {
      pickupLocation = (
        <View style={styles.pickupRow}>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            numberOfLines={2}
            accessibilityLabel={`Pickup at ${pickupLocationName}`}
            accessibilityRole="text"
            aria-label={`Pickup at ${pickupLocationName}, text`}
            style={styles.pickupText}
          >
            {fullPickupAddress}
          </Text>
          <AVTouchableOpacity
            accessible={true}
            accessibilityLabel={Localized.Labels.change_pickup_location}
            accessibilityRole="link"
            accessibilityHint="Double tap to select a new pickup location"
            aria-label={Localized.Labels.change_pickup_location}
            role="link"
            style={styles.pickupChange}
            onPress={this.goToPickupLocationScreen}
          >
            <View
              style={{
                alignSelf: 'flex-end',
                borderBottomColor: Styles.Colors.PayNew.primary01,
                borderBottomWidth: 2,
              }}
            >
              <Text
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                style={styles.pickupChangeText}
              >
                {Localized.Labels.change}
              </Text>
            </View>
          </AVTouchableOpacity>
        </View>
      );
    }

    return pickupLocation;
  }

  getOrderAheadEmptyScreen(): React.ReactNode {
    return (
      <ListCartEmpty
        cartType={this.props.cartType}
        handleGoBack={() => NavActions.pop()}
      />
    );
  }

  getOrderAheadTopView(): React.ReactNode {
    const pickupLocation = this.getPickupLocationComponent();
    return (
      <View style={styles.locationContainer}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          style={styles.payYourBalance}
        >
          {`${Localized.Labels.your_balance}: `}
          <AVFormattedCurrency
            testID={'balanceFormattedCurrency'}
            accessibilityLabel={
              AccountStore.getAccountBalance()
                ? `$${AccountStore.getAccountBalance().toString()}`
                : '0'
            }
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            style={styles.payYourBalance}
            value={getTotalDisplayAmount(this.props.balances)}
            currency={AccountStore.getCurrency()}
          />
        </AVText>
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          style={styles.pickupAt}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.pickup_at}
        </Text>
        {pickupLocation}
        <View style={[styles.pickupRow, styles.pickupTimeRow]}>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            aria-label={`Pickup time is at ${this.state.pickupTime?.time}, text`}
            accessibilityLabel={`Pickup time is at ${this.state.pickupTime?.time}`}
            accessibilityRole="text"
            style={styles.pickupText}
          >
            {this.state.pickupTime?.time}
          </Text>
        </View>
        {this.getPreparationArea()}
      </View>
    );
  }

  getPreparationArea(): React.ReactNode | null {
    let preparationArea = null;

    if (this.state.displayItems && this.state.displayItems.length > 0) {
      preparationArea = (
        <PreparationMethods
          onMethodSelected={this.onPreparationMethodSelected}
          selectedMethod={this.state.preparationMethod}
          togoAvailable={this.state.togoAvailable}
          dineInAvailable={this.state.dineInAvailable}
          lockerAvailable={this.state.lockerAvailable}
        />
      );
    }

    return preparationArea;
  }

  onPreparationMethodSelected(method: PreparationMethodValues) {
    FirebaseAnalytic.trackEvent('onPreparationMethodSelected', 'ScanScreen', {
      ...this.props,
      ...this.state,
      method,
      pickUpLocation: TransactionStore.getPickupLocationName(),
      pickUpTime: TransactionStore.getPickupTime(),
    });
    this.setState(
      {
        preparationMethod: method,
      },
      () => {
        const items = [...TransactionStore.getAllItems()];
        if (items.length > 0) {
          this.updateCart(items, null, this.getDiscountCode());
        }
      },
    );
  }

  getTopView(): React.ReactNode | null {
    if (this.props.cartType === CartTypes.ScanAndPay && this.state.isFocused) {
      return (
        <View>
          <SearchAndBalanceAmount
            onPress={() => {
              NavActions.navigate(AppRoutes.SearchProducts, {
                marketName: this.props.marketName,
                displayName: this.props.location.displayName,
                locationId: this.props.locationId,
                itemSelected: this.barcodeScanned,
              });
            }}
          />

          <View style={styles.scanAreaView}>
            <ScanArea
              ref={(scanArea) => (this.scanArea = scanArea)}
              barcodeScanned={this.barcodeScanned}
              promotionDescription={this.props.promotionDescription}
              navigation={this.props.navigation}
            />
          </View>
        </View>
      );
    }

    if (
      this.props.cartType === CartTypes.TouchlessCoffee ||
      !this.props.location
    ) {
      return null;
    }
    return this.getOrderAheadTopView();
  }

  renderCart() {
    const key = this.state.isFocused ? 'focused-true' : 'focused-false';
    if (
      this.state.displayItems.length == 0 &&
      !this.state.checkingOut &&
      this.props.cartType !== CartTypes.ScanAndPay
    ) {
      return this.getOrderAheadEmptyScreen();
    } else {
      return (
        <View style={styles.container}>
          <ScrollView
            bounces={false}
            showsVerticalScrollIndicator={false}
            automaticallyAdjustContentInsets={false}
          >
            {this.getTopView()}

            <ListScanItems
              key={key}
              trashClick={this.handleConfirmTrashClick}
              editClicked={this.handleEditItemClicked}
              balance={this.state.balance}
              hideYourItems={false}
              showGrandTotal={false}
              checkoutDone={this.state.checkingOut}
              cartType={this.props.cartType}
              onAddItem={this.onBackSelect}
              locationId={this.props.locationId}
              preparationMethod={this.state.preparationMethod}
              hasConsumerRoles={this.state.hasConsumerRoles}
              consumerRoleButton={this.state.consumerRoleButton}
              validateDiscountCode={this.validateDiscountCode}
              addDiscountToCart={this.addDiscountToCart}
              location={this.props.location}
              handleCheckoutClick={() => this.handleCheckoutClick()}
              discountCodeButtonAction={this.handleAddDiscountCodeClick}
              saveDiscountCode={this.saveDiscountCode}
              discountCodeError={this.state.discountCodeError}
              discountCodeSuccess={this.state.discountCodeSuccess}
              changeToDefaultDiscount={this.state.changeToDefaultDiscount}
              onChangeBtnPress={this.onChangeBtnPress}
            />
          </ScrollView>
        </View>
      );
    }
  }

  async backSubHeaderSelect(cartType: CartTypes) {
    if (cartType === CartTypes.OrderAhead) {
      this.setState({orderAheadBackSelected: true});
    }
    NavActions.pop();
  }

  render() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );

    return (
      <BackSubheader
        previousRoute={previousRoute}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
        title={this.pageTitle}
        onBackSelect={() => this.backSubHeaderSelect(this.props.cartType)}
        pop={false}
      >
        {this.renderCart()}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  scanAreaView: {
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
    borderRadius: 8,
  },
  container: {
    flex: 1,
  },
  payYourBalance: {
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.tabBarTextColor,
    position: 'relative',
    width: '100%',
  },
  pickupText: {
    fontSize: Styles.Fonts.f7,
    color: Styles.darkColor,
    width: '65%',
  },
  pickupAt: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    paddingTop: Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
  },
  locationContainer: {
    alignSelf: 'stretch',
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
  },
  locationAddress: {
    fontSize: Styles.Fonts.f1 + 2,
    color: Styles.tabBarTextColor,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  locationText: {
    fontSize: Styles.Fonts.f1 + 2,
    color: Styles.tabBarTextColor,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  pickupRow: {
    marginTop: Styles.Spacing.m1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  pickupTimeRow: {
    marginTop: Styles.Spacing.m2,
  },
  pickupChange: {
    flex: 1,
    marginLeft: Styles.Spacing.m2,
  },
  pickupChangeText: {
    color: Styles.Colors.PayNew.primary01,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f7,
  },
});

const ConnectedScanScreenNewUI = connect(
  (state: RootState) => ({
    isPayrollAvailable: state.account.account.isPayrollAvailable,
    payrollIdentifierId: state.account.account.payrollIdentifier?.id,
    creditCards: state.account.creditCards,
    accountId: state.account.account.id,
    defaultBalanceId: state.account.account.defaultBalance?.id,
    balances: state.account.account.balances,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(ScanScreenNewUI);

export default withForwardedNavigationParams()(
  withGlobalize(ConnectedScanScreenNewUI),
);
