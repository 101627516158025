import React, {useEffect} from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import {IsConnectedProps} from 'src/types/Screen';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import BaseScreen from 'src/components/screens/BaseScreen';
import ScanAndPayLocationsList from 'src/components/elements/home/ScanAndPayLocationsList';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';

type ChooseLocationScreenProps = {
  locations: CampusLocationType[];
  onLocationClick: (machine, autoAdvanced?: boolean) => Promise<void>;
} & IsConnectedProps;

const ChooseLocationScreen = (props: ChooseLocationScreenProps) => {
  useEffect(() => {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ChooseLocationScreen', {
      ...props,
    });
  }, []);

  return (
    <BaseScreen
      title={Localized.Labels.choose_a_location}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the Home screen`}
    >
      <ScanAndPayLocationsList
        locations={props.locations}
        isConnected={true}
        onLocationClick={props.onLocationClick}
        scrollEnabled={true}
      />
    </BaseScreen>
  );
};

export default withForwardedNavigationParams()(
  withIsConnected(ChooseLocationScreen),
);
