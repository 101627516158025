import React from 'react';
import {View, StyleSheet} from 'react-native';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import QRIconSVG from '../../img/svg/QRIconSVG';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import DiscountTag from 'src/components/img/svg/DiscountTag';
import ErrorIcon from 'src/components/img/svg/ErrorIcon';
import Util from 'src/Util';
import Settings from 'src/Settings';

type DiscountCodeProps = {
  location?: {
    locationFeatures?: {
      is365PayAfterTaxPromo?: boolean;
    };
  };
  consumerRoleButton?: string;
  addDiscountToCart?: () => void;
  hasConsumerRoles?: boolean;
  consumerRoleIdentifier?: {
    value?: string;
  };
  validateDiscountCode?: () => void;
  discountCodeButtonAction?: () => void;
  saveDiscountCode?: (discountCode?: string) => void;
  showErrorMsg?: boolean;
  showSuccessMsg?: boolean;
  changeToDefaultDiscount?: boolean;
  onChangeBtnPress?: () => void;
};

const getActiveColor = (showErrorMsg: boolean) => {
  return showErrorMsg
    ? Styles.Colors.PayNew.error
    : Styles.Colors.PayNew.darkHuesBase04;
};

const getBorderColor = (showErrorMsg: boolean) => {
  return showErrorMsg
    ? Styles.Colors.PayNew.error
    : Styles.Colors.PayNew.neutralHuesBase09;
};

const getRightSymbol = (showErrorMsg: boolean) => {
  return showErrorMsg ? (
    <ErrorIcon color={Styles.Colors.PayNew.error} />
  ) : (
    <QRIconSVG />
  );
};
const DiscountCode: React.FC<DiscountCodeProps> = ({
  location,
  consumerRoleButton,
  addDiscountToCart,
  hasConsumerRoles,
  consumerRoleIdentifier,
  discountCodeButtonAction,
  showErrorMsg,
  saveDiscountCode,
  showSuccessMsg,
  changeToDefaultDiscount,
  onChangeBtnPress,
}) => {
  const [saveCode, setSaveCode] = React.useState(
    consumerRoleIdentifier?.value ?? null,
  );
  const handleTextChange = (text) => {
    setSaveCode(text);
    /** when length 0 change the textinput to default */
    if (text.length === 0) {
      onChangeBtnPress();
    }
  };
  const discountLabel =
    showSuccessMsg && consumerRoleIdentifier?.value
      ? Localized.Labels.discount_code_saved
      : Localized.Labels.saved_discount_from_last_visit;
  if (
    location?.locationFeatures?.is365PayAfterTaxPromo &&
    !Settings.is365Pay()
  ) {
    return (
      <View style={styles.afterTaxButton}>
        <RoundedButton
          accessibilityHint={consumerRoleButton}
          buttonType={ButtonType.normal}
          onPress={() => addDiscountToCart?.()}
          accessible={true}
          accessibilityLabel={consumerRoleButton}
          accessibilityRole="button"
          aria-label={consumerRoleButton}
          role="button"
          text={consumerRoleButton || ''}
        />
      </View>
    );
  }

  if (hasConsumerRoles) {
    if (consumerRoleIdentifier?.value && !changeToDefaultDiscount) {
      return (
        <View style={styles.discountApplied}>
          <View style={styles.discountRow}>
            <View style={styles.appliedDiscountView}>
              <DiscountTag style={{marginTop: 2, alignSelf: 'center'}} />
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessibilityLabel={consumerRoleIdentifier?.value}
                aria-label={consumerRoleIdentifier?.value}
                style={styles.discountText}
              >
                {Util.removeTrailingEscapeSequencesAndSpaces(
                  consumerRoleIdentifier?.value,
                )}
              </AVText>
            </View>

            <RoundedAppButton
              buttonType={RoundedAppButtonType.Outline}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessibilityLabelValue={Localized.Buttons.change}
              aria-label={Localized.Buttons.change}
              onPress={onChangeBtnPress}
              titleText={Localized.Buttons.change}
              buttonViewStyle={styles.buttonStyle}
              buttonContainerStyle={{
                marginLeft: Styles.Spacing.m3,
                marginTop: 4,
              }}
            />
          </View>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessibilityLabel={consumerRoleIdentifier?.value}
            aria-label={consumerRoleIdentifier?.value}
            style={styles.savedText}
          >
            {discountLabel}
          </AVText>
        </View>
      );
    }

    return (
      <View style={styles.wrapper}>
        <View style={{flex: 1, marginRight: Styles.Spacing.m3}}>
          <CustomTextInput
            label={Util.secondLetterCapitalize(Localized.Labels.discount_code)}
            rightIcon={getRightSymbol(showErrorMsg)}
            rightIconPressed={!showErrorMsg ? discountCodeButtonAction : null}
            containerStyle={{
              marginTop: 0,
            }}
            value={saveCode}
            activeOutlineColor={getActiveColor(showErrorMsg)}
            outlineStyle={{
              borderColor: getBorderColor(showErrorMsg),
              borderRadius: 8,
              borderWidth: 1,
            }}
            fontSize={Styles.Fonts.f7}
            hasErrorMsg={showErrorMsg}
            errorMsg={Localized.Errors.sorry_we_couldnt_recognize_that_code}
            errorTextStyle={styles.errorText}
            onChangeText={handleTextChange}
            showShadow={true}
          />
        </View>
        <View>
          <RoundedAppButton
            buttonType={RoundedAppButtonType.Outline}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabelValue={Localized.Buttons.apply}
            aria-label={Localized.Buttons.apply}
            onPress={() => saveDiscountCode(saveCode)}
            titleText={Localized.Buttons.apply}
            buttonViewStyle={styles.buttonStyle}
            buttonContainerStyle={{
              alignSelf: 'center',
              marginTop: Styles.Spacing.m2 + 1,
            }}
          />
        </View>
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  wrapper: {
    marginHorizontal: Styles.Spacing.m3,
    flex: 1,
    flexDirection: 'row',
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m2,
    justifyContent: 'space-between',
    position: 'relative',
  },
  discountApplied: {
    flex: 1,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
  },
  discountRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  appliedDiscountView: {
    flexDirection: 'row',
    alignSelf: 'center',
    paddingHorizontal: Styles.Spacing.m2 + Styles.Spacing.m1,
    backgroundColor: 'rgba(0, 109, 255, 0.15)',
    maxWidth: '65%',
    paddingVertical: Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m1,
  },
  discountText: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.Colors.PayNew.primary01,
    fontWeight: '400',
    marginLeft: Styles.Spacing.m2,
    paddingRight: Styles.Spacing.m2 + +Styles.Spacing.m1,
  },
  buttonStyle: {
    height: Styles.Spacing.m4 + Styles.Spacing.m1 + 2,
    borderWidth: 2,
    paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    backgroundColor: Styles.Colors.PayNew.white01,
  },
  savedText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.Colors.PayNew.primary01,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    marginTop: Styles.Spacing.m2 + 2,
  },
  errorText: {
    fontSize: Styles.Fonts.f8,
    color: Styles.Colors.PayNew.error,
    alignSelf: 'center',
    marginRight: Styles.Spacing.m3,
    marginLeft: Styles.Spacing.m3,
  },
  afterTaxButton: {
    marginTop: 10,
  },
});

export default DiscountCode;
