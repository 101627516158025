import RevolveDescriptor from './wallet-revolve';
import RedesignDescriptor from './wallet-redesign';
import CanteenDescriptor from './wallet-canteen';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export interface AccountFundingDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  title?: string;
  onClose: () => void;
}
