import Styles from '../../Styles';
import {Image} from 'react-native';
import FocusAwareStatusBar from 'src/components/elements/FocusAwareStatusBar';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import UIManager from 'src/components/elements/ui/UIManager';
import Localized from 'src/constants/AppStrings';

export function linkExistingAccountStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
      padding: Styles.Spacing.m3,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    title: {
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.darkColor,
      fontWeight: '700',
    },
  };
}

export function renderPointsToNextRewardLbl() {
  return <></>;
}

export function actionLinkText(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    actionLinkText: {
      color: '#066DFF',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textDecorationLine: 'underline',
    },
  };
}

export function getWelcomeStatusBarStyleDescriptor(): string {
  return Styles.primaryColor;
}

export function getWelcomeViewBckGrndStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    view: {
      backgroundColor: Styles.darkBlueRevolveColor,
      flex: 1,
    },
    overlay: {
      width: 393,
      height: 510,
    },
  };
}

export function baseAccountContainerDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      backgroundColor: Styles.tabBarBackgroundColor,
    },
  };
}

export function getWelcomeOverLayImage() {
  return (
    <Image
      source={require('src/components/img/welcomedotoverlay.png')}
      style={getWelcomeViewBckGrndStyleDescriptor()['overlay']}
      resizeMode="cover"
    />
  );
}

export function getFocusAwareStatusBar() {
  return (
    <FocusAwareStatusBar
      barStyle={UIManager.getLoginStatusBarStyle()}
      backgroundColor={getWelcomeStatusBarStyleDescriptor()}
    />
  );
}

export function getOrTextDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    orText: {
      flex: 0.5,
      textAlign: 'center',
      fontSize: Styles.Fonts.f1,
      color: Styles.white,
      textTransform: 'uppercase',
    },
    solidLine: {
      backgroundColor: Styles.white,
    },
  };
}

export function getLineContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    lineContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: Styles.Spacing.m3 + 2,
    },
  };
}

export function getVersionTextDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    versionText: {
      color: Styles.white,
      marginLeft: Styles.Spacing.m4 + Styles.Spacing.m3,
    },
  };
}

export function getLogoContainer(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    logoContainer: {
      alignSelf: 'center',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      top: -190,
      flex: 1,
    },
  };
}

export function getWelcomeButtonStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    topButton: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
      borderWidth: 2,
    },
  };
}

export function getContainer(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      marginTop: -120,
    },
  };
}
export function renderYourBalanceRewardLbl() {
  return <></>;
}

export function locationCodebgColor(): string {
  return Styles.darkGreenRevolve;
}

export function getOnBoardingButton(): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.normal}
      accessible={true}
      accessibilityLabel="Next"
      aria-label="Next"
      onPress={this.handleClick}
      text={Localized.Buttons.next}
      textStyle={{paddingHorizontal: Styles.Spacing.m2}}
      color={Styles.Colors.PayNew.primary01}
      containerStyle={{
        display: 'flex',
        width: 315,
        padding: 14,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Styles.white,
      }}
    />
  );
}

export function getBodyTextStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    bodyText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 18,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontStyle: 'normal',
    },
  };
}

export default {
  linkExistingAccountStyleDescriptor,
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  baseAccountContainerDescriptor,
  getOrTextDescriptor,
  getVersionTextDescriptor,
  getLogoContainer,
  getContainer,
  getWelcomeOverLayImage,
  getFocusAwareStatusBar,
  renderYourBalanceRewardLbl,
  getLineContainer,
  getWelcomeButtonStyles,
  renderPointsToNextRewardLbl,
  locationCodebgColor,
  getOnBoardingButton,
  actionLinkText,
  getBodyTextStyles,
};
