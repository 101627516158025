import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import BackSubheader from '../../elements/BackSubheader';
import ItemAddedFeedback from '../../elements/cart/ItemAddedFeedback';
import Styles from '../../Styles';
import {
  Keyboard,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import UIManager from '../../elements/ui/UIManager';
import NavActions from 'src/actions/NavActions';
import ScreenContext from '../../ScreenContext';
import Localized from 'src/constants/AppStrings';
import AVText from '../../elements/AVText';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import {isValidEmail} from 'src/services/ValidatorService';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import StickerList from '../../elements/sendSnack/StickerList';
import AccountStore from 'src/stores/AccountStore';
import Util, {getPreviousRouteName} from 'src/Util';
import AppRoutes from 'src/AppRoutes';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {
  sendSnackToUser,
  SendSnackToUserParams,
} from '../../../redux/thunks/snackThunks';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import EmailInput from 'src/components/elements/sendSnack/EmailInput';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';

type SendSnackScreenProp = WithGlobalizeProps & {
  sendSnackToUser(params: SendSnackToUserParams): {
    [index: string]: number | string | {[index: string]: string | number};
  };
  navigation?: NavigationProp<SendSnackScreen>;
  displayBalances?: number;
};
type SendSnackScreenState = {
  amount: string;
  description: string;
  email: string;
  isPrivate: boolean;
  stickerId: number;
  amountError?: string;
  mailError?: string;
  isSnackDataValid: boolean;
  isGiftTextInputFocused: boolean;
  previousRoute: string;
  balance?: number;
};

const dollarSymbol = '$';

class SendSnackScreen extends React.Component<
  SendSnackScreenProp,
  SendSnackScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      description: '',
      email: '',
      isPrivate: false,
      stickerId: 0,
      amountError: '',
      mailError: '',
      isSnackDataValid: false,
      isGiftTextInputFocused: false,
      previousRoute: '',
      balance: this.props.displayBalances ?? 0,
    };
    this.onBackSelect = this.onBackSelect.bind(this);
    this.getRightView = this.getRightView.bind(this);
    this.getBalanceContainer = this.getBalanceContainer.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.onChangeGiftAmount = this.onChangeGiftAmount.bind(this);
    this.validateGiftAmount = this.validateGiftAmount.bind(this);
    this.onSendSnack = this.onSendSnack.bind(this);
    this.validateSnackData = this.validateSnackData.bind(this);
    this.handleSnackBalanceCovers = this.handleSnackBalanceCovers.bind(this);
    this.navigateToFunding = this.navigateToFunding.bind(this);
    this.onContactSelected = this.onContactSelected.bind(this);
    this.getGiftAmountTextValue = this.getGiftAmountTextValue.bind(this);
    this.checkErrorMessage = this.checkErrorMessage.bind(this);
    this.checkDesktopBgColor = this.checkDesktopBgColor.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  componentDidUpdate(prevProps: Readonly<SendSnackScreenProp>): void {
    if (prevProps.displayBalances !== this.props.displayBalances) {
      this.setState({balance: this.props.displayBalances});
    }
  }

  getBalanceContainer(smallText: boolean) {
    return UIManager.getBalanceContainer(smallText, Localized);
  }

  getRightView() {
    if (Platform.OS === 'web') {
      return null;
    } else {
      return (
        <View style={!Settings.isNewUI && styles.rightContainer}>
          {this.getBalanceContainer(false)}
        </View>
      );
    }
  }

  onBackSelect() {
    NavActions.pop();
  }

  onChangeEmail(email: string) {
    FirebaseAnalytic.trackEvent('onChangeEmail', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.setState({
      email,
      mailError: undefined,
    });
    this.validateSnackData(
      Number(this.state.amount),
      email,
      this.state.stickerId,
    );
  }

  validateEmail() {
    if (isValidEmail(this.state.email)) {
      this.setState({
        mailError: undefined,
      });
    } else {
      this.setState({
        mailError: Localized.Errors.please_enter_the_valid_email,
      });
    }
  }

  onChangeGiftAmount(amount: string) {
    amount = amount.replace(dollarSymbol, '');
    FirebaseAnalytic.trackEvent('onChangeGiftAmount', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      amount,
    });
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState(
        {
          amount,
          amountError: undefined,
        },
        () => {
          this.validateSnackData(
            Number(this.state.amount),
            this.state.email,
            this.state.stickerId,
          );
        },
      );
    }
  }

  validateGiftAmount() {
    FirebaseAnalytic.trackEvent('validateGiftAmount', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
    });
    this.setState({
      isGiftTextInputFocused: false,
    });
    const amountValue = Number(this.state.amount);

    if (amountValue >= 1.0) {
      this.setState({
        amount: amountValue.toFixed(2).toString(),
        amountError: undefined,
      });
    } else {
      this.setState({
        amountError: Localized.Errors.minimum_snack_gift_amount,
      });
    }
  }

  getGiftAmountTextValue() {
    FirebaseAnalytic.trackEvent('getGiftAmountTextValue', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
    });
    if (this.state.amount === '' && this.state.isGiftTextInputFocused) {
      return dollarSymbol;
    } else if (this.state.amount === '') {
      return '';
    } else {
      return dollarSymbol + this.state.amount;
    }
  }

  onContactSelected(email: string) {
    FirebaseAnalytic.trackEvent('onContactSelected', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      email,
    });
    this.setState({
      email,
    });

    if (isValidEmail(email)) {
      this.setState({
        mailError: undefined,
      });
    } else {
      this.setState({
        mailError: Localized.Errors.please_enter_the_valid_email,
      });
    }

    this.validateSnackData(
      Number(this.state.amount),
      email,
      this.state.stickerId,
    );
  }

  validateSnackData(amount: number, email: string, stickerId: number) {
    const validData =
      isValidEmail(email) &&
      Number(amount) >= 1.0 &&
      ((!Settings.isRevolveAnd365Pay() && stickerId > 0) ||
        (Settings.isRevolveAnd365Pay() && stickerId === 0));

    FirebaseAnalytic.trackEvent('validateSnackData', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      validData,
      email,
      stickerId,
    });

    this.setState({
      isSnackDataValid: validData,
    });
  }

  canteenStickers() {
    return (
      <>
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.choose_a_sticker}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.choose_a_sticker}, text`}
          style={styles.chooseStickerLabel}
        >
          {Localized.Labels.choose_a_sticker}
        </AVText>
        <StickerList
          size={Styles.Heights.h8}
          onStickerSelection={(id) => {
            this.setState({
              stickerId: id,
            });
            this.validateSnackData(
              Number(this.state.amount),
              this.state.email,
              id,
            );
          }}
        />
      </>
    );
  }
  handleSnackBalanceCovers() {
    const transDate = Util.getCurrentDate();
    if (
      this.state.email.toLowerCase() === AccountStore.getEmail().toLowerCase()
    ) {
      this.setState({
        mailError:
          Localized.Errors.oops +
          ' ' +
          Localized.Errors.you_cannot_snack_to_yourself.substring(
            0,
            Localized.Errors.you_cannot_snack_to_yourself.length - 1,
          ) +
          '.',
        isSnackDataValid: false,
      });
      return;
    }

    const snackAmount = Number(this.state.amount);
    const balance = this.state.balance;
    const amountDue = snackAmount - balance;
    FirebaseAnalytic.trackEvent('handleSnackBalanceCovers', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
      snackAmount,
      balance,
      amountDue,
    });
    if (amountDue > 0) {
      this.navigateToFunding(amountDue, transDate);
    } else {
      this.onSendSnack(transDate);
    }
  }

  navigateToFunding(balance: number, transDate: string) {
    FirebaseAnalytic.trackEvent('navigateToFunding', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
      balance,
    });
    this.context.actions.navigateToFunding(true, {
      transDate,
      remainingDue: balance,
      isSnackGiftDue: true,
      addFundsSuccess: () => this.onSendSnack(transDate),
    });
  }

  yourMsgLbl() {
    return Settings.is365Pay()
      ? Util.secondLetterCapitalize('Your Message')
      : 'Your Message';
  }

  giftAmtLbl() {
    return Settings.is365Pay()
      ? Util.secondLetterCapitalize(Localized.Labels.gift_amount)
      : Localized.Labels.gift_amount;
  }

  checkErrorMessage() {
    return Settings.is365Pay()
      ? Localized.Errors.not_registed_snack_error_365
      : Localized.Errors.person_cannot_receive_snack_error;
  }

  async onSendSnack(transDate: string) {
    Keyboard.dismiss();
    FirebaseAnalytic.trackEvent('onSendSnack', 'SendSnackScreen', {
      ...this.props,
      ...this.state,
      transDate,
    });
    try {
      const data = await this.props.sendSnackToUser({
        locationId: AccountStore.getLocationId(),
        amount: Number(this.state.amount),
        description: this.state.description,
        email: this.state.email,
        isPrivate: this.state.isPrivate,
        stickerId: this.state.stickerId,
        transDate: transDate,
        context: this.context,
      });
      if (data['payload'] && data['payload']['responseCode'] === 404) {
        this.setState({
          mailError: String(data?.payload['responseMsg'])
            .toLowerCase()
            .includes('this person cannot receive snacks')
            ? this.checkErrorMessage()
            : Localized.Errors.not_enabled_snack,
          isSnackDataValid: false,
        });
      }
      if (
        !data['payload'] &&
        Settings.buildType === 'default' &&
        data['meta'] &&
        Object.keys(data['meta']).length > 0 &&
        data['meta']['requestStatus'] === 'fulfilled'
      ) {
        this.setState({
          amount: '',
          description: '',
          email: '',
          isPrivate: false,
          stickerId: 0,
          amountError: '',
          mailError: '',
          isSnackDataValid: false,
          isGiftTextInputFocused: false,
        });
      }
    } catch (error) {

    }
  }

  checkDesktopBgColor = () => {
    return Platform.OS === 'web'
      ? Styles.Colors.PayNew.neutralHuesBase11
      : null;
  };

  render() {
    const rightView = !Settings.isRevolveAnd365Pay() && this.getRightView();
    const content = (
      <KeyboardAvoidingView behavior="height" insideTab>
        <ScrollView
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View
            style={[
              styles.content,
              Styles.Style.maxWidthContainer,
              Settings.isRevolveAnd365Pay()
                ? {paddingTop: Styles.Spacing.m4}
                : null,
            ]}
          >
            {!Settings.isRevolveAnd365Pay() && this.canteenStickers()}
            {Settings.isRevolveAnd365Pay() && (
              <>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                  style={
                    Settings.is365Pay()
                      ? styles.payYourBalance
                      : styles.yourBalance
                  }
                >
                  {`${Localized.Labels.your_balance}: `}
                  <AVFormattedCurrency
                    testID={'balanceFormattedCurrency'}
                    accessibilityLabel={
                      this.state.balance
                        ? `$${this.state.balance.toString()}`
                        : '0'
                    }
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                    style={
                      Settings.is365Pay()
                        ? styles.payYourBalance
                        : styles.yourBalance
                    }
                    value={this.state.balance ?? 0}
                    currency={AccountStore.getCurrency()}
                  />
                </AVText>
                {Settings.is365Pay() && (
                  <View
                    style={{
                      alignSelf: 'flex-end',
                      top: -18,
                    }}
                  >
                    <TouchableOpacity
                      style={[styles.refillBal]}
                      onPress={() => {
                        FirebaseAnalytic.trackEvent(
                          'refillBalance',
                          'SendSnackScreen',
                          {
                            ...this.props,
                            ...this.state,
                            transDate: Util.getCurrentDate(),
                          },
                        );
                        this.context.actions.navigateToFunding(true, {
                          transDate: Util.getCurrentDate(),
                        });
                      }}
                      role="button"
                      accessibilityLabel={Localized.Labels.refill_bal}
                      aria-label={Localized.Labels.refill_bal}
                      accessibilityRole="button"
                    >
                      <AVText
                        style={styles.refillBalbtnStyle}
                        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                        adjustsFontSizeToFit={true}
                      >
                        {Localized.Labels.refill_bal}
                      </AVText>
                    </TouchableOpacity>
                  </View>
                )}
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                  style={
                    Settings.is365Pay()
                      ? styles.paySendSnackDescription
                      : styles.sendSnackDescription
                  }
                >
                  {`${Localized.Labels.send_snack_description}`}
                </AVText>

                <CustomTextInput
                  label={Util.secondLetterCapitalize(
                    Localized.Labels.email_address,
                  )}
                  value={this.state.email}
                  autoCapitalize="none"
                  keyboardType="email-address"
                  onBlur={() => this.validateEmail()}
                  returnKeyType="next"
                  maxLength={75}
                  accessible={true}
                  accessibilityLabel="Email"
                  aria-label="Email"
                  onChangeText={this.onChangeEmail}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                  showShadow={true}
                  textColor={'#111'}
                  fontSize={Styles.Fonts.f7}
                  hasErrorMsg={this.state.mailError ? true : false}
                  errorMsg={this.state.mailError}
                  errorTextStyle={{
                    fontWeight: '400',
                    fontFamily: Settings.is365Pay()
                      ? Styles.FontFamily.aeonikRegular
                      : Styles.FontFamily.figtreeRegular,
                    fontSize: 16,
                    alignContent: 'flex-start',
                    alignSelf: 'flex-start',
                  }}
                  textStyle={{
                    height: 60,
                  }}
                  outlineStyle={{
                    borderColor: this.state.mailError
                      ? Styles.Colors.PayNew.error
                      : '#CFCFCF',
                    borderRadius: 8,
                    borderWidth: this.state.mailError ? 1.5 : 1,
                    elevation: 1,
                  }}
                  labelStyle={{
                    fontSize: Styles.Spacing.m3 + 2,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontWeight: '400',
                    color: '#707070',
                  }}
                />

                <View
                  style={[
                    styles.contactContainer,
                    Settings.isRevolveAnd365Pay()
                      ? styles.emailRevolveSpacing
                      : null,
                    this.state.mailError ? {marginTop: 13} : {marginTop: 8},
                  ]}
                >
                  <RoundedButton
                    role="button"
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    buttonType={ButtonType.outline}
                    text={Localized.Labels.choose_from_contacts}
                    disabled={Platform.OS === 'web'}
                    containerStyle={[
                      styles.button,
                      styles.chooseContactBtnRevolve,
                      Settings.is365Pay() ? {borderWidth: 2} : null,
                    ]}
                    backgroundColor={
                      Settings.is365Pay() && Platform.OS !== 'web'
                        ? 'white'
                        : this.checkDesktopBgColor()
                    }
                    accessible={true}
                    textStyle={
                      Settings.is365Pay()
                        ? {
                            fontSize: 14,
                            fontWeight: '700',
                            fontFamily: Styles.FontFamily.aeonikRegular,
                            color:
                              Platform.OS == 'web'
                                ? '#707070'
                                : Styles.Colors.PayNew.primary01,
                          }
                        : styles.chooseContactTxtRevolve
                    }
                    accessibilityLabel="Choose from contacts"
                    testID="Choose from contacts"
                    onPress={() => {
                      NavActions.navigate(AppRoutes.ChooseContact, {
                        onContactSelect: (email) => {
                          this.onContactSelected(email);
                        },
                      });
                    }}
                    accessibilityRole="button"
                    color={
                      Settings.isRevolve()
                        ? Styles.darkGreenRevolve
                        : Util.checkIS365()
                    }
                    aria-label="Choose from contacts"
                  />
                </View>
              </>
            )}
            <View style={[{marginBottom: 10}]}>
              <CustomTextInput
                label={this.giftAmtLbl()}
                value={this.getGiftAmountTextValue()}
                autoCapitalize="none"
                maxLength={10}
                accessible={true}
                accessibilityLabel={this.giftAmtLbl()}
                accessibilityValue={{text: this.getGiftAmountTextValue()}}
                aria-label={this.giftAmtLbl()}
                aria-valuetext={this.getGiftAmountTextValue()}
                onChangeText={(e) =>
                  this.onChangeGiftAmount(e.replace(',', '.'))
                }
                keyboardType={Platform.OS === 'web' ? 'default' : 'numeric'}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                returnKeyType="next"
                clearButtonMode="while-editing"
                textColor={'#111'}
                onBlur={() => this.validateGiftAmount()}
                fontSize={Styles.Fonts.f7}
                outlineStyle={{
                  borderRadius: 8,
                  borderWidth: 1,
                  elevation: 1,
                  borderColor: this.state.amountError
                    ? Styles.Colors.PayNew.error
                    : '#CFCFCF',
                }}
                textStyle={{
                  height: 60,
                }}
                labelStyle={{
                  fontSize: Styles.Spacing.m3 + 2,
                  fontFamily: Styles.FontFamily.aeonikRegular,
                  fontWeight: '400',
                  color: '#707070',
                }}
                showShadow={true}
                hasErrorMsg={this.state.amountError ? true : false}
                errorMsg={this.state.amountError}
                errorTextStyle={{
                  fontWeight: '400',
                  fontFamily: Settings.is365Pay()
                    ? Styles.FontFamily.aeonikRegular
                    : Styles.FontFamily.figtreeRegular,
                  fontSize: 16,
                  alignContent: 'flex-start',
                  alignSelf: 'flex-start',
                }}
              />
            </View>
            <View style={{marginTop: Styles.Spacing.m1 - 11}}>
              <CustomTextInput
                autoCapitalize="none"
                accessible={true}
                label={this.yourMsgLbl()}
                accessibilityLabel={this.yourMsgLbl()}
                accessibilityValue={{text: this.state.description}}
                aria-label={this.yourMsgLbl()}
                aria-valuetext={this.state.description}
                value={this.state.description}
                multiline={Settings.isRevolveAnd365Pay()}
                numberOfLines={5}
                onChangeText={(e) => {
                  this.setState({
                    description: e,
                  });
                }}
                maxLength={140}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                returnKeyType="next"
                containerStyle={{
                  minHeight: 120,
                }}
                clearButtonMode="while-editing"
                textColor={'#111'}
                fontSize={Styles.Fonts.f7}
                labelStyle={{
                  fontSize: Styles.Spacing.m3 + 2,
                  fontFamily: Styles.FontFamily.aeonikRegular,
                  fontWeight: '400',
                  color: '#707070',
                }}
                outlineStyle={{
                  borderColor: '#CFCFCF',
                  borderRadius: 8,
                  borderWidth: 1,
                  elevation: 3,
                }}
                showShadow={Platform.OS === 'ios'}
              />

              <AVText
                style={
                  Settings.is365Pay()
                    ? styles.payDescriptionCountLabel
                    : styles.descriptionCountLabel
                }
              >
                {this.state.description.length + '/140'}
              </AVText>
            </View>
            {!Settings.isRevolveAnd365Pay() && (
              <>
                <View style={styles.contactContainer}>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    style={styles.toLabel}
                  >
                    {Localized.Labels.to}
                  </AVText>
                  <RoundedButton
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                    buttonType={ButtonType.outline}
                    text={Localized.Labels.choose_from_contacts}
                    containerStyle={styles.button}
                    disabled={Platform.OS === 'web'}
                    onPress={() => {
                      NavActions.navigate(AppRoutes.ChooseContact, {
                        onContactSelect: (email) => {
                          this.onContactSelected(email);
                        },
                      });
                    }}
                    accessible={true}
                    accessibilityLabel="Choose from contacts"
                    accessibilityRole="button"
                    role="button"
                    aria-label="Choose from contacts"
                  />
                </View>

                <EmailInput
                  email={this.state.email}
                  mailError={this.state.mailError}
                  onChangeEmail={this.onChangeEmail}
                  onBlur={this.validateEmail}
                  label={Localized.Labels.email_address}
                />
                <View style={styles.privateStatusContainer}>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    style={styles.privateLabel}
                  >
                    {Localized.Labels.private}
                  </AVText>
                  <CustomToggleSwitch
                    accessible={true}
                    accessibilityLabel="Snack Private Status"
                    accessibilityRole="switch"
                    role="switch"
                    aria-label="Send private status"
                    nativeID="Snack Private Status"
                    value={this.state.isPrivate}
                    onValueChange={(value) =>
                      this.setState({
                        isPrivate: value,
                      })
                    }
                    containerStyle={{
                      flex: 0,
                      justifyContent: 'flex-end',
                      padding: 0,
                    }}
                  />
                </View>
              </>
            )}
            <View style={styles.spacer} />
          </View>
        </ScrollView>
        <RoundedButton
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          buttonType={ButtonType.action}
          onPress={this.handleSnackBalanceCovers}
          accessibilityLabel="Send Snack"
          aria-label="Send Snack"
          disabled={!this.state.isSnackDataValid}
          text={
            Settings.is365Pay()
              ? Util.secondLetterCapitalize(Localized.Buttons.send_snack)
              : Localized.Buttons.send_snack
          }
          color={
            Settings.isRevolve() ? Styles.darkGreenRevolve : Util.checkIS365()
          }
          textStyle={Settings.is365Pay() ? styles.paySnackBtn : null}
          containerStyle={
            Settings.is365Pay() ? styles.paySnackBtncontainer : null
          }
        />
      </KeyboardAvoidingView>
    );
    return (
      <BackSubheader
        title={Localized.Labels.send_a_snack_title}
        accessibilityLabel="Back arrow"
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        onBackSelect={this.onBackSelect}
        pop={false}
        rightView={rightView}
      >
        {content}
        <ItemAddedFeedback
          strings={Localized}
          extraMargin={Styles.Spacing.m0}
        />
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Settings.isNewUI()
      ? Styles.Spacing.m3 + Styles.Spacing.m2
      : Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m1,
  },
  rightContainer: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: Styles.Spacing.m4,
  },
  errorLabel: {
    color: Settings.isRevolve() ? '#D7263D' : Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
  },
  descriptionCountLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    alignSelf: 'flex-end',
  },
  payDescriptionCountLabel: {
    color: '#707070',
    fontSize: Styles.Fonts.f0 + 2,
    alignSelf: 'flex-end',
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  textField: {
    marginTop: Styles.Spacing.m2,
    alignSelf: 'stretch',
    height: Styles.Heights.h3,
  },
  button: {
    alignSelf: 'flex-end',
    marginEnd: Styles.Spacing.m1,
  },
  privateStatusContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    padding: Styles.Spacing.m2,
  },
  privateLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  chooseStickerLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m2,
  },
  contactContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m3,
    alignItems: 'center',
  },
  toLabel: {
    fontWeight: 'bold',
    marginRight: Styles.Spacing.m1,
  },
  spacer: {
    height: Styles.Heights.h5,
    marginBottom: 200,
  },
  yourBalance: {
    color: Styles.black,
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f2,
    position: 'relative',
    width: '100%',
    height: 40,
  },
  sendSnackDescription: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
    position: 'relative',
    width: 295,
    height: 34,
    top: 10,
  },
  chooseContactTxtRevolve: {
    fontSize: 14,
    fontWeight: '700',
    color: Styles.darkGreenRevolve,
  },
  chooseContactBtnRevolve: {
    borderRadius: Styles.Spacing.m4,
    borderWidth: 1,
    paddingVertical: Styles.Spacing.m2 + 4,
    paddingHorizontal: Styles.Spacing.m2 + 7,
    marginTop: 10,
  },
  emailRevolveSpacing: {
    marginLeft: 'auto',
    marginTop: Styles.Spacing.m1 - 10,
    marginBottom: 8,
  },
  inputTextStyles: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '500',
    color: Styles.black,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  paySnackBtn: {
    fontSize: 16,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  paySnackBtncontainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
  payLblStyle: {
    fontSize: 18,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  paySendSnackDescription: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  payYourBalance: {
    fontSize: 18,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: '#111',
    position: 'relative',
    width: '100%',
    paddingVertical: 4,
  },
  refillBal: {
    alignItems: 'center',
    borderWidth: 1.2,
    borderRightColor: '#066DFF',
    borderRadius: 32,
    borderBottomColor: '#066DFF',
    paddingVertical: 10,
    bottom: 15,
    borderTopColor: '#066DFF',
    borderLeftColor: '#066DFF',
    paddingHorizontal: 14,
    overflow: 'hidden',
    backgroundColor: '#fff',
  },
  refillBalbtnStyle: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0 + 2,
    fontWeight: Platform.OS === 'android' ? 'bold' : '700',
    color: '#066DFF',
  },
});

const mapStateToProps = (state: RootState) => ({
  displayBalances: state.account.account.defaultBalance.balance,
});

export default compose(
  withForwardedNavigationParams(),
  withGlobalize,
  connect(mapStateToProps, (dispatch: AppDispatch) => ({
    sendSnackToUser: (params) => dispatch(sendSnackToUser(params)),
  })),
)(SendSnackScreen);
