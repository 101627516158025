import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import DeviceInfo from 'src/nativeModules/DeviceInfo';
import RefundSummaryScreen from 'src/components/screens/account/history/RefundSummaryScreen';
import ProductCategorySearchScreen from 'src/components/screens/cart/ProductCategorySearchScreen';
import InsufficientCreditScreen from 'src/components/screens/funding/InsufficientCreditScreen';
import PromotionsScreen from 'src/components/screens/PromotionsScreen';
import RewardsScreen from 'src/components/screens/RewardsScreen';
import DealDetailScreen from 'src/components/screens/inbox/DealDetailScreen';
import ReferralScreen from 'src/components/screens/ReferralScreen';
import ReferralSummaryScreen from 'src/components/screens/account/history/ReferralSummaryScreen';
import ShareReceiptScreen from 'src/components/screens/cart/ShareReceiptScreen';
import GiftFeedScreen from 'src/components/screens/sendSnack/GiftFeedScreen';
import SendSnackScreen from 'src/components/screens/sendSnack/SendSnackScreen';
import ChooseContactScreen from 'src/components/screens/sendSnack/ChooseContactScreen';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import PurchaseReceiptScreen from '../../components/screens/account/history/PurchaseHistoryDetailScreen';
import ScanScreenNewUI from '../../components/screens/cart/ScanScreen';

const Stack = createStackNavigator();
export default function PromotionNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Promotions}
      screenOptions={{
        ...StackTransitionPreset,
        title: DeviceInfo.getApplicationName(),
        headerShown: false,
      }}
    >
      <Stack.Screen name={AppRoutes.Promotions} component={PromotionsScreen} />
      <Stack.Screen name={AppRoutes.Scan} component={ScanScreenNewUI} />
      <Stack.Screen name={AppRoutes.Rewards} component={RewardsScreen} />
      <Stack.Screen
        name={AppRoutes.ProductCategorySearch}
        component={ProductCategorySearchScreen}
      />
      <Stack.Screen
        name={AppRoutes.Receipt}
        component={PurchaseReceiptScreen}
      />
      <Stack.Screen
        name={AppRoutes.InsufficientCredit}
        component={InsufficientCreditScreen}
      />
      <Stack.Screen
        name={AppRoutes.PurchaseHistoryDetail}
        component={PurchaseReceiptScreen}
      />
      <Stack.Screen
        name={AppRoutes.ShareReceipt}
        component={ShareReceiptScreen}
      />
      <Stack.Screen
        name={AppRoutes.ReferralSummary}
        component={ReferralSummaryScreen}
      />
      <Stack.Screen name={AppRoutes.DealDetail} component={DealDetailScreen} />
      <Stack.Screen
        name={AppRoutes.RefundSummary}
        component={RefundSummaryScreen}
      />
      <Stack.Screen name={AppRoutes.Referral} component={ReferralScreen} />
      <Stack.Screen name={AppRoutes.GiftFeed} component={GiftFeedScreen} />
      <Stack.Screen name={AppRoutes.SendSnack} component={SendSnackScreen} />
      <Stack.Screen
        name={AppRoutes.SnackDetail}
        component={SnackDetailScreen}
      />
      <Stack.Screen
        name={AppRoutes.ChooseContact}
        component={ChooseContactScreen}
      />
    </Stack.Navigator>
  );
}
