import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import type {MenuProductType, TabType} from 'src/types/Menu';
import ProductModifier from '../../elements/orderAhead/ProductModifier';
import AppRoutes from 'src/AppRoutes';
import NavActions from 'src/actions/NavActions';
import Util, {getPreviousRouteName} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import MenuService from 'src/services/MenuService';
import TransactionStore from 'src/stores/TransactionStore';
import CartService from 'src/services/CartService';
import AccountConstants from 'src/constants/AccountConstants';
import type {LocationType} from 'src/types/Location';
import CartStore from 'src/stores/CartStore';
import Localized from 'src/constants/AppStrings';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import PromotionTypes from 'src/constants/cart/PromotionTypes';
import Settings from 'src/Settings';
type ProductUpsellScreenProps = WithGlobalizeProps & {
  tabs: Array<TabType>;
  product: MenuProductType;
  location: LocationType;
  navigation?: NavigationProp<ProductUpsellScreen>;
};
type ProductUpsellScreenState = {
  tab: TabType;
  cost: number;
  previousRoute: string | null;
};

class ProductUpsellScreen extends React.Component<
  ProductUpsellScreenProps,
  ProductUpsellScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  selections: any;

  constructor(props: ProductUpsellScreenProps) {
    super(props);
    this.state = {
      tab: props.tabs[0],
      cost: 0,
      previousRoute: null,
    };
    this.selections = {};
    this.addItemClick = this.addItemClick.bind(this);
    this.modifierSelectionChanged = this.modifierSelectionChanged.bind(this);
    this.toNextScreen = this.toNextScreen.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ProductUpsellScreen', {
      ...this.props,
      ...this.state,
    });
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  render() {
    const modifiers =
      this.state.tab.modifiers &&
      this.state.tab.modifiers.map((modifier, index) => {
        return (
          <ProductModifier
            key={index}
            productModifier={modifier}
            selectionChanged={this.modifierSelectionChanged}
          />
        );
      });
    const actionButtonText =
      Object.keys(this.selections).length !== 0
        ? `${Localized.Buttons.add_items} ${Util.formatCurrency(
            this.props,
            this.state.cost,
            AccountStore.getCurrency(),
          )}`
        : Localized.Buttons.no_thanks;
    const actionButton = (
      <RoundedButton
        accessible={true}
        accessibilityLabel={actionButtonText}
        accessibilityRole="button"
        aria-label={actionButtonText}
        role="button"
        buttonType={ButtonType.action}
        text={actionButtonText}
        onPress={this.addItemClick}
        color={
          Settings.is365Pay()
            ? Styles.Colors.PayNew.primary01
            : Styles.primaryColor
        }
      />
    );
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={this.state.tab.desc}
      >
        <View style={styles.container}>
          <ScrollView>
            <View style={styles.content}>
              {modifiers}
              <View style={styles.bottom} />
            </View>
          </ScrollView>
          {actionButton}
        </View>
      </BackSubheader>
    );
  }

  modifierSelectionChanged(modifierId: string, selections: Array<string>) {
    if (selections && selections.length > 0) {
      this.selections[modifierId] = selections;
    } else {
      delete this.selections[modifierId];
    }

    const options = this.state.tab.modifiers
      .map((modifier) => modifier.vals)
      .reduce((array, val) => {
        return array.concat(val);
      }, []);
    const values = Object.keys(this.selections)
      .map((key) => this.selections[key])
      .reduce((array, selection) => {
        return array.concat(selection);
      }, []);
    const price = options.reduce((sum, option) => {
      sum += values.includes(option.id) ? Number(option.price) : 0;
      return sum;
    }, 0);

    FirebaseAnalytic.trackEvent(
      'modifierSelectionChanged',
      'ProductUpsellScreen',
      {
        ...this.props,
        ...this.state,
        options,
        modifierId,
        selections,
        price,
        values,
      },
    );

    this.setState({
      cost: price,
    });
  }

  addItemClick() {
    if (Object.keys(this.selections).length !== 0) {
      const modifiers = [];
      Object.keys(this.selections).forEach((modifier: string) => {
        this.selections[modifier].forEach((modifierVal: string) => {
          modifiers.push(
            MenuService.getCartModifier(modifierVal) ||
              MenuService.getCartModifierForFreeText(modifier, modifierVal),
          );
        });
      });
      CartStore.setUpsellModifiers(this.state.tab.id, modifiers);
    }

    FirebaseAnalytic.trackEvent('addItemClick', 'ProductUpsellScreen', {
      ...this.props,
      ...this.state,
    });

    this.toNextScreen();
  }

  async toNextScreen() {
    const tabs = [...this.props.tabs];

    FirebaseAnalytic.trackEvent('toNextScreen', 'ProductUpsellScreen', {
      ...this.props,
      ...this.state,
      tabs,
    });

    tabs.shift();

    if (tabs.length > 0) {
      NavActions.push(
        AppRoutes.ProductUpsell,
        {...this.props, tabs},
        this.state.tab.id,
      );
    } else {
      const result = await this.updateCart();

      if (result) {
        NavActions.popToRoute(AppRoutes.Menu, {
          location: this.props.location,
        });
      }
    }
  }

  async updateCart() {
    const items = TransactionStore.getAllItems();
    const pendingItem = CartStore.getPendingItem();
    const pendingQuantity = CartStore.getPendingQuantity();
    const prepDeafults = TransactionStore.getPreparationMethodDefaults(
      this.props.location,
    );
    //Adding product modifiers as individual products
    const filteredItems = MenuService.filterProductModifiers(pendingItem);
    FirebaseAnalytic.trackEvent('updateCart', 'ProductUpsellScreen', {
      ...this.props,
      ...this.state,
      items,
      pendingItem,
      pendingQuantity,
      prepDeafults,
      filteredItems,
    });

    for (let i = 0; i < pendingQuantity; i++) {
      items.unshift(...filteredItems);
    }

    const item = items[0];
    const pendingModifiers = CartStore.getPendingModifiers();
    const existingModifiers = item.Modifiers ? item.Modifiers : [];
    items[0] = {
      ...item,
      Modifiers: [...existingModifiers, ...pendingModifiers],
    };
    //Adding upsell modifiers as products
    const filteredItemsWithPendingModifiers =
      MenuService.filterProductModifiers(items[0]);
    filteredItemsWithPendingModifiers.shift();
    items.unshift(...filteredItemsWithPendingModifiers);
    const discountCode =
      this.props.location.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
        ? PromotionTypes.AfterTaxPromo
        : null;
    const response = await CartService.updateCart(
      this.props.location.locationId,
      AccountStore.getAccountId(),
      [],
      items,
      AccountConstants.SOS_LOCATION_TYPE,
      this.props.product.scancode,
      TransactionStore.getPickupTime(),
      prepDeafults.selectedMethod,
      this.context,
      discountCode,
    );
    FirebaseAnalytic.trackEvent('updateCart response', 'ProductUpsellScreen', {
      ...this.props,
      ...this.state,
      items,
      pendingItem,
      pendingQuantity,
      prepDeafults,
      filteredItems,
      response,
      filteredItemsWithPendingModifiers,
      pendingModifiers,
      existingModifiers,
    });

    return response;
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    paddingHorizontal: Settings.is365Pay() ? 0 : Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  bottom: {
    height: Styles.Spacing.m5,
    width: '100%',
  },
});
export default withForwardedNavigationParams()(
  withGlobalize(ProductUpsellScreen),
);
