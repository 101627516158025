import React, {useContext, useEffect} from 'react';
import {
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  StatusBar,
  StyleSheet,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import {IsConnectedProps} from 'src/types/Screen';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {useAppSelector} from 'src/redux/hooks';
import MainConsumerContext from 'src/components/MainConsumerContext';
import Header from 'src/components/elements/Header';
import AccountStore from 'src/stores/AccountStore';
import QRCode from 'react-native-qrcode-svg';
import AVText from 'src/components/elements/AVText';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Util, {getTotalDisplayAmount} from 'src/Util';
import Events from 'src/logging/Events';
import {alertError} from 'src/components/helpers/AlertHelper';
import PassKit from 'src/nativeModules/PassKit';
import Settings from 'src/Settings';
import LinearGradient from 'react-native-linear-gradient';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import BrightnessTouchable from 'src/components/elements/BrightnessTouchable';
import {BalanceTypes} from '../../../types/serverTypes/Account';

type ScanAccountScreenProps = IsConnectedProps;

const {width, height} = Dimensions.get('screen');

const ScanAccountScreen = (props: ScanAccountScreenProps) => {
  const context = useContext(MainConsumerContext);

  const balances = useAppSelector((state) => state.account.account.balances);
  const defaultBalance = balances.find((b) => b.type === BalanceTypes.Default);
  useEffect(() => {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ScanAccountScreen', {
      ...props,
    });
  }, []);

  const addPassToWallet = async () => {
    const {state, actions} = context;
    FirebaseAnalytic.trackEvent('addPassToWallet', 'ScanAccountScreen');
    actions.showSpinner();
    try {
      const addPasssuccess = await PassKit.addPass(state.passUrl);
      if (addPasssuccess) {
        const isPassExists = await PassKit.doesPassExist(state.passUrl);

        if (isPassExists) {
          if (actions.passAdded) {
            actions.passAdded();
          }
          Events.Pass.trackEvent();
        }
      } else {
        alertError(Localized.Errors.failed_to_add_pass);
      }
    } finally {
      actions.hideSpinner();
    }
  };

  const addFundsAction = () => {
    FirebaseAnalytic.trackEvent('addFundClicked', 'ScanAccountScreen');
    context.actions.navigateToFunding(props.isConnected, {
      transDate: Util.getCurrentDate(),
    });
  };

  // const manageAction = () => {
  //   FirebaseAnalytic.trackEvent('manageClicked', 'ScanAccountScreen');
  //   NavActions.navigate(AppRoutes.Balances);
  // };
  const renderImage = () => {
    return (
      <Image
        source={require('src/components/img/welcomedotoverlay.png')}
        style={styles.overlay}
        resizeMode="cover"
      />
    );
  };
  const scanScreenDescriptionButtons = () => {
    return (
      <View style={styles.descMain}>
        <View style={styles.balanceContainer}>
          <View style={styles.balanceView}>
            <AVText
              style={[
                getDescriber().scanScreenQRCodeStyleDescriptor()['balanceText'],
              ]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              accessibilityRole="text"
              accessible={true}
              accessibilityLabel={Localized.Labels.my_balance}
              aria-label={`${Localized.Labels.my_balance}, text`}
            >
              {Localized.Labels.my_balance + ': '}
            </AVText>
            <AVFormattedCurrency
              testID={'balanceFormattedCurrencyScanAccount'}
              accessibilityLabel={String(getTotalDisplayAmount(balances))}
              aria-label={String(getTotalDisplayAmount(balances))}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              style={[
                getDescriber().scanScreenQRCodeStyleDescriptor()['balanceText'],
              ]}
              value={getTotalDisplayAmount(balances)}
              currency={defaultBalance?.currency ?? AccountStore.getCurrency()}
            />
          </View>
        </View>
        <View style={styles.btnMainView}>
          <View
            style={
              getDescriber().scanScreenQRCodeStyleDescriptor()[
                'addFundsButtonContainer'
              ]
            }
          >
            <RoundedButton
              accessible={true}
              accessibilityHint="Double tap to open funding modal"
              accessibilityRole="button"
              role="button"
              accessibilityLabel={Localized.Labels.add_funds}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              aria-label={Localized.Labels.add_funds}
              testID={Localized.Labels.add_funds}
              buttonType={ButtonType.normal}
              text={Localized.Labels.add_funds}
              textStyle={[
                getDescriber().scanScreenQRCodeStyleDescriptor()[
                  'addFundsText'
                ],
                {color: Styles.white},
                !Settings.isRevolve() ? null : {textTransform: 'uppercase'},
              ]}
              color={
                Settings.isRevolve()
                  ? Styles.blueRevolve
                  : Styles.Colors.PayNew.primary01
              }
              onPress={() => addFundsAction()}
              containerStyle={
                getDescriber().scanScreenQRCodeStyleDescriptor()['btnAddFund']
              }
            />
          </View>
          <View style={styles.bottomButtonsView}>
            {!context?.state.passExists && context?.state.canAddPass && (
              <RoundedButton
                accessible={true}
                accessibilityHint="Double tap to add to wallet"
                accessibilityRole="button"
                role="button"
                accessibilityLabel={Localized.Buttons.add_to_wallet}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                aria-label={Localized.Buttons.add_to_wallet}
                testID={Localized.Buttons.add_to_wallet}
                buttonType={ButtonType.normal}
                text={Localized.Buttons.add_to_wallet}
                textStyle={[
                  getDescriber().scanScreenQRCodeStyleDescriptor()[
                    'addToWalletText'
                  ],
                  !Settings.isRevolve() ? null : {textTransform: 'uppercase'},
                ]}
                containerStyle={
                  getDescriber().scanScreenQRCodeStyleDescriptor()[
                    'btnAddToWallet'
                  ]
                }
                color={Settings.isRevolve() ? Styles.white : '#066DFF'}
                backgroundColor={Styles.white}
                onPress={() => addPassToWallet()}
              />
            )}
          </View>
        </View>
      </View>
    );
  };

  return Settings.isRevolve() ? (
    <Header title={getDescriber().scanScreenQRCodeHeaderText()}>
      <View style={styles.container}>
        <View style={{bottom: Platform.OS === 'ios' ? -195 : -175}}>
          {renderImage()}
        </View>
        <View
          style={{top: -330, justifyContent: 'center', alignItems: 'center'}}
        >
          <ImageBackground
            source={require('src/components/img/scanrectangle.png')}
            style={{
              width: 240,
              height: 240,
              opacity: 0.95,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <BrightnessTouchable
              style={[
                styles.qrCodeView,
                {
                  borderWidth: 8,
                  borderColor: '#D8D8D8',
                  borderRadius: 10,
                  bottom: 20,
                },
              ]}
              accessibilityLabel="QRCode"
              aria-label="QRCode"
              testID="QRCode"
              timeout={10000}
            >
              <QRCode
                value={AccountStore.getQrCode() ?? ''}
                size={width / 2.6}
              />
            </BrightnessTouchable>
          </ImageBackground>
          {scanScreenDescriptionButtons()}
        </View>
      </View>
    </Header>
  ) : (
    <LinearGradient
      colors={['#FEE0E6', '#E6ECFF']}
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      style={styles.redesignContainer}
    >
      <StatusBar translucent={true} backgroundColor="transparent" />
      <View style={styles.scanHeaderTextView}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          accessibilityRole="text"
          accessible={true}
          accessibilityLabel={Localized.Labels.pay_at_kiosk}
          aria-label={Localized.Labels.pay_at_kiosk}
          style={styles.scanHeaderTextDesc}
        >
          {getDescriber().scanScreenQRCodeHeaderText()}
        </AVText>
      </View>

      <View style={styles.gradientBoxView}>
        <LinearGradient
          style={styles.gradientBox}
          start={{x: 0, y: 0}}
          end={{x: 1, y: 1}}
          colors={['#FEE0E6', '#E6ECFF']}
        >
          <BrightnessTouchable
            style={styles.redesignQrCodeView}
            accessibilityLabel="QRCode"
            aria-label="QRCode"
            testID="QRCode"
            timeout={10000}
          >
            <QRCode value={AccountStore.getQrCode() ?? ''} size={width / 2.2} />
          </BrightnessTouchable>
        </LinearGradient>
      </View>

      {scanScreenDescriptionButtons()}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  scanHeaderTextView: {
    position: 'absolute',
    top: 35,
    left: 5,
    padding: 10,
    zIndex: 1,
    marginTop: Platform.OS === 'ios' ? Styles.Spacing.m3 : 0,
  },
  scanHeaderTextDesc: {
    fontWeight: '700',
    fontSize: Styles.Fonts.headerFont,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.black,
  },
  container: {
    backgroundColor: Styles.darkBlueRevolveColor,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redesignContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  redesignQrCodeView: {
    borderWidth: 10,
    borderColor: '#FFF',
    borderRadius: 10,
  },
  gradientBoxView: {
    borderRadius: 40,
    ...Styles.Style.shadow,
  },
  gradientBox: {
    width: width * 0.68,
    height: height * 0.33,
    borderRadius: 40,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.07)',
    justifyContent: 'center',
    alignItems: 'center',
  },

  content: {
    marginTop: Styles.Spacing.m3,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    shadowColor: '#000',
    width: width - (Styles.Spacing.m3 + Styles.Spacing.m2) * 2,
    backgroundColor: Styles.white,
    borderColor: Styles.lightGray,
    shadowOffset: {width: 1, height: 1},
    height: '88%',
    elevation: 5,
    alignItems: 'center',
    borderRadius: Styles.Spacing.m3,
    shadowRadius: 2,
    shadowOpacity: 0.4,
  },
  logoView: {
    height: 65,
    width: '100%',
    marginTop: Styles.Spacing.m0,
    backgroundColor: Styles.primaryColor,
    borderTopLeftRadius: Styles.Spacing.m2,
    borderTopRightRadius: Styles.Spacing.m2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    width: 98,
    height: 98,
    marginBottom: -138,
  },
  qrCodeView: {
    marginTop: Styles.Spacing.m4,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  balanceContainer: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
    alignItems: 'center',
  },
  balanceView: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  ponitsText: {
    marginTop: Styles.Spacing.m2,
    color: Styles.darkColor,
    fontWeight: '400',
    fontSize: Styles.Fonts.f0,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  textButtonContainer: {
    minHeight: Styles.Heights.touchTargetHeight1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    color: Styles.blueRevolve,
    textDecorationLine: 'underline',
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  bottomButtonsView: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: Styles.Spacing.m3 + 5,
  },
  overlay: {
    width: 370,
    height: 620,
  },
  descMain: {
    top: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnMainView: {
    top: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default withForwardedNavigationParams()(
  withIsConnected(ScanAccountScreen),
);
