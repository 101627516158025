import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import StackTransitionPreset from '../StackTransitionPreset';
import HistoryScreen from 'src/components/screens/account/history/HistoryScreen';
import PurchaseHistoryDetailScreen from 'src/components/screens/account/history/PurchaseHistoryDetailScreen';

import RefundSummaryScreen from 'src/components/screens/account/history/RefundSummaryScreen';
import ShareReceiptScreen from 'src/components/screens/cart/ShareReceiptScreen';
import ReferralSummaryScreen from 'src/components/screens/account/history/ReferralSummaryScreen';
import PurchaseReceiptScreen from '../../components/screens/account/history/PurchaseHistoryDetailScreen';

import SendSnackScreenNew from 'src/components/screens/account/history/SnackSummaryScreen';
const HistoryStack = createStackNavigator();

const HistoryNavigator = () => {
  return (
    <HistoryStack.Navigator
      initialRouteName={AppRoutes.History}
      screenOptions={{
        ...StackTransitionPreset,
        headerShown: false,
      }}
    >
      <HistoryStack.Screen name={AppRoutes.History} component={HistoryScreen} />
      <HistoryStack.Screen
        component={PurchaseHistoryDetailScreen}
        name={AppRoutes.PurchaseHistoryDetail}
      />
      <HistoryStack.Screen
        name={AppRoutes.RefundSummary}
        component={RefundSummaryScreen}
      />
      <HistoryStack.Screen
        component={PurchaseReceiptScreen}
        name={AppRoutes.PurchaseReceipt}
      />
      <HistoryStack.Screen
        name={AppRoutes.ShareReceipt}
        component={ShareReceiptScreen}
      />
      <HistoryStack.Screen
        component={ReferralSummaryScreen}
        name={AppRoutes.ReferralSummary}
      />
      <HistoryStack.Screen
        component={SendSnackScreenNew}
        name={AppRoutes.SnackDetail}
      />
      <HistoryStack.Screen
        component={PurchaseHistoryDetailScreen}
        name={AppRoutes.PurchaseHistoryDetailNew}
      />
    </HistoryStack.Navigator>
  );
};
export default HistoryNavigator;
