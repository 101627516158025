import RevolveDescriptor from './common-module-revolve';
import Settings from 'src/Settings';
import RedesignDescriptor from './common-module-redesign';
import CanteenDescriptor from './common-module-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';

const typeDescriber = {
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
