import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
  Modal,
  Text,
  StyleSheet,
  View,
  ViewStyle,
  Dimensions,
  TouchableOpacity,
} from 'react-native';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountApi from 'src/api/AccountApi';
import HttpClient from 'src/api/HttpClient';
import GreetingsAndBalanceView from 'src/components/elements/home/GreetingsAndBalanceView';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import LeftArrow from 'src/components/img/svg/LeftArrow';
import RightArrow from 'src/components/img/svg/RightArrow';
import ArrowStep1 from 'src/components/img/svg/tutorials/ArrowStep1';
import ArrowStep2 from 'src/components/img/svg/tutorials/ArrowStep2';
import ArrowStep3 from 'src/components/img/svg/tutorials/ArrowStep3';
import ArrowStep4 from 'src/components/img/svg/tutorials/ArrowStep4';
import LocationTileTutorial from 'src/components/img/svg/tutorials/LocationTileTutorial';
import NotificationTutorialIcon from 'src/components/img/svg/tutorials/NotificationTutorialIcon';
import PayTab from 'src/components/img/svg/tutorials/PayTab';
import Styles from 'src/components/Styles';
import AppEventTypes from 'src/constants/AppEventTypes';
import Localized from 'src/constants/AppStrings';
import Logger from 'src/logging/Logger';
import {useAppSelector} from 'src/redux/hooks';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import NetInfo from '@react-native-community/netinfo';

const InteractiveTutorial = () => {
  const width = Dimensions.get('window').width;
  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [showInteractiveTour, setShowInteractiveTour] =
    useState<boolean>(false);
  const account = useAppSelector((s) => s.account.account);

  useEffect(() => {
    getAppTourDetails();
  }, []);

  const getAppTourDetails = async () => {
    let appTourVersion = account.appTour;
    if (!appTourVersion || appTourVersion === '') {
      let res;
      try {
        res = await AccountApi.fetchAccount(AccountStore.getAccountId());
        appTourVersion = res?.appTour;
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'FetchAccount',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(res),
        );
      } catch (err) {
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'FetchAccount',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(err),
        );
      }
    }
    const networkState = await NetInfo.fetch();
    const isNetworkConnected = networkState?.isConnected;
    const appTourData = HttpClient.parseJsonSafe(appTourVersion);
    if (appTourData?.AppTourShown === Settings.appTourVersion) {
      setShowInteractiveTour(false);
    } else if (!isNetworkConnected) {
      setShowInteractiveTour(false);
    } else {
      setShowInteractiveTour(true);
    }
  };

  const updateAppTourStatus = async () => {
    //Hide Interactive Tutorial Moadal
    setShowInteractiveTour(false);

    try {
      const appTourVersionData = JSON.stringify({
        AppTourShown: Settings.appTourVersion,
      });
      const accountId = AccountStore.getAccountId();
      const utcDate = Util.getCurrentUTCDate();
      const date = Util.getCurrentDate();

      const result = await ActionsFactory.getAccountActions().updateAppEvent(
        accountId,
        AppEventTypes.AppTour,
        utcDate,
        date,
        moment.tz.guess(),
        appTourVersionData,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'UpdateAppEvent',
        JSON.stringify({
          accountId: accountId,
          AppEvent: AppEventTypes.AppTour,
          appTourVersionData,
        }),
        JSON.stringify(result),
      );
    } catch (err) {
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'UpdateAppEvent',
        JSON.stringify({
          accountId: AccountStore.getAccountId(),
          AppEvent: AppEventTypes.AppTour,
        }),
        JSON.stringify(err),
      );
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return Localized.Labels.new_pay_tab;
      case 2:
        return Localized.Labels.auto_reload_new_header;
      case 3:
        return Localized.Labels.notifications_center;
      case 4:
        return Localized.Labels.link_location;
      default:
        return '';
    }
  };

  const getStepDescription = () => {
    switch (currentStep) {
      case 1:
        return Localized.Labels
          .scan_your_unique_qr_code_to_make_quick_and_secure_payments;
      case 2:
        return Localized.Labels.revolve_auto_reload_description;
      case 3:
        return Localized.Labels
          .we_help_keep_you_updated_with_important_messages_and_updates;
      case 4:
        return Localized.Labels
          .choose_a_nearby_location_to_help_us_provide_you_with_more_relevant_features_in_the_app;
      default:
        return '';
    }
  };

  const getTextWidth = () => {
    switch (currentStep) {
      case 1:
        return 160;
      case 2:
        return 200;
      case 3:
        return 230;
      case 4:
        return 220;
      default:
        return 160;
    }
  };

  const nextAction = () => {
    setCurrentStep(currentStep + 1);
  };

  const skipAction = () => {
    updateAppTourStatus();
  };

  const getActionButtons = () => {
    if (currentStep !== totalSteps) {
      return (
        <View style={styles.bottomButtonsView}>
          <RoundedAppButton
            accessibilityLabelValue={Localized.Buttons.skip}
            aria-label={Localized.Buttons.skip}
            titleText={Localized.Labels.skip}
            buttonType={RoundedAppButtonType.Solid}
            textStyle={{
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontWeight: '700',
              fontSize: Styles.Fonts.f1,
              textDecorationLine: 'underline',
            }}
            buttonViewStyle={{
              height: 42,
              minWidth: 80,
              backgroundColor: Styles.transparent,
            }}
            onPress={() => skipAction()}
          />
          <RoundedAppButton
            accessibilityLabelValue={Localized.Buttons.next}
            aria-label={Localized.Buttons.next}
            titleText={Localized.Buttons.next}
            buttonType={RoundedAppButtonType.Solid}
            textStyle={{
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontWeight: '700',
              fontSize: Styles.Fonts.f1,
            }}
            buttonViewStyle={{height: 42, minWidth: 80}}
            onPress={() => nextAction()}
          />
        </View>
      );
    } else {
      return (
        <View
          style={[
            styles.bottomButtonsView,
            {alignItems: 'center', justifyContent: 'center'},
          ]}
        >
          <RoundedAppButton
            accessibilityLabelValue={Localized.Buttons.lets_go_new}
            aria-label={Localized.Buttons.lets_go_new}
            titleText={Localized.Buttons.lets_go_new}
            buttonType={RoundedAppButtonType.Solid}
            buttonViewStyle={{height: 42, minWidth: 120}}
            onPress={() => skipAction()}
            textStyle={{
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontWeight: '700',
              fontSize: Styles.Fonts.f1,
            }}
          />
        </View>
      );
    }
  };

  const stepTextView = (viewStyle: ViewStyle) => {
    return (
      <View
        style={[viewStyle, styles.textContentView, {width: getTextWidth()}]}
      >
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          style={styles.titleText}
        >
          {getStepTitle()}
        </Text>
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          style={styles.descriptionText}
        >
          {getStepDescription()}
        </Text>
      </View>
    );
  };

  const stepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <View
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            {stepTextView({
              position: 'absolute',
              bottom: 194,
              left: 124,
            })}
            <View
              style={{
                position: 'absolute',
                bottom: 92,
                left: 16,
              }}
            >
              <ArrowStep1 />
            </View>
            <View
              style={{
                height: 54,
                width: 54,
                borderRadius: 27,
                backgroundColor: Styles.white,
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 32,
                left: width / 5 + 8,
              }}
            >
              <PayTab />
            </View>
          </View>
        );

      case 2:
        return (
          <View
            style={{
              top: Styles.Heights.touchTargetHeight1 + 54,
              width: '100%',
              height: '100%',
            }}
          >
            <GreetingsAndBalanceView />
            <View
              style={{
                left: Styles.Spacing.m5,
                marginTop: 120,
                position: 'absolute',
              }}
            >
              <ArrowStep2 />
            </View>
            {stepTextView({
              left: Styles.Spacing.m5 + 85,
              marginTop: 185,
              position: 'absolute',
            })}
          </View>
        );

      case 3:
        return (
          <View
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <View
              style={{
                height: 54,
                borderRadius: 27,
                width: 54,
                alignItems: 'center',
                backgroundColor: Styles.white,
                justifyContent: 'center',
                position: 'absolute',
                top: Styles.Heights.touchTargetHeight1 + 4,
                right: 8,
              }}
            >
              <NotificationTutorialIcon />
            </View>
            <View
              style={{
                marginTop: Styles.Heights.touchTargetHeight1 + 24,
                right: 62,
                position: 'absolute',
              }}
            >
              <ArrowStep3 />
            </View>
            {stepTextView({
              right: Styles.Spacing.m4 + Styles.Spacing.m3,
              marginTop: Styles.Heights.touchTargetHeight1 + 172,
              position: 'absolute',
            })}
          </View>
        );

      case 4:
        return (
          <View
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <View
              style={{
                position: 'absolute',
                top: Styles.Heights.touchTargetHeight2,
                left: 8,
              }}
            >
              <LocationTileTutorial />
            </View>

            <View
              style={{
                left: Styles.Spacing.m4 + Styles.Spacing.m2,
                marginTop: 108,
                position: 'absolute',
              }}
            >
              <ArrowStep4 />
            </View>
            {stepTextView({
              left: Styles.Spacing.m5 + 72,
              marginTop: 204,
              position: 'absolute',
            })}
          </View>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      transparent={true}
      style={{zIndex: 1}}
      visible={showInteractiveTour}
      animationType="fade"
    >
      <View style={[styles.overlay, {backgroundColor: 'rgba(92,92,101,0.95)'}]}>
        {stepContent()}
        <View style={styles.stepIndicatorView}>
          <TouchableOpacity
            style={styles.arrowButtonView}
            disabled={currentStep === 1}
            onPress={() => setCurrentStep(currentStep - 1)}
          >
            {currentStep !== 1 && <LeftArrow />}
          </TouchableOpacity>
          <Text style={styles.stepIndicatorText}>
            {Localized.Labels.formatString(
              Localized.Labels.steps_tutorials,
              currentStep,
              totalSteps,
            )}
          </Text>
          <TouchableOpacity
            style={styles.arrowButtonView}
            disabled={currentStep === totalSteps}
            onPress={() => setCurrentStep(currentStep + 1)}
          >
            {currentStep !== totalSteps && <RightArrow />}
          </TouchableOpacity>
        </View>
        {getActionButtons()}
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(74,73,83,0.95)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomButtonsView: {
    position: 'absolute',
    left: Styles.Spacing.m3,
    right: Styles.Spacing.m3,
    bottom: 24,
    height: Styles.Heights.h5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  stepIndicatorView: {
    position: 'absolute',
    left: Styles.Spacing.m3,
    right: Styles.Spacing.m3,
    bottom: 104,
    height: Styles.Heights.h5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIndicatorText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.sectionHeader,
    color: Styles.white,
  },
  arrowButtonView: {
    width: Styles.Heights.touchTargetHeight1,
    height: Styles.Heights.touchTargetHeight1,
    marginHorizontal: Styles.Spacing.m2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContentView: {
    width: 160,
    alignItems: 'center',
  },
  titleText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.headerFont,
    color: Styles.white,
    marginBottom: Styles.Spacing.m1,
    textAlign: 'center',
  },
  descriptionText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    color: Styles.white,
    textAlign: 'center',
    maxWidth: 220,
  },
});

export default InteractiveTutorial;
