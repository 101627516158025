import FundingRevolveDescriptor from './funding-revolve';
import FundingRedesignDescriptor from './funding-redesign';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: FundingRevolveDescriptor,
  [BuildTypes.canteen]: FundingRedesignDescriptor,
  [BuildTypes.default]: FundingRedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export interface FundingDescriberProps {
  close: () => void;
}
