import AVText from 'src/components/elements/AVText';
import React from 'react';
import SnackProduct from 'src/components/img/svg/sendSnackStickers/SnackProduct';
import Styles from '../../../Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {
  Dimensions,
  View,
  StyleSheet,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {
  SendSnackDescriberData,
  AnnouncementStylesDescriptor,
  CampusLocationListStylesDescriptor,
  ReferralDescriberData,
  EngagementCarouselStylesDescriptor,
} from 'src/components/elements/home/descriptor/DescriptorType';
import GrpReferral from 'src/components/img/svg/GrpReferral';

export function getCampusLocationStyles(): {
  [key: string]: {[key: string]: number | string};
} {
  const _width = Dimensions.get('window').width;
  return {
    recentLocationAddressText: {
      fontWeight: '400',
      color: Styles.lightGray,
      width: _width - Styles.Spacing.m6,
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.robotoRegular,
      textAlignVertical: 'center',
    },
    recentLocationViewLocationNameText: {
      textAlignVertical: 'center',
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      width: _width - Styles.Spacing.m6,
      fontWeight: '700',
    },
  };
}
export function rewardsViewDescriptor(): JSX.Element {
  return <></>;
}
export function snackViewDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  const {onSendASnackClick, styles} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View style={styles.iconView} accessibilityLabel={'SnackIcon'}>
            <SnackProduct color={Styles.white} />
          </View>
          <AVText
            style={styles.titleText}
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          >
            {Localized.Buttons.send_a_snack}
          </AVText>
        </View>
        <AVText
          accessibilityLabel={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          aria-label={
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
          accessibilityRole="text"
          style={styles.snackDescriptionText}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {
            Localized.Labels
              .send_your_colleagues_tasty_snacks_to_brighten_their_day
          }
        </AVText>
        <RoundedButton
          buttonType={ButtonType.outline}
          containerStyle={styles.snackButton}
          onPress={onSendASnackClick}
          text={Localized.Buttons.send_a_snack}
          accessibilityLabel={Localized.Buttons.send_a_snack}
          textStyle={styles.snackButtonText}
          aria-label={Localized.Buttons.send_a_snack}
          accessibilityRole="button"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        />
      </View>
    </React.Fragment>
  );
}
export function getScanAndPayTile(): {
  [key: string]: {[index: string]: number | string};
} {
  return {
    iconViewBackground: {
      backgroundColor: Styles.primaryColor + '15',
      margin: Styles.Spacing.m3,
      height: Styles.Heights.h5,
      width: Styles.Heights.h5,
    },
    rowSpace: {
      marginVertical: Styles.Spacing.m2,
    },
    titleText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.black,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    listContainer: {
      marginTop: Styles.Spacing.m3,
    },
    nameText: {
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      color: Styles.black,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
    },
    textView: {
      height: Styles.Heights.h5,
      marginVertical: Styles.Spacing.m3,
    },
  };
}
export function infoIconPrimaryColor() {
  return Styles.primaryColor;
}
export function infoIconInnerColor() {
  return Styles.secondaryColor;
}
export function announcemnentButtonColor() {
  return Styles.primaryColor;
}

export function getAnnouncementStyles(): AnnouncementStylesDescriptor {
  return StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      marginTop: Styles.Spacing.m3 - 4,
      marginHorizontal: Styles.Spacing.m3,
      marginBottom: Styles.Spacing.m2,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...Styles.Style.shadow,
    },
    titleText: {
      fontSize: Styles.Fonts.headerFont,
      width: '80%',
      fontWeight: '700',
      color: Styles.black,
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m2,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    descriptionText: {
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.darkColor,
      marginRight: Styles.Spacing.m4,
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  });
}

export function getWidthImageAvailable() {
  return '70%';
}

export function getCampusLocationListStyles(): CampusLocationListStylesDescriptor {
  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    campusLocationListView: {
      width: '100%',
      marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    headerMargin: {
      marginLeft: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
  });
}
export function referralViewDescriptor(
  props?: ReferralDescriberData,
  _amtLocalised?: string,
): JSX.Element {
  const {onInviteClick, styles, title, stat} = props;
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.headerView}>
          <View style={styles.iconView} accessibilityLabel={'SnackIcon'}>
            <GrpReferral />
          </View>
          <AVText
            style={[
              {
                fontSize: 20,
                fontFamily: Styles.FontFamily.robotoRegular,
                color: '#22212D',
                fontWeight: '700',
                marginLeft: Styles.Spacing.m3,
              },
            ]}
            accessibilityLabel={`Text${Localized.Buttons.send_a_snack}`}
            aria-label={`Text${Localized.Buttons.send_a_snack}`}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          >
            {title}
          </AVText>
        </View>
        <AVText
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          aria-label={Localized.Labels.receive_cash_to_spend}
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {
              fontSize: 18,
              color: '#22212D',
              fontFamily: Styles.FontFamily.robotoRegular,
              fontWeight: '400',
            },
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.receive_cash_to_spend}
        </AVText>
        <AVText
          accessibilityLabel={Localized.Labels.receive_cash_to_spend}
          aria-label={Localized.Labels.receive_cash_to_spend}
          accessibilityRole="text"
          style={[
            styles.snackDescriptionText,
            {
              fontSize: 18,
              color: '#727179',
              fontFamily: Styles.FontFamily.robotoRegular,
              fontWeight: '400',
            },
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {stat}
        </AVText>
        <TouchableOpacity
          style={[
            styles.invite,
            {
              borderBottomColor: '#003349',
              borderTopColor: '#003349',
              borderLeftColor: '#003349',
              borderRightColor: '#003349',
            },
          ]}
          onPress={onInviteClick}
          accessibilityRole="button"
          accessibilityLabel={Localized.Buttons.invite}
          role="button"
          aria-label={Localized.Buttons.invite}
          testID={Localized.Buttons.invite}
        >
          <AVText
            style={{
              fontWeight: Platform.OS === 'android' ? 'bold' : '600',
              fontFamily: Styles.FontFamily.robotoRegular,
              fontSize: Styles.Fonts.f0 + 4,
              color: '#003349',
              textTransform: 'capitalize',
            }}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            testID={Localized.Buttons.invite}
            adjustsFontSizeToFit={true}
          >
            {Localized.Buttons.invite}
          </AVText>
        </TouchableOpacity>
      </View>
    </React.Fragment>
  );
}

export function engagementCarouselMargin(): number {
  return Styles.Spacing.m3 + Styles.Spacing.m2;
}
export function engagementCarouselMarginBottom(): number {
  return -Styles.Spacing.m4;
}
export function engagementCarouselMarginZeroLength(): number | undefined {
  return undefined;
}
export function engagementCarouselPaginationColor(): string {
  return Styles.secondaryColor;
}
export function engagementCarouselPaginationInactiveColor(): string {
  return Styles.inActiveDotColor;
}

export function getEngagementCarouselStyles(): EngagementCarouselStylesDescriptor {
  return StyleSheet.create({
    activeDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    inActiveDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    imageContentContainer: {
      paddingLeft: 10,
    },
    parralaxContainer: {
      backgroundColor: Styles.white,
    },
  });
}

export default {
  getAnnouncementStyles,
  getCampusLocationStyles,
  getScanAndPayTile,
  referralViewDescriptor,
  getWidthImageAvailable,
  announcemnentButtonColor,
  snackViewDescriptor,
  infoIconPrimaryColor,
  infoIconInnerColor,
  getCampusLocationListStyles,
  rewardsViewDescriptor,
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  engagementCarouselPaginationInactiveColor,
  getEngagementCarouselStyles,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
};
