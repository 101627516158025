import React from 'react';
import {
  BackHandler,
  NativeEventSubscription,
  PixelRatio,
  Platform,
  ScrollView,
  Share,
  StyleSheet,
  View,
} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import moment from 'moment';
import ScreenContext from '../../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {getTaxDescription} from 'src/services/TranslationService';
import Util, {getPreviousRouteName} from 'src/Util';
import RefundDetail from '../../../elements/account/RefundDetail';
import AVFormattedCurrency from '../../../elements/AVFormattedCurrency';
import BackSubheader from '../../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../../elements/RoundedButton';
import Styles from '../../../Styles';
import BaseScreen from '../../BaseScreen';
import type {
  HistoryItem,
  PurchaseDetailType,
  TransactionDetail,
} from 'src/types/TransactionDetail';
import ActionsFactory from 'src/actions/ActionsFactory';
import RedemptionDetail from '../../../elements/account/RedemptionDetail';
import SaleDetail from '../../../elements/account/SaleDetail';
import FundingDetail from '../../../elements/account/FundDetail';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../../elements/AVText';
import AccountConstants from 'src/constants/AccountConstants';
import {PICKUP_LOCATIONS_KEYWORD} from 'src/stores/TransactionStore';
import {OrderStatusEnum} from 'src/constants/OrderStatusEnum';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import QRCode from 'react-native-qrcode-svg';
import BranchApi from 'src/api/BranchApi';
import Settings from 'src/Settings';
import {InputTypes} from 'src/constants/KeyboardTypes';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../../../helpers/AlertHelper';
import {TimeSlotType} from 'src/types/Menu';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {
  adjustDefaultBalance,
  fetchAccount,
} from 'src/redux/slices/accountSlice';
import {EnvironmentKey} from 'src/models/Environment';
import {RootState} from 'src/redux/store';
import {compose} from 'redux';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {getDescriber} from 'src/components/elements/account/descriptor/DescriptorType';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {SuccessDialog} from 'src/components/elements/SuccessDialog';
import {Balance} from '../../../../models/Balance';

export type PurchaseHistoryDetailProps = WithGlobalizeProps & {
  transaction: TransactionDetail;
  transactionId: string;
  transactionType: string;
  checkout: boolean;
  pickupNotes: string;
  marketAccount: string | null | undefined;
  pickupLocation: string;
  pickupTime: TimeSlotType;
  orderNumber: string;
  location: string;
  locationName: string;
  transactionDate: string;
  discount: number;
  dispatch: AppDispatch;
  env: EnvironmentKey;
  navigation?: NavigationProp<PurchaseHistoryDetail>;
  transactionCurrency?: string;
  transactionTypeDisplay?: string;
  balances: Array<Balance>;
};

type PurchaseHistoryDetailState = {
  historyItem?: HistoryItem;
  detail: TransactionDetail | PurchaseDetailType;
  subtotal: number;
  loading: boolean;
  previousRoute: string | null;
  contentHeight?: number;
  showModal?: boolean;
};
export class PurchaseHistoryDetail extends React.Component<
  PurchaseHistoryDetailProps,
  PurchaseHistoryDetailState
> {
  DISCOUNT_PAYMENT_TYPES: Array<string> = ['CARTDISC', 'PROMOTION'];
  backHandler: NativeEventSubscription;
  receiptLink: string | null | undefined;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: PurchaseHistoryDetailProps) {
    super(props);
    this.state = {
      historyItem: AccountStore.getPurchaseHistoryItem(
        this.props.transactionId,
      ),
      detail: {} as TransactionDetail,
      subtotal: 0,
      loading: true,
      contentHeight: 0,
      showModal: false,
      previousRoute: null,
    };
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderFunding = this.renderFunding.bind(this);
    this.renderPurchase = this.renderPurchase.bind(this);
    this.handleEmailReceipt = this.handleEmailReceipt.bind(this);
    this.handleEmailFundingReceipt = this.handleEmailFundingReceipt.bind(this);
    this.getTranslatedPaymentType = this.getTranslatedPaymentType.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleRefund = this.handleRefund.bind(this);
    this.renderRefund = this.renderRefund.bind(this);
    this.renderRedemption = this.renderRedemption.bind(this);
    this.renderContainer = this.renderContainer.bind(this);
    this.handleEmailRefundReceipt = this.handleEmailRefundReceipt.bind(this);
    this.getTaxLabel = this.getTaxLabel.bind(this);
    this.getDisplayItems = this.getDisplayItems.bind(this);
    this.getOrderStatusDisplay = this.getOrderStatusDisplay.bind(this);
    this.isOrderAhead = this.isOrderAhead.bind(this);
    this.shareReceipt = this.shareReceipt.bind(this);
    this.toShareReceiptScreen = this.toShareReceiptScreen.bind(this);
    this.isReceiptShared = this.isReceiptShared.bind(this);
    this.mapTransactionToState = this.mapTransactionToState.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleLayout = this.handleLayout.bind(this);
  }

  async componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      if (this.props.checkout) {
        NavActions.popToTop();
        return true;
      }

      return false;
    });
    if (!this.props.transaction) {
      this.context.actions.showSpinner();
      try {
        const {historyItem} = this.state;
        const response =
          await ActionsFactory.getAccountActions().loadPurchaseDetail(
            this.props.marketAccount ?? AccountStore.getAccountId(),
            historyItem?.Id ?? this.props.transactionId,
            historyItem?.Type ?? this.props.transactionType,
            historyItem?.TransDate ?? this.props.transactionDate,
          );

        if (typeof response === 'object' && !response.Data) {
          alertError(Localized.Errors.error_loading_transaction);
          this.context.actions.hideSpinner();
          return;
        }
        this.updateState(response && response.Data);
      } catch (error) {
        const guid = await uuid.getRandomUUID();

        if (this.props.checkout) {
          alertError(
            Localized.Errors.error_loading_transaction_after_checkout,
            guid,
          );
        } else {
          alertError(Localized.Errors.error_loading_transaction, guid);
        }
        CrashlyticsEvents.log(
          'Exception',
          'PurchaseHistoryDetailScreen:ComponentDidMount',
          generateErrorMessage(error),
          guid,
          {
            transactionId: this.props.transactionId,
          },
        );
        Events.Error.trackEvent(
          'Exception',
          'PurchaseHistoryDetailScreen:ComponentDidMount',
          generateErrorMessage(error),
          guid,
          {
            transactionId: this.props.transactionId,
          },
        );
      } finally {
        this.setState({
          loading: false,
        });

        if (this.state.detail) {
          this.context.actions.hideSpinner();
        }
      }
    } else {
      this.setState({loading: false});
      this.updateState(this.mapTransactionToState());
    }
  }

  componentWillUnmount() {
    if (this.backHandler) {
      this.backHandler.remove();
    }
  }

  updateState(detail) {
    let subtotal = 0;
    if (this.props.checkout && detail.Items) {
      subtotal = detail.Items.reduce((a, b) => a + b.Price, 0);
    } else {
      if (detail.Items) {
        subtotal = detail.Items.reduce((accumulator, currentItem) => {
          accumulator += currentItem.Price;
          if (currentItem.Modifiers && currentItem.Modifiers.length > 0) {
            accumulator += currentItem.Modifiers.reduce(
              (modifierSum, modifier) => {
                return modifierSum + modifier.Price;
              },
              0,
            );
          }
          return accumulator;
        }, 0);
      }
    }
    this.setState({
      detail,
      subtotal,
    });
  }

  async handleEmailReceipt() {
    try {
      const response = await ActionsFactory.getAccountActions().emailReceipt(
        AccountStore.getEmail(),
        this.props.transactionId,
        this.props.transactionType,
        AccountStore.getRegion(),
        this.state.historyItem?.Balance ?? 0,
      );

      if (response.statuscode === 200) {
        FirebaseAnalytic.trackEvent(
          'handleEmailReceipt',
          'PurchaseHistoryDetail',
          this.props,
        );
        this.setState({
          showModal: true,
        });
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  async handleEmailFundingReceipt() {
    const parsedDate = Util.parseDateTime(this.state.detail.DateString);
    let response;
    try {
      response = await ActionsFactory.getAccountActions().emailFundingReceipt(
        AccountStore.getEmail(),
        Util.formatCurrency(
          this.props,
          this.state.historyItem?.Amount ?? 0,
          this.props.transactionCurrency ?? AccountStore.getCurrency(),
        ),
        parsedDate.date,
        parsedDate.time,
        this.state.historyItem?.Location ?? AccountStore.getLocationName(),
        this.state.detail.Id,
        Util.formatCurrency(
          this.props,
          this.state.historyItem?.Balance ?? 0,
          this.props.transactionCurrency ?? AccountStore.getCurrency(),
        ),
        AccountStore.getFirstName(),
      );

      if (response.status === 'ok') {
        this.setState({
          showModal: true,
        });
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailFundingReceipt',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailFundingReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  async handleEmailRefundReceipt() {
    const parsedDate = Util.parseDateTime(this.state.detail.DateString);
    let response;
    try {
      response = await ActionsFactory.getAccountActions().emailRefundReceipt(
        AccountStore.getEmail(),
        Util.formatCurrency(
          this.props,
          this.state.historyItem?.Amount ?? 0,
          this.props.transactionCurrency ?? AccountStore.getCurrency(),
        ),
        parsedDate.date,
        parsedDate.time,
        Util.getLast4(this.state.detail.Name),
        this.state.detail.Id,
        Util.formatCurrency(
          this.props,
          this.state.historyItem?.Balance ?? 0,
          this.props.transactionCurrency ?? AccountStore.getCurrency(),
        ),
      );

      if (response.status === 'ok') {
        this.setState({
          showModal: true,
        });
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailRefundReceipt',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'PurchaseHistoryDetailScreen:HandleEmailRefundReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  handleRefund() {
    if (this.state.historyItem?.past90Days) {
      alertError(Localized.Labels.refund_past_90_days);
      return;
    }

    confirm(
      Localized.Labels.refund_confirmation_message,
      async () => {
        this.context.actions.showSpinner();
        let response;
        try {
          response = await ActionsFactory.getAccountActions().issueRefund(
            this.props.transactionId,
            AccountStore.getAccountId(),
            Util.getCurrentDate(),
            AccountStore.getAccountBalanceId(),
          );

          if (response.transactionHistoryId) {
            Events.RefundRequest.trackEvent(
              this.props.transactionId,
              this.state.historyItem?.Amount ?? 0,
            );
            const balance = AccountStore.getAccountBalance();

            this.props.dispatch(
              adjustDefaultBalance({
                amount: -response.amount,
                reason: Localized.Labels.refund,
              }),
            );
            this.props.dispatch(fetchAccount(AccountStore.getAccountId()));

            NavActions.replace(AppRoutes.RefundSummary, {
              refundRequest: this.state.detail.Amount,
              balanceBeforeRefund: balance,
              balanceAfterRefund: response.balance,
              refundAmount: response.amount,
            });
            // Get the new balance, and update the purchase history
            ActionsFactory.getAccountActions().getBalance(
              AccountStore.getAccountId(),
              true,
            );
            ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              1,
            );
          } else {
            throw new Error(response.msg);
          }
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'PurchaseHistoryDetailScreen:HandleRefund',
            generateErrorMessage(error),
            guid,
            response,
          );
          Events.Error.trackEvent(
            'Exception',
            'PurchaseHistoryDetailScreen:HandleRefund',
            generateErrorMessage(error),
            guid,
          );
          alertError(Localized.Errors.error_issuing_refund, guid);
        } finally {
          this.context.actions.hideSpinner();
        }
      },
      undefined,
      Localized.Labels.confirm_refund,
      Localized.Buttons.cancel,
      Localized.Buttons.ok,
    );
  }

  handleDone() {
    if (Settings.isRevolve() && this.props.checkout) {
      NavActions.reset(AppRoutes.RevolveHome);
    } else if (
      Settings.isNewUI() &&
      Platform.OS == 'web' &&
      this.props.checkout
    ) {
      NavActions.reset(AppRoutes.Home);
    } else if (Settings.isNewUI() && this.props.checkout) {
      NavActions.reset(AppRoutes.NewHome);
    } else {
      NavActions.popToTop();
    }
  }

  mapTransactionToState() {
    const {transaction} = this.props;
    const taxObj = {};
    for (let i = 0; i < transaction.Taxes.length; i++) {
      const tax = transaction.Taxes[i];
      taxObj[`Tax${i + 1}`] = +tax.TaxAmount;
      taxObj[`Tax${i + 1}Label`] = tax.Description;
      taxObj[`TaxCat${i + 1}`] = null;
    }
    const result = {
      ...taxObj,
      Id: this.props.transactionId,
      DateLong: new Date().getTime(),
      DateString: this.props.transactionDate,
      Amount: +transaction.Due,
      Tax: transaction.Taxes.reduce((total, val) => total + +val.TaxAmount, 0),
      OrderStatus: 'Sent',
      OrderNumber: this.props.orderNumber,
      PickupDate: this.props.pickupTime.date,
      PickupTime: this.props.pickupTime.time,
      PickupLocation: this.props.pickupLocation,
      PickupNotes: this.props.pickupNotes,
      Location: this.props.locationName,
      Items: transaction.Items.map((item) => ({
        Id: this.props.transactionId,
        Name: item.Description,
        Price:
          Number(item.Price) +
          (item.Modifiers?.reduce((m, n) => m + Number(n.Price), 0) ?? 0),
        Quantity: item.LineNumber,
        Modifiers:
          item.Modifiers?.map((m) => ({
            ...m,
            Price: Number(m.Price),
          })) ?? [],
        TransactionChargeName: item.TransactionChargeName,
        TransactionChargeAmount: item.TransactionChargeAmount,
      })),
      Payments: [
        {
          Id: this.props.transactionId,
          Amount: +transaction.Due,
          Type: 'ACCOUNT',
        },
        {
          Id: this.props.transactionId,
          Amount: this.props.discount,
          Type: 'PROMOTION',
        },
      ],
    };
    return result;
  }

  render() {
    if (Object.keys(this.state.detail).length > 0) {
      if (
        this.props.transactionType.toUpperCase() ===
        AccountConstants.SALE_TYPE.toUpperCase()
      ) {
        return this.renderPurchase();
      } else if (
        this.props.transactionType.toUpperCase() ===
        AccountConstants.FUNDING_TYPE.toUpperCase()
      ) {
        return this.renderFunding();
      } else if (
        this.props.transactionType.toUpperCase() ===
        AccountConstants.REDEMPTION_TYPE.toUpperCase()
      ) {
        return this.renderRedemption();
      } else if (
        this.props.transactionType.toUpperCase() ===
        AccountConstants.REFUND_TYPE.toUpperCase()
      ) {
        return this.renderRefund();
      }
    }

    return this.renderEmpty();
  }

  renderEmpty() {
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Buttons.receipt}
      />
    );
  }

  renderFunding() {
    if (typeof this.state.detail.Amount === 'undefined') {
      return this.renderEmpty();
    }

    const detailList = (
      <FundingDetail
        detail={{
          ...this.state.detail,
          Location: this.state.historyItem?.Location,
          Balance: this.state.historyItem?.Balance,
          Source: this.state.historyItem?.Source,
          past90Days: this.state.historyItem?.past90Days,
        }}
        strings={Localized}
        handleEmailReceipt={this.handleEmailFundingReceipt}
        handleRefund={this.handleRefund}
        previousRoute={this.state.previousRoute}
      />
    );
    return this.renderContainer(detailList);
  }

  renderContainer(content: JSX.Element): JSX.Element {
    return (
      <>
        <BaseScreen
          previousRoute={this.state.previousRoute}
          accessibilityLabel={Localized.Buttons.back_arrow}
          aria-label={Localized.Buttons.back_arrow}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={
            this.state.previousRoute.includes('History')
              ? this.checkFundingTransactionDisplay()
              : getDescriber()?.fundingHeaderTitle()
          }
        >
          <View style={styles.content}>{content}</View>
        </BaseScreen>
        <View>
          {this.state.showModal && (
            <SuccessDialog
              title={Localized.Success.receipt_send_success}
              desc="Desc"
            ></SuccessDialog>
          )}
        </View>
      </>
    );
  }

  renderRefund() {
    const refundDetail = (
      <RefundDetail
        detail={{
          ...this.state.detail,
          Location: this.state.historyItem?.Location,
          Balance: this.state.historyItem?.Balance,
          Source: this.state.historyItem?.Source,
        }}
        strings={Localized}
        handleEmailReceipt={this.handleEmailRefundReceipt}
        handleRefund={this.handleRefund}
        currency={this.state.historyItem.currency}
      />
    );
    return this.renderContainer(refundDetail);
  }

  renderRedemption() {
    if (this.state.historyItem || this.state.detail) {
      const redemptionDetail = (
        <RedemptionDetail
          detail={{...this.state.detail, ...this.state.historyItem}}
          strings={Localized}
        />
      );
      return this.renderContainer(redemptionDetail);
    } else {
      NavActions.navigate(AppRoutes.ServerErrorDialog, {
        errorTitle: Localized.Labels.error_getting_receipt,
        errorDesc: Localized.Labels.error_server,
      });
      return this.renderEmpty();
    }
  }

  getTranslatedPaymentType(payment: Record<string, string>) {
    if (payment.Type.toLowerCase() === 'account') {
      return Localized.Labels.account;
    }

    return payment.Type || Localized.Labels.account;
  }

  getTaxLabel(label: string, number?: number): string {
    return getTaxDescription(Localized, label, number);
  }

  getPickupLocation(): string | null | undefined {
    if ((this.state.detail as TransactionDetail).PickupLocation) {
      return (this.state.detail as TransactionDetail).PickupLocation;
    }

    if (this.state.detail.Items) {
      const pickupLocationItem = this.state.detail.Items.find((item) =>
        item.Name.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
      );

      if (
        pickupLocationItem &&
        pickupLocationItem.Modifiers &&
        pickupLocationItem.Modifiers.length === 1
      ) {
        return pickupLocationItem.Modifiers[0].Name;
      }
    }
  }
  checkTransactionDisplay() {
    return ['Market Purchase', 'Mobile Purchase'].indexOf(
      this.props.transactionTypeDisplay,
    ) !== -1
      ? this.props.transactionTypeDisplay
      : Localized.Labels.order_ahead;
  }

  checkFundingTransactionDisplay() {
    return ['Loyalty Credit'].indexOf(this.props.transactionTypeDisplay) !== -1
      ? this.props.transactionTypeDisplay
      : getDescriber()?.fundingHeaderTitle();
  }
  getDisplayItems() {
    let items = [];
    if (this.state.detail.Items) {
      items = this.state.detail.Items.filter(
        (item) => !item.Name.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
      );
    }

    return items;
  }

  handleLayout(event) {
    // Get the content height and set the state
    const {height} = event.nativeEvent.layout;
    this.setState({
      contentHeight: height,
    });
  }

  getOrderStatusDisplay(status: string) {
    if (status === OrderStatusEnum.Preparing) {
      return Localized.Labels.preparing_order;
    } else if (status === OrderStatusEnum.Ready) {
      return Localized.Labels.order_ready;
    } else if (status === OrderStatusEnum.Received) {
      return Localized.Labels.order_received;
    } else if (status === OrderStatusEnum.Retrieved) {
      return Localized.Labels.order_retrieved;
    } else if (status === OrderStatusEnum.Expired) {
      return Localized.Labels.order_expired;
    }

    return Localized.Labels.order_sent;
  }

  isOrderAhead(): string {
    return (this.state.detail as TransactionDetail).PickupTime;
  }

  renderPurchase() {
    const {Delivery, OrderStatus} = this.state.detail as TransactionDetail;
    let qrCodeSection: JSX.Element | null = null;

    if (
      Delivery === PreparationMethodValues.Locker &&
      OrderStatus !== OrderStatusEnum.Expired &&
      OrderStatus !== OrderStatusEnum.Retrieved
    ) {
      const code = Util.getTransactionCode(this.props.transactionId);
      qrCodeSection = (
        <View style={styles.codeContainer}>
          <View
            accessible={true}
            accessibilityLabel={`${Localized.Labels.qr_code}, ${code}`}
            aria-label={`${Localized.Labels.qr_code}, ${code}`}
          >
            <QRCode value={code} size={Styles.Heights.h8} />
          </View>
          <AVText
            accessible={true}
            accessibilityLabel={Localized.Labels.scan_this_qr_code}
            aria-label={Localized.Labels.scan_this_qr_code}
            style={[
              styles.codeLabel,
              {
                marginTop: Styles.Spacing.m2,
              },
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
          >
            {Localized.Labels.scan_this_qr_code}
          </AVText>
          <AVText
            style={[
              styles.label,
              {
                marginTop: Styles.Spacing.m2,
              },
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
          >
            {Localized.Labels.or}
          </AVText>
          <AVText
            style={[
              styles.codeLabel,
              {
                marginBottom: Styles.Spacing.m2,
              },
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          >
            {Localized.Labels.formatString(Localized.Labels.enter_code, code)}
          </AVText>
        </View>
      );
    }
    const displayItems = this.getDisplayItems();
    const detailList = displayItems.map((item, index) => (
      <View
        key={index}
        style={getDescriber().getStyleDescriptor()['sectionMarginLeftWithZero']}
      >
        <SaleDetail
          detail={item}
          currency={this.props.transactionCurrency}
          cartcheckOut={this.props.checkout}
        />
        <View
          style={[index < displayItems.length - 1 && styles.bottomMargin]}
        />
      </View>
    ));

    let actionButton: JSX.Element | null = null;
    let emailReceiptButton: JSX.Element | null = null;

    if (
      !this.state.previousRoute.includes('History') &&
      AccountStore.getRegion() !== 'GBR' &&
      !Util.isDatePastNinetyDays(this.props.transactionDate)
    ) {
      actionButton = (
        <RoundedButton
          buttonType={ButtonType.action}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessible={true}
          accessibilityLabel={Localized.Buttons.email_receipt}
          accessibilityRole="button"
          aria-label={Localized.Buttons.email_receipt}
          role="button"
          onPress={this.handleEmailReceipt}
          text={Localized.Buttons.email_receipt}
        />
      );

      if (
        this.props.checkout &&
        !Util.isDatePastNinetyDays(this.props.transactionDate)
      ) {
        emailReceiptButton = (
          <RoundedButton
            buttonType={ButtonType.outline}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessible={true}
            accessibilityLabel={Localized.Buttons.email_receipt}
            accessibilityRole="button"
            role="button"
            aria-label={Localized.Labels.email_receipt}
            onPress={this.handleEmailReceipt}
            text={Localized.Buttons.email_receipt}
            containerStyle={styles.receiptButton}
          />
        );
      }
    } else if (this.state.previousRoute.includes('History')) {
      actionButton = getDescriber().historyEmailReceiptBtn(
        this.handleEmailReceipt,
        styles,
        '',
        AccountStore.getRegion(),
        this.state.previousRoute?.includes('History'),
      );
    }

    if (this.props.checkout) {
      actionButton = (
        <RoundedButton
          buttonType={ButtonType.action}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessible={true}
          accessibilityLabel={Localized.Buttons.done}
          accessibilityRole="button"
          aria-label={Localized.Buttons.done}
          role="button"
          onPress={this.handleDone}
          text={Localized.Buttons.done}
          color={Settings.isRevolve() && Styles.darkGreenRevolve}
          containerStyle={
            Settings.isRevolve() && {
              paddingHorizontal: 12,
              paddingVertical: 8,
            }
          }
          textStyle={
            Settings.isRevolve() && [
              styles.buttonText,
              {
                textTransform: 'uppercase',
                fontSize: Styles.Fonts.f1 + 2,
              },
            ]
          }
        />
      );
    }

    if (this.isOrderAhead()) {
      emailReceiptButton = null;

      if (this.isReceiptShared()) {
        actionButton = null;
      } else if (Platform.OS === 'web') {
        actionButton = (
          <View style={[Styles.Style.row, styles.rowReverse]}>
            {this.props.checkout && (
              <RoundedButton
                buttonType={ButtonType.normal}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessible={true}
                accessibilityLabel={Localized.Buttons.done}
                accessibilityRole="button"
                aria-label={Localized.Buttons.done}
                role="button"
                onPress={this.handleDone}
                containerStyle={[
                  Styles.Style.actionButton,
                  styles.buttonSpacing,
                ]}
                textStyle={Styles.Style.actionButtonText}
                text={Localized.Buttons.done}
              />
            )}
            <RoundedButton
              buttonType={ButtonType.normal}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              accessible={true}
              accessibilityLabel={Localized.Labels.print_receipt}
              accessibilityRole="button"
              aria-label={Localized.Labels.print_receipt}
              onPress={() => {
                FirebaseAnalytic.trackEvent(
                  Localized.Labels.print_receipt,
                  'PurchaseHistoryDetail',
                  this.props,
                );
                window.print();
              }}
              containerStyle={[Styles.Style.actionButton, styles.buttonSpacing]}
              textStyle={Styles.Style.actionButtonText}
              text={Localized.Labels.print_receipt}
            />

            <>
              <RoundedButton
                buttonType={ButtonType.normal}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessible={true}
                accessibilityLabel={Localized.Labels.text_receipt}
                accessibilityRole="button"
                aria-label={Localized.Labels.text_receipt}
                role="button"
                onPress={() => this.toShareReceiptScreen(InputTypes.text)}
                containerStyle={[
                  Styles.Style.actionButton,
                  styles.buttonSpacing,
                ]}
                textStyle={Styles.Style.actionButtonText}
                text={Localized.Labels.text_receipt}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessible={true}
                accessibilityLabel={Localized.Labels.email_receipt}
                accessibilityRole="button"
                aria-label={Localized.Labels.email_receipt}
                role="button"
                onPress={() => this.toShareReceiptScreen(InputTypes.email)}
                containerStyle={Styles.Style.actionButton}
                textStyle={Styles.Style.actionButtonText}
                text={Localized.Labels.email_receipt}
              />
            </>
          </View>
        );
      } else if (this.state.previousRoute.includes('History')) {
        emailReceiptButton = getDescriber().historyEmailReceiptBtn(
          this.handleEmailReceipt,
          styles,
        );
      } else {
        actionButton = getDescriber().getActionBtns(
          this.handleDone,
          this.handleEmailReceipt,
        );
      }
    }

    let bottleDepositLabel: JSX.Element | null = null;
    let bottleDepositAmount: JSX.Element | null = null;

    if (
      this.state.detail.Deposit &&
      Number((this.state.detail as PurchaseDetailType).Deposit) > 0
    ) {
      bottleDepositLabel = (
        <AVText
          accessible={true}
          accessibilityLabel={`${Localized.Labels.deposit} label`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.deposit} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
        >
          {Localized.Labels.deposit}
        </AVText>
      );
      bottleDepositAmount = (
        <AVFormattedCurrency
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={Number((this.state.detail as PurchaseDetailType).Deposit) || 0}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.deposit} $${
            this.state.detail.Deposit || 0
          }`}
          aria-label={`${Localized.Labels.deposit} $${
            this.state.detail.Deposit || 0
          }`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        />
      );
    }

    let discountLabel: JSX.Element | null = null;
    let discountAmount: JSX.Element | null = null;

    if (this.state.detail.Payments) {
      const discountPayments = this.state.detail.Payments.filter((p) =>
        this.DISCOUNT_PAYMENT_TYPES.includes(p.Type),
      );

      if (discountPayments && discountPayments.length > 0) {
        const totalDiscount = discountPayments.reduce(
          (a, b) => a + b.Amount,
          0,
        );
        if (totalDiscount != 0) {
          discountLabel = (
            <AVText
              accessible={true}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              accessibilityLabel={`${Localized.Labels.discounts} label`}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.discounts} label, text`}
              style={[
                getDescriber().PurchaseDetailDiscountStyleDescriptor(),
                styles.discountLabel,
              ]}
            >
              {Localized.Labels.discounts}
            </AVText>
          );
          discountAmount = (
            <AVFormattedCurrency
              style={[
                getDescriber().PurchaseDetailDiscountStyleDescriptor(),
                styles.discountLabel,
              ]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              value={totalDiscount * -1}
              accessible={true}
              accessibilityLabel={`${Localized.Labels.discounts} $${
                totalDiscount * -1
              }`}
              aria-label={`${Localized.Labels.discounts} $${
                totalDiscount * -1
              }, text`}
              currency={
                this.props.transactionCurrency ?? AccountStore.getCurrency()
              }
            />
          );
        }
      }
    }

    let taxDisplayed = false;
    let tax1Label: JSX.Element | null = null;
    let tax1Amount: JSX.Element | null = null;

    if (this.state.detail.Tax1 || this.state.detail.Tax1Label) {
      taxDisplayed = true;
      tax1Label = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax1Label,
          )} label`}
          accessibilityRole="text"
          aria-label={`${this.getTaxLabel(
            this.state.detail.Tax1Label,
          )} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {this.getTaxLabel(this.state.detail.Tax1Label)}
        </AVText>
      );
      tax1Amount = (
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={this.state.detail.Tax1 || 0}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax1Label,
          )} $${this.state.detail.Tax1 || 0}`}
          aria-label={`${this.getTaxLabel(this.state.detail.Tax1Label)} $${
            this.state.detail.Tax1 || 0
          }`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
        />
      );
    }

    let tax2Label: JSX.Element | null = null;
    let tax2Amount: JSX.Element | null = null;

    if (this.state.detail.Tax2 || this.state.detail.Tax2Label) {
      taxDisplayed = true;
      tax2Label = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax2Label,
            2,
          )} label`}
          accessibilityRole="text"
          aria-label={`${this.getTaxLabel(
            this.state.detail.Tax2Label,
            2,
          )} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {this.getTaxLabel(this.state.detail.Tax2Label, 2)}
        </AVText>
      );
      tax2Amount = (
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={this.state.detail.Tax2 || 0}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax2Label,
            2,
          )} $${this.state.detail.Tax2 || 0}`}
          aria-label={`${this.getTaxLabel(this.state.detail.Tax2Label, 2)} $${
            this.state.detail.Tax2 || 0
          }`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
        />
      );
    }

    let tax3Label: JSX.Element | null = null;
    let tax3Amount: JSX.Element | null = null;

    if (this.state.detail.Tax3 || this.state.detail.Tax3Label) {
      taxDisplayed = true;
      tax3Label = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax3Label,
            3,
          )} label`}
          accessibilityRole="text"
          aria-label={`${this.getTaxLabel(
            this.state.detail.Tax3Label,
            3,
          )} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {this.getTaxLabel(this.state.detail.Tax3Label, 3)}
        </AVText>
      );
      tax3Amount = (
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={this.state.detail.Tax3 || 0}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            this.state.detail.Tax3Label,
            3,
          )} $${this.state.detail.Tax3 || 0}`}
          aria-label={`${this.getTaxLabel(this.state.detail.Tax3Label, 3)} $${
            this.state.detail.Tax3 || 0
          }`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
        />
      );
    }

    let tax4Label: JSX.Element | null = null;
    let tax4Amount: JSX.Element | null = null;

    if (
      (this.state.detail as PurchaseDetailType).Tax4 ||
      (this.state.detail as PurchaseDetailType).Tax4Label
    ) {
      taxDisplayed = true;
      tax4Label = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            (this.state.detail as PurchaseDetailType).Tax4Label,
            4,
          )} label`}
          accessibilityRole="text"
          aria-label={`${this.getTaxLabel(
            (this.state.detail as PurchaseDetailType).Tax4Label,
            4,
          )} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {this.getTaxLabel(
            (this.state.detail as PurchaseDetailType).Tax4Label,
            4,
          )}
        </AVText>
      );
      tax4Amount = (
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={(this.state.detail as PurchaseDetailType).Tax4 || 0}
          accessible={true}
          accessibilityLabel={`${this.getTaxLabel(
            (this.state.detail as PurchaseDetailType).Tax4Label,
            4,
          )} $${(this.state.detail as PurchaseDetailType).Tax4 || 0}`}
          aria-label={`${this.getTaxLabel(
            (this.state.detail as PurchaseDetailType).Tax4Label,
            4,
          )} $${(this.state.detail as PurchaseDetailType).Tax4 || 0}`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
        />
      );
    }

    if (!taxDisplayed) {
      tax1Label = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.tax} label`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.tax} label, text`}
          style={[
            getDescriber().PurchaseHistorySubtotalStyleDescriptor(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {Localized.Labels.tax}
        </AVText>
      );
      tax1Amount = (
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
            this.state.previousRoute.includes('History'),
          )}
          value={this.state.detail.Tax || 0}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.tax} ${
            Localized.Labels.tax
          } $${this.state.detail.Tax || 0}`}
          aria-label={`${Localized.Labels.tax} ${Localized.Labels.tax} $${
            this.state.detail.Tax || 0
          }`}
          currency={
            this.props.transactionCurrency ?? AccountStore.getCurrency()
          }
        />
      );
    }

    let points: JSX.Element | null = null;

    if (
      (this.state.detail as PurchaseDetailType).Points > 0 &&
      !Settings.isRevolve()
    ) {
      points = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessible={true}
          accessibilityLabel={`${
            (this.state.detail as PurchaseDetailType).Points
          } ${Localized.Labels.points_earned}, text`}
          aria-label={`${(this.state.detail as PurchaseDetailType).Points} ${
            Localized.Labels.points_earned
          }, text`}
          style={
            getDescriber().getStyleDescriptor(
              this.state.previousRoute.includes('History'),
              this.props.transactionTypeDisplay,
            )['points']
          }
        >
          {(this.state.detail as PurchaseDetailType).Points}{' '}
          {Localized.Labels.points_earned}
        </AVText>
      );
    }

    const locationAddress = Util.getLocationAddress({
      address: (this.state.detail as PurchaseDetailType).LocationAddress,
      city: (this.state.detail as PurchaseDetailType).LocationCity,
      state: (this.state.detail as PurchaseDetailType).LocationState,
    });
    let locationAddressDisplay: JSX.Element | null = null;

    if (locationAddress) {
      locationAddressDisplay = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          accessible={true}
          accessibilityLabel={`Location ${locationAddress}`}
          aria-label={`Location ${locationAddress}`}
          style={[
            styles.locationAddress,
            getDescriber().payLocationAddr(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          {locationAddress}
        </AVText>
      );
    }

    let pickupDisplay: JSX.Element | null = null;

    if ((this.state.detail as TransactionDetail).PickupTime) {
      const momentPickupDate = moment(
        (this.state.detail as TransactionDetail).PickupDate,
      );
      const momentPickupTime = moment(
        (this.state.detail as TransactionDetail).PickupTime,
        'LT',
      );

      pickupDisplay = (
        <>
          <AVText
            testID="pickupDate"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessible={true}
            accessibilityLabel={`${
              Localized.Labels.pickup_date
            }: ${momentPickupDate.format('MM/DD/yyyy')}`}
            accessibilityRole="text"
            aria-label={`${
              Localized.Labels.pickup_date
            }: ${momentPickupDate.format('MM/DD/yyyy')}, text`}
            style={[
              getDescriber().getStyleDescriptor(
                this.state.previousRoute.includes('History'),
              )['pickupDate'],
            ]}
          >
            {`${Localized.Labels.pickup_date}: ${momentPickupDate.format(
              'MM/DD/yyyy',
            )}`}
          </AVText>
          <AVText
            testID="pickupTime"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessible={true}
            accessibilityLabel={`${
              Localized.Labels.pickup_time
            }: ${momentPickupTime.format('LT')}}`}
            accessibilityRole="text"
            aria-label={`${
              Localized.Labels.pickup_time
            }: ${momentPickupTime.format('LT')}}, text`}
            style={[
              getDescriber().getStyleDescriptor(
                this.state.previousRoute.includes('History'),
              )['pickupTime'],
            ]}
          >
            {`${Localized.Labels.pickup_time}: ${momentPickupTime.format(
              'LT',
            )}`}
          </AVText>
        </>
      );
    }

    let orderStatusSection: JSX.Element | null = null;
    if (
      (this.state.detail as TransactionDetail).OrderStatus &&
      ['Market Purchase', 'Mobile Purchase'].indexOf(
        this.props.transactionTypeDisplay,
      ) === -1
    ) {
      orderStatusSection = (
        <View
          style={[
            styles.orderStatusContainer,
            getDescriber().payOrderStatus(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={`${Localized.Labels.order_status}`}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.order_status}, text`}
            style={[
              getDescriber().getStyleDescriptor(
                this.state.previousRoute.includes('History'),
              )['orderStatus'],
            ]}
          >
            {`${Localized.Labels.order_status}: `}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={`${this.getOrderStatusDisplay(
              (this.state.detail as TransactionDetail).OrderStatus,
            )}`}
            accessibilityRole="text"
            aria-label={`${this.getOrderStatusDisplay(
              (this.state.detail as TransactionDetail).OrderStatus,
            )}, text`}
            style={[
              getDescriber().getStyleDescriptor(
                this.state.previousRoute.includes('History'),
              )['orderStatusValue'],
            ]}
          >
            {this.getOrderStatusDisplay(
              (this.state.detail as TransactionDetail).OrderStatus,
            )}
          </AVText>
        </View>
      );
    }

    let orderNumberSection: JSX.Element | null = null;
    if (
      (this.state.detail as TransactionDetail).OrderNumber &&
      ['Market Purchase', 'Mobile Purchase'].indexOf(
        this.props.transactionTypeDisplay,
      ) === -1
    ) {
      orderNumberSection = (
        <View
          style={[
            styles.orderStatusContainer,
            getDescriber().payOrderNumber(
              this.state.previousRoute.includes('History'),
            ),
          ]}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessible={true}
            accessibilityLabel={Localized.Labels.order_number}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.order_number}:, text`}
            numberOfLines={2}
            style={[
              styles.orderStatus,
              getDescriber().getStyleDescriptor(
                this.state.previousRoute.includes('History'),
              )['orderStatus'],
            ]}
          >
            {`${Localized.Labels.order_number}: `}
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityLabel={`$${
                (this.state.detail as TransactionDetail).orderNumber
              }`}
              accessibilityRole="text"
              aria-label={`$${
                (this.state.detail as TransactionDetail).OrderNumber
              }}`}
              style={[
                styles.orderStatusValue,
                getDescriber().getStyleDescriptor(
                  this.state.previousRoute.includes('History'),
                )['orderStatusValue'],
              ]}
            >
              {(this.state.detail as TransactionDetail).OrderNumber}
            </AVText>
          </AVText>
        </View>
      );
    }

    let pickupNotesDisplay: JSX.Element | null = null;
    const pickupNotes =
      (this.state.detail as TransactionDetail).PickupNotes ??
      this.props.pickupNotes;

    if (pickupNotes && this.isOrderAhead()) {
      const formattedPickupNotes = Localized.Labels.formatString(
        Localized.Labels.pickup_instruction,
        pickupNotes,
      );
      pickupNotesDisplay = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${pickupNotes} label`}
          accessibilityRole="text"
          aria-label={`${pickupNotes}, text`}
          style={getDescriber().getStyleDescriptor()['instructions']}
        >
          {formattedPickupNotes}
        </AVText>
      );
    }

    let lockerPickupNotesDisplay: JSX.Element | null = null;

    if (
      Delivery === PreparationMethodValues.Locker &&
      (this.state.detail as TransactionDetail).timer
    ) {
      const lockerPickupNote = Localized.Labels.formatString(
        Localized.Labels.locker_pickup_instruction,
        (this.state.detail as TransactionDetail).timer,
      );
      lockerPickupNotesDisplay = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
          accessible={true}
          accessibilityLabel={`${lockerPickupNote.toString()}, text`}
          accessibilityRole="text"
          aria-label={lockerPickupNote.toString()}
          style={getDescriber().getStyleDescriptor()['instructions']}
        >
          {lockerPickupNote}
        </AVText>
      );
    }

    let locationText = this.state.detail.Location;
    const pickupLocation = this.getPickupLocation();

    if (pickupLocation) {
      locationText = `${locationText}: ${pickupLocation}`;
    }

    let charityDisplay: JSX.Element | null = null;
    if ((this.state.detail as TransactionDetail).charityAmount) {
      charityDisplay = (
        <>
          <View style={styles.bottomMargin} />
          <View
            style={[
              styles.row,
              getDescriber().getStyleDescriptor()['charityRow'],
            ]}
          >
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
              accessible={true}
              accessibilityLabel={Localized.Labels.charity_round_up}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.charity_round_up}, text`}
              numberOfLines={1}
              style={styles.item}
            >
              {Localized.Labels.charity_round_up}
            </AVText>
            <AVFormattedCurrency
              style={styles.amount}
              value={Number(
                (this.state.detail as TransactionDetail).charityAmount,
              )}
              accessible={true}
              accessibilityLabel={`$${
                (this.state.detail as TransactionDetail).charityAmount
              }`}
              aria-label={`$${
                (this.state.detail as TransactionDetail).charityAmount
              }`}
              currency={
                this.props.transactionCurrency ?? AccountStore.getCurrency()
              }
            />
          </View>
        </>
      );
    }

    return (
      <>
        <BaseScreen
          previousRoute={this.state.previousRoute}
          accessibilityLabel={Localized.Buttons.back_arrow}
          aria-label={Localized.Buttons.back_arrow}
          accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
          title={
            this.state.previousRoute.includes('History')
              ? this.checkTransactionDisplay()
              : Localized.Buttons.receipt
          }
          hideBack={this.props.checkout}
        >
          <View style={styles.purchaseContent}>
            <ScrollView
              style={styles.historyContainer}
              showsVerticalScrollIndicator={false}
              automaticallyAdjustContentInsets={false}
              onLayout={this.handleLayout}
            >
              {qrCodeSection}
              {orderNumberSection}
              {!Settings.isRevolve() && orderStatusSection}
              {['Market Purchase', 'Mobile Purchase'].indexOf(
                this.props.transactionTypeDisplay,
              ) !== -1 && (
                <View>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
                    style={[
                      getDescriber().getStyleDescriptor(
                        this.state.previousRoute.includes('History'),
                      )['location'],
                      {marginTop: 8},
                    ]}
                  >
                    {locationText}
                  </AVText>
                  {locationAddressDisplay}
                  {points}
                </View>
              )}
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                style={[
                  getDescriber().PurchaseDetailFormateedDateStyleDescriptor(),
                ]}
              >
                {Util.formatDate(this.state.detail.DateString, 'LLL')}
              </AVText>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
                style={[
                  getDescriber().PurchaseDetailIdStyleDescriptor(
                    this.state.previousRoute.includes('History'),
                  ),
                ]}
              >
                {this.state.detail.Id}
              </AVText>
              {['Market Purchase', 'Mobile Purchase'].indexOf(
                this.props.transactionTypeDisplay,
              ) === -1 && (
                <>
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
                    style={[getDescriber().getStyleDescriptor()['location']]}
                  >
                    {locationText}
                  </AVText>
                  {locationAddressDisplay}
                  {pickupDisplay}
                  {pickupNotesDisplay}
                  {lockerPickupNotesDisplay}
                  {points}
                </>
              )}
              {!Settings.isRevolve() && emailReceiptButton}
              <View style={styles.topFiller} />
              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(
                  Settings.isRevolve() ? 1 : 4,
                )}
                dashLength={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashLength'
                  ]
                }
                dashThickness={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashThickness'
                  ]
                }
                dashColor={
                  getDescriber().getDashColor()['dashStyle']['dashColor']
                }
                style={styles.dashStyle}
              />
              {detailList}
              {charityDisplay}
              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(
                  Settings.isRevolve() ? 1 : 4,
                )}
                dashLength={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashLength'
                  ]
                }
                dashThickness={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashThickness'
                  ]
                }
                dashColor={
                  getDescriber().getDashColor()['dashStyle']['dashColor']
                }
                style={styles.dashStyle}
              />
              <View
                style={[
                  Styles.Style.summarySection,
                  getDescriber().getStyleDescriptor()['summaryTopMargin'],
                ]}
              >
                <View
                  style={[
                    Styles.Style.labelColumn,
                    getDescriber().getStyleDescriptor()['sectionMarginLeft'],
                  ]}
                >
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                    accessible={true}
                    accessibilityLabel={`${Localized.Labels.subtotal} label`}
                    accessibilityRole="text"
                    aria-label={`${Localized.Labels.subtotal} label, text`}
                    style={[
                      getDescriber().PurchaseHistorySubtotalStyleDescriptor(
                        this.state.previousRoute.includes('History'),
                      ),
                    ]}
                  >
                    {Localized.Labels.subtotal}
                  </AVText>
                  {bottleDepositLabel}
                  {tax1Label}
                  {tax2Label}
                  {tax3Label}
                  {tax4Label}
                  {discountLabel}
                </View>
                <View
                  style={[
                    Styles.Style.amountColumn,
                    getDescriber().getStyleDescriptor()[
                      'sectionMarginRightWithZero'
                    ],
                  ]}
                >
                  <AVFormattedCurrency
                    style={getDescriber().PurchaseHistorySubtotalStyleDescriptor(
                      this.state.previousRoute.includes('History'),
                    )}
                    value={this.state.subtotal || 0}
                    accessible={true}
                    accessibilityLabel={`${Localized.Labels.subtotal} $${
                      this.state.subtotal || 0
                    }`}
                    accessibilityRole="text"
                    aria-label={`${Localized.Labels.subtotal} $${
                      this.state.subtotal || 0
                    }, text`}
                    currency={
                      this.props.transactionCurrency ??
                      AccountStore.getCurrency()
                    }
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
                  />
                  {bottleDepositAmount}
                  {tax1Amount}
                  {tax2Amount}
                  {tax3Amount}
                  {tax4Amount}
                  {discountAmount}
                </View>
              </View>

              <DashedLine
                dashGap={PixelRatio.roundToNearestPixel(
                  Settings.isRevolve() ? 1 : 4,
                )}
                dashLength={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashLength'
                  ]
                }
                dashThickness={
                  getDescriber().getDashedStyleDescriptor()['dashStyle'][
                    'dashThickness'
                  ]
                }
                dashColor={
                  getDescriber().getDashColor()['dashStyle']['dashColor']
                }
                style={styles.dashStyle}
              />

              <View
                style={[
                  Styles.Style.summarySection,
                  getDescriber().getStyleDescriptor()['summaryExtra'],
                ]}
              >
                <View
                  style={[
                    Styles.Style.labelColumn,
                    getDescriber().getStyleDescriptor()['sectionMarginLeft'],
                    {
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <AVText
                    accessible={true}
                    accessibilityLabel={Localized.Labels.total}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    aria-label={Localized.Labels.total}
                    style={[
                      getDescriber().getStyleDescriptor(
                        this.state.previousRoute.includes('History'),
                        this.props.transactionTypeDisplay,
                      )['totalLabel'],
                    ]}
                  >
                    {Localized.Labels.total}
                  </AVText>
                </View>

                <View
                  style={[
                    Styles.Style.amountColumn,
                    getDescriber().getStyleDescriptor()[
                      'sectionMarginRightWithZero'
                    ],
                  ]}
                >
                  <AVFormattedCurrency
                    style={[
                      getDescriber().getStyleDescriptor(
                        this.state.previousRoute.includes('History'),
                        this.props.transactionTypeDisplay,
                      )['totalLabel'],
                    ]}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                    value={this.state.detail.Amount || 0}
                    accessible={true}
                    accessibilityLabel={`$${this.state.detail.Amount || 0}`}
                    aria-label={`$${this.state.detail.Amount || 0}`}
                    currency={
                      this.props.transactionCurrency ??
                      AccountStore.getCurrency()
                    }
                  />
                </View>
              </View>
              <View style={styles.bottom} />
              <View style={[{bottom: this.state.contentHeight > 0 ? 10 : 0}]}>
                {Settings.isRevolve() && (
                  <RoundedButton
                    buttonType={ButtonType.outline}
                    containerStyle={styles.buttonContainer}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                    accessible={true}
                    accessibilityLabel={Localized.Buttons.email_receipts}
                    accessibilityRole="button"
                    aria-label={Localized.Buttons.email_receipts}
                    role="button"
                    onPress={this.handleEmailReceipt}
                    text={Localized.Buttons.email_receipts}
                    color={Styles.darkGreenRevolve}
                    textStyle={[
                      styles.buttonText,
                      {textTransform: 'uppercase'},
                    ]}
                  />
                )}
                {actionButton}
              </View>
            </ScrollView>
          </View>
        </BaseScreen>
        <View>
          {this.state.showModal && (
            <SuccessDialog
              title={Localized.Success.receipt_send_success}
              desc="Desc"
            ></SuccessDialog>
          )}
        </View>
      </>
    );
  }

  toShareReceiptScreen(inputType: keyof typeof InputTypes) {
    FirebaseAnalytic.trackEvent(
      'toShareReceiptScreen',
      'PurchaseHistoryDetail',
      {
        ...this.props,
        inputType,
        transactionId: this.props.transactionId,
        transactionDate: this.props.transactionDate,
      },
    );
    NavActions.navigate(AppRoutes.ShareReceipt, {
      inputType,
      transactionId: this.props.transactionId,
      transactionDate: this.props.transactionDate,
    });
  }

  isReceiptShared() {
    return !!this.props.marketAccount;
  }

  async shareReceipt() {
    const {transactionId, transactionDate} = this.props;
    const {PickupLocation, Location} = this.state.detail as TransactionDetail;

    try {
      if (!this.receiptLink) {
        this.receiptLink = await BranchApi.fetchReceiptLink(
          transactionId,
          transactionDate,
          AccountStore.getAccountId(),
          Settings.branchKey,
          Settings.branchFallbackUrls[this.props.env],
        );
      }

      if (!this.receiptLink) {
        throw new Error('Error fetching the receipt branch link');
      }

      const result: any = await Share.share({
        message: Localized.Labels.formatString(
          Localized.Labels.receipt_share_text,
          Location,
          PickupLocation,
          this.receiptLink,
          Util.getTransactionCode(transactionId),
        ) as string,
      });

      FirebaseAnalytic.trackEvent(
        'shareReceipt',
        'PurchaseHistoryDetail',
        result,
      );

      result.url;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'PurchaseHistoryDetailScreen:shareReceipt',
        e,
      );
      Events.Error.trackEvent(
        'Exception',
        'PurchaseHistoryDetailScreen:shareReceipt',
        e,
      );
    }
  }
}

const styles = StyleSheet.create({
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Settings.isRevolve() ? 24 : Styles.Fonts.f3,
    fontWeight: Settings.isRevolve() ? '700' : '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,

    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
  bottomMargin: {
    marginBottom: Styles.Spacing.m3,
  },
  content: {
    flex: 1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    overflow: 'hidden',
    width: '100%',
  },
  date: {
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  discountLabel: {
    marginBottom: Styles.Spacing.m1,
  },
  historyContainer: {
    flexGrow: 1, // Ensures the ScrollView takes up all available space
    paddingBottom: 100,
  },
  bottom: {
    height: 20,
    width: '100%',
  },
  location: {
    marginTop: Styles.Spacing.m3,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
  locationAddress: {
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontSize: Settings.isRevolve() ? Styles.Fonts.f0 + 2 : Styles.Fonts.f0,
    fontWeight: '400',
    color: Styles.darkColor,
  },
  pickupDate: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m2,
  },
  pickupTime: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
  },
  orderNumber: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m2,
  },
  points: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
  },
  instructions: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    fontWeight: '600',
  },
  purchaseContent: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  receiptButton: {
    marginTop: Styles.Spacing.m3,
  },
  summary: {
    marginBottom: Styles.Spacing.m1,
  },
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
  topFiller: {
    height: Styles.Spacing.m2,
  },
  orderStatus: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    color: Styles.darkColor,
  },
  orderStatusValue: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    color: Styles.darkColor,
  },
  codeContainer: {
    flex: 1,
    alignSelf: 'stretch',
    flexDirection: 'column',
    alignItems: 'center',
  },
  codeLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  label: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m2,
  },
  rowReverse: {
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
  },
  buttonSpacing: {
    marginStart: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m1,
  },
  row: {
    alignItems: 'center',
    backgroundColor: Styles.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: Styles.Spacing.m0,
  },
  amount: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  orderStatusContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: Styles.Spacing.m1 + 4,
  },
  item: {
    color: Styles.darkColor,
    flex: 1,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
  },
  buttonContainer: {
    marginBottom: Styles.Spacing.m3,
    marginRight: 130,
    alignSelf: 'flex-end',
    borderWidth: 2,
    paddingVertical: 8,
  },
  buttonText: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
  },
  historyEmailRcptBtn: {
    borderTopColor: '#066DFF',
    borderBottomColor: '#066DFF',
    borderLeftColor: '#066DFF',
    borderRightColor: '#066DFF',
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 20,
    paddingRight: 20,
    bottom: 10,
    right: 10,
    overflow: 'hidden',
    alignSelf: 'flex-end',
  },
  redesignsubTotals: {
    fontSize: 18,
    fontWeight: '400',
    color: '#707070',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  redesignDate: {
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
});

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  };
};

function mapStateToProps(state: RootState) {
  return {
    env: state.environment.env,
    balances: state.account.account.balances,
  };
}

export default compose(
  withForwardedNavigationParams<PurchaseHistoryDetailProps>(),
  withGlobalize,
  connect(mapStateToProps, mapDispatchToProps),
)(PurchaseHistoryDetail);
