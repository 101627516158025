import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import Util, {getPreviousRouteName} from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import BaseScreen from '../BaseScreen';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import ActionsFactory from 'src/actions/ActionsFactory';
import {StyleSheet, View, ScrollView} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import Localized from 'src/constants/AppStrings';
import {alertSuccess, alertError} from '../../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import PasswordInput from 'src/components/PasswordInput';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import Settings from 'src/Settings';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';

type ResetPinProps = {
  isConnected?: boolean;
  navigation?: NavigationProp<ResetPin>;
};

type ResetPinState = {
  pinRequired: boolean;
  newPin: string;
  confirmNewPin: string;
  previousRoute?: string | null;
};

class ResetPin extends React.Component<ResetPinProps, ResetPinState> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ResetPinProps) {
    super(props);
    this.state = {
      newPin: '',
      confirmNewPin: '',
      pinRequired: AccountStore.getPinRequired(),
      previousRoute: null,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
  }

  handleClick() {
    this.handlePinChange();
  }
  saveBtnDisable() {
    if (Settings.is365Pay()) {
      return this.state.newPin.length > 0 && this.state.confirmNewPin.length > 0
        ? false
        : true;
    } else {
      return false;
    }
  }
  async handlePinChange() {
    if (
      this.state.pinRequired !== AccountStore.getPinRequired() &&
      !this.state.pinRequired &&
      !this.state.newPin
    ) {
      try {
        await ActionsFactory.getAccountActions().pinChanged(
          AccountStore.getAccountId(),
          '',
          this.state.pinRequired,
        );

        FirebaseAnalytic.trackEvent('handlePinChange', 'ResetPinScreen', {
          ...this.props,
          ...this.state,
        });

        alertSuccess(Localized.Success.pin_no_longer_required);
        NavActions.pop();
      } catch (exception) {
        CrashlyticsEvents.log(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          generateErrorMessage(exception),
        );
        Events.Error.trackEvent(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          generateErrorMessage(exception),
        );
        alertError(Localized.Errors.problem_changing_pin);
      }
    } else if (!this.state.newPin || this.state.newPin.length === 0) {
      alertError(Localized.Errors.enter_new_pin);
    } else if (this.state.newPin !== this.state.confirmNewPin) {
      alertError(Localized.Errors.pins_do_not_match);
    } else if (this.state.newPin && this.state.newPin.length < 4) {
      alertError(Localized.Errors.pin_has_to_be_four_digits);
    } else {
      try {
        await ActionsFactory.getAccountActions().pinChanged(
          AccountStore.getAccountId(),
          this.state.newPin,
          this.state.pinRequired,
        );

        alertSuccess(Localized.Success.pin_changed);
        NavActions.pop();
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'ResetPinScreen:HandlePinChange',
          generateErrorMessage(error),
        );
        alertError(Localized.Errors.problem_changing_pin);
      }
    }
  }

  render() {
    return (
      <BaseScreen
        title={Localized.Labels.change_pin}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView
            style={Styles.Style.flex}
            keyboardDismissMode="interactive"
            automaticallyAdjustContentInsets={false}
            keyboardShouldPersistTaps="handled"
          >
            <View style={[styles.content, Styles.Style.maxWidthContainer]}>
              {Settings.is365Pay() ? (
                <>
                  <CustomTextInput
                    accessible={true}
                    accessibilityLabel={Localized.Labels.new_pin}
                    accessibilityValue={{text: this.state.newPin}}
                    label={Localized.Labels.new_pin}
                    secureTextEntry={true}
                    value={this.state.newPin}
                    keyboardType="number-pad"
                    maxLength={4}
                    showShadow={true}
                    onChangeText={(text: string) => {
                      this.setState({
                        newPin: Util.onlyNumbers(text),
                      });
                    }}
                    labelStyle={{
                      fontSize: Styles.Spacing.m3 + 2,
                      fontFamily: Styles.FontFamily.aeonikRegular,
                      fontWeight: '400',
                      color: '#707070',
                    }}
                    outlineStyle={{
                      borderColor: '#CFCFCF',
                      borderRadius: 8,
                      borderWidth: 1,
                      elevation: 5,
                    }}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                    textColor={'#111'}
                    fontSize={Styles.Fonts.f7}
                  />
                  <CustomTextInput
                    label={Localized.Labels.confirm_new_pin}
                    secureTextEntry={true}
                    showShadow={true}
                    maxLength={4}
                    value={this.state.confirmNewPin}
                    defaultValue={this.state.confirmNewPin}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.confirm_new_pin}
                    accessibilityValue={{text: this.state.confirmNewPin}}
                    keyboardType="number-pad"
                    onChangeText={(text: string) =>
                      this.setState({
                        confirmNewPin: Util.onlyNumbers(text),
                      })
                    }
                    labelStyle={{
                      fontSize: Styles.Spacing.m3 + 2,
                      fontFamily: Styles.FontFamily.aeonikRegular,
                      fontWeight: '400',
                      color: '#707070',
                    }}
                    outlineStyle={{
                      borderColor: '#CFCFCF',
                      borderRadius: 8,
                      borderWidth: 1,
                      elevation: 5,
                    }}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                    textColor={'#111'}
                    fontSize={Styles.Fonts.f7}
                  />
                </>
              ) : (
                <>
                  <PasswordInput
                    accessible={true}
                    accessibilityLabel={Localized.Labels.new_pin}
                    accessibilityValue={{text: this.state.newPin}}
                    label={Localized.Labels.new_pin}
                    value={this.state.newPin}
                    keyboardTypeValue="numeric"
                    needValidation={false}
                    maxLength={4}
                    onChangeText={(text: string) =>
                      this.setState({
                        newPin: Util.onlyNumbers(text),
                      })
                    }
                  />
                  <PasswordInput
                    accessible={true}
                    accessibilityLabel={Localized.Labels.confirm_new_pin}
                    accessibilityValue={{text: this.state.confirmNewPin}}
                    label={Localized.Labels.confirm_new_pin}
                    value={this.state.confirmNewPin}
                    keyboardTypeValue="numeric"
                    needValidation={false}
                    maxLength={4}
                    onChangeText={(text: string) =>
                      this.setState({
                        confirmNewPin: Util.onlyNumbers(text),
                      })
                    }
                  />
                </>
              )}

              <View style={styles.row}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  style={[
                    styles.label,
                    getDescriber()['sendSnackOTSNotificationStyleDescriptor']()[
                      'acceptSnackText'
                    ],
                  ]}
                >
                  {Localized.Labels.pin_required_on_this_device}
                </AVText>

                <CustomToggleSwitch
                  accessibilityLabel="PIN Required Switch"
                  accessibilityHint={
                    'Double tap to set pin required to true or false'
                  }
                  accessibilityState={{checked: this.state.pinRequired}}
                  value={this.state.pinRequired}
                  onValueChange={(value) =>
                    this.setState({
                      pinRequired: value,
                    })
                  }
                  containerStyle={{
                    flex: 0,
                    justifyContent: 'flex-end',
                    padding: 0,
                  }}
                />
              </View>
              <View style={Styles.Style.bottomButtonContainer} />
            </View>
          </ScrollView>
          {this.props.isConnected && (
            <RoundedButton
              buttonType={ButtonType.action}
              accessible={true}
              accessibilityRole="button"
              accessibilityLabel={Localized.Buttons.save}
              aria-label={Localized.Buttons.save}
              color={getDescriber().checkRoundedBtnColor()}
              role="button"
              disabled={this.saveBtnDisable()}
              onPress={this.handleClick}
              text={Localized.Buttons.save}
              containerStyle={
                getDescriber()['sendSnackOTSNotificationStyleDescriptor']()[
                  'saveContainer'
                ]
              }
            />
          )}
        </KeyboardAvoidingView>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    paddingTop: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m5,
  },
  label: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    fontWeight: '400',
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
  },
});
export default withForwardedNavigationParams()(withIsConnected(ResetPin));
