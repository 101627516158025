import {VendingStylesDescriptor} from 'src/components/screens/vending/descriptor/Descriptor';
import Styles from 'src/components/Styles';

export function getVendingStyles(): VendingStylesDescriptor {
  return {
    balance: {
      fontSize: Styles.Fonts.f11,
      fontWeight: '600',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
    },
    balanceLabel: {
      fontSize: Styles.Fonts.f9,
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: Styles.darkColor,
    },
    timer: {
      fontSize: Styles.Fonts.f11,
      fontWeight: '600',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
    },
    timerLabel: {
      fontSize: Styles.Fonts.f9,
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '400',
      color: Styles.darkColor,
    },
    locationName: {
      color: Styles.black,
      fontSize: Styles.Fonts.f2,
      fontWeight: '700',
    },
    progressContainer: {
      alignItems: 'center',
      backgroundColor: Styles.tabBarBackgroundColor,
      flex: 1,
      marginHorizontal: Styles.Spacing.m1,
      marginTop: Styles.Spacing.m3,
    },
    messageContainer: {
      alignItems: 'center',
      backgroundColor: Styles.blueRevolve,
      borderRadius: 4,
      bottom: Styles.Spacing.m4 + 2,
      flexDirection: 'row',
      justifyContent: 'center',
      left: Styles.Spacing.m3 + Styles.Spacing.m2,
      opacity: 0.9,
      paddingHorizontal: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m2 + 4,
      position: 'absolute',
      right: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
    messageText: {
      color: Styles.white,
      flex: 1,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getTintColor(): string {
  return Styles.blueRevolve;
}

export function getCircleBackgroundColor(): string {
  return '#D9D9D9';
}
export function getButtonColor(): string {
  return Styles.darkGreenRevolve;
}

export default {
  getVendingStyles,
  getTintColor,
  getCircleBackgroundColor,
  getButtonColor,
};
