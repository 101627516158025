import {Text, StyleSheet} from 'react-native';
import {RenderItemProps} from 'src/components/elements/cart/descriptor/DescriptorType';
import Localized from 'src/constants/AppStrings';
import Icon, {FontAwesomeIconType} from '../../../icons/FontAwesomeIcon';
import Styles from 'src/components/Styles';
import Button from '../../../elements/Button';

export function searchFieldLabelDescriptor(data: typeof Localized): string {
  return data.Labels.searchproducts;
}

export function productCatSelectedStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.darkGreenRevolve,
    borderBottomColor: '#025D33',
    borderTopColor: '#025D33',
    borderStartColor: '#025D33',
    borderEndColor: '#025D33',
  };
}

export function productCatButtonStyleDescriptor(): {
  [index: string]: number | string;
} {
  // const maxWidth =
  //   Dimensions.get('screen').width * (PixelRatio.getFontScale() > 1 ? 1 : 0.75);
  return {
    backgroundColor: '#FFF',
    borderRadius: 32,
    height: undefined,
    marginRight: Styles.Spacing.m1 + 5,
    //padding: Styles.Spacing.m2,
    paddingVertical: 8,
    paddingHorizontal: Styles.Spacing.m3,
    overflow: 'hidden',
    //maxWidth: maxWidth,
    //top: -1,
    marginTop: 1,
    marginBottom: 15,
    //minHeight: Styles.Heights.touchTargetHeight2,
    borderWidth: 1,
    borderBottomColor: '#BCBCBC',
    borderTopColor: '#BCBCBC',
    borderStartColor: '#BCBCBC',
    borderEndColor: '#BCBCBC',
  };
}

export function productCatTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
    textTransform: 'uppercase',
  };
}
export function productCatSelectedTextStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.white,
  };
}

export function productCatrenderItem({
  item,
  index,
  selectedCategory,
  categoriesLength,
  onCategoryPress,
}: RenderItemProps): JSX.Element {
  const itemName = item.iconName ? (
    <Text maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}>
      <Icon
        name={item.iconName as FontAwesomeIconType}
        size={Styles.Fonts.f1}
        style={{marginRight: Styles.Spacing.m2}}
      />
      {` ${item.name}`}
    </Text>
  ) : (
    item.name
  );

  return (
    <Button
      role="button"
      accessibilityHint="Double tap to filter products by selected category"
      accessibilityLabel={`${item.name} ${Localized.Buttons.category}`}
      accessibilityRole="button"
      aria-label={`${item.name} ${Localized.Buttons.category}`}
      overrideStyles={[
        productCatButtonStyleDescriptor(),

        selectedCategory === item.key && productCatSelectedStyleDescriptor(),
        index === 0 && styles.firstItemMarginLeft,
        index === categoriesLength - 1 && styles.lastItemMarginRight,
      ]}
      onPress={() => onCategoryPress(item)}
      overrideTextStyles={[
        productCatTextStyleDescriptor(),
        selectedCategory === item.key &&
          productCatSelectedTextStyleDescriptor(),
      ]}
      text={itemName}
    />
  );
}
const styles = StyleSheet.create({
  lastItemMarginRight: {
    marginRight: Styles.Spacing.m3,
  },
  firstItemMarginLeft: {
    marginLeft: Styles.Spacing.m3,
  },
});

export default {
  searchFieldLabelDescriptor,
  productCatSelectedStyleDescriptor,
  productCatButtonStyleDescriptor,
  productCatTextStyleDescriptor,
  productCatSelectedTextStyleDescriptor,
  productCatrenderItem,
};
