import * as React from 'react';
import {
  KeyboardTypeOptions,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import Styles from '../../Styles';
import CreditCardImage from '../CreditCardImage';
import creditCardType from 'credit-card-type';
import Settings from 'src/Settings';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import {getDescriber} from './descriptor/DescriptorType';

type CreditCardInputProps = {
  onChangeText?: (text: string) => void;
  accessibilityLabel?: string;
  onSubmitEditing?: () => void;
  label?: string;
  issuer?: string;
  editable?: boolean;
  value?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  outlineStyle?: StyleProp<TextStyle>;
  errorTextStyle?: StyleProp<TextStyle>;
  hasErrorMsg?: boolean;
  errorMsg?: string | null;
  labelStyle?: StyleProp<TextStyle>;
};
type CreditCardInputState = {
  issuer: string;
};

class CreditCardInput extends React.Component<
  CreditCardInputProps,
  CreditCardInputState
> {
  static defaultProps = {
    editable: true,
  };

  constructor(props: CreditCardInputProps) {
    super(props);
    this.state = {
      issuer: props.issuer || '',
    };
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(text: string) {
    if (this.props.onChangeText) {
      this.props.onChangeText(text);
    }

    const results = creditCardType(text);

    if (results.length === 1) {
      this.setState({
        issuer: results[0].type,
      });
    } else {
      this.setState({
        issuer: '',
      });
    }
  }

  render() {
    const textProps = {
      accessibilityLabel: this.props.accessibilityLabel,
      keyboardType: 'numeric' as KeyboardTypeOptions,
      onChangeText: this.onTextChange,
      onSubmitEditing: this.props.onSubmitEditing,
      editable: this.props.editable,
      value: this.props.value,
    };

    const {getAmericanExpressRightIcon} = getDescriber();

    if (!this.props.value) {
      delete textProps.value;
    }
    return (
      <View style={[styles.container, this.props.style]}>
        {Settings.is365Pay() ? (
          <CustomTextInput
            label={this.props.label}
            value={this.props.value}
            defaultValue={this.props.value}
            rightIcon={<CreditCardImage issuer={this.state.issuer} />}
            rightIconStyle={getAmericanExpressRightIcon(this.state.issuer)}
            accessible={true}
            accessibilityLabel={this.props.accessibilityLabel}
            accessibilityValue={{text: this.props.value}}
            accessibilityState={{disabled: !this.props.editable}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            textStyle={this.props.textStyle}
            errorTextStyle={this.props.errorTextStyle}
            errorMsg={this.props.errorMsg}
            hasErrorMsg={this.props.hasErrorMsg}
            outlineStyle={this.props.outlineStyle}
            labelStyle={this.props.labelStyle}
            {...textProps}
            showShadow={true}
          />
        ) : (
          <AllyTextInput
            label={this.props.label}
            value={this.props.value}
            defaultValue={this.props.value}
            hasInlineImage={true}
            imageElement={<CreditCardImage issuer={this.state.issuer} />}
            accessible={true}
            accessibilityLabel={this.props.accessibilityLabel}
            accessibilityValue={{text: this.props.value}}
            accessibilityState={{disabled: !this.props.editable}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            {...textProps}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  issuerImage: {
    backgroundColor: Styles.transparent,
    bottom: Styles.Spacing.m3,
    position: 'absolute',
    right: Styles.Spacing.m1,
  },
});
export default CreditCardInput;
