import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type DoubleGreenTickProps = {
  width: number;
  height: number;
  bgColor?: string;
};

export default class DoubleGreenTick extends React.Component<DoubleGreenTickProps> {
  render() {
    return (
      <Svg
        width={this.props.width}
        height={this.props.height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <Path
          d="M23.2499 4.24902L11.8079 19.505C11.679 19.677 11.5146 19.8193 11.3259 19.9223C11.1372 20.0252 10.9286 20.0864 10.7142 20.1016C10.4998 20.1169 10.2846 20.0859 10.0832 20.0107C9.88182 19.9356 9.69894 19.818 9.54695 19.666L6.56995 16.689"
          stroke="#025D33"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M6.00001 19.7601C5.86131 19.9019 5.69585 20.0148 5.51322 20.0922C5.33058 20.1697 5.1344 20.2102 4.93602 20.2113C4.73764 20.2124 4.54101 20.1741 4.35752 20.0987C4.17402 20.0234 4.0073 19.9123 3.86701 19.772L0.890015 16.8"
          stroke="#025D33"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <Path
          d="M17.43 4.35498L9.29004 15.209"
          stroke="#025D33"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </Svg>
    );
  }
}
