import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const CheckCircle = ({color = '#025D33', width = 24, height = 24}: IProps) => (
  <Svg width={width} height={height} fill="none">
    <Path
      fill={color}
      fillRule="evenodd"
      d="M12.5 20a8 8 0 0 0 8-8h2a10 10 0 1 1-6.23-9.26L14.7 4.31A8 8 0 1 0 12.5 20ZM7 11.5l1.41-1.42 3.09 3.09 8.59-8.59L21.5 6l-10 10L7 11.5Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default CheckCircle;
