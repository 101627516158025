import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import LocationPermissionErrorIcon from 'src/components/img/svg/LocationPermissionErrorIcon';
import LocationMarkerErrorIcon from 'src/components/img/svg/LocationMarkerErrorIcon';
import Settings from 'src/Settings';

//Location Error Types
//LocationPermission - When Location Permission is disbaled/denied
//NoNearbyLocations - When Location Permission enabled but there are no nearby location
export enum LocationErrorStatusType {
  LocationPermission = 'LocationPermission',
  NoNearbyLocations = 'NoNearbyLocations',
}

type LocationErrorStatusProps = {
  locationErrorStatusType: LocationErrorStatusType;
};

const LocationErrorStatusOnboarding = (props: LocationErrorStatusProps) => {
  const descriptionText =
    props.locationErrorStatusType === LocationErrorStatusType.LocationPermission
      ? Localized.Labels.turn_on_location_services
      : Localized.Labels.there_are_no_locations_near_you;

  return (
    <View
      style={[
        styles.container,
        props.locationErrorStatusType ===
        LocationErrorStatusType.LocationPermission
          ? {height: Styles.Heights.h7 * 2.6}
          : {height: Styles.Heights.h7 * 2},
      ]}
    >
      <View style={styles.imageView}>
        {props.locationErrorStatusType ===
        LocationErrorStatusType.LocationPermission ? (
          <LocationPermissionErrorIcon />
        ) : (
          <LocationMarkerErrorIcon />
        )}
      </View>
      <AVText
        style={styles.descriptionText}
        accessible={false}
        accessibilityElementsHidden={true}
        accessibilityLabel={descriptionText}
        accessibilityRole="text"
        aria-label={`${descriptionText}, text`}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      >
        {descriptionText}
      </AVText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    //height: Styles.Heights.h7 * 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  descriptionText: {
    color: Settings.is365Pay() ? '#111' : Styles.black,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
    fontFamily: Settings.is365Pay()
      ? Styles.FontFamily.aeonikRegular
      : Styles.FontFamily.robotoRegular,
  },
  imageView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: Styles.Heights.h5,
    height: Styles.Heights.h5,
    marginBottom: Styles.Spacing.m2,
  },
  locationSettings: {
    borderTopColor: '#066DFF',
    borderBottomColor: '#066DFF',
    borderLeftColor: '#066DFF',
    borderRightColor: '#066DFF',
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingVertical: 10,
    paddingHorizontal: 20,
    overflow: 'hidden',
    backgroundColor: '#066DFF',
  },
  locationSettingsbtnStyle: {
    fontWeight: Platform.OS === 'android' ? 'bold' : '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0 + 4,
    color: Styles.white,
  },
});

export default LocationErrorStatusOnboarding;
