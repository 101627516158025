import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../Styles';
import {Text, View} from 'react-native';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import {AccountFundingDescriberData} from 'src/components/screens/funding/descriptor/DescriptorType';
import React from 'react';
import PaperBillRedeisgn from 'src/components/img/svg/cards/paperBillRedesign';
import AVText from 'src/components/elements/AVText';
import WarningIconSnackDetails from 'src/components/img/svg/WarningIconSnackDetails';

export function getCreditCardAddStyleDescriptor(): {
  [index: string]: {
    [index: string]:
      | number
      | string
      | Array<{scaleX?: number; scaleY?: number}>;
  };
} {
  return {
    inputsContainer: {
      flexDirection: 'column',
      marginTop: Styles.Spacing.m2,
      paddingHorizontal: Styles.Spacing.m2,
    },
    defaultSwitch: {
      transform: [
        {
          scaleX: 0.85,
        },
        {
          scaleY: 0.85,
        },
      ],
    },
  };
}

export function getModalTexts(): {
  [index: string]: number | string;
} {
  return {
    fundModalHeaderTitle: Localized.Labels.quick_fund_your_account_redesign,
  };
}

export function getAcntFundingHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerNewContainer: {
      alignItems: 'center',
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      backgroundColor: Styles.white,
      flexDirection: 'row',
      paddingHorizontal: Styles.Spacing.m3,
      justifyContent: 'space-between',
    },
  };
}
export function switchStyle() {
  return {
    transform: [{scaleX: 0.8}, {scaleY: 0.7}],
  };
}
export function getOptinTxt() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 16,
    fontWeight: '700',
    color: '#111',
    paddingRight: 12,
  };
}
export function getOptinView() {
  return {justifyContent: 'flex-start'};
}

export function getOptingOutPayrollDeductContent(styles, isOptIn) {
  return (
    <>
      {isOptIn ? (
        <AVText
          style={[styles.infoText, getOptingOutPayrollDeduct()]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.opting_out_of_payroll_deduct}
        </AVText>
      ) : (
        <View
          style={[
            styles.referralSuccErrView,
            styles.flexRowContainer,
            {
              backgroundColor: '#d7263d26',
              marginTop: 24,
            },
          ]}
        >
          <View style={styles.flexLeft}>
            <WarningIconSnackDetails />
          </View>
          <View style={styles.flexRight}>
            <AVText
              style={{
                fontFamily: Styles.FontFamily.aeonikRegular,
                fontWeight: '400',
                color: '#111',
                fontSize: Styles.Fonts.f8,
              }}
            >
              <AVText style={[styles.referralTextDesc]}>
                {Localized.Labels.opt_out}
              </AVText>
              {Localized.Labels.opt_out_desc}
            </AVText>
          </View>
        </View>
      )}
    </>
  );
}

export function getOptingOutPayrollDeduct() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 16,
    fontWeight: '400',
    color: '#4D4D4D',
    textAlign: 'left',
    marginBottom: 0,
  };
}

export function getPayrollDeductAgreementUnderline() {
  return {
    alignSelf: 'flex-start',
    borderBottomColor: '#066DFF',
    borderBottomWidth: 1.5,
    marginLeft: 8,
  };
}
export function getPayrollDeductAgreementTxt() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 16,
    fontWeight: '700',
    color: '#066DFF',
    textAlign: 'left',
    marginTop: 24,
    //marginLeft: 1,
    textDecorationLine: 'none',
  };
}
export function getPayrollDeductPendingDescStyle() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    textAlign: 'left',
    marginLeft: 8,
  };
}
export function getResetLbl() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 14,
    fontWeight: '400',
    color: '#4D4D4D',
    paddingTop: 3,
  };
}
export function accountFundingHeaderNewDescDescriptor(
  props: AccountFundingDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <Text
        accessible={true}
        accessibilityLabel="Add money to your account"
        aria-label="Add money to your account"
        accessibilityRole="header"
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        role="heading"
        style={props.styles.headerNewText}
      >
        {props.title}
      </Text>

      <AVTouchableOpacity
        style={{
          minHeight: Styles.Heights.touchTargetHeight2,
          minWidth: Styles.Heights.touchTargetHeight2,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
        accessible={true}
        accessibilityLabel={Localized.Buttons.cancel}
        accessibilityHint="Double tap X button to close the funding modal"
        aria-label={Localized.Buttons.cancel}
        accessibilityRole="button"
        role="button"
        onPress={() => props.onClose()}
      >
        <FontAwesome5Pro
          name="times"
          color={Styles.darkColor}
          size={Styles.Fonts.f3}
          style={{
            marginRight: Styles.Spacing.m1,
          }}
          light
        />
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function getPayRollDeductImage() {
  return <PaperBillRedeisgn size={Styles.Fonts.f5} />;
}
export function getPayrollDeductImageStyle(): {
  [index: string]: number | string;
} {
  return {alignItems: 'flex-start', marginVertical: 0};
}

export function getSpendLimit() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 22,
    fontWeight: '700',
    color: '#111',
    marginBottom: 15,
  };
}

export function normalPayrollAggreement() {
  return {marginLeft: -8};
}

export function getPayrollDeductPendingTitleStyle() {
  return {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 22,
    fontWeight: '700',
    color: '#111',
    textAlign: 'left',
    marginLeft: 8,
    paddingBottom: 8,
  };
}

export function getRemainingStyle() {
  return {
    fontSize: 16,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: '#111',
    fontWeight: '400',
    paddingBottom: 2,
  };
}
export function getFilledColor() {
  return '#2C6CD3';
}

export function getUnfilledColor() {
  return '#CFCFCF';
}
export function getSwitchColor() {
  return {
    false: '#707070',
    true: '#066DFF',
  };
}

export function payrollDeductNormlSectionImage(styles) {
  return (
    <View
      style={[
        styles.iconContainer,
        getPayrollDeductImageStyle(),
        {
          marginLeft: -5,
        },
      ]}
    >
      <PaperBillRedeisgn size={Styles.Fonts.f5} />
    </View>
  );
}
export default {
  getPayrollDeductPendingTitleStyle,
  getCreditCardAddStyleDescriptor,
  getRemainingStyle,
  getPayRollDeductImage,
  getSpendLimit,
  getFilledColor,
  getOptingOutPayrollDeductContent,
  getPayrollDeductPendingDescStyle,
  getAcntFundingHeaderStyleDescriptor,
  getOptinTxt,
  getPayrollDeductAgreementTxt,
  normalPayrollAggreement,
  getOptinView,
  getResetLbl,
  payrollDeductNormlSectionImage,
  getModalTexts,
  getSwitchColor,
  getPayrollDeductImageStyle,
  getUnfilledColor,
  getOptingOutPayrollDeduct,
  getPayrollDeductAgreementUnderline,
  switchStyle,
};
