import React, {useContext, useEffect, useState} from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  PermissionsAndroid,
  Platform,
  RefreshControl,
  Dimensions,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import withIsConnected from '../../hoc/withIsConnected';
import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import LocationMarker from 'src/components/img/svg/LocationMarker';
import AVText from 'src/components/elements/AVText';
import QRCodeHeader from 'src/components/elements/home/QRCodeHeader';
import EngagementCarousel from 'src/components/elements/home/EngagementCarousel';
import LocationHandler from 'src/handlers/LocationHandler';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {IsConnectedProps} from 'src/types/Screen';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import CampusLocationsList from 'src/components/elements/home/CampusLocationList';
import {useAppDispatch, useAppSelector} from 'src/redux/hooks';
import {
  fetchCampusLocations,
  fetchRecentCampusesAndLocations,
  fetchAnnouncements,
} from 'src/redux/slices/campusLocationSlice';
import {
  CampusLocationCategory,
  CampusLocationType,
} from 'src/components/elements/home/CampusLocationView';
import MachineStore from 'src/stores/MachineStore';
import BluetoothManager, {
  BluetoothEvents,
  BluetoothStates,
} from 'src/services/bluetooth/BluetoothManager';
import MachineAction from 'src/actions/MachineActions';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import BeaconDevice from 'src/services/bluetooth/BeaconDevice';
import Settings from 'src/Settings';
import TSFInsideDevice, {
  TSFInsideEvents,
} from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import AccountStore from 'src/stores/AccountStore';
import BluetoothDevice from 'src/services/bluetooth/BluetoothDevice';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import TransactionActions from 'src/actions/TransactionActions';
import VEVendSession from 'src/services/VEVendSession';
import TSFInsideVendSession from 'src/services/TSFInsideVendSession';
import VendSession from 'src/services/VEVendSession';
import SyncHelper from 'src/services/SyncService';
import MainConsumerContext from 'src/components/MainConsumerContext';
import ActionsFactory from 'src/actions/ActionsFactory';
import CartTypes from 'src/constants/cart/CartTypes';
import {alertError} from 'src/components/helpers/AlertHelper';
import VendingMachineConfig, {
  VendingMachineSetting,
} from 'src/models/VendingMachineConfig';
import {LocationType} from 'src/types/Location';
import MenuService from 'src/services/MenuService';
import MenuActions from 'src/actions/MenuActions';
import uuid from 'src/nativeModules/UUID';
import SendASnackView from 'src/components/elements/home/SendASnackView';
import {RootState} from '../../../redux/store';
import {MachineType} from '../../../types/MachineType';
import AppApi from 'src/api/AppApi';
import NotificationIcon from 'src/components/img/svg/newui/notificationLogo';
import NotificationUnReadIcon from 'src/components/img/svg/newui/notificationUnReadLogo';
import AnnouncementsList from 'src/components/elements/home/AnnouncementsList';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import VendingLocationList from 'src/components/elements/home/VendingLocationList';
import Milk from 'src/components/img/svg/newui/milk';
import CoolDrink from 'src/components/img/svg/newui/cooldrink';
import Awareness from 'src/components/img/svg/newui/awareness';
import Fruit from 'src/components/img/svg/newui/fruit';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import GreetingsAndBalanceView from 'src/components/elements/home/GreetingsAndBalanceView';
import PersistentStore from 'src/services/PersistentStoreService';
import PlatformApi from 'src/api/PlatformApi';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import SuccessModal from 'src/components/elements/SuccessModal';
import {useNavigation} from '@react-navigation/native';
import ReferralView from 'src/components/elements/home/ReferralView';
import ScanProductBarCode from 'src/components/img/svg/ScanProductBarCode';
import RewardsView from 'src/components/elements/home/RewardsView';
import InteractiveTutorial from 'src/components/elements/appTour/InteractiveTutorial';
import {getDescriber} from './descriptor/DescriptorType';
import AccountConstants from 'src/constants/AccountConstants';
import Snackbar from 'src/components/dialogs/Snackbar';
import {getDescriber as getDescriberTypeElements} from 'src/components/elements/descriptor/DescriptorType';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

import ScanAndPayLocationsList from 'src/components/elements/home/ScanAndPayLocationsList';
import NewHeader from 'src/components/elements/NewHeader';
import Util from 'src/Util';

const {backGroundColorHomeScreen} = getDescriber();

type HomeScreenProps = IsConnectedProps;

const width = Dimensions.get('window').width;
const HomeScreen = (props: HomeScreenProps) => {
  const context = useContext(MainConsumerContext);
  const navigation = useNavigation();

  const dispatch = useAppDispatch();
  const accountId = useAppSelector<RootState>((s) => s.account.account.id);
  const selectedLocation = useAppSelector(
    (s) => s.campusLocation.selectedLocation,
  );
  const announcementsData = useAppSelector(
    (s) => s.campusLocation.announcements,
  );
  const diningLocations = useAppSelector(
    (s) => s.campusLocation.diningLocations,
  );

  const TIMEOUT_MILLISECONDS = 10000;

  const [scanAndPayLocations, setScanAndPayLocations] = useState<
    Array<CampusLocationType>
  >([]);
  const [vendingLocations, setVendingLocations] = useState<
    Array<CampusLocationType>
  >([]);
  const [bluetoothState, setBluetoothState] = useState(
    BluetoothStates?.PoweredOff,
  );

  const [refreshing, setRefreshing] = useState(false);
  const [unreadNotifications, setUnreadNotification] = useState(false);
  const [showErrorSendSnackModal, setErrorShowSendSnackModal] = useState(false);
  const [showSendSnackModal, setshowSendSnackModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [snackList, SetSnackList] = useState([]);
  const [isFocused, setIsFocused] = useState(true);
  const [referralId, setReferralId] = useState<null | undefined | string>('');
  const [isToastVisible, setIsToastVisible] = useState<boolean>(false);
  const [payrollImportId, setPayrollImportId] = useState<
    null | undefined | string
  >('');

  let selectedDevice: BluetoothDevice;
  let authorizing = false;
  let _crntVendTransId: null | string = null;
  let connectTimeout: NodeJS.Timeout;

  useEffect(() => {
    getReferral();
    checkPayrollImport();
    CrashlyticsEvents.crashLogEnableSetup();
    FirebaseAnalytic.trackEvent('componentDidMount', 'HomeScreen', {...props});

    MachineStore.addChangeListener(onMachineStoreChange);
    MachineStore.addConnectionErrorListener(onMachineConnectionError);
    BluetoothManager.addListener(
      BluetoothEvents.onDeviceFound,
      bluetoothDeviceFound,
    );
    BluetoothManager.addListener(
      BluetoothEvents.onBTStateUpdate,
      bluetoothStateUpdated,
    );
    sendaSnackPopUp();
    checkOTSNotification();
    ActionsFactory.getAccountActions().loadPurchaseHistory(
      AccountStore.getAccountId(),
      1,
    );
    ActionsFactory.getAccountActions().loadPurchaseHistory(
      AccountStore.getAccountId(),
      1,
      AccountConstants.FUNDING_TYPE,
    );
    return () => {
      MachineStore.removeChangeListener(onMachineStoreChange);
      MachineStore.removeConnectionErrorListener(onMachineConnectionError);
      BluetoothManager.removeListener(
        BluetoothEvents.onBTStateUpdate,
        bluetoothStateUpdated,
      );
      BluetoothManager.removeListener(
        BluetoothEvents.onDeviceFound,
        bluetoothDeviceFound,
      );
    };
  }, []);
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      refreshBluetoothDevices();
      setIsFocused(true);
      sendaSnackPopUp();
      checkOTSNotification();
      getUnReadNotificationCount();
      getReferralCount();
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      setIsFocused(false);
    });

    return unsubscribe;
  }, []);

  const getReferral = async () => {
    const referralID = await PersistentStore.getReferralId();
    setReferralId(referralID);
  };

  const checkPayrollImport = async () => {
    const payrollImportData = await PersistentStore.getPayrollImportData();
    setPayrollImportId(payrollImportData?.importId);
  };

  const getReferralCount = async () => {
    await ActionsFactory.getAccountActions().loadReferralDetails();
  };

  const getUnReadNotificationCount = async () => {
    const messages = await AppApi.getMessages(AccountStore.getAccountId());
    if (messages) {
      if (messages.length == 0) setUnreadNotification(false);
      else {
        const unreadMessages = messages.filter((item) => item.isRead === false);
        if (unreadMessages.length > 0) setUnreadNotification(true);
        else setUnreadNotification(false);
      }
    } else setUnreadNotification(false);
  };
  // //Do not remove - use for testing beacon simulation
  // useEffect(() => {
  //   bluetoothDeviceFound({
  //     type: BluetoothDeviceTypes.beacon,
  //     beaconId: 'fffffffffffd',
  //   });
  // }, []);
  useEffect(() => {
    if (accountId && accountId !== -1 && accountId.length > 2) {
      requestPermissions();
      getRecentLocations();
      getUnReadNotificationCount();
    }
  }, [accountId]);

  //Fetch campus locations whenever user selects location/campus
  useEffect(() => {
    if (selectedLocation) {
      dispatch(fetchAnnouncements(selectedLocation.locationId));
      dispatch(fetchCampusLocations(selectedLocation.locationId));
    }
  }, [selectedLocation]);
  useEffect(() => {
    // Show the modal if there is data
    const isNotReadIndexes = [...snackList.keys()].filter(
      (item) => !snackList[item].isRead,
    );
    if (isNotReadIndexes.length > 0) {
      setCurrentIndex(isNotReadIndexes[0]);
      setshowSendSnackModal(true);
    } else {
      setshowSendSnackModal(false);
    }
  }, [snackList]);

  const sendaSnackPopUp = async () => {
    const snackData = (await PersistentStore.getSendaSnackArray()) || [];

    /** send a snack pop up */
    const snacksResponse = await PlatformApi.fetchSnacks(
      AccountStore.getLocationId(),
      0,
      10,
      accountId,
    );
    const snackItems = snacksResponse?.items || [];
    const sortedJsObjects = snackItems?.sort(
      (a, b) =>
        new Date(b.lastUpdated).valueOf() - new Date(a.lastUpdated).valueOf(),
    );
    const updatedSnacks = sortedJsObjects
      .filter(
        (item) => item.status === 'S' && item.receiverAccountId === accountId,
      )
      .map((item) => {
        const existingSnack = snackData.find((j) => j.snackId === item.snackId);
        return {
          ...item,
          isRead: existingSnack ? existingSnack.isRead : false,
        };
      });
    const mergedSnacks = [...updatedSnacks];
    snackData.forEach((storedSnack) => {
      if (
        !updatedSnacks.some((snack) => snack.snackId === storedSnack.snackId)
      ) {
        mergedSnacks.push(storedSnack);
      }
    });
    SetSnackList(mergedSnacks.slice(0, 5));
    await PersistentStore.setSendaSnackArray(mergedSnacks.slice(0, 5));
  };
  const checkOTSNotification = async () => {
    const {oneTimeSnackId, oneTimeSnackLocationId} =
      AccountStore.getOTSNotification();
    const snackDetails = await PlatformApi.loadSnackDetails(
      oneTimeSnackLocationId,
      oneTimeSnackId,
    );
    if (oneTimeSnackId && oneTimeSnackLocationId) {
      if (snackDetails.status === 'S') {
        setshowSendSnackModal(true);
      } else if (snackDetails.status === 'C') {
        setErrorShowSendSnackModal(true);
      }
    }
  };
  const hideModal = async () => {
    snackList.map((item, i) => {
      if (i === currentIndex) {
        item.isRead = true;
      }
      return item;
    });
    const snackFilterResult = snackList.filter((item) => item.status !== 'C');
    SetSnackList(snackFilterResult);
    await PersistentStore.setSendaSnackArray(snackFilterResult);
    AccountStore.resetOTSNotification();
  };
  const hideErrorModal = () => {
    setErrorShowSendSnackModal(false);
    AccountStore.resetOTSNotification();
  };
  const onAcceptModalBtn = async (currentSnack) => {
    snackList.map((item, i) => {
      if (i === currentIndex) {
        item.isRead = true;
      }
      return item;
    });
    const filterResult = snackList.filter((item) => item.status !== 'C');
    SetSnackList(filterResult);
    await PersistentStore.setSendaSnackArray(filterResult);
    NavActions.navigate(AppRoutes.SnackDetail, {
      snackId: currentSnack.snackId,
      snackLocationId: currentSnack.locationId,
      snackAction: SnackActions.AcceptSnack,
    });
    AccountStore.resetOTSNotification();
  };

  const onMachineStoreChange = () => {
    getMachines();
    getMarkets();
  };

  const refreshBluetoothDevices = async () => {
    setScanAndPayLocations([]);
    MachineStore.clearMarkets();
    MachineAction.clearMarkets();
    BluetoothManager.stopScan();
    BluetoothManager.clearDevices();
    const bluetoothPermission =
      Platform.OS === 'android'
        ? await PermissionsAndroid.check(
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN,
          )
        : true;
    if (bluetoothPermission) {
      const state = await BluetoothManager.getBluetoothState();
      if (state === BluetoothStates?.PoweredOn) {
        await Util.delay(5000);
        checkBluetoothState();
      }
    }
  };

  const checkBluetoothState = async () => {
    const currentState = await BluetoothManager.getBluetoothState();
    if (currentState !== bluetoothState) {
      setBluetoothState(currentState);
      if (currentState === BluetoothStates?.PoweredOn) {
        BluetoothManager.startScan();
      } else {
        BluetoothManager.stopScan();
        MachineAction.clearMarkets();
        if (NavActions.getLastRoute() === AppRoutes.NewHome) {
          MachineAction.clearMachines();
          MachineAction.clearDevices();
        }
      }
    }
  };

  const requestPermissions = async () => {
    if (Platform.OS === 'android') {
      PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
      ).then((result) => {
        if (result === 'granted') {
          PermissionsAndroid.requestMultiple([
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_SCAN,
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_CONNECT,
            PermissionsAndroid?.PERMISSIONS?.BLUETOOTH_ADVERTISE,
          ]).then(() => {
            LocationHandler.getCurrentLocation();
            checkBluetoothState();
          });
        }
      });
    } else {
      checkBluetoothState();
      LocationHandler.getCurrentLocation();
    }
  };

  const bluetoothDeviceFound = async (device: {type: string}) => {
    if (device.type === BluetoothDeviceTypes.vendorsExchange) {
      MachineAction.deviceAddedVE(device as VendorsExchangeDevice);
    } else if (device.type === BluetoothDeviceTypes.beacon) {
      const beacon = device as BeaconDevice;
      MachineAction.marketScanned(
        beacon.beaconId,
        AccountStore.getAccountId(),
        Settings.buildType,
      );
    } else if (device.type === BluetoothDeviceTypes.tsfInside) {
      const tsfInside = device as TSFInsideDevice;
      if (tsfInside.deviceName) {
        MachineAction.machineAdded(tsfInside);
      } else {
        MachineAction.deviceAdded(tsfInside);
      }
    }
  };

  const bluetoothStateUpdated = () => {
    checkBluetoothState();
  };

  //Fetches Markets(Scan And Pay Beacons)
  const getMarkets = () => {
    //Note: Assign MachineType to CampusLocationType ???
    const marketsList =
      MachineStore.getMarkets() as unknown as CampusLocationType[];
    setScanAndPayLocations([...marketsList]);
  };

  //Fetch Machines(Vending)
  const getMachines = () => {
    const machinesList = MachineStore.getMachines();
    const coffeeMachinesList = MachineStore.getCoffeeMachines();

    //Note: Assign MachineType to CampusLocationType ???
    const vendingMachinesList = [...machinesList, ...coffeeMachinesList];
    setVendingLocations(vendingMachinesList as unknown as CampusLocationType[]);
    return machinesList;
  };

  //Fetch recently user selected locations
  const getRecentLocations = async () => {
    await dispatch(fetchRecentCampusesAndLocations(accountId));
  };

  const getSelectedCampusOrLocation = () => {
    if (selectedLocation) {
      return selectedLocation?.displayName ?? selectedLocation?.name;
    }
    return Localized.Labels.select_a_location;
  };

  const handleOrderAhead = async (
    machine: MachineType,
    autoAdvanced = false,
  ) => {
    FirebaseAnalytic.trackEvent('handleOrderAhead', 'HomeScreen', {
      ...props,
    });
    try {
      context?.actions.showSpinner();
      //Fetch Location Details from Machine Id
      const locationResponse =
        await ActionsFactory.getAccountActions().getLocation(machine.id);
      //Set Timezone as per location
      MenuService.setTimezone(locationResponse.timezone);
      const param = {
        autoAdvanced,
        location: {...machine, ...locationResponse},
      };
      //Check if location has disclaimer to show
      if (locationResponse.onlineOrderConfig.disclaimer) {
        startCartSession(AppRoutes.Disclaimer, machine, {
          ...param,
          getRouteFromPickupLocations: getRouteFromLocationConfig,
        });
      } else {
        const routeDetails = await getRouteFromLocationConfig(
          locationResponse,
          autoAdvanced,
        );
        if (!routeDetails.error) {
          startCartSession(
            routeDetails.name,
            machine,
            {
              ...param,
              selectedPickupLocation: routeDetails.selectedPickupLocation,
              availableTimes: routeDetails.availableTimes,
            },
            'machines-pickup',
          );
        }
      }
    } catch (error: unknown) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'HomeScreen:HandleOrderAhead',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'HomeScreen:HandleOrderAhead',
        generateErrorMessage(error),
        guid,
      );
      //Show Error Alert
      alertError(Localized.Errors.order_ahead_unavailable, guid);
    } finally {
      context?.actions.hideSpinner();
    }
  };

  //Fetch route based on selected location configuration
  const getRouteFromLocationConfig = async (
    location: LocationType,
    autoAdvanced = false,
  ) => {
    const {pickupLocations} = location;

    let error = false;
    let selectedPickupLocation;
    let availableTimeSlots;

    if (pickupLocations?.length <= 1) {
      let pickupLocId = '';
      if (pickupLocations?.length === 1) {
        //Select Default First pickup location if only one is available
        pickupLocId = pickupLocations[0].pickupLocationId;
        TransactionActions.pickupLocationUpdated(pickupLocations[0]);
        selectedPickupLocation = pickupLocations[0];
      }
      try {
        context?.actions.showSpinner();
        //Fetch available Timeslots
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          location,
          pickupLocId,
        );
        if (availableTimes.length > 0) {
          availableTimeSlots = MenuService.getAvailableTimeSlotsFromList(
            availableTimes,
            location.onlineOrderConfig.kitchenSchedule,
          );
        } else if (!autoAdvanced) {
          error = true;
          alertError(Localized.Errors.order_ahead_unavailable);
        } else {
          error = true;
          alertError(Localized.Errors.no_pickup_times_available);
        }
      } catch (err: any) {
        error = true;
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'HomeScreen:GetRouteFromPickupLocations',
          generateErrorMessage(err),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'HomeScreen:GetRouteFromPickupLocations',
          generateErrorMessage(err),
          guid,
        );
        alertError(Localized.Errors.order_ahead_unavailable, guid);
      } finally {
        context?.actions.hideSpinner();
      }
    }
    return {
      availableTimes: availableTimeSlots,
      name: AppRoutes.NewPickupLocation,
      selectedPickupLocation,
      error,
    };
  };

  const handleScanAndPay = async (machine: CampusLocationType) => {
    try {
      context?.actions.showSpinner();
      const locationResponse =
        await ActionsFactory.getAccountActions().getLocation(machine.id);
      const parameters = {
        cartType: CartTypes.ScanAndPay,
        location: {...machine, ...locationResponse},
      };
      startCartSession(AppRoutes.ScanAndPay, machine, parameters);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'HomeScreen:handleScanAndPay',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'HomeScreen:handleScanAndPay',
        generateErrorMessage(error),
      );
    } finally {
      context?.actions.hideSpinner();
    }
  };

  const startCartSession = (
    route: AppRoutes,
    machine: MachineType,
    additionalParams?: object,
    key?: string,
  ) => {
    const param = context?.actions.getScanScreenParams(machine);
    Events.Machines.trackEvent('startCartSession', {
      param,
      additionalParams,
      route,
    });
    NavActions.push(route, {...param, ...additionalParams}, key);
  };

  const onMachineSelect = async (machine: any, autoAdvanced = false) => {
    FirebaseAnalytic.trackEvent('onMachineSelect', 'HomeScreen', {
      ...props,
    });
    Events.Machines.trackEvent('onMachineSelect', {
      localType: machine.localType,
    });

    // Clear Cart Items
    TransactionActions.shoppingCartCleared();
    // handleScanAndPay(machine);
    // return;

    if (machine.localType === 'coffee') {
      //Navigate to Coffee Machine Screen
      startCartSession(AppRoutes.CoffeeMachine, machine);
    } else if (machine.localType !== 'market') {
      //Handle Vending Flow
      handleMachineSelected(machine);
    } else if (machine.orderAhead) {
      //OrderAhead flow
      handleOrderAhead(machine, autoAdvanced);
    } else {
      //Scan and Pay flow - Navigate to Scan Screen
      handleScanAndPay(machine);
    }
  };

  const onScanAndPayClick = (locations: CampusLocationType[]) => {
    TransactionActions.shoppingCartCleared();
    if (locations.length > 1) {
      NavActions.push(AppRoutes.ScanAndPayLocations, {
        locations,
        onLocationClick: onMachineSelect,
      });
    } else {
      handleScanAndPay(locations[0]);
    }
  };

  const onPlanogramClick = (machine: MachineType) => {
    const deviceId = machine.deviceId;
    NavActions.push(AppRoutes.MainProduct, {
      deviceId: deviceId,
      machine,
      onConnect: () => onMachineSelect(machine),
    });
  };

  const onHighestPriceReceived = (price: number) => {
    TSFInsideVendSession.setMachineHighestPrice(price);
  };

  const onMachineConnect = () => {
    FirebaseAnalytic.trackEvent('onMachineConnect', 'HomeScreen', {
      ...props,
    });
    MachineAction.machineConnected();
    clearTimeout(connectTimeout);
    context?.actions.hideSpinner();
    NavActions.push(AppRoutes.Vending, {
      returnRoute: AppRoutes.NewHome,
    });
    removeDeviceListeners(false);
  };

  const onMachineConnecting = () => {
    clearTimeout(connectTimeout);
    connectTimeout = startConnectionTimeout(3000);
  };

  const handleMachineSelected = async (machine: MachineType) => {
    FirebaseAnalytic.trackEvent('handleMachineSelected', 'HomeScreen', {
      ...props,
    });
    if (!authorizing) {
      try {
        context?.actions.showSpinner(Localized.Labels.authorizing);
        authorizing = true;
        // Type MachineType is missing the following properties from type BluetoothDevice
        selectedDevice = machine as unknown as BluetoothDevice;
        const authResultData = await TransactionActions.authorize(
          machine.deviceId,
        );
        onAuthorize(authResultData);
      } catch (error) {
        if (VEVendSession?.MachineConfig?.TransactionId) {
          cancelTransaction(VendSession?.MachineConfig?.TransactionId);
        }
        CrashlyticsEvents.log(
          'Exception',
          'HomeScreen:handleMachineSelected',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'HomeScreen:handleMachineSelected',
          generateErrorMessage(error),
        );
      } finally {
        authorizing = false;
      }
    }
  };

  const handleDisconnect = () => {
    //Check if any device is connected
    if (selectedDevice) {
      removeDeviceListeners();
      selectedDevice.disconnect();
    }
  };

  const removeDeviceListeners = (removeSyncEvents = true) => {
    if (selectedDevice) {
      if (removeSyncEvents) {
        SyncHelper.removeEvents(selectedDevice);
      }
      //Remove all Device Listners
      selectedDevice.removeListener(
        TSFInsideEvents.onConnecting,
        onMachineConnecting,
      );
      selectedDevice.removeListener(
        TSFInsideEvents.onHighestPriceReceived,
        onHighestPriceReceived,
      );

      selectedDevice.removeListener(
        TSFInsideEvents.onConnected,
        onMachineConnect,
      );
    }
  };

  const addDeviceListeners = () => {
    if (selectedDevice) {
      SyncHelper.setDevice(selectedDevice);
      selectedDevice.addListener(TSFInsideEvents.onConnected, onMachineConnect);
      selectedDevice.addListener(
        TSFInsideEvents.onConnecting,
        onMachineConnecting,
      );
      selectedDevice.addListener(
        TSFInsideEvents.onHighestPriceReceived,
        onHighestPriceReceived,
      );
    }
  };

  const handleConnectTimeout = async (error = false) => {
    if (_crntVendTransId || VendSession?.MachineConfig?.TransactionId) {
      cancelTransaction(
        _crntVendTransId ?? VendSession?.MachineConfig?.TransactionId,
      );
    }
    const guid = await uuid.getRandomUUID();
    clearTimeout(connectTimeout);
    CrashlyticsEvents.log(
      'Exception',
      'HomeScreen:HandleConnectTimeout',
      '',
      guid,
    );
    Events.Error.trackEvent(
      'Exception',
      'HomeScreen:HandleConnectTimeout',
      '',
      guid,
    );
    handleDisconnect();
    context?.actions.hideSpinner();

    if (error) {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }
  };

  const startConnectionTimeout = (timeout: number) => {
    return setTimeout(() => {
      handleConnectTimeout(true);
    }, timeout);
  };

  const onAuthorize = async (authResultData: {
    AuthResult: {
      AuthorizationAmount: number;
      TimeoutBetweenVends: number;
      TransactionId: string;
      TransactionType: string;
      ErrorMessage: string;
    };
    Settings: VendingMachineSetting[];
  }) => {
    const validAuthorieResult =
      authResultData &&
      authResultData.AuthResult &&
      !authResultData.AuthResult.ErrorMessage;

    if (validAuthorieResult && authResultData.AuthResult.TransactionId) {
      _crntVendTransId =
        authResultData?.AuthResult?.TransactionId ??
        VendSession?.MachineConfig?.TransactionId;
      context?.actions.showSpinner('', true, handleConnectTimeout);

      if (selectedDevice.type === BluetoothDeviceTypes.vendorsExchange) {
        const vendorsExchangeDevice = selectedDevice as VendorsExchangeDevice;
        await selectedDevice.connect();
        context?.actions.hideSpinner();

        if (vendorsExchangeDevice.currency === AccountStore.getCurrency()) {
          const machineConfig = new VendingMachineConfig(
            authResultData.AuthResult,
            authResultData.Settings,
          );
          VEVendSession.startSession(vendorsExchangeDevice, machineConfig);
          NavActions.push(AppRoutes.VendorsExchangeVending);
        } else {
          alertError(Localized.Labels.incompatible_currency);
          handleDisconnect();
        }
      } else {
        connectTimeout = startConnectionTimeout(TIMEOUT_MILLISECONDS * 3);
        const machineConfig = new VendingMachineConfig(
          authResultData.AuthResult,
          authResultData.Settings,
        );
        addDeviceListeners();
        TSFInsideVendSession.startSession(selectedDevice, machineConfig);
      }
    } else {
      context?.actions.hideSpinner();
      if (
        authResultData?.AuthResult?.TransactionId ||
        VendSession?.MachineConfig?.TransactionId ||
        _crntVendTransId
      ) {
        cancelTransaction(
          authResultData?.AuthResult?.TransactionId ??
            VendSession?.MachineConfig?.TransactionId ??
            _crntVendTransId,
        );
      }

      if (
        !authResultData.AuthResult ||
        !authResultData.AuthResult.ErrorMessage
      ) {
        alertError(Localized.Errors.unable_to_vend);
      } else {
        alertError(authResultData.AuthResult.ErrorMessage);
      }
    }

    authorizing = false;
  };

  const onMachineConnectionError = async () => {
    if (VendSession?.MachineConfig?.TransactionId || _crntVendTransId) {
      cancelTransaction(
        VendSession?.MachineConfig?.TransactionId ?? _crntVendTransId,
      );
    }
    const guid = await uuid.getRandomUUID();
    clearTimeout(connectTimeout);
    context?.actions.hideSpinner();
    const error = MachineStore.getConnectionError();

    if (error === 'invalidkey') {
      alertError(Localized.Errors.invalid_key, guid);
    } else if (error === 'notavailable') {
      alertError(
        Localized.Errors.notification_sent_to_operator,
        guid,
        undefined,
        Localized.Errors.unable_to_connect_with_vending_machine,
      );
    } else {
      alertError(Localized.Errors.unable_to_connect_with_vending_machine, guid);
    }
    handleDisconnect();
    CrashlyticsEvents.log(
      'Exception',
      'HomesScreen:OnConnectionError',
      error,
      guid,
    );
    Events.Error.trackEvent(
      'Exception',
      'HomesScreen:OnConnectionError',
      error,
      guid,
    );
  };

  //Cancel Transcation
  const cancelTransaction = (transId: string) => {
    TransactionActions.cancelTransaction(transId);
    _crntVendTransId = null;
  };

  const onToastDismiss = () => {
    setIsToastVisible(false);
  };
  const onToastEnable = () => {
    setIsToastVisible(true);
  };

  const onRefresh = async () => {
    FirebaseAnalytic.trackEvent('onRefresh', 'HomeScreen', {
      ...props,
    });
    setRefreshing(true);
    getRecentLocations();
    setIsToastVisible(false);
    try {
      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });
    } catch (error: any) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'HomeScreen:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'HomeScreen:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
    }
    setRefreshing(false);
  };

  //Render location list of selected campus/location based on category
  const renderCampusLocations = () => {
    const content = [];
    if (Settings.isCanteen() && scanAndPayLocations.length > 0) {
      content.push(
        <ScanAndPayLocationsList
          locations={scanAndPayLocations}
          isConnected={true}
          onLocationClick={onMachineSelect}
        />,
      );
    }

    if (diningLocations.length > 0) {
      content.push(
        <CampusLocationsList
          locations={diningLocations}
          categoryType={CampusLocationCategory.Dining}
          isConnected={true}
          onLocationClick={onMachineSelect}
        />,
      );
    }
    if (vendingLocations.length > 0) {
      content.push(
        <VendingLocationList
          locations={vendingLocations}
          isConnected={true}
          onLocationClick={onMachineSelect}
          onPlanogramClick={onPlanogramClick}
        />,
      );
    }
    return content;
  };
  const currentSnack = snackList[currentIndex];

  const renderContent = () => {
    if (Settings.buildType === BuildTypeConstants.default) {
      return (
        <>
          <GreetingsAndBalanceView />
          <RewardsView />
          {!refreshing &&
            !selectedLocation &&
            scanAndPayLocations.length <= 0 &&
            vendingLocations.length <= 0 &&
            diningLocations.length <= 0 && (
              <View
                accessible={true}
                accessibilityLabel={Localized.Labels.logo_noreovolveblockeduser}
                accessibilityRole="image"
                role="img"
                aria-label={Localized.Labels.logo_noreovolveblockeduser}
                style={styles.noLocationContainer}
              >
                <AVText style={styles.emptyLocation}>
                  {Localized.Labels.empty_location_message}
                </AVText>

                <View style={styles.noLocationImgContainer}>
                  <View style={styles.noLocationImgSpacing}>
                    <Milk />
                  </View>
                  <View style={styles.noLocationImgSpacing}>
                    <CoolDrink />
                  </View>
                  <View style={[styles.noLocationImgSpacing, {top: 6}]}>
                    <Awareness />
                  </View>
                  <View style={styles.noLocationImgSpacing}>
                    <Fruit />
                  </View>
                </View>
              </View>
            )}
        </>
      );
    } else if (Settings.buildType === BuildTypeConstants.canteen) {
      return (
        <>
          <QRCodeHeader isConnected={props.isConnected} />
        </>
      );
    }
  };

  return (
    <NewHeader
      title=""
      content={
        <View style={styles.headerView}>
          <TouchableOpacity
            style={styles.campusView}
            onPress={() => {
              FirebaseAnalytic.trackEvent(
                'selectALocationClick',
                'HomeScreen',
                {
                  ...props,
                },
              );
              NavActions.navigate(AppRoutes.CampusLocations);
            }}
            accessible={true}
            accessibilityHint="Double tap to navigate to the Locations Screen"
            accessibilityRole={'button'}
            accessibilityLabel="SelectLocationDropdown"
            aria-label="SelectLocationDropdown"
            testID="SelectLocationDropdown"
          >
            <LocationMarker
              size={Styles.Fonts.f3}
              color={Settings.is365Pay() && Styles.Colors.PayNew.white01}
            />
            <AVText
              style={[
                styles.campusNameText,
                Settings.is365Pay() && styles.locationText365,
              ]}
              numberOfLines={1}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            >
              {getSelectedCampusOrLocation()}
            </AVText>
            <FontAwesome5Pro
              accessible={true}
              accessibilityLabel={
                'Down arrow icon, Double tap to the Locations Screen'
              }
              aria-label={'Down arrow icon, Double tap to the Locations Screen'}
              name="chevron-down"
              color={
                Settings.is365Pay()
                  ? Styles.Colors.PayNew.white01
                  : Styles.lightGray
              }
              size={Styles.Heights.touchTargetHeight0}
              style={styles.campusArrow}
            />
          </TouchableOpacity>
          <TouchableOpacity
            accessible={true}
            style={styles.notificationLogo}
            accessibilityHint="Double tap to navigate to the Notifications screen"
            accessibilityRole={'button'}
            accessibilityLabel="NotificationsIcon"
            aria-label="NotificationsIcon"
            testID="NotificationsIcon"
            onPress={() => {
              FirebaseAnalytic.trackEvent(
                'NotificationsButtonClick',
                'connect and pay',
              );
              NavActions.push(AppRoutes.Inbox, {
                selectedTab: 'Messages',
              });
            }}
          >
            {unreadNotifications ? (
              <NotificationUnReadIcon
                color={
                  Settings.is365Pay() ? Styles.Colors.PayNew.white01 : '#003349'
                }
                size={Styles.Fonts.f4 + 6}
              />
            ) : (
              <NotificationIcon
                color={
                  Settings.is365Pay() ? Styles.Colors.PayNew.white01 : '#003349'
                }
                size={Styles.Fonts.f3}
              />
            )}
          </TouchableOpacity>
        </View>
      }
    >
      {Settings.is365Pay() && !referralId && !payrollImportId && (
        <InteractiveTutorial />
      )}

      <ScrollView
        style={styles.content}
        refreshControl={
          props.isConnected ? (
            <RefreshControl
              aria-label={refreshing ? 'refreshing' : ''}
              refreshing={refreshing}
              accessibilityLabel={refreshing ? 'refreshing' : ''}
              colors={[Styles.primaryColor]}
              accessible={true}
              onRefresh={onRefresh}
              titleColor={Styles.black}
              tintColor={Styles.primaryColor}
            />
          ) : undefined
        }
      >
        {renderContent()}
        <EngagementCarousel
          isConnected={props.isConnected}
          showHeader={Settings.buildType === 'default'}
          onViewAll={() => NavActions.navigate(AppRoutes.Offers)}
          onToastEnable={onToastEnable}
        />
        {renderCampusLocations()}
        {selectedLocation && (
          <>
            <SendASnackView />
            <ReferralView />
          </>
        )}
        {Settings.buildType === 'default' && announcementsData?.length > 0 ? (
          <AnnouncementsList
            isConnected={true}
            announcements={announcementsData.slice(0, 2)}
            showHeader={Settings.buildType === 'default'}
            onViewAll={() => NavActions.navigate(AppRoutes.Inbox)}
            showViewAll={announcementsData?.length > 2}
          />
        ) : null}
        <View style={{backgroundColor: 'transparent', paddingBottom: 16}} />
      </ScrollView>
      {Settings.is365Pay() && showSendSnackModal && currentSnack && (
        <SuccessModal
          isModalVisible={showSendSnackModal && isFocused}
          headerTitle={
            Localized.Success.formatString(
              Localized.Labels.one_time_snack_accept_header,
              currentSnack?.amount.toFixed(2).toString(),
              currentSnack?.senderName,
            ) as string
          }
          description={Localized.Labels.one_time_snack_accept_desc}
          buttonTitle={Localized.Labels.one_time_snack_accept_btn}
          descLines={2}
          oneTimeSendSnackPopUp={true}
          customAction={() => onAcceptModalBtn(currentSnack)}
          onModalClose={() => hideModal()}
        />
      )}
      {Settings.is365Pay() && showErrorSendSnackModal && (
        <SuccessModal
          isModalVisible={showErrorSendSnackModal}
          headerTitle={Localized.Errors.one_time_snack_cancelled_error_header}
          description={Localized.Errors.one_time_snack_365Pay_error_desc}
          buttonTitle={Localized.Buttons.okay}
          descLines={5}
          errorSendSnackPopUp={true}
          onModalClose={() => hideErrorModal()}
        />
      )}
      {Settings.is365Pay() && isToastVisible && (
        <Snackbar
          message={Localized.Labels.page_taking_longer_to_load}
          duration={4000}
          backgroundColor={
            getDescriberTypeElements().getStyleWithColorOnly()[
              'backgroundColor'
            ]
          }
          textColor={
            getDescriberTypeElements().getStyleWithColorOnly()['color']
          }
          onDismiss={onToastDismiss}
        />
      )}
      {Settings.is365Pay() && scanAndPayLocations.length > 0 && (
        <RoundedAppButton
          buttonType={RoundedAppButtonType.Solid}
          onPress={() => onScanAndPayClick(scanAndPayLocations)}
          accessibilityLabelValue={Localized.Labels.product}
          titleText={Localized.Labels.product}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          textStyle={[styles.applyButtonText]}
          buttonIcon={<ScanProductBarCode />}
          buttonContainerStyle={{
            right: Styles.Spacing.m3,
            position: 'absolute',
            bottom: Styles.Spacing.m3 + 6,
          }}
          buttonViewStyle={{
            paddingHorizontal: 16,
            paddingVertical: 10,
            minHeight: 42,
            gap: 5,
          }}
        />
      )}
    </NewHeader>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: backGroundColorHomeScreen(),
    flex: 1,
  },
  content: {
    flex: 1,
  },
  headerView: {
    flexDirection: 'row',
    width: '100%',
    height: Styles.Heights.touchTargetHeight1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: Platform.OS === 'ios' ? 8 : 34,
  },
  accountView: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    right: Styles.Spacing.m2,
    width: Styles.Heights.touchTargetHeight1,
    height: Styles.Heights.touchTargetHeight1,
  },
  campusView: {
    flexDirection: 'row',
    maxWidth: '65%',
    height: Styles.Heights.h5,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: Styles.Spacing.m3,
    marginRight: Styles.Spacing.m4,
  },
  campusNameText: {
    marginLeft: Styles.Spacing.m0,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    maxWidth: '100%',
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  locationText365: {
    color: Styles.white,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '700',
  },
  campusArrow: {
    marginLeft: Styles.Spacing.m2,
    marginTop: 2,
  },
  notificationLogo: {
    right: Styles.Spacing.m2,
    width: Styles.Heights.touchTargetHeight1,
    height: Styles.Heights.touchTargetHeight1,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Styles.Heights.h1 - 5,

    position: 'absolute',

    paddingBottom: Styles.Spacing.m1,
  },
  emptyLocation: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '700',
  },
  noLocationContainer: {
    marginVertical: 'auto',
    paddingTop: 120,
    paddingHorizontal: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noLocationImgSpacing: {
    paddingHorizontal: 10,
  },
  noLocationImgContainer: {
    paddingVertical: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  applyButtonText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 18,
    textAlign: 'right',
    fontWeight: '700',
  },
});

export default withForwardedNavigationParams()(withIsConnected(HomeScreen));
