import React from 'react';
import {StyleSheet, Text, View} from 'react-native';

import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import {DNA} from 'src/types/dnaTypes';
import AccountStore from 'src/stores/AccountStore';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {MenuProductType} from 'src/types/Menu';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';

type SearchProductItemProps = {
  imageUrl?: string;
  name: string;
  price: number;
  item?: MenuProductType;
  dnaConfiguration?: DNA;
  onPress: () => void;
  isProductCat?: boolean;
};

function SearchProductItem(props: SearchProductItemProps) {
  const calories = props.item?.calories;

  const unitDisplayName = props?.dnaConfiguration?.showCaloriesAsKilocalories
    ? Localized.Labels.kcal
    : Localized.Labels.cal;

  return (
    <View
      accessible={true}
      accessibilityRole="button"
      accessibilityHint="Double tap to navigate to the product details screen"
      accessibilityState={{disabled: false}}
      aria-disabled={false}
      role="button"
      style={styles.container}
    >
      <Text
        accessible={true}
        accessibilityLabel={`${props.name}, `}
        accessibilityRole="text"
        aria-label={`${props.name}, `}
        maxFontSizeMultiplier={2}
        style={styles.productName}
      >
        {props.name}

        {Boolean(props.dnaConfiguration?.showCalories && calories !== null) && (
          <Text
            accessible={true}
            accessibilityLabel={`${calories} ${Localized.Labels.calories.toLowerCase()}, `}
            accessibilityRole="text"
            aria-label={`${calories} ${Localized.Labels.calories.toLowerCase()}, text, `}
            maxFontSizeMultiplier={2}
          >
            {` ${calories} ${unitDisplayName}`}
          </Text>
        )}
      </Text>

      <AVFormattedCurrency
        style={styles.productPrice}
        value={props.price}
        accessible={true}
        aria-label={`$${props.price.toFixed(2)}, `}
        accessibilityLabel={`$${props.price.toFixed(2)}, text, `}
        currency={AccountStore.getCurrency()}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        maxFractionDigits={2}
      />

      <View>
        <RoundedButton
          wrapperStyle={{paddingVertical: 0, marginVertical: 0}}
          buttonType={ButtonType.outline}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessible={true}
          accessibilityLabel={Localized.Buttons.add}
          accessibilityRole="button"
          aria-label={Localized.Buttons.add}
          role="button"
          onPress={props.onPress}
          isProductSearch={true}
          text={Localized.Buttons.add}
          color={'#066DFF'}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    marginTop: Styles.Spacing.m2,
    marginHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    flexDirection: 'row',
    alignItems: 'center',
    ...Styles.Style.shadow,
  },
  productPrice: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.Colors.PayNew.black01,
    marginRight: Styles.Spacing.m2,
  },
  productName: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.Colors.PayNew.black01,
    marginRight: Styles.Spacing.m2,
  },
});

export default SearchProductItem;
