import * as React from 'react';
import Svg, {Path, G, Mask} from 'react-native-svg';

type EyelidProps = {
  size: number;
}; // tslint:disable

export default class Eyelid extends React.Component<EyelidProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 20 18"
        fill="none"
      >
        <G id="Group 12">
          <Path
            id="Fill 1"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 5.02051C11.6865 5.02051 13.3091 5.54013 14.9605 6.60901C16.0562 7.31826 17.0284 8.18426 17.8488 9.00544C16.9186 9.8711 15.8624 10.7819 14.6795 11.5131L15.7828 12.6163C17.4618 11.5214 18.8609 10.1476 20 9.05994C17.6875 6.53335 14.4107 3.49976 10 3.49976C8.99162 3.49976 8.04488 3.66007 7.12891 3.96244L8.36553 5.1991C8.89628 5.08169 9.43831 5.02051 10 5.02051Z"
            fill="#9A9A9F"
          />
          <Path
            id="Fill 3"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10 12.9792C8.32119 12.9792 6.86372 12.5564 5.41325 11.6485C4.31228 10.9594 3.26887 10.0277 2.19978 9.00877C3.30366 7.99271 4.33122 7.15252 5.34291 6.50908L4.24566 5.41187C2.87294 6.32777 1.49575 7.5438 0 8.99999C3.00894 11.909 5.53572 14.5 10 14.5C11.0184 14.5 11.9751 14.3284 12.8724 14.0386L11.6321 12.7983C11.1064 12.914 10.5627 12.9792 10 12.9792Z"
            fill="#9A9A9F"
          />
          <Path
            id="Fill 5"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2712 12.4372L9.97666 11.1427L7.85707 9.02305L6.56251 7.72852C6.41601 8.12461 6.33569 8.55277 6.33569 8.99973C6.33569 11.0235 7.97626 12.664 9.99997 12.664C10.447 12.664 10.8751 12.5837 11.2712 12.4372Z"
            fill="#9A9A9F"
          />
          <Path
            id="Fill 7"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.72876 5.56251L10.0233 6.85704L12.1429 8.97669L13.4374 10.2712C13.584 9.87516 13.6643 9.44697 13.6643 8.99997C13.6643 6.97626 12.0237 5.33569 10 5.33569C9.55301 5.33569 9.12482 5.41597 8.72876 5.56251Z"
            fill="#9A9A9F"
          />
          <G id="Group 11">
            <Mask id="mask0_5266_1794" x="1" y="0">
              <Path
                id="Clip 10"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.20007 0.199951H18.8V17.7998H1.20007V0.199951Z"
                fill="white"
              />
            </Mask>
            <G mask="url(#mask0_5266_1794)">
              <Path
                id="Fill 9"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.20007 1.29086L17.7091 17.7999L18.8 16.709L2.29098 0.199951L1.20007 1.29086Z"
                fill="#9A9A9F"
              />
            </G>
          </G>
        </G>
      </Svg>
    );
  }
}
