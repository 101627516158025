import * as React from 'react';
import Svg, {Path, G, ClipPath, Defs, Rect} from 'react-native-svg';

type InfoIconProps = {
  size: number;
}; // tslint:disable

export default class InfoIconImage extends React.Component<InfoIconProps> {
  render() {
    return (
      <Svg width="28" height="28" viewBox="0 0 24 25" fill="none">
        <G clip-path="url(#clip0_5806_6143)">
          <Path
            d="M12 2C14.7848 2 17.4555 3.10625 19.4246 5.07538C21.3938 7.04451 22.5 9.71523 22.5 12.5C22.5 15.2848 21.3938 17.9555 19.4246 19.9246C17.4555 21.8938 14.7848 23 12 23C9.21523 23 6.54451 21.8938 4.57538 19.9246C2.60625 17.9555 1.5 15.2848 1.5 12.5C1.5 9.71523 2.60625 7.04451 4.57538 5.07538C6.54451 3.10625 9.21523 2 12 2ZM12 24.5C15.1826 24.5 18.2348 23.2357 20.4853 20.9853C22.7357 18.7348 24 15.6826 24 12.5C24 9.3174 22.7357 6.26516 20.4853 4.01472C18.2348 1.76428 15.1826 0.5 12 0.5C8.8174 0.5 5.76516 1.76428 3.51472 4.01472C1.26428 6.26516 0 9.3174 0 12.5C0 15.6826 1.26428 18.7348 3.51472 20.9853C5.76516 23.2357 8.8174 24.5 12 24.5ZM9.75 17C9.3375 17 9 17.3375 9 17.75C9 18.1625 9.3375 18.5 9.75 18.5H14.25C14.6625 18.5 15 18.1625 15 17.75C15 17.3375 14.6625 17 14.25 17H12.75V11.75C12.75 11.3375 12.4125 11 12 11H10.125C9.7125 11 9.375 11.3375 9.375 11.75C9.375 12.1625 9.7125 12.5 10.125 12.5H11.25V17H9.75ZM12 9.125C12.2984 9.125 12.5845 9.00647 12.7955 8.7955C13.0065 8.58452 13.125 8.29837 13.125 8C13.125 7.70163 13.0065 7.41548 12.7955 7.2045C12.5845 6.99353 12.2984 6.875 12 6.875C11.7016 6.875 11.4155 6.99353 11.2045 7.2045C10.9935 7.41548 10.875 7.70163 10.875 8C10.875 8.29837 10.9935 8.58452 11.2045 8.7955C11.4155 9.00647 11.7016 9.125 12 9.125Z"
            fill="#022649"
          />
        </G>
        <Defs>
          <ClipPath id="clip0_5806_6143">
            <Rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.5)"
            />
          </ClipPath>
        </Defs>
      </Svg>
    );
  }
}
