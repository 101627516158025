import RevolveDescriptor from './common-navigation-revolve';
import RedesignDescriptor from './common-navigation-redesign';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: RedesignDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
