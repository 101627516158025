import * as React from 'react';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import BackSubheader from '../elements/BackSubheader';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import Localized from 'src/constants/AppStrings';
import WarningIconSnackDetails from 'src/components/img/svg/WarningIconSnackDetails';
import Styles from 'src/components/Styles';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import ReferralDetailsIcon from 'src/components/img/svg/ReferralDetailsIcon';
import AVText from 'src/components/elements/AVText';
import SuccessIconReferralDetails from 'src/components/img/svg/SuccessIconReferralDetails';
import {Referral, ReferralStatus} from 'src/types/Referral';
import ScreenContext from 'src/components/ScreenContext';
import ReferralActions from 'src/actions/ReferralActions';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import Events from 'src/logging/Events';
import {alertError} from '../helpers/AlertHelper';
import LoadingScreen from 'src/components/screens/LoadingScreen';
import Util from 'src/Util';
import ReferralSignUpDetailsIcon from 'src/components/img/svg/ReferralSignUpDetailsIcon';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import HttpClient from 'src/api/HttpClient';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import {getDescriber} from './descriptor/DescriptorType';
import {
  markSingleMessageAsRead,
  NotificationMessageParams,
} from 'src/redux/thunks/notificationThunks/messageRead';

type ReferralDetailScreenProps = WithGlobalizeProps & {
  referralId: string;
  selectedReferItem: Referral;
  markMessageAsRead(params: NotificationMessageParams): void;
};
type ReferralDetailScreenState = {
  referalDetails: Referral;
  referralSuccess: boolean;
};

class ReferralDetailsScreen extends React.Component<
  ReferralDetailScreenProps,
  ReferralDetailScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.fetchReferralDetails = this.fetchReferralDetails.bind(this);
    this.state = {
      referalDetails: null,
      referralSuccess: false,
    };
  }

  componentDidMount() {
    this.props.markMessageAsRead({
      externalServiceId: this.props.selectedReferItem.externalServiceId,
      messageType: 'Referral',
      locationId: this.props.selectedReferItem.locationId,
    });
    this.fetchReferralDetails();
  }
  async fetchReferralDetails() {
    try {
      const referalDetails = await ReferralActions.getReferralDetails(
        this.props.referralId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralDetails',
        JSON.stringify({referralId: this.props.referralId}),
        JSON.stringify(referalDetails),
      );
      this.setState({
        referalDetails,
      });
    } catch (e) {
      const errMsg = e.message ? e.message : e.toString();
      CrashlyticsEvents.log(
        'Exception',
        'ReferralDetailsScreen:fetchReferralDetails',
        errMsg,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralDetailsScreen:fetchReferralDetails',
        errMsg,
      );
      alertError(errMsg);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'fetchReferralDetails',
        JSON.stringify({referralId: this.props.referralId}),
        JSON.stringify(e),
      );
    }
  }
  getDateText() {
    const snackDate = Util.formatDate(
      this.props.selectedReferItem.dateCreated,
      'MMM DD, YYYY',
    );
    const snackTime = Util.formatDate(
      this.props.selectedReferItem.dateCreated,
      'hh:mm A',
    );
    return snackDate + ' at ' + snackTime;
  }
  renderDescription(isSender) {
    const {selectedReferItem} = this.props;
    const {Labels} = Localized;
    const prefix = isSender
      ? Labels.formatString(
          Labels.your_friend_referral,
          selectedReferItem?.receiverName ?? '',
          Util.formatCurrency(
            this.props,
            selectedReferItem?.amount ?? 0,
            AccountStore.getCurrency(),
          ),
        )
      : Labels.formatString(
          Labels.thanks_for_referral_joining,
          Settings.appDisplayName,
          Util.formatCurrency(
            this.props,
            selectedReferItem?.amount ?? 0,
            AccountStore.getCurrency(),
          ),
        );

    return `${prefix}`;
  }
  renderDescriptiontitle(isSender: boolean) {
    return isSender
      ? Localized.Labels.your_referral
      : Localized.Labels.your_referral_sign_up;
  }

  renderSuccessErrorDescription() {
    const {selectedReferItem: selectedItem} = this.props;
    const {referalDetails: errorReferralDetails} = this.state;
    const isSender = selectedItem?.sender === AccountStore?.getAccountId();
    const status = isSender
      ? errorReferralDetails?.senderRedeem
      : errorReferralDetails?.receiverRedeem;
    return (
      <>
        {(status === ReferralStatus.Claimed || this.state.referralSuccess) && (
          <AVText
            style={
              getDescriber().renderReferralStyleDescriptor()[
                'referralSuccErrorText'
              ]
            }
          >
            <AVText style={[styles.referralTextDesc]}>
              {Localized.Labels.referral_suc_text}
            </AVText>
            {Localized.Labels.referral_suc_text_cont}
          </AVText>
        )}
        {status === ReferralStatus.Expired && (
          <AVText
            style={
              getDescriber().renderReferralStyleDescriptor()[
                'referralSuccErrorText'
              ]
            }
          >
            <AVText style={styles.referralTextDesc}>
              {Localized.Labels.referral_err_text}
            </AVText>
            {Localized.Labels.referral_err_text_cont}
          </AVText>
        )}
      </>
    );
  }

  async onReferralClaim(referral: Referral) {
    this.context.actions.showSpinner();
    let redeemReferralResponse;
    try {
      redeemReferralResponse = await ReferralActions.redeemReferral(
        AccountStore.getAccountId(),
        referral.referralId,
        Util.getCurrentDate(),
      );
      const error = HttpClient.parseJsonSafe(
        redeemReferralResponse?.message ?? redeemReferralResponse.toString(),
      );
      if (error?.code) {
        let message = Localized.Labels.unable_to_redeem;
        if (error?.code === PlatformApiErrors.CampaignAlreadyExpired) {
          message = Localized.Labels.campaign_already_expired;
        } else if (error?.code === PlatformApiErrors.ReferralAlreadyRedeemed) {
          message = Localized.Labels.reward_already_redeemed;
        }
        alertError(message);
      } else {
        this.setState({
          referralSuccess: true,
        });
      }
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(redeemReferralResponse),
      );
      FirebaseAnalytic.trackEvent(
        'onReferralClaim',
        'ReferralDetailScreen: onReferralClaim',
        {
          ...this.props,
          ...this.state,
        },
      );
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());
      CrashlyticsEvents.log(
        'Exception',
        'ReferralDetailScreen: onReferralClaim',
        error,
        redeemReferralResponse,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralDetailScreen: onReferralClaim',
        error,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchAccount',
        JSON.stringify({
          referralId: referral.referralId,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  render() {
    const {selectedReferItem} = this.props;
    const {referalDetails} = this.state;
    const isSender = selectedReferItem?.sender === AccountStore?.getAccountId();
    const status = isSender
      ? referalDetails?.senderRedeem
      : referalDetails?.receiverRedeem;
    const describerprops = {
      referalDetails,
      isSender,
    };
    if (!referalDetails) {
      return <LoadingScreen />;
    }
    const referralHeaderColor =
      Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor;

    return (
      <BackSubheader
        style={{backgroundColor: referralHeaderColor}}
        title={
          describerprops && describerprops.isSender
            ? Localized.Labels.referral
            : Localized.Labels.referral_sign_up
        }
      >
        <ScrollView
          style={[
            styles.scrollView,
            {backgroundColor: getDescriber().referralDetailBgColorDescriptor()},
          ]}
        >
          <View style={styles.iconContainer}>
            {isSender ? <ReferralDetailsIcon /> : <ReferralSignUpDetailsIcon />}
          </View>
          <View style={styles.textContainer}>
            <AVText
              style={
                getDescriber().renderReferralStyleDescriptor()[
                  'referralHederText'
                ]
              }
            >
              {this.renderDescriptiontitle(isSender)}
            </AVText>
            <AVText
              style={
                getDescriber().renderReferralStyleDescriptor()[
                  'referralDescText'
                ]
              }
            >
              {this.getDateText()}
            </AVText>
          </View>
          <View style={styles.flexTextContainer}>
            <AVText
              style={[
                getDescriber().renderReferralStyleDescriptor()[
                  'referralDescText'
                ],
                styles.succText,
              ]}
            >
              {this.renderDescription(isSender)}
            </AVText>
          </View>
          {(status === ReferralStatus.Claimed ||
            this.state.referralSuccess ||
            status === ReferralStatus.Expired) && (
            <View
              style={[
                styles.referralSuccErrView,
                styles.flexRowContainer,
                {
                  backgroundColor:
                    status === ReferralStatus.Claimed ||
                    this.state.referralSuccess
                      ? getDescriber().claimeSucessdBtnColorDescriptor()
                      : getDescriber().claimedErrorBtnColorDescriptor(),
                },
              ]}
            >
              <View
                style={
                  Platform.OS === 'web' ? styles.flexLeftWeb : styles.flexLeft
                }
              >
                {(status === ReferralStatus.Claimed ||
                  this.state.referralSuccess) && <SuccessIconReferralDetails />}
                {status === ReferralStatus.Expired && (
                  <WarningIconSnackDetails />
                )}
              </View>
              <View style={styles.flexRight}>
                {this.renderSuccessErrorDescription()}
              </View>
            </View>
          )}
        </ScrollView>

        {this.props.selectedReferItem?.isActive &&
          status === ReferralStatus?.Active &&
          !this.state.referralSuccess && (
            <RoundedButton
              accessibilityLabel={Localized.Labels.claim}
              buttonType={ButtonType.action}
              color={getDescriber().claimBtnColorDescriptor()}
              text={Localized.Labels.claim}
              onPress={() => this.onReferralClaim(referalDetails)}
              containerStyle={styles.referralBtn}
            />
          )}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  referralBtn: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  referralSuccErrView: {
    flexDirection: 'row',
    marginBottom: 10,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 12,
  },

  scrollView: {
    flex: 1,
    padding: 20,
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  textContainer: {
    marginBottom: 15,
  },
  flexTextContainer: {
    flexDirection: 'row',
    marginBottom: 25,
  },
  flexRowContainer: {
    flexDirection: 'row',
  },
  flexLeft: {
    flex: 0.1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flexRight: {
    flex: 0.9,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  referralTextDesc: {
    fontWeight: '700',
    paddingRight: 5,
  },
  flexLeftWeb: {
    marginRight: 10,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  succText: {
    flex: 0.8,
  },
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  markMessageAsRead: (params) => dispatch(markSingleMessageAsRead(params)),
});

export default compose(
  withForwardedNavigationParams<ReferralDetailScreenProps>(),
  withGlobalize,
  connect(null, mapDispatchToProps),
)(ReferralDetailsScreen);
