import React, {useEffect, useRef, useState} from 'react';
import {
  Text,
  TextInput,
  StyleSheet,
  View,
  Animated,
  Easing,
  TouchableWithoutFeedback,
  StyleProp,
  ViewStyle,
  AccessibilityState,
  AccessibilityValue,
  TextStyle,
  PixelRatio,
  Platform,
} from 'react-native';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import ScansearchIcon from 'src/components/img/svg/ScansearchIcon';

type Props = React.ComponentProps<typeof TextInput> & {
  label: string;
  value: string;
  hasError?: boolean;
  errorMessage?: string | null;
  placeholder?: string;
  multiline?: boolean;
  accessible?: boolean;
  accessibilityLabel?: string;
  maxFontSizeMultiplier?: number;
  accessibilityValue?: AccessibilityValue;
  accessibilityState?: AccessibilityState;
  secureTextEntry?: boolean;
  maxLength?: number;
  hasIcon?: boolean;
  iconStyle?: StyleProp<ViewStyle>;
  iconName?: string;
  iconHeight?: number;
  iconLabel?: string;
  hasInlineImage?: boolean;
  imageElement?: React.ReactElement;
  onChangeText?: (value: string) => void;
  iconPressed?: () => void;
  showHelperText?: boolean;
  helperText?: string;
  disabled?: boolean;
  testID?: string;
  newPaymentOptionsUI?: boolean;
  leftIcon?: React.ReactNode;
  leftText?: string;
  iconContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  labelStyle?: StyleProp<TextStyle>;
  isSnack?: boolean;
  isProductSearch?: boolean;
  keyboardStatus?: string;
  helperStyle?: StyleProp<TextStyle>;
};
const diffInputPadding = Platform.OS == 'ios' ? Styles.Spacing.m2 : 0;

const AllyTextInput: React.FC<Props> = (props) => {
  const {
    label,
    hasError,
    errorMessage,
    value,
    onBlur,
    onFocus,
    leftIcon,
    isSnack = false,
    ...restOfProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<TextInput>(null);
  const focusAnim = useRef(new Animated.Value(0)).current;

  const descriptor = getDescriber();

  function getLastFourChars(str: string): string {
    const containsNumber = /\d/.test(str);
    const containsAsterisk = /\*/.test(str);

    if (containsNumber && containsAsterisk) {
      return (
        (props.leftText || '').toLocaleUpperCase() + '  ****' + str.slice(-4)
      );
    } else {
      return str || '';
    }
  }

  useEffect(() => {
    Animated.timing(focusAnim, {
      toValue: isFocused || !!value ? 1 : 0,
      duration: 150,
      easing: Easing.bezier(0.4, 0, 0.2, 1),
      useNativeDriver: true,
    }).start();
  }, [focusAnim, isFocused, value]);

  const color = descriptor.getColor(isFocused, !!hasError, isSnack);

  const backgroundColor = descriptor.getBackgroundColor(isFocused, value);
  const textInputHeight = descriptor.getTextInputHeight(
    props?.multiline,
  ).inputHeight;

  const textAlignVertical = descriptor.getTextInputHeight(
    props?.multiline,
  ).textAlignVertical;

  const searchTxtFont = () => {
    if (
      props.keyboardStatus === 'Keyboard Hidden' ||
      props.keyboardStatus === 'Keyboard Shown'
    ) {
      return props.value.length >= 1 ? 14 : 20;
    }
  };

  const getComponentBasedValue = () => {
    return (
      <TouchableWithoutFeedback onPress={() => inputRef.current?.focus()}>
        <Animated.View
          style={[
            descriptor.getStyles().labelContainer,
            props.keyboardStatus === 'Keyboard Hidden' &&
              value.length === 0 &&
              props.isProductSearch && {left: 30, top: 24},
            props.keyboardStatus === 'Keyboard Hidden' &&
              value.length >= 1 &&
              props.isProductSearch && {left: 6},
            props.keyboardStatus === 'Keyboard Shown' &&
              props.isProductSearch && {left: 6},
            {
              backgroundColor: backgroundColor,
              transform: [
                {
                  scale: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: descriptor.getAnimationConfig(props.label)
                      .scale,
                  }),
                },
                {
                  translateY: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: descriptor.getAnimationConfig(
                      props.label,
                      props.iconName,
                    ).translateY,
                  }),
                },
                {
                  translateX: focusAnim.interpolate({
                    inputRange: [0, 1],
                    outputRange: descriptor.getAnimationConfig(
                      props.label,
                      props.iconName,
                    ).translateX,
                  }),
                },
              ],
            },
          ]}
        >
          <Text
            nativeID={props?.nativeID}
            accessibilityLabel={`${props?.accessibilityLabel} label`}
            accessibilityRole="text"
            numberOfLines={1}
            aria-label={`${props?.accessibilityLabel} label, text`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            style={[
              styles.label,
              {
                color,
              },
              props.isProductSearch && {
                fontSize: searchTxtFont(),
                fontWeight: '400',
                fontFamily: Settings.isRevolve()
                  ? Styles.FontFamily.figtreeRegular
                  : Styles.FontFamily.robotoRegular,
                top: -4,
                left: 5,
              },
              props.labelStyle,
              Platform.OS === 'ios' &&
                props.iconName &&
                (value || isFocused) && {marginTop: -6, marginLeft: -2},
            ]}
          >
            {label}
            {hasError && !isSnack ? '*' : ''}
          </Text>
        </Animated.View>
      </TouchableWithoutFeedback>
    );
  };

  return (
    <View
      accessibilityLabel={props?.accessibilityLabel}
      style={[
        props.newPaymentOptionsUI
          ? descriptor.getStyles().paymentDropDownInputContainer
          : descriptor.getStyles().container,
        props.keyboardStatus === 'Keyboard Shown' &&
          value.length >= 1 &&
          props.isProductSearch && {top: 20, paddingBottom: 20},
      ]}
    >
      {leftIcon && (
        <View style={{alignSelf: 'center', marginLeft: 4}}>
          <View style={descriptor.getStyles().leftIcon}>{leftIcon}</View>
        </View>
      )}
      {props.keyboardStatus === 'Keyboard Hidden' &&
        value.length === 0 &&
        props.isProductSearch && (
          <View
            style={{
              top: 42,
              alignItems: 'center',
              zIndex: 999999,
              width: 22,
              height: 22,
              left: 15,
            }}
          >
            <ScansearchIcon />
          </View>
        )}
      <TextInput
        multiline={!!props?.multiline}
        accessible={true}
        accessibilityLabel={props?.accessibilityLabel}
        maxFontSizeMultiplier={props?.maxFontSizeMultiplier}
        accessibilityLabelledBy={props?.nativeID}
        accessibilityValue={{text: props?.value}}
        aria-label={props?.accessibilityLabel}
        aria-labelledby={props?.nativeID}
        aria-valuetext={props?.value}
        style={[
          props.newPaymentOptionsUI
            ? descriptor.getStyles().paymentInput
            : descriptor.getStyles().input,
          {
            borderColor: color,
            color: isFocused
              ? Styles.primaryColor
              : descriptor.textInputColor(),
            backgroundColor: backgroundColor,
            height: textInputHeight,
            textAlignVertical: textAlignVertical,
            paddingLeft: props.newPaymentOptionsUI
              ? Styles.Spacing.m1
              : Styles.Spacing.m3,
          },
          props?.multiline && descriptor.getTopPadding(),
          !props.newPaymentOptionsUI
            ? descriptor.getStyles().shadow
            : undefined,
          props.textStyle,

          props.keyboardStatus === 'Keyboard Hidden' &&
            value.length === 0 &&
            props.isProductSearch && {top: 2},
          props.keyboardStatus === 'Keyboard Hidden' &&
            value.length >= 1 &&
            props.isProductSearch && {top: 2},
          props.keyboardStatus === 'Keyboard Shown' &&
            props.isProductSearch && {top: 2},
        ]}
        onChangeText={props.onChangeText}
        ref={inputRef}
        {...restOfProps}
        value={
          Boolean(props.newPaymentOptionsUI) ? getLastFourChars(value) : value
        }
        onBlur={(event) => {
          setIsFocused(false);
          onBlur?.(event);
        }}
        onFocus={(event) => {
          setIsFocused(true);
          onFocus?.(event);
        }}
        editable={props.editable}
      />
      {descriptor.getIcon(props, isFocused)}
      {props?.hasInlineImage && (
        <View style={descriptor.getStyles().imageContainer}>
          {props?.imageElement}
        </View>
      )}
      {!props.newPaymentOptionsUI && getComponentBasedValue()}
      {!value && props.newPaymentOptionsUI && getComponentBasedValue()}
      {hasError && !isSnack && errorMessage !== null && (
        <Text
          accessible={true}
          accessibilityLabel={`Error: ${errorMessage}`}
          accessibilityRole="text"
          aria-label={`Error: ${errorMessage}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={styles.error}
        >
          {errorMessage}
        </Text>
      )}
      {(!hasError || errorMessage === null) &&
        props.showHelperText &&
        props.helperText && (
          <Text
            accessible={true}
            accessibilityLabel={props.helperText}
            accessibilityRole="text"
            aria-label={`${props.helperText}, text`}
            style={[styles.help, props.helperStyle]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          >
            {props.helperText}
          </Text>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  shadow: {
    shadowOffset: {
      width: 1,
      height: 1,
    },
    borderBottomWidth: 0.6,
    borderRadius: 8,
    elevation: 1,
    shadowRadius: 2,
    shadowOpacity: 0.3,
  },
  paymentDropDownInputContainer: {
    height: 55,
    padding: 2,
    flexDirection: 'row',
    borderColor: Styles.lightGray,
    marginBottom: Styles.Spacing.m2,
    borderWidth: 1,
    borderRadius: 10,
    backgroundColor: Styles.white,
    overflow: 'hidden',
  },
  paymentInput: {
    fontSize: Styles.Fonts.f7,
    color: Styles.black,
    fontWeight: '700',
    flex: 1,
    backgroundColor: Styles.white,
  },
  input: {
    paddingHorizontal: Styles.Spacing.m1,
    paddingTop: Styles.Spacing.m3 + diffInputPadding,
    paddingBottom:
      Platform.OS == 'ios'
        ? Styles.Spacing.m2
        : Styles.Spacing.m1 + PixelRatio.getFontScale() * 2,
    borderWidth: 1,
    borderRadius: 8,
    fontSize: Styles.Fonts.f7,
    fontWeight: '500',
    marginBottom: 5,
    backgroundColor: Settings.isNewUI() ? Styles.white : Styles.inputBgColor,
  },
  iconContainer: {
    position: 'absolute',
    justifyContent: 'flex-end',
    top: 15,
    right: 16,
  },
  imageContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    right: 10,
  },
  icon: {
    backgroundColor: 'transparent',
    position: 'absolute',
    right: 0,
    width: 50,
    height: 50,
    bottom: 0,
  },
  labelContainer: {
    position: 'absolute',
    paddingHorizontal: 8,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  label: {
    fontSize: 16,
    color: Styles.lightGray,
    fontFamily: Styles.FontFamily.figtreeRegular,
    backgroundColor: Styles.transparent,
  },
  error: {
    marginTop: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m1,
    fontSize: 12,
    color: Styles.dangerColor,
  },
  help: {
    marginTop: Styles.Spacing.m1,
    marginLeft: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f8,
    color: Styles.lightGray,
  },
  textInputRevolve: {
    fontSize: Styles.Fonts.f7,
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
});

export default AllyTextInput;
