import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';
type GiftBoxProps = {
  width: number;
  height: number;
  color?: string;
  style?: Record<string, any>;
};
export default class GiftBox extends React.Component<GiftBoxProps> {
  render() {
    return (
      <>
        {Settings.isRevolve() ? (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 17 15"
            fill="none"
            style={this.props.style ? this.props.style : null}
          >
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.732 7.5v5.77c0 .796-.646 1.442-1.442 1.442H2.75a1.442 1.442 0 01-1.442-1.442V7.5a.721.721 0 01-.721-.721V4.615c0-.797.645-1.442 1.442-1.442h2.286A2.164 2.164 0 016.356.288c.722 0 1.356.36 1.753.894v-.007l.411.555.411-.555v.007a2.188 2.188 0 011.753-.894 2.164 2.164 0 012.04 2.885h2.287c.796 0 1.442.645 1.442 1.442V6.78a.721.721 0 01-.72.721zM2.75 13.27H7.8V7.5H2.75v5.77zM14.29 7.5v5.77H9.24V7.5h5.049zM6.356 1.73a.721.721 0 100 1.443.721.721 0 000-1.443zm3.606.721a.721.721 0 111.443 0 .721.721 0 01-1.443 0zM2.03 4.615v1.443H7.8V4.615h-5.77zm7.212 1.443V4.615h5.77v1.443H9.24z"
              fill={this.props.color || '#FF8B4A'}
            />
          </Svg>
        ) : Settings.is365Pay() ? (
          <Svg width={50} height={51} viewBox="0 0 50 51" fill="none">
            <Path
              d="M0 25.5C0 11.6929 11.1929 0.5 25 0.5C38.8071 0.5 50 11.6929 50 25.5C50 39.3071 38.8071 50.5 25 50.5C11.1929 50.5 0 39.3071 0 25.5Z"
              fill="#066DFF"
            />
            <Path
              d="M21.9297 16.725L23.5609 19.5H23.5H20.125C19.0891 19.5 18.25 18.6609 18.25 17.625C18.25 16.5891 19.0891 15.75 20.125 15.75H20.2281C20.9266 15.75 21.5781 16.1203 21.9297 16.725ZM16 17.625C16 18.3 16.1641 18.9375 16.45 19.5H14.5C13.6703 19.5 13 20.1703 13 21V24C13 24.8297 13.6703 25.5 14.5 25.5H35.5C36.3297 25.5 37 24.8297 37 24V21C37 20.1703 36.3297 19.5 35.5 19.5H33.55C33.8359 18.9375 34 18.3 34 17.625C34 15.3469 32.1531 13.5 29.875 13.5H29.7719C28.2766 13.5 26.8891 14.2922 26.1297 15.5813L25 17.5078L23.8703 15.5859C23.1109 14.2922 21.7234 13.5 20.2281 13.5H20.125C17.8469 13.5 16 15.3469 16 17.625ZM31.75 17.625C31.75 18.6609 30.9109 19.5 29.875 19.5H26.5H26.4391L28.0703 16.725C28.4266 16.1203 29.0734 15.75 29.7719 15.75H29.875C30.9109 15.75 31.75 16.5891 31.75 17.625ZM14.5 27V35.25C14.5 36.4922 15.5078 37.5 16.75 37.5H23.5V27H14.5ZM26.5 37.5H33.25C34.4922 37.5 35.5 36.4922 35.5 35.25V27H26.5V37.5Z"
              fill="#FEE0E6"
            />
          </Svg>
        ) : (
          <Svg width={42} height={42} viewBox="0 0 42 42" fill="none">
            <Path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M36.91 21v12.727a3.182 3.182 0 01-3.183 3.182H8.273a3.182 3.182 0 01-3.182-3.182V21A1.59 1.59 0 013.5 19.41v-4.774a3.182 3.182 0 013.182-3.181h5.043a4.773 4.773 0 014.502-6.364c1.591 0 2.991.795 3.866 1.973v-.016L21 8.273l.907-1.225v.016c.875-1.178 2.275-1.973 3.866-1.973a4.773 4.773 0 014.502 6.364h5.043a3.182 3.182 0 013.182 3.181v4.773A1.59 1.59 0 0136.91 21zM8.272 33.727h11.136V21H8.273v12.727zM33.727 21v12.727H22.591V21h11.136zm-17.5-12.727a1.59 1.59 0 100 3.181 1.59 1.59 0 000-3.181zm7.955 1.59a1.59 1.59 0 113.182 0 1.59 1.59 0 01-3.182 0zm-17.5 4.773v3.182h12.727v-3.182H6.682zm15.909 3.182v-3.182h12.727v3.182H22.591z"
              fill="#22212D"
            />
          </Svg>
        )}
      </>
    );
  }
}
