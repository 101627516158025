import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Dimensions} from 'react-native';
import Styles from 'src/components/Styles';
import AccountStore from 'src/stores/AccountStore';
import {FlashList} from '@shopify/flash-list';
import ActionsFactory from 'src/actions/ActionsFactory';
import HistorySectionView from './HistorySectionView';
import Util from 'src/Util';
import moment from 'moment';
import AccountConstants from 'src/constants/AccountConstants';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import AVText from 'src/components/elements/AVText';
import Localized from 'src/constants/AppStrings';
import {NavigationProp} from '@react-navigation/native';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import uuid from 'src/nativeModules/UUID';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import EmptyReceipt from 'src/components/img/EmptyReceipt';

type HistoryComponentProps = {
  histroyfilter?: AccountConstants;
  navigation: NavigationProp<HistoryComponentProps>;
};

const HistoryComponent: React.FC<HistoryComponentProps> = (props) => {
  let started = moment();
  const [page, setPageNumber] = useState(1);
  const [fundingPage, setFundingPageNumber] = useState(1);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [history, setHistory] = useState(
    AccountStore.filteredAndSortedHistory(props.histroyfilter),
  );
  const {height} = Dimensions.get('window');
  useEffect(() => {
    const onFocus = props.navigation.addListener('focus', () => {
      setIsLoading(true); // Set loading to true when starting the data fetch

      FirebaseAnalytic.trackEvent('useEffect', 'HistoryComponent', {
        ...props,
      });

      // make the API call when the component is in focus
      const fetchHistory = async (filter) => {
        try {
          if (filter === AccountConstants.FUNDING_TYPE) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.FUNDING_TYPE,
            );
          } else if (filter === AccountConstants.REDEMPTION_TYPE) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.REDEMPTION_TYPE,
            );
          } else if (filter === AccountConstants.SEND_SNACK) {
            // Create an array of promises
            const promises = [
              ActionsFactory.getAccountActions().loadPurchaseHistory(
                AccountStore.getAccountId(),
                fundingPage,
                AccountConstants.SEND_SNACK,
              ),
              ActionsFactory.getAccountActions().loadPurchaseHistory(
                AccountStore.getAccountId(),
                fundingPage,
                AccountConstants.SEND_SNACK_CANCEL,
              ),
              ActionsFactory.getAccountActions().loadPurchaseHistory(
                AccountStore.getAccountId(),
                fundingPage,
                AccountConstants.SEND_SNACK_REVERSE,
              ),
              ActionsFactory.getAccountActions().loadPurchaseHistory(
                AccountStore.getAccountId(),
                fundingPage,
                AccountConstants.SEND_SNACK_ACCEPT,
              ),
              ActionsFactory.getAccountActions().loadPurchaseHistory(
                AccountStore.getAccountId(),
                fundingPage,
                AccountConstants.SEND_SNACK_EXPIRE,
              ),
            ];

            // Use Promise.all to execute all promises concurrently
            await Promise.all(promises);
          } else if (filter === AccountConstants.SALE_TYPE) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.SALE_TYPE,
            );
          } else if (filter === AccountConstants.MARKET_PURCHASES) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.SALE_TYPE,
              AccountConstants.MARKET_PURCHASES,
            );
          } else if (filter === AccountConstants.MOBILE_PURCHASES) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.SALE_TYPE,
              AccountConstants.MOBILE_PURCHASES,
            );
          } else if (filter === AccountConstants.REFERRAL_TYPE) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.REFERRAL_TYPE,
            );
          } else if (
            filter === AccountConstants.SIGN_UP_REWARDS ||
            filter === AccountConstants.REFERRAL_SIGNUP_TYPE
          ) {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              fundingPage,
              AccountConstants.REFERRAL_SIGNUP_TYPE,
            );
          } else {
            await ActionsFactory.getAccountActions().loadPurchaseHistory(
              AccountStore.getAccountId(),
              page,
            );
          }
        } catch (error) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'HistoryComponent:onRefresh',
            generateErrorMessage(error),
            guid,
          );
          Events.Error.trackEvent(
            'Exception',
            'HistoryComponent:onRefresh',
            generateErrorMessage(error),
            guid,
          );
        }
      };

      fetchHistory(props.histroyfilter);
    });

    AccountStore.addHistoryChangedListener(onChange);

    return () => {
      onFocus();
      AccountStore.removeHistoryChangedListener(onChange);
    };
  }, [props.histroyfilter]);

  /** onEndReached function */
  const loadMore = () => {
    if (isRefreshing) return; // Prevent multiple fetches at once

    FirebaseAnalytic.trackEvent('loadMore', 'HistoryComponent', {
      ...props,
    });
    const allItemsList = AccountStore.filteredAndSortedHistory('');
    const getLastItem = allItemsList[allItemsList.length - 1]?.data;

    let hasMoreItems = false;
    if (getLastItem) {
      hasMoreItems = !getLastItem[getLastItem.length - 1].lastItem;
    }
    if (hasMoreItems && !isRefreshing) {
      const pageNumber =
        props.histroyfilter === AccountConstants.FUNDING_TYPE ||
        props.histroyfilter === AccountConstants.SEND_SNACK ||
        props.histroyfilter === AccountConstants.SALE_TYPE ||
        props.histroyfilter === AccountConstants.MARKET_PURCHASES ||
        props.histroyfilter === AccountConstants.MOBILE_PURCHASES ||
        props.histroyfilter === AccountConstants.REFERRAL_TYPE ||
        props.histroyfilter === AccountConstants.SIGN_UP_REWARDS ||
        props.histroyfilter === AccountConstants.REFERRAL_SIGNUP_TYPE ||
        props.histroyfilter === AccountConstants.REDEMPTION_TYPE
          ? fundingPage
          : page;
      onRefresh(pageNumber + 1);
    }
  };

  /** Pull to refresh function */
  const onRefresh = async (pageNo: number) => {
    started = moment();
    FirebaseAnalytic.trackEvent('onRefresh', 'HistoryComponent', {
      ...props,
    });
    try {
      if (props.histroyfilter === AccountConstants.FUNDING_TYPE) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.FUNDING_TYPE,
        );

        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.REDEMPTION_TYPE) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.REDEMPTION_TYPE,
        );
        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.SEND_SNACK) {
        const accountId = AccountStore.getAccountId();
        const _pageNo = pageNo;

        const promises = [
          ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            _pageNo,
            AccountConstants.SEND_SNACK,
          ),
          ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            _pageNo,
            AccountConstants.SEND_SNACK_CANCEL,
          ),
          ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            _pageNo,
            AccountConstants.SEND_SNACK_REVERSE,
          ),
          ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            _pageNo,
            AccountConstants.SEND_SNACK_ACCEPT,
          ),
          ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            _pageNo,
            AccountConstants.SEND_SNACK_EXPIRE,
          ),
        ];

        // Run all promises in parallel
        await Promise.all(promises);

        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.SALE_TYPE) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.SALE_TYPE,
        );
        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.MARKET_PURCHASES) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.SALE_TYPE,
          AccountConstants.MARKET_PURCHASES,
        );
        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.MOBILE_PURCHASES) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.SALE_TYPE,
          AccountConstants.MOBILE_PURCHASES,
        );
        setFundingPageNumber(pageNo);
      } else if (props.histroyfilter === AccountConstants.REFERRAL_TYPE) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.REFERRAL_TYPE,
        );
        setFundingPageNumber(pageNo);
      } else if (
        props.histroyfilter === AccountConstants.SIGN_UP_REWARDS ||
        props.histroyfilter === AccountConstants.REFERRAL_SIGNUP_TYPE
      ) {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
          AccountConstants.REFERRAL_SIGNUP_TYPE,
        );
        setFundingPageNumber(pageNo);
      } else {
        await ActionsFactory.getAccountActions().loadPurchaseHistory(
          AccountStore.getAccountId(),
          pageNo,
        );
        setPageNumber(pageNo);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'HistoryComponent:onRefresh',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'HistoryComponent:onRefresh',
        generateErrorMessage(error),
        guid,
      );
    } finally {
      setIsRefreshing(false);
    }
  };

  /** Function for updating local state with store changes */
  const onChange = async () => {
    setHistory(AccountStore.filteredAndSortedHistory(props.histroyfilter));
    await Util.delayForUX(started);
    setTimeout(() => {
      setIsRefreshing(false);
      setIsLoading(false);
    }, 4000);
  };
  const getLoadingView = () => {
    return (
      <View style={styles.emptyView}>
        <AVText style={styles.emptyText}>{Localized.Labels.loading}</AVText>
      </View>
    );
  };

  const getEmptyView = () => {
    return (
      <View style={styles.emptyView}>
        <EmptyReceipt />
      </View>
    );
  };
  return (
    <View
      style={[styles.container, Settings.isRevolve() && {top: -9}]}
      testID="historyComponentContainer"
    >
      {isLoading && history?.length === 0 && getLoadingView()}
      {!isLoading && history?.length === 0 && getEmptyView()}
      {history?.length !== 0 && (
        <View style={{flex: 1, height: height}}>
          <FlashList
            testID="historyComponentFlashlist"
            data={history}
            keyExtractor={(item, index) => `${item.title}${index.toString()}`}
            renderItem={({item, index}) => (
              <HistorySectionView
                testId={item.title}
                indexNo={`${item.title}${index.toString()}`}
                title={item.title}
                data={item.data}
              />
            )}
            estimatedItemSize={80}
            onRefresh={() => {
              onRefresh(1);
            }}
            refreshing={isRefreshing}
            onEndReached={loadMore}
            onEndReachedThreshold={0.5}
          />
        </View>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Settings.isRevolveAnd365Pay()
      ? Styles.tabBarBackgroundColor
      : Styles.white,
  },
  item: {
    color: Styles.darkColor,
    flex: 2,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  emptyView: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  emptyText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
  },
  footerLoader: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 10,
  },
});

export default HistoryComponent;
