import React, {useRef, useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  PixelRatio,
  ScrollView,
  Platform,
} from 'react-native';
import Styles from '../Styles';

import Localized from 'src/constants/AppStrings';
import type {MenuOption} from 'src/types/MenuOption';
import Settings from 'src/Settings';
import {TextInput, Menu, Text} from 'react-native-paper';
import AVText from './AVText';
import {useComponentSize} from '../Hooks';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import {getDescriber} from './descriptor/DescriptorType';
import ChevronDown from 'src/components/img/svg/ChevronDown';

const maxWidth = Dimensions.get('screen').width - 30;
const maxHeight = Dimensions.get('screen').height * 0.5;
const {getDropDownMenuStyles, getDropDownMenuListStyle} = getDescriber();

type NBDropdownProps = React.ComponentProps<typeof TextInput> & {
  onSelect: (value: string) => void;
  selectedValue?: string | number;
  options: Array<MenuOption>;
  label: string;
  dropDownHeader?: string;
  defaultLabel?: string;
  flexWidth?: boolean;
  anchorPosition?: string;
  accessibilityState?: string;
  accessibilityRole?: string;
  accessibilityLabel?: string;
  accessibilityValue?: {[key: string]: string};
  onVisibilityChange?: (visible: boolean) => void;
  menuContainerStyle?: {[key: string]: string | number};
};

const NBDropdown: React.FC<NBDropdownProps> = (props) => {
  const [dropdownSize, onLayout] = useComponentSize();
  const [menuOpen, setMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({top: 0, left: 0});
  const buttonRef = useRef(null);
  const onOpen = () => {
    setMenu(true);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(true);
    }
    Platform.OS !== 'web' &&
      buttonRef.current.measure(
        (x, y, buttonWidth, buttonHeight, pageX, pageY) => {
          setMenuPosition({
            top: pageY + buttonHeight - 60,
            left: pageX + buttonWidth - 270,
          });
        },
      );
  };
  const anchorPosition = props.anchorPosition === 'top' ? 'top' : 'bottom';

  const onClose = () => {
    setMenu(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
  };

  const selectedOption = props.options.find(
    (option) => option.value == props.selectedValue,
  );
  const title = selectedOption?.text || props.defaultLabel || '';
  let baseText;
  if (props.flexWidth) {
    baseText = (
      <AVText
        style={[
          styles.flexWidthBase,
          Styles.Style.font500,
          {color: Styles.darkColor},
        ]}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityRole="text"
        aria-label={`${props.accessibilityLabel}, text`}
      >
        {title}
      </AVText>
    );
  } else {
    const id =
      selectedOption &&
      (selectedOption?.id == 'american-express' ||
        selectedOption?.id == 'american express')
        ? 'amex'
        : (selectedOption?.id as string);
    baseText = (
      <AllyTextInput
        pointerEvents="none"
        label={props.label}
        newPaymentOptionsUI={props.dropDownHeader ? true : false}
        placeholder={props.placeholder}
        onChangeText={undefined}
        editable={false}
        value={title}
        accessible={true}
        leftIcon={props.dropDownHeader && selectedOption?.image}
        leftText={props.dropDownHeader && id}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityValue={{text: props.value}}
        accessibilityState={{disabled: true}}
        disabled={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
      />
    );
  }

  const options = props.options.map(
    (
      item: {text: string; value: string; id: string; image: string},
      index: number,
    ) => {
      return (
        <View key={index}>
          <TouchableOpacity
            accessible={true}
            accessibilityRole="menuitem"
            accessibilityLabel={item.text}
            aria-label={item.text}
            role="menuitem"
            style={[
              getDropDownMenuListStyle(dropdownSize, maxWidth, props, index)[
                'optionContainer'
              ],
              getDropDownMenuListStyle(dropdownSize, maxWidth, props, index)[
                'options'
              ],
              props.options.length - 1 !== index &&
                props.dropDownHeader == Localized.Labels.payment && {
                  borderBottomWidth: 1,
                  borderBottomColor: '#9A9A9F',
                  maxWidth: '100%',
                },
              props.dropDownHeader == Localized.Labels.payment &&
                Settings.isNewUI() &&
                Settings.is365Pay() && {
                  paddingLeft: 0,
                  paddingRight: 16,
                  paddingTop: 16,
                  paddingBottom: 16,
                },
              !item.id &&
                item.value === 'payment' && {
                  left: 10,
                },
            ]}
            onPress={() => {
              props.onSelect(`${item.value}`);
              onClose();
            }}
            key={item.value}
          >
            {item.image && (
              <View
                style={[
                  props.dropDownHeader == Localized.Labels.payment &&
                  Settings.isNewUI() &&
                  Settings.is365Pay()
                    ? styles.payitemImageStyle
                    : styles.itemImageStyle,
                  getDropDownMenuListStyle(
                    dropdownSize,
                    maxWidth,
                    props,
                    index,
                  )['optionImageExtraMargins'],

                  item.id === 'AMEX' &&
                    getDropDownMenuListStyle(
                      dropdownSize,
                      maxWidth,
                      props,
                      index,
                    )['amexSpecificMargin'],
                ]}
              >
                {item.image}
              </View>
            )}
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              style={[
                styles.optionText,
                item.value === props.selectedValue && {
                  color: Styles.primaryColor,
                },
                item.value === 'payment' &&
                  getDropDownMenuListStyle(
                    dropdownSize,
                    maxWidth,
                    props,
                    index,
                  )['optionPaymentText'],
                getDropDownMenuListStyle(dropdownSize, maxWidth, props, index)[
                  'optionTextStyling'
                ],
                item.id === 'AMEX' && {left: -5},
                !item.id &&
                  item.value === 'payment' && {
                    left: 14,
                  },
              ]}
            >
              {item.text}
            </Text>
          </TouchableOpacity>
        </View>
      );
    },
  );
  return (
    <View
      accessible={true}
      importantForAccessibility={menuOpen ? 'yes' : 'no-hide-descendants'}
      accessibilityElementsHidden={menuOpen}
      accessibilityRole="menu"
      accessibilityLabel={props.accessibilityLabel}
      accessibilityState={{expanded: menuOpen}}
      role="menu"
      aria-label={props.accessibilityLabel}
      aria-expanded={menuOpen}
      ref={buttonRef}
    >
      {props.dropDownHeader && (
        <Text
          style={[
            styles.flexDropDownTitle,
            {fontWeight: Settings.isRevolve() ? '500' : '400'},
            {color: Styles.darkColor},
            Settings.is365Pay() && {
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontSize: 18,
              fontWeight: '400',
              color: '#111',
            },
          ]}
          accessible={true}
          accessibilityLabel={props.accessibilityLabel}
          accessibilityRole="text"
          aria-label={`${props.accessibilityLabel}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
        >
          {props.dropDownHeader}
        </Text>
      )}
      <Menu
        visible={menuOpen}
        onDismiss={onClose}
        style={[
          getDropDownMenuStyles(props)['style'],
          props.menuContainerStyle,
          Platform.OS !== 'web' && {
            top: menuPosition.top,
            left: menuPosition.left,
          },
        ]}
        contentStyle={[
          getDropDownMenuStyles(props)['contentStyle'],
          props.dropDownHeader == Localized.Labels.payment &&
            Settings.isNewUI() &&
            Settings.is365Pay() && {
              borderColor: '#9A9A9F',
              borderWidth: 1,
              shadowOpacity: 0,
              shadowOffset: {width: 0, height: 0},
            },
        ]}
        anchorPosition={Settings.isRevolve() ? 'top' : anchorPosition}
        anchor={
          <TouchableOpacity
            onLayout={onLayout}
            onPress={onOpen}
            style={[
              {
                color: Styles.darkColor,
                alignContent: 'center',
              },
              props.style,
            ]}
          >
            {baseText}
            <View
              style={[
                props.flexWidth
                  ? styles.flexWidthDropDownIcon
                  : styles.dropDownIcon,
                {bottom: 22},
              ]}
            >
              <ChevronDown />
            </View>
          </TouchableOpacity>
        }
      >
        <ScrollView style={{flex: 1, flexDirection: 'column', maxHeight: 170}}>
          {options}
        </ScrollView>
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  dropDown: {
    maxHeight: maxHeight,
  },
  dropDownIcon: {
    position: 'absolute',
    right: 8,
    bottom: Styles.Spacing.m2 + PixelRatio.getFontScale() * 7,
    margin: Styles.Spacing.m1,
  },
  flexWidthBase: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  flexDropDownTitle: {
    alignSelf: 'flex-start',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.robotoRegular,
    paddingBottom: Styles.Spacing.m1,
  },
  flexWidthContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    textAlignVertical: 'center',
  },
  flexWidthDropDownIcon: {
    margin: Settings.isNewUI() ? Styles.Spacing.m0 : Styles.Spacing.m2,
    alignSelf: 'center',
  },
  option: {
    padding: Styles.Spacing.m2,
    flexDirection: 'row',
    alignItems: 'center',
    width: '65%',
    height: 60,
  },
  optionText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  base: {
    paddingHorizontal: 0,
  },
  itemImageStyle: {
    marginTop: Styles.Spacing.m1,
    alignItems: 'center',
    height: Styles.Fonts.f3,
    marginRight: Styles.Spacing.m3,
    width: 40,
  },
  payitemImageStyle: {
    alignItems: 'center',
    height: Styles.Fonts.f3,
    paddingHorizontal: 16,
    marginTop: -2,
  },
});
export default NBDropdown;
