import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  width?: number;
  height?: number;
  color?: string;

  style?: Record<string, any>;
}
function Card(props: IProps) {
  const {width = 32, height = 32, color = null, style = null} = props;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      {...(props as any)}
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.462 4.947H2.578a.983.983 0 00-.991.971v9.163a.985.985 0 00.991.972h13.884c.552 0 .991-.44.991-.982V5.929a.985.985 0 00-.991-.982zM3.184 6.057h12.668c.245 0 .429.177.465.417v.694H2.72v-.705a.464.464 0 01.464-.406zm12.672 8.886H3.184a.461.461 0 01-.464-.407V10.5h13.6v4.026a.47.47 0 01-.464.417z"
        fill={color}
      />
      <Path fill="#fff" d="M3.85333 12.166H10.65333V12.721333H3.85333z" />
      <Path fill="#fff" d="M3.85333 13.2766H7.25333V13.831933H3.85333z" />
      <Path fill="#fff" d="M12.92 12.166H15.18667V13.832H12.92z" />
    </Svg>
  );
}

export default Card;
