import moment from 'moment';
import * as React from 'react';
import {
  InteractionManager,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import Header from '../../elements/Header';
import HeaderButton from '../../elements/HeaderButton';
import CreditCardInput from '../../elements/funding/CreditCardInput';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import type {CCModel} from 'src/types/CCModel';
import ActionsFactory from 'src/actions/ActionsFactory';
import CreditCardHelper from 'src/services/CreditCardService';
import {CardIOUtilities, CardIOView} from 'src/nativeModules/CardIO';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import {CreditCard} from 'src/models/CreditCard';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import BackSubheader from 'src/components/elements/BackSubheader';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import {SafeAreaView} from 'react-native-safe-area-context';

import {getDescriber} from '../account/descriptor/DescriptorType';
import {getFundingDescriber} from './descriptor/DescriptorType';
import PersistentStore from 'src/services/PersistentStoreService';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';

type CreditCardAddProps = {
  accountId: string;
  username: string;
  startTime: moment.Moment;
  paymentCredentials: PaymentCredentials;
  isLinkingKioskAccount: boolean;
  navigation?: NavigationProp<CreditCardAdd>;
  hasPayrollImportId?: boolean;
};
type CreditCardAddState = {
  addManual: boolean;
  expireYear: string;
  issuer: string;
  cvv: string;
  expireMonth: string;
  creditCard: string;
  redactedCardNumber: string;
  zip: string;
  cardScanned: boolean;
  setCardAsDefault: boolean;
  previousRoute: string | null;
};
type CardIOResult = {
  cardNumber: string;
  expiryMonth: string;
  expiryYear: string;
  cvv: string;
  redactedCardNumber: string;
  cardType: string;
};

class CreditCardAdd extends React.Component<
  CreditCardAddProps,
  CreditCardAddState
> {
  scrollView: ScrollView | null;
  month: AVTextInput | null;
  year: AVTextInput | null;
  cvv: AVTextInput | null;
  zipCode: AVTextInput | null;
  focusField: (field: AVTextInput) => void;

  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: CreditCardAddProps) {
    super(props);
    this.state = {
      addManual: false,
      issuer: '',
      expireYear: '',
      cvv: '',
      expireMonth: '',
      creditCard: '',
      redactedCardNumber: '',
      zip: '',
      cardScanned: false,
      setCardAsDefault: false,
      previousRoute: null,
    };
    this.displayCardAddedError = this.displayCardAddedError.bind(this);
    this.validate = this.validate.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSkipClick = this.handleSkipClick.bind(this);
    this.navigateToMain = this.navigateToMain.bind(this);
    this.navigateToAppTour = this.navigateToAppTour.bind(this);
    this.onAddManuallyPress = this.onAddManuallyPress.bind(this);
    this.scanClick = this.scanClick.bind(this);
    this.didScanCard = this.didScanCard.bind(this);
    this.handleSkipClickOnSetupAccount =
      this.handleSkipClickOnSetupAccount.bind(this);
  }

  componentDidMount() {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});

    FirebaseAnalytic.trackEvent('componentDidMount', 'CreditCardAddScreen', {
      ...this.props,
      navigate: AppRoutes.MyModal,
    });
    InteractionManager.runAfterInteractions(() => {
      if (CardIOUtilities && CardIOUtilities.preload) {
        CardIOUtilities.preload();
      }
    });
  }

  onAddManuallyPress() {
    this.setState({
      addManual: true,
    });
    FirebaseAnalytic.trackEvent(
      'AddCreditCardManually',
      'CreditCardAddScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
  }

  displayCardAddedError(error: string) {
    alertError(error);
  }

  validate(ccModel: CCModel) {
    FirebaseAnalytic.trackEvent('validate', 'CreditCardAddScreen', {
      ...this.props,
      ccModel,
    });

    if (
      !ccModel.CardNumber ||
      !ccModel.ExpirationMonth ||
      !ccModel.ExpirationYear ||
      !ccModel.CvvCode ||
      !ccModel.BillingZip
    ) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  async handleClick() {
    let {expireYear, issuer} = this.state;
    const {cvv, expireMonth} = this.state;
    const cardNumber = this.state.creditCard;
    const {accountId} = this.props;

    FirebaseAnalytic.trackEvent('handleClick', 'CreditCardAddScreen', {
      ...this.props,
      ...this.state,
    });

    if (expireYear && expireYear.length === 2) {
      expireYear = `20${expireYear}`;
    }

    const ccModel = {
      CardNumber: cardNumber,
      ExpirationMonth: expireMonth as any as number,
      ExpirationYear: expireYear as any as number,
      CvvCode: cvv,
      CardNumberMask: this.state.redactedCardNumber,
      BillingZip: this.state.zip,
      AccountId: accountId,
      Issuer: '',
      Token: '',
      AccountBalanceId: '',
      IsDefault: true,
      DateCreated: '',
    };
    const errorMessage = this.validate(ccModel);

    if (errorMessage) {
      alertError(errorMessage);
      CrashlyticsEvents.log(
        'Exception',
        'CreditCardAdd:Validate ccModel',
        errorMessage,
      );
      Events.Error.trackEvent(
        'Exception',
        'CreditCardAdd:Validate ccModel',
        errorMessage,
      );
    } else {
      if (!issuer) {
        issuer = CreditCardHelper.getCardType(cardNumber);
      }

      ccModel.Issuer = issuer;
      this.context.actions.showSpinner();

      try {
        const response = await ActionsFactory.getAccountActions().addCreditCard(
          ccModel,
          this.props.paymentCredentials,
          this.props.hasPayrollImportId,
          ccModel.IsDefault,
        );

        if (response) {
          await ActionsFactory.getAccountActions().updateDefaultToken(
            response.accountId,
            response.accountBalanceId,
            response.balanceTokenId,
          );
          ccModel.Token = response.balanceTokenId;
          ccModel.AccountBalanceId = response.accountBalanceId;
          Events.AccountCreation.trackEvent(
            this.props.startTime,
            '',
            'CREDIT_CARD_ADDED',
          );

          if (Settings.buildType === BuildTypes.pantry) {
            this.navigateToMain();
          } else {
            const creditCard: CreditCard = {
              id: ccModel.Token,
              cardIssuer: ccModel.Issuer,
              name: ccModel.CardNumberMask,
              expirationMonth: ccModel.ExpirationMonth,
              expirationYear: ccModel.ExpirationYear,
              provider: Settings.processors[this.props.paymentCredentials.type],
              providerKey: this.props.paymentCredentials.key,
              accountBalanceId: ccModel.AccountBalanceId,
              isDefault: ccModel.IsDefault,
              dateCreated: ccModel.DateCreated,
            };

            NavActions.replace(AppRoutes.AccountSetupAutoFund, {
              ...this.props,
              creditCard,
            });
          }
        } else {
          this.displayCardAddedError(Localized.Errors.error_adding_credit_card);
        }
      } catch (error) {
        if (error.statusCode === 429) {
          this.displayCardAddedError(Localized.Errors.please_try_again_shortly);
          return;
        }

        this.displayCardAddedError(Localized.Errors.error_adding_credit_card);
        CrashlyticsEvents.log(
          'Exception',
          'CreditCardAdd:HandleClick',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'CreditCardAdd:HandleClick',
          generateErrorMessage(error),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  handleSkipClick() {
    this.navigateToMain();
  }

  navigateToMain() {
    alertSuccess(Localized.Success.create_account_success, () =>
      this.navigateToAppTour(),
    );
  }

  handleSkipClickOnSetupAccount() {
    this.navigateToAppTour();
  }

  async navigateToAppTour() {
    FirebaseAnalytic.trackEvent('navigateToAppTour', 'CreditCardAddScreen', {
      ...this.props,
      ...this.state,
    });

    const payrollData = await PersistentStore.getPayrollImportData();

    if (Platform.OS === 'web') {
      this.context.actions.changeRootRoute(AppRoutes.App);
      NavActions.replace(AppRoutes.MainConsumer);
    } else {
      if (this.props.isLinkingKioskAccount) {
        this.context.actions.changeRootRoute(AppRoutes.App);
      } else if (payrollData?.importId) {
        this.context.actions.changeRootRoute(AppRoutes.App);
      } else {
        this.context.actions.changeRootRoute(AppRoutes.AppProductTour);
      }
    }
  }

  scanClick() {
    this.setState({
      addManual: false,
    });
  }

  didScanCard(result: CardIOResult) {
    this.setState({
      creditCard: result.cardNumber,
      cardScanned: true,
      expireMonth: result.expiryMonth,
      expireYear: result.expiryYear,
      cvv: result.cvv,
      redactedCardNumber: result.redactedCardNumber,
      issuer: result.cardType,
    });
    Events.AccountCreation.trackEvent(
      this.props.startTime,
      '',
      'CREDIT_CARD_SCANNED',
    );
  }

  static focusField(field: AVTextInput | null) {
    if (field) {
      field.focus();
    }
  }

  render() {
    const {maxFontSizeMultipleDescriptor} = getDescriber();
    const {getCreditCardAddStyleDescriptor} = getFundingDescriber();
    const addLaterBtn = (
      <HeaderButton
        type="Add Later"
        label={Localized.Labels.add_later}
        onPress={this.handleSkipClick}
      />
    );
    const instructionText = (
      <AVText
        accessible={true}
        accessibilityRole="text"
        accessibilityLabel={Localized.Labels.enter_credit_card_info}
        aria-label={`${Localized.Labels.enter_credit_card_info}, text`}
      >
        {Localized.Labels.enter_credit_card_info}
      </AVText>
    );

    if (this.state.cardScanned) {
      return (
        <SafeAreaView style={{flex: 1, backgroundColor: Styles.white}}>
          <Header
            title={Localized.Labels.add_credit_card}
            accessibilityLabel={Localized.Labels.add_credit_card}
            accessibilityRole="header"
            aria-label={Localized.Labels.add_credit_card}
            role="heading"
            rightView={addLaterBtn}
          >
            <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
              <ScrollView
                contentContainerStyle={styles.scrollView}
                style={Styles.Style.maxWidthContainer}
                ref={(scrollView) => {
                  this.scrollView = scrollView;
                }}
                automaticallyAdjustContentInsets={false}
              >
                <View
                  style={
                    getCreditCardAddStyleDescriptor() &&
                    getCreditCardAddStyleDescriptor()['inputsContainer']
                  }
                >
                  <CreditCardInput
                    accessibilityLabel={Localized.Labels.card_number}
                    editable={false}
                    issuer={this.state.issuer}
                    value={this.state.redactedCardNumber}
                    label={Localized.Labels.card_number}
                  />
                  <View style={styles.row}>
                    <View style={styles.inputRowLeft}>
                      <AllyTextInput
                        keyboardType="numeric"
                        label={Localized.Labels.expiration_month}
                        value={this.state.expireMonth}
                        accessible={true}
                        accessibilityLabel={Localized.Labels.expiration_month}
                        accessibilityValue={{text: this.state.expireMonth}}
                        aria-label={Localized.Labels.expiration_month}
                        aria-valuetext={this.state.expireMonth}
                        onChangeText={(text) =>
                          this.setState({expireMonth: text})
                        }
                        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
                      />
                    </View>
                    <View style={styles.inputRowRight}>
                      <AllyTextInput
                        keyboardType="numeric"
                        label={Localized.Labels.expiration_year + 'please'}
                        value={this.state.expireYear}
                        accessible={true}
                        accessibilityLabel={Localized.Labels.expiration_year}
                        accessibilityValue={{text: this.state.expireYear}}
                        aria-label={Localized.Labels.expiration_year}
                        aria-valuetext={this.state.expireYear}
                        onChangeText={(text) =>
                          this.setState({expireYear: text})
                        }
                        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
                      />
                    </View>
                  </View>

                  <View style={styles.row}>
                    <View style={styles.inputRowLeft}>
                      <AllyTextInput
                        keyboardType="numeric"
                        label={Localized.Labels.cvv}
                        value={this.state.cvv}
                        accessible={true}
                        accessibilityLabel={Localized.Labels.cvv}
                        accessibilityValue={{text: this.state.cvv}}
                        accessibilityHint="CVV is found on the back of most credit cards"
                        onChangeText={(text) =>
                          this.setState({
                            cvv: text,
                          })
                        }
                        aria-label={Localized.Labels.cvv}
                        aria-valuetext={this.state.cvv}
                        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
                      />
                    </View>

                    <View style={styles.inputRowRight}>
                      <AllyTextInput
                        keyboardType="numeric"
                        label={Localized.Labels.zip_code}
                        value={this.state.zip}
                        accessible={true}
                        accessibilityLabel={Localized.Labels.zip_code}
                        accessibilityValue={{text: this.state.zip}}
                        aria-label={Localized.Labels.zip_code}
                        aria-valuetext={this.state.zip}
                        onChangeText={(text) => this.setState({zip: text})}
                        onSubmitEditing={this.handleClick}
                        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
                      />
                    </View>
                  </View>
                  <View style={styles.defaultStatusView}>
                    <AVText
                      accessible={true}
                      accessibilityLabel={Localized.Labels.set_as_default}
                      aria-label={Localized.Labels.set_as_default}
                      accessibilityRole="text"
                      nativeID="setAsDefault"
                      style={styles.setDefaultLabel}
                    >
                      {Localized.Labels.set_as_default}
                    </AVText>

                    <CustomToggleSwitch
                      style={
                        getCreditCardAddStyleDescriptor() &&
                        getCreditCardAddStyleDescriptor()['defaultSwitch']
                      }
                      isDisabled={true}
                      accessible={true}
                      accessibilityLabelledBy={'setAsDefault'}
                      accessibilityLabel={Localized.Labels.set_as_default}
                      accessibilityState={{
                        checked: true,
                        disabled: false,
                      }}
                      accessibilityRole="switch"
                      role="switch"
                      aria-labelledby="setAsDefault"
                      aria-label={Localized.Labels.set_as_default}
                      aria-checked={true}
                      aria-disabled={false}
                      value={true}
                    />
                  </View>
                  <AVTouchableOpacity
                    onPress={this.handleSkipClick}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.add_later}
                    accessibilityHint="Double tap to add your default payment later"
                    aria-label={Localized.Labels.add_later}
                    accessibilityRole="button"
                    role="button"
                    style={styles.secondaryBtn}
                  >
                    {Localized.Labels.add_later}
                  </AVTouchableOpacity>
                </View>
              </ScrollView>
            </KeyboardAvoidingView>
            <RoundedButton
              buttonType={ButtonType.action}
              onPress={this.handleClick}
              accessible={true}
              accessibilityLabel={Localized.Buttons.next}
              aria-label={Localized.Buttons.next}
              accessibilityRole="button"
              role="button"
              text={Localized.Buttons.next}
              textStyle={styles.nextButtonText}
            />

            <View style={styles.buttonsContainerBottomRight}>
              <RoundedButton
                buttonType={ButtonType.outline}
                onPress={this.handleSkipClickOnSetupAccount}
                containerStyle={{
                  marginHorizontal: Styles.Spacing.m3,
                }}
                accessibilityLabel="Add Later"
                text={Localized.Labels.add_later}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleClick}
                accessibilityLabel="Next"
                text={Localized.Buttons.next}
                textStyle={styles.nextButtonText}
              />
            </View>
          </Header>
        </SafeAreaView>
      );
    } else if (
      !this.state.addManual &&
      Platform.OS === 'ios' &&
      CardIOUtilities.CAN_READ_CARD_WITH_CAMERA
    ) {
      return (
        <SafeAreaView style={{flex: 1, backgroundColor: Styles.white}}>
          <Header
            title={Localized.Labels.add_credit_card}
            accessibilityLabel={Localized.Labels.add_credit_card}
            accessibilityRole="header"
            aria-label={Localized.Labels.add_credit_card}
            role="heading"
            rightView={addLaterBtn}
          >
            <View style={styles.cardView}>
              <View style={styles.cardViewContainer}>
                <View
                  accessible={true}
                  accessibilityLabel="Scan new card"
                  aria-label="Scan new card"
                >
                  <CardIOView
                    style={styles.cardView}
                    scanExpiry
                    hideCardIOLogo
                    detectionMode={CardIOView.cardImageAndNumber}
                    didScanCard={this.didScanCard}
                    scanInstructions=""
                    allowFreelyRotatingCardGuide={false}
                    guideColor={Styles.white}
                  />
                </View>

                <View style={styles.absoluteTextContainer}>
                  <AVText
                    style={styles.instructionText}
                    accessible={true}
                    accessibilityLabel={Localized.Labels.position_card_in_frame}
                    accessibilityRole="text"
                    aria-label={`${Localized.Labels.position_card_in_frame}, text`}
                  >
                    {Localized.Labels.position_card_in_frame}
                  </AVText>
                </View>
              </View>
              <RoundedButton
                accessible={true}
                accessibilityLabel="Add Card Manually"
                accessibilityHint="Double tap to enter your card details manually"
                accessibilityRole="button"
                aria-label="Add Card Manually"
                role="button"
                buttonType={ButtonType.outline}
                containerStyle={styles.tetriaryBtn}
                onPress={this.onAddManuallyPress}
                text={Localized.Buttons.manual_card_entry}
              />
            </View>
          </Header>
        </SafeAreaView>
      );
    }

    return (
      <SafeAreaView style={{flex: 1, backgroundColor: Styles.white}}>
        <BackSubheader
          accessibilityLabel={Localized.Labels.add_credit_card}
          aria-label={Localized.Labels.add_credit_card}
          previousRoute={this.state.previousRoute}
          title={Localized.Labels.add_credit_card}
        >
          <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
            <ScrollView
              style={Styles.Style.maxWidthContainer}
              ref={(scrollView) => {
                this.scrollView = scrollView;
              }}
              keyboardDismissMode="interactive"
              automaticallyAdjustContentInsets={false}
              keyboardShouldPersistTaps="handled"
            >
              <View
                style={
                  getCreditCardAddStyleDescriptor() &&
                  getCreditCardAddStyleDescriptor()['inputsContainer']
                }
              >
                <CreditCardInput
                  accessibilityLabel={Localized.Labels.card_number}
                  onChangeText={(text) =>
                    this.setState({
                      creditCard: text,
                    })
                  }
                  onSubmitEditing={() => this.focusField(this.month)}
                  label={Localized.Labels.card_number}
                  value={this.state.creditCard}
                />
                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.expiration_month}
                      aria-label={Localized.Labels.expiration_month}
                      aria-valuetext={this.state.expireMonth}
                      value={this.state.expireMonth}
                      accessible={true}
                      accessibilityLabel={Localized.Labels.expiration_month}
                      accessibilityValue={{text: this.state.expireMonth}}
                      onChangeText={(text) =>
                        this.setState({expireMonth: text})
                      }
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>
                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.expiration_year}
                      accessibilityLabel={Localized.Labels.expiration_year}
                      accessibilityValue={{text: this.state.expireYear}}
                      aria-label={Localized.Labels.expiration_year}
                      aria-valuetext={this.state.expireYear}
                      value={this.state.expireYear}
                      accessible={true}
                      onChangeText={(text) => this.setState({expireYear: text})}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>
                </View>
                <View style={styles.row}>
                  <View style={styles.inputRowLeft}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.cvv}
                      value={this.state.cvv}
                      accessibilityLabel={Localized.Labels.cvv}
                      accessibilityHint="CVV is found on the back of most credit cards"
                      accessibilityValue={{text: this.state.cvv}}
                      aria-label={Localized.Labels.cvv}
                      aria-valuetext={this.state.cvv}
                      onChangeText={(text) =>
                        this.setState({
                          cvv: text,
                        })
                      }
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>
                  <View style={styles.inputRowRight}>
                    <AllyTextInput
                      keyboardType="numeric"
                      label={Localized.Labels.zip_code}
                      value={this.state.zip}
                      accessibilityLabel={Localized.Labels.zip_code}
                      accessibilityValue={{text: this.state.zip}}
                      aria-label={Localized.Labels.zip_code}
                      aria-valuetext={this.state.zip}
                      onChangeText={(text) =>
                        this.setState({
                          zip: text,
                        })
                      }
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                    />
                  </View>
                </View>
                <View style={styles.defaultStatusView}>
                  <AVText
                    accessibilityLabel={Localized.Labels.set_as_default}
                    accessibilityRole="text"
                    style={styles.setDefaultLabel}
                    accessible={true}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
                    aria-label={`${Localized.Labels.set_as_default} label, text`}
                  >
                    {Localized.Labels.set_as_default}
                  </AVText>

                  <CustomToggleSwitch
                    value={this.state.setCardAsDefault}
                    onValueChange={(value) =>
                      this.setState({
                        setCardAsDefault: value,
                      })
                    }
                    containerStyle={
                      getCreditCardAddStyleDescriptor() &&
                      getCreditCardAddStyleDescriptor()['defaultSwitch']
                    }
                    accessible={true}
                    accessibilityLabel={Localized.Labels.set_as_default}
                    accessibilityRole="switch"
                    accessibilityState={{
                      checked: this.state.setCardAsDefault,
                    }}
                    role="switch"
                    aria-label={Localized.Labels.set_as_default}
                    aria-checked={this.state.setCardAsDefault}
                    nativeID="Set as Default"
                  />
                </View>
                {Platform.OS === 'ios' &&
                CardIOUtilities.CAN_READ_CARD_WITH_CAMERA ? (
                  <RoundedButton
                    buttonType={ButtonType.outline}
                    onPress={this.scanClick}
                    accessible={true}
                    accessibilityLabel="Scan card"
                    accessibilityHint="Double tap to scan your card with your device's camera"
                    accessibilityRole="button"
                    aria-label={Localized.Buttons.scan_card}
                    role="button"
                    containerStyle={styles.scanBtn}
                    text={Localized.Buttons.scan_card}
                  />
                ) : null}
              </View>
            </ScrollView>
            <View style={styles.buttonsContainerBottomRight}>
              <RoundedButton
                buttonType={ButtonType.outline}
                onPress={this.handleSkipClickOnSetupAccount}
                containerStyle={{
                  marginHorizontal: Styles.Spacing.m3,
                }}
                accessible={true}
                accessibilityLabel={Localized.Labels.add_later}
                accessibilityRole="button"
                role="button"
                aria-label={Localized.Labels.add_later}
                text={Localized.Labels.add_later}
              />
              <RoundedButton
                buttonType={ButtonType.normal}
                onPress={this.handleClick}
                accessible={true}
                accessibilityLabel={Localized.Buttons.next}
                accessibilityRole="button"
                role="button"
                aria-label={Localized.Buttons.next}
                text={Localized.Buttons.next}
                textStyle={styles.nextButtonText}
              />
            </View>
          </KeyboardAvoidingView>
        </BackSubheader>
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  cardView: {
    flex: 1,
  },
  cardViewContainer: {
    flex: 1,
  },
  content: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  inputRowLeft: {
    flex: 0.5,
    paddingRight: Styles.Spacing.m1,
  },
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  inputsContainer: {
    flexDirection: 'column',
    paddingHorizontal: Styles.Spacing.m2,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  secondaryBtn: {
    marginTop: Styles.Spacing.m2,
  },
  tetriaryBtn: {
    alignSelf: 'center',
    bottom: Styles.Spacing.m3,
    position: 'absolute',
  },
  buttonsContainerBottomRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: Styles.Spacing.m3,
    marginEnd: Styles.Spacing.m3,
  },
  defaultStatusView: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },

  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  scanBtn: {
    marginTop: Styles.Spacing.m2,
  },
  instructionText: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
    marginTop: Styles.Spacing.m2,
  },
  absoluteTextContainer: {
    backgroundColor: Styles.white,
    bottom: 0,
    height: 300,
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: 2,
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<CreditCardAddProps>()(
  CreditCardAdd,
);
