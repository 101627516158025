import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from '../../Styles';
import {Platform} from 'react-native';

const backgroundColor = Platform.select({
  web: 'white',
  default: Styles.loginBackground,
});

export function renderPointsToNextRewardLbl() {
  return <></>;
}

export function renderYourBalanceRewardLbl() {
  return <></>;
}

export function linkExistingAccountStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    title: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f2,
    },
    container: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
  };
}

export function actionLinkText(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    actionLinkText: {
      color: '#003349',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textDecorationLine: 'underline',
    },
  };
}

export function getBodyTextStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    bodyText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 18,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontStyle: 'normal',
    },
  };
}

export function getWelcomeStatusBarStyleDescriptor(): string {
  return Styles.loginBackground;
}

export function getWelcomeViewBckGrndStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    view: {
      flex: 1,
    },
    overlay: {},
  };
}

export function baseAccountContainerDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      paddingBottom: Platform.OS === 'android' ? 10 : 0,
      backgroundColor: Styles.white,
    },
  };
}

export function getWelcomeOverLayImage() {
  return null;
}

export function getFocusAwareStatusBar() {
  return null;
}

export function getOrTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    orText: {
      fontSize: Styles.Fonts.f1,
      textTransform: 'uppercase',
    },
    solidLine: {
      backgroundColor: '#999',
    },
  };
}

export function getLineContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    lineContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: Styles.Spacing.m1,
    },
  };
}

export function getVersionTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    versionText: {
      color: Styles.Colors.Canteen.darkGreen,
      marginLeft: Styles.Spacing.m3,
    },
  };
}
export function getLogoContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    logoContainer: {
      alignSelf: 'center',
      height: Styles.Heights.h3,
      justifyContent: 'center',
      paddingTop: Styles.Spacing.m1,
      marginTop: -80,
      marginBottom: -20,
      flex: 1,
    },
  };
}

export function getWelcomeButtonStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    topButton: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
  };
}

export function getContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    container: {
      marginTop: 120,
    },
  };
}

export function locationCodebgColor(): string {
  return '#066DFF';
}

export function getOnBoardingButton(
  buttonText: string,
  handleClick: () => void,
  disabled?: boolean,
): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.normal}
      accessible={true}
      accessibilityLabel={buttonText}
      aria-label={buttonText}
      onPress={handleClick}
      disabled={disabled}
      text={buttonText}
      textStyle={{paddingHorizontal: Styles.Spacing.m2}}
      color="#003349"
      containerStyle={{
        display: 'flex',
        width: 315,
        padding: 14,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Styles.white,
      }}
    />
  );
}

export default {
  linkExistingAccountStyleDescriptor,
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  baseAccountContainerDescriptor,
  getOrTextDescriptor,
  getVersionTextDescriptor,
  renderPointsToNextRewardLbl,
  getLogoContainer,
  getContainer,
  getWelcomeOverLayImage,
  getFocusAwareStatusBar,
  getLineContainer,
  getWelcomeButtonStyles,
  renderYourBalanceRewardLbl,
  locationCodebgColor,
  getOnBoardingButton,
  actionLinkText,
  getBodyTextStyles,
};
