import {Key} from 'react';
import {View, PixelRatio} from 'react-native';
import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency.web';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import PersonLight from 'src/components/img/svg/PersonLight';
import Reward from 'src/components/img/svg/Reward';
import SnackGiftIcon from 'src/components/img/svg/SnackGiftIcon';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getBalanceStripeIcon(): JSX.Element {
  return <PersonLight size={Styles.Heights.h3} />;
}

export function balanceAmountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontWeight: '700',
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
  };
}

export function getFundButtonForBalanceScreen(fundBtnCallback): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.outline}
      containerStyle={getBalanceItemStyleDescriptor()['button']}
      textStyle={getBalanceItemStyleDescriptor()['fundButtonText']}
      accessible={true}
      accessibilityRole="button"
      accessibilityLabel={Localized.Labels.fund_account}
      aria-label={Localized.Labels.fund_account}
      role="button"
      onPress={fundBtnCallback}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      text={Localized.Labels.fund}
      color={balanceItemRoundedBtnColorDescriptor()}
    />
  );
}

export function balanceTypeStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
  };
}
export function getBalanceItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      justifyContent: 'flex-start',
      borderBottomColor: Styles.tabBarBackgroundColor,
      flex: 1,
      borderBottomWidth: 2,
      paddingHorizontal: Styles.Spacing.m3,
      backgroundColor: Styles.white,
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      paddingVertical: Styles.Spacing.m3,
    },
    button: {
      top: Styles.Spacing.m3,
      right: Styles.Spacing.m3,
      borderWidth: 1,
      position: 'absolute',
    },
    fundButtonText: {
      paddingHorizontal: Styles.Spacing.m2,
    },
    balanceContainer: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      paddingHorizontal: Styles.Spacing.m1,
    },
  };
}

export function SaleDetailRowStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.tabBarBackgroundColor,
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m2,
    justifyContent: 'space-between',
    minHeight: 24,
    width: '90%',
  };
}
export function SaleDetailSupLblMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm3;
}

export function SaleDetailNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.darkColor,
    fontWeight: '400',
    flex: 1,
    flexWrap: 'wrap',
  };
}
export function payLocationAddr() {
  return null;
}

export function SaleDetailAmtStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f7,
    color: Styles.darkColor,
    fontWeight: '400',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    right: -32,
  };
}
export function getDashColor(): {
  [index: string]: {[index: string]: string};
} {
  return {
    dashStyle: {
      dashColor: '#999',
    },
  };
}
export function SaleDetailModNameStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    maxWidth: '70%',
  };
}
export function getBalanceRollOverIcon(): JSX.Element {
  return <Reward size={Styles.Heights.h3} color={Styles.darkColor} />;
}
export function fundingdetailDate(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
  };
}

export function SaleDetailModMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm3;
}
export function PurchaseDetailFormateedDateStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f8 + 2,
    color: Styles.darkColor,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  };
}
export function paydescription() {
  return null;
}
export function balanceItemRoundedBtnColorDescriptor(): string {
  return Styles.darkGreenRevolve;
}
export function fundingdetailTrans(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  };
}

export function payDteDisplay(styles) {
  return styles.payDteDisplay;
}
export function getSaleAmountTextUI(
  modPrice: number,
  saleCurrency: string,
  modifierStyle: {
    [index: string]: {[index: string]: number};
  },
  modId: Key,
) {
  return (
    <AVText key={modId} style={modifierStyle}>
      +
      <AVFormattedCurrency
        style={modifierStyle}
        value={modPrice}
        accessibilityLabel={`+$${modPrice}`}
        aria-label={`+$${modPrice}`}
        currency={saleCurrency}
      />
    </AVText>
  );
}
export function fundingDetailAccount() {
  return null;
}

export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    orderStatus: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.darkColor,
    },
    remainingBalance: {},
    orderStatusValue: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.darkColor,
    },
    location: {
      marginTop: Styles.Spacing.m3,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    pickupDate: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
      marginTop: Styles.Spacing.m2,
    },
    pickupTime: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
    },
    instructions: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f0,
      fontWeight: '600',
    },
    points: {
      color: Styles.primaryColor,
      fontSize: Styles.Fonts.f1,
    },
    modifierAmount: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
    },
    sectionMarginLeft: {
      marginLeft: -4,
    },
    summaryTopMargin: {
      marginTop: null,
    },
    buttonSpacing: {
      marginBottom: Styles.Spacing.m1,
      marginStart: Styles.Spacing.m2,
    },
    sectionMarginLeftWithZero: {
      marginLeft: 0,
    },
    sectionMarginRightWithZero: {
      marginRight: -5,
    },
    summaryExtra: {
      marginBottom: Styles.Spacing.m1,
    },
    dashStyleBorder: {
      borderRadius: 100,
      height: PixelRatio.roundToNearestPixel(2),
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      overflow: 'hidden',
      width: '100%',
    },
    shareIconColor: {
      color: Styles.white,
    },
    totalLabel: {
      color: Styles.darkColor,
      fontSize: 24,
      fontWeight: '700',
      marginBottom: Styles.Spacing.m1,
      marginTop: -1 * Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    rowReverse: {
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
    },
  };
}
export function paystatus() {
  return null;
}
export function payBalanceType(styles) {
  return styles.paybalanceTypeDisplay;
}
export function fundingDetailContainer() {
  return Styles.Style.summarySection;
}

export function getDashedStyleDescriptor(): {
  [index: string]: {[index: string]: number};
} {
  return {
    dashStyle: {
      dashThickness: PixelRatio.roundToNearestPixel(2),
      dashLength: PixelRatio.roundToNearestPixel(2),
    },
  };
}

export function getActionBtns(
  handleDone: () => void,
  shareReceipt: () => void,
) {
  return (
    <View style={[Styles.Style.row, getStyleDescriptor()['rowReverse']]}>
      <RoundedButton
        buttonType={ButtonType.normal}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        accessible={true}
        accessibilityLabel={Localized.Buttons.done}
        accessibilityRole="button"
        aria-label={Localized.Buttons.done}
        role="button"
        onPress={handleDone}
        containerStyle={[
          Styles.Style.actionButton,
          getStyleDescriptor()['buttonSpacing'],
        ]}
        textStyle={Styles.Style.actionButtonText}
        text={Localized.Buttons.done}
      />
      <RoundedButton
        testID="openSystemShareButton"
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        buttonType={ButtonType.normal}
        accessible={true}
        accessibilityLabel={Localized.Labels.share}
        accessibilityRole="button"
        aria-label={Localized.Labels.share}
        role="button"
        onPress={shareReceipt}
        containerStyle={[
          Styles.Style.actionButton,
          getStyleDescriptor()['buttonSpacing'],
        ]}
        textStyle={Styles.Style.actionButtonText}
        text={Localized.Labels.share}
        icon={
          <FontAwesome5Pro
            name={'share-alt'}
            color={Styles.white}
            size={Styles.Fonts.f1}
            style={{
              marginEnd: Styles.Spacing.m2,
            }}
            solid
          />
        }
      />
    </View>
  );
}

export function giftIconSnack() {
  return <SnackGiftIcon />;
}
export function fundingDetailTotal(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f3,
    marginTop: -1 * Styles.Spacing.m1,
    color: Styles.black,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
  };
}
export function payPrefixTxt() {
  return '';
}
export function paySnackRow() {
  return null;
}

export function historyEmailReceiptBtn(
  handleEmailReceipt,
  styles,
  detailID?: string,
  isUSACheck?: string,
  isHistory?: boolean,
) {
  return (
    isUSACheck === 'USA' &&
    !isHistory && (
      <RoundedButton
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        accessibilityLabel={Localized.Buttons.email_receipts}
        aria-label={Localized.Buttons.email_receipts}
        accessibilityRole="button"
        role="button"
        buttonType={ButtonType.action}
        onPress={() => {
          handleEmailReceipt(detailID);
        }}
        text={Localized.Buttons.email_receipts}
      />
    )
  );
}
export function leftViewStyle() {
  return null;
}
export function PurchaseDetailDateFormatStyleDescriptor(): string {
  return 'MMMM D, YYYY h:mm A';
}

export function paySnackContainer() {
  return null;
}

export function PurchaseDetailIdStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1 - 2,
    marginTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
  };
}

export function PurchaseDetailSubtotalStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f1 + 2,
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.darkColor,
    marginRight: Styles.Spacing.m3,
    fontWeight: '400',
  };
}
export function payDashedLine() {
  return '#999';
}

export function PurchaseDetailDepositStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontSize: Styles.Fonts.f1 + 2,
    fontFamily: Styles.FontFamily.figtreeRegular,
    marginRight: Styles.Spacing.m3,
    color: Styles.darkColor,
    fontWeight: '400',
    marginBottom: Styles.Spacing.m1,
  };
}

export function PurchaseDetailtaxStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '400',
    marginRight: Styles.Spacing.m3,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1 + 2,
  };
}

export function PurchaseDetailDiscountStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkGreenRevolve,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f1 + 2,
    fontWeight: '400',
  };
}

export function fundingdetailAccountFndgTxt() {
  return {
    fontSize: Styles.Fonts.f1,
  };
}

export function rightViewStyle() {
  return null;
}
export function PurchaseHistorySubtotalStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f1 + 2,
    marginBottom: Styles.Spacing.m1,
    fontWeight: '400',
  };
}
export function payOrderStatus() {
  return null;
}

export function payOrderNumber() {
  return null;
}

export function fundingHeaderTitle() {
  return Localized.Labels.funding_summary;
}
export default {
  getStyleDescriptor,
  getDashedStyleDescriptor,
  getDashColor,
  getActionBtns,
  fundingdetailTrans,
  payOrderStatus,
  fundingDetailContainer,
  getSaleAmountTextUI,
  getBalanceStripeIcon,
  paySnackContainer,
  getBalanceRollOverIcon,
  getFundButtonForBalanceScreen,
  leftViewStyle,
  fundingHeaderTitle,
  balanceItemRoundedBtnColorDescriptor,
  balanceAmountStyleDescriptor,
  paySnackRow,
  PurchaseDetailtaxStyleDescriptor,
  fundingDetailAccount,
  PurchaseDetailDiscountStyleDescriptor,
  PurchaseDetailDateFormatStyleDescriptor,
  PurchaseDetailIdStyleDescriptor,
  SaleDetailSupLblMaxFontSizeMultipleDescriptor,
  SaleDetailNameStyleDescriptor,
  SaleDetailAmtStyleDescriptor,
  SaleDetailModNameStyleDescriptor,
  PurchaseDetailFormateedDateStyleDescriptor,
  fundingdetailDate,
  rightViewStyle,
  PurchaseDetailSubtotalStyleDescriptor,
  PurchaseDetailDepositStyleDescriptor,
  balanceTypeStyleDescriptor,
  payOrderNumber,
  getBalanceItemStyleDescriptor,
  SaleDetailModMaxFontSizeMultipleDescriptor,
  SaleDetailRowStyleDescriptor,
  paystatus,
  fundingdetailAccountFndgTxt,
  payPrefixTxt,
  payLocationAddr,
  fundingDetailTotal,
  payDteDisplay,
  payDashedLine,
  giftIconSnack,
  payBalanceType,
  PurchaseHistorySubtotalStyleDescriptor,
  historyEmailReceiptBtn,
  paydescription,
};
