import RevolveDescriptor from './blockedUser-module-revolve';
import RedesignDescriptor from './blockedUser-module-redesign';
import ConnectDescriptor from './blockedUser-module-connect';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: ConnectDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
