import React from 'react';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import {StyleProp, ViewStyle} from 'react-native';
import NBRDropdown from 'src/components/elements/NBRDropdown';
type LocaleDropdownProps = {
  selectedValue: string;
  onSelect: (value: string) => void;
  onVisibilityChange: (visible: boolean) => void;
  style?: StyleProp<ViewStyle>;
};

const LocaleDropdown = (props: LocaleDropdownProps) => {
  const options = Settings.getLocales().map((locale) => ({
    value: locale,
    text: locale,
  }));
  return (
    <NBRDropdown
      label={Localized.Labels.system_language}
      accessibilityLabel={Localized.Labels.system_language}
      accessibilityRole="menu"
      accessibilityValue={{text: props.selectedValue}}
      aria-label={Localized.Labels.system_language}
      role="menu"
      aria-valuetext={props.selectedValue}
      style={props.style}
      selectedValue={props.selectedValue}
      onSelect={props.onSelect}
      onVisibilityChange={props.onVisibilityChange}
      options={options}
    />
  );
};

export default LocaleDropdown;
