import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
interface IProps {
  color?: string;
  width?: number;
  height?: number;
}
const LightBulb = ({color = '#2C6CD3', height = 24, width = 24}: IProps) => (
  <Svg height={height} width={width} fill="none">
    <Path
      fillRule="evenodd"
      d="m13.8 19.429-3.225.851a.39.39 0 1 0 .2.755l3.224-.852a.39.39 0 0 0-.199-.754ZM12.844 20.886l-1.312.346a.39.39 0 1 0 .2.755l1.31-.346a.39.39 0 0 0-.198-.755ZM14.153 18.305h.114c0-1.918 1.226-3.001 2.066-4.41a4.84 4.84 0 1 0-8.902-2.499l.003.079.002.046.007.108v.01a4.809 4.809 0 0 0 .662 2.093c.84 1.49 2.16 2.582 2.16 4.572l1.998.004h.007l1.22-.002-2.915.77a.39.39 0 1 0 .2.754l3.224-.851a.39.39 0 0 0 .154-.674ZM12.264 5.711c.233 0 .422-.157.422-.352V2.352c0-.195-.19-.352-.422-.352-.233 0-.422.157-.422.352v3.007c0 .195.189.352.422.352ZM21.176 10.842h-3.008c-.194 0-.352.189-.352.422 0 .233.158.421.352.421h3.008c.194 0 .351-.188.351-.421 0-.233-.157-.422-.351-.422ZM6.711 11.264c0-.223-.157-.403-.352-.403H3.352c-.195 0-.352.18-.352.403 0 .222.157.402.352.402h3.007c.195 0 .352-.18.352-.402ZM16.737 7.387l2.127-2.127c.137-.137.115-.382-.05-.547-.165-.165-.41-.187-.547-.05L16.14 6.79c-.137.138-.115.383.05.547.165.165.41.187.547.05ZM7.804 15.154 5.677 17.28c-.137.137-.121.376.036.533.157.157.396.174.534.036l2.126-2.127c.138-.137.122-.376-.036-.533-.157-.157-.396-.173-.533-.036ZM16.737 15.16c-.137-.138-.382-.115-.547.05-.165.164-.187.409-.05.546l2.127 2.127c.137.137.382.115.547-.05.165-.164.187-.41.05-.546l-2.127-2.127ZM7.804 7.393c.137.137.376.12.533-.036.158-.158.174-.396.036-.534L6.247 4.696c-.138-.137-.377-.12-.534.037-.157.157-.173.395-.036.533l2.127 2.127Z"
      clipRule="evenodd"
      fill={color}
    />
  </Svg>
);
export default LightBulb;
