import React from 'react';
import {View, StyleSheet, Text, PixelRatio} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import Localized from 'src/constants/AppStrings';
import Styles from '../../../Styles';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import AVText from 'src/components/elements/AVText';
import {PreparationMethodValues} from 'src/types/PreparationMethods';

type PreparationMethodsNewProps = {
  onMethodSelected: (method: PreparationMethodValues) => void;
  selectedMethod: PreparationMethodValues;
  togoAvailable: boolean;
  dineInAvailable: boolean;
  lockerAvailable: boolean;
};
export default (props: PreparationMethodsNewProps) => {
  const prepMethod =
    props.selectedMethod === 'T'
      ? Localized.Labels.to_go
      : Localized.Labels.dine_in;

  const renderButton = (
    text: string,
    disabled: boolean,
    method: PreparationMethodValues,
  ) => {
    let updatedText = text;
    if (text === prepMethod) {
      updatedText += ' (selected)';
    }
    return (
      <AVTouchableOpacity
        accessible={true}
        accessibilityRole="button"
        accessibilityLabel={updatedText}
        accessibilityHint={`You have selected '${text}' as the preparation method for your order`}
        aria-label={updatedText}
        role="button"
        style={[styles.btnContainer, disabled && styles.disabledBtn]}
        onPress={() => {
          props.onMethodSelected(method);
        }}
      >
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
          style={[styles.text, disabled && styles.disabledText]}
        >
          {text}
        </Text>
      </AVTouchableOpacity>
    );
  };

  let content;

  if (props.lockerAvailable) {
    const text = Localized.Labels.locker_only;
    content = (
      <View style={styles.container}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
          accessible={true}
          accessibilityLabel={Localized.Labels.locker_only}
          aria-label={Localized.Labels.locker_only}
          style={styles.methodText}
        >
          {text}
        </AVText>
      </View>
    );
  } else if (props.togoAvailable && props.dineInAvailable) {
    content = (
      <View style={styles.container}>
        {renderButton(
          Localized.Labels.to_go_redesign_new,
          props.selectedMethod !== PreparationMethodValues.ToGo,
          PreparationMethodValues.ToGo,
        )}
        <View style={{width: Styles.Spacing.m3}}></View>
        {renderButton(
          Localized.Labels.dine_in_caps,
          props.selectedMethod !== PreparationMethodValues.DineIn,
          PreparationMethodValues.DineIn,
        )}
      </View>
    );
  } else if (props.togoAvailable || props.dineInAvailable) {
    let text = Localized.Labels.to_go_only;

    if (props.dineInAvailable) {
      text = Localized.Labels.dine_in_only;
    }

    content = (
      <View style={styles.container}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          accessible={true}
          accessibilityLabel={text}
          aria-label={text}
          style={styles.methodText}
        >
          {text}
        </AVText>
      </View>
    );
  }

  if (content) {
    return (
      <View style={styles.preparationContainer}>
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          accessible={true}
          accessibilityLabel={Localized.Labels.preparation_method}
          aria-label={Localized.Labels.preparation_method}
          style={styles.preparationLabel}
        >
          {Localized.Labels.preparation_method}
        </Text>
        {content}
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(6)}
          dashThickness={1}
          dashColor={Styles.Colors.PayNew.neutralHuesBase09}
          style={[styles.dashStyle, styles.bottomDashedLine]}
        />
      </View>
    );
  }

  return null;
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: Styles.Spacing.m2,
  },
  btnContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#DCE8FD',
    minHeight: 42,
    paddingVertical: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m4,
    borderColor: Styles.Colors.PayNew.primary01,
    borderWidth: 2,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m4),
    overflow: 'hidden',
  },
  disabledBtn: {
    backgroundColor: Styles.white,
    borderColor: Styles.Colors.PayNew.primary01,
    borderWidth: 2,
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m4),
  },
  text: {
    color: Styles.black,
    fontWeight: 'bold',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 16,
  },
  disabledText: {
    color: Styles.Colors.PayNew.primary01,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  methodText: {
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  dashStyle: {
    height: PixelRatio.roundToNearestPixel(2),
    overflow: 'hidden',
    width: '100%',
  },
  bottomDashedLine: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  preparationContainer: {
    alignSelf: 'stretch',
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  preparationLabel: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.darkColor,
  },
});
