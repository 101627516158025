import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import {useGlobalize} from 'react-native-globalize';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from '../AVText';
import Settings from 'src/Settings';

type DiscountSectionProps = {
  discount: number;
  currency: string;
  accountType: string;
};

const DiscountSection = (props: DiscountSectionProps) => {
  const {formatCurrency} = useGlobalize();
  const discountMessage = Localized.Labels.formatString(
    Localized.Labels.discount_message,
    formatCurrency(props.discount, props.currency)?.replace(
      props.currency.substring(0, 2),
      '',
    ),
    props.accountType,
  );
  return (
    <View style={styles.container}>
      <Image
        accessible={true}
        accessibilityLabel="Piggy bank"
        accessibilityRole="image"
        role="img"
        aria-label="Piggy bank"
        style={styles.piggy}
        resizeMode="contain"
        source={require('../../img/piggy.gif')}
      />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        accessible={true}
        accessibilityLabel={discountMessage.toString()}
        accessibilityRole="text"
        aria-label={`${discountMessage.toString()}, text`}
        style={styles.text}
      >
        {discountMessage}
      </AVText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  piggy: {
    height: Styles.Heights.h5,
    width: Styles.Heights.h4,
  },
  text: {
    color: Styles.positiveColor,
    fontSize: Settings.isRevolve() ? 18 : Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
    flex: 1,
    marginLeft: Styles.Spacing.m3,
    marginTop: 0,
    textAlign: 'center',
  },
});

export default DiscountSection;
