import React from 'react';
import {View, StyleSheet} from 'react-native';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';

type EmailInputProps = {
  email: string;
  mailError?: string;
  onChangeEmail: (email: string) => void;
  onBlur: () => void;
  label?: string;
  isSnack?: boolean;
};

const EmailInput: React.FC<EmailInputProps> = ({
  email,
  mailError,
  onChangeEmail,
  onBlur,
  label,
  isSnack,
}) => {
  return (
    <View style={styles.container}>
      <AllyTextInput
        label={label}
        onChangeText={onChangeEmail}
        accessible={true}
        accessibilityLabel="Email"
        aria-label="Email"
        value={email}
        autoCapitalize="none"
        keyboardType="email-address"
        onBlur={onBlur}
        returnKeyType="next"
        maxLength={75}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
        textStyle={styles.inputTextStyles}
        isSnack={isSnack}
        hasError={
          Settings.isRevolveAnd365Pay() && mailError ? true : false
        } /**Needed for SOS-56916 */
      />
      {mailError && (
        <AVText
          style={[
            styles.errorLabel,
            Settings.isRevolveAnd365Pay() && {
              fontWeight: '400',
              fontFamily: Settings.is365Pay()
                ? Styles.FontFamily.aeonikRegular
                : Styles.FontFamily.figtreeRegular,
            },
          ]}
        >
          {mailError}
        </AVText>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 15,
  },
  errorLabel: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
  },
  inputTextStyles: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '500',
    color: Styles.black,
  },
});

export default EmailInput;
