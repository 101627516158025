import React, {useState, useCallback, useRef} from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import Styles from '../../Styles';
import type {MenuCategoryType, MenuProductType} from 'src/types/Menu';
import {DNA} from 'src/types/dnaTypes';
import AVText from 'src/components/elements/AVText';
import MenuItemList from 'src/components/elements/orderAhead/MenuListItem';
import {FlashList} from '@shopify/flash-list';

type SubMenuProps = {
  menu: MenuCategoryType;
  dnaConfiguration?: DNA;
  onProductPress: (product: MenuProductType) => void;
};
export default function NewSubMenu({
  menu,
  dnaConfiguration,
  onProductPress,
}: SubMenuProps): JSX.Element {
  const lineCounts = useRef({});
  const [extraPadding, setExtraPadding] = useState({});

  const handleUpdateLineCounts = useCallback((index, lineCount) => {
    if (lineCounts.current[index] !== lineCount) {
      lineCounts.current[index] = lineCount;
      calculatePadding(lineCounts.current);
    }
  }, []);

  const calculatePadding = (counts) => {
    const padding = {};
    const lineItemHeight = Styles.Fonts.sectionHeader + 4;

    Object.keys(counts).forEach((key) => {
      const index = parseInt(key, 10);
      const rowIndex = Math.floor(index / 2);
      const leftIndex = rowIndex * 2;
      const rightIndex = leftIndex + 1;

      const leftLines = counts[leftIndex] || 0;
      const rightLines = counts[rightIndex] || 0;

      if (leftLines === 0 || rightLines === 0) {
        return;
      }
      const maxLines = Math.max(leftLines, rightLines);

      if (leftLines < maxLines) {
        padding[leftIndex] = (maxLines - leftLines) * lineItemHeight;
      }
      if (rightLines < maxLines) {
        padding[rightIndex] = (maxLines - rightLines) * lineItemHeight;
      }
    });

    setExtraPadding(padding);
  };

  const getNumColumns = (): number => {
    if (Platform.OS == 'web') {
      return 3;
    } else {
      return 2;
    }
  };

  const renderItem = ({item, index}) => {
    return (
      <View style={{flex: 1}}>
        <MenuItemList
          key={index}
          dnaConfiguration={dnaConfiguration}
          item={item}
          name={item?.name}
          price={item?.price}
          onPress={() => onProductPress(item)}
          imageUrl={item?.smallimageurl ?? null}
          index={index}
          isProductCat={true}
          extraPadding={extraPadding[index]}
          updateLineCounts={handleUpdateLineCounts}
        />
      </View>
    );
  };

  return (
    <View style={styles.container} accessible>
      {menu?.products.length > 0 && (
        <>
          <View
            accessible
            accessibilityLabel={`submenu-${menu?.longname}`}
            style={styles.submenuLabelView}
          >
            <AVText
              style={styles.subMenuLabel}
              accessible
              accessibilityLabel={`${menu?.longname}`}
              aria-label={`${menu?.longname}, text`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            >
              {menu?.longname}
            </AVText>
          </View>
          <FlashList
            data={menu?.products ?? []}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${item.id || item.name}-${index}`}
            numColumns={getNumColumns()}
            estimatedItemSize={72}
            extraData={extraPadding}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  subMenuLabel: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.sectionHeader,
    color: Styles.Colors.PayNew.black01,
    fontWeight: '700',
  },
  submenuLabelView: {
    marginBottom: Styles.Spacing.m3,
  },
});
