import * as React from 'react';
import Svg, {Circle} from 'react-native-svg';

function UnReadIcon(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <Circle cx={12} cy={12} r={12} fill="#7FBC03" />
    </Svg>
  );
}

export default UnReadIcon;
