import AccountScreenRevolveDescriptor from './account-revolve';
import AccountScreenRedesignDescriptor from './account-redesign';
import AccountScreenCanteenDescriptor from './account-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: AccountScreenRevolveDescriptor,
  [BuildTypes.canteen]: AccountScreenCanteenDescriptor,
  [BuildTypes.default]: AccountScreenRedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
