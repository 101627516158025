import React, {useEffect} from 'react';
import {Dimensions, StyleSheet, Text, View} from 'react-native';
import Styles from '../Styles';
import Animated, {
  Easing,
  StyleProps,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import LinearGradient from 'react-native-linear-gradient';
import SpinnerInSVG from './SpinnerInSVG';
import SpinnerOutSVG from './SpinnerOutSVG';

type RedirectLoadingScreenProps = {
  signInMessage?: string;
};

const {width} = Dimensions.get('window');
const LOADING_LOGO_OUT = width / 3;
const LOADING_LOGO_IN = width / 3 - 32;
const SPRING_OUT_TIME = 4000;

const SpinnerOutSVGAnimation =
  Animated.createAnimatedComponent<StyleProps>(SpinnerOutSVG);
const SpinnerInSVGAnimation =
  Animated.createAnimatedComponent<StyleProps>(SpinnerInSVG);

const RedirectLoadingScreen: React.FC<RedirectLoadingScreenProps> = (
  props: RedirectLoadingScreenProps,
) => {
  const rotationOut = useSharedValue(0);
  const rotationIn = useSharedValue(0);

  useEffect(() => {
    rotationOut.value = withRepeat(
      withTiming(360, {duration: SPRING_OUT_TIME, easing: Easing.linear}),
      -1,
      false,
    );

    rotationIn.value = withRepeat(
      withTiming(-360, {
        duration: SPRING_OUT_TIME,
        easing: Easing.linear,
      }),
      -1,
      false,
    );
  }, []);

  const spinnerOutStyle = useAnimatedStyle(() => {
    return {
      transform: [{rotate: `${rotationOut.value}deg`}],
    };
  });
  const spinnerInStyle = useAnimatedStyle(() => {
    return {
      transform: [{rotate: `${rotationIn.value}deg`}],
    };
  });

  return (
    <LinearGradient
      start={{
        x: 0.0,
        y: 0.0,
      }}
      end={{
        x: 1.0,
        y: 1.0,
      }}
      colors={['#FEE0E6', '#E6ECFF']}
      style={styles.container}
    >
      <View style={styles.logoView}>
        <View style={styles.absoluteView}>
          <SpinnerOutSVGAnimation
            style={spinnerOutStyle}
            size={LOADING_LOGO_OUT}
          />
        </View>

        <View style={styles.absoluteView}>
          <SpinnerInSVGAnimation
            style={spinnerInStyle}
            size={LOADING_LOGO_IN}
          />
        </View>

        <Text style={styles.text365}>365</Text>
      </View>
      <Text style={styles.text}>{props?.signInMessage}</Text>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  text365: {
    fontSize: 28,
    fontWeight: 'bold',
    color: Styles.Colors.PayNew.primary01,
  },
  absoluteView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoView: {
    width: LOADING_LOGO_OUT,
    height: LOADING_LOGO_OUT,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontWeight: 'bold',
    fontSize: 32,
    width: '60%',
    textAlign: 'center',
    marginTop: Styles.Spacing.m3,
    color: Styles.Colors.PayNew.black01,
  },
});

export default RedirectLoadingScreen;
