import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function RevolveCartItemClose(props) {
  return (
    <Svg width={38} height={38} viewBox="0 0 38 38" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.144 7.803c-6.152-6.182-16.129-6.182-22.288 0-6.16 6.18-6.152 16.206 0 22.395 6.152 6.18 16.129 6.18 22.288 0 6.159-6.181 6.151-16.214 0-22.395zM26.273 25.41l-.894.898-6.394-6.41-6.364 6.38-.894-.899L18.091 19l-6.364-6.379.894-.898 6.364 6.379 6.394-6.41.894.899L19.879 19l6.394 6.409z"
        fill="#9A9A9F"
      />
    </Svg>
  );
}

export default RevolveCartItemClose;
