import React from 'react';
import {PixelRatio, ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import AVText from '../../../elements/AVText';
import BaseScreen from '../../BaseScreen';
import ScreenContext from '../../../ScreenContext';
import Util from 'src/Util';
import Styles from '../../../Styles';
import AccountStore from 'src/stores/AccountStore';
import DashedLine from 'react-native-dashed-line';
import AVFormattedCurrency from '../../../elements/AVFormattedCurrency';
import type {ReferralRewardSummary} from 'src/types/Referral';
import Events from 'src/logging/Events';
import type {HistoryItem} from 'src/types/TransactionDetail';
import LoadingScreen from '../../LoadingScreen';
import Localized from 'src/constants/AppStrings';
import ReferralActions from 'src/actions/ReferralActions';
import {alertError} from '../../../helpers/AlertHelper';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import Settings from 'src/Settings';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import NavActions from 'src/actions/NavActions';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import AppRoutes from 'src/AppRoutes';
import uuid from 'src/nativeModules/UUID';
import ActionsFactory from 'src/actions/ActionsFactory';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {SuccessDialog} from 'src/components/elements/SuccessDialog';

type ReferralSummaryScreenProps = WithGlobalizeProps & {
  historyItem: HistoryItem;
};
type ReferralSummaryScreenState = {
  summary: ReferralRewardSummary;
  showModal?: boolean;
};

class ReferralSummaryScreen extends React.Component<
  ReferralSummaryScreenProps,
  ReferralSummaryScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.renderLabel = this.renderLabel.bind(this);
    this.renderUser = this.renderUser.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.fetchReferralSummaryDetails =
      this.fetchReferralSummaryDetails.bind(this);
    this.handleEmailReferralReceipt =
      this.handleEmailReferralReceipt.bind(this);
    this.state = {
      summary: null,
      showModal: false,
    };
  }

  componentDidMount() {
    this.fetchReferralSummaryDetails();
  }

  async handleEmailReferralReceipt(isSender?: boolean) {
    let response;
    const label = isSender ? 'referral' : 'signup';
    try {
      response = await ActionsFactory.getAccountActions().emailReferralReceipt(
        AccountStore.getEmail(),
        this.state.summary.receiverName,
        this.state.summary?.amount.toString(),
        this.state.summary.locationName,
        this.state.summary.dateCreated,
        this.props.historyItem.currency ?? AccountStore.getCurrency(),
        this.state.summary.transactionDate,
        this.state.summary.transactionId,
        label,
      );
      if (response.status === 'ok') {
        this.setState({
          showModal: true,
        });
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'ReferralSummary:handleEmailReferralReceipt',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralSummary:handleEmailReferralReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  async fetchReferralSummaryDetails() {
    try {
      const summary = await ReferralActions.fetchReferralSummaryV2(
        this.props.historyItem.ReferralId,
        this.props.historyItem.Id,
        this.props.historyItem.TransDate,
      );

      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralSummary',
        JSON.stringify({transactionId: this.props.historyItem.Id}),
        JSON.stringify(summary),
      );
      if (summary?.referralId) {
        this.setState({
          summary,
        });
      } else {
        NavActions.pop();
        if (!Settings.isRevolve()) {
          NavActions.navigate(AppRoutes.ServerErrorDialog, {
            errorTitle: Localized.Labels.error_getting_receipt,
            errorDesc: Localized.Labels.error_server,
            popToRoute: AppRoutes.History,
          });
        } else {
          alertError(
            Localized.Errors.error +
              '\n' +
              Localized.Errors.please_try_again_shortly,
          );
        }
      }
    } catch (e) {
      const errMsg = e.message ? e.message : e.toString();
      CrashlyticsEvents.log(
        'Exception',
        'ReferralSummaryScreen:fetchReferralSummaryDetails',
        errMsg,
      );
      Events.Error.trackEvent(
        'Exception',
        'ReferralSummaryScreen:fetchReferralSummaryDetails',
        errMsg,
      );
      alertError(errMsg);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralSummary',
        JSON.stringify({transactionId: this.props.historyItem.Id}),
        JSON.stringify(e),
      );
    }
  }

  renderLabel(isSender: boolean) {
    const label = isSender
      ? Localized.Labels.referral_reward
      : Localized.Labels.signup_reward;
    return (
      <AVText
        style={[
          styles.referral,
          Settings.isCanteen() || Settings.is365Pay()
            ? {
                fontFamily: Styles.FontFamily.robotoRegular,
                fontSize: 22,
                fontWeight: '700',
                color: '#111',
              }
            : null,
        ]}
      >
        {label}
      </AVText>
    );
  }

  getTitle(isSender: boolean) {
    return isSender
      ? Localized.Labels.referral_reward
      : Localized.Labels.signup_reward;
  }

  renderUser(isSender) {
    const {summary} = this.state;
    const canteenSignUp = Localized.Labels.new_signup;
    //Need to remove name string check after API fix
    const {label, value} = isSender
      ? {
          label:
            Settings.isCanteen() || Settings.is365Pay()
              ? canteenSignUp
              : Localized.Labels.new_sign_up,
          value:
            summary?.receiverName === null ||
            summary?.receiverName === 'null null'
              ? Localized.Labels.name_not_available
              : summary?.receiverName,
        }
      : {
          label: Localized.Labels.referrer_name,
          value:
            summary?.senderName === null || summary?.senderName === 'null null'
              ? Localized.Labels.name_not_available
              : summary?.senderName,
        };
    return (
      <AVText
        style={[
          styles.user,
          Settings.isCanteen() || Settings.is365Pay()
            ? {
                fontFamily: Styles.FontFamily.robotoRegular,
                fontSize: 18,
                fontWeight: '400',
                color: '#111',
              }
            : null,
        ]}
      >
        {Localized.Labels.formatString(label, value)}
      </AVText>
    );
  }

  render() {
    const {summary} = this.state;

    if (!summary) {
      return <LoadingScreen />;
    }

    const isSender = summary?.sender === AccountStore.getAccountId();
    return (
      <BaseScreen
        title={
          Settings.buildType !== 'revolve'
            ? this.getTitle(isSender)
            : 'Reward Summary'
        }
      >
        <View style={styles.purchaseContent}>
          <ScrollView contentContainerStyle={styles.historyContainer}>
            <AVText style={[styles.date, getDescriber().referralDate()]}>
              {Util.formatDate(summary?.transactionDate, 'LLL')}
            </AVText>
            <AVText
              style={[styles.location, getDescriber().referrallocationName()]}
            >
              {summary?.locationName ?? Localized.Labels.loc_not_available}
            </AVText>
            <AVText
              style={[
                styles.transactionId,
                getDescriber().referralTransactionId(),
              ]}
            >
              {summary?.transactionId ?? ''}
            </AVText>
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(1)}
              dashLength={PixelRatio.roundToNearestPixel(4)}
              dashThickness={PixelRatio.roundToNearestPixel(2)}
              dashColor={Settings.isRevolve() ? '#999' : '#CFCFCF'}
              style={styles.dashStyle}
            />
            {this.renderLabel(isSender)}
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(1)}
              dashLength={PixelRatio.roundToNearestPixel(4)}
              dashThickness={PixelRatio.roundToNearestPixel(2)}
              dashColor={Settings.isRevolve() ? '#999' : '#CFCFCF'}
              style={styles.dashStyle}
            />
            {this.renderUser(isSender)}
            <View
              style={[
                Styles.Style.summarySection,
                styles.summary,
                !Settings.isRevolve() && {
                  paddingVertical: 50,
                },
              ]}
            >
              <View style={[Styles.Style.labelColumn, styles.leftView]}>
                <AVText
                  style={[
                    styles.boldLabel,
                    getDescriber().referralSummaryTotal(),
                  ]}
                >
                  {Localized.Labels.total}
                </AVText>
              </View>
              <View style={[styles.rightView]}>
                <AVFormattedCurrency
                  style={[
                    styles.boldLabel,
                    getDescriber().referralSummaryTotal(),
                  ]}
                  value={summary?.amount || 0}
                  accessibilityLabel="total"
                  currency={
                    this.props.historyItem.currency ??
                    AccountStore.getCurrency()
                  }
                />
              </View>
            </View>
            <View style={{paddingTop: 22}}>
              <RoundedButton
                buttonType={ButtonType.action}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                accessible={true}
                accessibilityLabel={getDescriber().checkEmailreceiptBtnTxt()}
                accessibilityRole="button"
                role="button"
                aria-label={getDescriber().checkEmailreceiptBtnTxt()}
                onPress={() => this.handleEmailReferralReceipt(isSender)}
                text={getDescriber().checkEmailreceiptBtnTxt()}
                color={getDescriber().checkRoundedBtnColor()}
                containerStyle={{right: 10}}
              />
            </View>
          </ScrollView>
        </View>

        <View>
          {this.state.showModal && (
            <SuccessDialog
              title={getDescriber().checkEmailSuccessMsg()}
              desc="Desc"
            ></SuccessDialog>
          )}
        </View>
      </BaseScreen>
    );
  }
}

const styles = StyleSheet.create({
  date: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingBottom: Styles.Spacing.m2,
  },
  location: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  purchaseContent: {
    flex: 1,
  },
  historyContainer: {
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  transactionId: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  referral: {
    fontSize: Styles.Fonts.f2,
  },
  user: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    fontWeight: 'bold',
  },
  summary: {
    marginBottom: Styles.Spacing.m1,
  },
  summaryLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m1,
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});
export default withForwardedNavigationParams<ReferralSummaryScreenProps>()(
  withGlobalize(ReferralSummaryScreen),
);
