import Localized from 'src/constants/AppStrings';
import {
  DietaryHealthGood,
  DietaryHealthWarning,
  DietaryHealthAlert,
} from 'src/components/img/svg/DietaryHealthGrades';

export const getCalorieIndicator = (parsedCalorieValue, dnaConfig) => {
  const {levelGreenMax, levelYellowMax} = dnaConfig ?? {};

  if (parsedCalorieValue <= levelGreenMax) {
    return {
      IconComponent: DietaryHealthGood,
      label: Localized.Labels.good,
      hint: `Calories are between 0 - ${levelGreenMax}`,
    };
  } else if (parsedCalorieValue <= levelYellowMax) {
    return {
      IconComponent: DietaryHealthWarning,
      label: Localized.Labels.warning,
      hint: `Calories are between ${levelGreenMax} - ${levelYellowMax}`,
    };
  } else {
    return {
      IconComponent: DietaryHealthAlert,
      label: Localized.Labels.alert,
      hint: `Calories are greater than ${levelYellowMax}`,
    };
  }
};
