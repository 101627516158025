import * as React from 'react';
import {PixelRatio, StyleSheet, View, ScrollView} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import Util from 'src/Util';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {HistoryItem} from 'src/types/TransactionDetail';
import AVText from '../AVText';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';

type RedemptionDetailProps = WithLocalizedProps & {
  detail: HistoryItem;
};

const RedemptionDetail = (props: RedemptionDetailProps) => {
  return (
    <View style={styles.container}>
      <ScrollView style={styles.content}>
        <AVText
          accessible={true}
          accessibilityLabel={`${
            props.strings.Labels.transaction_date
          } $${Util.formatDate(props.detail.TransDate, 'LLL')}`}
          aria-label={`${
            props.strings.Labels.transaction_date
          } $${Util.formatDate(props.detail.TransDate, 'LLL')}`}
          style={styles.date}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        >
          {Util.formatDate(props.detail.TransDate, 'LLL')}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={`${
            props.strings.Labels.location
          } $${Util.formatDate(props.detail.TransDate, 'LLL')}`}
          aria-label={`${props.strings.Labels.location} $${Util.formatDate(
            props.detail.TransDate,
            'LLL',
          )}`}
          style={styles.location}
        >
          {props.detail.Location}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={props.strings.Labels.transaction_id}
          aria-label={props.strings.Labels.transaction_id}
          style={styles.trans}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm9}
        >
          {props.detail.Id}
        </AVText>
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={1}
          dashColor="#999"
          style={styles.dashStyle}
        />

        <View accessible={true} style={[styles.row, styles.fundingRow]}>
          <AVText
            accessibilityLabel={props.strings.Labels.loyalty_credit}
            aria-label={props.strings.Labels.loyalty_credit}
            style={[styles.largeLabel, styles.textLabel]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {props.strings.Labels.loyalty_credit}
          </AVText>
          <AVFormattedCurrency
            style={styles.largeLabel}
            value={props.detail.Amount}
            accessibilityLabel={`$${props.detail.Amount}`}
            aria-label={`$${props.detail.Amount}`}
            currency={props.detail.currency ?? AccountStore.getCurrency()}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          />
        </View>

        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={1}
          dashColor="#999"
          style={styles.dashStyle}
        />

        <View accessible={true} style={Styles.Style.summarySection}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              accessibilityLabel={props.strings.Labels.total}
              aria-label={props.strings.Labels.total}
              style={[styles.total]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            >
              {props.strings.Labels.total}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[styles.total]}
              value={props.detail.Amount}
              accessibilityLabel={`$${props.detail.Amount}`}
              aria-label={`$${props.detail.Amount}`}
              currency={props.detail.currency ?? AccountStore.getCurrency()}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  date: {
    marginBottom: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f0,
  },
  content: {
    flex: 1,
    marginBottom: Styles.Spacing.m5,
    paddingLeft: Styles.Spacing.m2,
    paddingRight: Styles.Spacing.m2,
    paddingVertical: 32,
  },
  dashStyle: {
    marginBottom: Styles.Spacing.m2,
    borderRadius: 100,
    overflow: 'hidden',
    height: PixelRatio.roundToNearestPixel(2),
    width: '100%',
    marginTop: Styles.Spacing.m2,
  },

  fundingRow: {},

  location: {
    fontSize: Styles.Fonts.f1,
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  textLabel: {
    flex: 1,
  },

  trans: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
  },
  largeLabel: {
    fontSize: Styles.Fonts.f1,
  },
  total: {
    marginTop: -1 * Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m1,
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
  },
});
export default RedemptionDetail;
