import * as _ from 'lodash';
import BuildTypes from 'src/constants/BuildTypeConstants';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import type {
  DispatchAction,
  UnattachedMachine,
} from 'src/dispatchers/DispatchTypes';
import MachineConstants from 'src/constants/MachineConstants';
import AccountStore from './AccountStore';
import PersistentStore from 'src/services/PersistentStoreService';
import {EventEmitter} from 'events';
import Settings from 'src/Settings';
import LocationFeatures from 'src/models/LocationFeatures';
import MachineFeatures from 'src/models/MachineFeatures';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import TSFInsideDevice from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import DemoTSFInsideDevice from 'src/services/bluetooth/tsfInside/DemoTSFInsideDevice';
import type {VendingMachine} from 'src/services/bluetooth/VendingMachineInterface';
import type {MachineType} from 'src/types/MachineType';
import type {MachineModel} from 'src/types/MachineModel';
import type {MachineSetupType, MDBOption} from 'src/types/MachineSetupType';
const MAX_LOG_SIZE = 500;
const BASE_ADDRESS_SETTING = 1;

class MachineStore {
  _emitter: EventEmitter = new EventEmitter();
  dispatchToken: number;
  machines: Array<VendingMachine> = [];
  devices: Array<VendingMachine> = [];
  beaconMarkets: Array<MachineType> = [];
  coffeeMachines: Array<MachineType>;
  vmcLogArray: Array<string> = [];
  vmcLogText = '';
  unattachedMachines: Array<UnattachedMachine> = [];
  errorLoadingMachines = false;
  errorLoadingDevices = false;
  errorLoadingUnattachedMachines = false;
  dexFilesSuccessfullyUploaded = false;
  machineSettings: any = null;
  rebootReason = '';
  setupData: MachineSetupType | null = null;
  wifiOn = false;
  wifiNetwork = '';
  wifiPassword = '';
  highestPrice = 0;
  mdbAvailable = true;
  serviceMode = false;
  machineModels: Array<MachineModel> = [];
  cashlessDeviceSetting: MDBOption | null = null;
  dexDataReceivedMessage = '';
  dexFailedMessage = '';
  connectionError = '';
  checkVersionPayload = {};

  constructor() {
    this.coffeeMachines = [];
    this.dispatchToken = AppDispatcher.register(
      async (action: DispatchAction) => {
        if (action.actionType === MachineConstants.CLEAR_MACHINES) {
          this.clearMachines();
        }

        if (action.actionType === MachineConstants.MACHINE_ADDED) {
          this.addMachineToList(action.data.machine);
        }

        if (action.actionType === MachineConstants.DEVICE_UPDATED) {
          if (action.data.machine.Name) {
            const indexToRemove = this.devices.findIndex(
              (device) => device.deviceId === action.data.machine.DeviceId,
            );
            let existingDevice: VendingMachine | null;

            if (indexToRemove > -1) {
              existingDevice = this.devices[indexToRemove];
              this.devices.splice(indexToRemove, 1);
            } else {
              existingDevice = null;
            }

            if (existingDevice) {
              const newMachine = new TSFInsideDevice(
                existingDevice.device || undefined,
              );
              newMachine.setDeviceName(action.data.machine.Name || '');
              newMachine.setColor(action.data.machine.Color);
              newMachine.setLetter(action.data.machine.Letter);
              newMachine.setImageUrl(action.data.machine.ImageUrl);
              newMachine.setHidePlanogram(
                action.data.machine.DisableNutritionalInfo,
              );
              this.addMachineToList(newMachine);
            }
          }
        }

        if (action.actionType === MachineConstants.MACHINE_IMAGE_UPDATED) {
          const indexToUpdate = this.machines.findIndex(
            (machine) => machine.deviceId === action.data.deviceId,
          );

          if (indexToUpdate >= 0) {
            const existingMachine: VendingMachine =
              this.machines[indexToUpdate];

            let newMachine;
            if (existingMachine.type === BluetoothDeviceTypes.tsfInside) {
              newMachine = new TSFInsideDevice(
                existingMachine.device || undefined,
              );
            } else if (
              existingMachine.type === BluetoothDeviceTypes.vendorsExchange
            ) {
              newMachine = new VendorsExchangeDevice(existingMachine.device!);
            }
            if (newMachine) {
              newMachine.setDeviceName(existingMachine.deviceName);
              newMachine.setColor(existingMachine.color);
              newMachine.setLetter(existingMachine.letter);
              newMachine.setImageUrl(action.data.imageUrl);
              newMachine.setHidePlanogram(existingMachine.hidePlanogram);
              this.machines[indexToUpdate] = newMachine;
              this.emitChange();
            }
          }
        }

        if (action.actionType === MachineConstants.MACHINE_UPDATED) {
          const indexToUpdate = this.machines.findIndex(
            (machine) => machine.deviceId === action.data.machine.DeviceId,
          );
          let letter = action.data.machine.Letter;

          if (action.data.machine.Number) {
            letter += action.data.machine.Number;
          }

          if (indexToUpdate >= 0) {
            if (action.data.machine.Name) {
              const existingMachine: VendingMachine =
                this.machines[indexToUpdate];
              const newMachines = this.machines.slice();
              let newMachine: VendingMachine;

              if (
                existingMachine.type === BluetoothDeviceTypes.vendorsExchange
              ) {
                newMachine = new VendorsExchangeDevice(existingMachine.device!);
              } else {
                newMachine = new TSFInsideDevice(existingMachine.device!);
              }

              if (newMachine) {
                newMachine.setDeviceName(action.data.machine.Name);
                newMachine.setColor(action.data.machine.Color);
                newMachine.setLetter(letter);
                newMachine.setImageUrl(action.data.machine.ImageUrl);
                newMachine.setHidePlanogram(
                  action.data.machine.DisableNutritionalInfo,
                );
                newMachines[indexToUpdate] = newMachine;
              }
              newMachine.address = action.data?.address?.address;
              newMachine.city = action.data?.address?.city;
              newMachine.state = action.data?.address?.state;
              newMachine.zip = action.data?.address?.zip;
              newMachine.country = action.data?.address?.country;

              this.machines = newMachines;
            } else {
              const existingMachine: VendingMachine =
                this.machines[indexToUpdate];
              this.machines.splice(indexToUpdate, 1);
              this.devices.push(existingMachine);
              PersistentStore.removeMachineInfo(action.data.machine.DeviceId);
            }

            this.emitChange();
          }

          PersistentStore.setMachineInfo(
            action.data.machine.DeviceId,
            action.data.machine,
          );
        }

        if (action.actionType === MachineConstants.DEVICES_LOADED_SUCCESS) {
          this.devices = action.data.devices;
          this.errorLoadingDevices = false;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.DEVICES_LOADED_FAIL) {
          this.errorLoadingDevices = true;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.CLEAR_DEVICES) {
          this.clearDevices();
        }

        if (action.actionType === MachineConstants.CLEAR_MARKETS) {
          this.clearMarkets();
        }

        if (action.actionType === MachineConstants.DEVICE_ADDED) {
          this.devices.push(action.data.device);
          this.errorLoadingDevices = false;
          this.emitChange();
        }

        if (
          action.actionType ===
          MachineConstants.LOAD_UNATTACHED_MACHINES_SUCCESS
        ) {
          this.unattachedMachines = action.data.response;
          this.errorLoadingUnattachedMachines = false;
          this.emitChange();
        }

        if (
          action.actionType === MachineConstants.LOAD_UNATTACHED_MACHINES_ERROR
        ) {
          this.errorLoadingUnattachedMachines = true;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.MACHINE_ATTACHED) {
          AccountStore.addAuthorizedDevice(action.data.deviceId);
          this.emitMachineAttached();
        }

        if (action.actionType === MachineConstants.DISCONNECT_FROM_MACHINE) {
          this.resetMachineData();
          this.emitChange();
        }

        if (action.actionType === MachineConstants.MACHINE_CONNECTED) {
          this.resetMachineData();
          this.emitChange();
        }

        if (action.actionType === MachineConstants.SERVICE_COMPLETE) {
          this.emitServiceComplete();
        }

        if (action.actionType === MachineConstants.SERVICE_COMPLETE_ERROR) {
          this.emitServiceCompleteError();
        }

        if (action.actionType === MachineConstants.DEX_DATA_RECEIVED) {
          const message = action.data.dexDataReceived;
          this.dexDataReceivedMessage = message;
          this.emitDEXDataReceived();
        }

        if (action.actionType === MachineConstants.DEX_FAILED) {
          const message = action.data.dexFailed;
          this.dexFailedMessage = message;
          this.emitDEXFailed();
        }

        if (action.actionType === MachineConstants.UPLOAD_DEX_FILES) {
          this.dexFilesSuccessfullyUploaded = true;

          if (action.data.files.length > 0) {
            this.emitDexFileUploaded();
          }
        }

        if (action.actionType === MachineConstants.UPLOAD_DEX_FILES_ERROR) {
          this.dexFilesSuccessfullyUploaded = false;
          this.emitDexFileUploaded();
        }

        if (action.actionType === MachineConstants.SETTINGS_UPDATED_FAILED) {
          this.emitChange();
        }

        if (action.actionType === MachineConstants.ERROR_CONNECTING) {
          this.connectionError = action.data.error;
          this.mdbAvailable = this.connectionError !== 'notavailable';
          this.emitConnectionError();
        }

        if (action.actionType === MachineConstants.MACHINE_REBOOTING) {
          this.rebootReason = action.data.reason;
          this.emitReboot();
        }

        if (
          action.actionType === MachineConstants.UPLOAD_SETUP ||
          action.actionType === MachineConstants.UPLOAD_SETUP_ERROR
        ) {
          this.setupData = action.data.setupData;

          if (action.data.response) {
            this.wifiOn = action.data.response.MachineConfig.IsWifiConnection;
            this.wifiNetwork = action.data.response.MachineConfig.WiFiNetwork;
            this.wifiPassword = action.data.response.MachineConfig.WiFiPassword;

            if (action.data.response.MachineConfig.MDBWriteOptions) {
              this.cashlessDeviceSetting =
                action.data.response.MachineConfig.MDBWriteOptions.find(
                  (setting: MDBOption) =>
                    setting.SettingNumber === BASE_ADDRESS_SETTING,
                );
            }

            if (this.setupData) {
              this.setupData.ConfigData.LastDateDEXPulled =
                action.data.response.MachineConfig.LastDateDEXPulled;
              this.setupData.ConfigData.LastDateDEXServiced =
                action.data.response.MachineConfig.LastDateDEXServiced;
              this.setupData.ConfigData.IsActiveDex =
                action.data.response.MachineConfig.IsActiveDex;
              this.setupData.ConfigData.MDBWriteOptions =
                action.data.response.MachineConfig.MDBWriteOptions;
            }

            PersistentStore.setIsActiveDex(
              action.data.deviceId,
              action.data.response.MachineConfig.IsActiveDex,
            );
          } else {
            const isActiveDex = await PersistentStore.getIsActiveDex(
              action.data.deviceId,
            );

            if (
              isActiveDex !== null &&
              this.setupData &&
              this.setupData.ConfigData
            ) {
              this.setupData.ConfigData.IsActiveDex = isActiveDex;
            }
          }

          this.emitChange();
        }

        if (action.actionType === MachineConstants.WIFI_UPDATED) {
          this.wifiOn = action.data.settings.WiFiOn;
          this.wifiNetwork = action.data.settings.WiFiNetwork;
          this.wifiPassword = action.data.settings.WiFiPassword;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.DEVICE_UNATTACHED) {
          AccountStore.removeAuthorizedDevice(action.data.deviceId);
          this.emitDeviceUnattached();
        }

        if (action.actionType === MachineConstants.BEACON_UPDATED) {
          const existingIndex = this.beaconMarkets.findIndex(
            (market) => market.broadcastid === action.data.marketId,
          );

          if (existingIndex >= 0) {
            this.beaconMarkets[existingIndex] = {
              ...this.beaconMarkets[existingIndex],
              distance: action.data.distance,
            };
            this.emitChange();
          }
        }

        if (action.actionType === MachineConstants.MARKET_SCANNED) {
          this.addMarketToList(action.data.market, action.data.market.beacon);
        }

        if (action.actionType === MachineConstants.CASHLESS_DEVICE_UPDATED) {
          this.cashlessDeviceSetting = {
            SettingNumber: BASE_ADDRESS_SETTING,
            SettingValue: action.data.hexValue,
          };

          if (this.setupData && this.setupData.ConfigData) {
            const mdbOptions = this.setupData.ConfigData.MDBWriteOptions.filter(
              (option) => option.SettingNumber !== BASE_ADDRESS_SETTING,
            );
            mdbOptions.push({
              SettingNumber: BASE_ADDRESS_SETTING,
              SettingValue: action.data.hexValue,
            });

            // Have to check this again to make flow happy
            if (this.setupData && this.setupData.ConfigData) {
              this.setupData.ConfigData.MDBWriteOptions = mdbOptions;
            }
          }

          this.emitChange();
        }

        // It will be removed once new API is ready
        // Used for Canteen Pantry create account flow
        if (action.actionType === MachineConstants.MARKET_UPDATED_LEGACY) {
          if (
            action.data.response.status === 'ok' &&
            action.data.response.data &&
            action.data.response.data.id &&
            action.data.response.data.isdisabled === 'N'
          ) {
            const locationFeatures = new MachineFeatures(
              action.data.response.data.features,
            );
            let emitChange = false;
            const accountCurrency = AccountStore.getCurrency();
            const orgCurrency = action.data.response.data.currency;
            const incompatibleCurrency =
              orgCurrency &&
              (!accountCurrency ||
                accountCurrency.toUpperCase() !== orgCurrency.toUpperCase());

            if (locationFeatures.HasScanAndPay) {
              const newMarket = _.assign(action.data.response.data, {
                localType: 'market',
                incompatibleCurrency,
                ccNotConfigured:
                  !Settings.processors[action.data.response.data.processorType],
                currency: action.data.response.data.currency,
                deviceId: action.data.response.data.id,
                beacon: action.data.beacon,
                locationType: action.data.response.data.type,
                distance: action.data.distance,
                incompatibleOrg:
                  !action.data.response.data.canUserMakePurchaseAtOrg,
                hiatusMode: locationFeatures.IsInHiatusMode,
              });

              const beaconExistingIndex = this.beaconMarkets.findIndex(
                (market) => market.deviceId === newMarket.deviceId,
              );

              if (beaconExistingIndex < 0) {
                this.beaconMarkets.push(newMarket);
              } else {
                this.beaconMarkets.splice(beaconExistingIndex, 1, newMarket);
              }

              PersistentStore.setMarketInfo(newMarket.broadcastid, newMarket);
              emitChange = true;
            }

            if (locationFeatures.HasBunnCoffeeMachine) {
              const deviceId = action.data.response.data.id;
              const existingIndex = this.coffeeMachines.findIndex(
                (machine) => machine.deviceId === deviceId,
              );

              if (existingIndex < 0) {
                this.coffeeMachines.push({
                  ...action.data.response.data,
                  localType: 'coffee',
                  deviceId,
                  beacon: false,
                  locationType: 'sos',
                  incompatibleOrg:
                    !action.data.response.data.canUserMakePurchaseAtOrg,
                  hiatusMode: locationFeatures.IsInHiatusMode,
                  ccNotConfigured:
                    !Settings.processors[
                      action.data.response.data.processorType
                    ],
                  incompatibleCurrency,
                });
                emitChange = true;
              }
            }

            if (emitChange) {
              this.emitChange();
            }
          }
        }

        if (action.actionType === MachineConstants.MARKET_UPDATED) {
          if (
            action.data.response &&
            action.data.response.locationId &&
            !action.data.response.isDisabled
          ) {
            const locationFeatures = new LocationFeatures(
              action.data.response.locationFeatures,
            );
            let emitChange = false;
            const accountCurrency = AccountStore.getCurrency();
            const orgCurrency = action.data.response.localization.currency;
            const incompatibleCurrency =
              orgCurrency &&
              (!accountCurrency ||
                accountCurrency.toUpperCase() !== orgCurrency.toUpperCase());

            if (locationFeatures.HasScanAndPay) {
              const newMarket = _.assign(action.data.response, {
                id: action.data.response.locationId,
                broadcastid: action.data.response.beaconId,
                localType: 'market',
                incompatibleCurrency,
                ccNotConfigured:
                  !Settings.processors[action.data.response.processorType],
                currency: action.data.response.localization.currency,
                deviceId: action.data.response.locationId,
                beacon: action.data.beacon,
                locationType: action.data.response.type,
                distance: action.data.distance,
                incompatibleOrg: !action.data.response.canUserMakePurchaseAtOrg,
                hiatusMode: locationFeatures.IsInHiatusMode,
              });

              const beaconExistingIndex = this.beaconMarkets.findIndex(
                (market) => market.deviceId === newMarket.deviceId,
              );

              if (beaconExistingIndex < 0) {
                this.beaconMarkets.push(newMarket);
              } else {
                this.beaconMarkets.splice(beaconExistingIndex, 1, newMarket);
              }

              PersistentStore.setMarketInfo(newMarket.broadcastid, newMarket);
              emitChange = true;
            }

            if (locationFeatures.HasBunnCoffeeMachine) {
              const deviceId = action.data.response.locationId;
              const existingIndex = this.coffeeMachines.findIndex(
                (machine) => machine.deviceId === deviceId,
              );

              if (existingIndex < 0) {
                this.coffeeMachines.push({
                  ...action.data.response,
                  id: action.data.response.locationId,
                  broadcastid: action.data.response.beaconId,
                  localType: 'coffee',
                  deviceId,
                  beacon: false,
                  locationType: 'sos',
                  incompatibleOrg:
                    !action.data.response.canUserMakePurchaseAtOrg,
                  hiatusMode: locationFeatures.IsInHiatusMode,
                  ccNotConfigured:
                    !Settings.processors[action.data.response.processorType],
                  incompatibleCurrency,
                });
                emitChange = true;
              }
            }

            if (emitChange) {
              this.emitChange();
            }
          }
        }

        if (action.actionType === MachineConstants.VMC_LOG_RECEIVED) {
          this.vmcLogArray = this.vmcLogArray.concat(action.data.logs);

          while (this.vmcLogArray.length > MAX_LOG_SIZE) {
            this.vmcLogArray.shift();
          }

          this.vmcLogText = this.vmcLogArray.join('\r\n');
          this.emitChange();
        }

        if (action.actionType === MachineConstants.SERVICE_MODE_CHANGED) {
          this.serviceMode = action.data.serviceMode;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.MACHINE_MODELS_FETCHED) {
          this.machineModels = action.data.response;
          this.emitChange();
        }

        if (action.actionType === MachineConstants.CHECK_VERSION) {
          this.checkVersionPayload = action.data.payload;
          this.emitCheckVersionChange();
        }
      },
    );
  }

  addReadOnlyDevice(machine: VendingMachine) {
    const updatedMachine = new TSFInsideDevice(machine.device || undefined);
    updatedMachine.setReadOnly(true);
    this.devices.push(updatedMachine);
    this.errorLoadingDevices = false;
    this.mdbAvailable = true;
    this.emitChange();
  }

  async addMachineToList(machine: VendingMachine) {
    const machineInfo = await PersistentStore.getMachineInfo(machine.deviceId);

    if (machineInfo) {
      let letter = machineInfo.Letter;

      if (machineInfo.Number) {
        letter += machineInfo.Number;
      }

      machine.setDeviceName(machineInfo.Name);
      machine.setColor(machineInfo.Color);
      machine.setLetter(letter);
      machine.setImageUrl(machineInfo.ImageUrl);
      machine.setHidePlanogram(machineInfo.DisableNutritionalInfo);
    }

    const existingMachine = this.machines.find(
      (item) => item.deviceId === machine.deviceId,
    );

    if (!existingMachine) {
      this.machines.push(machine);
    } else {
      existingMachine.setDeviceName(machine.name);
      existingMachine.setColor(machine.color);
      existingMachine.setLetter(machine.letter);
      existingMachine.setImageUrl(machine.imageUrl);
      existingMachine.setHidePlanogram(machine.hidePlanogram);
    }

    this.errorLoadingMachines = false;
    this.emitChange();
  }

  async addMarketToList(market: MachineType, beacon: boolean) {
    const marketInfo = await PersistentStore.getMarketInfo(market.deviceId);

    if (marketInfo) {
      const existingBeaconMarket = this.beaconMarkets.find(
        (beaconMarket) => beaconMarket.deviceId === marketInfo.deviceId,
      );

      if (!existingBeaconMarket) {
        if (beacon) {
          this.beaconMarkets.push(_.assign(market, marketInfo));
        }

        this.emitChange();
      }
    }
  }

  // events for store data changes
  emitChange() {
    this._emitter.emit('change');
  }

  addChangeListener(callback: () => void) {
    this._emitter.on('change', callback);
  }

  removeChangeListener(callback: () => void) {
    this._emitter.removeListener('change', callback);
  }

  emitMachineAttached() {
    this._emitter.emit('machineAttached');
  }

  addMachineAttachedListener(callback: () => void) {
    this._emitter.on('machineAttached', callback);
  }

  removeMachineAttachedListener(callback: () => void) {
    this._emitter.removeListener('machineAttached', callback);
  }

  emitDexFileUploaded() {
    this._emitter.emit('dexFileUploaded');
  }

  emitServiceComplete() {
    this._emitter.emit('serviceComplete');
  }

  emitServiceCompleteError() {
    this._emitter.emit('serviceCompleteError');
  }

  emitDEXDataReceived() {
    this._emitter.emit('dexDataReceived');
  }

  emitDEXFailed() {
    this._emitter.emit('dexFailed');
  }

  addDexFileUploadedListener(callback: () => void) {
    this._emitter.on('dexFileUploaded', callback);
  }

  removeDexFileUploadedListener(callback: () => void) {
    this._emitter.removeListener('dexFileUploaded', callback);
  }

  addServiceCompleteListener(callback: () => void) {
    this._emitter.on('serviceComplete', callback);
  }

  removeServiceCompleteListener(callback: () => void) {
    this._emitter.removeListener('serviceComplete', callback);
  }

  addDEXDataReadListener(callback: () => void) {
    this._emitter.on('dexDataReceived', callback);
  }

  removeDEXDataReceivedListener(callback: () => void) {
    this._emitter.removeListener('dexDataReceived', callback);
  }

  addDEXFailedListener(callback: () => void) {
    this._emitter.on('dexFailed', callback);
  }

  removeDEXFailedListener(callback: () => void) {
    this._emitter.removeListener('dexFailed', callback);
  }

  emitConnectionError() {
    this._emitter.emit('connectionError');
  }

  addConnectionErrorListener(callback: () => void) {
    this._emitter.on('connectionError', callback);
  }

  removeConnectionErrorListener(callback: () => void) {
    this._emitter.removeListener('connectionError', callback);
  }

  emitReboot() {
    this._emitter.emit('reboot');
  }

  addRebootListener(callback: () => void) {
    this._emitter.on('reboot', callback);
  }

  removeRebootListener(callback: () => void) {
    this._emitter.removeListener('reboot', callback);
  }

  emitDeviceUnattached() {
    this._emitter.emit('deviceUnattached');
  }

  addDeviceUnattachedListener(callback: () => void) {
    this._emitter.on('deviceUnattached', callback);
  }

  removeDeviceUnattachedListener(callback: () => void) {
    this._emitter.removeListener('deviceUnattached', callback);
  }

  emitCheckVersionChange() {
    this._emitter.emit('checkVersion');
  }

  addCheckVersionChangeListener(callback: () => void) {
    this._emitter.on('checkVersion', callback);
  }

  removeCheckVersionChangeListener(callback: () => void) {
    this._emitter.removeListener('checkVersion', callback);
  }

  resetMachineData() {
    this.setupData = null;
    this.vmcLogArray.length = 0;
    this.vmcLogText = '';
    this.serviceMode = false;
  }

  getShops(): Array<MachineType | VendingMachine> {
    let shops = this.getMarkets();
    shops = shops.concat(this.getCoffeeMachines());

    if (Settings.buildType !== BuildTypes.pantry) {
      shops = shops.concat(this.getMachines());
    }

    return shops;
  }

  getCoffeeMachines(): Array<MachineType> {
    if (AccountStore.isDemo()) {
      return [
        {
          name: 'Coffee Machine',
          localType: 'coffee',
          deviceId: 'coffee',
          beacon: false,
          locationType: 'sos',
          deviceName: 'Coffee Machine',
          id: 'coffee-id',
        },
      ];
    }

    return this.coffeeMachines;
  }

  getCheckVersionPayload() {
    return this.checkVersionPayload;
  }

  getMachines(): Array<VendingMachine> {
    if (AccountStore.isDemo()) {
      const machine1 = new DemoTSFInsideDevice();
      machine1.setDeviceName('Quick Snacks');
      machine1.setLetter('A');
      machine1.setColor('green');
      machine1.setHidePlanogram(false);
      machine1.setDeviceId('AV10000');
      const machine2 = new DemoTSFInsideDevice();
      machine2.setDeviceName('Quick Drinks');
      machine2.setLetter('B');
      machine2.setColor('blue');
      machine2.setHidePlanogram(false);
      machine2.setDeviceId('AV10001');
      return [machine1, machine2];
    }

    return this.machines;
  }

  getMachine(deviceId: string) {
    return this.machines.find((el) => el.deviceId === deviceId);
  }

  getErrorLoadingMachines() {
    return this.errorLoadingMachines;
  }

  getDevices(): Array<VendingMachine> {
    if (AccountStore.isDemo()) {
      const device = new TSFInsideDevice(undefined);
      device.setDeviceName('Quick Drinks');
      device.setHidePlanogram(false);
      device.setDeviceId('AV21624-10000802');
      device.setReadOnly(true);
      return [device];
    }

    return this.devices;
  }

  getUnattachedMachines() {
    return this.unattachedMachines;
  }

  getUnattachedMachinesError() {
    return this.errorLoadingUnattachedMachines;
  }

  getErrorLoadingDevices() {
    return this.errorLoadingDevices;
  }

  dexDataFailedMessage() {
    return this.dexFailedMessage;
  }

  getConnectionError() {
    return this.connectionError;
  }

  getRebootReason() {
    return this.rebootReason;
  }

  getMachineSettings() {
    if (this.setupData && this.setupData.ConfigData) {
      return {
        Version: this.setupData.Version,
        PICVersion: this.setupData.ConfigData.PICVersion,
        OSVersion: this.setupData.ConfigData.OSVersion,
        IPAddress: this.setupData.ConfigData.IPAddress,
        WiFiMacAddress: this.setupData.ConfigData.WiFiMacAddress,
        LastDateDEXServiced: this.setupData.ConfigData.LastDateDEXServiced,
        LastDateDEXPulled: this.setupData.ConfigData.LastDateDEXPulled,
        IsActiveDex: this.setupData.ConfigData.IsActiveDex,
        LastUpdateStatus: this.setupData.LastUpdateStatus,
        LastUpdateDate: this.setupData.LastUpdateDate,
      };
    }

    return null;
  }

  clearMachines() {
    this.machines = [];
    this.errorLoadingMachines = false;
    this.coffeeMachines = [];
    this.emitChange();
  }

  clearDevices() {
    this.devices = [];
    this.errorLoadingDevices = false;
    this.emitChange();
  }

  clearMarkets() {
    this.beaconMarkets = [];
    this.emitChange();
  }

  getWifiOn() {
    return this.wifiOn;
  }

  getWifiNetwork() {
    return this.wifiNetwork;
  }

  getWifiPassword() {
    return this.wifiPassword;
  }

  isMdbAvailable() {
    return this.mdbAvailable;
  }

  getMarkets(): Array<MachineType> {
    if (AccountStore.isDemo()) {
      return [
        {
          name: 'Fresh Food',
          localType: 'market',
          deviceId: 'e36b2b54c6ff0708b293c40d43dd7f50',
          beacon: true,
          locationType: 'sos',
          id: 'id',
        },
      ];
    }

    return this.beaconMarkets;
  }

  getVMCLog() {
    return this.vmcLogText;
  }

  getServiceMode() {
    return this.serviceMode;
  }

  getModels() {
    return this.machineModels;
  }

  getCashlessDeviceSetting(): MDBOption | null {
    return this.cashlessDeviceSetting;
  }

  getMdbOptions() {
    if (this.setupData?.ConfigData) {
      return this.setupData?.ConfigData.MDBWriteOptions;
    }

    return [];
  }

  isLocationInList(locationId: string): boolean {
    return this.beaconMarkets.some(
      (market: MachineType) => market.id === locationId,
    );
  }
}

export default new MachineStore();
