import React from 'react';
import {
  PixelRatio,
  ScrollView,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import BackSubheader from '../../elements/BackSubheader';
import type {LocationType} from 'src/types/Location';
import CheckoutBar from '../../elements/orderAhead/CheckoutBar';
import RoundedAppButton, {
  RoundedAppButtonType,
} from '../../elements/RoundedAppButton';
import Styles from '../../Styles';
import type {MenuProductType, ProductModifierType} from 'src/types/Menu';
import MenuService from 'src/services/MenuService';
import ProductModifier, {
  ModifierTypes,
} from '../../elements/orderAhead/ProductModifier';
import NavActions from 'src/actions/NavActions';
import CartService from 'src/services/CartService';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import type {CartItemModifier} from 'src/types/Cart';
import AppRoutes from 'src/AppRoutes';
import AccountStore from 'src/stores/AccountStore';
import TransactionStore from 'src/stores/TransactionStore';
import AccountConstants from 'src/constants/AccountConstants';
import Spinner from '../../elements/NumberSpinner';
import CartTypes from 'src/constants/cart/CartTypes';
import CartStore from 'src/stores/CartStore';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import MenuActions from 'src/actions/MenuActions';
import {CartItem} from 'src/types/TransactionDetail';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {
  DietaryHealthGood,
  DietaryHealthWarning,
  DietaryHealthAlert,
} from 'src/components/img/svg/DietaryHealthGrades';
import {ProductNutrition} from '../../../types/ProductType';
import {DNA} from 'src/types/dnaTypes';
import {checkValue, formatCurrency, getPreviousRouteName} from '../../../Util';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import moment from 'moment';
import uuid from 'src/nativeModules/UUID';
import TransactionActions from 'src/actions/TransactionActions';
import Settings from 'src/Settings';
import PromotionTypes from 'src/constants/cart/PromotionTypes';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getDescriber} from 'src/components/screens/orderAhead/descriptor/DescriptorType';

const {
  productDetailsTitle,
  productDetailsImageView,
  productDetailsStyles,
  dashLineColor,
  roundedButtonColor,
} = getDescriber();

type ProductDetailScreenProps = {
  product: MenuProductType;
  location: LocationType;
  productAdded?: () => void;
  edit: boolean;
  lineNumber: number;
  modifierSelections: Array<CartItemModifier>;
  dnaConfiguration: DNA;
  isRecentOrder?: boolean;
  navigation?: NavigationProp<ProductDetailScreen>;
};

type ProductDetailScreenState = {
  modifiers: Array<ProductModifierType>;
  quantity: number;
  actionButtonPressed: boolean;
  nutrition: ProductNutrition;
  filteredNutritionFactCount: number;
  updatedPrice: number;
  modifierSectionCalories: any;
  previousRoute: string | null;
};

class ProductDetailScreen extends React.Component<
  ProductDetailScreenProps,
  ProductDetailScreenState
> {
  selections: Record<string, string[] | undefined>;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ProductDetailScreenProps) {
    super(props);
    this.state = {
      modifiers: MenuService.getModifiers(this.props.product),
      quantity: 1,
      actionButtonPressed: false,
      nutrition: null,
      filteredNutritionFactCount: 0,
      updatedPrice: 0,
      modifierSectionCalories: [],
      previousRoute: null,
    };
    this.selections = {};
    this.modifierSelectionChanged = this.modifierSelectionChanged.bind(this);
    this.hasRequiredSelections = this.hasRequiredSelections.bind(this);
    this.addItemClick = this.addItemClick.bind(this);
    this.addItem = this.addItem.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.getItem = this.getItem.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.onCartPressed = this.onCartPressed.bind(this);
  }

  async componentDidMount(): Promise<void> {
    try {
      const previousRoute = getPreviousRouteName(
        this.props.navigation?.getState()?.routes,
      );

      const modifierSections = [];
      if (this.state.modifiers) {
        this.state.modifiers.forEach((inner) => {
          const temporaryValueObject = {
            id: inner.id,
            sectionCalories: 0,
          };
          modifierSections.push(temporaryValueObject);
        });
      }

      let nutrition = await MenuActions.getNutrition(
        this.props.location.locationId,
        this.props.product.id,
      );

      nutrition = {
        ...nutrition,
        productName: this.props.product.name,
      };

      this.setState(
        {
          previousRoute,
          modifierSectionCalories: modifierSections,
          nutrition: nutrition,
          updatedPrice: this.props.product.price,
          filteredNutritionFactCount: this.getNumberOfFilteredKeys(nutrition),
        },
        () => {
          FirebaseAnalytic.trackEvent(
            'componentDidMount',
            'ProductDetailScreen',
            {
              ...this.props,
              ...this.state,
            },
          );
        },
      );
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:componentDidMount',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:componentDidMount',
        e.message ? e.message : e.toString(),
      );
    }
  }

  modifierSelectionChanged(modifierId: string, selections: Array<string>) {
    try {
      this.selections[modifierId] = selections;

      // Add calories and price increases from the selected modifiers
      let calorieSum = this.props.product.calories;
      let priceSum = this.props.product.price;

      const keys = Object.keys(this.selections);
      const caloriesBySectionArray = [];

      this.state.modifiers.forEach((inner) => {
        let sectionCalories = 0;

        if (keys.includes(inner.id)) {
          const specificValues = this.selections[inner.id];

          inner.vals.forEach((m) => {
            if (specificValues.includes(m.id)) {
              if (m.calories !== null) {
                calorieSum += m.calories;
                sectionCalories += m.calories;
              }
              if (m.price !== null) {
                priceSum += m.price;
              }
            }
          });
        }
        const temporaryValueObject = {
          id: inner.id,
          sectionCalories: sectionCalories,
        };

        caloriesBySectionArray.push(temporaryValueObject);
      });

      const updatedNutrition = {
        ...this.state.nutrition,
        calories: calorieSum,
      };

      this.setState(
        {
          nutrition: updatedNutrition,
          updatedPrice: priceSum,
          modifierSectionCalories: caloriesBySectionArray,
        },
        () => {
          FirebaseAnalytic.trackEvent(
            'modifierSelectionChanged',
            'ProductDetailScreen',
            {
              ...this.props,
              ...this.state,
              modifierId,
            },
          );
        },
      );
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:modifierSelectionChanged',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:modifierSelectionChanged',
        e.message ? e.message : e.toString(),
      );
    }
  }

  hasRequiredSelections() {
    try {
      const requiredModifiers = this.state.modifiers.filter(
        (modifier) => modifier.forced === 'Y',
      );

      FirebaseAnalytic.trackEvent(
        'hasRequiredSelections',
        'ProductDetailScreen',
        {
          ...this.props,
          ...this.state,
          requiredModifiers,
        },
      );

      if (requiredModifiers) {
        return !requiredModifiers.some((modifier) => {
          const modifierSelections = this.selections[modifier.id];
          return (
            !modifierSelections ||
            modifierSelections.length === 0 ||
            modifierSelections.includes('')
          );
        });
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:hasRequiredSelections',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:hasRequiredSelections',
        e.message ? e.message : e.toString(),
      );
    }
    return true;
  }

  async onCartPressed() {
    const pickupTime = TransactionStore.getPickupTime();
    const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
    const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
    const now = moment()
      .add(leadTime, 'minutes')
      .add(timeZoneOffsetMinutes, 'minutes');
    if (!moment(pickupTime.date).isAfter(now)) {
      try {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        alertError(
          Localized.Errors.pickup_time_not_available,
          undefined,
          () => {
            TransactionActions.shoppingCartTransactionsCleared();
            if (Settings.isNewUI()) {
              NavActions.replace(AppRoutes.NewPickupTime, {
                availableTimes: MenuService.getAvailableTimeSlotsFromList(
                  availableTimes,
                  this.props.location.onlineOrderConfig.kitchenSchedule,
                ),
                selectedPickupLocation: TransactionStore.getPickupLocation(),
                location: this.props.location,
              });
            } else {
              NavActions.replace(AppRoutes.PickupTime, {
                strings: Localized,
                location: this.props.location,
                availableTimes,
              });
            }
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    } else {
      try {
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        const isTimeSlotPresent = availableTimes.some(
          (obj) =>
            obj.timeString === pickupTime.timeString &&
            moment(pickupTime.date).isSame(moment(obj.date), 'day'),
        );
        if (moment(pickupTime.date).isAfter(now) && isTimeSlotPresent) {
          if (this.props.edit) {
            NavActions.pop();
          } else {
            FirebaseAnalytic.trackEvent(
              'onCartPressed',
              'ProductDetailScreen',
              {
                ...this.props,
                ...this.state,
                marketName: this.props.location.name,
                locationId: this.props.location.locationId,
                locationType: AccountConstants.SOS_LOCATION_TYPE,
                beaconId:
                  this.props.location.beaconId ??
                  this.props.location.locationId,
                cartType: CartTypes.OrderAhead,
                location: this.props.location,
                showPreparationMethod:
                  this.props.location.onlineOrderConfig.hasDiningPreference,
                defaultPreparationMethod:
                  this.props.location.onlineOrderConfig.diningPreference,
                navigate: AppRoutes.Scan,
              },
            );
            NavActions.replace(AppRoutes.Scan, {
              marketName: this.props.location.name,
              locationId: this.props.location.locationId,
              locationType: AccountConstants.SOS_LOCATION_TYPE,
              beaconId:
                this.props.location.beaconId ?? this.props.location.locationId,
              cartType: CartTypes.OrderAhead,
              location: this.props.location,
              showPreparationMethod:
                this.props.location.onlineOrderConfig.hasDiningPreference,
              defaultPreparationMethod:
                this.props.location.onlineOrderConfig.diningPreference,
            });
          }
        } else {
          alertError(
            Localized.Errors.order_limit_reached_for_pickup_time,
            undefined,
            () => {
              TransactionActions.shoppingCartTransactionsCleared();
              if (Settings.isNewUI()) {
                NavActions.replace(AppRoutes.NewPickupTime, {
                  availableTimes: MenuService.getAvailableTimeSlotsFromList(
                    availableTimes,
                    this.props.location.onlineOrderConfig.kitchenSchedule,
                  ),
                  selectedPickupLocation: TransactionStore.getPickupLocation(),
                  location: this.props.location,
                });
              } else {
                NavActions.replace(AppRoutes.PickupTime, {
                  strings: Localized,
                  location: this.props.location,
                  availableTimes,
                });
              }
            },
          );
        }
      } catch (e) {
        CrashlyticsEvents.log(
          'Exception',
          'CountrySelectionScreen:onCartPressed:else',
          e.message ? e.message : e.toString(),
        );
        Events.Error.trackEvent(
          'Exception',
          'CountrySelectionScreen:onCartPressed:else',
          e.message ? e.message : e.toString(),
        );
      }
    }
  }

  async addItemClick() {
    const pickupTime = TransactionStore.getPickupTime();
    const timeZoneOffsetMinutes = MenuService.getTimezoneOffsetMinutes();
    const leadTime = this.props.location.onlineOrderConfig.kitchenSchedule;
    const now = moment()
      .add(leadTime, 'minutes')
      .add(timeZoneOffsetMinutes, 'minutes');
    if (!moment(pickupTime?.date).isAfter(now)) {
      try {
        this.context.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        alertError(
          Localized.Errors.pickup_time_not_available,
          undefined,
          () => {
            TransactionActions.shoppingCartTransactionsCleared();
            if (Settings.isNewUI()) {
              NavActions.replace(AppRoutes.NewPickupTime, {
                availableTimes: MenuService.getAvailableTimeSlotsFromList(
                  availableTimes,
                  this.props.location.onlineOrderConfig.kitchenSchedule,
                ),
                selectedPickupLocation: TransactionStore.getPickupLocation(),
                location: this.props.location,
              });
            } else {
              NavActions.replace(AppRoutes.PickupTime, {
                strings: Localized,
                location: this.props.location,
                availableTimes,
              });
            }
          },
        );
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected',
          generateErrorMessage(error),
          guid,
        );
      } finally {
        this.context?.actions.hideSpinner();
      }
    } else {
      try {
        this.context?.actions.showSpinner();
        const availableTimes = await MenuActions.getAvailableTimeSlots(
          this.props.location,
          TransactionStore.getPickupLocationId(),
        );
        const isTimeSlotPresent = availableTimes.some(
          (obj) =>
            obj.timeString === pickupTime?.timeString &&
            moment(pickupTime?.date).isSame(moment(obj.date), 'day'),
        );
        if (moment(pickupTime?.date).isAfter(now) && isTimeSlotPresent) {
          this.context?.actions.hideSpinner();
          if (!this.hasRequiredSelections()) {
            alertError(Localized.Errors.required_modifier);
            return;
          }
          this.setState({
            actionButtonPressed: true,
          });

          const items = [...TransactionStore.getAllItems()];

          FirebaseAnalytic.trackEvent('addItemClick', 'ProductDetailScreen', {
            ...this.props,
            ...this.state,
            items,
          });

          if (this.props.edit && items.length > 0) {
            this.updateItem(items);
          } else {
            // When new item is added see if product has modifier of type 5 then navigate to ProductUpsellScreen
            const upsellTabs = MenuService.getUpsellTabs();

            if (upsellTabs && upsellTabs.length > 0) {
              CartStore.setPendingItem(this.getItem());
              CartStore.setPendingQuantity(this.state.quantity);
              const route =
                Settings.is365Pay() && Platform.OS !== 'web'
                  ? AppRoutes.ProductUpsellSheet
                  : AppRoutes.ProductUpsell;
              NavActions.push(route, {
                ...this.props,
                tabs: upsellTabs,
              });
              return;
            }
            this.addItem(items);
          }
        } else {
          this.context?.actions.hideSpinner();
          alertError(
            Localized.Errors.order_limit_reached_for_pickup_time,
            undefined,
            () => {
              TransactionActions.shoppingCartTransactionsCleared();
              if (Settings.isNewUI()) {
                NavActions.replace(AppRoutes.NewPickupTime, {
                  availableTimes: MenuService.getAvailableTimeSlotsFromList(
                    availableTimes,
                    this.props.location.onlineOrderConfig.kitchenSchedule,
                  ),
                  selectedPickupLocation: TransactionStore.getPickupLocation(),
                  location: this.props.location,
                });
              } else {
                NavActions.replace(AppRoutes.PickupTime, {
                  strings: Localized,
                  location: this.props.location,
                  availableTimes,
                });
              }
            },
          );
        }
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected:else',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'ProductDetailScreen:PickupTimeSelected:else',
          generateErrorMessage(error),
          guid,
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  updateItem(items: CartItem[]) {
    try {
      const updatedItem = this.getItem();
      // When product is updated, put back the upsell modifiers if any
      const upsellModifiers = this.props.modifierSelections?.filter(
        (modifier) => {
          return modifier.Type === ModifierTypes.Upsell;
        },
      );

      if (upsellModifiers && upsellModifiers.length > 0) {
        if (updatedItem.Modifiers) {
          updatedItem.Modifiers = updatedItem.Modifiers.concat(upsellModifiers);
        } else {
          updatedItem.Modifiers = upsellModifiers;
        }
      }

      const existingIndex = items.findIndex(
        (item) => item.LineNumber === this.props.lineNumber,
      );
      const filteredItems = MenuService.filterProductModifiers(updatedItem);

      items.splice(existingIndex, 1, ...filteredItems);

      FirebaseAnalytic.trackEvent('updateItem', 'ProductDetailScreen', {
        ...this.props,
        ...this.state,
        updatedItem,
        upsellModifiers,
        filteredItems,
        items,
        existingIndex,
      });

      this.updateCart(items);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:updateItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:updateItem',
        generateErrorMessage(error),
      );
    }
  }

  addItem(items: CartItem[]) {
    try {
      const item = this.getItem();

      // Adding product modifiers as individual products
      const filteredItems = MenuService.filterProductModifiers(item);

      FirebaseAnalytic.trackEvent('addItem', 'ProductDetailScreen', {
        ...this.props,
        ...this.state,
        filteredItems,
        items,
      });
      for (let i = 0; i < this.state.quantity; i++) {
        items.unshift(...filteredItems);
      }
      this.updateCart(items);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:addItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:addItem',
        generateErrorMessage(error),
      );
    }
  }

  async updateCart(items: CartItem[]) {
    const prepDefaults = TransactionStore.getPreparationMethodDefaults(
      this.props.location,
    );

    const discountCode =
      this.props.location.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
        ? PromotionTypes.AfterTaxPromo
        : null;

    const result = await CartService.updateCart(
      this.props.location.locationId,
      AccountStore.getAccountId(),
      [],
      items,
      AccountConstants.SOS_LOCATION_TYPE,
      this.props.product.scancode,
      TransactionStore.getPickupTime(),
      prepDefaults.selectedMethod,
      this.context,
      discountCode,
    );

    FirebaseAnalytic.trackEvent('addItem', 'ProductDetailScreen', {
      ...this.props,
      ...this.state,
      prepDefaults,
      items,
      result,
    });

    if (result) {
      if (this.props.isRecentOrder) {
        NavActions.popToRoute(AppRoutes.Menu, {
          location: this.props.location,
        });
      } else {
        NavActions.pop();
      }
      if (this.props.productAdded) {
        this.props.productAdded();
      }
    }
  }

  getItem(): CartItem {
    const barcode = this.props.product.scancode;
    const item: CartItem = {
      BarCode: barcode,
      Modifiers: [],
      PrintGroups: this.props.product.printgroups,
    };
    try {
      Object.keys(this.selections).forEach((modifier: string) => {
        this.selections[modifier].forEach((modifierVal: string) => {
          item.Modifiers.push(
            MenuService.getCartModifier(modifierVal) ||
              MenuService.getCartModifierForFreeText(modifier, modifierVal),
          );
        });
      });

      FirebaseAnalytic.trackEvent('getItem', 'ProductDetailScreen', {
        ...this.props,
        ...this.state,
        barcode,
        item,
      });

      Events.CartSession.trackProductDetailAdded(item);
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'ProductDetailScreen:getItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'ProductDetailScreen:getItem',
        generateErrorMessage(error),
      );
    }
    return item;
  }

  // if the nutrition object exists
  // if the nutrition object has at least 1 entry and the entry keys are NOT productId, productName, locationId, productNutritionId, calories
  getNumberOfFilteredKeys(nutritionObject) {
    let keyCount = 0;
    if (nutritionObject !== null && nutritionObject !== undefined) {
      if (Object.keys(nutritionObject).length > 0) {
        Object.keys(nutritionObject).forEach((key: string) => {
          if (
            key !== 'productId' &&
            key !== 'productName' &&
            key !== 'locationId' &&
            key !== 'productNutritionId' &&
            key !== 'percentDailyValues' &&
            key !== 'calories'
          ) {
            if (
              nutritionObject[key] !== null &&
              nutritionObject[key] !== undefined &&
              nutritionObject[key] !== ''
            ) {
              keyCount++;
            }
          }
        });
      }
    }

    FirebaseAnalytic.trackEvent(
      'getNumberOfFilteredKeys',
      'ProductDetailScreen',
      {
        ...this.props,
        ...this.state,
        keyCount,
        nutritionObject,
      },
    );
    return keyCount;
  }

  render() {
    let imageUri = null;
    if (
      this.props.product.largeimageurl !== '' &&
      this.props.product.largeimageurl !== null
    ) {
      imageUri = this.props.product.largeimageurl;
    }

    /**
     * ###############################
     * DNA: Daily Nutritional Analysis
     * ###############################
     */
    const dna = this.props?.location?.locationFeatures?.dna;
    let calorieSection = null;
    let healthGradeSection = null;
    let nutritionButton = null;
    const unitDisplayName = dna?.showCaloriesAsKilocalories
      ? Localized.Labels.kcal
      : Localized.Labels.cal_not_all_caps;

    // not disabled
    if (!dna?.isDisabled) {
      // calories are set and it is a number
      const calorieValue = this.props.product.calories;

      // show calories
      if (dna?.showCalories) {
        if (
          this.state.nutrition?.calories !== null &&
          checkValue(calorieValue)
        ) {
          calorieSection = (
            <View
              accessible={true}
              accessibilityLabel={`${this.state.nutrition?.calories} calories`}
              accessibilityRole="text"
              aria-label={`${this.state.nutrition?.calories} calories`}
              style={{flexDirection: 'row'}}
            >
              <Text
                style={productDetailsStyles().calories}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {this.state.nutrition?.calories}
              </Text>
              <Text
                style={productDetailsStyles().calorieText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {unitDisplayName}
              </Text>
            </View>
          );
        }
      }

      // show color codes
      if (dna?.showColorCodes) {
        if (this.state.nutrition?.calories !== null && !isNaN(calorieValue)) {
          if (
            this.state.nutrition?.calories > -1 &&
            this.state.nutrition?.calories <= dna?.levelGreenMax
          ) {
            healthGradeSection = (
              <View
                accessible={true}
                accessibilityLabel="Health grade: good, "
                accessibilityHint={`Calories are between 0 - ${dna?.levelGreenMax}`}
                accessibilityRole="image"
                role="img"
                aria-label="Health grade: good, "
                style={productDetailsStyles().healthGrade}
              >
                <DietaryHealthGood />
              </View>
            );
          } else if (
            this.state.nutrition?.calories > dna?.levelGreenMax &&
            this.state.nutrition?.calories <= dna?.levelYellowMax
          ) {
            healthGradeSection = (
              <View
                accessible={true}
                accessibilityLabel="Health grade: warning, "
                accessibilityHint={`Calories are between ${dna?.levelGreenMax} - ${dna?.levelYellowMax}`}
                accessibilityRole="image"
                role="img"
                aria-label="Health grade: warning, "
                style={productDetailsStyles().healthGrade}
              >
                <DietaryHealthWarning />
              </View>
            );
          } else {
            healthGradeSection = (
              <View
                accessible={true}
                accessibilityLabel="Health grade: alert, "
                accessibilityHint={`Calories are greater than ${dna?.levelYellowMax}`}
                accessibilityRole="image"
                role="img"
                aria-label="Health grade: alert, "
                style={productDetailsStyles().healthGrade}
              >
                <DietaryHealthAlert />
              </View>
            );
          }
        }
      }
      // Color Codes are disabled
      else {
        healthGradeSection = null;
      }
      if (dna?.showFullNutrition) {
        if (checkValue(calorieValue)) {
          if (
            this.state.filteredNutritionFactCount > 0 &&
            this.state.nutrition?.calories
          ) {
            nutritionButton = (
              <View style={productDetailsStyles().nutritionButtonView}>
                <TouchableOpacity
                  accessible={true}
                  accessibilityLabel={`View Nutrition for ${this.props.product.name}`}
                  accessibilityHint="Double tap to navigate to the nutrition screen"
                  accessibilityRole="button"
                  aria-label={`View Nutrition for ${this.props.product.name}`}
                  role="button"
                  onPress={() => {
                    NavActions.push(AppRoutes.ViewNutrition, {
                      product: this.props.product,
                      nutrition: this.state.nutrition,
                    });
                  }}
                  style={productDetailsStyles().outlined}
                  testID="viewNutrition"
                >
                  <Text
                    style={[
                      productDetailsStyles().outlinedButtonText,
                      !Settings.isRevolve()
                        ? null
                        : {textTransform: 'uppercase'},
                    ]}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                  >
                    {Localized.Buttons.view_nutrition}
                  </Text>
                </TouchableOpacity>
              </View>
            );
          } else {
            nutritionButton = null;
          }
        }
      } else {
        nutritionButton = null;
      }
    }
    // DNA is disabled
    else {
      healthGradeSection = null;
    }

    let modifiers = null;
    if (this.state.modifiers && this.state.modifiers.length > 0) {
      const sortedModifiers = Settings.is365Pay()
        ? this.state.modifiers.sort((a, b) => {
            const aTypeIsFour = a?.type === 4;
            const bTypeIsFour = b?.type === 4;

            if (aTypeIsFour && !bTypeIsFour) return 1;
            if (bTypeIsFour && !aTypeIsFour) return -1;
            return 0;
          })
        : this.state.modifiers;
      modifiers = sortedModifiers.map((modifier, index) => {
        let selections = null;

        if (this.props.modifierSelections) {
          selections = this.props.modifierSelections
            .filter((selection) => selection.Modifier === modifier.id)
            .map((val) =>
              val.Type === ModifierTypes.FreeText ? val.Name : val.KeyRef,
            );
        }
        return (
          <ProductModifier
            key={index}
            showCalories={!dna?.isDisabled && dna?.showCalories}
            productModifier={modifier}
            selectionChanged={this.modifierSelectionChanged}
            sectionCalories={this.state.modifierSectionCalories}
            selectedValues={selections}
            showAsKiloCalories={dna?.showCaloriesAsKilocalories}
          />
        );
      });
    }

    let quantityContainer = null;
    if (!this.props.edit) {
      quantityContainer = (
        <View style={productDetailsStyles().quantityContainer}>
          <DashedLine
            dashThickness={PixelRatio.roundToNearestPixel(1)}
            dashGap={PixelRatio.roundToNearestPixel(4)}
            dashLength={PixelRatio.roundToNearestPixel(3)}
            dashColor={dashLineColor()}
            style={styles.dashStyle}
          />
          <View style={productDetailsStyles().quantitySelectionView}>
            <Text
              accessible={true}
              accessibilityLabel="Quantity"
              aria-label="Quantity"
              style={productDetailsStyles().quantityLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
            >
              {Localized.Labels.quantity}
            </Text>
            <Spinner
              btnFontSize={Styles.Fonts.f4}
              fontSize={Styles.Fonts.f3}
              showBorder={false}
              min={1}
              default={this.state.quantity}
              color={Styles.primaryColor}
              onNumChange={(num) => {
                this.setState({quantity: num});
              }}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            />
          </View>
        </View>
      );
    }

    let description = null;

    if (this.props.product.description) {
      description = (
        <Text
          accessible={true}
          accessibilityLabel={this.props.product.description}
          aria-label={this.props.product.description}
          style={productDetailsStyles().description}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
        >
          {this.props.product.description}
        </Text>
      );
    }

    let actionButtonText = Localized.Buttons.add_item;
    if (this.props.edit && modifiers) {
      actionButtonText = Localized.Buttons.update_item;
    }

    let actionButton = (
      <RoundedAppButton
        isDisabled={!this.hasRequiredSelections()}
        accessibilityLabelValue="Add item"
        aria-label="Add item"
        aria-disabled={this.state.actionButtonPressed}
        buttonType={RoundedAppButtonType.Solid}
        titleText={actionButtonText}
        onPress={this.addItemClick}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        buttonContainerStyle={styles.actionButtonStyle}
        buttonViewStyle={{
          paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
          backgroundColor: roundedButtonColor(),
        }}
        textStyle={{marginBottom: 1}}
        inActiveButtonViewStyle={{
          backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
          paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
        }}
      />
    );

    if (this.props.edit && !modifiers) {
      actionButton = null;
    }

    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={productDetailsTitle()}
      >
        <View style={productDetailsStyles().container}>
          <ScrollView>
            {productDetailsImageView(imageUri)}
            <View style={productDetailsStyles().content}>
              <View style={productDetailsStyles().infoContainer}>
                <Text
                  accessible={true}
                  accessibilityLabel={this.props.product.name}
                  aria-label={this.props.product.name}
                  style={productDetailsStyles().title}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
                >
                  {this.props.product.name}
                </Text>

                <View style={{flexDirection: 'row'}}>
                  <AVFormattedCurrency
                    style={productDetailsStyles().productPrice}
                    value={this.state.updatedPrice}
                    accessibilityLabel={`${formatCurrency(
                      this.props,
                      this.state.updatedPrice,
                      AccountStore.getCurrency(),
                    )}`}
                    currency={AccountStore.getCurrency()}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
                  />

                  {(dna?.showCalories === true ||
                    dna?.showColorCodes === true) &&
                    this.props.product?.calories !== null && (
                      <View style={productDetailsStyles().verticalLine} />
                    )}
                  {dna?.showCalories === true &&
                    this.props.product?.calories !== null &&
                    calorieSection}

                  {dna?.showColorCodes === true &&
                    this.props.product?.calories !== null &&
                    healthGradeSection}
                </View>
                {nutritionButton}
              </View>

              <View style={productDetailsStyles().descriptionContainer}>
                {description}
              </View>

              <View style={productDetailsStyles().customizationSection}>
                {modifiers}
                {quantityContainer}
              </View>
              <View style={styles.bottom} />
            </View>
          </ScrollView>
          {actionButton}
        </View>

        {!Settings.is365Pay() && (
          <CheckoutBar
            location={this.props.location}
            strings={Localized}
            onPress={this.onCartPressed}
          />
        )}
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  bottom: {
    height: Styles.Spacing.m6,
    width: '100%',
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginVertical: Styles.Spacing.m3,
    overflow: 'hidden',
    width: Settings.is365Pay() ? '85%' : '100%',
    marginHorizontal: Settings.is365Pay()
      ? Styles.Spacing.m2 + Styles.Spacing.m3
      : 0,
  },
  actionButtonStyle: {
    right: Styles.Spacing.m3,
    position: 'absolute',
    bottom: Styles.Spacing.m3 + 6,
  },
});
export default withForwardedNavigationParams<ProductDetailScreenProps>()(
  ProductDetailScreen,
);
