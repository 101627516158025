import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function GiftIconHistory() {
  return (
    <Svg width={54} height={54} viewBox="0 0 54 54" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.455 27v16.364a4.09 4.09 0 01-4.091 4.09H10.636a4.09 4.09 0 01-4.09-4.09V27A2.045 2.045 0 014.5 24.954v-6.136a4.09 4.09 0 014.09-4.09h6.485a6.135 6.135 0 01-.348-2.046 6.136 6.136 0 016.137-6.137c2.045 0 3.845 1.023 4.97 2.537v-.02L27 10.635l1.166-1.575v.02c1.125-1.513 2.925-2.536 4.97-2.536a6.136 6.136 0 016.137 6.137c0 .716-.123 1.411-.348 2.045h6.484c2.26 0 4.091 1.832 4.091 4.091v6.136c0 1.13-.916 2.046-2.045 2.046zM10.636 43.364h14.319V27H10.635v16.364zM43.364 27v16.364H29.046V27h14.318zm-22.5-16.364a2.045 2.045 0 100 4.091 2.045 2.045 0 000-4.09zm10.227 2.046a2.045 2.045 0 114.09 0 2.045 2.045 0 01-4.09 0zm-22.5 6.136v4.091h16.364v-4.09H8.59zm20.455 4.091v-4.09h16.363v4.09H29.046z"
        fill="#111"
      />
    </Svg>
  );
}

export default GiftIconHistory;
