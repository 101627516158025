enum AppRoutes {
  Home = 'Promotions',
  Scan = 'Scan',
  ScanAndPay = 'ScanAndPay',
  SearchProducts = 'SearchProducts',
  Account = 'Account',
  AccountFunding = 'AccountFunding',
  AccountSetupAutoFund = 'AccountSetupAutoFund',
  PantryMarketSearch = 'PantryMarketSearch',
  InsufficientCredit = 'InsufficientCredit',
  ScanMarketCard = 'ScanMarketCard',
  MarketCardRegistration = 'MarketCardRegistration',
  RegistrationEnterPinScreen = 'RegistrationEnterPinScreen',
  ExistingAccountWarningScreen = 'ExistingAccountWarningScreen',
  MarketCardNotUniqueScreen = 'MarketCardNotUniqueScreen',
  EnterPin = 'EnterPin',
  PayrollDeduct = 'PayrollDeduct',
  ScanPay365ReceiptScreen = 'ScanPay365ReceiptScreen',
  ProductCategorySearch = 'ProductCategorySearch',
  PrivacyPolicy = 'PrivacyPolicy',
  WebContent = 'WebContent',
  RefundSummary = 'RefundSummary',
  EatItOrDeleteItWelcome = 'EatItOrDeleteItWelcome',
  ProductFeedbackInstruction = 'ProductFeedbackInstruction',
  FeedbackLocationScan = 'FeedbackLocationScan',
  ProductFeedback = 'ProductFeedback',
  ProductFeedbackComplete = 'ProductFeedbackComplete',
  Promotions = 'Promotions',
  Passcode = 'Passcode',
  Vending = 'Vending',
  PurchaseHistoryDetail = 'PurchaseHistoryDetail',
  ReferralSummary = 'ReferralSummary',
  AccountSetup = 'AccountSetup',
  PurchaseHistory = 'PurchaseHistory',
  CreditCardStripe = 'CreditCardStripe',
  CreditCard = 'CreditCard',
  CreditCardAdd = 'CreditCardAdd',
  ResetPin = 'ResetPin',
  ChangePassword = 'ChangePassword',
  Login = 'Login',
  Welcome = 'Welcome',
  NeverUsedApp = 'NeverUsedApp',
  LinkExistingAccount = 'LinkExistingAccount',
  Redirect = 'Redirect',
  Receipt = 'Receipt',
  Cards = 'Cards',
  AddEGiftCard = 'AddEGiftCard',
  AddEGiftCardManual = 'AddEGiftCardManual',
  ContactUs = 'ContactUs',
  CreditCardDetail = 'CreditCardDetail',
  Environment = 'Environment',
  Main = 'Main',
  MainConsumer = 'MainConsumer',
  MyModal = 'MyModal',
  AutoReloadPanel = 'AutoReloadPanel',
  ProductList = 'ProductList',
  MainProduct = 'MainProduct',
  ProductNutrition = 'ProductNutrition',
  EnteredEmail = 'EnteredEmail',
  KioskCreateAccount = 'KioskCreateAccount',
  KioskCreatePassword = 'KioskCreatePassword',
  ManageAccount = 'ManageAccount',
  CountrySelection = 'CountrySelection',
  ItalyAccountInfo = 'ItalyAccountInfo',
  ChangeEmail = 'ChangeEmail',
  CheckEmail = 'CheckEmail',
  ScanKioskQR = 'ScanKioskQR',
  CreateAccountEnterEmail = 'CreateAccountEnterEmail',
  KioskChangeEmail = 'KioskChangeEmail',
  Rewards = 'Rewards',
  QRCode = 'QRCode',
  Connect = 'Connect',
  App = 'App',
  Machines = 'Machines',
  ExistingAccount = 'ExistingAccount',
  Demographic = 'Demographic',
  Shop = 'Shop',
  MessageDetail = 'MessageDetail',
  Inbox = 'Inbox',
  Loading = 'Loading',
  Funding = 'Funding',
  Settings = 'Settings',
  Help = 'Help',
  DealDetail = 'DealDetail',
  LocationCode = 'LocationCode',
  PickupTime = 'PickupTime',
  Menu = 'Menu',
  ProductDetail = 'ProductDetail',
  ProductUpsell = 'ProductUpsell',
  CoffeeMachine = 'CoffeeMachine',
  PickupLocation = 'PickupLocation',
  Disclaimer = 'Disclaimer',
  RecentOrders = 'RecentOrders',
  Feedback = 'Feedback',
  BunnDispense = 'BunnDispense',
  EditMarketCard = 'EditMarketCard',
  ScanKioskQrCode = 'ScanKioskQrCode',
  PayrollDeductAgreement = 'PayrollDeductAgreement',
  VendorsExchangeVending = 'VendorsExchangeVending',
  Referral = 'Referral',
  ReferralLanding = 'ReferralLanding',
  AccountReady = 'AccountReady',
  ShareReceipt = 'ShareReceipt',
  GiftFeed = 'GiftFeed',
  SnackBlockedUsers = 'SnackBlockedUsers',
  SendSnack = 'SendSnack',
  ServerErrorDialog = 'ServerErrorDialog',
  ChooseContact = 'ChooseContact',
  SnackDetail = 'SnackDetail',
  ReferralDetail = 'ReferralDetail',
  Balances = 'Balances',
  DeleteAccount = 'DeleteAccount',
  OrderNote = 'OrderNote',
  Empty = '',
  DetectedBeacon = 'DetectedBeacon',
  AppTourWelcome = 'AppTourWelcome',
  AppTour = 'AppTour',
  AppProductTour = 'AppProductTour',
  ViewNutrition = 'ViewNutrition',
  ScanDiscountCode = 'ScanDiscountCode',
  EnterDiscountCode = 'EnterDiscountCode',
  History = 'HistoryScreen',
  NewHome = 'NewHome',
  CampusLocations = 'CampusLocations',
  NewPickupLocation = 'NewPickupLocation',
  NewPickupTime = 'NewPickupTime',
  RevolveHome = 'RevolveHome',
  ScanAccount = 'ScanAccount',
  Offers = 'Offers',
  OffersScreen = 'OffersScreen',
  OfferDetailsScreen = 'OfferDetailsScreen',
  HistoryDetailsScreen = 'HistoryDetailsScreen',
  PDCards = 'PDCards',
  WalletTabHome = 'Wallet',
  SplashRevolve = 'SplashRevolve',
  AppTourRevolve = 'AppTourRevolve',
  ChooseLocation = 'ChooseLocation',
  ReferralReadyScreen = 'ReferralReadyScreen',
  HomeTab = 'Home',
  ScanAndPayLocations = 'ScanAndPayLocations',
  SnackHistoryDetail = 'SnackHistoryDetail',
  PurchaseReceipt = 'PurchaseReceipt',
  PurchaseHistoryDetailNew = 'PurchaseHistoryDetailNew',
  MarketHistoryList = 'Market Purchases',
  MobilePurchaseList = 'Mobile Purchases',
  SignUpRewardsHistoryList = 'Sign-Up Rewards',
  SendASnackHistoryList = 'Send a Snack',
  ReferralsHistoryList = 'Referral Reward',
  OrderAheadHistoryList = 'Order Ahead',
  notification = 'Notifications',
  ProductUpsellSheet = 'ProductUpsellSheet',
}

export default AppRoutes;
export type RootRoutes =
  | AppRoutes.Login
  | AppRoutes.Passcode
  | AppRoutes.App
  | AppRoutes.Loading
  | AppRoutes.AppProductTour
  | AppRoutes.Welcome
  | AppRoutes.SplashRevolve;
