import React from 'react';
import {View, StyleSheet, Platform} from 'react-native';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AccountHistoryList from 'src/components/elements/account/AccountHistoryList';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';
import type {HistoryItem as HistoryItemType} from 'src/types/TransactionDetail';
import NewHistoryItem from './NewHistoryItem';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';
import AVText from 'src/components/elements/AVText';

type SectionViewProps = {
  title: string;
  data: HistoryItemType[];
  indexNo: string | number;
  testId?: string;
};

const getBGColor = () => {
  if (Platform.OS == 'web') {
    return Styles.white;
  } else if (Settings.is365Pay()) {
    return Styles.tabBarBackgroundColor;
  } else {
    return Styles.white;
  }
};

const HistorySectionView = (props: SectionViewProps) => (
  <View key={`${props.title}${props.indexNo}`} testID={props.testId}>
    {props.data.length > 0 ? (
      <View style={styles.sectionHeaderView}>
        <AVText
          style={[
            getDescriber()['historyListStyleDescriptor']()[
              'historyHeaderTitle'
            ],
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
          accessible={true}
          accessibilityLabel={`${props.title}, `}
          aria-label={`${props.title}, `}
        >
          {props.title}
        </AVText>

        <View
          style={[
            styles.itemContainer,
            Settings.isRevolve() && styles.shadowStyles,
            Settings.is365Pay() && Styles.Style.shadow,
          ]}
        >
          {props.data &&
            props.data.map((item, index) => (
              <View
                style={[styles.historyContainer]}
                key={`${index} ${item.Id}`}
                accessibilityLabel={item.Id}
                testID={item.Id}
              >
                <NewHistoryItem
                  index={index}
                  length={props.data && props.data.length}
                  {...props}
                  historyItem={item}
                  onPress={(newHistoryItem) =>
                    Settings.isRevolve()
                      ? NavActions.push(AppRoutes.HistoryDetailsScreen, {
                          transactionId: newHistoryItem.Id,
                          transactionType: newHistoryItem.Type,
                          transactionDate: newHistoryItem.TransDate,
                          transactionCurrency: newHistoryItem.currency,
                          transactionTypeDisplay:
                            newHistoryItem.TransactionTypeDisplay,
                          newHistoryItem,
                        })
                      : AccountHistoryList.onItemPress(newHistoryItem, true)
                  }
                />
              </View>
            ))}
        </View>
      </View>
    ) : null}
  </View>
);
const styles = StyleSheet.create({
  itemContainer: {
    flex: 1,
    borderWidth: Settings.isRevolve() ? 0 : 1,
    borderColor: Settings.is365Pay()
      ? Styles.Colors.PayNew.neutralHuesBase11
      : Styles.borderColor,
    borderRadius: Settings.isRevolveAnd365Pay()
      ? Styles.Spacing.m2
      : Styles.Heights.h1,
    marginBottom: 3,
    padding: Styles.Spacing.m1,
  },
  shadowStyles: {
    backgroundColor: Styles.white,
    elevation: 6,
    shadowColor: '#000',
    shadowOffset: {width: Styles.Spacing.m1, height: Styles.Spacing.m1},
    shadowOpacity: 0.1,
    shadowRadius: Styles.Spacing.m1,
    borderBottomWidth: 0,
  },
  sectionHeaderView: {
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3 + 3,
    backgroundColor: getBGColor(),
  },
  sectionHeader: {
    fontWeight: '700',
    fontSize: Styles.Fonts.sectionHeader,
    paddingBottom: Styles.Spacing.m3,
    paddingTop: Styles.Heights.h2,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  historyContainer: {},
});
export default HistorySectionView;
