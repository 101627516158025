import React from 'react';
import {
  StyleSheet,
  View,
  PixelRatio,
  TouchableOpacity,
  Platform,
} from 'react-native';
import type {BundleType} from 'src/types/TransactionDetail';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import {CartItemModifier} from 'src/types/Cart';
import AccountStore from 'src/stores/AccountStore';
import SupLeafIcon from 'src/components/img/svg/SupLeafIcon';
import AVText from 'src/components/elements/AVText';
import TrashIcon from 'src/components/img/svg/TrashIcon';
import TagLightIcon from 'src/components/img/svg/TagLightIcon';
import DeleteIcon from 'src/components/img/svg/DeleteIcon';
type CartBundleProps = {
  trashClick?: (lineNumbers: Array<number>) => void;
  bundle: BundleType;
};

const CartItemBundle = (props: CartBundleProps): JSX.Element => {
  const cartTrashClick = (lineNumbers: Array<number>) => {
    props.trashClick?.(lineNumbers);
  };

  const totalDiscount = props.bundle.items.reduce((prev, cur) => {
    if (cur.Discount) {
      return prev + parseFloat(cur.Discount) * 1;
    } else {
      return prev;
    }
  }, 0);

  const totalPrice = props.bundle.items.reduce((prev, cur) => {
    const price = parseFloat(cur.Price ?? '');
    let modifierPrice = 0;

    if (cur.Modifiers) {
      modifierPrice = cur.Modifiers.reduce(
        (prevModifierPrice: number, modifier: CartItemModifier) => {
          if (modifier.Price) {
            return prevModifierPrice + parseFloat(modifier.Price.toString());
          } else {
            return prevModifierPrice;
          }
        },
        0,
      );
    }

    return prev + price + modifierPrice;
  }, 0);

  const items = props.bundle.items.map((item) => {
    const icon = (
      <View style={styles.tag}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          accessibilityRole="text"
          accessible={true}
          accessibilityLabel={item.Description}
          aria-label={item.Description}
          style={styles.tagText}
          numberOfLines={3}
          ellipsizeMode="tail"
        >
          {`${item.Description || ''}`}
        </AVText>
        <DeleteIcon style={styles.promoDeleteIcon} />
      </View>
    );
    return (
      <>
        <TouchableOpacity
          accessible={true}
          accessibilityLabel="Cart Promo Delete product"
          accessibilityRole="button"
          accessibilityHint={`Cart Promo Double tap to delete ${
            item?.Name ?? ' this item '
          } from your cart`}
          role="button"
          aria-label="Cart Promo Delete product"
          style={styles.categoryButton}
          onPress={() => cartTrashClick([item.LineNumber || -1])}
        >
          {icon}
        </TouchableOpacity>
      </>
    );
  });

  const _totalSupFee = props.bundle.items.reduce(
    (acc, detail) => acc + (detail.TransactionChargeAmount || 0),
    0,
  );

  return (
    <View style={{marginTop: Styles.Spacing.m2}}>
      <View style={styles.promoContainer}>
        <View style={[styles.column, styles.promoTrashIcon]}>
          {Platform.OS !== 'web' && (
            <TouchableOpacity
              accessible={true}
              accessibilityLabel="Delete bundle products"
              accessibilityHint={'Delete bundle products from your cart'}
              onPress={() =>
                cartTrashClick(
                  props.bundle.items.map((item) => item.LineNumber || -1),
                )
              }
            >
              <TrashIcon size={44} color={Styles.footerTabActive} />
            </TouchableOpacity>
          )}
        </View>
        <View style={[styles.column, styles.promoDescriptionMain]}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
            accessibilityRole="text"
            accessible={true}
            accessibilityLabel={Localized.Labels.promo_item_text}
            aria-label={Localized.Labels.promo_item_text}
            numberOfLines={1}
            style={[styles.cartSupLine, styles.promoText]}
          >
            {items?.length} {Localized.Labels.promo_item_text}
          </AVText>
          <View style={styles.promoNameItemName}>
            <View style={styles.promoTagIcon}>
              <TagLightIcon />
            </View>
            <View style={styles.promoNameDesc}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                accessible={true}
                accessibilityLabel={`${
                  props.bundle.title
                }, $${totalPrice.toFixed(2)}`}
                accessibilityRole="text"
                aria-label={`${props.bundle.title}, $${totalPrice.toFixed(
                  2,
                )}, text`}
                numberOfLines={6}
                style={[styles.promoDesc, styles.promoDescmargin]}
              >
                {props.bundle.title.substring(0, 30) + ' '}
                (-
                {
                  <AVFormattedCurrency
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    style={styles.promoDesc}
                    value={totalDiscount}
                    currency={AccountStore.getCurrency()}
                    numberOfLines={1}
                  />
                }
                )
              </AVText>
            </View>
          </View>
        </View>
        <View style={[styles.column, styles.promoAmountMainView]}>
          <AVFormattedCurrency
            style={[styles.amount]}
            value={totalPrice}
            accessible={true}
            accessibilityLabel={`amount $${totalPrice}`}
            aria-label={`amount $${totalPrice}, text`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
            currency={AccountStore.getCurrency()}
            numberOfLines={1}
          />
          <AVFormattedCurrency
            style={styles.priceAmountLine}
            value={totalPrice - totalDiscount}
            accessible={true}
            accessibilityLabel={`Discount amount $${
              totalPrice - totalDiscount
            }`}
            aria-label={`Discount amount $${totalPrice - totalDiscount}, text`}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
            currency={AccountStore.getCurrency()}
            numberOfLines={1}
          />
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.supItemView}>{items}</View>
        {!!_totalSupFee && (
          <View style={styles.supMainView}>
            <SupLeafIcon />
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              accessibilityRole="text"
              accessible={true}
              accessibilityLabel={Localized.Labels.sup_fee}
              aria-label={Localized.Labels.sup_fee}
              numberOfLines={1}
              style={styles.cartSupLine}
            >
              {Localized.Labels.sup_fee}
            </AVText>
            <AVFormattedCurrency
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              numberOfLines={1}
              style={styles.cartSupLine}
              accessible={true}
              accessibilityLabel={`Cart SUP amount $${_totalSupFee}`}
              aria-label={`Cart SUP amount $${_totalSupFee}, text`}
              value={_totalSupFee}
              currency={AccountStore.getCurrency()}
            />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  priceAmountLine: {
    color: Styles.Colors.PayNew.black01,
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  promoDesc: {
    color: Styles.Colors.PayNew.success,
    fontWeight: '400',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  promoDescmargin: {
    marginRight: 5,
  },
  amount: {
    textDecorationLine: 'line-through',
    fontWeight: '400',
    fontSize: Styles.Fonts.sectionHeader,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  container: {
    alignSelf: 'stretch',
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m2,
  },
  items: {
    flexDirection: 'row',
  },
  cartSupLine: {
    fontWeight: '300',
    marginLeft: 5,
    color: '#111',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  categoryButton: {
    backgroundColor: '#C3CCE6',
    borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m4),
    height: undefined,
    marginRight: Styles.Spacing.m2,
    padding: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m1,
  },
  promoContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingTop: 10,
  },
  column: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  promoTrashIcon: {
    flex: 0.15,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    marginRight: Styles.Spacing.m2,
  },
  promoDescriptionMain: {
    flex: 0.8,
    justifyContent: 'center',
    left: 8,
    top: -2,
    alignItems: 'flex-start',
    rowGap: 5,
  },
  promoNameItemName: {
    flex: 1,
    justifyContent: 'center',
    left: 8,
    flexDirection: 'row',
    paddingTop: 2,
  },
  promoTagIcon: {
    justifyContent: 'center',
    alignSelf: 'flex-start',
  },
  promoNameDesc: {
    flex: 1,
    flexDirection: 'column',
    left: 5,
  },
  promoAmountMainView: {
    flex: 0.4,
    justifyContent: 'center',
    top: -2,
    alignItems: 'flex-end',
    rowGap: 5,
  },
  supMainView: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  promoText: {
    fontWeight: '400',
    color: '#111',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  supItemView: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
    gap: 10,
  },
  tag: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    flexShrink: 1,
    marginLeft: 5,
  },
  tagText: {
    fontWeight: '400',
    color: '#111',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
    marginRight: 5,
    flexShrink: 1,
    overflow: 'hidden',
  },
  promoDeleteIcon: {
    justifyContent: 'center',
    alignSelf: 'center',
    marginRight: 3,
  },
});
export default CartItemBundle;
