import * as React from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from 'react-native';

import Localized from 'src/constants/AppStrings';
import Styles from '../Styles';
import type {ScreenProps} from 'src/types/Screen';
import {ReferralStatus} from 'src/types/Referral';
import AVText from './AVText';
import Settings from 'src/Settings';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import type {Referral} from 'src/types/Referral';
import UnReadlIcon from 'src/components/img/svg/UnReadIcon';
import {getDescriber} from './descriptor/DescriptorType';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
type ReferralItemProps = ScreenProps &
  WithGlobalizeProps & {
    referral: Referral;
    onPress?: () => void;
  };

class ReferralItem extends React.Component<ReferralItemProps> {
  constructor(props: ReferralItemProps) {
    super(props);
  }

  renderDescription(isSender) {
    const {referral} = this.props;
    const {Labels} = Localized;
    const prefixText = isSender
      ? Labels.formatString(
          Labels.your_friend_joined,
          referral?.receiverName ?? '',
          Util.formatCurrency(
            this.props,
            referral?.amount ?? 0,
            AccountStore.getCurrency(),
          ),
        )
      : Labels.formatString(
          Labels.thanks_for_referral_joining_sign_up,
          Settings.appDisplayName,
          Util.formatCurrency(
            this.props,
            referral?.amount ?? 0,
            AccountStore.getCurrency(),
          ),
        );

    return `${prefixText}`;
  }

  renderStatus(status) {
    if (status === ReferralStatus.Expired) {
      return (
        <AVTouchableOpacity
          style={[
            getDescriber().getSnackItemStyleDescriptor()['expiredBtnText'],
          ]}
        >
          <AVText
            style={[
              styles.status,
              {
                color: Styles.dangerColor,
              },
              getDescriber().getSnackItemStyleDescriptor()[
                'referralStatusText'
              ],
            ]}
          >
            {Localized.Labels.expired}
          </AVText>
        </AVTouchableOpacity>
      );
    }

    if (status === ReferralStatus.Claimed) {
      return (
        <AVTouchableOpacity
          style={getDescriber().getSnackItemStyleDescriptor()['claimedBtnText']}
        >
          <AVText
            style={[
              styles.status,
              {
                color: Styles.Colors.Canteen.darkGreen,
              },
              getDescriber().getSnackItemStyleDescriptor()[
                'referralStatusText'
              ],
            ]}
          >
            {Localized.Labels.claimed}
          </AVText>
        </AVTouchableOpacity>
      );
    }

    return <AVText style={styles.status}>{''}</AVText>;
  }

  renderSubtitle(isSender: boolean) {
    return isSender
      ? Localized.Labels.you_referral_amount
      : Localized.Labels.account_created;
  }
  render() {
    const {referral, onPress} = this.props;
    const isSender = referral.sender === AccountStore.getAccountId();
    const status = isSender ? referral.senderRedeem : referral.receiverRedeem;
    const describerprops = {
      referral,
      isSender,
    };
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          getDescriber().getSnackItemStyleDescriptor()['container'],
          !Settings.isCanteen() && Styles.Style.shadow,
          {borderBottomWidth: StyleSheet.hairlineWidth},
          !Settings.isRevolveAnd365Pay() && {
            borderBottomWidth: 1,
            borderBottomColor: '#9A9A9F',
          },
        ]}
        accessible
        accessibilityLabel="Inbox referral Item"
      >
        {getDescriber().referralImageTemplateDescriptor(describerprops)}
        <View
          style={[
            getDescriber().getSnackItemStyleDescriptor()['messageLeft'],
            Settings.isCanteen() && {right: 10},
          ]}
        >
          {!Settings.isRevolve() && (
            <View style={[styles.titleHeaderView]}>
              {/* <View style={{flexDirection: 'row'}}> */}
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={[
                  getDescriber().getSnackItemStyleDescriptor()['referalTitle'],
                  styles.statusText,
                ]}
              >
                {isSender
                  ? Localized.Labels.referral
                  : Localized.Labels.referral_sign_up}
              </AVText>
              <View
                style={{
                  flexDirection: 'row', // horizontal layout (you can change this to 'column' for vertical layout)
                  alignItems: 'center', // vertically centers the items
                  justifyContent: 'flex-start', // ensures elements are aligned to the left
                }}
              >
                {this.renderStatus(status)}

                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                  style={[
                    getDescriber().getSnackItemStyleDescriptor()[
                      'referralDate'
                    ],
                    styles.commonRow,
                  ]}
                >
                  {getDescriber().referralItemDateDescriptor(describerprops)}
                </AVText>
              </View>
            </View>
          )}

          {
            <AVText
              style={getDescriber().getSnackItemStyleDescriptor()['subject']}
              numberOfLines={getDescriber().snackItemNumberDescriptor()}
            >
              {this.renderSubtitle(isSender)}
            </AVText>
          }
          <AVText
            style={[
              getDescriber().getSnackItemStyleDescriptor()['message'],
              Platform.OS === 'web'
                ? styles.referralItemViewWeb
                : styles.referralItemView,
            ]}
            numberOfLines={getDescriber().snackItemNumberDescriptor()}
          >
            {this.renderDescription(isSender)}
          </AVText>
        </View>

        {Settings.isRevolveAnd365Pay() ? (
          <View
            style={getDescriber().getSnackItemStyleDescriptor()['chevronRight']}
            accessible
            accessibilityLabel="Snack Item Icon"
          >
            <ChevronRight />
          </View>
        ) : (
          <View
            style={[
              styles.arrow,
              styles.commonRow,
              !referral.isRead && {
                left: -10,
              },
            ]}
            accessible
            accessibilityLabel="Snack Item Icon"
          >
            <View style={{left: -3}}>
              {!referral.isRead && <UnReadlIcon />}
            </View>
            <ChevronRight />
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m3,
    paddingRight: Styles.Spacing.m3 + Styles.Spacing.m2,
    flex: 1,
    flexDirection: 'row',
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
    minHeight: Styles.Heights.touchTargetHeight2,
  },
  starContainer: {
    justifyContent: 'center',
  },
  mainContent: {
    flex: 1,
    marginStart: Styles.Spacing.m2,
    marginEnd: Styles.Spacing.m3,
  },
  topSection: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  bottomSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Styles.Spacing.m1,
  },
  status: {
    fontWeight: '600',
    flex: 1,
    fontSize: Styles.Fonts.f0,
    textTransform: 'capitalize',
    justifyContent: 'center',
  },
  title: {
    fontSize: Settings.isNewUI ? Styles.Fonts.f7 : Styles.Fonts.f1,
    fontWeight: '600',
    color: Styles.darkColor,
  },
  description: {
    flex: 1,
    fontSize: Settings.isNewUI ? Styles.Fonts.f7 : Styles.Fonts.f0,
    marginEnd: Styles.Spacing.m5,
    marginBottom: Styles.Spacing.m1,
  },
  date: {
    color: Styles.lightGray,
    fontSize: Settings.isNewUI ? Styles.Fonts.f1 : Styles.Fonts.f0,
  },
  titleHeaderView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  commonRow: {
    flexDirection: 'row',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  arrow: {
    width: 24,
    height: 24,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  statusText: {
    textAlign: 'auto',
    paddingRight: 16,
  },
  referralItemViewWeb: {
    flex: 1,
    width: '90%',
  },
  referralItemView: {
    flex: 0.6,
    width: '90%',
  },
});
export default withGlobalize(ReferralItem);
