import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import React from 'react';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import Styles from '../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import Localized from 'src/constants/AppStrings';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import BaseAccountSetupScreenRedesign from 'src/components/screens/BaseAccountSetupScreenRedesign';
import AVText from 'src/components/elements/AVText';
import {getDescriber} from '../descriptor/DescriptorType';
import AppRoutes from 'src/AppRoutes';

type EnterPinProps = {
  code: string;
  data: {id: string; email: string};
  locationId?: string;
  onSuccess: () => void;
  overrideEmail: boolean;
};
type EnterPinState = {
  pin: string;
  errorMessage: string;
};

const {getOnBoardingButton, actionLinkText} = getDescriber();

class RegistrationEnterPin extends React.Component<
  EnterPinProps,
  EnterPinState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: EnterPinProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.state = {
      pin: '',
      errorMessage: '',
    };
  }

  async logout() {
    this.context.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    this.context.actions.hideSpinner();
    this.context.actions.showModal(
      Localized.Success.entered_market_card,
      Localized.Success.success,
      Localized.Buttons.ok,
      Styles.Colors.PayNew.primary01,
      2,
      false,
      false,
      undefined,
      () => {
        NavActions.reset(AppRoutes.Welcome, {
          shouldAutoLogin: true,
          autoLoginEmail: this.props.data.email,
        });
      },
    );
  }

  handleBack() {
    NavActions.pop();
  }

  async handleClick() {
    this.context.actions.showSpinner();

    const errorMessage = this.validate(this.state.pin);

    FirebaseAnalytic.trackEvent('handleClick', 'EnterPinScreen', {
      ...this.props,
      ...this.state,
      errorMessage,
    });

    if (errorMessage) {
      this.setState({errorMessage});
      this.context.actions.hideSpinner();
    } else {
      try {
        const response =
          await ActionsFactory.getAccountActions().validatePinAndMergeAccounts(
            this.props.data.id,
            this.state.pin,
            this.props.code,
            this.props.locationId,
            this.props.overrideEmail,
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'verifyPinScanCode',
          JSON.stringify({}),
          JSON.stringify(response),
        );

        FirebaseAnalytic.trackEvent(
          'handleClick verifyPinScanCode',
          'EnterPinScreen',
          {
            ...this.props,
            ...this.state,
            response,
          },
        );

        if (response.sourceAccountId === this.props.data.id) {
          this.logout();
        } else if (response.statusCode === 412) {
          const message = JSON.stringify(response.message);
          const regex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
          const emailMatch = message.match(regex);
          const existingEmail = emailMatch.find((x) => x);

          NavActions.push(AppRoutes.ExistingAccountWarningScreen, {
            email: existingEmail,
            overrideEmail: this.props.data.email,
            onSuccess: this.props.onSuccess,
            code: this.props.code,
            accountId: this.props.data.id,
            pin: this.state.pin,
            locationId: this.props.locationId,
          });
        } else {
          this.setState({errorMessage: Localized.Errors.invalid_pin});
        }
      } catch (e) {
        if (!e.showMessage) {
          const guid = await uuid.getRandomUUID();
          CrashlyticsEvents.log(
            'Exception',
            'EnterPinScreen:HandleClick',
            e.message ? e.message : e.toString(),
            guid,
          );
          Events.Error.trackEvent(
            'Exception',
            'EnterPinScreen:HandleClick',
            e.message ? e.message : e.toString(),
            guid,
          );
          this.setState({errorMessage: Localized.Errors.invalid_pin});
        } else {
          this.setState({errorMessage: e.message});
        }
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'verifyPinScanCode',
          JSON.stringify({}),
          JSON.stringify(e),
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  validate(pin: string) {
    if (!pin) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  render() {
    return (
      <BaseAccountSetupScreenRedesign
        headlineText={Localized.Labels.pin_required}
        instructionText={Localized.Labels.pin_recognized}
      >
        <View style={styles.content}>
          <View style={styles.inputsContainer}>
            <View style={styles.spacing}>
              <AllyTextInput
                label={Localized.Labels.pin}
                value={this.state.pin}
                accessible={true}
                accessibilityLabel={Localized.Labels.pin}
                accessibilityValue={{text: this.state.pin}}
                onChangeText={(text) =>
                  this.setState({
                    pin: text,
                    errorMessage: '',
                  })
                }
                secureTextEntry
                onSubmitEditing={this.handleClick}
                returnKeyType="done"
                style={styles.input}
                errorMessage={this.state.errorMessage}
                hasError={this.state.errorMessage !== ''}
              />
            </View>
            <View style={styles.spacing}>
              {getOnBoardingButton(Localized.Buttons.next, this.handleClick)}
            </View>
          </View>
          <View style={styles.buttonAndTexts}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginTop: Styles.Spacing.m2,
                alignItems: 'center',
              }}
            >
              <AVText style={styles.forgotPin}>
                {Localized.Labels.forgot_pin}
              </AVText>
              <TouchableOpacity
                onPress={() => {
                  NavActions.push(AppRoutes.MarketCardRegistration, {
                    onSuccess: this.props.onSuccess,
                  });
                }}
                style={styles.scanQRCodeLinkContainer}
              >
                <AVText style={actionLinkText()['actionLinkText']}>
                  {Localized.Buttons.scan_qr_code_instead}
                </AVText>
              </TouchableOpacity>
            </View>

            <AVText style={styles.forgotPinDescription}>
              {Localized.Labels.reset_pin_description}
            </AVText>
          </View>
        </View>
      </BaseAccountSetupScreenRedesign>
    );
  }
}

const styles = StyleSheet.create({
  buttonAndTexts: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: Styles.Spacing.m3,
    alignSelf: 'stretch',
    paddingBottom: 252,
  },
  buttonContainer: {
    alignSelf: 'center',
    width: '100%',
  },
  forgotPin: {
    color: Styles.Colors.PayNew.black01,
    fontSize: 16,
    fontWeight: '400',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.aeonikRegular,
    marginRight: Styles.Spacing.m1,
  },
  forgotPinDescription: {
    color: Styles.Colors.PayNew.black01,
    fontSize: 16,
    fontWeight: '400',
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.aeonikRegular,
    marginRight: Styles.Spacing.m1,
    fontStyle: 'italic',
  },
  input: {
    alignSelf: 'stretch',
    backgroundColor: Styles.bgColor,
    borderColor: Styles.white,
    borderRadius: 8,
    borderWidth: 1,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    height: Styles.Heights.h4,
    paddingHorizontal: Styles.Spacing.m3,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  scanQRCodeLinkContainer: {
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m5,
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
  negativeBtn: {
    borderColor: Styles.white,
    borderWidth: 1,
    height: Styles.Heights.h5,
    marginVertical: Styles.Spacing.m2,
  },
  override: {
    height: Styles.Heights.h5,
    marginTop: Styles.Spacing.m2,
  },
  content: {
    alignSelf: 'stretch',
  },
  spacing: {
    marginBottom: Styles.Spacing.m3,
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<EnterPinProps>()(
  RegistrationEnterPin,
);
