import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import BackSubheader from 'src/components/elements/BackSubheader';
import {View} from 'react-native';
import GiftBox from 'src/components/img/svg/GiftBox';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import React from 'react';
import {SendSnackDescriberData} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Settings from 'src/Settings';
import {WithGlobalizeProps} from 'react-native-globalize';

export function getBackSubHeaderDescriptor(): JSX.Element {
  return (
    <BackSubheader
      style={{
        backgroundColor: Styles.darkBlueRevolveColor,
        color: Styles.white,
      }}
      title={Localized.Buttons.send_a_snack}
    >
      <View style={getSendSnackStyleDescriptor()['emptyContainer']} />
    </BackSubheader>
  );
}

export function getTopHeaderSnackDescriptor(
  snackProps: SendSnackDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <GiftBox
        width={Styles.Heights.h4}
        height={Styles.Heights.h4}
        color={Styles.darkGreenRevolve}
      />
      <AVText
        style={getSendSnackStyleDescriptor()['appTitleStyle']}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
      >
        {snackProps.isSender
          ? Localized.Labels.your_snack_sent
          : Localized.Labels.formatString(
              Localized.Labels.sent_you_snack,
              snackProps.senderName,
            )}
      </AVText>
    </React.Fragment>
  );
}

export function getSnackStickerDescriptor(): JSX.Element {
  return null;
}

export function unblockButtonType() {
  return ButtonType.normal;
}

export function titleDescriptor(): string {
  return Localized.Buttons.send_a_snack;
}

export function blockUserBtnStyle() {
  return null;
}
export function snackDetailsBtnFontSTyle() {
  return {fontFamily: Styles.FontFamily.figtreeRegular};
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.darkBlueRevolveColor,
    color: Styles.white,
  };
}
export function acceptButtonColor() {
  return Styles.darkGreenRevolve;
}
export function blockUnblockButtonColor() {
  return null;
}

export function renderSnackMsgDescriptor(
  props: SendSnackDescriberData & WithGlobalizeProps,
): string | string[] {
  return Localized.Labels.formatString(
    Localized.Labels.you_have_received_a_snack,
    Util.formatCurrency(props, props.amount, AccountStore.getCurrency()),
    props.senderName,
  );
}

export function getAcceptSnackBtn(props, appContext) {
  return (
    <RoundedButton
      accessibilityLabel="Accept Snack"
      buttonType={ButtonType.action}
      color={Settings.isRevolve() && Styles.darkGreenRevolve}
      text={Localized.Buttons.accept_snack}
      onPress={() =>
        props.acceptSnack({
          snackDetails: props.snackDetails,
          context: appContext,
        })
      }
    />
  );
}

export function getSendSnackStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topRow: {
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m2,
      alignItems: 'center',
    },
    senderSnackStatusText: {
      color: Styles.black,
      flex: 1,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
    },
    appTitleStyle: {
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
      marginLeft: Styles.Spacing.m3,
      flex: 1,
    },
    content: {
      padding: Styles.Spacing.m3,
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    emptyContainer: {
      backgroundColor: Styles.white,
      flex: 1,
    },

    dateStyle: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      marginRight: Styles.Spacing.m2,
    },
    description: {
      color: Styles.black,
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f1,
      flex: 1,
    },
    message: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
      color: Styles.black,
      flex: 1,
    },
    receiverSnackStatusText: {
      color: Styles.black,
      marginTop: Styles.Spacing.m2,
      flex: 1,
      fontFamily: Styles.FontFamily.robotoRegular,
      textAlign: 'center',
      fontSize: Styles.Fonts.f1,
    },
    amount: {
      marginTop: Styles.Spacing.m4,
      color: Styles.black,
      fontSize: 42,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    btnBlockUser: {
      alignSelf: 'center',
      marginTop: Styles.Spacing.m3,
    },
    btnBlockUserText: {},
    btnAcceptSnack: {},
  };
}

export default {
  getBackSubHeaderDescriptor,
  getTopHeaderSnackDescriptor,
  getSendSnackStyleDescriptor,
  blockUserBtnStyle,
  backHeaderStyleDescriptor,
  titleDescriptor,
  blockUnblockButtonColor,
  snackDetailsBtnFontSTyle,
  acceptButtonColor,
  getSnackStickerDescriptor,
  getAcceptSnackBtn,
  renderSnackMsgDescriptor,
  unblockButtonType,
};
