import React from 'react';
import {StyleSheet, Image, View, StyleProp, ViewStyle} from 'react-native';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import IconWithBadge from '../IconWithBadge';
import Styles from '../../Styles';
import RewardSection from '../RewardSection';
import type {RewardType} from 'src/types/Rewards';
import PromotionsHeader from '../promotions/PromotionsHeader';
import BalanceContainer from '../../elements/BalanceContainer';
import AVText from '../../elements/AVText';
import Settings from 'src/Settings';
import {getDescriber} from '../../descriptor/DescriptorType';
const scrollRangeForAnimation = 180;

class DefaultUIManager {
  getShopIcon(tintColor: string, shopBadgeCount: number) {
    return (
      <IconWithBadge
        iconName="store-alt"
        tintColor={tintColor}
        size={Styles.Fonts.f2}
        badgeCount={shopBadgeCount}
      />
    );
  }

  getShopLabel() {
    return Localized.Labels.shop;
  }

  getInboxLabel() {
    return Localized.Labels.inbox;
  }

  getShopTitle() {
    return Localized.Labels.shop;
  }

  getHelpLabel() {
    return Localized.Labels.help;
  }

  getHomeRewardSection(
    rewards: number,
    rewardSteps: Array<RewardType>,
    style: StyleProp<ViewStyle>,
    isRewardButtonShown: boolean,
  ) {
    return (
      <RewardSection
        key={-1}
        style={style}
        rewards={rewards}
        rewardSteps={rewardSteps}
        isRewardButtonShown={isRewardButtonShown}
      />
    );
  }

  getPromotionsHeader(
    qrCode: string,
    balance: number,
    animationScrollY: any,
    strings: typeof Localized,
    isConnected: boolean,
    _1: any,
    _2: any,
  ) {
    return (
      <PromotionsHeader
        qrCode={qrCode}
        balance={balance}
        hideBalance={false}
        animationScrollY={animationScrollY}
        strings={strings}
        scrollRangeForAnimation={scrollRangeForAnimation}
        isConnected={isConnected}
      />
    );
  }

  getScrollRangeForAnimation() {
    return scrollRangeForAnimation;
  }

  getBalanceContainer(
    smallText: boolean,
    strings: typeof Localized,
    isButton = true,
  ) {
    return (
      <BalanceContainer
        smallText={smallText}
        strings={strings}
        isButton={isButton}
        primaryColor={Settings.isNewUI()}
      />
    );
  }

  shouldHideFunding() {
    return false;
  }

  getLoginLogoImage() {
    return (
      <Image
        style={styles.logoImg}
        source={{
          uri: Styles.mainIconUrl,
        }}
        resizeMode="contain"
      />
    );
  }

  getMarketLogo() {
    return (
      <View style={[styles.marketLogo]}>
        <FontAwesome5Pro
          name="store-alt"
          color={Styles.primaryColor}
          size={Styles.Fonts.f4}
          solid
        />
      </View>
    );
  }

  getWelcomeHeaderImage() {
    return (
      <Image
        style={styles.image}
        accessibilityLabel={'Icon'}
        nativeID="Icon"
        source={{
          uri: Styles.iconUrl,
        }}
        resizeMode="contain"
      />
    );
  }

  getOrderAheadLabel() {
    return Localized.Labels.order_ahead;
  }

  showDemographicScreen() {
    return true;
  }

  getLoginStatusBarStyle() {
    return getDescriber().statusBarStyleDescriptor();
  }

  showAllFAQs() {
    return true;
  }

  getScanLabel(strings: typeof Localized): React.ReactNode | null {
    return (
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
        style={styles.subheaderText}
        accessible={true}
        accessibilityLabel={'Scan this at checkout'}
      >
        {strings.Labels.scan_this_at_checkout}
      </AVText>
    );
  }

  getPromotionsStickyHeaderIndices(): number[] {
    return [];
  }
}

const styles = StyleSheet.create({
  logoImg: {
    alignSelf: 'stretch',
    height: Styles.Heights.h4,
    marginHorizontal: Styles.Spacing.m5,
  },
  marketLogo: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: Styles.Spacing.m3,
    width: Styles.Spacing.m5,
  },
  image: {
    height: Styles.Heights.headerHeight,
    marginVertical: Styles.Spacing.m4,
  },
  subheaderText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m1,
    textAlign: 'center',
  },
});
export default DefaultUIManager;
