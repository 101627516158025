import Api from './Api';
import AccountConstants from 'src/constants/AccountConstants';
import {UrlTypes} from 'src/types/UrlTypes';
const FUNDING_RECEIPT_TYPE = 'FUNDING_RECEIPT';
const FUNDING_ITALY_TEMPLATE = 'italysalesreceipt.tpl';
const FUNDING_TEMPLATE = 'fundingreceipt.tpl';
const REFUND_TEMPLATE_NAME = 'refund-notification';
const REFUND_UNKNOWN_TEMPLATE_NAME = 'refund-notification-unknown';
const FUNDING_TEMPLATE_NAME = 'funding-notification';
export class ReceiptApi {
  sendEmailReceipt(
    email: string,
    transactionId: string,
    type: string,
    region: string,
    balance: number,
  ): Promise<any> {
    const baseReceipt = this.getReceiptApiBase();
    const params: any = {
      to: email,
      transid: transactionId,
      balance,
    };

    if (type === AccountConstants.FUNDING_TYPE) {
      params.template = FUNDING_TEMPLATE;
      params.receipttype = FUNDING_RECEIPT_TYPE;
    } else if (region === 'ITA') {
      params.template = FUNDING_ITALY_TEMPLATE;
    }

    const url = Api.getFullUrl(baseReceipt, '/notify/email', params);
    return Api.fetch(url, null, 'POST');
  }

  sendFundingReceipt(
    to: string,
    source: string,
    locale: string,
    amount: string,
    date: string,
    time: string,
    location: string,
    transID: string,
    newBalance: string,
    firstName: string,
  ) {
    const smtMailUrl = this.getSmtMailBase();
    const url = Api.getFullUrl(smtMailUrl, '/notify/v2/mail');
    const template = FUNDING_TEMPLATE_NAME;
    const body = {
      source,
      template,
      locale,
      params: {
        to,
        amount,
        date,
        time,
        location,
        transID,
        newBalance,
        firstName,
      },
    };
    return Api.fetch(url, body, 'POST');
  }

  sendRefundReceipt(
    to: string,
    source: string,
    locale: string,
    amount: string,
    date: string,
    time: string,
    last4ofCC: string,
    transID: string,
    newBalance: string,
  ) {
    const smtMailUrl = this.getSmtMailBase();
    const url = Api.getFullUrl(smtMailUrl, '/notify/v2/mail');
    let template = REFUND_TEMPLATE_NAME;

    if (!last4ofCC) {
      template = REFUND_UNKNOWN_TEMPLATE_NAME;
    }

    const body = {
      source,
      template,
      locale,
      params: {
        to,
        amount,
        date,
        time,
        last4ofCC,
        transID,
        newBalance,
      },
    };
    return Api.fetch(url, body, 'POST');
  }
  sendReferralReceipt(
    to: string,
    source: string,
    locale: string,
    receiverName: string,
    balance: number | string,
    locationName: string,
    dateCreated: string,
    currency: string,
    transactionDate: string,
    transactionId: string,
    type?: string,
  ) {
    const smtMailUrl = this.getSmtMailBase();
    const url = Api.getFullUrl(smtMailUrl, '/notify/v2/mail');
    const template =
      type === 'referral'
        ? 'referral-rewards-receipt'
        : 'signup-rewards-receipt';
    const body = {
      source,
      template,
      locale,
      params: {
        to,
        receiverName,
        balance,
        locationName,
        currency,
        dateCreated,
        transactionDate,
        transactionId,
      },
    };
    return Api.fetch(url, body, 'POST');
  }
  getReceiptApiBase() {
    return Api.getTallyUrl(UrlTypes.receiptapi);
  }

  getSmtMailBase() {
    return Api.getTallyUrl(UrlTypes.smtmail);
  }
}
export default new ReceiptApi();
