import moment from 'moment';
import * as React from 'react';
import {
  FlatList,
  Platform,
  RefreshControl,
  StyleSheet,
  View,
  TouchableOpacity,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import ShadowContainer from '../../elements/ShadowContainer';
import Styles from '../../Styles';
import type {MessageType} from 'src/types/MessageType';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Deal from '../../elements/promotions/Deal';
import DealService from 'src/services/DealService';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import Events from 'src/logging/Events';
import Localized from 'src/constants/AppStrings';
import type {GiftItem, GiftItem as GiftItemType} from 'src/types/Snack';
import SnackItem from '../../elements/SnackItem';
import DealModel from 'src/models/Moblico/Deal';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {loadAllMessages, LoadSnacksParams} from 'src/redux/thunks/snackThunks';
import {NavigationProp} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Chat from 'src/components/img/svg/inbox/Chat';
import BackSubheader from 'src/components/elements/BackSubheader';
import DoubleGreenTick from 'src/components/img/svg/doubleGreenTick';
import Snackbar from 'src/components/dialogs/Snackbar';
import {
  markAllMessagesAsRead,
  NotificationMessage,
} from 'src/redux/thunks/notificationThunks/allMessagesRead';
import CrashlyticsEvents from 'src/logging/Crashlytics';

type RevolveInboxScreenProps = {
  loadAllMessages(params: LoadSnacksParams): Promise<Array<GiftItemType>>;
  markAllMessagesAsRead(params: NotificationMessage): Promise<boolean>;
  filterType: filterTypes;
  unreadMessagesCount: number;
  navigation: NavigationProp<RevolveInboxScreen>;
};
type InboxScreenState = {
  messages: Array<DealModel | MessageType | GiftItemType>;
  filterType: filterTypes;
  snacksRefreshing: boolean;
  pastDeals: Array<DealModel>;
  refreshing: boolean;
  activeDeals: Array<DealModel>;
  snackbarVisible: boolean;
  snackBarMessage: string;
  unreadMessagesCount: number;
};

export enum filterPositions {
  all = 0,
  active = 1,
  past = 2,
}

export enum filterTypes {
  past = 'past',
  active = 'active',
  all = 'all',
}

class RevolveInboxScreen extends React.Component<
  RevolveInboxScreenProps,
  InboxScreenState
> {
  focusListener;
  didFocusSubscription;
  didBlurSubscription;
  isDealsRefreshed = false;
  declare unreadNotification: number;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: RevolveInboxScreenProps) {
    super(props);
    this.state = {
      messages: [],
      activeDeals: [],
      pastDeals: [],
      refreshing: false,
      filterType: filterTypes.all,
      snacksRefreshing: false,
      snackbarVisible: false,
      snackBarMessage: Localized.Success.notificationNoUnReadMessages,
      unreadMessagesCount: 0,
    };
    this.renderItem = this.renderItem.bind(this);
    this.reload = this.reload.bind(this);
    this.loadMsges = this.loadMsges.bind(this);
    this.onSnackClose = this.onSnackClose.bind(this);
    this.getKey = this.getKey.bind(this);
  }

  componentDidMount() {
    if (Platform.OS === 'web') {
      ActionsFactory.getAccountActions().refreshMessages().catch();
    }
    if (!Settings.isNewUI()) {
      this.didFocusSubscription = this.props.navigation.addListener(
        'focus',
        () => {
          this.loadMsges().catch();
        },
      );
    } else {
      this.loadAllResponse();
    }
    this.focusListener = this.props.navigation.addListener('focus', () => {
      if (this.state.messages.length == 0) {
        return;
      }
      this.updateUnreadMessagesCount();
    });
    this.didBlurSubscription = this.props.navigation.addListener('blur', () => {
      this.context.actions.resetFilterType();
    });
  }

  async loadAllResponse() {
    this.loadMsges().catch();
  }

  componentWillUnmount() {
    if (!Settings.isNewUI) {
      this.didFocusSubscription();
      this.didBlurSubscription();
    }
    this.focusListener = null;
  }

  isSendASnackItem(item: GiftItemType) {
    return item.type == 'SendaSnack';
  }

  updateUnreadMessagesCount() {
    const tempMessages = this.state.messages;
    if (this.selectedListItemIndex >= 0) {
      const selectedItem = tempMessages[
        this.selectedListItemIndex
      ] as GiftItemType;
      if (selectedItem.isRead == false) {
        selectedItem.isRead = true;
        const unReadMessages = this.state.unreadMessagesCount - 1;
        tempMessages[this.selectedListItemIndex] = selectedItem;
        this.setState({
          unreadMessagesCount: unReadMessages,
          snackbarVisible: unReadMessages == 0,
          messages: tempMessages,
        });
      }
    }
  }

  filterMsgBasedOnAdmFeatureFlag(
    messages: Array<GiftItemType>,
  ): Array<GiftItemType> {
    let filteredMessages = messages;
    const shouldLoadSnacks =
      AccountStore.isSendSnackEnabledForLocation() &&
      AccountStore.isConsumerSendSnackEnabled();
    if (!shouldLoadSnacks) {
      filteredMessages = filteredMessages.filter(
        (item: GiftItemType) => item.type === 'Deal',
      );
    }
    return filteredMessages;
  }

  async updateInbox(unreadNotification: number, messages: Array<GiftItem>) {
    try {
      const allMessages = [...messages] as Array<
        MessageType & DealModel & GiftItemType
      >;
      allMessages.sort((a, b) => {
        const bDate = this.getSortDate(b);
        const aDate = this.getSortDate(a);
        return bDate - aDate;
      });

      this.setState({
        messages: allMessages,
        refreshing: false,
        snacksRefreshing: false,
        unreadMessagesCount: unreadNotification,
      });
      FirebaseAnalytic.trackEvent(
        'updateInbox',
        'RevolveInboxScreen: updateInbox',
        {
          ...this.props,
          ...this.state,
        },
      );
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'RevolveInboxScreen:updateInbox',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'RevolveInboxScreen:updateInbox',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getSortDate(item: MessageType & GiftItemType & DealModel) {
    if (item.subject) {
      return moment(item.date).valueOf();
    }

    if (this.isSendASnackItem(item)) {
      return moment(item.snackSent).valueOf();
    }

    return moment(item.endDate).valueOf();
  }

  async loadMsges() {
    this.setState({
      snacksRefreshing: true,
      refreshing: true,
    });
    const messages = await this.props.loadAllMessages({
      locationId: AccountStore.getLocationId(),
    });
    const unreadMessages = messages.filter((item) => item.isRead === false);
    let unreadNotification = 0;
    if (unreadMessages.length > 0) {
      unreadNotification = unreadMessages.length;
    }
    if (messages) {
      FirebaseAnalytic.trackEvent(
        'loadMessages',
        'RevolveInboxScreen: loadMessages',
        {
          ...this.props,
          ...this.state,
        },
      );
    }
    this.updateInbox(unreadNotification, messages);
  }

  async reload() {
    await ActionsFactory.getAccountActions().reloadConsumerData({
      accountBalanceId: AccountStore.getAccountBalanceId(),
      email: AccountStore.getEmail(),
      accountId: AccountStore.getAccountId(),
      userInitiated: true,
    });
  }

  _onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    ActionsFactory.getAccountActions().refreshMessages().catch();
    this.loadMsges().catch();
  };

  getDataSource() {
    return this.state.messages;
  }

  getKey(item) {
    return `${item.externalServiceId}`;
  }

  renderItem({item, index}: {item: GiftItemType; index: number}) {
    if (item.type == 'SendaSnack') {
      return this.getSnackComponent(item, index);
    }

    return this.getDealCmpnt(item, index);
  }

  getDealCmpnt(deal, index) {
    return (
      <Deal
        selectedItemIndex={index}
        key={deal.externalServiceId}
        giftItem={deal}
        onPress={() => {
          this.selectedListItemIndex = index;
          FirebaseAnalytic.trackEvent(
            'onDealPress',
            'RevolveInboxScreen: onDealPress',
            {
              ...this.props,
              ...this.state,
            },
          );
          NavActions.push(AppRoutes.DealDetail, {
            deal,
          });
        }}
        {...this.context}
      />
    );
  }

  gotoSnackDetail(item: GiftItem) {
    NavActions.push(AppRoutes.SnackDetail, {
      snackId: item.externalServiceId,
      snackLocationId: item.locationId,
      snackType: item.type,
    });
  }

  async onMarkAllAsReadPressed() {
    if (this.state.unreadMessagesCount == 0) {
      const message = this.getSnackMessage(true);
      this.setState({
        snackbarVisible: true,
        snackBarMessage: message,
      });
    } else {
      const markMessagesAsRead = await this.props.markAllMessagesAsRead({
        locationId: AccountStore.getLocationId(),
      });
      const message = this.getSnackMessage(false);
      if (markMessagesAsRead) {
        this.setState({
          snackbarVisible: true,
          snackBarMessage: message,
        });
        this.loadAllResponse();
      }
    }
  }

  getSnackMessage(allMessageRead: boolean) {
    let snackMessage = '';
    if (allMessageRead) {
      snackMessage = Localized.Success.notificationNoUnReadMessages;
    } else {
      snackMessage = Localized.Labels.formatString(
        Localized.Success.notificationMarkedAllAsRead,
        this.state.unreadMessagesCount,
      ).toString();
    }
    return snackMessage;
  }

  declare selectedListItemIndex;

  getSnackComponent(item, index) {
    return (
      <SnackItem
        key={item.externalServiceId}
        snack={item}
        onPress={() => {
          this.selectedListItemIndex = index;
          this.gotoSnackDetail(item);
        }}
        {...this.context}
      />
    );
  }

  getNewDataSource() {
    return this.getDataSource() as Array<GiftItemType & DealModel>;
  }

  isRefreshing() {
    return this.state.refreshing || this.state.snacksRefreshing;
  }

  isDealsRefreshing() {
    let isDealsRefreshing = false;
    if (DealService.getDeals().length == 0) {
      if (!this.isDealsRefreshed) {
        isDealsRefreshing = true;
      } else {
        isDealsRefreshing = false;
      }
    } else {
      isDealsRefreshing = false;
    }
    this.isDealsRefreshed = false;
    return isDealsRefreshing;
  }

  getLoadingView() {
    return (
      <View style={styles.emptyMessageContainer}>
        <AVText>{Localized.Labels.loading}</AVText>
      </View>
    );
  }

  getNewEmptyMessageUI() {
    return (
      <View style={styles.emptyMessageContainer}>
        <Chat />
        <AVText style={styles.emptyMessageText}>
          {this.getEmptyMessage()}
        </AVText>
      </View>
    );
  }

  getEmptyMessage() {
    return Localized.Labels.no_messages;
  }

  getListHeader() {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {this.getUnreadMessagesComponent()}
        {this.getMarkAsReadComponent()}
      </View>
    );
  }

  getUnreadMessagesComponent() {
    return (
      <View style={styles.unreadMessagesContainer}>
        <AVText style={styles.unreadMessagesText}>
          {Localized.Labels.formatString(
            Localized.Labels.unreadNotificationsCount,
            this.state.unreadMessagesCount,
          ).toString()}
        </AVText>
      </View>
    );
  }

  getMarkAsReadComponent() {
    return (
      <TouchableOpacity
        style={styles.markAllAsReadContainer}
        onPress={() => {
          this.onMarkAllAsReadPressed();
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignSelf: 'center',
            alignContent: 'center',
          }}
        >
          <View style={{marginTop: 8}}>
            <DoubleGreenTick width={24} height={24} />
          </View>
          <AVText style={styles.markAllAsReadText}>
            {Localized.Labels.mark_all_as_read}
          </AVText>
        </View>
      </TouchableOpacity>
    );
  }

  getRenderCmpnt() {
    const listData = this.getNewDataSource();
    return (
      <BackSubheader
        style={{
          backgroundColor: Styles.darkBlueRevolveColor,
          color: Styles.white,
        }}
        title={Localized.Labels.notifications}
      >
        <ShadowContainer style={styles.content}>
          {listData.length != 0 && (
            <View style={{flex: 5, flexDirection: 'column'}}>
              {this.getListHeader()}
              <View style={{flex: 4.8, flexDirection: 'column'}}>
                <FlatList
                  renderItem={this.renderItem}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.isRefreshing()}
                      onRefresh={this._onRefresh}
                    />
                  }
                  data={listData}
                />
              </View>
            </View>
          )}
          {!this.isRefreshing() &&
            listData.length == 0 &&
            this.getNewEmptyMessageUI()}
          {this.isRefreshing() && listData.length == 0 && this.getLoadingView()}
        </ShadowContainer>

        {this.state.snackbarVisible && (
          <Snackbar
            message={this.state.snackBarMessage}
            duration={4000}
            backgroundColor={'#dce4f1'}
            textColor={Styles.black}
            onDismiss={this.onSnackClose}
          />
        )}
      </BackSubheader>
    );
  }

  onSnackClose() {
    this.setState({
      snackbarVisible: false,
    });
  }

  render() {
    return this.getRenderCmpnt();
  }
}

const styles = StyleSheet.create({
  arrow: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  borderContainer: {
    borderBottomColor: Styles.lightGray,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
  content: {backgroundColor: Styles.tabBarBackgroundColor},

  emptyMessageText: {
    marginTop: Styles.Spacing.m3,
  },
  emptyMessageContainer: {
    alignSelf: 'center',
    textAlignVertical: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flex: 1,
    flexDirection: 'column',
  },
  date: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
  from: {
    fontSize: Styles.Fonts.f1,
    fontWeight: 'bold',
    color: Styles.black,
  },

  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  icon: {
    height: Styles.Fonts.f4,
    width: Styles.Fonts.f4,
    resizeMode: 'contain',
    borderRadius: Styles.Fonts.f4 / 2,
  },
  message: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    flex: 1,
  },

  topRight: {
    flexDirection: 'row',
  },
  messageContainer: {
    flex: 1,
    flexDirection: 'row',
    minHeight: Styles.Heights.touchTargetHeight2,
    padding: Styles.Spacing.m3,
  },
  subject: {
    marginBottom: Styles.Spacing.m1,
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
  },
  unreadMessagesContainer: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  unreadMessagesText: {
    paddingLeft: 20,
    paddingTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    paddingBottom: 2,
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  markAllAsReadContainer: {
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  markAllAsReadText: {
    paddingTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    paddingBottom: 2,
    fontWeight: '500',
    color: Styles.darkGreenRevolve,
    textDecorationLine: 'underline',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  snackbar: {
    flex: 1,
    backgroundColor: Styles.positiveColor,
  },
});

export default compose(
  withForwardedNavigationParams<RevolveInboxScreenProps>(),
  connect(null, (dispatch: AppDispatch) => ({
    loadAllMessages: (params) => dispatch(loadAllMessages(params)).unwrap(),
    markAllMessagesAsRead: (params) =>
      dispatch(markAllMessagesAsRead(params)).unwrap(),
  })),
)(RevolveInboxScreen);
