import React from 'react';
import {View} from 'react-native';
import AVText from '../../AVText';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import {formatCurrency} from 'src/components/helpers/HistoryHelper';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';

import {getDescriber} from '../descriptor/DescriptorType';
type TaxInfoComponentProps = {
  taxAmount: number;
  currency?: string;
  taxLabel?: string;
};

const TaxInfoComponent: React.FC<TaxInfoComponentProps> = ({
  taxAmount,
  taxLabel,
  currency,
}) => {
  return (
    <View style={[Styles.Style.revolveRow, {paddingVertical: 2}]}>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        accessible={true}
        accessibilityLabel={`${taxLabel} label`}
        aria-label={`${taxLabel} label, text`}
        style={getDescriber().PurchaseDetailtaxStyleDescriptor()}
      >
        {taxLabel}
      </AVText>
      <AVFormattedCurrency
        {...formatCurrency(taxAmount, taxLabel, currency)}
        style={[
          Settings.isRevolve() && {
            fontSize: Styles.Fonts.f1 + 2,
            fontFamily: Styles.FontFamily.figtreeRegular,
            fontWeight: '400',
            color: Styles.darkColor,
          },
        ]}
      />
    </View>
  );
};

export default TaxInfoComponent;
