import RevolveInboxScreen from 'src/components/screens/inbox/RevolveInboxScreen';
import React from 'react';

export function inboxComponentDescriptor(): React.ComponentType {
  return RevolveInboxScreen;
}

export default {
  inboxComponentDescriptor,
};
