import BuildTypes from 'src/constants/BuildTypeConstants';
import Styles from 'src/components/Styles';
import Settings from 'src/Settings';

const offersThemeConfiguration = {
  [BuildTypes.revolve]: {
    buttonColor: Styles.lightGreenRevolve,
    textFontFamily: Styles.FontFamily.figtreeRegular,
    textColor: Styles.darkColor,
  },
  [BuildTypes.canteen]: {
    buttonColor: Styles.primaryColor,
    textFontFamily: Styles.FontFamily.robotoRegular,
    textColor: Styles.darkColor,
  },
  [BuildTypes.default]: {
    buttonColor: Styles.Colors.PayNew.primary01,
    textFontFamily: Styles.FontFamily.aeonikRegular,
    textColor: Styles.Colors.PayNew.black01,
  },
};

export const offersTheme = offersThemeConfiguration[Settings.buildType];
