import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../Styles';
import {View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {AccountFundingDescriberData} from 'src/components/screens/funding/descriptor/DescriptorType';
import React from 'react';
import PaperBill from 'src/components/img/svg/cards/paperBill';
import AVText from 'src/components/elements/AVText';

export function getCreditCardAddStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    inputsContainer: {
      flexDirection: 'column',
      paddingHorizontal: Styles.Spacing.m2,
    },
    defaultSwitch: null,
  };
}
export function payrollDeductNormlSectionImage() {
  return null;
}

export function getModalTexts(): {
  [index: string]: number | string;
} {
  return {
    fundModalHeaderTitle: Localized.Labels.quick_fund_your_account,
  };
}

export function getAcntFundingHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerNewContainer: {
      backgroundColor: Styles.white,
      flexDirection: 'row',
      paddingHorizontal: Styles.Spacing.m3,
      alignItems: 'center',
      borderTopLeftRadius: 32,
      borderTopRightRadius: 32,
    },
  };
}

export function accountFundingHeaderNewDescDescriptor(
  props: AccountFundingDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <AVTouchableOpacity
        style={{
          minHeight: Styles.Heights.touchTargetHeight2,
          minWidth: Styles.Heights.touchTargetHeight2,
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
        accessible={true}
        accessibilityLabel={Localized.Buttons.cancel}
        accessibilityHint="Double tap X button to close the funding modal"
        aria-label={Localized.Buttons.cancel}
        accessibilityRole="button"
        role="button"
        onPress={() => props.onClose()}
      >
        <View
          style={{
            width: 48,
            height: 6,
            borderRadius: 16,
            backgroundColor: Styles.borderColor,
          }}
        />
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function getPayrollDeductAgreementUnderline() {
  return null;
}
export function getRemainingStyle() {
  return null;
}
export function getPayRollDeductImage(styles) {
  return (
    <View style={styles.circle}>
      <PaperBill size={Styles.Fonts.f5} style={styles.cardLogoRevolve} />
    </View>
  );
}
export function getPayrollDeductImageStyle() {
  return null;
}
export function getPayrollDeductPendingDescStyle() {
  return null;
}
export function getOptinTxt() {
  return null;
}
export function getPayrollDeductPendingTitleStyle() {
  return null;
}
export function getPayrollDeductAgreementTxt() {
  return null;
}
export function getSpendLimit() {
  return null;
}
export function getOptingOutPayrollDeductContent(styles) {
  return (
    <AVText
      style={[styles.infoText, getOptingOutPayrollDeduct()]}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
    >
      {Localized.Labels.opting_out_of_payroll_deduct}
    </AVText>
  );
}
export function getOptinView() {
  return null;
}

export function switchStyle() {
  return null;
}
export function getResetLbl() {
  return null;
}
export function normalPayrollAggreement() {
  return null;
}
export function getUnfilledColor() {
  return Styles.lightGray;
}

export function getSwitchColor() {
  return {
    false: Styles.lightGray,
    true: Styles.primaryColor,
  };
}

export function getFilledColor() {
  return Styles.primaryColor;
}

export function getOptingOutPayrollDeduct() {
  return null;
}
export default {
  getOptinView,
  getPayrollDeductPendingDescStyle,
  getCreditCardAddStyleDescriptor,
  getAcntFundingHeaderStyleDescriptor,
  getResetLbl,
  getFilledColor,
  getSpendLimit,
  normalPayrollAggreement,
  getOptinTxt,
  getPayrollDeductImageStyle,
  switchStyle,
  getPayrollDeductPendingTitleStyle,
  getModalTexts,
  getPayRollDeductImage,
  getUnfilledColor,
  getRemainingStyle,
  getPayrollDeductAgreementUnderline,
  getOptingOutPayrollDeductContent,
  getPayrollDeductAgreementTxt,
  payrollDeductNormlSectionImage,
  getOptingOutPayrollDeduct,
  getSwitchColor,
};
