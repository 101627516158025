import * as React from 'react';
import FontAwesome5Pro, {FontAwesomeIconType} from '../icons/FontAwesomeIcon';
import Amex from '../img/svg/cards/amex';
import ApplePay from '../img/svg/cards/applePay';
import Discover from '../img/svg/cards/discover';
import MasterCard from '../img/svg/cards/mastercard';
import PaperBill from '../img/svg/cards/paperBill';
import Visa from '../img/svg/cards/visa';
import Styles from '../Styles';
import AccountConstants from 'src/constants/AccountConstants';
import {Image} from 'react-native';
import PaperBillPinkTint from 'src/components/img/svg/cards/paperBillPinkTint';
import Settings from 'src/Settings';
import AmexRedesign from 'src/components/img/svg/cards/amexRedesign';
import JCBIcon from 'src/components/img/svg/JCBIcon';
import DinersClubIcon from 'src/components/img/svg/DinersClubIcon';

type CreditCardImageProps = {
  issuer: string;

  style?: {[index: string]: {[index: string]: number | string}};
};

class CreditCardImage extends React.Component<CreditCardImageProps> {
  render() {
    let cardType: FontAwesomeIconType | undefined = undefined;
    const color = Styles.primaryColor;
    const style = this.props.style ? this.props.style : null;
    if (this.props.issuer) {
      switch (this.props.issuer.toLowerCase()) {
        case 'visa':
          return <Visa size={30} style={style} />;
        case 'dinersclub':
          return <DinersClubIcon size={30} style={style} />;
        case 'jcb':
          return <JCBIcon size={30} style={style} />;
        case 'american-express':
        case 'american express':
        case 'americanexpress':
        case 'amex':
          return Settings.is365Pay() ? (
            <AmexRedesign size={41} style={style} />
          ) : (
            <Amex size={26} style={style} />
          );

        case 'discover':
          return <Discover size={26} style={style} />;

        case 'master-card':
        case 'mastercard':
          return <MasterCard size={26} style={style} />;

        case AccountConstants.APPLE_PAY_TOKEN:
          return <ApplePay size={26} style={style} />;

        case AccountConstants.GOOGLE_PAY_TOKEN:
          return (
            <Image
              style={{width: 39, height: 26, marginRight: 1, marginTop: 1}}
              resizeMode="contain"
              source={require('src/components/img/cards/googlepay.png')}
            />
          );

        case AccountConstants.PAYROLL_TOKEN:
          return <PaperBill size={26} style={style} />;
        case AccountConstants.PAYROLL_TOKEN_REDESIGN:
          return <PaperBillPinkTint size={26} style={style} />;

        default:
          cardType = 'credit-card-front';
          break;
      }
    }

    if (cardType) {
      return <FontAwesome5Pro name={cardType} color={color} size={28} />;
    } else {
      return null;
    }
  }
}

export default CreditCardImage;
