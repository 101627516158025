import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import BackSubheader from 'src/components/elements/BackSubheader';
import StickerComponent from 'src/components/elements/sendSnack/StickerComponent';
import GiftBox from 'src/components/img/svg/GiftBox';
import React from 'react';
import {SendSnackDescriberData} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import {ButtonType} from 'src/components/elements/RoundedButton';
import {WithGlobalizeProps} from 'react-native-globalize';

export function getBackSubHeaderDescriptor(): JSX.Element {
  return <BackSubheader title="" />;
}

export function getTopHeaderSnackDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <GiftBox width={Styles.Heights.h3} height={Styles.Heights.h3} />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        style={getSendSnackStyleDescriptor()['appTitleStyle']}
      >
        {Settings.appDisplayName}
      </AVText>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={getSendSnackStyleDescriptor()['dateStyle']}
      >
        {props.snackDate}
      </AVText>
    </React.Fragment>
  );
}

export function getSnackStickerDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  return (
    props?.stickerId && (
      <StickerComponent id={props.stickerId} imageSize={Styles.Spacing.m7} />
    )
  );
}

export function titleDescriptor(
  props?: SendSnackDescriberData,
): string | string[] {
  return props.isSender
    ? Localized.Labels.your_snack_sent
    : Localized.Labels.formatString(
        Localized.Labels.sent_you_snack,
        props.senderName,
      );
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return null;
}
export function acceptButtonColor() {
  return Styles.Colors.Canteen.darkGreen;
}
export function renderSnackMsgDescriptor(
  props: SendSnackDescriberData & WithGlobalizeProps,
): string | string[] {
  return Localized.Labels.formatString(
    Localized.Labels.sent_you_snack,
    props.senderName,
  );
}
export function unblockButtonType() {
  return ButtonType.normal;
}
export function snackDetailsBtnFontSTyle() {
  return {fontFamily: Styles.FontFamily.robotoRegular};
}

export function getSendSnackStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m3,
    },
    content: {
      padding: Styles.Spacing.m3,
      flex: 1,
    },
    appTitleStyle: {
      color: Styles.black,
      fontSize: Styles.Fonts.f2,
      fontWeight: 'bold',
      marginLeft: Styles.Spacing.m3,
      flex: 1,
    },
    dateStyle: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f0,
      marginRight: Styles.Spacing.m2,
    },
    description: {
      color: Styles.black,
      flex: 1,
      marginTop: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f1,
    },
    message: {
      color: Styles.black,
      flex: 1,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
    },
    receiverSnackStatusText: {
      color: Styles.black,
      flex: 1,
      textAlign: 'center',
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
    },
    amount: {
      color: Styles.black,
      fontSize: Styles.Fonts.f4,
      fontWeight: 'bold',
    },
    btnBlockUser: {
      alignSelf: 'center',
      marginTop: Styles.Spacing.m3,
    },
  };
}
export function blockUnblockButtonColor() {
  return null;
}

export default {
  getBackSubHeaderDescriptor,
  getTopHeaderSnackDescriptor,
  getSnackStickerDescriptor,
  getSendSnackStyleDescriptor,
  backHeaderStyleDescriptor,
  titleDescriptor,
  renderSnackMsgDescriptor,
  snackDetailsBtnFontSTyle,
  unblockButtonType,
  acceptButtonColor,
  blockUnblockButtonColor,
};
