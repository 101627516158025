import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';
interface IProps {
  size?: number;
  color?: string;
}
const OffersRevolveIcon = ({color = '#727179', size = 34}: IProps) => (
  <Svg width={size} height={size} viewBox="0 0 34 34" fill={color}>
    <G clipPath="url(#clip0_8661_10713)">
      <Path
        d="M7.625 15.654V8.063c0-.447.366-.813.813-.813h7.591c.432 0 .843.173 1.148.477l8.938 8.938a1.628 1.628 0 010 2.3l-6.78 6.774a1.628 1.628 0 01-2.3 0l-8.938-8.937a1.622 1.622 0 01-.477-1.148h.005zm-1.625 0c0 .864.34 1.691.95 2.3l8.937 8.938a3.249 3.249 0 004.596 0l6.78-6.78a3.249 3.249 0 000-4.595L18.324 6.58a3.251 3.251 0 00-2.3-.95l-7.587-.005A2.438 2.438 0 006 8.063v7.591zm5.688-3.123a1.22 1.22 0 100-2.438 1.22 1.22 0 000 2.438z"
        fill={color}
      />
    </G>
    <Defs>
      <ClipPath id="clip0_8661_10713">
        <Path fill="#fff" transform="translate(6 4)" d="M0 0H22.75V26H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);
export default OffersRevolveIcon;
