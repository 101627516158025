import Styles from 'src/components/Styles';
import AVText from 'src/components/elements/AVText';
import {DealDescriberData} from 'src/components/screens/inbox/descriptor/DescriptorType';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';
import {View, Image} from 'react-native';

import DealIconPure from 'src/components/img/svg/DealIconPure';

export function dealDescDescriptor(props: DealDescriberData): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      style={props.styles.legalese}
      accessibilityLabel={props.deal.legalese}
    >
      {props.deal.legalese}
    </AVText>
  );
}
export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    expiration: {
      fontSize: Styles.Fonts.f0,
      marginBottom: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m2,
      textAlign: 'center',
    },
    content: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    topRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m3,
    },
  };
}

export function titleDescriptor(props?: DealDescriberData): string {
  return props.deal.promotionText;
}

export function dealIconDescriptor(): JSX.Element {
  return (
    <DealIconPure
      width={Styles.Heights.h2}
      height={Styles.Heights.h2}
      bgColor={'#138665'}
    />
  );
}

export function dealDefaultIconDescriptor(): JSX.Element {
  return (
    <Image
      accessible
      accessibilityLabel={`Default-MenuIcon Image`}
      accessibilityRole="image"
      aria-label="Default-MenuIcon"
      role="img"
      style={{width: 100, height: 100}}
      source={require('src/components/img/CanteenDealDefault.png')}
      resizeMode="cover"
    />
  );
}

export function dealAppliedBtn() {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Labels.claimed}
      color={'#707070'}
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      textStyle={getDealDetailStyleDescriptor().appliedButtonText}
      onPress={() => {
        return false;
      }}
    />
  );
}

export function dealApplyBtn(applyDeal: () => void) {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Labels.claim}
      containerStyle={getDealDetailStyleDescriptor().appliedButton}
      textStyle={getDealDetailStyleDescriptor().appliedButtonText}
      onPress={applyDeal}
      color={Styles.Colors.Canteen.darkGreen}
    />
  );
}

export function dealAppliedContainer() {
  const offers = Localized.Labels.offer_already_claimed.split(': ');
  return (
    <View style={getDealDetailStyleDescriptor()['alreadyClaimedOffer']}>
      {dealIconDescriptor()}
      <AVText style={getDealDetailStyleDescriptor()['alreadyClaimedOfferText']}>
        <AVText
          style={[
            getDealDetailStyleDescriptor()['alreadyClaimedOfferText'],
            {fontWeight: 'bold'},
          ]}
        >
          {offers[0] + ': '}
        </AVText>
        {offers[1]}
      </AVText>
    </View>
  );
}

export function dealValidityKey() {
  return Localized.Labels.ends_on;
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.white,
  };
}

export function getDealEndDateTime() {
  return null;
}

export function getDealDetailStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topView: {
      marginTop: Styles.Spacing.m2,
      flexDirection: 'row',
    },
    image: {
      width: 100,
      height: 100,
      borderRadius: Styles.Spacing.m2,
    },
    defaultImageIcon: {
      width: 100,
      height: 100,
      borderRadius: Styles.Spacing.m2,
    },
    dealTitleRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m2,
    },
    iconView: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    redeemLimit: {
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
      alignSelf: 'center',
      color: Styles.lightGray,
    },
    validUntil: {
      fontSize: Styles.Fonts.f1,
      marginTop: 40,
      fontWeight: '400',
      alignSelf: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    alreadyClaimedOffer: {
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      padding: Styles.Spacing.m3,
      flex: 1,
      flexDirection: 'row',
      borderRadius: Styles.Spacing.m2,
      backgroundColor: '#EEF5DC',
    },
    alreadyClaimedOfferText: {
      fontSize: Styles.Fonts.f8,
      alignSelf: 'center',
      flex: 1,
      marginLeft: Styles.Spacing.m2,
      textAlignVertical: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    description: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      paddingLeft: Styles.Spacing.m1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    expiration: {
      fontSize: Styles.Fonts.f8,
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    name: {
      color: Styles.black,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
      flex: 1,
      fontSize: Styles.Fonts.f7,
      position: 'absolute',
      justifyContent: 'center',
    },
    appliedButton: {
      alignItems: 'center',
      alignSelf: 'center',
      padding: Styles.Heights.h1,
      paddingHorizontal: 8,
      paddingVertical: 8,
      justifyContent: 'center',
    },
    appliedButtonText: {
      color: Styles.white,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      textAlign: 'center',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    content: {
      paddingVertical: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m1,
      flex: 1,
      backgroundColor: Styles.white,
    },
  };
}

export function dealAppliedBtnIcon() {
  return null;
}

export default {
  dealDescDescriptor,
  getStyleDescriptor,
  titleDescriptor,
  dealIconDescriptor,
  dealApplyBtn,
  dealValidityKey,
  dealAppliedBtn,
  dealAppliedBtnIcon,
  dealAppliedContainer,
  getDealDetailStyleDescriptor,
  backHeaderStyleDescriptor,
  getDealEndDateTime,
  dealDefaultIconDescriptor,
};
