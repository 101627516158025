import React from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import type {IsConnectedProps} from 'src/types/Screen';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import {StyleSheet, ScrollView, View, Platform} from 'react-native';
import Settings from 'src/Settings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import BackSubheader from 'src/components/elements/BackSubheader';
import AVText from 'src/components/elements/AVText';
import {getDescriber} from './descriptor/DescriptorType';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getMobileAuth0Client, getWebAuth0Client} from 'src/nativeModules/Auth0';
import {store} from 'src/redux/store';
import {authStore} from 'src/init';
import moment from 'moment';
import AppRoutes from 'src/AppRoutes';

type ChangeEmailScreenProps = IsConnectedProps & {
  navigation: NavigationProp<ChangeEmailScreen>;
};
type ChangeEmailScreenState = {
  password?: string;
  newEmail: string;
  confirmEmail: string;
  previousRoute: string;
};

class ChangeEmailScreen extends React.Component<
  ChangeEmailScreenProps,
  ChangeEmailScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ChangeEmailScreenProps) {
    super(props);
    this.state = {
      password: '',
      confirmEmail: '',
      newEmail: '',
      previousRoute: '',
    };
    this.validate = this.validate.bind(this);
    this.saveClick = this.saveClick.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.handleNewEmail = this.handleNewEmail.bind(this);
    this.handleConfirmEmail = this.handleConfirmEmail.bind(this);
    this.checkIfDisabled = this.checkIfDisabled.bind(this);
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({
      previousRoute,
    });
  }

  saveClick() {
    const errorMessage = this.validate();

    if (errorMessage) {
      alertError(errorMessage);
    } else {
      this.changeEmail();
    }
  }

  validate() {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.newEmail)
    ) {
      return Localized.Errors.invalid_email;
    } else if (this.state.newEmail !== this.state.confirmEmail) {
      return Localized.Errors.emails_do_not_match;
    }
  }

  async changeEmail() {
    this.context.actions.showSpinner();
    const email = this.state.newEmail;

    FirebaseAnalytic.trackEvent('changeEmail', 'ChangeEmailScreen', {
      ...this.props,
      ...this.state,
    });

    try {
      const env = store.getState().environment.env;

      if (Settings.buildType === 'default') {
        const response =
          await ActionsFactory.getAccountActions().checkEmailExistence(email);
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'ChangeEmail',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            newEmail: this.state.newEmail,
          }),
          JSON.stringify(response),
        );

        if (response?.exists == false) {
          if (Platform.OS === 'web') {
            const auth0Client = await getWebAuth0Client();

            await auth0Client.loginWithPopup({
              authorizationParams: {
                scope: 'openid profile email offline_access',
                screen_hint: 'login-id',
                login_hint: email,
                update_email: true,
                accountId: AccountStore.getAccountId(),
                connection: 'email',
                prompt: 'login',
                useRefreshTokens: true,
              },
            });
            const authUser = await auth0Client.getUser();

            if (authUser) {
              const accessTokenWeb = await auth0Client.getTokenSilently({
                detailedResponse: true,
              });
              const idInfo = await auth0Client.getIdTokenClaims();

              await authStore.storeSession({
                accessToken: accessTokenWeb.access_token,
                expiresAt: moment().unix() + accessTokenWeb.expires_in,
                tokenType: 'Bearer',
                idToken: idInfo.__raw,
                refreshToken: 'web',
                scope: '',
                isNewUser: idInfo['https://365rm.us/isNewUser'],
                isSSO: idInfo['https://365rm.us/isSSO'],
                hasQRCode: idInfo['https://365rm.us/hasQRCode'],
              });
            }
            NavActions.navigate(AppRoutes.Account, {
              emailChanged: true,
            });
          } else {
            const Auth0 = await getMobileAuth0Client();
            try {
              const credentials = await Auth0.webAuth.authorize(
                {
                  scope: 'openid offline_access profile email',
                  audience: Settings.auth0[env].audience,
                  organization: Settings.auth0[env].orgIdAuth0,
                  additionalParameters: {
                    screen_hint: 'login-id',
                    accountId: AccountStore.getAccountId(),
                    update_email: 'true',
                    login_hint: email,
                    connection: 'email',
                  },
                },
                {
                  ephemeralSession: true,
                },
              );
              if (credentials) {
                await authStore.storeSession(credentials);
              }

              NavActions.navigate(AppRoutes.Account, {
                emailChanged: true,
              });
            } catch (error) {
              Logger.Log.LogAPIEvent(
                'AccountAPI',
                'ChangeEmail-Error: auth0',
                JSON.stringify({
                  email: this.state.newEmail,
                  accountId: AccountStore.getAccountId(),
                }),
                JSON.stringify(error),
              );
              Events.Error.trackEvent(
                'Exception',
                'ChangeEmailScreen:auth0',
                generateErrorMessage(error),
              );
            }
          }
        } else if (response?.exists) {
          alertError(Localized.Errors.email_already_exists);
        } else {
          if (response instanceof Error) {
            let parsedErrors;
            try {
              const errorString = response.toString();

              const match = errorString.match(/{.*}/);
              if (match && match[0]) {
                parsedErrors = JSON.parse(match[0]);
              }
            } catch (parseError) {
              Events.Error.trackEvent(
                'Exception',
                'ChangeEmailScreen:auth0',
                generateErrorMessage(parseError),
              );
            }

            // Check if `violation` exists and matches "Email is Locked/Disabled"
            if (
              parsedErrors?.violation &&
              parsedErrors?.violation === 'Email is Locked/Disabled'
            ) {
              alert(Localized.Errors.your_account_is_locked_disabled);
            }
          }
        }
      } else {
        const changeEmailresponse =
          await ActionsFactory.getAccountActions().changeEmail(
            AccountStore.getAccountId(),
            this.state.newEmail,
            Settings.buildType,
          );

        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'ChangeEmail',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            newEmail: this.state.newEmail,
          }),
          JSON.stringify(changeEmailresponse),
        );
        alertSuccess(Localized.Success.verify_email_has_been_sent);
        NavActions.pop();
      }
    } catch (error) {
      if (error.message.includes('Email already exists')) {
        alertError(Localized.Errors.email_already_exists);
      } else {
        alertError(Localized.Errors.unable_to_change_email);
      }

      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ChangeEmailScreen:ChangeEmail',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ChangeEmailScreen:ChangeEmail',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangeEmail-Error',
        JSON.stringify({
          email: this.state.newEmail,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(error),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  checkIfDisabled() {
    if (this.state.newEmail.length > 0 && this.state.confirmEmail.length > 0)
      return false;
    else return true;
  }

  handleNewEmail(text) {
    this.setState({newEmail: text});
  }
  handleConfirmEmail(text) {
    this.setState({confirmEmail: text});
  }

  render() {
    const {
      backHeaderDescriptor,
      maxFontSizeMultipleDescriptor,
      getStyleDescriptor,
      generateEmailInputFields,
      generateChangeEmailRoundedButton,
    } = getDescriber();
    return (
      <BackSubheader title={backHeaderDescriptor()}>
        <KeyboardAvoidingView style={Styles.Style.flex} insideTab>
          <ScrollView>
            <View
              style={[
                getStyleDescriptor() && getStyleDescriptor()['content'],
                Styles.Style.maxWidthContainer,
              ]}
            >
              <AVText
                style={
                  getStyleDescriptor() && getStyleDescriptor()['instructions']
                }
              >
                {Localized.Labels.update_email}
              </AVText>
              {generateEmailInputFields({
                labelNewEmail: Localized.Labels.new_email,
                valueNewEmail: this.state.newEmail,
                onChangeNewEmail: this.handleNewEmail,
                labelConfirmEmail: Localized.Labels.confirm_new_email,
                valueConfirmEmail: this.state.confirmEmail,
                onChangeConfirmEmail: this.handleConfirmEmail,
                maxFontSizeMultiplier: maxFontSizeMultipleDescriptor,
              })}
            </View>
          </ScrollView>
          {this.props.isConnected &&
            generateChangeEmailRoundedButton({
              accessibilityLabel: Localized.Buttons.save,
              accessibilityHint: 'Double tap to save new email address',
              ariaLabel: Localized.Buttons.save,
              buttonText: Localized.Buttons.save,
              onPressHandler: this.saveClick,
              isDisabled: this.checkIfDisabled(),
            })}
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    paddingTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m6,
  },
  instructions: {
    alignSelf: 'stretch',
    color: Styles.black,
    fontSize: Styles.Fonts.f9,
    fontWeight: '700',
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m2,
    textAlign: 'left',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
});
export default withForwardedNavigationParams()(
  withIsConnected(ChangeEmailScreen),
);
