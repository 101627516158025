import {Platform, StyleProp, ViewStyle} from 'react-native';
import Settings from 'src/Settings';
import Styles from 'src/components/Styles';

import FontAwesome5Pro from 'react-native-vector-icons/FontAwesome5Pro';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import {FundingDescriberProps} from 'src/components/elements/funding/descriptor/DescriptorType';
import Localized from 'src/constants/AppStrings';

export function fundAutoReloadTxtStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.robotoRegular,
    textDecorationLine: 'underline',
    fontWeight: '400',
    textTransform: 'capitalize',
  };
}

export function getFundAccountTabContainerStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      backgroundColor: Styles.white,
      ...(Platform.OS === 'web' && {
        flex: 1,
      }),
      height: Settings.isNewUI() ? 450 : 360,
      paddingBottom: Styles.Spacing.m4,
      paddingTop: Settings.isNewUI() ? Styles.Spacing.m2 : Styles.Spacing.m1,
      paddingHorizontal: Settings.isNewUI()
        ? Styles.Spacing.m0
        : Styles.Spacing.m3,
    },
  };
}

export function closeExpressFundingTemplateDescriptor(
  props?: FundingDescriberProps,
): JSX.Element {
  return (
    <AVTouchableOpacity
      style={{
        width: Styles.Spacing.m4 + Styles.Spacing.m3,
        height: Styles.Heights.touchTargetHeight0,
        alignItems: 'flex-end',
        justifyContent: 'center',
      }}
      accessibilityLabel="Cancel"
      accessibilityHint="Double tap to close the funding modal"
      aria-label="Cancel"
      accessibilityRole="button"
      role="button"
      onPress={() => props?.close()}
    >
      <FontAwesome5Pro
        name="times"
        color={Styles.darkColor}
        size={Styles.Fonts.f3}
        style={{
          marginRight: Styles.Spacing.m1,
        }}
        light
      />
    </AVTouchableOpacity>
  );
}

export function fundSwitchStyleDescriptor(): {
  [index: string]: number | string | Array<{scaleX?: number; scaleY?: number}>;
} {
  return {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  };
}

export function getModalTexts(): {
  [index: string]: number | string;
} {
  return {
    fundModalReloadHeader: Localized.Labels.edit_auto_reload,
  };
}

export function getAcntFundingHeaderStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    headerNewContainer: {},
  };
}

export function fundingSwitchTrackColorDescriptor(): string {
  return Styles.primaryColor;
}

export function getExpressFundingStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      paddingTop: Settings.isNewUI() ? Styles.Spacing.m3 : Styles.Spacing.m1,
      //borderRadius: Settings.isNewUI() ? 10 : 0,
      ...(Platform.OS === 'web' && {
        flex: 1,
      }),
      backgroundColor: Styles.white,
      paddingHorizontal: Settings.isNewUI()
        ? Styles.Spacing.m0
        : Styles.Spacing.m3,
      height: Settings.isNewUI() ? 560 : 410,
    },
    toggleLabel: {
      alignSelf: 'center',
      color: Styles.darkColor,
      fontSize: Settings.isNewUI() ? Styles.Fonts.f7 : Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    topContainer: {
      marginLeft: Settings.isNewUI()
        ? Styles.Spacing.m0
        : Styles.Spacing.m4 + Styles.Spacing.m3,
    },
    paymentNewContainer: {
      marginHorizontal: Settings.isNewUI()
        ? Styles.Spacing.m3
        : Styles.Spacing.m0,
    },
    saveButtonContainer: {
      bottom: Styles.Heights.h7,
      zIndex: 1,
    },
    headerNewUIText: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    bottomBtnContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flex: 1,
    },
    backButton: {
      zIndex: 1,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
    },
    saveButton: {
      zIndex: 1,
      right: 25,
      paddingTop: 6,
      paddingBottom: 6,
      paddingLeft: 10,
      paddingRight: 10,
    },
  };
}

export function getAmericanExpressRightIcon(
  issuer: string,
): StyleProp<ViewStyle> {
  switch (issuer.toLowerCase()) {
    case 'american-express':
    case 'american express':
    case 'americanexpress':
    case 'amex':
      return {
        borderRadius: 0,
        paddingTop: 4,
      };
  }
  return {
    paddingTop: 4,
  };
}

export default {
  fundAutoReloadTxtStyleDescriptor,
  getFundAccountTabContainerStyleDescriptor,
  fundSwitchStyleDescriptor,
  fundingSwitchTrackColorDescriptor,
  getExpressFundingStyleDescriptor,
  getModalTexts,
  getAmericanExpressRightIcon,
};
