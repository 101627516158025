import React from 'react';
import {StyleSheet, View, Image, Dimensions, Platform} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import {ensureHttps} from 'src/services/StringUtils';
import AVFormattedCurrency from '../AVFormattedCurrency';
import {DNA} from 'src/types/dnaTypes';
import {MenuProductType} from 'src/types/Menu';
import AccountStore from 'src/stores/AccountStore';
import {getCalorieIndicator} from './healthGradeHelper';
import MenuDefaultImg from 'src/components/img/svg/cards/menuDefaultImg';

type MenuItemListProps = {
  imageUrl?: string;
  name: string;
  price: number;
  item?: MenuProductType;
  dnaConfiguration?: DNA;
  onPress: () => void;
  isProductCat?: boolean;
  index?: number;
  extraPadding?: number;
  updateLineCounts?: (index: number, lineCount: number) => void;
};

/** dynamic card width */
const width = Dimensions.get('window').width;
const spaceBetweenColumns = Styles.Spacing.m3;
const totalHorizontalPadding = Styles.Spacing.m4;
const actualViewWidth = width - totalHorizontalPadding - spaceBetweenColumns;
const menuItemWidth = actualViewWidth / 2;

const MenuItemList = (props: MenuItemListProps) => {
  /** calculate layout based on number of lines */
  const handleTextLayout = (e) => {
    const lines = e.nativeEvent.lines.length;
    props.updateLineCounts(props.index, lines);
  };

  /** initialize */
  const displayHealthGrade =
    props?.dnaConfiguration?.showColorCodes &&
    !props?.dnaConfiguration?.isDisabled;
  const calorieUnitName = props?.dnaConfiguration?.showCaloriesAsKilocalories
    ? Localized.Labels.kcal
    : Localized.Labels.cal_not_all_caps;
  const caloriesLabel = props.item?.calories;

  const parsedCalorieValueNew =
    typeof caloriesLabel === 'number'
      ? caloriesLabel
      : parseInt('' + caloriesLabel);

  /** health grade component */
  const {IconComponent, label, hint} = getCalorieIndicator(
    parsedCalorieValueNew,
    props?.dnaConfiguration,
  );

  return (
    <AVTouchableOpacity onPress={props.onPress} accessible>
      <View
        style={styles.container}
        accessibilityLabel={props.name}
        accessible
        aria-label={`${props.name}`}
      >
        <View>
          {!props.imageUrl ? (
            <View
              accessible
              accessibilityLabel="Default-MenuIcon"
              aria-label="Default-MenuIcon"
            >
              {Platform.OS != 'web' ? (
                <Image
                  accessible
                  accessibilityLabel={`Default-MenuIcon Image`}
                  accessibilityRole="image"
                  aria-label="Default-MenuIcon"
                  role="img"
                  style={[styles.imageView, styles.defaultIcon]}
                  source={require('src/components/img/DefaultMenuItem.png')}
                  resizeMode="cover"
                />
              ) : (
                <MenuDefaultImg
                  size={100}
                  style={{size: {width: 176, height: 136}}}
                />
              )}
            </View>
          ) : (
            <View accessible>
              <Image
                accessible
                aria-label={`${props.name} Image`}
                accessibilityLabel={`${props.name} Image`}
                role="img"
                accessibilityRole="image"
                source={{uri: ensureHttps(props.imageUrl)}}
                style={[styles.imageView, styles.defaultIcon]}
                resizeMode="cover"
              />
            </View>
          )}
        </View>
        <View style={styles.menuDescriptionContainer} accessible>
          <View
            style={[styles.itemNameView, {paddingBottom: props.extraPadding}]}
          >
            <AVText
              accessible
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              style={styles.menuNameText}
              accessibilityLabel={`${props.name} Text`}
              accessibilityRole="text"
              aria-label={`${props.name} Text`}
              onTextLayout={handleTextLayout}
            >
              {props.name}
            </AVText>
          </View>
          <View style={styles.itemDetailsView}>
            <View style={styles.calView}>
              <>
                {props.dnaConfiguration?.showCalories &&
                  caloriesLabel !== null && (
                    <AVText
                      accessible
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                      style={styles.caloriesLabel}
                      accessibilityLabel={`${caloriesLabel} Text`}
                      accessibilityRole="text"
                      aria-label={`${caloriesLabel} Text`}
                    >
                      {`${caloriesLabel} ${calorieUnitName}`}
                    </AVText>
                  )}
              </>

              {displayHealthGrade &&
                !isNaN(parsedCalorieValueNew) &&
                parsedCalorieValueNew >= 0 && (
                  <View
                    accessible={true}
                    accessibilityHint={hint}
                    accessibilityLabel={`${Localized.Labels.health_grade}: ${label}, `}
                    aria-label={`${Localized.Labels.health_grade}: ${label}, `}
                    accessibilityRole="image"
                    role="img"
                    style={styles.healthGrade}
                  >
                    <IconComponent
                      width={Styles.Spacing.m3 + 2}
                      height={Styles.Spacing.m3 + 2}
                    />
                  </View>
                )}
            </View>
            <View style={{alignSelf: 'flex-end'}}>
              <AVFormattedCurrency
                value={props.price}
                style={[styles.priceText]}
                accessible={true}
                accessibilityLabel={`$${props.price.toFixed(2)}, text, `}
                aria-label={`$${props.price.toFixed(2)}, `}
                currency={AccountStore.getCurrency()}
                maxFractionDigits={2}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              />
            </View>
          </View>
        </View>
      </View>
    </AVTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: menuItemWidth,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: Styles.Spacing.m3,
    marginRight: spaceBetweenColumns,
  },
  imageView: {
    width: Platform.OS == 'web' ? 176 : menuItemWidth,
    height: 136,
    borderRadius: Styles.Spacing.m2,
  },
  defaultIcon: {
    backgroundColor: Styles.Colors.PayNew.white01,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.darkHuesBase04,
  },
  menuDescriptionContainer: {
    flexDirection: 'column',
  },
  menuNameText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    color: Styles.Colors.PayNew.black01,
    flexWrap: 'wrap',
  },
  itemNameView: {
    paddingVertical: Styles.Spacing.m2,
    width: '95%',
  },
  itemDetailsView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  priceText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    fontWeight: '700',
    color: Styles.Colors.PayNew.black01,
  },
  healthGrade: {
    marginTop: Styles.Spacing.m2 - 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: -1,
  },
  calView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  caloriesLabel: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    color: Styles.Colors.PayNew.darkHuesBase03,
  },
});

export default MenuItemList;
