import * as React from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  Modal,
  Platform,
  Dimensions,
} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import moment from 'moment';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import AVTouchableOpacity from '../../elements/AVTouchableOpacity';
import AppRoutes from 'src/AppRoutes';
import Util from 'src/Util';
import {alertError} from '../../helpers/AlertHelper';
import ProgressBar from '../../elements/ProgressBar';
import MainConsumerContext from '../../MainConsumerContext';
import {AppDispatch, RootState} from 'src/redux/store';
import {connect} from 'react-redux';
import {payrollDeductOptInChanged} from 'src/redux/slices/accountSlice';
import {PayrollDeductInformation} from 'src/models/PayrollDeductInformation';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getFundingDescriber} from './descriptor/DescriptorType';
import CancelIcon from 'src/components/img/svg/CancelIcon';

import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
type PayrollDeductScreenState = {
  showSave: boolean;
  optIn: boolean;
  showPayrollAgreement?: boolean;
};

type PayrollDeductScreenProps = WithGlobalizeProps & {
  dispatch: AppDispatch;
  payrollId: string;
  optIn: boolean;
  payrollDeductInformation: PayrollDeductInformation;
};
const screenWidth = Dimensions.get('window').width;
const modalWidth = screenWidth * 0.9;
class PayrollDeductScreen extends React.Component<
  PayrollDeductScreenProps,
  PayrollDeductScreenState
> {
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;

  constructor(props: PayrollDeductScreenProps) {
    super(props);
    this.state = {
      optIn: props.optIn,
      showSave: false,
      showPayrollAgreement: false,
    };
    this.onBackSelect = this.onBackSelect.bind(this);
    this.renderPending = this.renderPending.bind(this);
    this.handleAgreementPressed = this.handleAgreementPressed.bind(this);
    this.optInChanged = this.optInChanged.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
  }

  onBackSelect() {
    Events.PayrollDeduct.trackEvent('CANCELED');
  }

  async saveChanges() {
    const {dispatch} = this.props;
    dispatch(showSpinner({}));
    try {
      await this.props.dispatch(payrollDeductOptInChanged(this.state.optIn));
      FirebaseAnalytic.trackEvent(
        'payrollDeductOptInChanged',
        'PayrollDeductScreen: OptedIn',
        {
          ...this.props,
          ...this.state,
        },
      );
      NavActions.pop();
    } catch (e) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'PayrollDeductScreen:SaveChanges',
        '',
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'PayrollDeductScreen:SaveChanges',
        '',
        guid,
      );
      alertError(Localized.Errors.error_saving_your_selection, guid);
    } finally {
      dispatch(hideSpinner());
    }
  }

  optInChanged(value: boolean) {
    this.setState(
      {
        showSave: value !== this.props.optIn,
        optIn: value,
      },
      () => {
        if (Settings.is365Pay()) {
          this.saveChanges();
        }
      },
    );
  }

  handleAgreementPressed() {
    FirebaseAnalytic.trackEvent(
      'handleAgreementPressed',
      'PayrollDeductScreen',
      {
        ...this.props,
        ...this.state,
      },
    );
    if (Settings.is365Pay()) {
      this.setState({showPayrollAgreement: true});
    } else {
      NavActions.push(AppRoutes.PayrollDeductAgreement);
    }
  }

  renderPayrollAgreement(marginTop: number) {
    return (
      <AVTouchableOpacity
        onPress={this.handleAgreementPressed}
        testID="payRollAgreement"
      >
        <View
          style={getFundingDescriber().getPayrollDeductAgreementUnderline()}
        >
          <AVText
            style={[
              styles.agreementText,
              {
                marginTop,
              },
              getFundingDescriber().getPayrollDeductAgreementTxt(),
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {Localized.Labels.payroll_deduct_authorization_agreement}
          </AVText>
        </View>
      </AVTouchableOpacity>
    );
  }

  renderPending(): React.ReactNode {
    return (
      <BackSubheader title={Localized.Labels.payroll_deduct}>
        <ScrollView>
          <View style={[styles.content, Styles.Style.maxWidthContainer]}>
            <View
              style={[
                styles.iconContainer,
                getFundingDescriber().getPayrollDeductImageStyle(),
              ]}
            >
              {getFundingDescriber().getPayRollDeductImage(styles)}
            </View>
            <AVText
              style={[
                styles.pendingText,
                getFundingDescriber().getPayrollDeductPendingTitleStyle(),
              ]}
            >
              {Localized.Labels.enrollment_pending}
            </AVText>
            <AVText
              style={[
                styles.notifyText,
                getFundingDescriber().getPayrollDeductPendingDescStyle(),
              ]}
            >
              {Localized.Labels.notify_your_office_admin}
            </AVText>
            {this.renderPayrollAgreement(Styles.Spacing.m4)}
          </View>
          {this.state.showPayrollAgreement && this.renderModal()}
        </ScrollView>
      </BackSubheader>
    );
  }

  renderRemainingSection() {
    const availableBalance =
      this.props.payrollDeductInformation.availableBalance ?? 0;
    const spendLimit = this.props.payrollDeductInformation?.spendLimit ?? 0;
    const progress = this.props.payrollDeductInformation
      ? (spendLimit - availableBalance) / spendLimit
      : 0;
    const showRemaining = spendLimit <= 999;

    if (showRemaining) {
      return (
        <>
          <AVText
            style={[styles.spendLimit, getFundingDescriber().getSpendLimit()]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {Localized.Labels.spend_limit}
          </AVText>
          <View style={styles.progressContainer}>
            <ProgressBar
              progress={progress}
              unfilledColor={getFundingDescriber().getUnfilledColor()}
              color={getFundingDescriber().getFilledColor()}
            />
          </View>
          <AVText
            style={[
              styles.remainingText,
              getFundingDescriber().getRemainingStyle(),
              {lineHeight: 16},
            ]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {`${Localized.Labels.remaining} ${Localized.Labels.formatString(
              Localized.Labels.x_of_y,
              Util.formatCurrency(
                this.props,
                availableBalance,
                AccountStore.getCurrency(),
              ),
              Util.formatCurrency(
                this.props,
                spendLimit,
                AccountStore.getCurrency(),
              ),
            )}`}
          </AVText>
        </>
      );
    } else {
      return null;
    }
  }

  renderPayCycleSection() {
    const spendLimit = this.props.payrollDeductInformation?.spendLimit ?? 0;
    const showRemaining = spendLimit <= 999;
    const resetLabel = (
      <AVText
        style={[styles.resetLabel, getFundingDescriber().getResetLbl()]}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      >
        {Localized.Labels.formatString(
          Localized.Labels.resets_on,
          moment(this.props.payrollDeductInformation?.resetDate).format(
            Settings.is365Pay() ? 'LL' : 'L',
          ),
        )}
      </AVText>
    );
    const payrollRow = (
      <View
        style={[
          styles.payCycleRow,
          !showRemaining && styles.noMargin,
          getFundingDescriber().getOptinView(),
        ]}
      >
        <AVText
          style={[styles.payCycle, getFundingDescriber().getOptinTxt()]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {Localized.Labels.pay_cycle + ':'}
        </AVText>
        <AVText
          style={[
            styles.payCycle,
            styles.payCycleValue,
            getFundingDescriber().getRemainingStyle(),
          ]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {this.props.payrollDeductInformation?.payCycle}
        </AVText>
      </View>
    );

    if (showRemaining) {
      return (
        <>
          {resetLabel}
          {payrollRow}
        </>
      );
    } else {
      return (
        <>
          {payrollRow}
          {resetLabel}
        </>
      );
    }
  }

  renderNormalContent(): React.ReactNode {
    return (
      <ScrollView>
        <View
          style={[
            styles.content,
            styles.normalContent,
            Styles.Style.maxWidthContainer,
          ]}
        >
          <View
            style={
              Settings.is365Pay() &&
              !this.props.optIn && {
                backgroundColor: '#f4f4f4',
                opacity: 0.3,
              }
            }
          >
            {getFundingDescriber().payrollDeductNormlSectionImage(styles)}
            {this.renderRemainingSection()}
            {this.renderPayCycleSection()}
          </View>
          <View style={[styles.bottomContainer, {marginTop: 20}]}>
            <View
              style={[styles.optInRow, getFundingDescriber().getOptinView()]}
            >
              <AVText
                style={[styles.switchText, getFundingDescriber().getOptinTxt()]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {Localized.Labels.opt_in}
              </AVText>

              <CustomToggleSwitch
                accessibilityLabel="Opt In To Payroll Deduct"
                nativeID="Opt In To Payroll Deduct"
                value={this.state.optIn}
                onValueChange={this.optInChanged}
                containerStyle={
                  Settings.isCanteen() && {
                    alignItems: 'flex-end',
                    marginRight: -16,
                  }
                }
              />
            </View>
            {getFundingDescriber().getOptingOutPayrollDeductContent(
              styles,
              this.props.optIn,
            )}
            <View style={getFundingDescriber().normalPayrollAggreement()}>
              {this.renderPayrollAgreement(0)}
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }
  onSuccessModalClose = () => {
    this.setState({showPayrollAgreement: false});
  };
  renderModal(): React.ReactNode {
    return (
      <Modal
        visible={this.state.showPayrollAgreement}
        onDismiss={this.onSuccessModalClose}
        transparent={true}
      >
        <View style={styles.payrollModalMain}>
          <View style={styles.payrollModalContainer}>
            <AVTouchableOpacity
              aria-label={`X icon, Double tap to ${Localized.Buttons.cancel} offer details`}
              role="button"
              style={styles.closeModalTitleContainer}
              onPress={this.onSuccessModalClose}
              accessible
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
            >
              <CancelIcon />
            </AVTouchableOpacity>
            <View style={styles.payrollModalContent}>
              <AVText
                aria-label={`${Localized.Success.success} label, text`}
                accessibilityLabel={`${Localized.Success.success} label`}
                style={styles.payrollAgreeTitle}
                accessible={true}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Labels.payroll_deduct_authorization_agreement +
                  ' Revised: 02/03/2023'}
              </AVText>
              <AVText
                accessible={true}
                accessibilityLabel={`${Localized.Labels.payroll_deduct_agreement_text} label`}
                aria-label={`${Localized.Labels.payroll_deduct_agreement_text} label, text`}
                style={styles.payrollAgreeDesc}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Labels.payroll_deduct_agreement_text}
              </AVText>
            </View>
          </View>
        </View>
      </Modal>
    );
  }

  renderNormal(): React.ReactNode {
    let content = null;

    if (this.props.payrollDeductInformation?.payCycle) {
      content = this.renderNormalContent();
    }

    return (
      <BackSubheader title={Localized.Labels.payroll_deduct}>
        {content}
        {!Settings.is365Pay() && this.state.showSave && (
          <RoundedButton
            buttonType={ButtonType.action}
            accessibilityLabel="Save Payroll Deduct"
            onPress={this.saveChanges}
            text={Localized.Buttons.save}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            testID="savePayrollDeduct"
          />
        )}
        {this.state.showPayrollAgreement && this.renderModal()}
      </BackSubheader>
    );
  }

  render() {
    if (this.props.payrollId) {
      return this.renderNormal();
    } else {
      return this.renderPending();
    }
  }
}

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  normalContent: {
    paddingBottom: Styles.Spacing.m5,
  },
  label: {
    marginVertical: Styles.Spacing.m3,
  },
  iconContainer: {
    marginVertical: Styles.Spacing.m4,
    height: Styles.Fonts.f5,
    alignItems: 'center',
  },
  pendingText: {
    fontSize: Styles.Fonts.f2,
    textAlign: 'center',
  },
  notifyText: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m1,
  },
  agreementText: {
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    color: Styles.primaryColor,
    textDecorationLine: 'underline',
  },
  remainingBalance: {
    fontSize: Styles.Fonts.f2,
    color: Styles.primaryColor,
    textAlign: 'center',
  },
  payCycle: {
    fontSize: Styles.Fonts.f1,
  },
  payCycleRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Styles.Spacing.m4,
  },
  payCycleValue: {
    textTransform: 'capitalize',
  },
  resetLabel: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
  },
  optInRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoText: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    textAlign: 'center',
    marginVertical: Styles.Spacing.m3,
  },
  bottomContainer: {
    marginTop: Styles.Spacing.m4,
  },
  switchText: {
    fontSize: Styles.Fonts.f1,
  },
  progressContainer: {
    marginVertical: Styles.Spacing.m2,
    alignSelf: 'stretch',
  },
  remainingText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
  spendLimit: {
    fontSize: Styles.Fonts.f2,
  },
  noMargin: {
    marginTop: 0,
  },
  circle: {
    width: 50,
    height: 50,
    borderRadius: 100 / 2,
    backgroundColor: Styles.primaryColor,
  },
  cardLogoRevolve: {
    margin: Styles.Spacing.m2 + 3,
  },
  referralSuccErrView: {
    flexDirection: 'row',
    marginBottom: 10,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 12,
  },
  flexRowContainer: {
    flexDirection: 'row',
  },
  flexLeft: {
    flex: 0.1,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flexRight: {
    flex: 0.9,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  referralTextDesc: {
    fontWeight: '700',
    paddingRight: 5,
  },
  payrollModalContent: {
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  payrollModalMain: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  payrollModalContainer: {
    minHeight: Platform.OS == 'ios' ? 220 : 230,
    width: modalWidth,
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m3,
    paddingHorizontal: 15,
  },
  closeModalTitleContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: Styles.Heights.h1,
    right: Styles.Heights.h1,
  },
  payrollAgreeDesc: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
    paddingBottom: 40,
  },
  payrollAgreeTitle: {
    fontSize: 16,
    fontWeight: '700',
    color: '#111',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
  },
});
const ConnectedPayrollDeductScreen = connect(
  (state: RootState) => ({
    payrollId: state.account.account.payrollIdentifier?.value,
    optIn: state.account.account.isPayrollDeductOptIn,
    payrollDeductInformation: state.account.payrollDeductInformation,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(PayrollDeductScreen);
export default withForwardedNavigationParams()(
  withIsConnected(withGlobalize(ConnectedPayrollDeductScreen)),
);
