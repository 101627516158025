import * as React from 'react';
import {Platform, StyleSheet, TextStyle, View} from 'react-native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Styles from '../../../Styles';

import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import InboxScreen from 'src/components/screens/inbox/InboxScreen';
import BackSubheader from 'src/components/elements/BackSubheader';
import {loadAllMessages} from 'src/redux/thunks/snackThunks';
import Settings from 'src/Settings';
import AVText from 'src/components/elements/AVText';
import AnnouncementScreen from 'src/components/screens/inbox/newui/AnnounceMentScreen';
import {getDescriber} from '../../../../components/elements/descriptor/DescriptorType';

type InboxScreenNewUIProps = {
  selectedTab?: string;
  route?: {
    [index: string]: {[index: string]: number | string};
  };
};

export enum filterTypes {
  all = 'all',
  active = 'active',
  past = 'past',
}

const Tab = createMaterialTopTabNavigator();
const bordercolor = getDescriber().tabBarRoundedBtnColor();
const fontFamilyRevolve = Settings.isRevolve()
  ? {fontFamily: Styles.FontFamily.figtreeRegular}
  : {fontFamily: Styles.FontFamily.robotoRegular};
const fontWeightVerify = (focused: boolean): TextStyle | null => {
  return !focused ? {fontWeight: '400'} : {fontWeight: '700'};
};

const InboxTab1 = (props) => {
  return (
    <InboxScreen
      {...props}
      key={'InboxTab1'}
      navigation={props.navigation}
      route={props.route}
    />
  );
};

const InboxTab2 = (props) => {
  return (
    <InboxScreen
      {...props}
      key={'InboxTab2'}
      navigation={props.navigation}
      route={props.route}
    />
  );
};
class InboxScreenNewUI extends React.Component<InboxScreenNewUIProps> {
  render() {
    const checkDefaultSelect =
      this.props.route?.params?.selectedTab || this.props.selectedTab;
    const tabBarIndAndroidStyle = () => {
      return {
        height: '100%',
        backgroundColor: getDescriber().activeTabShadowColor(),
        padding: 20,
      };
    };
    const tabBarIndIOSStyle = () => {
      return {
        backgroundColor: 'transparent',
        height: Styles.Heights.h1 * 0.3,
      };
    };

    return (
      <BackSubheader title={Localized.Labels.notifications}>
        <View
          style={[
            styles.content,
            Platform.OS !== 'web' &&
              getDescriber().dealStyleDescriptor()['inboxTabBgColor'],
          ]}
        >
          <Tab.Navigator
            backBehavior="none"
            initialRouteName={
              checkDefaultSelect === Localized.Labels.messages
                ? Localized.Labels.messages
                : Localized.Labels.announcements
            }
            screenOptions={() => ({
              tabBarScrollEnabled: true,
              tabBarActiveTintColor: Styles.primaryColor,
              tabBarInactiveTintColor: Styles.lightGray,
              tabBarStyle: {
                borderBottomColor: Styles.lightGray,
                borderBottomWidth: StyleSheet.hairlineWidth,
              },
              tabBarIndicatorStyle:
                Platform.OS == 'android'
                  ? tabBarIndAndroidStyle()
                  : tabBarIndIOSStyle(),
            })}
          >
            {Settings.is365Pay() ? (
              <>
                {Platform.OS !== 'web' && (
                  <Tab.Screen
                    name={Localized.Labels.announcements}
                    component={AnnouncementScreen}
                    options={getTabOptions(Localized.Labels.announcements)}
                    initialParams={null}
                  />
                )}
                <Tab.Screen
                  name={Localized.Labels.messages}
                  component={InboxScreen}
                  options={getTabOptions(Localized.Labels.messages)}
                  initialParams={null}
                />
              </>
            ) : (
              <>
                <Tab.Screen
                  name={Localized.Labels.messages}
                  component={InboxScreen}
                  options={getTabOptions(Localized.Labels.messages)}
                  initialParams={{...this.props, filterType: filterTypes.all}}
                />
                <Tab.Screen
                  name={Localized.Labels.active_offers}
                  component={InboxTab1}
                  options={getTabOptions(Localized.Labels.active_offers)}
                  initialParams={{
                    ...this.props,
                    filterType: filterTypes.active,
                  }}
                />

                <Tab.Screen
                  name={Localized.Labels.past_offers}
                  component={InboxTab2}
                  options={getTabOptions(Localized.Labels.past_offers)}
                  initialParams={{...this.props, filterType: filterTypes.past}}
                />
              </>
            )}
          </Tab.Navigator>
        </View>
      </BackSubheader>
    );
  }
}

const getTabOptions = (label) => ({
  tabBarLabel: ({focused}) => {
    return (
      <View style={Platform.OS !== 'web' && styles.shadowStyle}>
        <View
          style={[
            {
              borderRadius: 32,
              overflow: 'hidden',
              alignItems: 'center',
            },
            !focused
              ? {
                  borderWidth: 1,
                  borderBottomColor: '#BCBCBC',
                  borderTopColor: '#BCBCBC',
                  borderStartColor: '#BCBCBC',
                  borderEndColor: '#BCBCBC',
                  backgroundColor: Styles.white,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  elevation: 4,
                }
              : {
                  borderWidth: Platform.OS === 'android' ? 0 : 1,
                  borderBottomColor: bordercolor,
                  borderTopColor: bordercolor,
                  borderStartColor: bordercolor,
                  borderEndColor: bordercolor,
                  backgroundColor: getDescriber().tabBarRoundedBtnColor(),
                  paddingHorizontal: Platform.OS === 'android' ? 0 : 5,
                  paddingVertical: 9,
                  justifyContent: 'center',
                  elevation: 4,
                },
          ]}
          accessibilityHint={label}
          role="button"
          accessible={true}
          aria-label={label}
          testID={label}
          accessibilityRole="button"
          accessibilityLabel={label}
        >
          <AVText
            style={[
              fontWeightVerify(focused),
              {...fontFamilyRevolve, fontSize: 18},
              !focused
                ? {color: Styles.tabBarTextColor}
                : {color: Styles.white},
            ]}
            testID={label}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          >
            {label}
          </AVText>
        </View>
      </View>
    );
  },
  tabBarItemStyle: {
    marginRight: -8,
    width: 'auto',
    top: Platform.OS === 'web' ? 5 : 10,
    marginLeft: -3,
  },
  tabBarAccessibilityLabel: `${label}`,
  tabBarTestID: `${label}`,
  tabBarStyle: {
    backgroundColor: 'transparent',
    marginLeft: 5,
    marginRight: 5,
    paddingBottom: 1,
    ...(Platform.OS === 'web' ? {zIndex: 0} : {}),
  },
});

const styles = StyleSheet.create({
  content: {
    flex: 1,
    marginTop: getDescriber().getInboxTabStyles()['marginTop'],
  },
  shadowStyle: {
    borderRadius: 32,
    marginBottom: Styles.Spacing.m3,
    ...Styles.Style.lightShadow,
  },
});

export default compose(
  withForwardedNavigationParams(),
  connect(null, (dispatch: AppDispatch) => ({
    loadSnacks: (params) => dispatch(loadAllMessages(params)).unwrap(),
  })),
)(InboxScreenNewUI);
