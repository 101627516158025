import React from 'react';
import {
  View,
  StyleSheet,
  Text,
  PixelRatio,
  StyleProp,
  ViewStyle,
  Platform,
} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import EmptyCartSvg from '../../img/svg/EmptyCart';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import RoundedButton, {ButtonType} from '../RoundedButton';
import AVText from '../AVText';
import EmptyCartRevolveSvg from 'src/components/img/svg/EmptyCartRevolveSvg';
import Settings from 'src/Settings';

type EmptyCartProps = {
  style: StyleProp<ViewStyle>;
  orderAhead: boolean;
  onAddItem: () => void;
};

const EmptyCart = (props: EmptyCartProps) => (
  <View style={props.style}>
    {props.orderAhead ? (
      <View style={styles.container}>
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={1}
          dashColor="#999"
          style={[
            {
              marginTop: -1 * Styles.Spacing.m1,
            },
          ]}
        />
        <View style={styles.mainContent}>
          <Text
            accessible={true}
            accessibilityLabel={Localized.Labels.your_order_is_empty}
            aria-label={Localized.Labels.your_order_is_empty}
            accessibilityRole="text"
            style={styles.emptyText}
          >
            {Localized.Labels.your_order_is_empty}
          </Text>
        </View>
        <RoundedButton
          accessible={true}
          accessibilityLabel={Localized.Buttons.add_items}
          accessibilityRole="button"
          aria-label={Localized.Buttons.add_items}
          role="button"
          text={Localized.Buttons.add_items}
          buttonType={ButtonType.normal}
          onPress={props.onAddItem}
        />
      </View>
    ) : Settings.isRevolve() ? (
      <>
        <View style={styles.revolveEmptyCartMainView}>
          <View style={styles.emptyCartDesc}>
            <AVText
              accessible={true}
              accessibilityLabel={
                Localized.Labels
                  .barcode_refocus_and_find_non_barcoded_items_revolve
              }
              aria-label={
                Localized.Labels
                  .barcode_refocus_and_find_non_barcoded_items_revolve
              }
              style={styles.revolveLabel}
            >
              {
                Localized.Labels
                  .barcode_refocus_and_find_non_barcoded_items_revolve
              }
            </AVText>
          </View>
          <View
            style={[
              styles.revolveEmptyCartIcon,
              Platform.OS === 'ios'
                ? {left: -9, alignItems: 'center'}
                : {alignItems: 'flex-end'},
            ]}
          >
            <EmptyCartRevolveSvg />
          </View>
        </View>
      </>
    ) : (
      <>
        <AVText
          accessible={true}
          accessibilityLabel={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          aria-label={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          style={styles.label}
        >
          {Localized.Labels.barcode_refocus_and_find_non_barcoded_items}
        </AVText>
        <EmptyCartSvg width="100%" height="100%" />
      </>
    )}
  </View>
);

const styles = StyleSheet.create({
  emptyText: {
    color: Styles.primaryColor,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    fontStyle: 'italic',
  },
  container: {
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m2,
  },
  mainContent: {
    flexDirection: 'row',
    marginVertical: Styles.Spacing.m2,
  },
  label: {
    margin: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
    color: Styles.darkColor,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  revolveLabel: {
    margin: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f7,
    textAlign: 'center',
    color: '#22212D',
    marginBottom: 15,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },

  revolveEmptyCartMainView: {
    flex: 1,
    justifyContent: 'space-between',
    backgroundColor: '#f4f4f4',
  },
  emptyCartDesc: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  revolveEmptyCartIcon: {
    justifyContent: 'center',
  },
});

export default EmptyCart;
