import moment, {Moment} from 'moment';
import {Platform} from 'react-native';
import AccountApi from 'src/api/AccountApi';
import AppApi from 'src/api/AppApi';
import AVLiveApi from 'src/api/AVLiveApi';
import EatItApi from 'src/api/EatItApi';
import GmaApi from 'src/api/GmaApi';
import MoblicoApi from 'src/api/MoblicoApi';
import PaymentApi from 'src/api/PaymentApi';
import PlatformApi from 'src/api/PlatformApi';
import ReceiptApi from 'src/api/ReceiptApi';
import AccountConstants from 'src/constants/AccountConstants';
import AppDispatcher from 'src/dispatchers/AppDispatcher';
import {authStore} from 'src/init';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import uuid from 'src/nativeModules/UUID';
import {
  fetchAccount,
  fetchPaymentCredentials,
  logout,
} from 'src/redux/slices/accountSlice';
import {store} from 'src/redux/store';
import MessageRepository from 'src/services/aws/MessageRepository';
import DealService from 'src/services/DealService';
import MoblicoService from 'src/services/MoblicoService';
import MoblicoTokenService from 'src/services/MoblicoTokenService';
import Settings from 'src/Settings';
import AccountStore, {AccountStoreData} from 'src/stores/AccountStore';
import {CCModel} from 'src/types/CCModel';
import {MessageType} from 'src/types/MessageType';
import {MoblicoPromotionType} from 'src/types/Promotions';
import {
  AccountUpdateModel,
  Auth0Signup,
  SetupModel,
} from 'src/types/SetupModel';
import {MarketAccountIdentifierTypes} from 'src/types/MarketAccountIdentifier';
import {getMobileAuth0Client, getWebAuth0Client} from '../nativeModules/Auth0';
import ReferralActions from './ReferralActions';
import Util from '../Util';
import {loadGiftFeed} from '../redux/thunks/snackThunks';
import Localized from 'src/constants/AppStrings';
import {Account} from '../types/serverTypes/Account';
import {MachineType} from '../types/MachineType';
import {type ReferralCampaign, ReferralStat} from '../types/Referral';
import {Sfecfg} from '../models/LocationFeatures';
import {RewardType} from '../types/Rewards';
import type {
  AdmPromotionResponse,
  AutoFundResponse,
} from '../types/PersistentStorageTypes';
import {GetAccountDispatchType} from '../dispatchers/DispatchTypes';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const BALANCE_THRESHOLD_SECONDS = 15;

const GooglePay = {
  App: 'google-pay-app',
  Web: 'google-pay-web',
};

type SaleDetailType = {
  salesItems: {
    salesItemId: string;
    name: string;
    price: string;
    quantity: string;
    modifiers?: Record<string, string>[];
  }[];
  msg: string;
  status: string;
  errorcode: string;
  referralId: string;
  orgId: string;
  orgName: string;
  locationId: string;
  transactionId: string;
  reason: string;
  name: string;
  date: string;
  transactionDate: string;
  creditCardType: string;
  creditCardPan: string;
  processor: string;
  amount: string;
  delivery: string;
  total: string;
  totalTax: string;
  totalTax1: string;
  tax1Label: string;
  totalTax2: string;
  tax2Label: string;
  totalTax3: string;
  tax3Label: string;
  totalTax4: string;
  tax4Label: string;
  totalDeposit: string;
  locationName: string;
  actionType: string;
  address: string;
  city: string;
  state: string;
  pickupInstructions: string;
  points: string;
  pickupTime: string;
  pickupDate: string;
  orderAheadStatus: string;
  pickupLocationName: string;
  pickupLocationId: string;
  ispaid: string;
  timer: string;
  discount: string;
  orderNumber: string;
  charityAmount: string;
  salesCharges: {
    calculatedAmount: number;
    salesItemId: string;
  }[];
  salesPayments: {
    transactionId: string;
    type: string;
    amount: number;
  }[];
};

class AccountActions {
  lastUpdated!: Moment;
  newBalanceChecked = 0;

  async setupAccount(
    setupModel: SetupModel,
    email: string,
    pinRequired: boolean,
  ) {
    const body = {
      ...setupModel,
      email,
      demographics: {
        birthMonth: setupModel?.birthMonth,
        birthYear: setupModel?.birthYear,
        gender: setupModel?.gender,
        jobTitle: setupModel?.jobTitle,
      },
      address: {
        city: setupModel?.city,
        country: setupModel?.country,
        state: setupModel?.state,
        street1: setupModel?.street1,
        street2: setupModel?.street2,
      },
      localization: {
        currency: setupModel?.currency,
        locale: setupModel?.locale,
        region: setupModel?.region,
      },
    };
    const response = setupModel?.payrollImportId
      ? await AccountApi.setupAccountPayrollImport(body)
      : await AppApi.setupAccount(setupModel);

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.PIN_CHANGED,
        data: {
          pinRequired: pinRequired,
          username: email,
        },
      });
      this.getCurrentPrivacyVersion()
        .then((resp) => {
          if (resp?.status === 'ok') {
            this.acceptPrivacy(
              setupModel.accountId ?? response?.accountId ?? '',
              resp?.version ?? '',
              Util.getCurrentDate(),
              !!setupModel?.payrollImportId,
            );
          }
        })
        .catch((error) => {
          CrashlyticsEvents.log(
            'Exception',
            'CreateAccountPrivacyPolicyHandler:getCurrentPrivacyVersion',
            generateErrorMessage(error),
            response,
          );
          Events.Error.trackEvent(
            'Exception',
            'CreateAccountPrivacyPolicyHandler:getCurrentPrivacyVersion',
            generateErrorMessage(error),
          );
        });
    }

    return response;
  }

  async setupAccountAuth0(signupModel: Auth0Signup) {
    const response = await AccountApi.setupAccountAuth0(signupModel);
    return response;
  }

  verifyEmail(
    email: string,
    buildType: string,
    locale: string,
    location: string,
    version: string,
    referralId: string | null | undefined,
  ) {
    return AccountApi.verifyEmail(
      email,
      buildType,
      locale,
      location,
      version,
      referralId,
    );
  }

  acceptPrivacy(
    accountId: string,
    version: string,
    date: string,
    isTally?: boolean,
  ) {
    if (isTally) {
      return GmaApi.acceptPrivacy(accountId, version);
    } else {
      return AccountApi.acceptPrivacy(accountId, version, date);
    }
  }

  async getCurrentPrivacyVersion(accountId?: string) {
    let response;
    try {
      response = (await GmaApi.getCurrentPrivacyVersion(accountId ?? '')) as {
        status: string;
        version: string;
        type: string;
      };
      if (response.status === 'ok') {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.PRIVACY_VERSION_LOADED,
          data: {
            response,
          },
        });
      }
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:getCurrentPrivacyVersion',
        generateErrorMessage(error),
        guid,
        {
          accountId: accountId || '',
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:getCurrentPrivacyVersion',
        generateErrorMessage(error),
        guid,
        {
          accountId: accountId || '',
        },
      );
    }
  }

  async getAccountLocation(locationId: string) {
    let response;
    try {
      response = await PlatformApi.fetchLocation(locationId);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchLocation',
        JSON.stringify({locationId}),
        JSON.stringify(response),
      );
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchLocation',
        JSON.stringify({locationId}),
        JSON.stringify(error),
      );
    }
  }

  async getAccountPromotions(locationId: string) {
    let response;
    try {
      response = await AppApi.loadPromotionsV2(locationId);
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'LoadPromotionsV2',
        JSON.stringify({locationId}),
        JSON.stringify(response),
      );
      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'LoadPromotionsV2',
        JSON.stringify({locationId}),
        JSON.stringify(error),
      );
    }
  }

  async getAccountOrg(
    locationId: string,
  ): Promise<AccountStoreData | undefined> {
    if (!locationId) {
      return;
    }
    let response;
    try {
      response = await PlatformApi.fetchOrg(locationId);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchOrg',
        JSON.stringify({locationId}),
        JSON.stringify(response),
      );
      return response as AccountStoreData;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchOrg',
        JSON.stringify({locationId}),
        JSON.stringify(error),
      );
    }
  }

  async getAccountDetails(response: Account) {
    try {
      const location = await this.getAccountLocation(response.locationId || '');
      const promotions = await this.getAccountPromotions(
        response.locationId || '',
      );
      const orgDetails = await this.getAccountOrg(response.orgId || '');
      return {
        ...response,
        ...location,
        ...promotions,
        orgUserKey: orgDetails?.userKey,
      };
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'FetchAccount:Error',
        generateErrorMessage(error),
        guid,
      );
    }
  }

  async loginLegacy(shortCircuit = false): Promise<any> {
    const accountId = await authStore.getAccountId();
    const response = await AccountApi.fetchAccount(accountId ?? '');
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'FetchAccount',
      JSON.stringify({accountId}),
      JSON.stringify(response),
    );

    if (response && response?.accountId) {
      const accountData = await this.getAccountDetails(response);

      if (accountData && shortCircuit) {
        return accountData;
      }
      await this.finishLogin(accountData as Account);
      return accountData;
    }
  }

  async logout() {
    try {
      if ((await authStore.getAccessToken()) !== null) {
        if (Platform.OS === 'web') {
          await authStore.clearSession();
          await getWebAuth0Client().logout({
            logoutParams: {
              returnTo: window.location.origin,
            },
          });
        } else if (Platform.OS === 'android') {
          await getMobileAuth0Client().webAuth.clearSession({
            federated: true,
          });
        }
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'Logout:Error',
        generateErrorMessage(error),
        guid,
      );

      Events.Error.trackEvent(
        'Exception',
        'Logout:Error',
        generateErrorMessage(error),
        guid,
      );
    }
    AccountStore.logout();
    store.dispatch(logout());
    await authStore.clearSession();

    AppDispatcher.handleViewAction({
      actionType: AccountConstants.LOGOUT,
    });
  }

  /**currently not in use */
  async loginGma(username: string, password: string, buildType: string) {
    const response = await GmaApi.loginGma(username, password, buildType);
    await this.finishLogin(response as unknown as Account);
    return response;
  }

  async finishLogin(response: Account) {
    if (response) {
      const qrResponse = (await GmaApi.getGmaQR(response.accountId)) as {
        status?: string;
        data: Record<string, string>;
      };
      if (qrResponse.status === 'ok') {
        response.qrcode = qrResponse.data.value;
      }

      if (response.localization?.region === 'ITA') {
        const taxResponse = (await AccountApi.getAccountIdentifier(
          response.accountId,
          MarketAccountIdentifierTypes.TaxId,
        )) as Array<{
          value: string;
        }>;
        if (taxResponse && taxResponse.length > 0) {
          response.taxid = taxResponse[0].value;
        } else {
          response.taxid = '';
        }
      }

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOGIN_GMA_SUCCESS,
        data: {
          response,
        },
      });
    }
  }

  loadAccountByMarketCard(code: string) {
    return GmaApi.loginMarketCard(code);
  }

  loadAccountByQR(qrCode: string) {
    return GmaApi.loginQR(qrCode);
  }

  async validateAndMergeAccounts(
    code: string,
    overrideEmail = false,
    locationId?: string,
  ) {
    const accountId = AccountStore.getAccountId();
    try {
      const response = await AccountApi.validateAndMergeAccounts(
        accountId,
        code,
        overrideEmail,
        locationId,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ValidateAndMergeAccounts',
        JSON.stringify({accountId, code}),
        JSON.stringify(response),
      );
      return response;
    } catch (error) {
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ValidateAndMergeAccounts',
        JSON.stringify({accountId, code}),
        JSON.stringify(error),
      );
      return error;
    }
  }

  async validatePinAndMergeAccounts(
    accountId: string,
    pin: string,
    code: string,
    locationId?: string,
    overrideEmail = false,
  ) {
    try {
      const response = await AccountApi.validatePinAndMergeAccounts(
        accountId,
        pin,
        code,
        locationId,
        overrideEmail,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ValidatePinAndMergeAccounts',
        JSON.stringify({accountId, pin, code, locationId}),
        JSON.stringify(response),
      );
      return response;
    } catch (error) {
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ValidatePinAndMergeAccounts',
        JSON.stringify({accountId, pin, code, locationId}),
        JSON.stringify(error),
      );
      return error;
    }
  }

  async addOrUpdateMarketCard(code: string) {
    const marketaccount = AccountStore.getAccountId();
    const currentCardsResponse = (await AccountApi.getAccountIdentifier(
      marketaccount,
      MarketAccountIdentifierTypes.ScanCode,
    )) as Array<{
      isDisabled: boolean;
      value: string;
    }>;

    if (!currentCardsResponse) {
      return [];
    }

    const currentCards = currentCardsResponse.filter(
      (mkii) => mkii.isDisabled === false,
    );

    if (currentCards.some((mkii) => mkii.value === code)) {
      // already have card, don't need to update
      return currentCardsResponse;
    }

    try {
      const result = await AccountApi.addOrUpdateMarketCard(
        marketaccount,
        code,
        MarketAccountIdentifierTypes.ScanCode,
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.MARKET_CARD_UPDATED,
        data: {
          marketCard: code,
        },
      });
      if (result?.okResponse === false) {
        return result;
      } else {
        return {status: 'ok'};
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:addOrUpdateMarketCard',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:addOrUpdateMarketCard',
        generateErrorMessage(error),
        guid,
      );
      return error;
    }
  }

  async deleteCreditCard(
    accountId: string,
    token: string,
    accountBalanceId: string,
    isDefault?: boolean,
  ) {
    try {
      const response = await AppApi.deleteCreditCard(
        accountId,
        token,
        accountBalanceId,
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'DeleteCreditCard',
        JSON.stringify({accountId, accountBalanceId, token}),
        JSON.stringify(response),
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.DELETE_CREDIT_CARD_SUCCESS,
        data: {
          response,
          isDefault,
        },
      });
      return {status: 'ok'};
    } catch (error) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.DELETE_CREDIT_CARD_FAIL,
        data: {
          error,
        },
      });
      return error;
    }
  }

  async updateDefaultToken(
    accountId: string,
    accountBalanceId: string,
    token: string,
  ) {
    try {
      const response = await AccountApi.updateDefaultToken(
        accountId,
        accountBalanceId,
        token,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'UpdateDefaultToken',
        JSON.stringify({accountId, accountBalanceId, token}),
        JSON.stringify(response),
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.UPDATE_DEFAULT_TOKEN_SUCCESS,
        data: {
          response,
        },
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:updateDefaultToken',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
          token,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:updateDefaultToken',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
          token,
        },
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'UpdateDefaultToken',
        JSON.stringify({accountId, accountBalanceId, token}),
        JSON.stringify(error),
      );
    }
  }

  async getProcessorCreds(accountId: string, isTally?: boolean) {
    if (isTally) {
      return await AppApi.getPaymentCredentialsTally(accountId);
    } else {
      return await AppApi.getPaymentCredentials(accountId);
    }
  }

  async addCreditCardTokenStripe(ccModel: CCModel) {
    const addCardResponse = await PaymentApi.addCreditCard(ccModel);
    Logger.Log.LogAPIEvent(
      'PaymentAPI',
      'AddCreditCard',
      JSON.stringify(ccModel),
      JSON.stringify(addCardResponse),
    );
    return this.addCreditCardSuccess(ccModel, addCardResponse);
  }

  addCreditCardSuccess(ccModel: CCModel, addCardResponse: unknown) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.ADD_CREDIT_CARD_SUCCESS,
      data: {
        ccModel,
        response: addCardResponse,
      },
    });
    return addCardResponse;
  }

  async addCreditCard(
    ccModel: CCModel,
    paymentCredentials: PaymentCredentials,
    useTally = false,
    isDefault = false,
  ): Promise<any> {
    if (
      Settings.processors[paymentCredentials.type] ===
      Settings.processors.heartland
    ) {
      return this.processHeartland(
        ccModel,
        paymentCredentials,
        useTally,
        isDefault,
      );
    }
  }

  async processHeartland(
    ccModel: CCModel,
    paymentCredentials: PaymentCredentials,
    useTally = false,
    isDefault = false,
  ) {
    const processorUrl = paymentCredentials.url;

    if (processorUrl) {
      const tokenResponse = await PaymentApi.getToken(
        ccModel,
        paymentCredentials.key,
        processorUrl,
      );
      Logger.Log.LogAPIEvent(
        'PaymentAPI',
        'GetToken',
        JSON.stringify({}),
        JSON.stringify(tokenResponse),
      );

      if (tokenResponse.card) {
        ccModel.CardNumberMask = tokenResponse.card.number;
      }

      ccModel.CardNumber = '';
      ccModel.CvvCode = '';

      if (tokenResponse.token_value) {
        await this.ensureDefaultBalance(ccModel.AccountId, useTally);
        const verifyResponse = await AppApi.setupAndAttachToken(
          AccountStore.getAccountBalanceId(),
          ccModel.AccountId,
          '',
          ccModel.Issuer,
          ccModel.ExpirationMonth,
          ccModel.ExpirationYear,
          ccModel.CardNumberMask,
          Settings.processors.heartland,
          tokenResponse.token_value,
          ccModel.BillingZip,
          useTally,
          isDefault,
        );
        Logger.Log.LogAPIEvent(
          'AppAPI',
          'SetupAndAttachToken',
          JSON.stringify({
            accountBalanceId: AccountStore.getAccountBalanceId(),
          }),
          JSON.stringify(verifyResponse),
        );

        if (!verifyResponse?.balanceTokenId) {
          throw verifyResponse;
        }

        if (verifyResponse) {
          return this.addCreditCardSuccess(ccModel, verifyResponse);
        } else {
          throw new Error('Verify Token Failed');
        }
      }

      throw new Error('Unable to tokenize card');
    } else {
      throw new Error('Empty ProcessorUrl!');
    }
  }

  itemVended(mdbKey: string, price: number, discount: number) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.ITEM_VENDED,
      data: {
        key: mdbKey,
        price,
        discount,
      },
    });
  }

  async loadPurchaseHistory(
    accountId: string,
    page = 1,
    transactionType?: string,
    saleOrderType?: string,
    pageSize = 25,
  ) {
    let response;
    try {
      let checkedAccountID = accountId;
      if (!accountId || accountId === '-1') {
        checkedAccountID = AccountStore.getAccountId();
      }

      response = (await AppApi.getAccountHistory(
        checkedAccountID,
        (page - 1) * pageSize,
        pageSize,
        transactionType,
        saleOrderType,
      )) as {items: unknown[]; metadata: unknown};
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'GetAccountHistory',
        JSON.stringify({
          accountId: checkedAccountID,
          offset: (page - 1) * pageSize,
          pageSize,
        }),
        JSON.stringify(response),
      );

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_PURCHASE_HISTORY_SUCCESS_GMA,
        data: {
          response,
          page,
          pageSize,
          transactionType,
          saleOrderType,
        },
      });
      return response;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:loadPurchaseHistory',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:loadPurchaseHistory',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'GetAccountHistory',
        JSON.stringify({
          accountId: AccountStore.getAccountId(),
          offset: (page - 1) * pageSize,
          pageSize,
        }),
        JSON.stringify(error),
      );
    }
  }

  emailReceipt(
    email: string,
    transactionId: string,
    type: string,
    region: string,
    balance: number,
  ) {
    return ReceiptApi.sendEmailReceipt(
      email,
      transactionId,
      type,
      region,
      balance,
    );
  }

  visitedEatItOrDeleteItWelcomeScreen() {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.VISITED_EAT_IT_OR_DELETE_IT_WELCOME,
    });
  }

  async getLocationByBeacon(code: string, gmaID: string) {
    return await AccountApi.beaconLookUp(code, gmaID);
  }

  deviceLookUp(locationId: string, query: string) {
    return AccountApi.deviceLookUp(locationId, query);
  }

  async getLocation(id: string, mkaId?: string): Promise<any> {
    const response = (await PlatformApi.getLocationFromId(id)) as unknown as {
      orgId: string;
      locationId: string;
      name: string;
      userkey: string;
      type: string;
      beaconId: string;
      orgUserKey: string;
      locationFeatures: Sfecfg;
    };

    Logger.Log.LogAPIEvent(
      'PlatformAPI',
      'GetLocationFromId',
      JSON.stringify({locationId: id}),
      JSON.stringify(response),
    );
    if (response) {
      const orgDetails = await this.getAccountOrg(response.orgId);
      if (mkaId && mkaId !== '-1') {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.LOCATION_UPDATED,
          data: {...response, orgUserKey: orgDetails?.userKey ?? ''},
        });
      } else {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.SELECT_SHOP,
          data: {...response, orgUserKey: orgDetails?.userKey},
        });
      }
    }

    return response;
  }

  async getLocationV1(id: string, mkaId?: string) {
    try {
      const response = await GmaApi.getLocationFromId(id, mkaId);
      return response;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:fetchLocation',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchLocation',
        generateErrorMessage(error),
      );
    }
  }

  async addLocationLink(accountId: string, locationId: string) {
    let response;
    try {
      response = await AccountApi.addLocationLink(accountId, locationId);
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddLocationLink',
        JSON.stringify({accountId, locationId}),
        JSON.stringify(response),
      );

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOCATION_LINK_ADDED,
        data: {id: locationId},
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:fetchLocation',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:addLocationLink',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddLocationLink',
        JSON.stringify({accountId, locationId}),
        JSON.stringify(error),
      );
    }
  }

  async linkLocation(accountId: string, locationId: string) {
    let response;
    try {
      response = await AccountApi.addLocationLink(accountId, locationId);
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddLocationLink',
        JSON.stringify({accountId, locationId}),
        JSON.stringify(response),
      );

      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOCATION_LINK_ADDED,
        data: {id: locationId},
      });
      return {
        success: true,
        data: response,
      };
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:fetchLocation',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:addLocationLink',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'AddLocationLink',
        JSON.stringify({accountId, locationId}),
        JSON.stringify(error),
      );
      return {
        success: false,
        error: error,
      };
    }
  }

  async removeLocationLink(marketAccount: string, location: MachineType) {
    const response = (await GmaApi.removeLocationLink(
      marketAccount,
      location.deviceId,
    )) as {status: string};

    if (response.status === 'ok') {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOCATION_LINK_REMOVED,
        data: location,
      });
    }

    return response;
  }

  async fetchLinkedLocations(accountId: string) {
    try {
      const response = (await AccountApi.getLinkedLocations(
        accountId,
      )) as MachineType[];
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LINKED_LOCATIONS_FETCHED,
        data: {
          response,
        },
      });
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'GetLinkedLocations',
        JSON.stringify({accountId}),
        JSON.stringify(response),
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:fetchLinkedLocations',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchLinkedLocations',
        generateErrorMessage(error),
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'GetLinkedLocations',
        JSON.stringify({accountId}),
        JSON.stringify(error),
      );
    }
  }

  async fetchNearbyLocations(latitude: number, longitude: number) {
    let response;
    try {
      response = (await PlatformApi.fetchNearbyLocations(
        latitude,
        longitude,
      )) as {items: MachineType[]};
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchNearbyLocations',
        JSON.stringify({latitude, longitude}),
        JSON.stringify(response),
      );
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.NEARBY_LOCATIONS_FETCHED,
        data: {
          response,
        },
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:fetchNearbyLocations',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:fetchNearbyLocations',
        generateErrorMessage(error),
      );
    }
  }

  emailFundingReceipt(
    to: string,
    amount: string,
    date: string,
    time: string,
    location: string,
    transID: string,
    newBalance: string,
    firstName: string,
  ) {
    return ReceiptApi.sendFundingReceipt(
      to,
      Settings.buildType,
      Settings.getLocale(),
      amount,
      date,
      time,
      location,
      transID,
      newBalance,
      firstName,
    );
  }

  emailReferralReceipt(
    to: string,
    receiverName?: string,
    balance?: number | string,
    locationName?: string,
    dateCreated?: string,
    currency?: string,
    transactionDate?: string,
    transactionId?: string,
    type?: string,
  ) {
    return ReceiptApi.sendReferralReceipt(
      to,
      Settings.buildType,
      'default',
      receiverName,
      balance,
      locationName,
      dateCreated,
      currency,
      transactionDate,
      transactionId,
      type,
    );
  }

  emailRefundReceipt(
    to: string,
    amount: string,
    date: string,
    time: string,
    last4ofCC: string,
    transID: string,
    newBalance: string,
  ) {
    return ReceiptApi.sendRefundReceipt(
      to,
      Settings.buildType,
      Settings.getLocale(),
      amount,
      date,
      time,
      last4ofCC,
      transID,
      newBalance,
    );
  }

  issueRefund(
    transactionId: string,
    marketAccount: string,
    transDate: string,
    accountBalanceId: string,
  ) {
    return AppApi.issueRefund(
      transactionId,
      marketAccount,
      transDate,
      accountBalanceId,
    );
  }

  updateAppEvent(
    accountId: string,
    eventName: string,
    utcEventDate: string,
    localEventDate: string,
    localEventDateTZ: string,
    additionalData?: string,
  ) {
    return AppApi.updateAppEvent(
      accountId,
      eventName,
      utcEventDate,
      localEventDate,
      localEventDateTZ,
      additionalData,
    );
  }

  deleteAccount(accountId: string, email: string) {
    return AppApi.deleteAccount(accountId, email);
  }

  async loadPurchaseDetail(
    accountId: string,
    transactionId: string,
    type: any,
    transactionDate: string,
    locationId?: string,
  ) {
    if (type === AccountConstants.SALE_TYPE) {
      const response = (await PlatformApi.loadSaleDetail(
        accountId,
        transactionId,
        transactionDate,
      )) as SaleDetailType;
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'LoadSaleDetail',
        JSON.stringify({accountId, transactionId, transactionDate}),
        JSON.stringify(response),
      );

      const updatedResponse = {
        Msg: response.msg,
        Status: response.status,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateString: response.transactionDate,
          Delivery: response.delivery,
          Amount: response.total,
          Tax: response.totalTax,
          Tax1: response.totalTax1,
          Tax1Label: response.tax1Label,
          Tax2: response.totalTax2,
          Tax2Label: response.tax2Label,
          Tax3: response.totalTax3,
          Tax3Label: response.tax3Label,
          Tax4: response.totalTax4,
          Tax4Label: response.tax4Label,
          Deposit: response.totalDeposit,
          Location: response.locationName,
          LocationAddress: response.address,
          LocationCity: response.city,
          LocationState: response.state,
          PickupNotes: response.pickupInstructions,
          Points: response.points,
          PickupTime: response.pickupTime,
          PickupDate: response.pickupDate,
          OrderStatus: response.orderAheadStatus,
          PickupLocation:
            response.pickupLocationName ?? response.pickupLocationId,
          IsPaid: response.ispaid,
          Timer: response.timer,
          Discount: response.discount,
          OrderNumber: response.orderNumber,
          Items: response.salesItems.map((item) => ({
            Id: item.salesItemId,
            Name: item.name,
            Price: item.price,
            Quantity: item.quantity,
            Modifiers: item.modifiers
              ? item.modifiers.map((modifier) => ({
                  Id: modifier.id,
                  ModifierName: modifier.modifiername,
                  Name: modifier.name,
                  Price: modifier.price,
                }))
              : [],
            TransactionChargeName: response.salesCharges
              ? Localized.Labels.sup_fee
              : '',
            TransactionChargeAmount: response.salesCharges
              .filter((a) => a.salesItemId === item.salesItemId)
              .map((a) => a.calculatedAmount)
              .reduce((a, b) => a + b, 0),
          })),
          charityAmount: response.charityAmount,
          Payments: response.salesPayments
            ? response.salesPayments.map((payment) => ({
                Id: payment.transactionId,
                Amount: payment.amount,
                Type: payment.type,
              }))
            : [],
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_SALE_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.FUNDING_TYPE ||
      type === AccountConstants.REDEMPTION_TYPE
    ) {
      const response = (await PlatformApi.loadFundingDetail(
        accountId,
        transactionId,
        transactionDate,
      )) as SaleDetailType;
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'LoadFundingDetail',
        JSON.stringify({accountId, transactionDate, transactionId}),
        JSON.stringify(response),
      );
      const updatedResponse = {
        Status: response.status,
        Msg: response.msg,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateLong: response.date,
          DateString: response.transactionDate,
          Amount: response.amount,
          CardIssuer: response.creditCardType,
          Name: response.creditCardPan,
          Processor: response.processor,
          Status: response.status,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_FUNDING_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (type === AccountConstants.REFUND_TYPE) {
      const response = (await PlatformApi.loadRefundDetail(
        accountId,
        transactionId,
        transactionDate,
      )) as SaleDetailType;
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'LoadRefundDetail',
        JSON.stringify({
          transactionDate,
          transactionId,
          accountId,
        }),
        JSON.stringify(response),
      );
      const updatedResponse = {
        Status: response.status,
        Msg: response.msg,
        ErrorCode: response.errorcode,
        Data: {
          Id: response.transactionId,
          DateLong: response.date,
          DateString: response.transactionDate,
          Amount: response.amount,
          CardIssuer: response.creditCardType,
          Name: response.creditCardPan,
          Processor: response.processor,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_REFUND_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.REFERRAL_TYPE ||
      type === AccountConstants.REFERRAL_SIGNUP_TYPE
    ) {
      const response = (await ReferralActions.fetchReferralSummary(
        transactionId,
      )) as Record<string, string>;

      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchReferralSummary',
        JSON.stringify({transactionId}),
        JSON.stringify(response),
      );

      const updatedResponse = {
        Status: response.status,
        Msg: response?.msg,
        ErrorCode: response?.errorcode,
        Data: {
          referralId: response.referralId,
          orgId: response.orgId,
          orgName: response.orgName,
          locationId: response.locationId,
          locationName: response.locationName,
          actionType: response.actionType,
          amount: response.amount,
          transactionDate: response.transactionDate,
          transactionId: response.transactionId,
          name: response.name,
          reason: response.reason,
          accountId: response.accountId,
          balance: response.balance,
          dateCreated: response.dateCreated,
          currency: response.currency,
          referenceId: response.referenceId,
          receiver: response.receiver,
          receiverName: response.receiverName,
          sender: response.sender,
          senderName: response.senderName,
        },
      };
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.LOAD_REFERRAL_DETAIL,
        data: {
          response: updatedResponse,
        },
      });
      return updatedResponse;
    } else if (
      type === AccountConstants.SEND_SNACK ||
      type === AccountConstants.SEND_SNACK_ACCEPT ||
      type === AccountConstants.SEND_SNACK_CANCEL ||
      type === AccountConstants.SEND_SNACK_EXPIRE ||
      type === AccountConstants.SEND_SNACK_REVERSE
    ) {
      const response = (await PlatformApi.loadSnackDetails(
        locationId,
        transactionId,
      )) as {status?: string; msg?: string; errorcode?: string};
      const updatedResponse = {
        Status: response?.status,
        Msg: response?.msg,
        ErrorCode: response?.errorcode,
        Data: response,
      };
      return updatedResponse;
    } else if (
      type === AccountConstants.SEND_SNACK ||
      type === AccountConstants.SEND_SNACK_ACCEPT ||
      type === AccountConstants.SEND_SNACK_CANCEL ||
      type === AccountConstants.SEND_SNACK_EXPIRE ||
      type === AccountConstants.SEND_SNACK_REVERSE ||
      type === AccountConstants.TRANSFER_IN ||
      type === AccountConstants.TRANSFER_OUT ||
      type === AccountConstants.ADJUSTMENT
    ) {
      return {
        Data: {
          transactionId,
          DateString: transactionDate,
          type,
        },
      };
    }

    return Promise.resolve();
  }

  async pinChanged(accountId: string, pin: string, pinRequired: boolean) {
    const response = await AccountApi.setPin(accountId, pin);
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'SetPin',
      JSON.stringify({accountId}),
      JSON.stringify(response),
    );
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.PIN_CHANGED,
      data: {
        pinRequired,
      },
    });
    return Promise.resolve(true);
  }

  async verifyPin(accountId: string, pin: string, qrValue: string) {
    const response = (await AccountApi.verifyPinQR(pin, qrValue)) as {
      accountId?: string;
      id?: string;
    };
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'VerifyPinQR',
      JSON.stringify({}),
      JSON.stringify(response),
    );
    return (
      response &&
      (response?.accountId === accountId || response?.id === accountId)
    );
  }

  verifyPinScanCode(pin: string, scanCode: string) {
    return AccountApi.verifyPinScanCode(pin, scanCode);
  }

  getAccountInfoByTally(accountId: string) {
    return AccountApi.fetchAccountTally(accountId);
  }

  async getRedemptionThresholds(accountId: string) {
    const response = (await AppApi.fetchRedemptionThresholds(
      accountId,
    )) as RewardType[];
    Logger.Log.LogAPIEvent(
      'AppAPI',
      'FetchRedemptionThresholds',
      JSON.stringify({accountId}),
      JSON.stringify(response),
    );
    if (response?.length > 0) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.THRESHOLDS_LOADED,
        data: {
          response: {
            data: response.map((i) => ({
              ...i,
              additionalpoints: i.additionalPoints || 0,
              redeemvalue: i.redeemValue || 0,
            })),
            msg: 'success',
            status: 'ok',
          },
        },
      });
    }

    return response;
  }

  async redeemRewards(accountId: string, points: number, transDate: string) {
    const response = await AccountApi.redeemRewards(
      accountId,
      points,
      transDate,
      Settings.buildType,
    );
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'RedeemRewards',
      JSON.stringify({accountId, points, transDate}),
      JSON.stringify(response),
    );
    await this.getBalance(accountId);
    return response;
  }

  async loadPromotions(locationId: string) {
    const response = (await AppApi.loadPromotionsV2(
      locationId,
    )) as AdmPromotionResponse;
    Logger.Log.LogAPIEvent(
      'AppAPI',
      'LoadPromotions',
      JSON.stringify({locationId}),
      JSON.stringify(response),
    );
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.PROMOTIONS_LOADED,
      data: {
        response,
      },
    });
    return response;
  }

  async loadMoblicoPromotions(email: string) {
    const nowDateTime = new Date().getTime();
    Events.Info.trackEvent('AccountActions:loadMoblicoPromotions start', {
      start_time: nowDateTime,
    });
    try {
      const token = await MoblicoTokenService.getToken(email);
      const originalResponse = (await MoblicoApi.getPromos(
        token,
      )) as Array<MoblicoPromotionType>;
      const dealResponse: Array<MoblicoPromotionType> =
        DealService.matchPromosWithDeals(
          AccountStore.getAccountId(),
          originalResponse,
        );
      const filteredResponse: Array<MoblicoPromotionType> =
        DealService.removePromosWithRedeemedDeals(dealResponse);
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.MOBLICO_PROMOS_LOADED,
        data: {
          response: filteredResponse,
        },
      });

      Events.Info.trackEvent('AccountActions:loadMoblicoPromotions end_time', {
        res_time:
          ((new Date().getTime() - nowDateTime) / 1000).toFixed(3) + 's',
        originalResponse,
        filteredResponse,
      });
    } catch (error) {
      // Only log the error if it's something other than a 404 from the request.
      const msgError = error as {networkResponse: {status: number}};
      if (
        msgError &&
        msgError.networkResponse &&
        msgError.networkResponse.status !== 404
      ) {
        CrashlyticsEvents.log(
          'Exception',
          'AccountActions:loadMoblicoPromotions',
          generateErrorMessage(msgError),
        );
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:loadMoblicoPromotions',
          generateErrorMessage(msgError),
        );
      }
    }
  }

  acceptMoblicoDeal(email: string, accountId: string, id: string) {
    return DealService.acceptMoblicoDeal(email, id);
  }

  async reloadConsumerData({
    accountId,
    accountBalanceId,
    email,
    firstLauch,
    newBalance,
    userInitiated,
  }: {
    accountId: string;
    accountBalanceId: string;
    email: string;
    firstLauch?: boolean;
    newBalance?: number;
    userInitiated?: boolean;
  }): Promise<any> {
    try {
      if (userInitiated || this.shouldUpdateAccount(newBalance)) {
        this.lastUpdated = moment();
        store.dispatch(fetchAccount(accountId));
        store.dispatch(fetchPaymentCredentials(accountId));

        const balanceResponse = (await this.getBalance(
          accountId,
          true,
          true,
          firstLauch,
        )) as {consumerEngagementId: string};

        // Need to call this after the balance/account has been loaded
        if (
          AccountStore.isConsumerEngagementEnabled() &&
          balanceResponse?.consumerEngagementId
        ) {
          // Load deals first because promotions depend on deals.
          await DealService.loadMoblicoDeals(email);
          await this.loadMoblicoPromotions(email);
        }

        this.getRedemptionThresholds(accountId);
        this.retrieveAutoFund(accountId, accountBalanceId);
        const privacyVersion = await this.getCurrentPrivacyVersion(accountId);
        this.loadPurchaseHistory(accountId, 1);
        this.loadReferralDetails();

        if (AccountStore.getLocationId()) {
          this.getLocation(
            AccountStore.getLocationId(),
            AccountStore.getAccountId(),
          );
          this.loadPromotions(AccountStore.getLocationId());
          store.dispatch(
            loadGiftFeed({
              locationId: AccountStore.getLocationId(),
            }),
          );
        }

        return {...balanceResponse, ...privacyVersion};
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:reloadConsumerData',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
          email,
          firstLauch: Boolean(firstLauch),
          newBalance: newBalance || '',
          userInitiated: Boolean(userInitiated),
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:reloadConsumerData',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
          email,
          firstLauch: Boolean(firstLauch),
          newBalance: newBalance || '',
          userInitiated: Boolean(userInitiated),
        },
      );
    }
    return Promise.resolve();
  }

  async loadReferralDetails() {
    if (AccountStore.getLocationId()) {
      try {
        const result = (await ReferralActions.getCampaignDetails(
          AccountStore.getLocationId(),
          true,
        )) as {items?: ReferralCampaign[]};
        Logger.Log.LogAPIEvent(
          'PlatformAPI',
          'FetchCampaignDetails',
          JSON.stringify({locationId: AccountStore.getLocationId()}),
          JSON.stringify(result),
        );
        const referralCampaign = result?.items ? result?.items?.[0] : undefined;
        const referralStat = (await ReferralActions.getReferralStats(
          AccountStore.getAccountId(),
        )) as ReferralStat | undefined;
        Logger.Log.LogAPIEvent(
          'PlatformAPI',
          'FetchUserReferralStat',
          JSON.stringify({accountId: AccountStore.getAccountId()}),
          JSON.stringify(referralStat),
        );
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.REFERRAL_DETAILS_LOADED,
          data: {
            referralStat,
            referralCampaign,
          },
        });
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'AccountActions:loadReferralDetails',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:loadReferralDetails',
          generateErrorMessage(error),
        );
      }
    }
  }

  // never gets here
  removeRecentOrder(index: number) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.REMOVE_RECENT_ORDER,
      data: {
        index,
      },
    });
  }

  shouldUpdateAccount(newBalance?: number) {
    if (!this.lastUpdated) {
      return true;
    }

    if (
      newBalance &&
      newBalance !== store.getState().account.account.defaultBalance.balance &&
      newBalance !== this.newBalanceChecked
    ) {
      // Record that we've already fetched this new balance, so we don't fetch it again
      this.newBalanceChecked = newBalance;
      return true;
    }

    const threshold = moment().subtract({
      seconds: BALANCE_THRESHOLD_SECONDS,
    });
    return this.lastUpdated.isBefore(threshold);
  }

  async getBalance(
    accountId: string,
    forceUpdate?: boolean,
    dispatch = true,
    firstLaunch = false,
  ) {
    if (forceUpdate || this.shouldUpdateAccount()) {
      try {
        let data;
        const response = await AccountApi.fetchAccount(accountId);
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'FetchAccount',
          JSON.stringify({accountId}),
          JSON.stringify(response),
        );

        if (response && response?.accountId) {
          try {
            const accountData = await this.getAccountDetails(response);
            if (accountData) {
              if (dispatch) {
                AppDispatcher.handleViewAction({
                  actionType: AccountConstants.GET_BALANCE_SUCCESS,
                  data: accountData as unknown as GetAccountDispatchType,
                });
              }

              try {
                // This needs to be after the dispatch so data in the AccountStore is populated
                // ORIGINAL CODE FOR MOBLICO
                // const consumerEngagementId = await MoblicoService.registerUser(
                //   response.email,
                //   response.accountId,
                //   response.consumerEngagementId,
                //   response.orgId,
                //   response.locationId,
                //   firstLaunch,
                // );
                const consumerEngagementId = await MoblicoService.registerUser(
                  accountData.email || '',
                  accountData.accountId,
                  accountData.consumerEngagementId || '',
                  accountData.orgId || '',
                  accountData.locationId || '',
                  firstLaunch,
                );
                // ORIGINAL CODE FOR MOBLICO
                // if (consumerEngagementId && consumerEngagementId !== response.consumerEngagementId))
                if (
                  consumerEngagementId &&
                  consumerEngagementId !== accountData.consumerEngagementId
                ) {
                  AppDispatcher.handleViewAction({
                    actionType: AccountConstants.CONSUMER_ENGAGEMENT_REGISTER,
                    data: consumerEngagementId,
                  });
                }
              } catch (error) {
                CrashlyticsEvents.log(
                  'Exception',
                  'AccountActions:getBalance',
                  generateErrorMessage(error),
                );
                Events.Error.trackEvent(
                  'Exception',
                  'AccountActions:getBalance',
                  generateErrorMessage(error),
                );
              }
            } else {
              AppDispatcher.handleViewAction({
                actionType: AccountConstants.LOGOUT,
              });
            }
            data = accountData;
          } catch (error) {
            AppDispatcher.handleViewAction({
              actionType: AccountConstants.GET_BALANCE_ERROR,
              data: {
                error,
              },
            });
          }
        } else if (!response) {
          AppDispatcher.handleViewAction({
            actionType: AccountConstants.LOGOUT,
          });
        }

        return data;
      } catch (error) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.GET_BALANCE_ERROR,
          data: {
            error,
          },
        });
      }
    } else {
      return {};
    }
  }

  async ensureDefaultBalance(accountId: string, useTally = false) {
    const balanceId = AccountStore.getAccountBalanceId();

    if (!balanceId) {
      try {
        // Create a balance if we can and update the account tokens.
        const balance = (await AccountApi.convertLegacyBalanceAndTokens(
          accountId,
          useTally,
        )) as {accountBalanceId: string} | undefined;

        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'ConvertLegacyBalanceAndTokens',
          JSON.stringify({accountId}),
          JSON.stringify(balance),
        );
        AccountStore.setAccountBalanceId(balance?.accountBalanceId ?? '');
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'AccountActions:ensureDefaultBalance',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'AccountActions:ensureDefaultBalance',
          generateErrorMessage(error),
        );
      }
    }
  }

  async addFunds(tokenId: string, amount: number, transdate: string) {
    const accountId = AccountStore.getAccountId();
    await this.ensureDefaultBalance(accountId);
    const response = (await AppApi.addFundsToken(
      accountId,
      AccountStore.getAccountBalanceId(),
      tokenId,
      amount,
      transdate,
    )) as {status: string};
    Logger.Log.LogAPIEvent(
      'AppAPI',
      'AddFundsToken',
      JSON.stringify({
        accountId,
        balanceId: AccountStore.getAccountBalanceId(),
        tokenId,
        amount,
        transdate,
      }),
      JSON.stringify(response),
    );
    return response.status === 'ok';
  }

  async createPaymentIntent(
    accountId: string,
    token: string,
    amount: number,
    transdate: string,
  ) {
    return await PaymentApi.createPaymentIntent(
      accountId,
      token,
      amount,
      transdate,
    );
  }

  async confirmPaymentIntent(
    accountId: string,
    paymentIntentId: string,
    transId: string,
  ) {
    return await PaymentApi.confirmPaymentIntent(
      accountId,
      paymentIntentId,
      transId,
    );
  }

  async addFundsPayroll(
    accountId: string,
    accountBalanceId: string,
    payrollIdentifierId: string,
    amount: number,
    transdate: string,
    transid = '',
  ) {
    return await AppApi.addFundsPayrollDeduct(
      accountId,
      accountBalanceId,
      amount,
      transdate,
      payrollIdentifierId,
      transid,
    );
  }

  chargeCard(
    accountId: string,
    amount: number,
    locationId: string,
    accountBalanceId: string,
    balanceTokenId: string,
  ) {
    return AppApi.chargeCard(
      accountId,
      amount,
      locationId,
      accountBalanceId,
      balanceTokenId,
    );
  }

  setupAutoFund(
    accountId: string,
    accountBalanceId: string,
    token: string,
    amount: number,
    fallBelowAmount: number,
  ) {
    return AccountApi.setupAutoFund(
      accountId,
      accountBalanceId,
      token,
      amount,
      fallBelowAmount,
    );
  }

  async retrieveAutoFund(
    accountId: string,
    accountBalanceId: string,
  ): Promise<any> {
    let response;
    try {
      response = (await AccountApi.retrieveAutoFund(
        accountId,
        accountBalanceId,
      )) as {isDisabled?: boolean; statusCode?: number};
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'RetrieveAutoFund',
        JSON.stringify({accountId, accountBalanceId}),
        JSON.stringify(response),
      );

      if (response?.statusCode === 404) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.AUTO_FUND_LOADED,
          data: {
            response: null,
          },
        });
        return;
      }

      if (response) {
        AppDispatcher.handleViewAction({
          actionType: AccountConstants.AUTO_FUND_LOADED,
          data: {
            response: {
              ...response,
              isDisabled: response.isDisabled ? 'Y' : 'N',
            } as AutoFundResponse,
          },
        });
      }

      return response;
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountActions:retrieveAutoFund',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
        },
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountActions:retrieveAutoFund',
        generateErrorMessage(error),
        guid,
        {
          accountId,
          accountBalanceId,
        },
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'RetrieveAutoFund',
        JSON.stringify({accountId, accountBalanceId}),
        JSON.stringify(error),
      );
    }
  }

  turnOffAutoFund(accountId: string, accountBalanceId: string) {
    return PaymentApi.turnOffAutoFund(accountId, accountBalanceId);
  }

  addFundsApplePay(
    apObject: string,
    accountId: string,
    _accountBalanceId: string,
    amount: number,
    transdate: string,
    test: boolean,
    processor: number,
  ) {
    Events.Info.trackEvent('AccountActions:addFundsApplePay', {
      apObject,
      accountId,
      accountBalanceId: AccountStore.getAccountBalanceId(),
      amount,
      transdate,
      processor,
      test,
    });

    if (processor !== Settings.processors.stripe) {
      if (typeof apObject === 'string') {
        apObject = JSON.parse(apObject);
      }
    }

    return PaymentApi.addFundsApplePay(
      apObject,
      accountId,
      amount,
      transdate,
      test,
      processor,
    );
  }

  addFundsGooglePay(
    token: string,
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transdate: string,
    test: boolean,
    processor: number,
  ): Promise<any> {
    return AppApi.addFundsThirdParty(
      accountId,
      accountBalanceId,
      amount,
      transdate,
      token,
      processor,
      test,
      Platform.OS === 'web' ? GooglePay.Web : GooglePay.App,
    );
  }

  getServerTime() {
    return AVLiveApi.getServerTime();
  }

  //actionType not use
  useGmaChanged(useGma: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.GMA_CHANGED,
      data: {
        useGma,
      },
    });
  }

  isDebugChanged(debug: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DEBUG_CHANGED,
      data: {
        isDebug: debug,
      },
    });
  }

  isDemoChanged(demo: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.DEMO_CHANGED,
      data: {
        demo,
      },
    });
  }

  async changePassword(
    accountId: string,
    password: string,
    newPassword: string,
  ) {
    try {
      const response = (await AccountApi.changePassword(
        accountId,
        newPassword,
        password,
      )) as {statusCode?: number};
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangePassword',
        JSON.stringify({accountId}),
        JSON.stringify(response),
      );
      if (response?.statusCode === 401) {
        return {
          InvalidPassword: true,
        };
      }
      return {
        Success: true,
      };
    } catch (error) {
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ChangePassword',
        JSON.stringify({accountId}),
        JSON.stringify(error),
      );
      return {
        InvalidPassword: (error as {statusCode: number}).statusCode !== 400,
      };
    }
  }

  async validatePin(email: string, pin: string): Promise<any> {
    const validateResponse = (await AccountApi.validateEmailPin(
      email,
      pin,
    )) as {accountId: string};
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'ValidateEmailPin',
      JSON.stringify({email}),
      JSON.stringify(validateResponse),
    );

    if (validateResponse && validateResponse.accountId) {
      return AccountApi.fetchAccountTally(validateResponse.accountId);
    }

    return validateResponse;
  }

  setPassword(accountId: string, password: string, useTally = false) {
    if (useTally) {
      return AccountApi.tallyChangePassword(accountId, password);
    }
    return AccountApi.changePassword(accountId, password);
  }

  changeEmail(accountId: string, newEmail: string, buildType: string) {
    return AccountApi.changeEmail(accountId, newEmail, buildType);
  }

  async updateInfo(accountId: string, info: AccountUpdateModel) {
    const response = await AccountApi.updateAccount(info, accountId);
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'UpdateAccount',
      JSON.stringify({accountId, info}),
      JSON.stringify(response),
    );

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.ACCOUNT_INFO_UPDATED,
        data: {
          info,
        },
      });
    }

    return response;
  }

  async updateSendSnackStatus(accountId: string, snackStatus: boolean) {
    let response = await AccountApi.updateSendSnackStatus(accountId, {
      sendaSnackEnabled: snackStatus,
    });
    Logger.Log.LogAPIEvent(
      'AccountAPI',
      'UpdateSendSnackStatus',
      JSON.stringify({accountId, sendaSnackEnabled: snackStatus}),
      JSON.stringify(response),
    );

    if (response) {
      AppDispatcher.handleViewAction({
        actionType: AccountConstants.ACCOUNT_SEND_SNACK_STATUS_UPDATED,
        data: {
          snackStatus: snackStatus,
        },
      });
      response = {...response, sendaSnackEnabled: snackStatus};
    }

    return response;
  }

  setBluetoothChecked(bluetoothChecked: boolean) {
    AppDispatcher.handleViewAction({
      actionType: AccountConstants.BLUETOOTH_CHECKED,
      data: {
        bluetoothChecked,
      },
    });
  }

  retrieveEatItLocation(baseUrl: string, orgUserKey: string) {
    return EatItApi.retrieveEatItLocation(baseUrl, orgUserKey);
  }

  retrieveEatItItems(
    baseUrl: string,
    eatItLocation: string,
    idfa: string,
    count = 5,
  ) {
    return EatItApi.retrieveEatItItems(baseUrl, eatItLocation, idfa, count);
  }

  voteEatItItem(
    baseUrl: string,
    item_id: string,
    locationId: string,
    skip: boolean,
    idfa: string,
    latitude: number,
    longitude: number,
    locationUserKey: string,
    like?: boolean,
  ) {
    return EatItApi.voteEatItItem(
      baseUrl,
      item_id,
      locationId,
      skip,
      idfa,
      latitude,
      longitude,
      locationUserKey,
      like,
    );
  }

  async deleteMessage(message: MessageType) {
    await MessageRepository.deleteMessage(AccountStore.getAccountId(), message);
  }

  async messageRead(message: MessageType) {
    message.read = true;
    await MessageRepository.updateMessage(AccountStore.getAccountId(), message);
  }

  async refreshMessages() {
    await MessageRepository.refresh();
  }

  async createSetupIntent(accountId: string) {
    return await AppApi.createSetupIntent(accountId);
  }

  async validateDiscountCode(locationId: string, scanCode: string) {
    return await PlatformApi.validateDiscountCode(locationId, scanCode);
  }

  async associateDiscountCode(scanCode: string) {
    const accountId = AccountStore.getAccountId();
    return await AccountApi.addOrUpdateMarketCard(
      accountId,
      scanCode,
      MarketAccountIdentifierTypes.ConsumerRole,
    );
  }

  async deleteAssociatedDiscountCode(identifierId: string) {
    const accountId = AccountStore.getAccountId();
    return await AccountApi.deleteAssociatedDiscountCode(
      accountId,
      identifierId,
    );
  }

  async fetchPaymentIntentClientSecret(
    accountId: string,
    accountBalanceId: string,
    amount: number,
    transactionDate: string,
    processor: number,
    test: boolean,
    type: string,
  ): Promise<any> {
    return await AppApi.fetchPaymentIntentClientSecret(
      '',
      accountId,
      accountBalanceId,
      amount,
      transactionDate,
      processor,
      test,
      type,
    );
  }
  async checkEmailExistence(emailId: string) {
    return await AccountApi.checkEmailExistence(emailId);
  }
}

export default AccountActions;
