import * as React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import Styles from '../Styles';
import {ScreenProps} from 'src/types/Screen';
import Settings from 'src/Settings';
import AccountStore from 'src/stores/AccountStore';
import {
  useGlobalize,
  withGlobalize,
  WithGlobalizeProps,
} from 'react-native-globalize';
import {GiftItem} from 'src/types/Snack';
import AVText from './AVText';
import {getDescriber} from './descriptor/DescriptorType';
import ChevronRight from 'src/components/img/svg/ChevronRight';

import UnReadlIcon from 'src/components/img/svg/UnReadIcon';
const margin = Settings.isRevolve()
  ? Styles.Spacing.m3
  : Styles.Spacing.m3 + Styles.Spacing.m2;

const SnackItemNewUI = ({
  snack,
  onPress,
  globalize,
}: ScreenProps &
  WithGlobalizeProps & {
    snack: GiftItem;
    onPress: () => void;
  }) => {
  const {Labels} = Localized;
  const describerprops = {
    snack,
    globalize,
  };

  const {formatCurrency} = useGlobalize();

  const renderDescription = (isSender: boolean) => {
    return isSender
      ? Labels.your_snack_sent
      : Labels.formatString(Labels.sent_you_snack, snack.senderName);
  };

  const renderMessage = (isSender: boolean) => {
    return isSender
      ? Labels.formatString(
          Labels.we_have_sent_your_snack,
          formatCurrency(snack.amount, AccountStore.getCurrency(), {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
          snack.email,
        )
      : getDescriber().snackMessageDescriptor(describerprops);
  };

  const isSender = snack.senderAccountId === AccountStore.getAccountId();
  const styles = StyleSheet.create({
    container: {
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: margin,
      marginTop: margin,
      paddingHorizontal: Styles.Spacing.m3,
      paddingVertical: Styles.Spacing.m3,
      justifyContent: 'center',
    },
    icon: {
      alignItems: 'center',
      borderRadius: 32,
      backgroundColor: Styles.darkBlueRevolveColor,
      width: 50,
      justifyContent: 'center',
      height: 50,
    },
    message: {
      flex: 1,
      color: Styles.black,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f8,
    },
    qrCodeImage: {
      width: 53,
      height: 48,
    },
    messageLeft: {
      flex: 1,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    containerVertical: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    topRight: {
      left: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    topRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    arrow: {
      width: 24,
      height: 24,
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    from: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    commonRow: {
      flexDirection: 'row',
    },
    titleHeaderView: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      // paddingBottom: 5,
    },
  });

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        getDescriber().getSnackItemStyleDescriptor()['container'],
        !Settings.isCanteen() && Styles.Style.shadow,
        !Settings.isRevolveAnd365Pay() && {
          borderBottomWidth: 1,
          borderBottomColor: '#9A9A9F',
        },
      ]}
      accessible
      accessibilityLabel="Inbox Snack Item"
    >
      {getDescriber().snackItemGiftBoxTemplateDescriptor(describerprops)}
      <View style={getDescriber().getSnackItemStyleDescriptor()['messageLeft']}>
        {!Settings.isRevolve() && (
          <View style={[styles.titleHeaderView]}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              style={
                getDescriber().getSnackItemStyleDescriptor()['titleHeader']
              }
            >
              {Localized.Buttons.send_a_snack}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              style={[
                getDescriber().getSnackItemStyleDescriptor()['date'],
                styles.commonRow,
              ]}
            >
              {getDescriber().snackItemDateDescriptor(describerprops)}
            </AVText>
          </View>
        )}
        <View style={styles.topRow}>
          {Settings.isRevolve() && (
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              style={getDescriber().getSnackItemStyleDescriptor()['from']}
            >
              {getDescriber().snackItemTitleDescriptor()}
            </AVText>
          )}
          <View
            style={getDescriber().getSnackItemStyleDescriptor()['topRight']}
          >
            {Settings.isRevolve() && (
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={getDescriber().getSnackItemStyleDescriptor()['date']}
              >
                {getDescriber().snackItemDateDescriptor(describerprops)}
              </AVText>
            )}
          </View>
        </View>
        <AVText
          style={getDescriber().getSnackItemStyleDescriptor()['subject']}
          numberOfLines={getDescriber().snackItemNumberDescriptor()}
        >
          {renderDescription(isSender)}
        </AVText>
        <AVText
          style={getDescriber().getSnackItemStyleDescriptor()['message']}
          numberOfLines={getDescriber().snackItemNumberDescriptor()}
        >
          {renderMessage(isSender)}
        </AVText>
      </View>
      {Settings.isRevolveAnd365Pay() ? (
        <View
          style={getDescriber().getSnackItemStyleDescriptor()['chevronRight']}
          accessible
          accessibilityLabel="Snack Item Icon"
        >
          <ChevronRight />
        </View>
      ) : (
        <View
          style={[
            styles.arrow,
            styles.commonRow,
            !snack.isRead && {
              left: -10,
            },
          ]}
        >
          <View style={{left: -3}}>{!snack.isRead && <UnReadlIcon />}</View>
          <ChevronRight />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default withGlobalize(SnackItemNewUI);
