import React from 'react';
import {Platform, StyleSheet, View} from 'react-native';
import Styles from '../Styles';
import AVFormattedCurrency from './AVFormattedCurrency';
import AVText from './AVText';
import {useAppSelector} from 'src/redux/hooks';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import {getTotalDisplayAmount} from '../../Util';

type Props = WithLocalizedProps & {
  isCentered?: boolean;
  smallText?: boolean;
  primaryColor?: boolean;
  isButton?: boolean;
};

const BalanceContainer: React.FC<Props> = ({
  strings,
  isCentered,
  smallText,
  primaryColor,
  isButton,
}) => {
  const defaultBalance = useAppSelector(
    (state) => state.account.account.defaultBalance,
  );
  const balances = useAppSelector((state) => state.account.account.balances);
  const totalAmount = getTotalDisplayAmount(balances);

  const getPrimaryColor = () => {
    return Platform.OS === 'web'
      ? {color: Styles.Colors.PayNew.primary01}
      : styles.textPrimary;
  };
  const textStyle = primaryColor ? getPrimaryColor() : styles.textWhite;
  return (
    <View
      style={[styles.container, isCentered && styles.center]}
      accessible={true}
      accessibilityRole={isButton ? 'button' : 'text'}
    >
      <View style={styles.balanceContainer}>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          style={[
            textStyle,
            primaryColor ? styles.label : styles.textWhite,
            smallText && Settings.isRevolve()
              ? styles.revolveLabelSmall
              : styles.labelSmall,
          ]}
        >
          {strings.Labels.your_balance}
        </AVText>
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={String(totalAmount)}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={[
            textStyle,
            styles.balance,
            smallText && Settings.isRevolve()
              ? styles.revolveBalanceSmall
              : styles.balanceSmall,
          ]}
          value={totalAmount}
          currency={defaultBalance?.currency ?? AccountStore.getCurrency()}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  balance: {
    alignSelf: 'flex-end',
    fontSize: Styles.Fonts.f3,
    fontWeight: '600',
  },
  balanceContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    top: Settings.isNewUI ? -3 : 0,
    left: Settings.isRevolve() && Platform.OS !== 'ios' ? 8 : 0,
  },
  balanceSmall: {
    fontSize: Styles.Fonts.f2,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  revolveBalanceSmall: {
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: Platform.OS === 'android' ? 'bold' : '600',
    color: '#111111',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  center: {
    alignItems: 'center',
  },
  container: {
    justifyContent: 'space-between',
  },
  label: {
    color: Styles.darkColor,
    alignSelf: 'flex-end',
    fontSize: Styles.Fonts.f1,
    position: 'relative',
  },
  labelSmall: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.robotoRegular,
    color: '#111111',
  },
  revolveLabelSmall: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  textPrimary: {
    color: Styles.balanceColor,
  },
  textWhite: {
    color: Styles.white,
  },
});

export default BalanceContainer;
