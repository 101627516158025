import React from 'react';
import {StyleSheet, View, Text, Platform} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {CartItem} from 'src/types/TransactionDetail';
import AVText from '../AVText';
import AccountStore from 'src/stores/AccountStore';
import {getDescriber} from './descriptor/DescriptorType';
type SaleDetailProps = {
  detail: CartItem;
  currency?: string;
  cartcheckOut?: boolean;
};

const SaleDetail: React.FC<SaleDetailProps> = ({
  detail,
  currency,
  cartcheckOut,
}) => {
  let itemPrice = parseFloat(detail.Price ?? '');
  let modifiers: JSX.Element[] | null = null;

  const saleCurrency = currency ?? AccountStore.getCurrency();

  if (detail.Modifiers && detail.Modifiers.length > 0) {
    detail.Modifiers.sort((a, b) => b.Price - a.Price);
    modifiers = detail.Modifiers.map((mod) => {
      if (cartcheckOut) {
        itemPrice -= mod.Price;
      }
      return (
        <View
          accessible={true}
          key={mod.Id}
          style={[
            getDescriber() && getDescriber()['SaleDetailRowStyleDescriptor'](),
            {width: '100%'},
          ]}
        >
          <AVText
            accessibilityLabel={mod.Name}
            maxFontSizeMultiplier={getDescriber().SaleDetailModMaxFontSizeMultipleDescriptor()}
            aria-label={mod.Name}
            style={getDescriber().SaleDetailModNameStyleDescriptor()}
          >
            {mod.Name}
          </AVText>
          {mod.Price > 0 &&
            getDescriber().getSaleAmountTextUI(
              mod.Price,
              saleCurrency,
              styles.modifierAmount,
              mod.Id,
            )}
        </View>
      );
    });
  }
  const supLabel = (
    <View>
      <AVText
        accessible={true}
        style={[
          styles.rowContainer,
          {justifyContent: 'flex-start', marginLeft: 20},
        ]}
        maxFontSizeMultiplier={getDescriber().SaleDetailSupLblMaxFontSizeMultipleDescriptor()}
        accessibilityLabel={`${Localized.Labels.sup_fee} label`}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.sup_fee} label, text`}
      >
        {Localized.Labels.sup_fee}{' '}
        <AVFormattedCurrency
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          numberOfLines={1}
          style={[
            styles.rowContainer,
            Platform.OS !== 'web' ? {marginLeft: -20} : {marginLeft: 0},
          ]}
          accessible={true}
          accessibilityLabel={`SUP amount $${detail.TransactionChargeAmount}`}
          aria-label={`SUP amount $${detail.TransactionChargeAmount}, text`}
          value={detail.TransactionChargeAmount || 0}
          currency={AccountStore.getCurrency()}
        />
      </AVText>
    </View>
  );
  return (
    <>
      <View
        accessible={true}
        style={[
          styles.container,
          getDescriber() && getDescriber()['SaleDetailRowStyleDescriptor'](),
        ]}
      >
        <View style={styles.leftView}>
          <Text
            accessibilityLabel={detail.Name}
            maxFontSizeMultiplier={getDescriber().SaleDetailModMaxFontSizeMultipleDescriptor()}
            aria-label={detail.Name}
            style={
              getDescriber() &&
              getDescriber()['SaleDetailNameStyleDescriptor']()
            }
          >
            {detail.Name}
          </Text>
        </View>
        <View style={styles.rightView}>
          <AVFormattedCurrency
            value={itemPrice}
            style={[
              getDescriber() &&
                getDescriber()['SaleDetailAmtStyleDescriptor'](),
            ]}
            accessibilityLabel={`$${itemPrice}`}
            aria-label={`$${itemPrice}`}
            currency={saleCurrency}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
          />
        </View>
      </View>
      {(detail.TransactionChargeAmount || 0) > 0 && supLabel}
      {modifiers}
    </>
  );
};

const styles = StyleSheet.create({
  modifierAmount: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
  },

  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    paddingRight: Styles.Spacing.m2,
    color: Styles.lightGray,
  },
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  rightView: {
    flex: 0.3,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
export default SaleDetail;
