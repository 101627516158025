import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import AppRoutes from 'src/AppRoutes';
import SnackDetailScreen from 'src/components/screens/sendSnack/SnackDetailScreen';
import RewardsScreen from 'src/components/screens/RewardsScreen';

const Stack = createStackNavigator();
export default function RewardsNavigator() {
  return (
    <Stack.Navigator
      initialRouteName={AppRoutes.Rewards}
      screenOptions={{
        headerShown: false,
        presentation: 'transparentModal',
        cardStyle: {
          backgroundColor: 'transparent',
        },
        cardOverlayEnabled: true,
      }}
    >
      <Stack.Screen name={AppRoutes.Rewards} component={RewardsScreen} />
      <Stack.Screen
        name={AppRoutes.SnackDetail}
        component={SnackDetailScreen}
      />
    </Stack.Navigator>
  );
}
