import RevolveDescriptor from './inbox-revolve';
import RedesignDescriptor from './inbox-redesign';
import CanteenDescriptor from './inbox-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import Deal from 'src/models/Moblico/Deal';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export interface DealDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  deal: Deal;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
