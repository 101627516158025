import Styles from '../../../../Styles';
import {StyleSheet} from 'react-native';
import Localized from 'src/constants/AppStrings';

export function getItemContainerStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    itemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: Styles.Spacing.m3,
      borderBottomWidth: StyleSheet.hairlineWidth,
      borderBottomColor: Styles.lightGray,
    },
    blockedName: {
      fontFamily: Styles.FontFamily.robotoRegular,
      alignSelf: 'center',
      fontSize: Styles.Fonts.f1,
    },
  };
}

export function getUnBlockUserStyle(): {
  [index: string]: {[index: string]: number | string} | number | string;
} {
  return {
    btnUnblock: {},
    color: Styles.lightGray,
    btnUnblockContainer: {},
  };
}

export function getNoBlockedUsers(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    noCardsContainer: {},
  };
}

export function getBlockedUsersList(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    listWrapper: {
      margin: 16,
      marginTop: 32,
      borderColor: '#EAEAEB',
    },
    listStyle: {
      flex: 1,
    },
    listShadow: Styles.Style.shadow,
  };
}

export function getBlockedUserTitle() {
  return Localized.Labels.blocked_users;
}

export default {
  getItemContainerStyle,
  getUnBlockUserStyle,
  getBlockedUsersList,
  getBlockedUserTitle,
};
