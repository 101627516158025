import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import ShopIcon from 'src/components/img/svg/ShopIcon';
import Localized from 'src/constants/AppStrings';

import Settings from 'src/Settings';
import ShopIconPay from 'src/components/img/svg/ShopIconpay';

export type NearbyLocationType = {
  locationId?: string;
  campusId?: string;
  displayName: string;
  distance: number;
  units?: string;
};

export enum LocationDistanceUnit {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC',
}

type NearbyLocationViewProps = {
  nearbyLocation: NearbyLocationType;
  selected?: boolean;
};

const width = 325;

const NearbyLocationView = (props: NearbyLocationViewProps) => {
  const buildType = Settings.buildType;

  return (
    <View
      style={[
        styles.container,
        styles.tileDesignView,
        props.selected && {
          borderColor:
            buildType === 'canteen'
              ? '#003349'
              : Styles.Colors.PayNew.primary01,
          backgroundColor:
            buildType === 'canteen' ? '#e2f1e8' : 'rgba(0, 109, 255, 0.15)',
        },
      ]}
      accessible={true}
      accessibilityLabel={`NearbyLocation${props.nearbyLocation.displayName}`}
      accessibilityRole="button"
    >
      <View style={styles.icon}>
        {Settings.is365Pay() ? (
          <ShopIconPay size={25} />
        ) : (
          <ShopIcon size={Styles.Spacing.m4} />
        )}
      </View>

      <AVText
        numberOfLines={1}
        accessibilityLabel={props.nearbyLocation.displayName}
        aria-label={`${props.nearbyLocation.displayName}, text`}
        accessibilityRole="text"
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={[
          styles.nameText,
          Settings.isRevolveAnd365Pay() && styles.topMargin,
          Settings.is365Pay() && {
            fontSize: 14,
            fontFamily: Styles.FontFamily.aeonikRegular,
            color: '#111',
          },
        ]}
      >
        {props.nearbyLocation.displayName}
      </AVText>
      <AVText
        numberOfLines={1}
        accessibilityLabel={props.nearbyLocation?.distance.toFixed(1)}
        aria-label={`${props.nearbyLocation?.distance.toFixed(1)}, text`}
        accessibilityRole="text"
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={[
          styles.distanceText,
          Settings.isRevolveAnd365Pay() && styles.topMargin,
          Settings.is365Pay() && {
            fontSize: 14,
            fontFamily: Styles.FontFamily.aeonikRegular,
            color: '#707070',
          },
        ]}
      >
        {props.nearbyLocation?.distance.toFixed(1) +
          ' ' +
          (Settings.is365Pay() ? 'mi' : Localized.Labels.miles)}
      </AVText>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m2,
    height: 98,
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m3,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    borderRadius: Styles.Spacing.m2,
    borderColor: Styles.lightGray,
    borderWidth: 2.5,
  },
  icon: {
    height: Styles.Spacing.m4,
    marginTop: -Styles.Spacing.m2,
  },
  tileDesignView: {
    backgroundColor: '#fff',
    shadowOffset: {width: 0, height: 1},
    width: width / 2.1,
    borderColor: Styles.white,
    shadowOpacity: 0.4,
    elevation: 4,
    shadowColor: '#000',
    shadowRadius: 2,
  },
  nameText: {
    color: Styles.black,
    fontWeight: '400',
    width: 160,
    fontSize: Styles.Fonts.f1,
    textAlignVertical: 'top',
    marginRight: Styles.Spacing.m1,
    height: Styles.Spacing.m3 + Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  distanceText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
    fontWeight: '400',
    width: Styles.Heights.h6 * 2,
    textAlignVertical: 'top',
    height: Styles.Spacing.m3 + Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  topMargin: {
    marginTop: Styles.Spacing.m1,
  },
});

export default NearbyLocationView;
