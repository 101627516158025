import React from 'react';
import {ScrollView, View, Dimensions, Platform} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import uuid from 'src/nativeModules/UUID';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import KeyboardAvoidingView from '../../elements/365KeyboardAvoidingView';
import {getMonthText} from '../../elements/account/BirthMonthDropdown';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import ShadowContainer from '../../elements/ShadowContainer';
import withIsConnected from '../../hoc/withIsConnected';
import Styles from '../../Styles';
import {IsConnectedProps} from 'src/types/Screen';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {AppDispatch} from 'src/redux/store';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {authStore} from 'src/init';
import CrashlyticsEvents from 'src/logging/Crashlytics';

import {getDescriber} from './descriptor/DescriptorType';
type ManageAccountProps = IsConnectedProps & {
  dispatch: AppDispatch;
  navigation?: NavigationProp<ManageAccount>;
};
type ManageAccountState = {
  firstName: string;
  lastName: string;
  gender: string;
  birthMonth: string;
  birthYear: string;
  birthMonthYear: string;
  birthMonthYearError: string;
  jobTitle: string;
  locale: string;
  previousRoute: string;
  genderMenuVisible: boolean;
  birthMonthMenuVisible: boolean;
  birthYearMenuVisible: boolean;
  localeMenuVisible: boolean;
  isSSO: boolean;
};

const maxWidth = Dimensions.get('screen').width - Styles.Spacing.m3 * 2;
const MAX_NAME_LENGTH = 20;

class ManageAccount extends React.Component<
  ManageAccountProps,
  ManageAccountState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ManageAccountProps) {
    super(props);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.convertMonthToText = this.convertMonthToText.bind(this);
    this.getBirthdate = this.getBirthdate.bind(this);
    this.genderSelected = this.genderSelected.bind(this);
    this.birthMonthSelected = this.birthMonthSelected.bind(this);
    this.birthYearSelected = this.birthYearSelected.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleJobTitleChange = this.handleJobTitleChange.bind(this);
    this.birthMonthYearSelected = this.birthMonthYearSelected.bind(this);
    this.checkForBtnDisabled = this.checkForBtnDisabled.bind(this);
    this.handleBirthMonthYearChange =
      this.handleBirthMonthYearChange.bind(this);
    this.localeSelected = this.localeSelected.bind(this);
    this.state = {
      firstName: AccountStore.getFirstName(),
      lastName: AccountStore.getLastName(),
      gender: AccountStore.getGender(),
      birthMonth: AccountStore.getBirthMonth(),
      birthYear: String(AccountStore.getBirthYear()),
      birthMonthYear: AccountStore.getBirthMonth()
        ? AccountStore.getBirthMonth() +
          '/' +
          String(AccountStore.getBirthYear())
        : null,
      jobTitle: AccountStore.getJobTitle(),
      locale: AccountStore.getLocale(),
      previousRoute: '',
      birthMonthYearError: '',
      genderMenuVisible: false,
      birthMonthMenuVisible: false,
      birthYearMenuVisible: false,
      localeMenuVisible: false,
      isSSO: false,
    };
  }
  async handleIsSSO() {
    const isSSO = await authStore.getIsSSO();
    this.setState({isSSO});
  }

  componentDidMount(): void {
    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );
    this.setState({previousRoute});
    this.handleIsSSO();
  }

  handleMenuVisibilityChange = (visible: boolean, whichMenu: string) => {
    switch (whichMenu) {
      case 'gender':
        this.setState({genderMenuVisible: visible});
        break;
      case 'birthMonth':
        this.setState({birthMonthMenuVisible: visible});
        break;
      case 'birthYear':
        this.setState({birthYearMenuVisible: visible});
        break;
      case 'locale':
        this.setState({localeMenuVisible: visible});
        break;
      default:
        break;
    }
  };

  localeSelected(locale: string) {
    this.setState({
      locale,
    });
  }

  genderSelected(gender: string) {
    this.setState({
      gender: gender,
      genderMenuVisible: false,
    });
  }

  birthMonthSelected(birthMonth: string) {
    this.setState({
      birthMonth: birthMonth,
      birthMonthMenuVisible: false,
    });
  }

  birthYearSelected(birthYear: string) {
    this.setState({
      birthYear: birthYear,
      birthYearMenuVisible: false,
    });
  }
  declare birthMonthYearPrevState: string;

  birthMonthYearSelected(birthMonthYear: string) {
    this.birthMonthYearPrevState = this.state.birthMonthYear;
    birthMonthYear = this.addSlash(birthMonthYear);
    const error = this.validateMonthYearInput(birthMonthYear);
    const [month, year] = birthMonthYear.split('/');
    this.setState({
      birthMonthYear: birthMonthYear,
      birthMonthYearError: error,
      birthMonth: month,
      birthYear: year,
    });
  }

  addSlash(birthMonthYear: string) {
    if (this.isSuffixHyphen(birthMonthYear)) {
      birthMonthYear = birthMonthYear + '/';
    } else if (this.isPrefixHyphen(birthMonthYear)) {
      birthMonthYear =
        birthMonthYear.substring(0, birthMonthYear.length - 1) +
        '/' +
        birthMonthYear.charAt(birthMonthYear.length - 1);
    }
    return birthMonthYear;
  }

  isPrefixHyphen(birthMonthYear: string) {
    return (
      this.birthMonthYearPrevState?.length == 2 && birthMonthYear.length == 3
    );
  }

  isSuffixHyphen(birthMonthYear: string) {
    return (
      this.birthMonthYearPrevState?.length == 1 && birthMonthYear.length == 2
    );
  }

  validateMonthYearInput(birthMonthYear) {
    let birthMonthYearError: string;
    if (!this.isValidMonthYear(birthMonthYear)) {
      birthMonthYearError = Localized.Errors.birthMonthYearError;
    } else if (!this.isValidYear(birthMonthYear)) {
      birthMonthYearError = Localized.Errors.birthMonthYearErrorExceedsYear;
    } else {
      birthMonthYearError = '';
    }
    return birthMonthYearError;
  }

  isValidMonthYear(input) {
    // Regular expression to match mm/yyyy format
    const pattern = /^(0[1-9]|1[0-2])\/\d{4}$/;

    // Test the input against the pattern
    if (!pattern.test(input)) {
      return false; // Not in mm/yyyy format
    }

    return true; // Valid mm/yyyy format and year is not greater than current year
  }

  isValidYear(input) {
    // Extract month and year from the input
    const [month, year] = input.split('/').map(Number);
    // Restrict input only 80 years below current year
    const YEARS_BACK = 80;
    // Get the current year
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 1 - YEARS_BACK;
    // Validate that the year is not greater than the current year
    if (year >= currentYear || month > 12 || year <= startYear) {
      return false; // Year is greater than current year
    } else {
      return true;
    }
  }

  getBirthdate(): string {
    const birthMonth = AccountStore.getBirthMonth();
    const birthYear = AccountStore.getBirthYear();
    const birthMonthText = this.convertMonthToText(birthMonth);
    return `${birthMonthText} ${birthYear || ''}`;
  }

  convertMonthToText(month) {
    if (!month) {
      return '';
    }

    return getMonthText(false, month);
  }

  checkForBtnDisabled() {
    if (this.state && this.state.birthMonthYearError) return true;
    else return false;
  }

  handleSave() {
    const errorMessage = this.validate();

    if (errorMessage) {
      alertError(errorMessage);
    } else {
      this.saveChanges();
    }
  }

  validate() {
    if (!this.state.firstName || !this.state.lastName) {
      return Localized.Errors.all_fields_required;
    }

    return null;
  }

  saveChanges = async () => {
    const {dispatch} = this.props;
    dispatch(showSpinner({}));
    let response;
    try {
      response = await ActionsFactory.getAccountActions().updateInfo(
        AccountStore.getAccountId(),
        {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          gender: this.state.gender ? this.state.gender : undefined,
          birthMonth:
            this.state.birthMonth !== '00'
              ? +this.state.birthMonth!
              : undefined,
          birthYear: this.state.birthYear ? +this.state.birthYear : undefined,
          jobTitle: this.state.jobTitle ? this.state.jobTitle : undefined,
          locale: this.state.locale ? this.state.locale : undefined,
        },
      );

      FirebaseAnalytic.trackEvent('saveChanges', 'ManageAccountScreen', {
        ...this.props,
        ...this.state,
        response,
      });

      if (response) {
        NavActions.pop();
      } else {
        alertError(Localized.Errors.unable_to_save_changes);
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'ManageAccountScreen:SaveChanges',
        generateErrorMessage(error),
        guid,
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'ManageAccountScreen:SaveChanges',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.unable_to_save_changes, guid);
    } finally {
      dispatch(hideSpinner());
    }
  };
  handleChangeEmail() {
    NavActions.push(AppRoutes.ChangeEmail);
  }

  getDisplayGender(value: string) {
    if (value === 'Male') {
      return Localized.Labels.male;
    } else if (value === 'Female') {
      return Localized.Labels.female;
    }

    return Localized.Labels.other;
  }

  handleLastNameChange(text) {
    this.setState({lastName: text});
  }

  handleFirstNameChange(text) {
    this.setState({firstName: text});
  }

  handleJobTitleChange(text) {
    this.setState({jobTitle: text});
  }

  handleBirthMonthYearChange(text) {
    this.setState({birthMonthYearError: text});
  }

  render() {
    const {getManageAcntStyleDescriptor} = getDescriber();
    return (
      <BackSubheader
        title={Localized.Labels.personal_info}
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
      >
        <KeyboardAvoidingView behavior="height" insideTab>
          <ScrollView style={Styles.Style.flex}>
            <ShadowContainer
              style={[
                getManageAcntStyleDescriptor() &&
                  getManageAcntStyleDescriptor()['content'],
                Platform.OS !== 'web' && {
                  backgroundColor: Styles.tabBarBackgroundColor,
                },
                Styles.Style.maxWidthContainer,
              ]}
            >
              <View style={Settings.buildType === 'revolve' ? {flex: 1} : null}>
                {getDescriber().getFirstNameManageAccount(
                  this.state,
                  MAX_NAME_LENGTH,
                  this.handleFirstNameChange,
                )}
                {getDescriber().getLastNameManageAccount(
                  this.state,
                  MAX_NAME_LENGTH,
                  this.handleLastNameChange,
                )}

                {getDescriber().getGenderDropDownManageAccount(
                  this.state,
                  this.genderSelected,
                  this.handleMenuVisibilityChange,
                )}

                {getDescriber().getBirthMonthDropDownManageAccount(
                  this.state,
                  this.birthMonthSelected,
                  this.handleMenuVisibilityChange,
                )}

                {getDescriber().getBirthYearDropDownManageAccount(
                  this.state,
                  this.birthMonthYearSelected,
                  this.birthYearSelected,
                  this.handleMenuVisibilityChange,
                )}

                {getDescriber().getJobTitleManageAccount(
                  this.state,
                  this.handleJobTitleChange,
                )}

                {getDescriber().getSystemLanguageDropDownManageAccount(
                  maxWidth,
                  this.state,
                  this.localeSelected,
                  this.handleMenuVisibilityChange,
                )}

                {Settings.buildType !== 'revolve' &&
                  this.state.isSSO !== true &&
                  getDescriber().getChangeEmail(this.handleChangeEmail)}
              </View>
            </ShadowContainer>
            {Settings.isRevolve() && (
              <View
                style={
                  getManageAcntStyleDescriptor() &&
                  getManageAcntStyleDescriptor()['saveBtnContainer']
                }
              >
                {this.props.isConnected && (
                  <RoundedButton
                    buttonType={ButtonType.action}
                    text={Localized.Buttons.save}
                    onPress={this.handleSave}
                    accessible={true}
                    addShadow={false}
                    accessibilityLabel={Localized.Buttons.save}
                    accessibilityRole="button"
                    role="button"
                    color={Styles.darkGreenRevolve}
                    aria-label={Localized.Buttons.save}
                  />
                )}
              </View>
            )}
          </ScrollView>
        </KeyboardAvoidingView>
        {Settings.buildType !== 'revolve' &&
          this.props.isConnected &&
          getDescriber().getSaveBtnManageAccount(
            this.handleSave,
            this.checkForBtnDisabled,
          )}
      </BackSubheader>
    );
  }
}

const ConnectedManageAccount = connect(null, (dispatch: AppDispatch) => ({
  dispatch,
}))(ManageAccount);
export default withForwardedNavigationParams()(
  withIsConnected(ConnectedManageAccount),
);
