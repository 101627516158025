import React, {useState} from 'react';
import Styles from '../Styles';
import {
  StyleSheet,
  Text,
  View,
  StyleProp,
  TextStyle,
  ViewStyle,
  NativeSyntheticEvent,
  TextInputFocusEventData,
} from 'react-native';
import {TextInput} from 'react-native-paper';
import Settings from 'src/Settings';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import CloseEyeIcon from 'src/components/img/svg/CloseEyeIcon';
import OpenEyeIcon from 'src/components/img/svg/OpenEyeIcon';

type TextInputProps = React.ComponentProps<typeof TextInput> & {
  placeholder?: string;
  title?: string;
  showHelpText?: boolean;
  helpText?: string;
  hasErrorMsg?: boolean;
  errorMsg?: string | null;
  rightIcon?: React.ReactNode;
  rightIconPressed?: () => void;
  outlineStyle?: StyleProp<ViewStyle>;
  activeColor?: string;
  containerStyle?: StyleProp<ViewStyle>;
  fontSize?: number;
  helperStyle?: StyleProp<ViewStyle | TextStyle>;
  children?: React.ReactNode;
  multiline?: boolean;
  textStyle?: StyleProp<TextStyle>;
  errorTextStyle?: StyleProp<TextStyle>;
  labelStyle?: StyleProp<TextStyle>;
  onBlur?: (e) => void;
  showShadow?: boolean;
  contentStyle?: StyleProp<ViewStyle>;
  rightIconStyle?: StyleProp<ViewStyle | TextStyle>;
};

const CustomTextInput = (props: TextInputProps) => {
  const [isSecureTextEntry, setIsSecureTextEntry] = useState<boolean>(true);

  const getFontStyle = (): StyleProp<TextStyle> => {
    if (Settings.buildType === BuildTypeConstants.default) {
      return {
        fontFamily: Styles.FontFamily.aeonikRegular,
        fontSize: props.fontSize ? props.fontSize : Styles.Fonts.f8,
        fontWeight: '400',
        color: Styles.black,
      };
    } else {
      return null;
    }
  };

  const getHelperText = () => {
    if (
      (!props.hasErrorMsg || props.errorMsg === null) &&
      props.showHelpText &&
      props.helpText
    ) {
      return (
        <Text
          accessible={true}
          accessibilityLabel={props.helpText}
          accessibilityRole="text"
          aria-label={`${props.helpText}, text`}
          style={[styles.helpText, props.helperStyle]}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        >
          {props.helpText}
        </Text>
      );
    }
    return null;
  };
  const passwordIcon = () => {
    if (Settings.is365Pay()) {
      return isSecureTextEntry ? () => <CloseEyeIcon /> : () => <OpenEyeIcon />;
    } else {
      return isSecureTextEntry ? 'eye-off' : 'eye';
    }
  };

  const getRightIcon = () => {
    return props.secureTextEntry ? (
      <TextInput.Icon
        icon={passwordIcon()}
        onPress={() => setIsSecureTextEntry(!isSecureTextEntry)}
      />
    ) : props.rightIcon ? (
      <TextInput.Icon
        icon={() => props.rightIcon}
        style={[{right: 0}, props.rightIconStyle]}
        onPress={props.rightIconPressed}
      />
    ) : null;
  };

  const getErrorMessage = () => {
    if (props.hasErrorMsg && props.errorMsg !== null) {
      return (
        <Text
          accessible={true}
          accessibilityLabel={`Error: ${props.errorMsg}`}
          accessibilityRole="text"
          aria-label={`Error: ${props.errorMsg}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={[styles.errorText, props.errorTextStyle]}
        >
          {props.errorMsg}
        </Text>
      );
    }
    return null;
  };

  const finalActiveOutLineColor = props.activeColor
    ? props.activeColor
    : Styles.Colors.PayNew.darkHuesBase04;

  const textInput = () => (
    <TextInput
      {...props}
      mode={'outlined'}
      outlineStyle={[
        styles.outlineStyle,
        props.outlineStyle,
        props.showShadow && styles.shadowStyle,
      ]}
      placeholderTextColor={Styles.Colors.PayNew.darkHuesBase04}
      activeOutlineColor={finalActiveOutLineColor}
      style={[
        styles.textContainer,
        getFontStyle(),
        props.containerStyle,
        props.textStyle,
      ]}
      right={getRightIcon()}
      secureTextEntry={props.secureTextEntry ? isSecureTextEntry : false}
      multiline={props.multiline}
      textAlignVertical="top"
      onBlur={(e: NativeSyntheticEvent<TextInputFocusEventData>) => {
        props.onBlur && props?.onBlur(e);
      }}
      contentStyle={[styles.textContentStyle, props.contentStyle]}
    />
  );

  return (
    <View>
      {textInput()}
      {getErrorMessage()}
      {getHelperText()}
    </View>
  );
};

const styles = StyleSheet.create({
  textContainer: {
    marginTop: Styles.Spacing.m3,
  },
  textContentStyle: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  shadowStyle: {
    elevation: 3,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    zIndex: -9,
  },
  outlineStyle: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
  },
  errorText: {
    color: Styles.dangerColor,
    marginTop: Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0,
    marginLeft: Styles.Spacing.m1,
    fontWeight: '400',
    alignSelf: 'center',
  },
  helpText: {
    color: Styles.lightGray,
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f8,
    marginLeft: Styles.Spacing.m1,
  },
});
export default CustomTextInput;
