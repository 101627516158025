import * as React from 'react';
import {PixelRatio, StyleSheet, View, ScrollView} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AVFormattedCurrency from '../AVFormattedCurrency';
import type {
  Funding,
  PurchaseDetailType,
  TransactionDetail,
} from 'src/types/TransactionDetail';
import AVText from '../AVText';
import {WithLocalizedProps} from 'src/types/PropTypes';
import AccountStore from 'src/stores/AccountStore';

type RefundDetailProps = WithLocalizedProps & {
  detail: Funding | TransactionDetail | PurchaseDetailType;
  currency?: string;
  handleEmailReceipt: (transId: string) => void;
  handleRefund: (transId: string) => void;
};

const RefundDetail = (props: RefundDetailProps) => {
  return (
    <View style={styles.container}>
      <ScrollView style={styles.content}>
        <AVText
          accessible={true}
          accessibilityLabel={`${
            props.strings.Labels.transaction_date
          } $${Util.formatDate(props.detail.DateString, 'LLL')}`}
          aria-label={`${
            props.strings.Labels.transaction_date
          } $${Util.formatDate(props.detail.DateString, 'LLL')}`}
          style={styles.date}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
        >
          {Util.formatDate(props.detail.DateString, 'LLL')}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={`${props.strings.Labels.location}}`}
          aria-label={`${props.strings.Labels.location}`}
          style={styles.location}
        >
          {props.detail.Location}
        </AVText>
        <AVText
          accessible={true}
          accessibilityLabel={props.strings.Labels.transaction_id}
          aria-label={props.strings.Labels.transaction_id}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
          style={styles.trans}
        >
          {props.detail.Id}
        </AVText>
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={styles.dashStyle}
        />

        <View accessible={true} style={[styles.row, styles.fundingRow]}>
          <AVText
            accessibilityLabel={props.strings.Labels.account_funding_refund}
            aria-label={props.strings.Labels.account_funding_refund}
            style={[styles.largeLabel, styles.textLabel]}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          >
            {props.strings.Labels.account_funding_refund}
          </AVText>
          <AVFormattedCurrency
            style={styles.largeLabel}
            value={props.detail.Amount * -1}
            accessibilityLabel={`$${props.detail.Amount * -1}`}
            aria-label={`$${props.detail.Amount * -1}`}
            currency={props.currency ?? AccountStore.getCurrency()}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          />
        </View>

        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(2)}
          dashThickness={PixelRatio.roundToNearestPixel(2)}
          dashColor="#999"
          style={styles.dashStyle}
        />

        <View accessible={true} style={Styles.Style.summarySection}>
          <View style={Styles.Style.labelColumn}>
            <AVText
              accessibilityLabel={props.strings.Labels.total}
              aria-label={props.strings.Labels.total}
              style={[styles.total]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {props.strings.Labels.total}
            </AVText>
          </View>
          <View style={Styles.Style.amountColumn}>
            <AVFormattedCurrency
              style={[styles.total]}
              value={props.detail.Amount * -1}
              accessibilityLabel={`$${props.detail.Amount * -1}`}
              aria-label={`$${props.detail.Amount * -1}`}
              currency={props.currency ?? AccountStore.getCurrency()}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            />
          </View>
        </View>
      </ScrollView>
      {!Util.isDatePastNinetyDays(props.detail.DateString) && (
        <RoundedButton
          accessible={true}
          accessibilityRole="button"
          buttonType={ButtonType.action}
          accessibilityLabel={props.strings.Buttons.email_receipt}
          aria-label={props.strings.Buttons.email_receipt}
          role="button"
          onPress={() => {
            props.handleEmailReceipt(props.detail.Id);
          }}
          text={props.strings.Buttons.email_receipt}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.white,
    flex: 1,
    padding: Styles.Spacing.m3,
  },
  content: {
    flex: 1,
    marginBottom: Styles.Spacing.m5,
    paddingVertical: 32,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  date: {
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m3,
  },
  fundingRow: {},
  largeLabel: {
    fontSize: Styles.Fonts.f1,
  },
  location: {
    fontSize: Styles.Fonts.f1,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textLabel: {
    flex: 1,
  },
  total: {
    color: Styles.black,
    fontSize: Styles.Fonts.f3,
    fontWeight: '300',
    marginBottom: Styles.Spacing.m1,
    marginTop: -1 * Styles.Spacing.m1,
  },
  trans: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
  },
});
export default RefundDetail;
