import React from 'react';
import {View, StyleSheet, Image, Dimensions, Platform} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from '../../elements/AVText';
import CartTypes from 'src/constants/cart/CartTypes';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

const {width: screenWidth, height: screenHeight} = Dimensions.get('window');

type ListCartEmptyProps = {
  cartType: CartTypes;
  handleGoBack?: () => void;
};

const ListCartEmpty = (props: ListCartEmptyProps) => {
  const {top} = useSafeAreaInsets();
  if (props.cartType == CartTypes.ScanAndPay) {
    return (
      <View style={styles.container}>
        <AVText
          accessible={true}
          accessibilityLabel={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          aria-label={
            Localized.Labels.barcode_refocus_and_find_non_barcoded_items
          }
          style={styles.label}
        >
          {Localized.Labels.barcode_refocus_and_find_non_barcoded_items}
        </AVText>
        <Image
          style={[
            styles.scanProductImage,
            {
              marginTop:
                screenHeight -
                (screenWidth * 257) / 512 -
                (Platform.OS === 'ios' ? 386 : 284) -
                top,
            },
          ]}
          resizeMode={'contain'}
          source={require('../../img/scan-product.png')}
        />
      </View>
    );
  } else {
    return (
      <View style={styles.orderAheadEmptycontainer}>
        <AVText
          accessible={true}
          accessibilityLabel={Localized.Labels.orderAheadEmptyCart}
          aria-label={Localized.Labels.orderAheadEmptyCart}
          style={styles.orderAheadLabel}
        >
          {Localized.Labels.orderAheadEmptyCart}
        </AVText>
        <View
          style={{
            alignSelf: 'center',
          }}
        >
          <Image
            style={styles.orderAheadEmptyImage}
            resizeMode={'contain'}
            source={require('../../img/OrderAheadEmptyIcon.png')}
          />
        </View>
        <RoundedAppButton
          buttonContainerStyle={{
            marginTop: Styles.Spacing.m4 + Styles.Spacing.m3,
            alignSelf: 'center',
          }}
          onPress={props.handleGoBack}
          buttonType={RoundedAppButtonType.Outline}
          buttonViewStyle={{
            width: 319,
            height: 42,
            backgroundColor: Styles.white,
          }}
          textStyle={{fontSize: Styles.Fonts.f1}}
          accessibilityLabelValue={Localized.Buttons.goback_to_menu}
          aria-label={Localized.Buttons.goback_to_menu}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
          titleText={Localized.Buttons.goback_to_menu}
        />
      </View>
    );
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m3,
  },
  orderAheadEmptycontainer: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: Styles.Spacing.m3,
  },
  scanProductImage: {
    width: screenWidth,
    height: (screenWidth * 257) / 512,
    alignSelf: 'center',
  },
  orderAheadEmptyImage: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    alignSelf: 'center',
  },
  label: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    textAlign: 'center',
  },
  orderAheadLabel: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export default ListCartEmpty;
