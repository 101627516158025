// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import moment from 'moment-timezone';
import _ from 'lodash';
import type {
  MenuCategoryType,
  MenuProductType,
  MenuType,
  ProductModifierType,
  ProductModifierValue,
  TabType,
  TimeSlotType,
} from 'src/types/Menu';
import type {CartItemModifier} from 'src/types/Cart';
import type {CartItem} from 'src/types/TransactionDetail';
import type {
  RecentOrderItemType,
  RecentOrderType,
} from 'src/types/RecentOrders';
import {ModifierTypes} from 'src/components/elements/orderAhead/ProductModifier';
import {PICKUP_LOCATIONS_KEYWORD} from 'src/stores/TransactionStore';
import Events from '../logging/Events';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class MenuService {
  currentMenu: MenuType;
  modifiers: Array<TabType>;
  timezoneOffset = 0;
  locationTimezone: string;
  deviceTimezone: string = moment.tz.guess();
  timezoneDisplay = '';

  setCurrentTabs(modifiers: Array<TabType>) {
    this.modifiers = modifiers;
  }

  /**
   * Iterate through existing this.tabs and add to array only
   * if this.tabs does not contain the item
   * @param tabsResponse
   */
  filterAndAddMissingModifiers(tabsResponse: Array<TabType>) {
    tabsResponse.map((responseItem) => {
      const found = this.modifiers.filter(
        (item) => responseItem.modifier.modifierId == item.modifier.modifierId,
      );
      if (found.length == 0) this.modifiers.push(responseItem);
    });
  }

  setCurrentMenu(menuData: MenuType) {
    this.currentMenu = menuData;
  }

  setTimezone(timezone: string) {
    try {
      this.locationTimezone = timezone;
      this.timezoneOffset = this.getTimeDifferenceInMinutes(
        this.deviceTimezone,
        this.locationTimezone,
      );
      this.timezoneDisplay = '';

      if (this.timezoneOffset !== 0) {
        this.timezoneDisplay = moment.tz
          .zone(this.locationTimezone)
          .abbr(moment.utc().unix());
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:setTimezone',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:setTimezone',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getTimezoneDisplay(): string {
    return this.timezoneDisplay;
  }

  getTimezoneOffsetMinutes(): number {
    return this.timezoneOffset;
  }

  getLocationDayOfWeek(): number {
    return this.getCurrentLocationTime().day();
  }

  getLocationTomorrowDayOfWeek(): number {
    return this.getCurrentLocationTime().add(1, 'day').day();
  }

  getCurrentLocationTime(): moment.Moment {
    return moment().add(this.timezoneOffset, 'minutes');
  }

  getTimesSlotsFromTimes(
    times: Array<any>,
    dayOfWeek: number,
    date: string,
  ): Array<TimeSlotType> {
    return times.map(({timeSlot: time, leadTime}) => {
      return {time, timeString: time, leadTime, date, dayOfWeek};
    });
  }

  updateOrdersFromMenu(
    recentOrders: Array<RecentOrderType>,
  ): Array<RecentOrderType> {
    try {
      const length = recentOrders.length;
      for (let i = 0; i < length; i++) {
        const recentOrder = recentOrders[i];
        recentOrder.available = this.updateOrderFromMenu(recentOrder);
      }

      return recentOrders;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:updateOrdersFromMenu',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:updateOrdersFromMenu',
        e.message ? e.message : e.toString(),
      );
    }
  }

  updateOrderFromMenu(recentOrder: RecentOrderType) {
    try {
      recentOrder = this.removePickupLocationItems(recentOrder);
      const length = recentOrder.items.length;
      let available = true;

      for (let i = 0; i < length; i++) {
        const item = recentOrder.items[i];
        this.updateItemFromMenu(item);

        if (!item.available) {
          available = false;
        }
      }

      return available;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:updateOrderFromMenu',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:updateOrderFromMenu',
        e.message ? e.message : e.toString(),
      );
    }
  }

  removePickupLocationItems(order: RecentOrderType) {
    order.items = order.items.filter(
      (item) => !item.name.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
    );
    return order;
  }

  updateItemFromMenu(item: RecentOrderItemType) {
    try {
      item.available = false;
      item.hasNewModifiers = false;
      const menuItem = this.getProductByScanCode(item.scancode);

      if (menuItem) {
        item.available = true;
        item.price = parseFloat(menuItem.price);
        item.printgroups = menuItem.printgroups;

        if (menuItem.hasmodifier === 'Y') {
          item.hasNewModifiers = true;
        }
        if (item.modifiers) {
          const length = item.modifiers.length;

          for (let i = 0; i < length; i++) {
            const itemModifier = item.modifiers[i];
            itemModifier.available = false;
            itemModifier.Modifier = itemModifier.modifier;
            const menuModifier = this.getCartModifierByName(
              menuItem.tabs,
              itemModifier.name,
            );

            if (menuModifier) {
              itemModifier.available = true;
              itemModifier.price = menuModifier.Price;
              itemModifier.keyReference =
                menuModifier.KeyRef ?? itemModifier.keyReference;
              itemModifier.KeyRef =
                menuModifier.KeyRef ?? itemModifier.keyReference;
            }
          }
        }
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:updateItemFromMenu',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:updateItemFromMenu',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getModifiers(product: MenuProductType): Array<ProductModifierType> {
    try {
      if (!product || product.hasmodifier === 'N' || !this.modifiers) {
        return [];
      }

      const modifiers = _.flatten(_.map(this.modifiers, 'modifiers')).filter(
        (modifier) => modifier.type !== ModifierTypes.Upsell, // Product Upsell Modifier must be handled differently
      );

      modifiers.sort((a, b) => {
        if (a.forced < b.forced) {
          return 1;
        } else if (b.forced < a.forced) {
          return -1;
        }

        return 0;
      });
      return modifiers;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getModifiers',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getModifiers',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getUpsellTabs(): Array<TabType> {
    try {
      const products = _.map(
        _.uniqBy(
          _.flattenDeep(_.map(this.currentMenu.data, 'products')),
          'scancode',
        ),
        'id',
      );
      // filter and return in-stock product upsell tabs
      return this.modifiers
        .filter((tab) =>
          tab.modifiers.some((mod) => mod.type === ModifierTypes.Upsell),
        )
        .map((tab) => ({
          ...tab,
          modifiers: tab.modifiers
            .map((mod) => ({
              ...mod,
              vals: mod.vals?.filter((val) => products?.includes(val.product)),
            }))
            .filter((mod) => mod.vals?.length > 0),
        }))
        .filter((tab) => tab.modifiers?.length > 0);
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getUpsellTabs',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getUpsellTabs',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getCartModifier(modifierValId: string): CartItemModifier | null {
    try {
      if (!this.modifiers) {
        return null;
      }
      const modifier = _.flatten(_.map(this.modifiers, 'modifiers')).find(
        (mod) => mod.vals.some((val) => val.id === modifierValId),
      );

      if (modifier) {
        const modifierValue = modifier.vals.find(
          (val) => val.id === modifierValId,
        );
        return this.getCartModifierFromModifierValue(modifier, modifierValue);
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getCartModifier',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getCartModifier',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  // Handling the `FreeText Modifier` differently
  getCartModifierForFreeText(
    modifierId: string,
    value: string,
  ): CartItemModifier | null {
    if (!this.modifiers) {
      return null;
    }
    try {
      const modifier = _.flatten(_.map(this.modifiers, 'modifiers')).find(
        (mod) => mod.id === modifierId,
      );

      if (modifier) {
        return {
          KeyRef: modifierId,
          Type: modifier.type,
          Modifier: modifierId,
          ModifierName: modifier.name,
          Product: '',
          Name: value,
          SeqNum: 0,
          Price: 0,
        };
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getCartModifierForFreeText',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getCartModifierForFreeText',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  getCartModifierByName(
    itemTabs: Array<string>,
    name: string,
  ): CartItemModifier | null {
    if (!this.modifiers) {
      return null;
    }
    try {
      const fullItemTabs = this.modifiers.filter((tab) =>
        itemTabs.includes(tab.id),
      );

      const modifier = _.flatten(_.map(fullItemTabs, 'modifiers')).find((mod) =>
        mod.vals.some((val) => val.name === name),
      );
      if (modifier) {
        const modifierValue = modifier.vals.find((val) => val.name === name);
        return this.getCartModifierFromModifierValue(modifier, modifierValue);
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getCartModifierByName',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getCartModifierByName',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  getCartModifierFromModifierValue(
    modifier: ProductModifierType,
    modifierValue: ProductModifierValue,
  ): CartItemModifier | null {
    try {
      if (modifierValue) {
        return {
          KeyRef: modifierValue.id,
          Type: modifier.type,
          Modifier: modifier.id,
          ModifierName: modifier.name,
          Product: modifierValue.product,
          Name: modifierValue.name,
          SeqNum: modifierValue.seq,
          Price: parseFloat(modifierValue.price),
        };
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getCartModifierFromModifierValue',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getCartModifierFromModifierValue',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  searchSubMenus(
    text: string,
    menuId: string | number,
    displayOnly = true,
  ): Array<MenuCategoryType> {
    try {
      const query = text.toLowerCase();
      const submenus: Array<MenuCategoryType> =
        this.currentMenu?.data?.filter((menu) => menu.parent === menuId) ?? [];
      let result = submenus.map((menu) => {
        const products = menu.products?.filter(
          (product) => product.name?.toLowerCase().includes(query) ?? false,
        );
        return {...menu, products};
      });

      if (displayOnly) {
        result = result.filter(
          (product) =>
            !product.name.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
        );
      }

      return result;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:searchSubMenus',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:searchSubMenus',
        e.message ? e.message : e.toString(),
      );
    }
  }

  searchProducts(
    text: string,
    menuId: number | string = -1,
    displayOnly = true,
  ): Array<MenuProductType> {
    try {
      const query = text.toLowerCase();
      let products: Array<MenuProductType> = [];

      if (!this.currentMenu) {
        return products;
      }

      if (menuId === -1) {
        products = _.uniqBy(
          _.flattenDeep(_.map(this.currentMenu.data, 'products')),
          'scancode',
        );
      } else {
        const category = this.currentMenu.data.find(
          (data) => data.id === menuId,
        );

        if (category) {
          products = category.products;
        }
      }

      let result = products.filter(
        (product) => product && product.name.toLowerCase().includes(query),
      );

      // Only sort alphabetically if it's all items
      if (menuId === -1) {
        result.sort((a, b) =>
          b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1,
        );
      }

      if (displayOnly) {
        result = result.filter(
          (product) =>
            !product.name.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
        );
      }

      return result;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:searchProducts',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:searchProducts',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getMenuCategories(): Array<MenuCategoryType> {
    try {
      if (!this.currentMenu) {
        return [];
      }

      return this.currentMenu.data.filter((data) => data.parent === 1);
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getMenuCategories',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getMenuCategories',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getFirstProduct(menuId: string | number): MenuProductType | null {
    try {
      if (!this.currentMenu) {
        return null;
      }

      const category = this.currentMenu.data.find((data) => data.id === menuId);

      if (category && category.products && category.products.length > 0) {
        return category.products[0];
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getFirstProduct',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getFirstProduct',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  getNextAvailableTime(
    availableTimesFromServer: Array<TimeSlotType> | null = null,
    _scanCodes: Array<string>,
    leadTime = 15,
    _intervalMinutes = 15,
  ): TimeSlotType | null {
    try {
      const availableTimes = this.getAvailableTimeSlotsFromList(
        availableTimesFromServer,
        leadTime,
      );

      if (!availableTimes || availableTimes.length === 0) {
        return null;
      }

      return availableTimes[0];
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getNextAvailableTime',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getNextAvailableTime',
        e.message ? e.message : e.toString(),
      );
    }
  }

  menuHasProducts(
    menuToCheck: MenuType | null | undefined,
    scanCodes: Array<string>,
  ): boolean {
    if (!menuToCheck) {
      return false;
    }
    try {
      const products = _.flattenDeep(_.map(menuToCheck.data, 'products'));

      for (let i = 0; i < scanCodes.length; i++) {
        const scanCode = scanCodes[i];

        if (
          !products.some((product) => product && product.scancode === scanCode)
        ) {
          return false;
        }
      }
      return true;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:menuHasProducts',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:menuHasProducts',
        e.message ? e.message : e.toString(),
      );
    }
    return false;
  }

  getProductByScanCode(scanCode: string): MenuProductType | null | undefined {
    if (!this.currentMenu) {
      return;
    }
    try {
      const products = _.flattenDeep(_.map(this.currentMenu.data, 'products'));

      return products.find(
        (product) => product && product.scancode === scanCode,
      );
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getProductByScanCode',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getProductByScanCode',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getProduct(productId: string): MenuProductType | null | undefined {
    if (!this.currentMenu) {
      return;
    }
    try {
      const products = _.flattenDeep(_.map(this.currentMenu.data, 'products'));

      return products.find((product) => product && product.id === productId);
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getProduct',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getProduct',
        e.message ? e.message : e.toString(),
      );
    }
  }

  filterProductModifiers(item: CartItem): Array<CartItem> {
    const items = [];
    const modifiers = [];
    try {
      item.Modifiers?.forEach((value) => {
        if (value.Product) {
          const product = this.getProduct(value.Product);

          if (product) {
            const newItem = {
              BarCode: product.scancode,
              PrintGroups: product.printgroups,
            };
            items.unshift({...newItem});
            return;
          }
        }

        modifiers.push(value);
      });
      item.Modifiers = modifiers;
      items.unshift({...item});
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:filterProductModifiers',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:filterProductModifiers',
        e.message ? e.message : e.toString(),
      );
    }
    return items;
  }

  getTimeDifferenceInMinutes(
    deviceTimezone: string,
    locationTimezone: string,
  ): number {
    try {
      const now = moment.utc().unix();
      // get the zone offsets for this time, in minutes
      const deviceOffset = moment.tz
        .zone(deviceTimezone)
        .utcOffset(moment.utc().unix());
      const locationOffset = moment.tz.zone(locationTimezone).utcOffset(now);
      return deviceOffset - locationOffset;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getTimeDifferenceInMinutes',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getTimeDifferenceInMinutes',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getAvailableTimeSlotsFromList(
    availableTimes: Array<TimeSlotType>,
    defaultLeadTime = 15,
    _items: Array<string> = [],
  ): Array<TimeSlotType> {
    const timeSlots: Array<TimeSlotType> = [];
    try {
      if (availableTimes && availableTimes.length > 0) {
        for (let i = 0; i < availableTimes.length; i++) {
          const {
            date,
            dayOfWeek,
            time,
            leadTime = defaultLeadTime,
          } = availableTimes[i];
          const now = moment()
            .add(leadTime, 'minutes')
            .add(this.timezoneOffset, 'minutes');
          const pickupSlot = moment(date).add(time);

          if (pickupSlot.isAfter(now)) {
            timeSlots.push({
              time: pickupSlot.format('LT'),
              timeString: time,
              timezone: this.timezoneDisplay,
              dateString: pickupSlot.format('MMMM D'),
              date: pickupSlot.toString(),
              dayOfWeek: dayOfWeek,
            });
          }
        }
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getAvailableTimeSlotsFromList',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getAvailableTimeSlotsFromList',
        e.message ? e.message : e.toString(),
      );
    }
    return timeSlots;
  }

  getTomorrowPickupDate() {
    return this.getCurrentLocationTime().add(1, 'day').format('YYYY-MM-DD');
  }

  getTomorrowPickupDateTime() {
    return this.getCurrentLocationTime()
      .add(1, 'day')
      .format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  getTimeSlots(
    start: string,
    end: string,
    leadTime = 15,
    intervalMinutes = 15,
  ): Array<TimeSlotType> {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const timeSlots = [];
    try {
      const now = this.getCurrentLocationTime().add(leadTime, 'minutes');
      const pickupDate = this.getPickupDate();
      const dayOfWeek = this.getLocationDayOfWeek();

      while (startTime.isSameOrBefore(endTime)) {
        if (startTime.isAfter(now)) {
          const time = moment(startTime);
          const timeFormat = time.format('LT');
          timeSlots.push({
            time: timeFormat,
            momentTime: time,
            timezone: this.timezoneDisplay,
            date: pickupDate,
            dayOfWeek,
          });
        }

        startTime.add(intervalMinutes, 'minutes');
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getTimeSlots',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getTimeSlots',
        e.message ? e.message : e.toString(),
      );
    }
    return timeSlots;
  }

  getPickupDate() {
    return this.getCurrentLocationTime().format('YYYY-MM-DD');
  }

  getPickupDateTime() {
    return this.getCurrentLocationTime().format('YYYY-MM-DDTHH:mm:ss.SSS');
  }

  getPickupLocations(): MenuProductType | null {
    try {
      const pickupProducts = this.searchProducts(
        PICKUP_LOCATIONS_KEYWORD,
        -1,
        false,
      );
      const pickupProduct = this.getPickupLocationProduct(pickupProducts);

      if (
        pickupProduct &&
        pickupProduct.pickupLocations &&
        pickupProduct.pickupLocations.length > 0
      ) {
        return pickupProduct;
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getPickupLocations',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getPickupLocations',
        e.message ? e.message : e.toString(),
      );
    }
    return null;
  }

  getPickupLocationProduct(pickupProducts: Array<MenuProductType>) {
    let pickupProduct = null;
    try {
      if (pickupProducts && pickupProducts.length === 1) {
        pickupProduct = pickupProducts[0];

        if (!pickupProduct.pickupLocations) {
          pickupProduct.pickupLocations =
            this.getLocationModifiers(pickupProduct);
        }

        return pickupProduct;
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getPickupLocationProduct',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getPickupLocationProduct',
        e.message ? e.message : e.toString(),
      );
    }
  }

  getLocationModifiers(pickupProduct: MenuProductType) {
    try {
      const modifiers = this.getModifiers(pickupProduct);

      if (modifiers && modifiers.length === 1) {
        return this.getLocationCartModifiers(modifiers);
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getLocationModifiers',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getLocationModifiers',
        e.message ? e.message : e.toString(),
      );
    }
    return [];
  }

  getLocationCartModifiers(modifiers: Array<ProductModifierType>) {
    const locations = [];
    try {
      const modifierVals = modifiers[0].vals;

      if (modifierVals && modifierVals.length) {
        return this.getLocationCartModifierVals(modifierVals);
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getLocationCartModifiers',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getLocationCartModifiers',
        e.message ? e.message : e.toString(),
      );
    }
    return locations;
  }

  getLocationCartModifierVals(modifierVals: Array<ProductModifierValue>) {
    const locations = [];
    try {
      for (let i = 0; i < modifierVals.length; i++) {
        const modifierVal = modifierVals[i];
        const cartModifier = this.getCartModifier(modifierVal.id);

        if (cartModifier) {
          locations.push(cartModifier);
        }
      }
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuService:getLocationCartModifierValues',
        e.message ? e.message : e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuService:getLocationCartModifierValues',
        e.message ? e.message : e.toString(),
      );
    }
    return locations;
  }
}

export default new MenuService();
