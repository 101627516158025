import React from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
  Image,
  Platform,
} from 'react-native';
import Styles from '../../Styles';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import Settings from 'src/Settings';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import {getDescriber} from './descriptor/DescriptorType';
import ScanProductBarCode from 'src/components/img/svg/ScanProductBarCode';

type ScanAndPayLocationViewProps = {
  location: CampusLocationType;
  onLocationClick: (
    machine: CampusLocationType,
    autoAdvanced?: boolean,
  ) => Promise<void>;
};

const width = Dimensions.get('window').width;

const {getScanAndPayTile} = getDescriber();

const ScanAndPayLocationView = (props: ScanAndPayLocationViewProps) => {
  const renderScanIcon = () => {
    if (Settings.isRevolve()) {
      return (
        <View
          style={[styles.iconView, getScanAndPayTile()['iconViewBackground']]}
        >
          <ScanProductBarCode color={Styles.Colors.PayNew.primary01} />
        </View>
      );
    }
    return (
      <View
        style={[styles.iconView, getScanAndPayTile()['iconViewBackground']]}
      >
        <Image
          source={
            Settings.buildType === BuildTypeConstants.default
              ? require('src/components/img/scanIconDefault.png')
              : require('src/components/img/scanIconCanteen.png')
          }
          style={styles.scanIcon}
        />
      </View>
    );
  };

  const renderTextContent = () => {
    return (
      <View
        style={[
          styles.textView,
          getScanAndPayTile()['textView'],
          Platform.OS === 'android' && {top: -Styles.Spacing.m1},
        ]}
      >
        <AVText
          accessibilityLabel={`LocationName${
            props.location.displayName ?? props.location.name
          }`}
          accessibilityRole="text"
          aria-label={`${
            props.location.displayName ?? props.location.name
          }, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={getScanAndPayTile()['titleText']}
        >
          {props.location.displayName ?? props.location.name}
        </AVText>
        <AVText
          accessibilityLabel={Localized.Labels.scan_product}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.scan_product}, text`}
          numberOfLines={1}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={getScanAndPayTile()['nameText']}
        >
          {Localized.Labels.scan_product}
        </AVText>
      </View>
    );
  };
  return (
    <TouchableOpacity
      accessibilityHint={`Double tap to select ${
        props.location.displayName ?? props.location.name
      }`}
      style={[styles.container, getScanAndPayTile()['rowSpace']]}
      accessibilityRole="button"
      onPress={() => props.onLocationClick(props.location)}
      accessibilityLabel={`Location${
        props.location.displayName ?? props.location.name
      }`}
      accessible={true}
      aria-label={`Location${
        props.location.displayName ?? props.location.name
      }`}
    >
      {renderScanIcon()}
      {renderTextContent()}
      <View style={styles.arrowView}>
        <FontAwesome5Pro
          accessibilityLabel="Right arrow icon"
          aria-label="Right arrow icon"
          name="chevron-right"
          color={Styles.lightGray}
          size={Styles.Heights.h2}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    width: width - (Styles.Spacing.m3 + Styles.Spacing.m2) * 2,
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    shadowColor: '#000',
    alignItems: 'center',
    shadowOffset: {width: 0, height: 1},
    flexDirection: 'row',
    borderRadius: Styles.Spacing.m2,
    shadowOpacity: 0.4,
    shadowRadius: 2,
    elevation: 4,
  },
  iconView: {
    borderRadius: Styles.Spacing.m2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textView: {
    marginRight: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m1,
    justifyContent: 'space-between',
  },
  titleTxt: {
    marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
    fontWeight: '700',
    color: Styles.black,
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.f7,
  },
  nameTxt: {
    fontFamily: Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.f7,
    color: Styles.black,
    marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
    fontWeight: '400',
  },
  arrowView: {
    right: Styles.Spacing.m3,
    position: 'absolute',
  },
  scanIcon: {
    width: Styles.Heights.h5 / 2,
    height: Styles.Heights.h5 / 2,
  },
});

export default ScanAndPayLocationView;
