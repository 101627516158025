import * as React from 'react';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import Util from 'src/Util';
import BackSubheader from '../../elements/BackSubheader';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Styles from '../../Styles';
import AccountStore from 'src/stores/AccountStore';
import AVText from '../../elements/AVText';
import type {BlockedUser, GiftItem as GiftItemType} from 'src/types/Snack';
import Settings from 'src/Settings';
import SnackActions from 'src/constants/sendSnack/SnackActions';
import {confirm} from '../../helpers/AlertHelper';
import Localized from 'src/constants/AppStrings';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {
  acceptSnack,
  AcceptSnackParams,
  blockUser,
  BlockUserParams,
  cancelSnack,
  CancelSnackParams,
  loadSnackDetail,
  LoadSnackDetailParams,
  getSnackBlockedUsers,
  unblockSnackUser,
  UnblockSnackUserParams,
} from 'src/redux/thunks/snackThunks';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import {getDescriber} from './descriptor/sendasnack/DescriptorType';
import {
  markSingleMessageAsRead,
  NotificationMessageParams,
} from 'src/redux/thunks/notificationThunks/messageRead';
import WarningIconSnackDetails from 'src/components/img/svg/WarningIconSnackDetails';
import SuccessModal from 'src/components/elements/SuccessModal';

type SnackDetailScreenProps = WithGlobalizeProps & {
  snackId: string;
  snackLocationId: string;
  snackType: string;
  snackAction?: keyof typeof SnackActions;
  loadSnackDetail(params: LoadSnackDetailParams): void;
  acceptSnack(params: AcceptSnackParams): void;
  cancelSnack(params: CancelSnackParams): void;
  markMessageAsRead(params: NotificationMessageParams): void;
  snackDetails: GiftItemType;
  loading: boolean;
  blockUser(params: BlockUserParams): {
    [index: string]: {[index: string]: number | string};
  };
  unblockSnackUser(params: UnblockSnackUserParams): {
    [index: string]: {[index: string]: number | string};
  };
  getSnackBlockedUsers(): void;
  loadingBlockedUsers: boolean;
  blockedUsers: Array<BlockedUser>;
};
interface BlockedUsersData {
  isSetBlockedUser: boolean;
  showErrorSendSnackModal: boolean;
}

const SnackStatus = {
  Sent: 'S',
  Cancelled: 'C',
  Accepted: 'A',
  Expired: 'E',
};

class SnackDetailScreen extends React.Component<
  SnackDetailScreenProps,
  BlockedUsersData
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  constructor(props: SnackDetailScreenProps) {
    super(props);
    this.state = {
      isSetBlockedUser: false,
      showErrorSendSnackModal: false,
    };
    this.onBlockUser = this.onBlockUser.bind(this);
    this.renderEmpty = this.renderEmpty.bind(this);
    this.renderTopHeader = this.renderTopHeader.bind(this);
    this.renderSnackMessage = this.renderSnackMessage.bind(this);
    this.renderSnackStatusForSender =
      this.renderSnackStatusForSender.bind(this);
    this.renderSnackStatusForReceiver =
      this.renderSnackStatusForReceiver.bind(this);
    this.formatCurrency = this.formatCurrency.bind(this);
    this.onUnBlockUser = this.onUnBlockUser.bind(this);
    this.loadSnackDetails = this.loadSnackDetails.bind(this);
  }
  describerData = {
    styles,
    snackDate: '',
    isSender: false,
    stickerId: null,
    senderName: '',
    amount: null,
    ...this.props,
  };

  componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'SnackDetailScreen', {
      ...this.props,
      ...this.state,
    });
    this.loadSnackDetails();
    this.props.markMessageAsRead({
      externalServiceId: this.props.snackId,
      messageType: this.props.snackType,
      locationId: this.props.snackLocationId,
    });
    if (this.props.snackAction) {
      this.setState({showErrorSendSnackModal: true});
    }
  }

  onBlockUser() {
    FirebaseAnalytic.trackEvent('onBlockUser', 'SnackDetailScreen', {
      ...this.props,
      ...this.state,
    });
    confirm(
      `${Localized.Labels.blocking_user_will_prevent_from_accept_snack}\n\n${Localized.Labels.user_can_be_unblocked_from_settings}`,
      undefined,
      () => {
        const result = this.props.blockUser({
          snackDetails: this.props.snackDetails,
        });
        if (
          result &&
          Object.keys(result).length > 0 &&
          result.arg.snackDetails
        ) {
          this.setState({isSetBlockedUser: false}, () => {
            this.loadSnackDetails();
          });
        }
      },
      Localized.Labels.are_you_sure_to_block_user,
      Localized.Labels.yes_block,
      Localized.Buttons.cancel,
    );
  }
  loadSnackDetails() {
    this.props.loadSnackDetail({
      messageAction: this.props.snackAction,
      externalMsgId: this.props.snackId,
      locationId: this.props.snackLocationId,
      context: this.context,
    });
  }
  onUnBlockUser() {
    const result = this.props.unblockSnackUser({
      blockedAccountId: this.props.snackDetails.senderAccountId,
    });
    if (
      result &&
      Object.keys(result).length > 0 &&
      result.arg.blockedAccountId
    ) {
      this.setState({isSetBlockedUser: true}, () => {
        this.loadSnackDetails();
      });
    }
  }

  formatCurrency(value: number) {
    return Util.formatCurrency(this.props, value, AccountStore.getCurrency());
  }

  renderEmpty() {
    return getDescriber() && getDescriber().getBackSubHeaderDescriptor();
  }

  renderDate() {
    const snackDate = Util.formatDate(
      this.props.snackDetails.snackSent,
      'MMM DD, YYYY',
    );
    const snackTime = Util.formatDate(
      this.props.snackDetails.snackSent,
      'hh:mm A',
    );
    return (
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={
          getDescriber() &&
          getDescriber().getSendSnackStyleDescriptor()['dateStyle']
        }
      >
        {snackDate + ' at ' + snackTime}
      </AVText>
    );
  }
  getDateText() {
    const snackDate = Util.formatDate(
      this.props.snackDetails.snackSent,
      'MMM DD, YYYY',
    );
    const snackTime = Util.formatDate(
      this.props.snackDetails.snackSent,
      'hh:mm A',
    );
    return snackDate + ' at ' + snackTime;
  }
  renderTopHeader() {
    const {senderName, senderAccountId, status} = this.props.snackDetails || {};

    const isSender = senderAccountId === AccountStore.getAccountId();

    return (
      <View
        style={
          getDescriber() &&
          getDescriber().getSendSnackStyleDescriptor()['topRow']
        }
      >
        {getDescriber() &&
          getDescriber().getTopHeaderSnackDescriptor({
            isSender,
            senderName,
            snackDate: this.getDateText(),
            styles,
            status,
          })}
      </View>
    );
  }

  renderSnackMessage(isSender) {
    const {email, senderName, amount, description} = this.props.snackDetails;
    if (amount) {
      this.describerData['amount'] = amount;
    }
    if (senderName) {
      this.describerData['senderName'] = senderName;
    }
    return (
      <View>
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={
            getDescriber() &&
            getDescriber().getSendSnackStyleDescriptor()['description']
          }
        >
          {isSender
            ? Localized.Labels.formatString(
                Localized.Labels.we_have_sent_your_snack_details,
                email,
              )
            : getDescriber() &&
              getDescriber().renderSnackMsgDescriptor(this.describerData)}
        </AVText>
        {!Settings.isRevolveAnd365Pay() && !isSender && (
          <AVText
            style={
              getDescriber() &&
              getDescriber().getSendSnackStyleDescriptor()['message']
            }
          >
            {description}
          </AVText>
        )}
      </View>
    );
  }

  getSnackStatusMessageForSender = () => {
    const {status} = this.props.snackDetails;

    if (status === SnackStatus.Sent) {
      return Localized.Labels.cancel_snack_button_description_message;
    } else if (status === SnackStatus.Expired) {
      return Localized.Labels.snack_has_expired;
    } else if (status === SnackStatus.Cancelled) {
      return Localized.Labels.snack_has_been_cancelled;
    } else if (status === SnackStatus.Accepted) {
      return Localized.Labels.snack_has_been_accepted;
    }
  };

  renderSnackStatusForSender() {
    return (
      <View style={styles.senderSnackStatusContainer}>
        <AVText style={styles.senderSnackStatusText}>
          {this.getSnackStatusMessageForSender()}
        </AVText>
        {this.props.snackDetails.status === SnackStatus.Sent && (
          <RoundedButton
            buttonType={ButtonType.outline}
            text={Localized.Buttons.cancel_snack}
            containerStyle={styles.cancelButton}
            color={Styles.dangerColor}
            onPress={() =>
              this.props.cancelSnack({
                snackDetails: this.props.snackDetails,
              })
            }
            accessibilityLabel="Cancel Snack"
          />
        )}
      </View>
    );
  }

  renderSnackStatusForReceiver() {
    const {stickerId, amount, isBlocked} = this.props.snackDetails;
    if (stickerId) {
      this.describerData['stickerId'] = stickerId;
    }
    return (
      <View style={styles.receiverSnackStatusContainer}>
        {getDescriber().getSnackStickerDescriptor(this.describerData)}
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={
            getDescriber() &&
            getDescriber().getSendSnackStyleDescriptor()['amount']
          }
          accessibilityLabel="Snack Amount"
        >
          {this.formatCurrency(amount)}
        </AVText>
        <AVText
          style={
            getDescriber() &&
            getDescriber().getSendSnackStyleDescriptor()[
              'receiverSnackStatusText'
            ]
          }
          accessibilityLabel="Snack Status Message"
        >
          {this.getSnackStatusMessageFoReceiver()}
        </AVText>
        {Settings.is365Pay() && isBlocked && !this.state.isSetBlockedUser && (
          <View style={styles.container}>
            <View style={styles.leftSection}>
              <WarningIconSnackDetails />
            </View>
            <View style={styles.rightSection}>
              <AVText style={styles.blockUserHeaderText}>
                {Localized.Labels.formatString(
                  Localized.Errors.blocked_users,
                  this.props.snackDetails.senderName,
                )}
              </AVText>
              <AVText style={styles.blockUserHeaderTextDesc}>
                {Localized.Labels.formatString(
                  Localized.Errors.blocked_users_desc,
                )}
              </AVText>
            </View>
          </View>
        )}
        {isBlocked && !this.state.isSetBlockedUser ? (
          <RoundedButton
            buttonType={getDescriber() && getDescriber().unblockButtonType()}
            text={
              Settings.is365Pay()
                ? Localized.Labels.unblock_user
                : Localized.Labels.blocked
            }
            backgroundColor={
              getDescriber() && getDescriber().blockUnblockButtonColor()
            }
            containerStyle={
              getDescriber() &&
              getDescriber().getSendSnackStyleDescriptor()['btnBlockUser']
            }
            color={Styles.dangerColor}
            accessibilityLabel="Blocked"
            textStyle={
              getDescriber() &&
              getDescriber().getSendSnackStyleDescriptor()['btnBlockUserText']
            }
            onPress={() => {
              if (Settings.is365Pay()) this.onUnBlockUser();
            }}
          />
        ) : (
          <RoundedButton
            buttonType={ButtonType.outline}
            text={Localized.Buttons.block_user}
            containerStyle={
              getDescriber() &&
              getDescriber().getSendSnackStyleDescriptor()['btnBlockUser']
            }
            backgroundColor={
              getDescriber() && getDescriber().blockUnblockButtonColor()
            }
            color={Styles.dangerColor}
            onPress={this.onBlockUser}
            accessibilityLabel="Block Snack User"
            textStyle={
              getDescriber() &&
              getDescriber().getSendSnackStyleDescriptor()['btnBlockUserText']
            }
          />
        )}
      </View>
    );
  }

  getSnackStatusMessageFoReceiver = () => {
    const {status, isBlocked} = this.props.snackDetails;

    if (status === SnackStatus.Sent && !isBlocked) {
      return Localized.Labels.accept_snack_to_add_to_your_account;
    } else if (status === SnackStatus.Accepted) {
      return Localized.Labels.has_been_added_to_your_account;
    } else if (status === SnackStatus.Cancelled) {
      return Localized.Labels.gift_has_been_cancelled;
    } else if (status === SnackStatus.Expired) {
      return Localized.Labels.gift_has_expired;
    }

    return '';
  };

  hideErrorModal = () => {
    this.setState({showErrorSendSnackModal: false});
  };

  render() {
    const {status, isBlocked, senderName, senderAccountId} =
      this.props.snackDetails || {};

    if (this.props.loading || !senderName || !senderAccountId) {
      return this.renderEmpty();
    }

    const isSender = senderAccountId === AccountStore.getAccountId();
    if (isSender) {
      this.describerData['isSender'] = isSender;
    }
    if (senderName) {
      this.describerData['senderName'] = senderName;
    }
    return (
      <BackSubheader
        style={getDescriber() && getDescriber().backHeaderStyleDescriptor()}
        title={
          getDescriber() && getDescriber().titleDescriptor(this.describerData)
        }
      >
        <View
          style={{
            flex: 1,
          }}
        >
          <ScrollView
            style={
              !Settings.isRevolve()
                ? Styles.Style.flex
                : getDescriber() &&
                  getDescriber().getSendSnackStyleDescriptor()['content']
            }
          >
            <View
              style={
                !Settings.isRevolve() &&
                getDescriber() &&
                getDescriber().getSendSnackStyleDescriptor()['content']
              }
            >
              {this.renderTopHeader()}
              {Settings.isRevolve() && this.renderDate()}
              {this.renderSnackMessage(isSender)}
              {isSender
                ? this.renderSnackStatusForSender()
                : this.renderSnackStatusForReceiver()}
              <View style={styles.bottom} />
            </View>
            {Settings.is365Pay() &&
              this.state.showErrorSendSnackModal &&
              this.props.snackDetails?.isErrormessage && (
                <SuccessModal
                  isModalVisible={
                    this.props.snackDetails.status === SnackStatus.Cancelled
                  }
                  headerTitle={
                    Localized.Errors.one_time_snack_cancelled_error_header
                  }
                  description={
                    Localized.Errors.one_time_snack_365Pay_error_desc
                  }
                  buttonTitle={Localized.Buttons.okay}
                  descLines={5}
                  errorSendSnackPopUp={true}
                  onModalClose={() => this.hideErrorModal()}
                />
              )}
          </ScrollView>
          {!isSender &&
            status === SnackStatus.Sent &&
            (!isBlocked || this.state.isSetBlockedUser) && (
              <RoundedButton
                accessibilityLabel="Accept Snack"
                buttonType={ButtonType.action}
                color={getDescriber().acceptButtonColor()}
                text={Localized.Buttons.accept_snack}
                textStyle={
                  Platform.OS === 'web' && {
                    fontFamily: Styles.FontFamily.aeonikRegular,
                  }
                }
                onPress={() =>
                  this.props.acceptSnack({
                    snackDetails: this.props.snackDetails,
                    context: this.context,
                  })
                }
              />
            )}
        </View>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  description: {
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f1,
    color: Styles.black,
    flex: 1,
  },
  message: {
    flex: 1,
    fontSize: Styles.Fonts.f1,
    color: Styles.black,
    fontFamily: Styles.FontFamily.figtreeRegular,
    marginTop: Styles.Spacing.m2,
  },
  senderSnackStatusContainer: {
    marginTop: Styles.Spacing.m3,
  },
  senderSnackStatusText: {
    color: Styles.black,
    flex: 1,
    marginTop: Styles.Spacing.m2,
    fontSize: Styles.Fonts.f1,
  },
  cancelButton: {
    alignSelf: 'flex-start',
    marginTop: Styles.Spacing.m2,
  },
  receiverSnackStatusContainer: {
    marginTop: Styles.Spacing.m3,
    alignItems: 'center',
  },
  sticker: {
    marginTop: Styles.Spacing.m3,
  },
  amount: {
    marginTop: Styles.Spacing.m4,
    color: Styles.black,
    fontSize: 42,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.robotoRegular,
  },
  blockButton: {
    alignSelf: 'center',
    marginTop: Styles.Spacing.m3,
  },
  bottom: {
    height: Styles.Spacing.m5,
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    paddingVertical: 16,
    paddingHorizontal: 12,
    alignItems: 'center',
    gap: 8,
    alignSelf: 'stretch',
    borderRadius: 8,
    backgroundColor: 'rgba(215, 38, 61, 0.15)',
    flex: 1,
    marginTop: 20,
    marginBottom: 10,
  },
  leftSection: {
    flex: 0.1,
    alignItems: 'center',
  },
  rightSection: {
    flex: 0.9,
  },
  blockUserHeaderText: {
    color: Styles.black,
    fontSize: Styles.Fonts.f8,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  blockUserHeaderTextDesc: {
    color: Styles.black,
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
});

const mapStateToProps = (state: RootState) => ({
  loading: state.snack.loading,
  snackDetails: state.snack.snackDetails,
  loadingBlockedUsers: state.snack?.loadingBlockedUsers,
  blockedUsers: state.snack?.blockedUsers,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadSnackDetail: (params) => dispatch(loadSnackDetail(params)),
  blockUser: (params) => dispatch(blockUser(params)),
  acceptSnack: (params) => dispatch(acceptSnack(params)),
  cancelSnack: (params) => dispatch(cancelSnack(params)),
  markMessageAsRead: (params) => dispatch(markSingleMessageAsRead(params)),
  getSnackBlockedUsers: () => dispatch(getSnackBlockedUsers()),
  unblockSnackUser: (params) => dispatch(unblockSnackUser(params)),
});

export default compose(
  withForwardedNavigationParams<SnackDetailScreenProps>(),
  withGlobalize,
  connect(mapStateToProps, mapDispatchToProps),
)(SnackDetailScreen);
