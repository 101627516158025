import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {WithGlobalizeProps, withGlobalize} from 'react-native-globalize';
import {ProductModifierType} from 'src/types/Menu';
import Styles from '../../Styles';
import {checkValue, formatCurrency} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {getDescriber} from './descriptor/DescriptorType';
import Settings from 'src/Settings';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import {Platform} from 'react-native';

const {
  radioButtonProductModifier,
  productModifierStyles,
  checkboxProductModifiers,
  getCounterText,
  requiredModifiersView,
} = getDescriber();

type ProductModifierProps = WithGlobalizeProps & {
  productModifier: ProductModifierType;
  selectionChanged: (modifierId: string, selections: Array<string>) => void;
  selectedValues?: Array<string>;
  showCalories?: boolean;
  showAsKiloCalories?: boolean;
  sectionCalories?: [];
};
type ProductModifierState = {
  values: Array<string>;
  radio: boolean;
  required: boolean;
  freeText: boolean;
  showUnitName: string;
};
const FREE_TEXT_MAX_LENGTH = 50;
export const ModifierTypes = {
  FreeText: 4,
  Upsell: 5,
};

class ProductModifier extends React.Component<
  ProductModifierProps,
  ProductModifierState
> {
  constructor(props: ProductModifierProps) {
    super(props);
    const radio =
      props.productModifier.maxsel === 1 &&
      props.productModifier.type !== ModifierTypes.Upsell;
    let values = props.productModifier.vals
      .filter((value) => value.isdefault === 'Y')
      .map((value) => value.id);

    if (this.props.selectedValues) {
      values = this.props.selectedValues;
    }
    if (!values) {
      values = [];
    }

    this.state = {
      values,
      radio,
      required: props.productModifier.forced === 'Y',
      freeText: props.productModifier.type === ModifierTypes.FreeText,
      showUnitName: props.showAsKiloCalories
        ? Localized.Labels.kcal
        : Localized.Labels.cal_not_all_caps,
    };

    if (values.length > 0) {
      props.selectionChanged(props.productModifier.id, values);
    }
    this.onPress = this.onPress.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onPress(value: string) {
    let values: Array<string> = [];

    if (this.state.radio) {
      if (!this.state.values.includes(value)) {
        values = [value];
      }
    } else {
      if (this.state.values.includes(value)) {
        values = this.state.values.filter((item) => item !== value);
      } else if (
        this.state.values.length < this.props.productModifier.maxsel ||
        this.props.productModifier.type === ModifierTypes.Upsell
      ) {
        values = [...this.state.values, value];
      } else {
        // values haven't changed
        values = [...this.state.values];
      }
    }

    this.setState({values}, () =>
      this.props.selectionChanged(
        this.props.productModifier.id,
        this.state.values,
      ),
    );
  }

  renderCheckBoxes() {
    return this.props.productModifier.vals.map((value) => {
      let price = '';
      if (value.price !== null) {
        price = `(+${formatCurrency(
          this.props,
          value?.price,
          AccountStore.getCurrency(),
        )})`;
      }
      let calories = '';
      if (checkValue(value.calories)) {
        calories = value.calories.toString() + ' calories';
      }
      return checkboxProductModifiers(
        value,
        price,
        calories,
        this.state.values,
        this.state.showUnitName,
        this.props.showCalories,
        this.onPress,
      );
    });
  }

  renderRadioButtons() {
    const buttons = this.props.productModifier.vals.map((value) => {
      let price = '';
      if (value.price !== null) {
        price = `(+${formatCurrency(
          this.props,
          value?.price,
          AccountStore.getCurrency(),
        )})`;
      }

      return {
        label: value.name,
        price,
        value: value.id,
        calories: value?.calories,
      };
    });
    return radioButtonProductModifier(
      buttons,
      this.state.values,
      this.props.showCalories,
      this.state.showUnitName,
      this.onPress,
    );
  }

  onChange(value: string) {
    const values = [value];
    this.setState(
      {
        values,
      },
      () =>
        this.props.selectionChanged(
          this.props.productModifier.id,
          this.state.values,
        ),
    );
  }

  renderFreeTextInput() {
    return (
      <CustomTextInput
        accessible={true}
        label={this.props.productModifier.name}
        accessibilityLabel={this.props.productModifier.name}
        aria-label={this.props.productModifier.name}
        value={this.state.values?.[0]}
        showHelpText={true}
        helpText={getCounterText(
          this.state.values?.[0],
          FREE_TEXT_MAX_LENGTH,
        ).toString()}
        multiline={true}
        numberOfLines={3}
        onChangeText={this.onChange}
        maxLength={FREE_TEXT_MAX_LENGTH}
        helperStyle={productModifierStyles().helperText}
        containerStyle={{maxHeight: 95}}
        contentStyle={{
          paddingTop: Styles.Spacing.m2,
          paddingBottom:
            Platform.OS === 'ios' ? Styles.Spacing.m4 * 3 : Styles.Spacing.m4,
        }}
      />
    );
  }

  render() {
    const {
      productModifier: {name, type, maxsel},
    } = this.props;

    const {sectionCalories, showCalories, productModifier} = this.props;

    const {required, freeText, values} = this.state;
    let content: React.ReactNode = this.renderCheckBoxes();

    if (type === ModifierTypes.FreeText) {
      content = this.renderFreeTextInput();
    } else if (maxsel === 1 && type !== ModifierTypes.Upsell) {
      content = this.renderRadioButtons();
    }

    const caloricSection = (
      <>
        {sectionCalories?.map(
          (section: {id: string; sectionCalories: number}) =>
            section.id === productModifier.id && (
              <Text
                accessible={true}
                accessibilityLabel={`${section?.sectionCalories} calories for section`}
                aria-label={`${section?.sectionCalories} calories for section`}
                key={section.id}
                style={productModifierStyles().sectionCalStyles}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {`${section?.sectionCalories} ${this.state.showUnitName}`}
              </Text>
            ),
        )}
      </>
    );
    return (
      <View style={productModifierStyles().productModifierContainer}>
        {!freeText && (
          <View style={productModifierStyles().titleRow}>
            {required ? (
              requiredModifiersView(name, showCalories, caloricSection)
            ) : (
              <View style={productModifierStyles().requiredViewStyles}>
                <Text
                  accessible={true}
                  accessibilityLabel={`${name} section`}
                  aria-label={`${name} section`}
                  style={productModifierStyles().titleText}
                >
                  {name}
                </Text>
                {Settings.buildType !== 'default' &&
                  showCalories &&
                  caloricSection}
              </View>
            )}
          </View>
        )}
        <View style={productModifierStyles().options}>{content}</View>
        {Settings.buildType === 'default' && !freeText && showCalories && (
          <View
            style={[
              productModifierStyles().requiredViewStyles,
              {marginTop: Styles.Spacing.m3},
            ]}
          >
            <Text
              accessible={true}
              accessibilityLabel={`${name} section`}
              aria-label={`${name} section`}
              style={productModifierStyles().totalCaloriesLabel}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            >
              {Localized.Labels.customized_total_calories}
            </Text>
            {caloricSection}
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  requiredText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.dangerColor,
    fontStyle: 'italic',
  },
  freeText: {
    fontSize: Styles.Fonts.f0,
    color: Styles.lightGray,
    paddingHorizontal: Styles.Spacing.m2,
  },
  input: {
    borderRadius: Styles.Spacing.m1,
    backgroundColor: Styles.inputBgColor,
    borderWidth: 1,
    padding: Styles.Spacing.m1,
    height: Styles.Heights.touchTargetHeight2,
    marginHorizontal: Styles.Spacing.m2,
  },
});
export default withGlobalize(ProductModifier);
