import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IProps {
  size?: number;
}

function CirclePlusIcon({size = 25}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 12C2 6.476 6.476 2 12 2s10 4.476 10 10-4.476 10-10 10S2 17.524 2 12zm.832 0c0 5.058 4.115 9.168 9.168 9.168 5.053 0 9.168-4.115 9.168-9.168 0-5.053-4.115-9.168-9.168-9.168-5.053 0-9.168 4.11-9.168 9.168zm8.75-6.154h.807v5.765h5.765v.807h-5.765v5.736h-.807v-5.736H5.846v-.807h5.736V5.846z"
        fill="#22212D"
      />
    </Svg>
  );
}

export default CirclePlusIcon;
