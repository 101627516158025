import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import ConnectandPayDescriptor from './recentorder-canteen-descriptor';
import RedesignDescriptor from './recentorder-redesign-descriptor';

const typeDescriber = {
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.revolve]: ConnectandPayDescriptor,
  [BuildTypes.canteen]: ConnectandPayDescriptor,
};

export function getRecentOrderDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
