import React from 'react';
import {Dimensions, View, StyleSheet} from 'react-native';
import AVText from 'src/components/elements/AVText';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import SnackProductNew from 'src/components/img/svg/sendSnackStickers/SnackProductNew';
import Styles from '../../../Styles';
import Localized from 'src/constants/AppStrings';
import {
  AnnouncementStylesDescriptor,
  SendSnackDescriberData,
  CampusLocationListStylesDescriptor,
  EngagementCarouselStylesDescriptor,
} from 'src/components/elements/home/descriptor/DescriptorType';

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  const width = Dimensions.get('window').width;
  return {
    recentLocationViewLocationNameText: {
      color: Styles.black,
      width: width - Styles.Spacing.m6,
      fontSize: Styles.Fonts.f1,
      textAlignVertical: 'center',
      fontWeight: '700',
      fontFamily: Styles.FontFamily.poppinsRegular,
    },
    recentLocationAddressText: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f8,
      width: width - Styles.Spacing.m6,
      textAlignVertical: 'center',
      fontWeight: '400',
    },
  };
}

export function snackViewDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  const {styles, onSendASnackClick} = props;
  return (
    <React.Fragment>
      <View style={styles.headerView}>
        <AVText
          style={{
            fontWeight: '400',
            fontSize: 24,
            color: Styles.black,
            textTransform: 'uppercase',
            paddingTop: 20,
            paddingLeft: 15,
            fontFamily: Styles.FontFamily.comodoVintage,
          }}
          accessible={true}
          accessibilityLabel={Localized.Labels.recents}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.recents}, text`}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        >
          {Localized.Labels.gifting_title}
        </AVText>
      </View>
      <AVTouchableOpacity
        accessible={true}
        accessibilityRole="button"
        accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
        role="button"
        onPress={onSendASnackClick}
        accessibilityLabel="onCreditCardClick"
        aria-label="onCreditCardClick"
      >
        <View style={[styles.container]}>
          <View style={[styles.starIconView, {height: 100}]}>
            <View
              accessibilityLabel={'SnackIcon'}
              accessibilityRole="image"
              aria-label={`SnackIcon`}
              role="img"
              style={[{top: 10}]}
            >
              <SnackProductNew />
            </View>
            <View style={{padding: Styles.Spacing.m2 + 4, paddingBottom: -10}}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                accessibilityLabel={Localized.Buttons.send_a_snack}
                accessibilityRole="text"
                aria-label={`${Localized.Buttons.send_a_snack}, text`}
                style={[
                  {textTransform: 'uppercase', paddingVertical: 6},
                  styles.revolveMainTxt,
                ]}
              >
                {Localized.Buttons.send_a_snack}
              </AVText>

              <AVText
                accessibilityLabel={
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
                aria-label={
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
                accessibilityRole="text"
                style={[{width: '40%'}, styles.revolveAddTxt]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
              </AVText>
            </View>
          </View>

          <View style={[{top: -50, alignSelf: 'flex-end'}]}>
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}

export function getScanAndPayTile(): {[key: string]: unknown} {
  return {
    iconViewBackground: {
      backgroundColor: Styles.lightBlueRevolve + '50',
      width: 50,
      height: 50,
      marginHorizontal: Styles.Spacing.m3,
      marginVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    titleText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.black,
      marginBottom: 3,
    },
    nameText: {
      color: Styles.black,
      fontWeight: '400',
      fontSize: Styles.Fonts.f7,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    rowSpace: {
      marginVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    listContainer: {
      marginTop: Styles.Spacing.m2,
    },
    textView: {
      minHeight: 56,
      marginVertical: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
  };
}

export function infoIconPrimaryColor() {
  return Styles.primaryColor;
}
export function infoIconInnerColor() {
  return Styles.positiveColor;
}
export function announcemnentButtonColor() {
  return Styles.darkGreenRevolve;
}

export function getAnnouncementStyles(): AnnouncementStylesDescriptor {
  return StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      marginTop: Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3,
      marginBottom: Styles.FontSizeMultiplier.maxfm8,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...Styles.Style.shadow,
    },
    titleText: {
      fontSize: Styles.Fonts.headerFont,
      width: '90%',
      fontWeight: '700',
      color: Styles.black,
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m2,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    descriptionText: {
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.darkColor,
      marginRight: Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  });
}

export function getCampusLocationListStyles(): CampusLocationListStylesDescriptor {
  return StyleSheet.create({
    container: {
      marginTop: Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    campusLocationListView: {
      width: '100%',
    },
    headerMargin: {
      marginLeft: 0,
    },
  });
}

export function getWidthImageAvailable() {
  return '80%';
}
export function referralViewDescriptor(): JSX.Element {
  return <></>;
}
export function rewardsViewDescriptor(): JSX.Element {
  return <></>;
}
export function engagementCarouselMargin(): number {
  return Styles.Spacing.m3;
}
export function engagementCarouselMarginBottom(): number {
  return -Styles.Spacing.m4;
}
export function engagementCarouselPaginationColor(): string {
  return Styles.Colors.Canteen.darkGreen;
}
export function engagementCarouselPaginationInactiveColor(): string {
  return Styles.inActiveDotColor;
}
export function engagementCarouselMarginZeroLength(): number | undefined {
  return undefined;
}
export function getEngagementCarouselStyles(): EngagementCarouselStylesDescriptor {
  return StyleSheet.create({
    activeDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    inActiveDotStyle: {
      width: 14,
      height: 14,
      borderRadius: 9,
    },
    imageContentContainer: {
      paddingLeft: 10,
    },
    parralaxContainer: {
      backgroundColor: Styles.white,
    },
  });
}

export default {
  getCampusLocationStyles,
  snackViewDescriptor,
  getScanAndPayTile,
  infoIconPrimaryColor,
  infoIconInnerColor,
  announcemnentButtonColor,
  getAnnouncementStyles,
  getWidthImageAvailable,
  getCampusLocationListStyles,
  referralViewDescriptor,
  rewardsViewDescriptor,
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  engagementCarouselPaginationInactiveColor,
  getEngagementCarouselStyles,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
};
