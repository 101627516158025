import React, {useCallback, useEffect, useRef} from 'react';
import {AppState, AppStateStatus, TouchableOpacity} from 'react-native';
import SystemSetting from 'react-native-system-setting';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import AVTouchableOpacity from './AVTouchableOpacity';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const MANUAL_BRIGHTNESS_MODE = 0;
const AUTOMATIC_BRIGHTNESS_MODE = 1;
type BrightnessTouchableProps = React.ComponentProps<
  typeof TouchableOpacity
> & {
  timeout: number | null;
};
type State = 'active' | 'background' | 'inactive';
export default function BrightnessTouchable(props: BrightnessTouchableProps) {
  const originalBrightness = useRef<number>(-1);
  const originalScreenMode = useRef<number>(MANUAL_BRIGHTNESS_MODE);
  const brightnessTimeoutId = useRef<any>();
  const resetBrightness = useCallback(() => {
    clearTimeout(brightnessTimeoutId.current);
    brightnessTimeoutId.current = undefined;

    if (originalBrightness.current >= 0) {
      SystemSetting.setAppBrightness(originalBrightness.current);
    }

    if (originalScreenMode.current === AUTOMATIC_BRIGHTNESS_MODE) {
      SystemSetting.setScreenMode(originalScreenMode.current);
    }
  }, []);
  const setBrightness = useCallback(async () => {
    try {
      if (brightnessTimeoutId.current === undefined) {
        originalBrightness.current = await SystemSetting.getAppBrightness();
        originalScreenMode.current = await SystemSetting.getScreenMode();

        if (originalScreenMode.current === AUTOMATIC_BRIGHTNESS_MODE) {
          await SystemSetting.setScreenMode(MANUAL_BRIGHTNESS_MODE);
        }
      } else {
        clearTimeout(brightnessTimeoutId.current);
      }

      SystemSetting.setAppBrightness(0.9);

      if (props.timeout !== null) {
        brightnessTimeoutId.current = setTimeout(
          resetBrightness,
          props.timeout,
        );
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'BrightnessTouchable:SetBrightness',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'BrightnessTouchable:SetBrightness',
        generateErrorMessage(error),
      );
    }
  }, [props.timeout, resetBrightness]);
  useEffect(() => {
    function handleAppStateChange(state: AppStateStatus) {
      if (state === 'background' || state === 'inactive') {
        resetBrightness();
      }
    }

    const handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      handleAppStateChange,
    );
    return () => {
      resetBrightness();
      handleAppStateChangeSubscription.remove();
    };
  });
  return <AVTouchableOpacity onPress={setBrightness} {...props} />;
}
