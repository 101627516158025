import React from 'react';
import {StyleSheet, Platform, ScrollView, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import MainConsumerContext from '../../MainConsumerContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import PassKit from 'src/nativeModules/PassKit';
import Header from '../../elements/Header';
import MenuOption from '../../elements/MenuOption';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import Section from '../../elements/Section';
import Styles from '../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError, alertSuccess} from '../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Util, {getPreviousRouteName} from 'src/Util';
import {NavigationProp} from '@react-navigation/native';
import CommonUtils from './newui/commonutils';
import uuid from '../../../nativeModules/UUID';
import {authStore} from 'src/init';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';
type AccountProps = {
  [key: string]: {[index: string]: number | string};
} & {
  paymentCredentials?: PaymentCredentials;
  navigation: NavigationProp<Account>;
  emailChanged?: boolean;
};

type AccountState = {
  fullVersion: string;
  email: string;
  name: string;
  locationName: string;
  feedback: string;
  sendSnackStatus: boolean;
  previousRoute: string;
  isSSO: boolean;
};

class Account extends React.Component<AccountProps, AccountState> {
  static defaultProps = {
    passExists: false,
    canAddPass: false,
    rewards: 0,
    passUrl: '',
  };
  static contextType = MainConsumerContext;
  declare context: React.ContextType<typeof MainConsumerContext>;
  currentPolicy: string;
  currentPolicyType: string;

  constructor(props: AccountProps) {
    super(props);
    this.state = {
      fullVersion: '',
      previousRoute: '',
      name: AccountStore.getFullName(),
      email: AccountStore.getEmail(),
      locationName: AccountStore.getLocationName(),
      feedback: AccountStore.getLocationFeedback(),
      sendSnackStatus: AccountStore.isConsumerSendSnackEnabled(),
      isSSO: false,
    };
    this.onLogoutSelect = this.onLogoutSelect.bind(this);
    this.toPurchaseHistory = this.toPurchaseHistory.bind(this);
    this.toCards = this.toCards.bind(this);
    this.toBalances = this.toBalances.bind(this);
    this.toManageAccount = this.toManageAccount.bind(this);
    this.rewardsClicked = this.rewardsClicked.bind(this);
    this.eatItOrDeleteItClicked = this.eatItOrDeleteItClicked.bind(this);
    this.addPassClicked = this.addPassClicked.bind(this);
    this.handlePrivacyPolicy = this.handlePrivacyPolicy.bind(this);
    this.onAccountChange = this.onAccountChange.bind(this);
    this.feedbackClicked = this.feedbackClicked.bind(this);
    this.blockedUsersClicked = this.blockedUsersClicked.bind(this);
    this.onSendSnackStatusChange = this.onSendSnackStatusChange.bind(this);
    this.handleDeleteAccount = this.handleDeleteAccount.bind(this);
    this.handleChangePin = this.handleChangePin.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleLinkLocation = this.handleLinkLocation.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  async handleIsSSO() {
    const isSSO = await authStore.getIsSSO();
    this.setState({isSSO});
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'AccountScreen', {
      ...this.props,
      ...this.state,
    });
    try {
      const previousRoute = getPreviousRouteName(
        this.props.navigation.getState().routes,
      );

      const fullVersion = await Settings.getFullDisplayVersion(Platform.OS);
      this.setState({
        fullVersion,
        previousRoute,
      });
      const response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        this.currentPolicy = response.version;
        this.currentPolicyType = response.type;
      }

      AccountStore.addChangeListener(this.onAccountChange);
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreen:componentDidMount',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:componentDidMount',
        generateErrorMessage(error),
        guid,
      );
    }
    this.handleIsSSO();
  }

  componentDidUpdate(prevProps: AccountProps) {
    if (
      prevProps.emailChanged !== this.props.emailChanged &&
      this.props.emailChanged
    ) {
      this.onRefresh();

      alertSuccess(
        Localized.Success.your_new_email_has_been_updated_in_the_app,
      );
    }
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountChange);
  }

  async onRefresh() {
    try {
      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreenNewUI:onRefresh',
        generateErrorMessage(error),
        guid,
        this.props,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreenNewUI:onRefresh',
        generateErrorMessage(error),
        guid,
        this.props,
      );
    }
  }

  onAccountChange() {
    try {
      const response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        this.currentPolicy = response.version;
        this.currentPolicyType = response.type;
      }

      this.setState({
        name: AccountStore.getFullName(),
        email: AccountStore.getEmail(),
        locationName: AccountStore.getLocationName(),
        sendSnackStatus: AccountStore.isConsumerSendSnackEnabled(),
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreen:onAccountChange',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:onAccountChange',
        generateErrorMessage(error),
      );
    }
  }

  async onLogoutSelect() {
    this.context?.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    this.context?.actions.hideSpinner();
    this.context?.actions.changeRootRoute(AppRoutes.Login);
  }

  toPurchaseHistory() {
    Settings.isNewUI()
      ? NavActions.push(AppRoutes.History)
      : NavActions.push(AppRoutes.PurchaseHistory);
  }

  toBalances() {
    NavActions.push(AppRoutes.Balances);
  }

  toCards() {
    NavActions.push(AppRoutes.Cards);
  }
  public amountWithCurrency =
    AccountStore.getReferralCampaign() &&
    Util.formatCurrency(
      AccountStore.getReferralCampaign().amount,
      AccountStore.getReferralCampaign().amount,
      AccountStore.getCurrency(),
    ) &&
    Util.formatCurrency(
      AccountStore.getReferralCampaign().amount,
      AccountStore.getReferralCampaign().amount,
      AccountStore.getCurrency(),
    )?.split('.').length > 0
      ? Util.formatCurrency(
          AccountStore.getReferralCampaign().amount,
          AccountStore.getReferralCampaign().amount,
          AccountStore.getCurrency(),
        )?.split('.')[0]
      : null;

  toManageAccount() {
    NavActions.push(AppRoutes.ManageAccount);
  }
  handleLinkLocation = () => {
    NavActions.push(AppRoutes.LocationCode);
  };

  handlePrivacyPolicy() {
    CommonUtils.handlePrivacyPolicy(this.currentPolicy, this.currentPolicyType);
  }

  rewardsClicked() {
    NavActions.push(AppRoutes.Rewards);
  }

  eatItOrDeleteItClicked() {
    try {
      const location = AccountStore.getLocationId();
      const alreadyVisitedWelcomeScreen =
        AccountStore.hasVisitedEatItOrDeleteItWelcomeScreen();

      if (!location || !alreadyVisitedWelcomeScreen) {
        NavActions.push(AppRoutes.EatItOrDeleteItWelcome);
      } else {
        NavActions.push(AppRoutes.ProductFeedbackInstruction, {
          eatItUrl: AccountStore.getEatItUrl(),
        });
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreen:eatItOrDeleteItClicked',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:eatItOrDeleteItClicked',
        generateErrorMessage(error),
      );
    }
  }

  feedbackClicked() {
    NavActions.push(AppRoutes.Feedback, {
      feedback: this.state.feedback,
    });
  }

  handleDeleteAccount() {
    NavActions.push(AppRoutes.DeleteAccount);
  }

  handleChangePin() {
    NavActions.push(AppRoutes.ResetPin);
  }

  handleChangePassword() {
    NavActions.push(AppRoutes.ChangePassword);
  }
  async addPassClicked() {
    this.context?.actions.showSpinner();

    try {
      const success = await PassKit.addPass(this.context?.state.passUrl ?? '');

      FirebaseAnalytic.trackEvent('addPassClicked', 'AccountScreen', {
        ...this.props,
        ...this.state,
        success,
      });

      if (success) {
        const passExists = await PassKit.doesPassExist(
          this.context?.state.passUrl ?? '',
        );

        if (passExists) {
          if (this.context?.actions.passAdded) {
            this.context.actions.passAdded();
          }

          Events.Pass.trackEvent();
        }
      } else {
        alertError(Localized.Errors.failed_to_add_pass);
      }
    } finally {
      this.context?.actions.hideSpinner();
    }
  }

  blockedUsersClicked() {
    NavActions.push(AppRoutes.SnackBlockedUsers);
  }

  async onSendSnackStatusChange() {
    this.context?.actions.showSpinner();

    try {
      const response =
        (await ActionsFactory.getAccountActions().updateSendSnackStatus(
          AccountStore.getAccountId(),
          !this.state.sendSnackStatus,
        )) as {sendaSnackEnabled: boolean};

      if (response) {
        this.setState({
          sendSnackStatus: response.sendaSnackEnabled,
        });
      } else {
        alertError(Localized.Errors.error_saving_user_send_snack_status);
      }
    } catch (error) {
      alertError(Localized.Errors.error_saving_user_send_snack_status);
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreen:onSendSnackStatusChange',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:onSendSnackStatusChange',
        generateErrorMessage(error),
      );
    } finally {
      this.context?.actions.hideSpinner();
    }
  }

  render() {
    // let rewardOption;
    // if (this.context?.state.rewards) {
    //   rewardOption = (
    //     <MenuOption
    //       accessible={true}
    //       accessibilityLabel={Localized.Labels.rewards}
    //       accessibilityHint="Double tap to view available rewards"
    //       onPress={this.rewardsClicked}
    //       label={Localized.Labels.rewards}
    //     />
    //   );
    // }

    // let sendLogsToDeveloper;
    // if (Logger.Log.isLogEnabled()) {
    //   sendLogsToDeveloper = (
    //     <MenuOption
    //       accessibilityLabel={Localized.Labels.send_logs_to_app_developers}
    //       accessibilityHint="Double tap to navigate to send logs to app developers"
    //       onPress={() => Logger.Log.shareLogFile()}
    //       label={Localized.Labels.send_logs_to_app_developers}
    //     />
    //   );
    // }

    return (
      <Header title={Localized.Labels.account}>
        <ScrollView>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Full Name: ${this.state.name}`}
            accessibilityRole="text"
            aria-label={`Full Name: ${this.state.name}, text`}
            style={[styles.nameText, styles.content]}
          >
            {this.state.name}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Email: ${this.state.email}`}
            accessibilityRole="text"
            aria-label={`Email: ${this.state.email}, text`}
            style={[styles.emailText, styles.content]}
          >
            {this.state.email}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            accessible={true}
            accessibilityLabel={`Market Location: ${this.state.locationName}`}
            accessibilityRole="text"
            aria-label={`Market Location: ${this.state.locationName}, text`}
            style={[styles.locationText, styles.content]}
          >
            {this.state.locationName}
          </AVText>

          <Section title={Localized.Labels.gift_title}>
            {AccountStore.isConsumerSendSnackEnabled() &&
              AccountStore.isSendSnackEnabledForLocation() && (
                <MenuOption
                  accessibilityLabel={Localized.Labels.send_a_snack_title}
                  accessibilityHint="Double tap to navigate to the send a snack screen"
                  onPress={CommonUtils.sendasnackClicked}
                  label={Localized.Labels.send_a_snack_title}
                />
              )}
            {AccountStore?.getReferralCampaign() &&
              AccountStore?.getReferralStat() && (
                <MenuOption
                  accessibilityLabel={
                    Localized.Labels.formatString(
                      Localized.Labels.referral_invite_friends,
                      this.amountWithCurrency || 0,
                      this.amountWithCurrency || 0,
                    ) as string
                  }
                  accessibilityHint="Double tap to navigate to the referral screen"
                  onPress={CommonUtils.referralClicked}
                  label={
                    Localized.Labels.formatString(
                      Localized.Labels.referral_invite_friends,
                      this.amountWithCurrency || 0,
                      this.amountWithCurrency || 0,
                    ) as string
                  }
                />
              )}
          </Section>
          <Section title={Localized.Labels.payment_account_section}>
            <MenuOption
              accessibilityLabel={Localized.Labels.wallet}
              accessibilityHint="Double tap to view your wallet"
              onPress={CommonUtils.toWalletScreen}
              label={Localized.Labels.wallet}
            />
            {!this.context?.state.passExists &&
              this.context?.state.canAddPass && (
                <MenuOption
                  accessible={true}
                  accessibilityLabel={Localized.Labels.add_to_apple_wallet}
                  accessibilityHint="Double tap to navigate to add your apple wallet"
                  onPress={this.addPassClicked}
                  label={Localized.Labels.add_to_apple_wallet}
                />
              )}
          </Section>
          <Section title={Localized.Labels.transaction_history}>
            <MenuOption
              accessibilityLabel={Localized.Labels.receipts}
              accessibilityHint="Double tap to view receipts"
              onPress={this.toPurchaseHistory}
              label={Localized.Labels.receipts}
            />
          </Section>

          <Section title={Localized.Labels.support}>
            <MenuOption
              accessibilityLabel={Localized.Labels.help}
              accessibilityHint="Double tap to navigate to the help screen"
              onPress={CommonUtils.contactClicked}
              label={Localized.Labels.help}
            />
            {!this.state.isSSO && (
              <MenuOption
                accessibilityLabel={Localized.Labels.change_email}
                accessibilityHint="Double tap to change your email"
                onPress={CommonUtils.handleChangeEmail}
                label={Localized.Labels.change_email}
              />
            )}
            <MenuOption
              accessibilityLabel={Localized.Labels.change_pin}
              accessibilityHint="Double tap to change your pin"
              onPress={CommonUtils.handleChangePin}
              label={Localized.Labels.change_pin}
            />
          </Section>
          <Section title={Localized.Labels.settings}>
            <MenuOption
              accessibilityLabel={Localized.Labels.personal_info}
              accessibilityHint="Double tap to view or update your personal information"
              onPress={this.toManageAccount}
              label={Localized.Labels.personal_info}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.personal_info}
              accessibilityHint="Double tap to view or update your personal information"
              onPress={this.handleLinkLocation}
              label={Localized.Labels.link_location}
            />
            {AccountStore.isConsumerSendSnackEnabled() &&
              AccountStore.isSendSnackEnabledForLocation() && (
                <MenuOption
                  accessible={true}
                  accessibilityLabel={Localized.Labels.blocked_users}
                  accessibilityHint="Double tap to navigate to the blocked users screen"
                  onPress={CommonUtils.blockedUsersClicked}
                  label={Localized.Labels.blocked_users}
                />
              )}
            <MenuOption
              accessibilityLabel={Localized.Labels.view_privacy_policy}
              accessibilityHint="Double tap to view the privacy policy"
              onPress={this.handlePrivacyPolicy}
              label={Localized.Labels.view_privacy_policy}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.view_terms_and_conditions}
              accessibilityHint="Double tap to view terms and conditions"
              onPress={CommonUtils.handleTerms}
              label={Localized.Labels.view_terms_and_conditions}
            />
            <MenuOption
              accessibilityLabel={Localized.Labels.delete_account}
              accessibilityHint="Double tap to navigate to the delete account screen"
              onPress={CommonUtils.handleDeleteAccount}
              label={Localized.Labels.delete_account}
            />
          </Section>

          <View style={styles.content}>
            <RoundedButton
              text={Localized.Buttons.signout}
              onPress={this.onLogoutSelect}
              containerStyle={styles.btnStyle}
              buttonType={ButtonType.outline}
              accessible={true}
              accessibilityLabel={Localized.Buttons.signout}
              accessibilityHint="Double tap to log out"
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.signout}
            />
            <AVText
              style={styles.versionText}
              accessible={true}
              accessibilityLabel={`Application version: ${this.state.fullVersion}`}
              accessibilityRole="text"
              aria-label={`Application version: ${this.state.fullVersion}, text`}
            >
              {this.state.fullVersion}
            </AVText>
          </View>
        </ScrollView>
      </Header>
    );
  }
}

const styles = StyleSheet.create({
  btnStyle: {
    marginTop: Styles.Spacing.m2,
  },
  content: {
    paddingHorizontal: Styles.Spacing.m3,
  },
  emailText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
  },
  locationText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m0,
  },
  nameText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
    marginTop: Styles.Spacing.m3,
  },
  roundButtonText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
  },
  versionText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f0,
    marginBottom: Styles.Spacing.m4,
    marginTop: Styles.Spacing.m4,
  },
});
const ConnectedAccount = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
}))(Account);
export default withForwardedNavigationParams<AccountProps>()(ConnectedAccount);
