import * as React from 'react';
import {FlatList, StyleSheet, View, ActivityIndicator} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import BackSubheader from '../../elements/BackSubheader';
import ScreenContext from '../../ScreenContext';
import type {
  RecentOrderType,
  RecentOrderItemType,
  RecentOrderItemModifierType,
} from 'src/types/RecentOrders';
import type {LocationType} from 'src/types/Location';
import AccountStore from 'src/stores/AccountStore';
import Styles from '../../Styles';
import CheckoutBar from '../../elements/orderAhead/CheckoutBar';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import AccountConstants from 'src/constants/AccountConstants';
import MenuService from 'src/services/MenuService';
import CartService from 'src/services/CartService';
import TransactionStore from 'src/stores/TransactionStore';
import CartTypes from 'src/constants/cart/CartTypes';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../../helpers/AlertHelper';
import MenuActions from 'src/actions/MenuActions';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import uuid from 'src/nativeModules/UUID';
import {compose} from 'redux';
import {AppDispatch, RootState} from 'src/redux/store';
import {connect} from 'react-redux';
import {
  getRecentOrders,
  GetRecentOrdersParams,
} from 'src/redux/thunks/menuThunks';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {getRecentOrderDescriber} from 'src/components/screens/orderAhead/descriptor/recentOrders/DescriptorType';

type RecentOrdersScreenProps = {
  location: LocationType;
  loading: boolean;
  recentOrders: Array<RecentOrderType>;
  getRecentOrders(params: GetRecentOrdersParams): void;
};

class RecentOrdersScreen extends React.Component<RecentOrdersScreenProps> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: RecentOrdersScreenProps) {
    super(props);

    this.getCartItem = this.getCartItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.addAllItems = this.addAllItems.bind(this);
    this.goToCart = this.goToCart.bind(this);
    this.goToProductDetail = this.goToProductDetail.bind(this);
    this.checkModifierAvailablity = this.checkModifierAvailablity.bind(this);
    this.onProductCellPressed = this.onProductCellPressed.bind(this);
    this.checkModiferAddAll = this.checkModiferAddAll.bind(this);
  }

  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'RecentOrdersScreen', {
      ...this.props,
    });

    this.props.getRecentOrders({
      locationId: this.props.location.locationId,
    });
  }

  async addAllItems(order: RecentOrderType) {
    FirebaseAnalytic.trackEvent('addAllItems', 'RecentOrdersScreen', {
      ...this.props,
      order,
    });

    try {
      this.context.actions.showSpinner();
      const newOrder = JSON.parse(JSON.stringify(order));
      const length = newOrder.items.length;
      for (let i = 0; i < length; i++) {
        const item = newOrder.items[i];
        if (item.available) {
          const newItems = await this.checkModiferAddAll(item);
          newOrder.items[i] = newItems ?? item;
        }
      }
      if (this.areModifiersAllAvailableForOrder(newOrder)) {
        this.forceAddAllItems(newOrder);
      } else {
        confirm(
          Localized.Labels.modifiers_unavailable,
          () => {
            this.onOkPressed(newOrder);
          },
          undefined,
          Localized.Labels.continue_with_order,
          Localized.Buttons.cancel,
          Localized.Buttons.ok,
        );
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:addAllItems',
        generateErrorMessage(error),
        guid,
      );
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:addAllItems',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:addAllItems',
        generateErrorMessage(error),
        guid,
      );
      alertError(Localized.Errors.internet_issue, guid);
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  onOkPressed(order: RecentOrderType) {
    try {
      const filteredItems = order.items.reduce((acc, obj) => {
        if (!obj.available) {
          return acc;
        }
        const modifiers = obj.modifiers.filter((modObj) => modObj.available);
        acc.push({...obj, modifiers});
        return acc;
      }, []);
      FirebaseAnalytic.trackEvent('onOkPressed', 'RecentOrdersScreen', {
        ...this.props,
        order,
        filteredItems,
      });
      this.forceAddAllItems({...order, items: filteredItems});
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:onOkPressed',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:onOkPressed',
        generateErrorMessage(error),
      );
    }
  }

  async forceAddAllItems(order: RecentOrderType) {
    try {
      const transactionItems = TransactionStore.getAllItems();
      const prepDefaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );
      FirebaseAnalytic.trackEvent('forceAddAllItems', 'RecentOrdersScreen', {
        ...this.props,
        order,
        transactionItems,
        prepDefaults,
      });
      if (transactionItems && order && order.items) {
        const items = [...transactionItems];
        const length = order.items.length;
        for (let i = 0; i < length; i++) {
          const item = order.items[i];
          if (item.available && item.modifiers.length > 0) {
            const product = MenuService.getProductByScanCode(item.scancode);
            await MenuActions.getModifiers(
              this.props.location.locationId,
              product,
            );
          }
          const cartItem = this.getCartItem(item);
          items.unshift(cartItem);
        }
        const response = CartService.updateCart(
          this.props.location.locationId,
          AccountStore.getAccountId(),
          [],
          items,
          AccountConstants.SOS_LOCATION_TYPE,
          order.items[0].scancode,
          TransactionStore.getPickupTime(),
          prepDefaults.selectedMethod,
          this.context,
        );
        FirebaseAnalytic.trackEvent(
          'forceAddAllItems updateCart',
          'RecentOrdersScreen',
          {
            ...this.props,
            order,
            transactionItems,
            prepDefaults,
            response,
            items,
          },
        );
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:forceAddAllItems',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:forceAddAllItems',
        generateErrorMessage(error),
      );
    }
  }

  async addItem(item: RecentOrderItemType) {
    try {
      let updatedItem = await this.checkModifierAvailablity(item);
      FirebaseAnalytic.trackEvent('addItem', 'RecentOrdersScreen', {
        ...this.props,
        item,
        updatedItem,
      });
      if (!this.areModifiersAllAvailableForItem(updatedItem)) {
        const newUpdatedItem = updatedItem.modifiers.filter((i) => i.available);
        updatedItem = {...updatedItem, modifiers: newUpdatedItem};
        confirm(
          Localized.Labels.modifiers_unable_to_add_prompt,
          () => this.goToProductDetail(updatedItem),
          undefined,
          Localized.Labels.modifiers_unable_to_add,
          Localized.Buttons.cancel,
          Localized.Buttons.ok,
        );
      } else {
        this.forceAddItem(updatedItem);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:addItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:addItem',
        generateErrorMessage(error),
      );
    }
  }

  forceAddItem(item: RecentOrderItemType) {
    try {
      const transactionItems = TransactionStore.getAllItems();
      const prepDefaults = TransactionStore.getPreparationMethodDefaults(
        this.props.location,
      );

      if (transactionItems) {
        const items = [...transactionItems];
        const cartItem = this.getCartItem(item);
        items.unshift({...cartItem});

        FirebaseAnalytic.trackEvent('forceAddItem', 'RecentOrdersScreen', {
          ...this.props,
          item,
          items,
          transactionItems,
          prepDefaults,
          cartItem,
        });
        CartService.updateCart(
          this.props.location.locationId,
          AccountStore.getAccountId(),
          [],
          items,
          AccountConstants.SOS_LOCATION_TYPE,
          item.scancode,
          TransactionStore.getPickupTime(),
          prepDefaults.selectedMethod,
          this.context,
        );
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:forceAddItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:forceAddItem',
        generateErrorMessage(error),
      );
    }
  }

  areModifiersAllAvailableForOrder(order: RecentOrderType) {
    if (order.items && order.items.length > 0) {
      for (let i = 0; i < order.items.length; i++) {
        const item = order.items[i];
        if (item.available) {
          if (!this.areModifiersAllAvailableForItem(item)) {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    return true;
  }

  areModifiersAllAvailableForItem(item: RecentOrderItemType) {
    if (item.modifiers && item.modifiers.length > 0) {
      for (let i = 0; i < item.modifiers.length; i++) {
        const modifier = item.modifiers[i];

        if (!modifier.available) {
          return false;
        }
      }
    }

    return true;
  }

  getCartItem(item: RecentOrderItemType) {
    const cartItem = {
      BarCode: item.scancode,
      Modifiers: [],
      PrintGroups: item.printgroups,
    };
    try {
      if (item.modifiers && item.modifiers.length > 0) {
        item.modifiers.forEach((itemModifier: RecentOrderItemModifierType) => {
          if (itemModifier.available) {
            cartItem.Modifiers.push(
              MenuService.getCartModifier(
                itemModifier.KeyRef ?? itemModifier.keyReference,
              ),
            );
          }
        });
      }
      FirebaseAnalytic.trackEvent('getCartItem', 'RecentOrdersScreen', {
        ...this.props,
        item,
        cartItem,
      });
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:getCartItem',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:getCartItem',
        generateErrorMessage(error),
      );
    }
    return cartItem;
  }

  goToCart() {
    FirebaseAnalytic.trackEvent('goToCart', 'RecentOrdersScreen', {
      ...this.props,
      marketName: this.props.location.name,
      locationId: this.props.location.locationId,
      locationType: AccountConstants.SOS_LOCATION_TYPE,
      beaconId: this.props.location.beaconId ?? this.props.location.locationId,
      cartType: CartTypes.OrderAhead,
      location: this.props.location,
      showPreparationMethod:
        this.props.location.onlineOrderConfig.hasDiningPreference,
      defaultPreparationMethod:
        this.props.location.onlineOrderConfig.diningPreference,
      navigate: AppRoutes.Scan,
    });

    NavActions.push(AppRoutes.Scan, {
      marketName: this.props.location.name,
      locationId: this.props.location.locationId,
      locationType: AccountConstants.SOS_LOCATION_TYPE,
      beaconId: this.props.location.beaconId ?? this.props.location.locationId,
      cartType: CartTypes.OrderAhead,
      location: this.props.location,
      showPreparationMethod:
        this.props.location.onlineOrderConfig.hasDiningPreference,
      defaultPreparationMethod:
        this.props.location.onlineOrderConfig.diningPreference,
    });
  }

  async goToProductDetail(item: RecentOrderItemType) {
    if (item.scancode) {
      const product = MenuService.getProductByScanCode(item.scancode);
      try {
        this.context.actions.showSpinner();
        NavActions.push(AppRoutes.ProductDetail, {
          strings: Localized,
          location: this.props.location,
          product,
          modifierSelections: item.modifiers,
          isRecentOrder: true,
        });
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RecentOrdersScreen:goToProductDetail',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RecentOrdersScreen:goToProductDetail',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.internet_issue, guid);
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }

  async checkModifierAvailablity(item: RecentOrderItemType) {
    FirebaseAnalytic.trackEvent(
      'checkModifierAvailablity',
      'RecentOrdersScreen',
      {
        ...this.props,
        item,
      },
    );
    if (item.scancode) {
      const product = MenuService.getProductByScanCode(item.scancode);
      try {
        this.context.actions.showSpinner();
        const response = await MenuActions.getModifiers(
          this.props.location.locationId,
          product,
        );

        const updatedItem = JSON.parse(JSON.stringify(item));

        for (let i = 0; i < updatedItem.modifiers.length; i++) {
          const itemModifier = updatedItem.modifiers[i];
          itemModifier.available = false;
          itemModifier.Modifier = itemModifier.modifier;
          const menuModifier = MenuService.getCartModifierByName(
            product.tabs,
            itemModifier.name,
          );
          if (menuModifier) {
            itemModifier.available = true;
            itemModifier.price = menuModifier.Price;
            itemModifier.KeyRef =
              menuModifier.KeyRef ?? itemModifier.keyReference;
          }
        }

        FirebaseAnalytic.trackEvent(
          'checkModifierAvailablity',
          'RecentOrdersScreen',
          {
            ...this.props,
            item,
            response,
            updatedItem,
          },
        );

        return updatedItem;
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RecentOrdersScreen:checkModifierAvailablity',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RecentOrdersScreen:checkModifierAvailablity',
          generateErrorMessage(error),
          guid,
        );
      } finally {
        this.context.actions.hideSpinner();
      }
    }
  }
  async onProductCellPressed(item: RecentOrderItemType) {
    try {
      let updatedItem = await this.checkModifierAvailablity(item);

      FirebaseAnalytic.trackEvent(
        'onProductCellPressed',
        'RecentOrdersScreen',
        {
          ...this.props,
          item,
          updatedItem,
        },
      );
      if (!this.areModifiersAllAvailableForItem(updatedItem)) {
        const newUpdatedItem = updatedItem.modifiers.filter((i) => i.available);
        updatedItem = {...updatedItem, modifiers: newUpdatedItem};
        confirm(
          Localized.Labels.modifiers_unable_to_add_prompt,
          () => this.goToProductDetail(updatedItem),
          undefined,
          Localized.Labels.modifiers_unable_to_add,
          Localized.Buttons.cancel,
          Localized.Buttons.ok,
        );
      } else {
        this.goToProductDetail(updatedItem);
      }
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentOrdersScreen:onProductCellPressed',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentOrdersScreen:onProductCellPressed',
        generateErrorMessage(error),
      );
    }
  }
  async checkModiferAddAll(item: RecentOrderItemType) {
    if (item.scancode && item.available && item.modifiers.length > 0) {
      const product = MenuService.getProductByScanCode(item.scancode);
      try {
        await MenuActions.getModifiers(this.props.location.locationId, product);

        const updatedItem = JSON.parse(JSON.stringify(item));

        for (let i = 0; i < updatedItem.modifiers.length; i++) {
          const itemModifier = updatedItem.modifiers[i];
          itemModifier.available = false;
          itemModifier.Modifier = itemModifier.modifier;
          const menuModifier = MenuService.getCartModifierByName(
            product.tabs,
            itemModifier.name,
          );

          if (menuModifier) {
            itemModifier.available = true;
            itemModifier.price = menuModifier.Price;
            itemModifier.KeyRef =
              menuModifier.KeyRef ?? itemModifier.keyReference;
          }
        }

        FirebaseAnalytic.trackEvent(
          'checkModiferAddAll',
          'RecentOrdersScreen',
          {
            ...this.props,
            item,
            updatedItem,
          },
        );

        return updatedItem;
      } catch (error) {
        const guid = await uuid.getRandomUUID();
        CrashlyticsEvents.log(
          'Exception',
          'RecentOrdersScreen:checkModifierAvailablityAddAll',
          generateErrorMessage(error),
          guid,
        );
        Events.Error.trackEvent(
          'Exception',
          'RecentOrdersScreen:checkModifierAvailablityAddAll',
          generateErrorMessage(error),
          guid,
        );
        alertError(Localized.Errors.internet_issue, guid);
      }
    }
  }

  render() {
    //renderItem={({item}) => {getRecentOrderDescriber().getRecentOrderListItem(item,this.addItem, this.addAllItems, this.onProductCellPressed)}
    return (
      <BackSubheader title={Localized.Buttons.recent_orders}>
        {this.props.recentOrders.length != 0 && (
          <FlatList
            data={this.props.recentOrders}
            style={styles.ordersContainer}
            keyExtractor={(item) => item.saleHeaderId}
            ListFooterComponent={<View style={{height: Styles.Heights.h8}} />}
            renderItem={({item}) => {
              return getRecentOrderDescriber().getRecentOrderListItem(
                item,
                this.addItem,
                this.addAllItems,
                this.onProductCellPressed,
              );
            }}
          />
        )}
        {this.props.recentOrders.length == 0 &&
          !this.props.loading &&
          this.getEmptyScreen()}
        {this.props.recentOrders.length == 0 &&
          this.props.loading &&
          this.getLoadingScreen()}
        <CheckoutBar
          location={this.props.location}
          strings={Localized}
          onPress={this.goToCart}
        />
      </BackSubheader>
    );
  }

  getLoadingScreen() {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator color={Styles.primaryColor} size="large" />
      </View>
    );
  }

  getEmptyScreen() {
    return getRecentOrderDescriber().getEmptyList();
  }
}

const styles = StyleSheet.create({
  ordersContainer: {
    flex: 1,
    shadowColor: Styles.lightGray,
    shadowOffset: {width: -2, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
  emptyText: {
    textAlign: 'center',
    color: Styles.darkColor,
    marginTop: Styles.Spacing.m3,
    fontSize: Styles.Fonts.f10,
    fontWeight: 'bold',
    marginBottom: Styles.Spacing.m3,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    marginTop: Styles.Spacing.m3,
  },
});

export default compose(
  withForwardedNavigationParams<RecentOrdersScreenProps>(),
  connect(
    (state: RootState) => ({
      recentOrders: state.menu.recentOrders,
      loading: state.menu.loading,
    }),
    (dispatch: AppDispatch) => ({
      getRecentOrders: (params) => dispatch(getRecentOrders(params)),
    }),
  ),
)(RecentOrdersScreen);
