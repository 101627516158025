import React from 'react';
import Styles from '../Styles';
import {StyleSheet, StyleProp, TextStyle, ViewStyle} from 'react-native';
import {Searchbar} from 'react-native-paper';
import Settings from 'src/Settings';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';

type SearchbarProps = React.ComponentProps<typeof Searchbar> & {
  placeholder: string;
  value: string;
  containerStyle?: StyleProp<ViewStyle>;
  searchTextStyle?: StyleProp<TextStyle>;
  onChangeText?: (text: string) => void;
  accessibilityLabel?: string;
  searchIcon?: React.ReactNode;
  elevation?: number;
};

const CustomSearchbar = (props: SearchbarProps) => {
  const getFont = (): StyleProp<TextStyle> => {
    if (Settings.buildType === BuildTypeConstants.default) {
      return {
        fontFamily: Styles.FontFamily.aeonikRegular,
        fontSize: Styles.Fonts.sectionHeader,
        fontWeight: '400',
        color: Styles.black,
        marginTop: -4,
        marginLeft: -3,
      };
    } else {
      return null;
    }
  };

  const getStyle = (): StyleProp<ViewStyle> => {
    const searchFieldHeight = props.containerStyle?.['height'] ?? 52;
    if (Settings.buildType === BuildTypeConstants.default) {
      return {
        height: searchFieldHeight,
        borderRadius: searchFieldHeight / 2,
        backgroundColor: Styles.Colors.PayNew.white01,
        borderBottomWidth: 1,
      };
    } else {
      return null;
    }
  };

  return (
    <Searchbar
      placeholder={props.placeholder}
      placeholderTextColor={Styles.Colors.PayNew.darkHuesBase04}
      onChangeText={props.onChangeText}
      value={props.value}
      style={[styles.defaultContainerStyle, props.containerStyle, getStyle()]}
      inputStyle={[props.searchTextStyle, getFont()]}
      iconColor={Styles.Colors.PayNew.darkHuesBase04}
      accessibilityLabel={props.accessibilityLabel ?? props.placeholder}
      icon={props.searchIcon ? () => props.searchIcon : null}
      elevation={props.elevation}
    />
  );
};

const styles = StyleSheet.create({
  defaultContainerStyle: {
    borderWidth: 1,
    elevation: 3,
    shadowOpacity: 0.25,
    shadowColor: Styles.Colors.PayNew.darkHuesBase04,
    shadowRadius: 3,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    shadowOffset: {width: 1, height: 1},
  },
});
export default CustomSearchbar;
