import CartRedesignDescriptor from './cartscreen-redesign-descriptor';
import CartRevolveDescriptor from './cartscreen-revolve-descriptor';
import CartCanteenDescriptor from './cartscreen-canteen-descriptor';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, TextStyle} from 'react-native';

const typeDescriber = {
  [BuildTypes.revolve]: CartRevolveDescriptor,
  [BuildTypes.canteen]: CartCanteenDescriptor,
  [BuildTypes.default]: CartRedesignDescriptor,
};

export interface ProductCategorySearchDescriberData {
  styles?: {[index: string]: {[index: string]: number | string}};
  category?: string;
}
export interface ScanDiscountCodeStylesDescriptor {
  descriptionText: StyleProp<TextStyle>;
}
export interface ManualEntryButtonProps {
  handleManualEntry?: () => void;
}
export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
