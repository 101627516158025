import {StatusBarStyle, StyleProp, ViewStyle} from 'react-native';
import Styles from 'src/components/Styles';

export function shadowStyleDescriptor(): {[key: string]: StyleProp<ViewStyle>} {
  return {
    shadow: {
      backgroundColor: '#FFFFFF',
      borderBottomWidth: 0,
      borderRadius: 8,
      shadowColor: '#9A9A9F',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      elevation: 5,
      shadowRadius: 1,
      shadowOpacity: 0.4,
    },
    lightShadow: {
      backgroundColor: '#FFFFFF',
      borderBottomWidth: 0,
      borderRadius: 8,
      shadowColor: '#9A9A9F',
      shadowOffset: {
        width: 0,
        height: 4,
      },
      elevation: 5,
      shadowRadius: 1,
      shadowOpacity: 0.4,
    },
  };
}
export function statusBarStyleDescriptor(): StatusBarStyle {
  return 'light-content';
}

export function statusBarBackgroundColorDescriptor(): string {
  return Styles.primaryColor;
}

export default {
  shadowStyleDescriptor,
  statusBarStyleDescriptor,
  statusBarBackgroundColorDescriptor,
};
