import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import RevolveDescriptor from './campus-module-revolve-descriptor';
import RedesignDescriptor from './campus-module-redesign-descriptor';
import CanteenDescriptor from './campus-module-canteen-descriptor';

const typeDescriber = {
  [BuildTypes.default]: RedesignDescriptor,
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
