import RevolveDescriptor from './campus-components-revolve-descriptor';
import RedesignDescriptor from './campus-components-redesign-descriptor';
import ConnectPaydescriptor from './campus-components-connectpay-descriptor';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {StyleProp, ViewStyle, TextStyle} from 'react-native';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: ConnectPaydescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export interface SendSnackDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onSendASnackClick: () => void;
}
export interface ReferralDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onInviteClick: () => void;
  title: string | string[];
  stat: string | (string | number)[];
  amount?: number;
  isSnackEnabled?: boolean;
}
export interface AnnouncementStylesDescriptor {
  container: StyleProp<ViewStyle>;
  titleText: StyleProp<TextStyle>;
  descriptionText: StyleProp<TextStyle>;
}
export interface CampusLocationListStylesDescriptor {
  container: StyleProp<ViewStyle>;
  campusLocationListView: StyleProp<ViewStyle>;
  headerMargin: StyleProp<ViewStyle>;
}

export interface RewardskDescriberData {
  styles: {
    [index: string]: {
      [index: string]: number | string | {width: number; height: number};
    };
  };
  onRewardsClick: () => void;
  stat: string | (string | number)[];
}
export interface EngagementCarouselStylesDescriptor {
  activeDotStyle?: StyleProp<ViewStyle>;
  inActiveDotStyle?: StyleProp<ViewStyle>;
  imageContentContainer?: StyleProp<ViewStyle>;
  parralaxContainer?: StyleProp<ViewStyle>;
}

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}
