import * as React from 'react';
import Styles from '../Styles';
import {Platform, ScrollView, StyleSheet, View} from 'react-native';
import AVText from '../elements/AVText';
import Localized from 'src/constants/AppStrings';
import BackSubheader from '../elements/BackSubheader';
import type {ReferralCampaign, ReferralStat} from 'src/types/Referral';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import ScreenContext from '../ScreenContext';
import Settings from 'src/Settings';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import moment from 'moment';
import {isValidEmail, isValidPhoneNumber} from 'src/services/ValidatorService';
import Events from 'src/logging/Events';
import ReferralActions from 'src/actions/ReferralActions';
import {PlatformApiErrors} from 'src/api/PlatformApi';
import HttpClient from 'src/api/HttpClient';
import {InputTypes} from 'src/constants/KeyboardTypes';
import {alertError} from '../helpers/AlertHelper';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import ErrorIcon from 'src/components/img/svg/ErrorIcon';
import {getDescriber} from 'src/components/screens/descriptor/referral/Descriptor';
import {SuccessDialog} from 'src/components/elements/SuccessDialog';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import NetInfo from '@react-native-community/netinfo';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';

type ReferralScreenProps = WithGlobalizeProps & {
  referralCampaign: ReferralCampaign;
  referralStat: ReferralStat;
  onSuccess: () => void;
};

type ReferralScreenState = {
  inputType: keyof typeof InputTypes;
  phoneReferrer: string;
  error?: string;
  showModal?: boolean;
  isEmailEnabled: boolean;
  isPhoneEnabled: boolean;
  emailString: string;
  phoneString: string;
  isConnected?: boolean;
};

class ReferralScreen extends React.Component<
  ReferralScreenProps,
  ReferralScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  // declare referralCampaign: ReferralCampaign;
  declare screenHeader: string;
  declare referralTitle: string;
  declare referralDesc: string;
  declare newCharsEntered: boolean;
  declare phoneNumberPrevState: string;

  constructor(props) {
    super(props);
    const amount = this.getCurrency(this.props.referralCampaign.amount);

    this.screenHeader = Localized.Labels.formatString(
      Localized.Labels.referral_invite_friends,
      amount,
      amount,
    ).toString();

    this.referralTitle = Localized.Labels.formatString(
      Localized.Labels.referral_invite_earn_friends_title,
      amount,
    ).toString();

    this.referralDesc = getDescriber().getReferralTitleMsg(amount);

    this.state = {
      inputType: InputTypes.email,
      phoneReferrer: '',
      error: '',
      isEmailEnabled: true,
      isPhoneEnabled: true,
      showModal: false,
      emailString: '',
      phoneString: '',
      isConnected: true,
    };
    this.sendInvite = this.sendInvite.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.validateWithDelay = this.validateWithDelay.bind(this);
    this.onTIFocusEmail = this.onTIFocusEmail.bind(this);
    this.onTIFocusPhone = this.onTIFocusPhone.bind(this);
    this.checkForBtnDisabled = this.checkForBtnDisabled.bind(this);
    this.onTIChangeEmail = this.onTIChangeEmail.bind(this);
    this.onTIChangePhone = this.onTIChangePhone.bind(this);
    this.getTIActiveColor = this.getTIActiveColor.bind(this);
    this.getTIBgColor = this.getTIBgColor.bind(this);
    this.trackPageView = this.trackPageView.bind(this);
    this.getRightErrorSymbol = this.getRightErrorSymbol.bind(this);
    this.trackPageView();
  }

  async validateInput() {
    setTimeout(this.validateWithDelay, 500);
  }

  validateWithDelay() {
    const {inputType, phoneReferrer, emailString} = this.state;
    const number = this.removeDashes(phoneReferrer);

    if (
      emailString.length > 0 &&
      inputType === InputTypes.email &&
      !isValidEmail(emailString)
    ) {
      this.setState({
        error: Localized.Errors.please_enter_a_valid_email_address,
      });
      return;
    }

    if (
      number &&
      number.length > 0 &&
      inputType === InputTypes.text &&
      !isValidPhoneNumber(number)
    ) {
      this.setState({
        error: Localized.Errors.please_enter_a_valid_phone_number,
      });
      return;
    }
  }

  trackPageView() {
    const locationName = AccountStore.getLocationName();
    FirebaseAnalytic.trackPageView('ReferralScreen', {
      ...this.props,
      ...this.state,
      locationName,
    });
  }

  removeDashes(phoneNumber: string) {
    if (phoneNumber) {
      const resultArr = phoneNumber.split('-');
      const num = resultArr.join('').toString();
      return num;
    } else {
      return null;
    }
  }

  getNetworkState = async () => {
    const networkState = await NetInfo.fetch();
    const isConnected = networkState?.isConnected;
    return isConnected;
  };

  async sendInvite() {
    const {inputType, emailString, phoneReferrer} = this.state;
    const number = this.removeDashes(phoneReferrer);
    const finalReferralValue = number || emailString;
    const isConnected = await this.getNetworkState();
    if (!isConnected) {
      NavActions.navigate(AppRoutes.ServerErrorDialog, {
        errorTitle: Localized.Errors.connection_error,
        errorDesc: Localized.Errors.error_referral_invite_sending,
      });
      return;
    }
    FirebaseAnalytic.trackEvent('sendInvite', 'ReferralScreen', {
      ...this.props,
      ...this.state,
    });
    if (inputType === InputTypes.email && !isValidEmail(emailString)) {
      this.setState({
        error: Localized.Errors.please_enter_a_valid_email_address,
      });
      return;
    }

    if (inputType === InputTypes.text && !isValidPhoneNumber(number)) {
      this.setState({
        error: Localized.Errors.please_enter_a_valid_phone_number,
      });
      return;
    }

    this.context.actions.showSpinner();
    this.setState({
      error: '',
    });

    try {
      const response = await ReferralActions.sendReferralInvite(
        this.props.referralCampaign.campaignId,
        AccountStore.getAccountId(),
        inputType,
        finalReferralValue,
        Settings.buildType,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'SendReferralInvite',
        JSON.stringify({
          campaignId: this.props.referralCampaign.campaignId,
          accountId: AccountStore.getAccountId(),
          inputType,
          finalReferralValue,
        }),
        JSON.stringify(response),
      );
      const referralResponse = HttpClient.parseJsonSafe(
        response?.message ?? response.toString(),
      );
      if (referralResponse?.code === PlatformApiErrors.AccountAlreadyExists) {
        this.setState({
          error: Localized.Labels.account_already_setup_referral,
        });
        return;
      } else if (
        referralResponse?.code === PlatformApiErrors.CampaignAlreadyExpired
      ) {
        alertError(Localized.Labels.campaign_already_expired);
        return;
      } else if (referralResponse?.code === PlatformApiErrors.SendingSMS) {
        alertError(Localized.Labels.error_sending_the_sms);
        return;
      } else if (referralResponse == '') {
        this.setState({
          showModal: true,
        });
        return;
      }
    } catch (err) {
      const error = HttpClient.parseJsonSafe(err?.message ?? err.toString());
      alertError(Localized.Labels.error_server_try_later);
      CrashlyticsEvents.log('Exception', 'ReferralScreen:SendInvite', error);
      Events.Error.trackEvent('Exception', 'ReferralScreen:SendInvite', error);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'SendReferralInvite-Error',
        JSON.stringify({
          inputType,
          campaignId: this.props.referralCampaign.campaignId,
          finalReferralValue,
          accountId: AccountStore.getAccountId(),
        }),
        JSON.stringify(err),
      );
    } finally {
      this.context.actions.hideSpinner();
    }
  }

  checkTextPresentForFocussedComponent() {
    let enableBtn = false;
    const {phoneString, isEmailEnabled, emailString, isPhoneEnabled} =
      this.state;
    if (isEmailEnabled && emailString.length > 0) {
      enableBtn = true;
    } else if (isPhoneEnabled && phoneString.length > 0) {
      enableBtn = true;
    } else {
      enableBtn = false;
    }
    return enableBtn;
  }

  checkForBtnDisabled() {
    if (
      this.state &&
      this.state.error == '' &&
      this.checkTextPresentForFocussedComponent()
    ) {
      return false;
    }
    return true;
  }

  getCurrency(amount: number) {
    return Util.formatCurrency(this.props, amount, AccountStore.getCurrency(), {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });
  }

  label() {
    const {endDate, maxRedeem} = this.props.referralCampaign;
    const formattedEndDate = endDate
      ? moment.utc(endDate, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY')
      : endDate;
    const {label, values} =
      formattedEndDate && maxRedeem
        ? {
            label: Localized.Labels.offer_expires_on,
            values: [formattedEndDate, maxRedeem],
          }
        : formattedEndDate
        ? {
            label: Localized.Labels.offer_expires_endDate,
            values: [formattedEndDate],
          }
        : maxRedeem
        ? {
            label: Localized.Labels.offer_expires_redeem,
            values: [maxRedeem],
          }
        : {
            label: '',
            values: [],
          };

    return Localized.Labels.formatString(label, ...values);
  }

  onTIFocusEmail() {
    this.setState({
      isEmailEnabled: true,
      isPhoneEnabled: false,
      error: '',
      phoneReferrer: '',
      inputType: InputTypes.email,
    });
  }

  onTIChangeEmail(text: string) {
    this.setState({error: '', emailString: text});
  }

  onTIChangePhone(text: string) {
    this.phoneNumberPrevState = this.state.phoneReferrer;
    const referrer = text;
    const phoneNumber = this.hyphenatePhField(referrer);
    this.setState({phoneReferrer: phoneNumber, error: '', phoneString: text});
  }

  hyphenatePhField(phoneNumber: string) {
    if (this.suffixHyphen(phoneNumber) == true) {
      phoneNumber = phoneNumber + '-';
    } else if (this.prefixHyphen(phoneNumber) == true) {
      phoneNumber =
        phoneNumber.substring(0, phoneNumber.length - 1) +
        '-' +
        phoneNumber.charAt(phoneNumber.length - 1);
    } else {
      phoneNumber = phoneNumber;
    }
    return phoneNumber;
  }

  suffixHyphen(phoneNumber: string) {
    // if 666 entered add suffix and make it 666-
    if (this.phoneNumberPrevState.length == 2 && phoneNumber.length == 3) {
      return true;
    } else if (
      // if 666-888 entered add suffix and make it 666-888-
      this.phoneNumberPrevState.length == 6 &&
      phoneNumber.length == 7
    ) {
      return true;
    } else return false;
  }

  prefixHyphen(phoneNumber: string) {
    // if 6668 entered replace 8 with -8 and make it 666-8
    if (
      this.phoneNumberPrevState.length == 3 &&
      phoneNumber.length == 4 &&
      phoneNumber.indexOf('-') <= 0
    ) {
      return true;
    } else if (
      // if 666-8885 entered replace 5 with -5 and make it 666-888-5
      this.phoneNumberPrevState.length == 7 &&
      phoneNumber.length == 8 &&
      phoneNumber.substring(4, 8).indexOf('-') <= 0
    ) {
      return true;
    } else return false;
  }

  onTIFocusPhone() {
    this.setState({
      isEmailEnabled: false,
      isPhoneEnabled: true,
      error: '',
      emailString: '',
      inputType: InputTypes.text,
    });
  }

  getTIBgColor(selectedField: boolean) {
    return selectedField == true
      ? Styles.white
      : Styles.Colors.PayNew.neutralHuesBase11;
  }

  getTIActiveColor(ipType: InputTypes) {
    if (ipType == this.state.inputType) {
      return this.state.error != ''
        ? Styles.Colors.PayNew.error
        : Styles.Colors.PayNew.darkHuesBase04;
    } else return Styles.Colors.PayNew.darkHuesBase04;
  }

  getTIBorderColor(ipType: InputTypes) {
    if (ipType == this.state.inputType) {
      return this.state.error != '' ? Styles.Colors.PayNew.error : '#97949B';
    } else return '#97949B';
  }

  getRightErrorSymbol(ipType: InputTypes) {
    const {error} = this.state;
    if (ipType == this.state.inputType) {
      return error != '' ? (
        <ErrorIcon color={Styles.Colors.PayNew.error} />
      ) : null;
    }
  }

  getError(inputType: InputTypes) {
    if (inputType == this.state.inputType && this.state.error != '') {
      return (
        <AVText
          style={getDescriber().getReferralStyles()['errorLabel']}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
        >
          {this.state.error}
        </AVText>
      );
    } else return null;
  }

  render() {
    return (
      <BackSubheader title={this.screenHeader}>
        <KeyboardAvoidingView
          style={Styles.Style.flex}
          behavior="height"
          insideTab
        >
          <ScrollView contentContainerStyle={styles.content}>
            <View style={{marginTop: 30}}>
              {getDescriber().getReferralHeaderIcon()}
            </View>
            <AVText
              style={getDescriber().getReferralStyles()['referralTitle']}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {this.referralTitle}
            </AVText>
            <AVText
              style={getDescriber().getReferralStyles()['referralDesc']}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {this.referralDesc}
            </AVText>
            {this.label().length > 0 && (
              <AVText
                style={getDescriber().getReferralStyles()['referralExpiry']}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {this.label()}
              </AVText>
            )}

            <View style={styles.totalReferralsContainer}>
              <View>
                <AVText
                  style={
                    getDescriber().getReferralStyles()['totalReferralsKey']
                  }
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                >
                  {Localized.Labels.referral_total_referrals_label}
                </AVText>
                <AVText
                  style={
                    getDescriber().getReferralStyles()['totalReferralsValue']
                  }
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                >
                  {this.props.referralStat.referrals}
                </AVText>
              </View>
              <View>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                  style={
                    getDescriber().getReferralStyles()['totalReferralsKey']
                  }
                >
                  {Localized.Labels.referral_total_signups_label}
                </AVText>
                <AVText
                  style={
                    getDescriber().getReferralStyles()['totalReferralsValue']
                  }
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                >
                  {this.props.referralStat.signUps}
                </AVText>
              </View>
            </View>

            <View style={[styles.shareViaEmailContainer]}>
              <AVText
                style={getDescriber().getReferralStyles()['shareViaEmailTitle']}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {Localized.Labels.referral_email_address}
              </AVText>
              <CustomTextInput
                label={'Email address'}
                value={this.state.emailString}
                onFocus={this.onTIFocusEmail}
                onBlur={this.validateInput}
                onChangeText={this.onTIChangeEmail}
                accessible={true}
                accessibilityLabel="Email"
                aria-label="Email"
                autoCapitalize="none"
                rightIcon={this.getRightErrorSymbol(InputTypes.email)}
                fontSize={Styles.Fonts.f1}
                keyboardType="email-address"
                activeColor={this.getTIActiveColor(InputTypes.email)}
                placeholderTextColor={this.getTIActiveColor(InputTypes.email)}
                outlineStyle={{
                  borderColor: this.getTIBorderColor(InputTypes.email),
                  borderRadius: 8,
                  borderWidth: 1,
                }}
                containerStyle={{
                  marginTop: Styles.Spacing.m2,
                  backgroundColor: this.getTIBgColor(this.state.isEmailEnabled),
                }}
                returnKeyType="done"
                maxLength={75}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                showShadow={true}
              />
              {this.getError(InputTypes.email)}
            </View>
            <View style={[styles.lineContainer]}>
              <View style={styles.solidLine} />
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                testID="orText"
                accessible={true}
                accessibilityLabel="or"
                accessibilityRole="text"
                style={getDescriber().getReferralStyles()['orText']}
              >
                {Localized.Labels.or}
              </AVText>
              <View style={styles.solidLine} />
            </View>
            <View style={[styles.shareViaPhoneContainer]}>
              <AVText
                style={getDescriber().getReferralStyles()['shareViaEmailTitle']}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {Localized.Labels.referral_phone_number}
              </AVText>
              <CustomTextInput
                label={'Phone number'}
                onFocus={this.onTIFocusPhone}
                onBlur={this.validateInput}
                value={this.state.phoneReferrer}
                fontSize={Styles.Fonts.f1}
                onChangeText={this.onTIChangePhone}
                accessible={true}
                accessibilityLabel="Phone"
                aria-label="Phone"
                autoCapitalize="none"
                keyboardType="number-pad"
                rightIcon={this.getRightErrorSymbol(InputTypes.text)}
                activeColor={this.getTIActiveColor(InputTypes.text)}
                outlineStyle={{
                  borderColor: this.getTIBorderColor(InputTypes.text),
                  borderRadius: 8,
                  borderWidth: 1,
                }}
                containerStyle={{
                  marginTop: Styles.Spacing.m2,
                  backgroundColor: this.getTIBgColor(this.state.isPhoneEnabled),
                }}
                returnKeyType="done"
                errorMsg={Localized.Errors.account_already_setup}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
                showShadow={true}
              />
              {this.getError(InputTypes.text)}
            </View>

            <View
              style={{
                marginTop: 70,
                padding: 10,
                alignSelf: 'flex-end',
                marginRight: -15,
              }}
            >
              {getDescriber().getSendInviteButton(
                this.checkForBtnDisabled,
                this.sendInvite,
              )}
            </View>
            <View>
              {this.state.showModal && (
                <SuccessDialog
                  title={Localized.Success.sent_successfully_referral}
                  desc="Desc"
                ></SuccessDialog>
              )}
            </View>
          </ScrollView>
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  lineContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2 + Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m3,
    ...(Platform.OS === 'web' && {
      width: '60%',
      alignSelf: 'flex-start',
      marginLeft: 20,
    }),
  },
  solidLine: {
    height: 1,
    flex: 1,
    backgroundColor: Styles.darkColor,
    marginHorizontal: Styles.Spacing.m1,
  },
  content: {
    paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F4F4F4',
  },
  totalReferralsContainer: {
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3,
    paddingTop: Styles.Spacing.m2,
    paddingBottom: Styles.Spacing.m1,
    paddingRight: Styles.Spacing.m3 + Styles.Spacing.m2,
    paddingLeft: Styles.Spacing.m3 + Styles.Spacing.m2,
    borderRadius: Styles.Spacing.m2,
    backgroundColor: Styles.white,
    borderWidth: 1,
    borderColor: Styles.Colors.PayNew.neutralHuesBase09,
    flex: 1,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  shareViaEmailContainer: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    width: '100%',
    justifyContent: 'flex-start',
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
    ...(Platform.OS === 'web' && {
      width: '60%',
      alignSelf: 'flex-start',
    }),
  },
  shareViaPhoneContainer: {
    flex: 1,
    marginHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
    width: '100%',
    justifyContent: 'flex-start',
    ...(Platform.OS === 'web' && {
      width: '60%',
      alignSelf: 'flex-start',
    }),
  },
  shareViaEmailTitle: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    textAlign: 'left',
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.tabBarTextColor,
  },
  amountLabel: {
    fontSize: Styles.Fonts.f5,
    fontWeight: 'bold',
    color: Styles.balanceColor,
  },
  cashSpendLabel: {
    fontSize: Styles.Fonts.f1,
  },
  expireLabel: {
    color: Styles.balanceColor,
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f0,
    textAlign: 'center',
  },
  rewardLabel: {
    color: Styles.balanceColor,
    fontSize: Styles.Fonts.f0,
  },
  buttonsView: {
    flexDirection: 'row',
    marginTop: Styles.Spacing.m3,
    alignSelf: 'flex-start',
  },
  textField: {
    marginTop: Styles.Spacing.m2,
    alignSelf: 'stretch',
    height: Styles.Heights.h3,
  },
  type: {
    color: Styles.white,
    borderRadius: Styles.Spacing.m2,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
    backgroundColor: Styles.primaryColor,
    fontWeight: 'bold',
  },
  disabled: {
    backgroundColor: Styles.lightGray,
  },
  errorLabel: {
    color: Styles.dangerColor,
    fontSize: Styles.Fonts.f1,
    alignSelf: 'flex-start',
  },
});
export default withForwardedNavigationParams<ReferralScreenProps>()(
  withGlobalize(ReferralScreen),
);
