import Analytics from 'src/nativeModules/Analytics';
import moment from 'moment';
import HttpError from 'src/models/HttpError';
import MoblicoService, {metricTypes} from 'src/services/MoblicoService';

const MoblicoScreens = ['Promotions', 'Machines', 'Inbox', 'Account'];
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import type {PaymentType} from 'src/stores/CartStore';
import AccountConstants from 'src/constants/AccountConstants';
import {CartItem} from 'src/types/TransactionDetail';
import {store} from '../redux/store';
import CrashlyticsService from 'src/nativeModules/Crashlytics';
import {OpenedEvent} from 'react-native-onesignal';
import {MoblicoPromotionType} from '../types/Promotions';

/**
 * App Center Analytics Events:
 * Allows for 200 unique events, each event can have at most 20 properties,
 * all strings with a max length of 125 characters.
 */
const maxLength = 125;
export const PromotionActions = {
  Clicked: 'CLICKED',
  Viewed: 'VIEWED',
  ViewedDeal: 'VIEWED_DEAL',
  ClaimedDeal: 'CLAIMED_DEAL',
};
export const FundStatus = {
  Success: 'SUCCESS',
};
export const VendorsExchangeActions = {
  InvalidState: 'InvalidState',
  CharacteristicFound: 'CharacteristicFound',
  CharacteristicPostWrite: 'CharacteristicPostWrite',
  CharacteristicPreWrite: 'CharacteristicPreWrite',
  PaymentConfiguration: 'PaymentConfiguration',
  KeypadConfiguration: 'KeypadConfiguration',
  PaymentRequest: 'PaymentRequest',
  PaymentStatus: 'PaymentStatus',
  NewSharedKey: 'NewSharedKey',
};

class BaseEvent {
  getLogData(params?: Record<string, string | boolean | number>) {
    return {
      email: AccountStore.getEmail() || '',
      locationId: AccountStore.getLocationId() || '',
      locationName: AccountStore.getLocationName() || '',
      environment: store.getState().environment.env,
      app: Settings.buildType,
      ...params,
    };
  }

  sanitizeStringParam(param: string | null | undefined): string {
    return param && param.substring ? param.substring(0, maxLength) : '';
  }

  sanitizeParam(param: any): string {
    if (typeof param === 'string') {
      return this.sanitizeStringParam(param);
    } else if (typeof param === 'object') {
      return this.sanitizeStringParam(JSON.stringify(param));
    } else if (param) {
      return param.toString();
    }

    return '';
  }

  sanitizeParams(params: any): any {
    const updatedParams: Record<string, any> = {};
    Object.keys(params).forEach((key: string) => {
      updatedParams[key] = this.sanitizeParam(params[key]);
    });
    return updatedParams as Record<string, unknown>;
  }
}

class AccountLoginEvent extends BaseEvent {
  trackEvent(
    device: string,
    country: string,
    currency: string,
    isSSO?: boolean,
    email?: string,
  ) {
    const logEvent = this.getLogData({
      device,
      country,
      currency,
      isSSO: !!isSSO,
      email: email || '',
    });
    Analytics.trackEvent('Account:Login', logEvent);
  }
}

class VendEvent extends BaseEvent {
  trackEvent(status: string, transactionId?: string, numItems?: number) {
    const logData = this.getLogData({
      status,
      numItems: numItems || '',
      transactionId: transactionId || '',
    });
    Analytics.trackEvent('Vend', logData);
  }
}

class AppSyncEvent extends BaseEvent {
  trackEvent(status: string, message: string, id?: string) {
    const logData = this.getLogData({
      status: status || '',
      message: message || '',
      id: id || '',
    });
    Analytics.trackEvent('AppSync:Log', logData);
  }
}

class BluetoothMessageEvent extends BaseEvent {
  trackEvent(message: string) {
    const logEvent = this.getLogData({
      message: this.sanitizeStringParam(message),
    });
    Analytics.trackEvent('BluetoothMessage', logEvent);
  }
}

class BluetoothScanEvent extends BaseEvent {
  trackEvent() {
    Analytics.trackEvent('BluetoothScan', this.getLogData());
  }
}

class ErrorEvent extends BaseEvent {
  trackEvent(
    type: string,
    name: string,
    data: string,
    guid?: string,
    additionalData?: Record<string, any>,
  ) {


    if (additionalData) {
      additionalData = this.sanitizeParams(additionalData) as Record<
        string,
        any
      >;
    } else {
      additionalData = {};
    }

    CrashlyticsService.trackEvent(name, {
      type,
      name,
      data: data.substring(0, 40),
      guid: guid || '',
      ...additionalData,
    });

    const logEvent = this.getLogData({
      ...additionalData,
      guid: guid || '',
      type: type || '',
      name: name || '',
      data: this.sanitizeStringParam(data).substring(0, 40),
    });
    Analytics.trackEvent('Error', logEvent);
  }
}

class InfoEvent extends BaseEvent {
  trackEvent(name: string, additionalData: any, guid?: string) {
    console.info(`Info: Type: Info, ${name}`);

    if (additionalData) {
      additionalData = this.sanitizeParams(additionalData);
    } else {
      additionalData = {};
    }

    const logEvent = this.getLogData({
      ...additionalData,
      guid: guid || '',
      type: 'Info',
      name: name || '',
    });
    Analytics.trackEvent('Info', logEvent);
  }
}

class FetchErrorEvent extends BaseEvent {
  trackEvent(error: HttpError) {
    const message = `${error.code}:${error.message}`;
    let body = error.body as Record<string, any>;
    if (body) {
      body = this.sanitizeParams(body);
    } else {
      body = {};
    }
    const logEvent = this.getLogData({
      statusCode: this.sanitizeParam(error.statusCode) || '0',
      url: this.sanitizeStringParam(error.url),
      message: this.sanitizeStringParam(message),
      code: this.sanitizeStringParam(error.code),
      ...body,
    });
    if (this.filterApplies(logEvent as {message?: string})) {
      return;
    }
    Analytics.trackEvent('Error:Fetch', logEvent);
  }

  filterApplies({message}: {message?: string}) {
    const filter = /:?Resource Auto-fund Config with [a-f0-9]{32} not found/g;
    return filter.exec(message || '');
  }
}

class CartSessionEvent extends BaseEvent {
  trackEvent(
    startDateTime: moment.Moment,
    locationId: string,
    locattionName: string,
    itemCount: number,
    discount: number,
    total: number,
    status: string,
    payments: PaymentType[],
    transId: string | null,
    promotions: MoblicoPromotionType[],
    totalCalories: string,
    healthyLivingDiscount: string,
    displayColor: string,
    displayLetter: string,
    promoName: string,
    transactionChargeName?: string | undefined,
    transactionChargeAmount?: number | undefined,
  ) {
    const duration = moment().diff(startDateTime, 'seconds');
    let paymentType = '';

    if (payments && payments.length > 0) {
      paymentType = payments.map((value) => value.type).join();
    }

    let promotionIds = '';
    let promotionNames = '';

    if (promotions && promotions.length > 0) {
      promotionIds = promotions.map((promotion) => promotion.id).join();
      promotionNames = promotions.map((promotion) => promotion.name).join();
    }

    const logData = this.getLogData({
      duration: this.sanitizeParam(duration),
      itemCount: this.sanitizeParam(itemCount),
      total: this.sanitizeParam(total),
      paymentType: this.sanitizeStringParam(paymentType),
      transId: transId || '',
      discount,
      promotionIds: this.sanitizeStringParam(promotionIds),
      promotionNames: this.sanitizeStringParam(promotionNames),
      totalCalories: this.sanitizeStringParam(totalCalories),
      healthyLivingDiscount: this.sanitizeStringParam(healthyLivingDiscount),
      displayColor: this.sanitizeStringParam(displayColor),
      displayLetter: this.sanitizeStringParam(displayLetter),
      promoName: this.sanitizeStringParam(promoName),
      transactionChargeName: this.sanitizeStringParam(transactionChargeName),
      transactionChargeAmount: this.sanitizeStringParam(
        String(transactionChargeAmount),
      ),
    });
    Analytics.trackEvent('Cart:Session:' + status, logData);
  }

  trackProductDetailAdded(item: CartItem) {
    const logData = this.getLogData({
      barcode: item.BarCode,
      numModifiers: item.Modifiers?.length ?? 0,
      modifiers: item.Modifiers?.map((m) => m.Modifier).join(',') ?? '',
    });
    Analytics.trackEvent('Cart:ProductDetailAdded', logData);
  }
}

class CartSearchEvent extends BaseEvent {
  trackEvent(
    startDateTime: moment.Moment,
    itemAdded: string,
    isCancelled = false,
    categoryClicked = '',
  ) {
    const duration = moment().diff(startDateTime, 'seconds');
    const logData = this.getLogData({
      duration: this.sanitizeParam(duration),
      itemAdded: this.sanitizeStringParam(itemAdded),
      isCancelled: this.sanitizeParam(isCancelled),
      categoryClicked,
    });
    Analytics.trackEvent('Cart:Search', logData);
  }
}

class AccountCreationEvent extends BaseEvent {
  trackEvent(
    startDateTime: moment.Moment,
    cancelStep: string,
    status = 'CANCELED',
  ) {
    const duration = moment().diff(startDateTime, 'seconds');
    const logData = this.getLogData({
      duration: this.sanitizeParam(duration),
      cancelStep,
      status,
    });
    Analytics.trackEvent('Account:Creation', logData);
  }
}

class NotificationReceivedEvent extends BaseEvent {
  trackEvent(notification: {
    payload?: {
      additionalData?: {
        fetchBalance?: boolean;
        transactionId?: boolean;
        transactionType?: boolean;
      };
      title?: string;
      body?: string;
    };
  }) {
    if (notification && notification.payload) {
      let fetchBalance = false;
      let transactionId = false;
      let transactionType = false;

      if (notification.payload.additionalData) {
        const data = notification.payload.additionalData;

        if (data.fetchBalance) {
          fetchBalance = data.fetchBalance;
        }

        if (data.transactionId) {
          transactionId = data.transactionId;
        }

        if (data.transactionType) {
          transactionType = data.transactionType;
        }
      }

      let title = '';
      let body = '';

      if (notification.payload.title) {
        title = this.sanitizeStringParam(notification.payload.title);
      }

      if (notification.payload.body) {
        body = this.sanitizeStringParam(notification.payload.body);
      }

      const logData = this.getLogData({
        title,
        body,
        fetchBalance: this.sanitizeParam(fetchBalance),
        transactionId: this.sanitizeParam(transactionId),
        transactionType: this.sanitizeParam(transactionType),
      });
      Analytics.trackEvent('Notification:Received', logData);
    }
  }
}

class NotificationEvent extends BaseEvent {
  trackOneSignalId(oneSignalId: string) {
    const logData = this.getLogData({
      oneSignalId: this.sanitizeParam(oneSignalId),
    });
    Analytics.trackEvent('Notification:Event.trackOneSignalId', logData);
  }

  trackUserResponseToNotificationPrompt(result: boolean) {
    const logData = this.getLogData({
      allowNotificationsiOS: this.sanitizeParam(result),
    });
    Analytics.trackEvent(
      'Notification:Event.trackUserResponseToNotificationPrompt',
      logData,
    );
  }

  trackNotification(result: OpenedEvent) {
    const logData = this.getLogData({
      notification: this.sanitizeParam(result.toString()),
    });
    Analytics.trackEvent('Notification:Event.trackNotification', logData);
  }
}

class AddCardEvent extends BaseEvent {
  trackEvent(status: string) {
    const logData = this.getLogData({
      status,
    });
    Analytics.trackEvent('CreditCard:Add', logData);
  }
}

class BluetoothDeviceFoundEvent extends BaseEvent {
  trackEvent(type: string, locationId: string, locationName: string) {
    const logData = this.getLogData({
      foundLocationId: locationId,
      foundLocationName: locationName || '',
      type,
    });
    Analytics.trackEvent('Bluetooth:Device:Found', logData);
  }
}

class BluetoothDeviceScannedEvent extends BaseEvent {
  trackEvent(type: string, id: string, distance?: number) {
    const logData = this.getLogData({
      type,
      id,
      distance: distance || '',
    });
    Analytics.trackEvent('Bluetooth:Device:Scanned', logData);
  }
}

class PayrollDeductEvent extends BaseEvent {
  trackEvent(status: string) {
    const logData = this.getLogData({
      status,
    });
    Analytics.trackEvent('PayrollDeduct', logData);
  }
}

class AddFundsEvent extends BaseEvent {
  trackEvent(status: string, amount = 0, payment = '', processor = '') {
    const logData = this.getLogData({
      status,
      amount: this.sanitizeParam(amount),
      payment,
      processor,
    });

    if (
      AccountStore.getConsumerEngagementId() &&
      status === FundStatus.Success &&
      payment === AccountConstants.APPLE_PAY_TOKEN
    ) {
      MoblicoService.sendApplePayMetric(AccountStore.getEmail(), amount);
    }

    Analytics.trackEvent('Funds:Add', logData);
  }
}

class AutoFundEvent extends BaseEvent {
  trackEvent(status: string, amount = 0, fallBelowAmount = 0, payment = '') {
    const logData = this.getLogData({
      status,
      amount: this.sanitizeParam(amount),
      fallBelowAmount: this.sanitizeParam(fallBelowAmount),
      payment,
    });
    Analytics.trackEvent('Funds:AutoFund', logData);
  }
}

class PromotionEvent extends BaseEvent {
  trackEvent(
    promotionId: string | number,
    promotionName: string,
    action: string,
    moblicoPromotion = false,
  ) {
    const logData = this.getLogData({
      promotionId,
      promotionName,
      action,
    });
    Analytics.trackEvent('Promotion', logData);

    if (AccountStore.getConsumerEngagementId() && moblicoPromotion) {
      let metricType = metricTypes.AdClick;

      if (action === PromotionActions.ViewedDeal) {
        metricType = metricTypes.ViewDeal;
      } else if (action === PromotionActions.Viewed) {
        metricType = metricTypes.AdView;
      }

      MoblicoService.sendMetric(
        AccountStore.getEmail(),
        metricType,
        promotionId,
      );
    }
  }
}

class ContactUsEvent extends BaseEvent {
  trackEvent() {
    const logData = this.getLogData();
    Analytics.trackEvent('ContactUs', logData);
  }
}

class LogoutEvent extends BaseEvent {
  trackEvent() {
    const logData = this.getLogData();
    Analytics.trackEvent('Logout', logData);
  }
}

class RefundRequestEvent extends BaseEvent {
  trackEvent(transId: string, amount: number) {
    const logData = this.getLogData({
      transId,
      amount: this.sanitizeParam(amount),
    });
    Analytics.trackEvent('Refund:Request', logData);
  }
}

class RewardsRedeemEvent extends BaseEvent {
  trackEvent(points: number, value: number) {
    const logData = this.getLogData({
      points: this.sanitizeParam(points),
      value: this.sanitizeParam(value),
    });
    Analytics.trackEvent('Rewards:Redeem', logData);
  }
}

class PassEvent extends BaseEvent {
  trackEvent() {
    const logData = this.getLogData();
    Analytics.trackEvent('Pass:Added', logData);
  }
}

class ScreenViewEvent extends BaseEvent {
  trackEvent(screen: string) {
    Analytics.trackPageView(
      this.sanitizeStringParam(screen),
      this.getLogData({}),
    );

    if (
      AccountStore.getConsumerEngagementId() &&
      AccountStore.getMoblicoUrl() &&
      MoblicoScreens.includes(screen)
    ) {
      MoblicoService.sendMetric(
        AccountStore.getEmail(),
        metricTypes.EnterPage,
        screen,
      );
    }
  }
}

class FirstLaunchEvent extends BaseEvent {
  trackEvent() {
    const logData = this.getLogData();
    Analytics.trackEvent('FirstLaunch', logData);
  }
}

class PermissionDeniedEvent extends BaseEvent {
  trackEvent(permission: string, response: string) {
    const logData = this.getLogData({
      permission,
      response,
    });
    Analytics.trackEvent('Permission:Denied', logData);
  }
}

class BranchEvent extends BaseEvent {
  trackEvent(params: Record<string, unknown>, route: string, error?: string) {
    const updatedParams = this.sanitizeParams(params);
    const logData = this.getLogData({
      ...updatedParams,
      currentRoute: route,
      error: this.sanitizeStringParam(error),
    });
    Analytics.trackEvent('Branch:Subscribe', logData);
  }
}

class BarcodeNotRecognized extends BaseEvent {
  trackEvent(barcode: string, locationType: string, responseCode: string) {
    const logData = this.getLogData({
      barcode: this.sanitizeStringParam(barcode),
      locationType: this.sanitizeStringParam(locationType),
      responseCode: this.sanitizeStringParam(responseCode),
    });
    Analytics.trackEvent('Barcode:NotRecognized', logData);
  }
}

class QRCodeNotRecognized extends BaseEvent {
  trackEvent(qrCode: string, responseCode: string) {
    const logData = this.getLogData({
      qrCode: this.sanitizeStringParam(qrCode),
      responseCode: this.sanitizeStringParam(responseCode),
    });
    Analytics.trackEvent('QRCode:NotRecognized', logData);
  }
}

class CountrySelected extends BaseEvent {
  trackEvent(email: string, country: string) {
    const logData = this.getLogData({
      country: this.sanitizeStringParam(country),
      email: this.sanitizeStringParam(email),
    });
    Analytics.trackEvent('Country:Selected', logData);
  }
}

class BunnEvent extends BaseEvent {
  trackEvent(action: string, data: Record<string, unknown>) {
    const sanitizedData = this.sanitizeParams(data);
    const logData = this.getLogData({...sanitizedData});
    Analytics.trackEvent(`Bunn:${action}`, logData);
  }
}

class VendorsExchangeEvent extends BaseEvent {
  trackEvent(action: string, data: Record<string, any>) {
    const sanitizedData = this.sanitizeParams(data);
    const logData = this.getLogData({
      ...sanitizedData,
      action: this.sanitizeStringParam(action),
    });
    Analytics.trackEvent('VendorsExchange', logData);
  }
}

class AppleWalletEvent extends BaseEvent {
  trackEvent(action: string, data: Record<string, any>) {
    const sanitizedData = this.sanitizeParams(data);
    const logData = this.getLogData({
      ...sanitizedData,
      action: this.sanitizeStringParam(action),
    });
    Analytics.trackEvent('AppleWallet', logData);
  }
}

class ScanEvent extends BaseEvent {
  trackEvent(action: string, data: Record<string, any>) {
    const sanitizedData = this.sanitizeParams(data);
    const logData = this.getLogData({
      ...sanitizedData,
      action: this.sanitizeStringParam(action),
    });
    Analytics.trackEvent('ScanEvent', logData);
  }
}

class MachinesEvent extends BaseEvent {
  trackEvent(action: string, data: Record<string, any>) {
    const sanitizedData = this.sanitizeParams(data);
    const logData = this.getLogData({
      ...sanitizedData,
      action: this.sanitizeStringParam(action),
    });
    Analytics.trackEvent('MachinesEvent', logData);
  }
}

export default class Events {
  static AccountCreation = new AccountCreationEvent();
  static AccountLogin = new AccountLoginEvent();
  static AddFunds = new AddFundsEvent();
  static Promotion = new PromotionEvent();
  static CartSearch = new CartSearchEvent();
  static Vend = new VendEvent();
  static CartSession = new CartSessionEvent();
  static AddCard = new AddCardEvent();
  static PayrollDeduct = new PayrollDeductEvent();
  static ContactUs = new ContactUsEvent();
  static Logout = new LogoutEvent();
  static BluetoothDeviceFound = new BluetoothDeviceFoundEvent();
  static AutoFund = new AutoFundEvent();
  static RefundRequest = new RefundRequestEvent();
  static RewardsRedeem = new RewardsRedeemEvent();
  static Pass = new PassEvent();
  static ScreenView = new ScreenViewEvent();
  static FirstLaunch = new FirstLaunchEvent();
  static PermissionDenied = new PermissionDeniedEvent();
  static Branch = new BranchEvent();
  static FetchError = new FetchErrorEvent();
  static AppSync = new AppSyncEvent();
  static Error = new ErrorEvent();
  static Info = new InfoEvent();
  static BluetoothMessage = new BluetoothMessageEvent();
  static NotificationReceived = new NotificationReceivedEvent();
  static NotificationEvent = new NotificationEvent();
  static BarcodeNotRecognized = new BarcodeNotRecognized();
  static CountrySelected = new CountrySelected();
  static QRCodeNotRecognized = new QRCodeNotRecognized();
  static BluetoothDeviceScanned = new BluetoothDeviceScannedEvent();
  static Bunn = new BunnEvent();
  static VendorsExchange = new VendorsExchangeEvent();
  static AppleWallet = new AppleWalletEvent();
  static BluetoothScan = new BluetoothScanEvent();
  static Machines = new MachinesEvent();
  static Scan = new ScanEvent();
}
