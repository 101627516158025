import {
  PickupLocationStylesDescriptor,
  PickupTimeStylesDescriptor,
  ProductDetailsStylesDescriptor,
  MenuListStylesDescriptor,
  FlashListPropsType,
  Identifiable,
  ProductComponentProps,
} from 'src/components/screens/orderAhead/descriptor/DescriptorType';
import Styles from 'src/components/Styles';
import {
  StyleSheet,
  View,
  Image,
  PixelRatio,
  Dimensions,
  Text,
  FlatList,
  Platform,
} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {ensureHttps} from 'src/services/StringUtils';
import AVText from 'src/components/elements/AVText';
import MenuItemList from 'src/components/elements/orderAhead/MenuListItem';
import {MenuProductType} from 'src/types/Menu';
import {DNA} from 'src/types/dnaTypes';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import QuickApplyIcon from 'src/components/img/svg/QuickApplyIcon';
import {TransactionDetail} from 'src/types/TransactionDetail';
import TransactionStore from 'src/stores/TransactionStore';
import {ListScanItemsProps} from 'src/components/screens/scanAndPay/ListScanItems';
import BulletPoint from 'src/components/img/svg/BulletPoint';

export function getPickupLocationStyles(): PickupLocationStylesDescriptor {
  return StyleSheet.create({
    buttonContainerStyle: {
      position: 'absolute',
      alignSelf: 'flex-end',
      bottom: Styles.Spacing.m3 + Styles.Spacing.m2,
      right: Styles.Spacing.m3,
    },
    buttonText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    headerText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.Colors.PayNew.black01,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    errorText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.Colors.PayNew.error,
      paddingHorizontal: Styles.Spacing.m1,
      alignItems: 'center',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    errorContainer: {
      flexDirection: 'row',
      paddingTop: Styles.Spacing.m2,
      alignItems: 'center',
    },
  });
}

export function getInactiveButtonColor() {
  return Styles.Colors.PayNew.darkHuesBase04;
}
export function fontFamilyStyle() {
  return Styles.FontFamily.aeonikRegular;
}

export function textColor() {
  return Styles.Colors.PayNew.black01;
}
export function getActiveButtonColor() {
  return Styles.Colors.PayNew.primary01;
}

export function getPickupTimeStyles(): PickupTimeStylesDescriptor {
  return StyleSheet.create({
    dateView: {
      marginTop: Styles.Spacing.m4 - Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m3,
    },
    timeView: {
      marginTop: Styles.Spacing.m4 - Styles.Spacing.m2,
    },
    pickupTimeContainer: {
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
      marginTop: Styles.Heights.h2,
    },
    pickupLocationHeaderView: {
      flexDirection: 'row',
      width: '100%',
      minHeight: Styles.Heights.touchTargetHeight2,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: Styles.Heights.h1,
      marginLeft: Styles.Spacing.m3,
    },
    customTileButtonTime: {
      minWidth: 93,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: Styles.Spacing.m1,
    },
    tilesStyle: {
      marginRight: Styles.Spacing.m3 - 4,
      marginTop: Styles.Spacing.m3,
    },
    errorContainer: {
      flexDirection: 'row',
      marginTop: Styles.Spacing.m3 + 4,
      marginBottom: Styles.Spacing.m2,
      alignItems: 'center',
    },
    buttonTextStyle: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f8,
      paddingHorizontal: Styles.Spacing.m1,
      marginBottom: 1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.Colors.PayNew.primary01,
    },
    editButton: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: Styles.Colors.PayNew.primary01,
      borderRadius: 32,
      borderWidth: 1,
      paddingVertical: Styles.Spacing.m2,
      paddingHorizontal: Styles.Spacing.m2 + 2,
      marginRight: Styles.Spacing.m3,
    },
  });
}

export function locationErrorMessage() {
  return Localized.Errors.please_select_location;
}
export function dayErrorMessage() {
  return Localized.Errors.please_select_day;
}
export function timeErrorMessage() {
  return Localized.Errors.please_select_time;
}

export function productDetailsTitle() {
  return Localized.Labels.menu_item;
}

export function productDetailsImageView(imageUri: string): JSX.Element {
  return (
    <>
      <View>
        {imageUri ? (
          <Image
            accessible={true}
            accessibilityLabel="Product image"
            accessibilityRole="image"
            aria-label="Product image"
            role="img"
            style={{aspectRatio: 16 / 9}}
            source={{uri: ensureHttps(imageUri)}}
            resizeMode={'cover'}
          />
        ) : (
          <Image
            accessible={true}
            accessibilityLabel="Default Product image"
            accessibilityRole="image"
            aria-label="Default Product image"
            role="img"
            source={require('src/components/img/DiningMenuItem.png')}
            resizeMode={'cover'}
            style={{width: '100%'}}
          />
        )}
      </View>
    </>
  );
}

export function productDetailsStyles(): ProductDetailsStylesDescriptor {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    content: {
      marginVertical: Styles.Spacing.m1,
    },
    title: {
      fontWeight: '700',
      fontSize: Styles.Fonts.headerFont,
      color: Styles.Colors.PayNew.black01,
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginBottom: Styles.Spacing.m2,
    },
    infoContainer: {
      marginHorizontal: Styles.Spacing.m3,
      paddingVertical: Styles.Spacing.m1,
      flexDirection: 'column',
    },
    productPrice: {
      fontWeight: '700',
      marginTop: 1,
      fontSize: Styles.Fonts.headerFont,
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.Colors.PayNew.black01,
    },
    calories: {
      fontWeight: '700',
      color: Styles.Colors.PayNew.black01,
      fontSize: Styles.Fonts.headerFont,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    calorieText: {
      fontSize: Styles.Fonts.headerFont,
      fontWeight: '700',
      color: Styles.Colors.PayNew.black01,
      marginLeft: 5,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    healthGrade: {
      marginLeft: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: Styles.Spacing.m1 + 2,
    },
    verticalLine: {
      marginHorizontal: Styles.Spacing.m2,
      backgroundColor: Styles.Colors.PayNew.darkHuesBase04,
      borderColor: Styles.Colors.PayNew.darkHuesBase04,
      borderWidth: 0.8,
    },
    nutritionButtonView: {
      flexDirection: 'row',
      minHeight: 44,
    },
    outlined: {
      borderRadius: 32,
      borderColor: Styles.Colors.PayNew.primary01,
      paddingVertical: Styles.Spacing.m2,
      paddingHorizontal: Styles.Spacing.m3,
      borderWidth: 2,
      marginTop: Styles.Spacing.m2,
      overflow: 'hidden',
      backgroundColor: Styles.white,
    },
    outlinedButtonText: {
      color: Styles.Colors.PayNew.primary01,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    descriptionContainer: {
      marginHorizontal: Styles.Spacing.m3,
    },
    description: {
      fontSize: Styles.Fonts.f8,
      color: Styles.Colors.PayNew.darkHuesBase04,
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    customizationSection: {
      flex: 1,
      flexDirection: 'column',
    },
    quantityContainer: {
      marginTop: Styles.Spacing.m0,
    },
    quantitySelectionView: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dashStyle: {
      borderRadius: 100,
      height: PixelRatio.roundToNearestPixel(2),
      overflow: 'hidden',
      width: '100%',
    },
    quantityLabel: {
      fontSize: Styles.Fonts.sectionHeader,
      color: Styles.Colors.PayNew.black01,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m3 + Styles.Spacing.m2,
      minHeight: 48,
      marginTop: 10,
    },
  });
}

export function roundedButtonColor() {
  return Styles.Colors.PayNew.primary01;
}
export function dashLineColor() {
  return Styles.Colors.PayNew.neutralHuesBase09;
}

export function menuListStyles(): MenuListStylesDescriptor {
  return StyleSheet.create({
    content: {
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    searchContainer: {
      marginLeft: Styles.Spacing.m3,
      marginRight: Styles.Spacing.m3,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m3,
    },
    categories: {
      marginTop: Styles.Spacing.m1,
      paddingBottom: Styles.Spacing.m2 + 2,
    },
  });
}

export function getFlashListProps<T extends Identifiable>({
  data,
  renderItem,
  refreshKey,
  isSubMenu,
}: FlashListPropsType<T>) {
  return {
    data,
    keyExtractor: (item: T) => item?.id.toString(),
    renderItem,
    ListEmptyComponent: (
      <View
        style={{
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          marginHorizontal: Styles.Spacing.m3,
          marginTop: Dimensions.get('screen').width / 3,
        }}
      >
        <AVText
          style={{
            fontSize: Styles.Fonts.f2,
            fontFamily: Styles.FontFamily.aeonikRegular,
            color: Styles.Colors.PayNew.black01,
          }}
        >
          {Localized.Labels.no_products_available}
        </AVText>
      </View>
    ),
    estimatedItemSize: 72,
    numColumns: getNumColumns(isSubMenu),
    contentContainerStyle: {
      paddingLeft: Styles.Spacing.m3,
      paddingTop: Styles.Spacing.m2,
    },
    extraData: refreshKey,
    ListFooterComponent: <View style={{height: Styles.Heights.h4}} />,
  };
}

const getNumColumns = (isSubMenu): number => {
  if (Platform.OS == 'web') {
    if (isSubMenu) {
      return 1;
    } else {
      return 3;
    }
  } else if (isSubMenu) {
    return 1;
  } else {
    return 2;
  }
};

export function getProductComponent(props: ProductComponentProps) {
  return (
    <MenuItemList
      dnaConfiguration={props?.dnaConfiguration}
      onPress={props?.onPress}
      name={props?.name}
      item={props?.item}
      price={props?.price}
      imageUrl={props?.imageUrl}
      isProductCat={props?.isProductCat}
      index={props?.index}
      updateLineCounts={props?.updateLineCounts}
      extraPadding={props?.extraPadding}
      key={props?.index}
    />
  );
}

export function getTransactionUpsellName(name: string) {
  return (
    <Text
      style={{
        fontFamily: Styles.FontFamily.aeonikRegular,
        fontSize: Styles.Fonts.f7,
        color: Styles.Colors.PayNew.darkHuesBase04,
        fontWeight: '400',
      }}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
    >
      {name}
    </Text>
  );
}

export function getTransactionUpsellProductsList(
  products: MenuProductType[],
  addItem: (item: MenuProductType) => void,
  dna?: DNA,
  props?: ListScanItemsProps,
) {
  const calorieUnit = dna?.showCaloriesAsKilocalories
    ? Localized.Labels.kcal
    : Localized.Labels.cal_not_all_caps;

  return (
    <View style={{marginHorizontal: -Styles.Spacing.m3}}>
      <FlatList
        horizontal
        data={products}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={() => <View style={{width: 16}} />}
        ListFooterComponent={() => <View style={{width: 16}} />}
        ItemSeparatorComponent={() => <View style={{width: 12}} />}
        renderItem={({item}) => {
          const caloriesValue = item?.calories;
          const price = Util.formatCurrency(
            props,
            item.price,
            AccountStore.getCurrency(),
          );
          return (
            <View
              style={{
                flex: 1,
                padding: 8,
                borderWidth: 1,
                justifyContent: 'space-between',
                borderRadius: Styles.Spacing.m2,
                borderColor: Styles.Colors.PayNew.neutralHuesBase09,
                backgroundColor: Styles.white,
                marginBottom: Styles.Spacing.m2,
                marginTop: Styles.Spacing.m3,
                width: 166,
                ...Platform.select({
                  ios: {
                    shadowColor: '#707070',
                    shadowOffset: {width: 1, height: 3},
                    shadowOpacity: 0.2,
                  },
                  android: {
                    elevation: 3,
                  },
                }),
              }}
            >
              <View>
                {item.smallimageurl ? (
                  <Image
                    accessible
                    aria-label={`${item.name} Image`}
                    accessibilityLabel={`${item.name} Image`}
                    role="img"
                    accessibilityRole="image"
                    source={{uri: ensureHttps(item.smallimageurl)}}
                    style={{
                      width: 148,
                      height: 125,
                      backgroundColor: Styles.Colors.PayNew.white01,
                      borderRadius: Styles.Spacing.m2,
                    }}
                    resizeMode="cover"
                  />
                ) : (
                  <Image
                    accessible
                    accessibilityLabel={`Default-Product Image`}
                    accessibilityRole="image"
                    aria-label="Default-Product Image"
                    role="img"
                    style={{
                      width: 148,
                      height: 125,
                      backgroundColor: Styles.Colors.PayNew.white01,
                      borderWidth: 1,
                      borderColor: Styles.Colors.PayNew.neutralHuesBase09,
                      borderRadius: Styles.Spacing.m2,
                    }}
                    source={require('src/components/img/DefaultMenuItem.png')}
                    resizeMode="cover"
                  />
                )}
                <View
                  style={{
                    marginHorizontal: Styles.Spacing.m1,
                    marginTop: Styles.Spacing.m1,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: Styles.FontFamily.aeonikRegular,
                      fontSize: Styles.Fonts.f1,
                      color: Styles.Colors.PayNew.black01,
                      fontWeight: '700',
                      width: 144,
                    }}
                    numberOfLines={0}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                  >
                    {item.name}
                  </Text>
                </View>
              </View>

              <View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginHorizontal: Styles.Spacing.m1,
                    marginTop: Styles.Spacing.m1,
                  }}
                >
                  <Text
                    accessible
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                    style={{
                      fontFamily: Styles.FontFamily.aeonikRegular,
                      fontSize: Styles.Fonts.sectionHeader,
                      color: Styles.Colors.PayNew.black01,
                      fontWeight: '400',
                      width: 144,
                      lineHeight: 23,
                    }}
                    accessibilityLabel={`${caloriesValue} Text`}
                    accessibilityRole="text"
                    aria-label={`${caloriesValue} Text`}
                  >
                    {`${price} `}
                    {dna?.showCalories && caloriesValue !== null && (
                      <View
                        style={{
                          height: 20,
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 3,
                        }}
                      >
                        <BulletPoint />
                      </View>
                    )}
                    {dna?.showCalories &&
                      caloriesValue !== null &&
                      ` ${caloriesValue} ${calorieUnit}`}
                  </Text>
                </View>

                <View
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: Styles.Spacing.m2,
                  }}
                >
                  <View>
                    <RoundedAppButton
                      buttonViewStyle={{
                        height: 32,
                        paddingLeft: 12,
                        paddingRight: 12,
                      }}
                      titleText={Localized.Buttons.quick_apply}
                      buttonType={RoundedAppButtonType.Outline}
                      buttonIcon={<QuickApplyIcon />}
                      textStyle={{
                        fontSize: Styles.Fonts.f8,
                        fontFamily: Styles.FontFamily.aeonikRegular,
                        fontWeight: '700',
                        marginTop: -2,
                      }}
                      onPress={() => addItem(item)}
                      iconStyle={{marginRight: 6}}
                      accessibilityLabelValue={Localized.Buttons.quick_apply}
                      aria-label={Localized.Buttons.quick_apply}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                    />
                  </View>
                </View>
              </View>
            </View>
          );
        }}
      />
    </View>
  );
}

export function renderTenderDiscount(
  transaction: TransactionDetail | null,
  props: ListScanItemsProps,
) {
  let onScreenPromotionDiscount = 0;
  const discount = parseFloat(transaction?.PromotionDiscount ?? '');
  const afterTaxDiscount = parseFloat(transaction?.AfterTaxDiscount ?? '');
  if (transaction?.hasOwnProperty('OnScreenDiscount')) {
    onScreenPromotionDiscount = transaction?.OnScreenDiscount ?? 0;
  }
  let totalTenderDiscount = discount - onScreenPromotionDiscount;
  totalTenderDiscount = Math.round(totalTenderDiscount * 100) / 100;
  if (TransactionStore.isAfterTaxPromoApplied()) {
    totalTenderDiscount = totalTenderDiscount - afterTaxDiscount;
  }
  totalTenderDiscount = Math.round(totalTenderDiscount * 100) / 100;
  if (totalTenderDiscount && totalTenderDiscount > 0) {
    return (
      <View style={{marginVertical: 18, marginHorizontal: Styles.Spacing.m3}}>
        <Text
          style={{
            fontSize: Styles.Fonts.f7,
            fontFamily: Styles.FontFamily.aeonikRegular,
            fontStyle: 'italic',
            fontWeight: '400',
            color: Styles.Colors.PayNew.black01,
          }}
        >
          {Localized.Labels.formatString(
            Localized.Labels.you_are_saving_amount_by_using_the_app,
            Util.formatCurrency(
              props,
              totalTenderDiscount,
              AccountStore.getCurrency(),
            ),
          )}
        </Text>
      </View>
    );
  }
  return null;
}

export default {
  getPickupLocationStyles,
  getInactiveButtonColor,
  textColor,
  fontFamilyStyle,
  getPickupTimeStyles,
  getActiveButtonColor,
  locationErrorMessage,
  dayErrorMessage,
  timeErrorMessage,
  productDetailsTitle,
  productDetailsImageView,
  productDetailsStyles,
  roundedButtonColor,
  dashLineColor,
  menuListStyles,
  getFlashListProps,
  getProductComponent,
  getTransactionUpsellName,
  getTransactionUpsellProductsList,
  renderTenderDiscount,
};
