import Localized from 'src/constants/AppStrings';
import Styles from '../../../Styles';
import {Platform, StyleSheet, View} from 'react-native';
import CommonUtils from '../newui/commonutils';
import UIManager from '../../../elements/ui/UIManager';
import AccountStore from 'src/stores/AccountStore';
import RevolveMarketOneLineLogo from 'src/components/img/svg/RevolveMarketOneLines';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import MainConsumerContext from '../../../MainConsumerContext';
import {ContextType} from 'react';
import React from 'react';
import MenuOption from 'src/components/elements/MenuOption';
import InfoIcon from 'src/components/elements/InfoIcon';
import Settings from 'src/Settings';
import LocaleDropdown from 'src/components/elements/account/LocaleDropdown';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import BirthYearDropdown from 'src/components/elements/account/BirthYearDropdown';
import GenderDropdown from 'src/components/elements/account/GenderDropdown';
import BirthMonthDropdown from 'src/components/elements/account/BirthMonthDropdown';

export function backHeaderDescriptor(): string {
  return Localized.Labels.change_email;
}

export function maxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm4;
}

export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      paddingTop: Styles.Spacing.m3,
      marginBottom: Styles.Spacing.m6,
    },
    instructions: {
      alignSelf: 'stretch',
      color: Styles.black,
      fontSize: Styles.Fonts.f9,
      fontWeight: '700',
      marginTop: Styles.Spacing.m3,
      marginBottom: Styles.Spacing.m2,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getChangePasswordStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      flex: 1,
      paddingHorizontal: Styles.Spacing.m3 + Styles.Spacing.m2,
      paddingTop: Styles.Spacing.m3,
      width: Platform.OS === 'web' ? '50%' : '100%',
    },
  };
}

export function getDeleteAcntDisclaimer() {
  return 'voluntary_deletion_1';
}
export function getDeleteAcntStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    disclaimerText: {
      marginBottom: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f1,
      color: Styles.primaryColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    disclaimerTextBold: {
      marginBottom: Styles.Spacing.m2,
      fontWeight: 'bold',
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getEditMarketCardStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.f2,
      fontWeight: '700',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m4,
      paddingStart: Styles.Spacing.m4,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    scanditContainer: {
      alignSelf: 'stretch',
      marginHorizontal: Styles.Spacing.m4,
      borderRadius: Styles.Spacing.m4,
      marginTop: Styles.Spacing.m3,
      overflow: 'hidden',
      flex: 1,
      marginBottom: Styles.Spacing.m4,
    },
  };
}

export function getHelpStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    answer: {
      fontSize: Styles.Fonts.f7,
      flex: 1,
      flexWrap: 'wrap',
      color: Styles.darkColor,
      fontWeight: '500',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    header: {
      color: Styles.black,
      fontSize: Styles.Fonts.f2,
      fontWeight: '700',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3,
      paddingStart: Styles.Spacing.m3,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    question: {
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    text: {
      marginBottom: Styles.Spacing.m4,
      marginTop: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    link: {
      color: Styles.primaryColor,
      textDecorationLine: 'underline',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getManageAcntStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    localeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: Styles.Spacing.m2,
    },
    content: {
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
      paddingHorizontal: Styles.Spacing.m3,
      paddingTop: Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2,
    },
    saveBtnContainer: {
      flex: 1,
      marginTop: Styles.Spacing.m5 + Styles.Spacing.m4,
      alignSelf: 'flex-end',
    },
    localeIcon: {
      marginRight: Styles.Spacing.m0,
      marginLeft: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3,
    },
    localeDropdown: {
      flex: 1,
      marginTop: Styles.Spacing.m2,
    },
    dropdownContainer: {
      marginTop: Styles.Spacing.m1,
      marginHorizontal: 2,
    },
    menu: {
      height: Styles.Heights.h6,
      marginBottom: Styles.Spacing.m5,
      marginTop: Styles.Spacing.m2,
      marginHorizontal: 0,
    },
  };
}

export function getUserProfileTextStyleDescriptors() {
  return {
    name: {},
    emailLocationText: {},
    locationView: {},
  };
}

export function getFirstNameManageAccount(state, maxCharacters, doSetState) {
  return (
    <AllyTextInput
      label={Localized.Labels.first_name}
      value={state.firstName}
      accessible={true}
      accessibilityLabel={Localized.Labels.first_name}
      accessibilityValue={{text: state.firstName}}
      onChangeText={doSetState}
      autoCapitalize="words"
      maxLength={maxCharacters}
      maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
    />
  );
}

export function getLastNameManageAccount(state, maxCharacters, doSetState) {
  return (
    <AllyTextInput
      label={Localized.Labels.last_name}
      value={state.lastName}
      accessible={true}
      accessibilityLabel={Localized.Labels.last_name}
      accessibilityValue={{text: state.lastName}}
      onChangeText={doSetState}
      autoCapitalize="words"
      maxLength={maxCharacters}
      maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
    />
  );
}

export function getGenderDropDownManageAccount(
  state,
  genderSelected,
  handleMenuVisibilityChange,
) {
  return (
    <View
      style={{marginTop: Styles.Spacing.m2}}
      accessible={true}
      accessibilityLabel="Gender"
      accessibilityRole="menu"
      accessibilityValue={{text: state.gender}}
      accessibilityState={{
        expanded: state.genderMenuVisible,
      }}
      accessibilityElementsHidden={
        state.birthMonthMenuVisible || state.birthYearMenuVisible
      }
      importantForAccessibility={
        state.birthMonthMenuVisible || state.birthYearMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
      role="menu"
      aria-label={'Gender'}
      aria-valuetext={state.gender}
      aria-expanded={state.genderMenuVisible}
    >
      <GenderDropdown
        selectedValue={state.gender}
        onSelect={genderSelected}
        onVisibilityChange={() =>
          handleMenuVisibilityChange(!state.genderMenuVisible, 'gender')
        }
      />
    </View>
  );
}

export function getBirthMonthDropDownManageAccount(
  state,
  birthMonthSelected,
  handleMenuVisibilityChange,
) {
  return (
    <View
      accessible={true}
      accessibilityLabel="Birth Month"
      accessibilityRole="menu"
      accessibilityValue={{text: state.birthMonth}}
      accessibilityState={{
        expanded: state.birthMonthMenuVisible,
      }}
      accessibilityElementsHidden={
        state.genderMenuVisible || state.birthYearMenuVisible
      }
      importantForAccessibility={
        state.genderMenuVisible || state.birthYearMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
      role="menu"
      aria-label={'Birth Month'}
      aria-valuetext={state.birthMonth}
      aria-expanded={state.birthMonthMenuVisible}
    >
      <BirthMonthDropdown
        selectedValue={state.birthMonth}
        onSelect={birthMonthSelected}
        onVisibilityChange={() =>
          handleMenuVisibilityChange(!state.birthMonthMenuVisible, 'birthMonth')
        }
        showNumbers={false}
      />
    </View>
  );
}

export function getBirthYearDropDownManageAccount(
  state,
  birthMonthYearSelected,
  birthYearSelected,
  handleMenuVisibilityChange,
) {

  return (
    <View
      accessible={true}
      accessibilityLabel="Birth Year"
      accessibilityRole="menu"
      accessibilityValue={{text: state.birthYear}}
      accessibilityState={{
        expanded: state.birthYearMenuVisible,
      }}
      accessibilityElementsHidden={
        state.genderMenuVisible || state.birthMonthMenuVisible
      }
      importantForAccessibility={
        state.genderMenuVisible || state.birthMonthMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
      role="menu"
      aria-label={'Birth Year'}
      aria-valuetext={state.birthYear}
      aria-expanded={state.birthYearMenuVisible}
    >
      <BirthYearDropdown
        selectedValue={state.birthYear}
        onSelect={birthYearSelected}
        onVisibilityChange={() =>
          handleMenuVisibilityChange(!state.birthYearMenuVisible, 'birthYear')
        }
      />
    </View>
  );
}

export function getJobTitleManageAccount(state, doSetState) {
  return (
    <View
      style={{marginTop: Styles.Spacing.m1}}
      accessibilityElementsHidden={
        state.genderMenuVisible ||
        state.birthMonthMenuVisible ||
        state.birthYearMenuVisible
      }
      importantForAccessibility={
        state.genderMenuVisible ||
        state.birthMonthMenuVisible ||
        state.birthYearMenuVisible
          ? 'no-hide-descendants'
          : 'yes'
      }
    >
      <AllyTextInput
        label={Localized.Labels.job_title}
        value={state.jobTitle}
        accessible={true}
        accessibilityLabel={Localized.Labels.job_title}
        accessibilityValue={{text: state.jobTitle}}
        onChangeText={doSetState}
        autoCapitalize="words"
        maxFontSizeMultiplier={maxFontSizeMultipleDescriptor()}
      />
    </View>
  );
}

export function getSystemLanguageDropDownManageAccount(
  maxWidth,
  state,
  localeSelected,
  handleMenuVisibilityChange,
) {
  return (
    <View
      style={
        getManageAcntStyleDescriptor() &&
        getManageAcntStyleDescriptor()['localeContainer']
      }
    >
      <View
        style={[
          getManageAcntStyleDescriptor()['localeDropdown'],
          getManageAcntStyleDescriptor()['dropdownContainer'],
          {
            width: maxWidth - Styles.Spacing.m2,
            marginHorizontal: 2,
          },
        ]}
        accessible={true}
        accessibilityLabel="Locale drop down"
        accessibilityRole="menu"
        accessibilityValue={{text: state.locale}}
        accessibilityState={{
          expanded: state.localeMenuVisible,
        }}
        accessibilityElementsHidden={
          state.genderMenuVisible ||
          state.birthMonthMenuVisible ||
          state.birthYearMenuVisible
        }
        importantForAccessibility={
          state.genderMenuVisible ||
          state.birthMonthMenuVisible ||
          state.birthYearMenuVisible
            ? 'no-hide-descendants'
            : 'yes'
        }
        role="menu"
        aria-label="Locale drop down"
        aria-valuetext={state.locale}
        aria-expanded={state.localeMenuVisible}
      >
        <LocaleDropdown
          style={{flex: 1}}
          selectedValue={state.locale}
          onSelect={localeSelected}
          onVisibilityChange={() =>
            handleMenuVisibilityChange(!state.localeMenuVisible, 'locale')
          }
        />
      </View>
      <View
        accessible={true}
        accessibilityLabel="Information"
        accessibilityRole="imagebutton"
        accessibilityHint="Double tap to show the help information"
        aria-label="Information"
        role="button"
        style={
          getManageAcntStyleDescriptor() &&
          getManageAcntStyleDescriptor()['localeIcon']
        }
      >
        <InfoIcon
          infoMessage={Localized.Labels.formatString(
            Localized.Labels.system_language_explanation,
            Settings.appDisplayName,
          )}
        />
      </View>
    </View>
  );
}

export function getChangeEmail(handleChangeEmail) {
  return (
    <MenuOption
      accessibilityLabel="Change Email"
      label={Localized.Labels.change_email}
      onPress={handleChangeEmail}
      style={getManageAcntStyleDescriptor()['menu']}
    />
  );
}

export function getSaveBtnManageAccount(handleSave) {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Buttons.save}
      onPress={handleSave}
      accessible={true}
      addShadow={false}
      accessibilityLabel={Localized.Buttons.save}
      accessibilityRole="button"
      role="button"
      color={Styles.darkGreenRevolve}
      aria-label={Localized.Buttons.save}
    />
  );
}

export function showIcon(openWebPage: () => void, brandTitle: string) {
  return (
    <>
      <View style={styles.revolveMarketSingleLineLogo}>
        <RevolveMarketOneLineLogo />
      </View>
      <AVTouchableOpacity onPress={openWebPage}>
        <AVText
          style={styles.fiveStarLink}
          accessible={true}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
          accessibilityLabel={brandTitle}
          accessibilityRole="text"
          aria-label={`fivestarBreakTime.com, text`}
        >
          {brandTitle}
        </AVText>
      </AVTouchableOpacity>
    </>
  );
}

export function signOutColor() {
  return Styles.white;
}
export function signOutButtonType() {
  return ButtonType.innerWhite;
}

const styles = StyleSheet.create({
  revolveMarketSingleLineLogo: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  fiveStarLink: {
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f7,
    textDecorationLine: 'underline',
    color: '#2C6CD3',
    fontWeight: '600',
  },
});

export function getHelpAndOtherStr() {
  return Localized.Labels.support.toUpperCase();
}

export function getSettingsOptions(handlePrivacyPolicyUrl: () => void) {
  const settings = [];
  settings.push({
    accessibilityLabel: Localized.Labels.personal_info,
    accessibilityHint: 'Double tap to view or update your personal information',
    onPressed: CommonUtils.toManageAccount,
    label: Localized.Labels.personal_info,
  });
  settings.push({
    accessibilityLabel: Localized.Labels.link_location,
    accessibilityHint: 'Double tap to go to link location',
    onPressed: CommonUtils.handleLinkLocation,
    label: Localized.Labels.link_location,
  });
  settings.push({
    accessibilityLabel: Localized.Labels.view_privacy_policy,
    accessibilityHint: 'Double tap to view the privacy policy',
    onPressed: handlePrivacyPolicyUrl,
    label: Localized.Labels.view_privacy_policy,
  }),
    settings.push({
      accessibilityLabel: Localized.Labels.view_terms_and_conditions,
      accessibilityHint: 'Double tap to view terms and conditions',
      onPressed: CommonUtils.handleTerms,
      label: Localized.Labels.view_terms_and_conditions,
    });
  settings.push({
    accessibilityLabel: Localized.Labels.delete_account,
    accessibilityHint: 'Double tap to navigate to the delete account screen',
    onPressed: CommonUtils.handleDeleteAccount,
    label: Localized.Labels.delete_account,
  });
  return settings;
}

export function getParentContainerStyle(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    parentContainer: {
      backgroundColor: Styles.tabBarBackgroundColor,
    },
  };
}

export function getHistoryOptions() {
  const settings = [];
  settings.push({
    accessibilityLabel: Localized.Labels.history,
    accessibilityHint: 'Double tap to view purchase history',
    onPressed: CommonUtils.toPurchaseHistoryScreen,
    label: Localized.Labels.history,
  });
  return settings;
}

export function getSecurityOptions() {
  return null;
}

export function getHelpAndOtherOptions() {
  let helpAndOther = [];
  helpAndOther = [
    {
      accessibilityLabel: UIManager.getHelpLabel(),
      accessibilityHint: 'Double tap to navigate to the help screen',
      onPressed: CommonUtils.contactClicked,
      label: UIManager.getHelpLabel(),
    },
    {
      accessibilityLabel: Localized.Labels.change_email,
      accessibilityHint: 'Double tap to change your email',
      onPressed: CommonUtils.handleChangeEmail,
      label: Localized.Labels.change_email,
    },
    {
      accessibilityLabel: Localized.Labels.change_password,
      accessibilityHint: 'Double tap to change your password',
      onPressed: CommonUtils.handleChangePassword,
      label: Localized.Labels.change_password,
    },
    {
      accessibilityLabel: Localized.Labels.change_pin,
      accessibilityHint: 'Double tap to change your pin',
      onPressed: CommonUtils.handleChangePin,
      label: Localized.Labels.change_pin,
    },
  ];
  return helpAndOther;
}

const getPaymentOptions = (
  context: ContextType<typeof MainConsumerContext>,
  addPassClicked: () => void,
) => {
  const settings = [];
  settings.push({
    accessibilityLabel: Localized.Labels.balances,
    accessibilityHint: 'Double tap to view your balances',
    onPressed: CommonUtils.toBalances,
    label: Localized.Labels.balances,
  });
  settings.push({
    accessibilityLabel: Localized.Labels.wallet,
    accessibilityHint: 'Double tap to view your wallet',
    onPressed: CommonUtils.toWalletScreen,
    label: Localized.Labels.wallet,
  });
  if (AccountStore.getMarketCard()) {
    settings.push({
      accessibilityLabel: Localized.Labels.edit_market_card,
      accessibilityHint: 'Double tap to edit your market card',
      onPressed: CommonUtils.handleMarketCard,
      label: Localized.Labels.edit_market_card,
    });
  } else {
    settings.push({
      accessibilityLabel: Localized.Labels.add_market_card,
      accessibilityHint: 'Double tap to add a market card',
      onPressed: CommonUtils.handleMarketCard,
      label: Localized.Labels.add_market_card,
    });
  }
  if (!context?.state.passExists && context?.state.canAddPass) {
    settings.push({
      accessibilityLabel: Localized.Labels.add_to_apple_wallet,
      accessibilityHint: 'Double tap to navigate to add your apple wallet',
      onPressed: addPassClicked,
      label: Localized.Labels.add_to_apple_wallet,
    });
  }
  return settings;
};

const getGiftingOptions = () => {
  let sendASnack: Array<object> = [];
  if (
    AccountStore.isConsumerSendSnackEnabled() &&
    AccountStore.isSendSnackEnabledForLocation()
  ) {
    sendASnack = [
      {
        accessibilityLabel: Localized.Labels.send_a_snack_title,
        accessibilityHint: 'Double tap to navigate to the send a snack screen',
        onPressed: CommonUtils.sendasnackClicked,
        label: Localized.Labels.send_a_snack_title,
      },
      {
        accessibilityLabel: Localized.Labels.blocked_users,
        accessibilityHint: 'Double tap to navigate to the blocked users screen',
        onPressed: CommonUtils.blockedUsersClicked,
        label: Localized.Labels.blocked_users,
      },
    ];
  }
  return sendASnack;
};

export function getLinkAccountsOptions() {
  return null;
}

export function getBrandWebPage() {
  return 'https://www.fivestarbreaktime.com/';
}

export function getBrandTitle() {
  return 'fivestarbreaktime.com';
}

export function getSignOutButton(onLogoutSelect: () => void): JSX.Element {
  return (
    <React.Fragment>
      <RoundedButton
        color={signOutColor() || ''}
        accessibilityHint="Double tap to log out"
        text={Localized.Buttons.signout}
        containerStyle={{
          borderWidth: 1,
          marginTop: Styles.Spacing.m2,
        }}
        accessibilityLabel={Localized.Buttons.signout}
        role="button"
        accessible={true}
        accessibilityRole="button"
        aria-label={Localized.Buttons.signout}
        onPress={onLogoutSelect}
        buttonType={signOutButtonType()}
      />
    </React.Fragment>
  );
}

function generateEmailInputFields({
  labelNewEmail,
  valueNewEmail,
  onChangeNewEmail,
  labelConfirmEmail,
  valueConfirmEmail,
  onChangeConfirmEmail,
  maxFontSizeMultiplier,
}) {
  return (
    <>
      <AllyTextInput
        maxFontSizeMultiplier={maxFontSizeMultiplier()}
        label={labelNewEmail}
        value={valueNewEmail}
        accessible={true}
        accessibilityLabel={labelNewEmail}
        accessibilityValue={{text: valueNewEmail}}
        onChangeText={onChangeNewEmail}
        autoCapitalize="none"
        keyboardType="email-address"
      />
      <AllyTextInput
        maxFontSizeMultiplier={maxFontSizeMultiplier()}
        label={labelConfirmEmail}
        value={valueConfirmEmail}
        accessible={true}
        accessibilityLabel={labelConfirmEmail}
        accessibilityValue={{text: valueConfirmEmail}}
        onChangeText={onChangeConfirmEmail}
        autoCapitalize="none"
        keyboardType="email-address"
      />
    </>
  );
}

function generateChangeEmailRoundedButton({
  accessibilityLabel,
  accessibilityHint,
  ariaLabel,
  buttonText,
  onPressHandler,
}) {
  return (
    <RoundedButton
      accessible={true}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="button"
      accessibilityHint={accessibilityHint}
      role="button"
      color={Styles.darkGreenRevolve}
      aria-label={ariaLabel}
      buttonType={ButtonType.action}
      text={buttonText}
      onPress={onPressHandler}
    />
  );
}

export default {
  backHeaderDescriptor,
  maxFontSizeMultipleDescriptor,
  getStyleDescriptor,
  getChangePasswordStyleDescriptor,
  getDeleteAcntStyleDescriptor,
  getEditMarketCardStyleDescriptor,
  getHelpStyleDescriptor,
  getManageAcntStyleDescriptor,
  getParentContainerStyle,
  showIcon,
  signOutColor,
  signOutButtonType,
  getSettingsOptions,
  getHistoryOptions,
  getSecurityOptions,
  getHelpAndOtherOptions,
  getDeleteAcntDisclaimer,
  getPaymentOptions,
  getHelpAndOtherStr,
  getUserProfileTextStyleDescriptors,
  getGiftingOptions,
  getLinkAccountsOptions,
  getBrandWebPage,
  getBrandTitle,
  getSignOutButton,
  getSystemLanguageDropDownManageAccount,
  getJobTitleManageAccount,
  getBirthYearDropDownManageAccount,
  getBirthMonthDropDownManageAccount,
  getGenderDropDownManageAccount,
  getLastNameManageAccount,
  getFirstNameManageAccount,
  getChangeEmail,
  getSaveBtnManageAccount,
  generateEmailInputFields,
  generateChangeEmailRoundedButton,
};
