import React from 'react';
import {Platform, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {networkError} from 'src/components/helpers/AlertHelper';
import Styles from 'src/components/Styles';
import AppRoutes from 'src/AppRoutes';
import HistoryIcon from 'src/components/img/svg/HistoryIcon';
import RewardsIcon from 'src/components/img/svg/RewardsIcon';
import WalletIcon from 'src/components/img/svg/WalletIcon';
import HomeIcon from 'src/components/img/svg/HomeIcon';
import Localized from 'src/constants/AppStrings';
import Settings from 'src/Settings';
import AccountTabIcon from 'src/components/img/svg/AccountTabIcon';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import PayIcon from 'src/components/img/svg/PayIcon';
import HomeRevolveIcon from 'src/components/img/svg/HomeRevolveIcon';
import OffersRevolveIcon from 'src/components/img/svg/OffersRevolveIcon';
import AccountRevolveIcon from 'src/components/img/svg/AccountRevolveIcon';
import RewardsDefaultIcon from 'src/components/img/svg/RewardsDefaultIcon';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import HomeIconLight from 'src/components/img/svg/HomeIconLight';
import OffersTabIcon from 'src/components/img/svg/OffersTabIcon';
import AccountRedesignDarkIcon from 'src/components/img/svg/AccountRedesignDarkIcon';
import AccountRedesignLightIcon from 'src/components/img/svg/AccountRedesignLightIcon';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

const BottomTabBar = ({state, descriptors, navigation}) => {
  const inset = useSafeAreaInsets();
  let label: string;

  const getTabColor = (isFocused: boolean) => {
    if (Settings.buildType === BuildTypeConstants.default) {
      return isFocused ? Styles.footerTabActive : Styles.footerTabInactive;
    } else if (Settings.buildType === BuildTypeConstants.canteen) {
      return isFocused
        ? Styles.Colors.Canteen.darkGreen
        : Styles.Colors.PayLegacy.grayScaleBase03;
    } else {
      return isFocused ? Styles.blueRevolve : Styles.lightGray;
    }
  };

  /** function to toggle icon color active/inactive  */
  const getTabIcon = (routeName: AppRoutes, isFocused: boolean) => {
    const color = getTabColor(isFocused);
    if (Settings.buildType === BuildTypeConstants.default) {
      switch (routeName) {
        case Localized.Labels.offers:
          return (
            <View style={{marginVertical: Styles.Spacing.m1}}>
              <OffersTabIcon color={color} />
            </View>
          );
        case Localized.Labels.rewards:
          return <RewardsDefaultIcon color={color} />;
        case Localized.Labels.home:
          return isFocused ? (
            <HomeRevolveIcon color={color} />
          ) : (
            <HomeIconLight color={color} />
          );
        case Localized.Labels.account:
          return isFocused ? (
            <AccountRedesignDarkIcon width={35} height={34} />
          ) : (
            <AccountRedesignLightIcon width={35} height={34} />
          );
        case Localized.Labels.pay:
          return <PayIcon color={color} isFocused={isFocused} />;
        default:
          return null;
      }
    } else if (Settings.buildType === BuildTypeConstants.canteen) {
      switch (routeName) {
        case Localized.Labels.wallet:
          return <WalletIcon color={color} />;
        case Localized.Labels.rewards:
          return (
            <View style={{marginLeft: Styles.Spacing.m2}}>
              <RewardsIcon color={color} />
            </View>
          );
        case Localized.Labels.history:
          return <HistoryIcon color={color} />;
        case Localized.Labels.account:
          return <AccountTabIcon color={color} />;
        case Localized.Labels.home:
          return <HomeIcon color={color} />;
        default:
          return null;
      }
    } else if (Settings.buildType === BuildTypeConstants.revolve) {
      switch (routeName) {
        case Localized.Labels.home:
          return <HomeRevolveIcon color={color} />;
        case Localized.Labels.pay:
          return <PayIcon color={color} />;
        case Localized.Labels.offers:
          return <OffersRevolveIcon color={color} />;
        case Localized.Labels.account:
          return <AccountRevolveIcon color={color} />;
        default:
          return null;
      }
    }
  };

  /** function tab press */
  const onPress = async (route, isFocused: boolean) => {
    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
    });
    try {
      const networkState = await NetInfo.fetch();
      /** function check network */
      if (
        !networkState?.isConnected &&
        (route.name === Localized.Labels.wallet ||
          route.name === Localized.Labels.rewards ||
          route.name === Localized.Labels.history ||
          route.name === Localized.Labels.scan ||
          route.name === Localized.Labels.offers ||
          route.name === Localized.Labels.account ||
          route.name === Localized.Labels.home)
      ) {
        networkError();
      }
      if (!isFocused && !event.defaultPrevented) {
        navigation.navigate(route.name);
      }
    } catch (err) {

    }
  };
  return (
    <View style={[styles.container, {paddingBottom: inset.bottom}]}>
      {state.routes.map((route, index) => {
        const {options} = descriptors[route.key];
        if (options.tabBarLabel !== undefined) {
          label = options.tabBarLabel;
        } else if (options.title !== undefined) {
          label = options.title;
        } else {
          label = route.name;
        }

        const isFocused = state.index === index;
        return (
          <View
            key={index}
            accessible={true}
            accessibilityLabel={options.tabBarAccessibilityLabel}
            aria-label={options.tabBarAccessibilityLabel}
            testID={options.tabBarTestID}
            style={{
              ...styles.tabView,
            }}
          >
            <View
              style={{
                borderTopColor: isFocused
                  ? getDescriber().bottomTabBtnColor()
                  : 'transparent',
                borderTopWidth: isFocused ? 3 : 0,
                width: 44,
                position: 'absolute',
                top: 0,
              }}
            />
            <TouchableOpacity
              key={index}
              accessible={true}
              accessibilityRole="button"
              accessibilityState={isFocused ? {selected: true} : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              aria-label={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={() => onPress(route, isFocused)}
              style={styles.btnStyle}
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: Styles.Heights.h1,
                  paddingBottom:
                    Platform.OS === 'android' ? Styles.Heights.h1 : 0,
                }}
              >
                {getTabIcon(route.name, isFocused)}
                <Text
                  accessible={true}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                  accessibilityLabel={label}
                  aria-label={label}
                  testID={label}
                  style={
                    Settings.buildType === BuildTypeConstants.default
                      ? {
                          fontFamily: Styles.FontFamily.aeonikRegular,
                          fontSize: 10,
                          fontWeight: isFocused ? '700' : '400',
                          textAlign: 'center',
                          color: getTabColor(isFocused),
                        }
                      : {
                          color: getTabColor(isFocused),
                          fontSize: Settings.isRevolve() ? 11 : 10,
                          fontWeight: isFocused ? '700' : 'normal',
                          textAlign: 'center',
                          fontFamily: Styles.FontFamily.robotoRegular,
                        }
                  }
                >
                  {label.toUpperCase()}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        );
      })}
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Styles.white,
  },
  tabView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Styles.Heights.h1,
    backgroundColor: Styles.white,
    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: Styles.Colors.PayNew.neutralHuesBase09,
  },
  btnStyle: {
    minHeight: Styles.Heights.touchTargetHeight2,
    minWidth: Styles.Heights.touchTargetHeight2,
  },
});
export default BottomTabBar;
