import * as React from 'react';
import {
  PixelRatio,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import Util from 'src/Util';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import AccountStore from 'src/stores/AccountStore';
type RewardSectionProps = WithGlobalizeProps & {
  rewards: number;
  style: StyleProp<ViewStyle>;
  isRewardButtonShown?: boolean;
  rewardSteps: Array<RewardType>;
};
export type RewardType = {
  points: number;
  additionalpoints: number;
  redeemvalue: number;
};

class RewardSection extends React.Component<RewardSectionProps> {
  render() {
    let i = 0;
    let currentPoints = this.props.rewards;
    let prevPoints = 0;
    let additionalPoints = 0;
    let rewardValue = 0;

    const rewardOptions = this.props.rewardSteps?.map((reward) => {
      let availableFlex = 1;
      let unavailableFlex = 1;
      const available = reward.points <= this.props.rewards;
      additionalPoints = reward.points - prevPoints;
      prevPoints = reward.points;

      if (additionalPoints === 0) {
        return null;
      }

      if (this.props.rewards > reward.points) {
        rewardValue = reward.redeemvalue;
      }

      if (currentPoints > 0) {
        if (currentPoints > reward.points) {
          availableFlex = 1;
          unavailableFlex = 0;
        } else {
          availableFlex = currentPoints / additionalPoints;
          unavailableFlex = 1 - availableFlex;
        }

        currentPoints -= additionalPoints;
      } else {
        availableFlex = 0;
      }

      return (
        <View
          key={i++}
          style={[
            styles.rewardOption,
            {
              flex: additionalPoints,
            },
          ]}
        >
          <View
            style={[
              styles.availableOption,
              {
                flex: availableFlex,
              },
            ]}
          />
          <View
            style={[
              styles.unavailableOption,
              {
                flex: unavailableFlex,
              },
            ]}
          />
          <View style={styles.overlayText}>
            <Text
              accessible={false}
              style={[styles.rewardText, available && styles.availableText]}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Util.formatCurrency(
                this.props,
                reward.redeemvalue,
                AccountStore.getCurrency(),
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 0,
                },
              )}
            </Text>
          </View>
          <View
            style={[styles.overlayPoint, available && styles.availablePoint]}
          />
        </View>
      );
    });
    const pointsNumber = `${Util.formatNumber(this.props, this.props.rewards, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
    })}`;
    const largestAvailableRewardAmount = Util.formatCurrency(
      this.props,
      rewardValue,
      AccountStore.getCurrency(),
      {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      },
    );
    return (
      <View style={[styles.imageContainer, this.props.style]}>
        <View style={styles.image}>
          <View style={styles.header}>
            <View
              style={styles.rewardOption}
              nativeID={this.props.rewards + ' Reward Points'}
            >
              <Text
                accessible={false}
                accessibilityElementsHidden={true}
                accessibilityLabel={Localized.Labels.rewards}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.rewards}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              >
                {Localized.Labels.rewards}
              </Text>
              <Text
                style={styles.pointNumberLabel}
                accessibilityLabel={`${pointsNumber} reward points`}
                accessibilityRole="text"
                aria-label={`${pointsNumber} reward points, text`}
                accessible={true}
                nativeID="Reward Points"
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              >
                {pointsNumber}
              </Text>
              <Text
                accessible={false}
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                style={styles.pointsLabel}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              >
                {Localized.Labels.points}
              </Text>
            </View>
            {this.props.isRewardButtonShown ? (
              <RoundedButton
                buttonType={ButtonType.normal}
                text={Localized.Labels.redeem}
                containerStyle={styles.button}
                onPress={() => NavActions.navigate(AppRoutes.Rewards)}
                accessible={true}
                accessibilityLabel={Localized.Buttons.redeem_reward_points}
                accessibilityRole="button"
                role="button"
                aria-label={Localized.Buttons.redeem_reward_points}
              />
            ) : null}
          </View>
          <View
            style={[
              styles.graph,
              this.props.isRewardButtonShown && {
                marginTop:
                  PixelRatio.getFontScale() > Styles.FontSizeMultiplier.maxfm0
                    ? Styles.Spacing.m5
                    : Styles.Spacing.m4,
              },
            ]}
            accessible={true}
            accessibilityLabel={`${largestAvailableRewardAmount} in rewards available`}
            accessibilityRole="image"
            aria-label={`${largestAvailableRewardAmount} in rewards available`}
            role="img"
            key={-1}
          >
            <View style={styles.line}>
              <View key={i++} style={[styles.rewardOption, styles.flex1]}>
                <View
                  style={[
                    styles.availableOption,
                    styles.firstSection,
                    {
                      flex: Styles.Spacing.m1,
                    },
                  ]}
                />
              </View>
            </View>
            <View style={[styles.line, styles.flex15]}>{rewardOptions}</View>
            <View style={styles.line}>
              <View key={i++} style={[styles.rewardOption, styles.flex1]}>
                <View
                  style={[
                    styles.unavailableOption,
                    styles.lastSection,
                    {
                      flex: Styles.Spacing.m1,
                    },
                  ]}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  availableOption: {
    height: PixelRatio.roundToNearestPixel(10),
    backgroundColor: Styles.primaryColor,
  },
  availablePoint: {
    backgroundColor: Styles.primaryColor,
    borderColor: Styles.primaryColor,
  },
  availableText: {
    color: Styles.primaryColor,
  },
  button: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 2,
  },
  firstSection: {
    borderBottomLeftRadius: Styles.Spacing.m3,
    borderTopLeftRadius: Styles.Spacing.m3,
  },
  flex1: {
    flex: 1,
  },
  flex15: {
    flex: 15,
  },
  graph: {
    alignSelf: 'stretch',
    flexDirection: 'row',
    marginTop: Styles.Spacing.m3,
  },
  header: {
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
  image: {
    alignItems: 'flex-start',
    flex: 1,
    justifyContent: 'flex-start',
  },
  imageContainer: {
    paddingHorizontal: Styles.Spacing.m2,
  },
  lastSection: {
    borderBottomRightRadius: Styles.Spacing.m3,
    borderTopRightRadius: Styles.Spacing.m3,
  },
  line: {
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m1,
  },
  overlayPoint: {
    backgroundColor: Styles.lightGray,
    height: PixelRatio.roundToNearestPixel(10),
    position: 'absolute',
    right: 0,
    width: PixelRatio.roundToNearestPixel(1),
  },
  overlayText: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    position: 'absolute',
    right: -7,
    top: Styles.Spacing.m2,
  },
  pointNumberLabel: {
    fontSize: Styles.Fonts.f2,
    fontWeight: '600',
    marginLeft: Styles.Spacing.m1,
  },
  pointsLabel: {
    alignSelf: 'flex-end',
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    lineHeight: Styles.Fonts.f2,
    marginLeft: Styles.Spacing.m1,
    textAlignVertical: 'bottom',
    textTransform: 'lowercase',
  },
  rewardsLabel: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f2,
    fontWeight: '500',
  },
  rewardOption: {
    flexDirection: 'row',
  },
  rewardText: {
    fontWeight: '300',
    marginTop: Styles.Spacing.m1,
  },
  unavailableOption: {
    height: PixelRatio.roundToNearestPixel(10),
    backgroundColor: Styles.bgColor,
  },
});
export default withGlobalize(RewardSection);
