import {useEffect, useState} from 'react';
import Styles from 'src/components/Styles';
import {StyleSheet} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Settings from 'src/Settings';
import {getDescriber} from '../home/descriptor/DescriptorType';

const margin = Settings.isRevolve()
  ? Styles.Spacing.m3
  : Styles.Spacing.m3 + Styles.Spacing.m2;

const SendASnackView = () => {
  const [isSnackEnabled, setIsSnackEnabled] = useState(false);

  useEffect(() => {
    checkSendASnackStatus();
    FirebaseAnalytic.trackEvent('componentDidMount', 'SendSnackView');
    AccountStore.addChangeListener(checkSendASnackStatus);
    return () => {
      AccountStore.removeChangeListener(checkSendASnackStatus);
    };
  }, []);

  //Checks Send A Snack Status based on User and Home location settings
  const checkSendASnackStatus = () => {
    if (
      AccountStore.isConsumerSendSnackEnabled() &&
      AccountStore.isSendSnackEnabledForLocation()
    ) {
      setIsSnackEnabled(true);
    } else {
      setIsSnackEnabled(false);
    }
  };

  //Send a snack action
  const onSendASnackClick = () => {
    FirebaseAnalytic.trackEvent('onSendASnackClick', 'SendSnackView');
    NavActions.navigate(AppRoutes.SendSnack);
  };
  const describerprops = {
    styles,
    onSendASnackClick,
  };

  return isSnackEnabled
    ? getDescriber().snackViewDescriptor(describerprops)
    : null;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    marginHorizontal: margin,
    marginTop: margin,
    paddingHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
    alignItems: 'flex-start',
    justifyContent: 'center',
    ...Styles.Style.shadow,
  },
  headerView: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: Styles.Heights.h4,
    marginTop: Styles.Spacing.m2,
  },
  iconView: {
    width: Styles.Spacing.m4,
    height: Styles.Spacing.m4,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Styles.primaryColor,
    borderRadius: Styles.Spacing.m2,
  },
  titleText: {
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '600',
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    marginLeft: Styles.Spacing.m3,
  },
  snackDescriptionText: {
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '400',
    textAlign: 'left',
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    marginVertical: Styles.Spacing.m1,
  },
  snackButton: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
    borderWidth: 1,
  },
  snackButtonText: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '600',
    fontSize: Styles.Fonts.f7,
  },
  revolveButtonStyle: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f7,
    fontWeight: '600',
  },
  starIconView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  cardNumber: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
  },
  revolveAddTxt: {
    fontWeight: '400',
    fontSize: 18,
    color: Styles.black,
  },
  revolveMainTxt: {
    fontWeight: '700',
    fontSize: 18,
    color: Styles.black,
    fontFamily: Styles.FontFamily.comodoVintage,
  },
});

export default SendASnackView;
