import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

interface IRecentComboIconProps {
  color?: string;
  width?: number;
  height?: number;
}
const RecentComboIcon = (props: IRecentComboIconProps) => (
  <Svg
    width={props?.width ?? 151}
    height={props?.height ?? 38}
    viewBox="0 0 151 38"
    fill="none"
  >
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27.555 9.21967H37.9892L33.6072 37.0952H20.4417C20.933 36.583 21.3456 35.992 21.66 35.3616H24.2931V25.3934H21.5028C20.3631 22.7339 18.1427 20.6851 15.4113 19.7395L13.7607 9.21967H24.1752V3.78248L25.2363 3.34907L31.3475 0.906269L32.9195 0.275879L34.1771 3.42788L32.6051 4.05827L27.555 6.06767V9.19998V9.21967ZM21.7583 31.1655H0.673828V27.7771H2.69779C3.48379 24.172 6.66707 21.611 10.3416 21.611H12.0905C15.765 21.611 18.9483 24.172 19.7343 27.7771H21.7583V31.1655ZM2.69779 33.7068H19.7147C19.1448 35.4995 17.4746 36.7012 15.6078 36.7012H6.80462C4.93787 36.7012 3.26764 35.4798 2.69779 33.7068Z"
      fill="#066DFF"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.9023 3.92041C59.9441 3.92041 55.2477 12.8445 59.7279 19.444L64.0312 25.7874L59.217 33.4901L64.9548 37.0952L69.2778 30.1805L69.5136 29.8062H83.6812L83.9171 30.1805L88.2401 37.0952L93.9779 33.4901L89.1636 25.7874L93.467 19.444C97.9472 12.8642 93.2508 3.92041 85.2926 3.92041C77.3343 3.92041 78.2185 6.08741 76.6073 9.10151C74.9763 6.10711 71.8323 3.92041 67.922 3.92041H67.9023ZM64.4439 16.1541C62.7933 13.7113 64.5225 10.4214 67.47 10.4214C70.4175 10.4214 72.1074 13.6128 70.5551 16.0753L67.5682 20.7639L64.4439 16.1541ZM88.7313 16.1541C90.3819 13.7113 88.6527 10.4214 85.7052 10.4214C82.7577 10.4214 81.0678 13.6128 82.6202 16.0753L85.607 20.7639L88.7313 16.1541ZM76.5876 17.474L80.498 24.0932H72.6772L76.5876 17.474Z"
      fill="#066DFF"
    />
    <Path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.065 6.2646C134.79 6.7571 134.613 7.289 134.515 7.8209C136.126 6.8162 138.131 6.06761 140.194 5.89031C141.825 5.75241 143.534 5.96911 145.126 6.77681C146.718 7.58451 148.132 8.9241 149.213 10.9532C150.53 13.4157 150.549 16.2919 149.94 19.1287C149.331 21.9655 148.054 24.8417 146.639 27.4224C145.401 29.6682 144.006 31.8352 142.473 33.9037C141.825 34.7902 141.157 35.657 140.449 36.5041L140.41 36.5435L139.938 37.0951L132.766 32.7808L125.594 37.0951L125.122 36.5435L125.083 36.5041C124.906 36.2874 124.71 36.0707 124.533 35.8343C124.159 35.3812 123.648 34.7114 123.059 33.9037C121.526 31.8352 120.151 29.6682 118.893 27.4224C117.498 24.8614 116.221 21.9655 115.592 19.1287C114.983 16.2919 115.002 13.4157 116.319 10.9532C117.4 8.9241 118.815 7.58451 120.406 6.77681C121.998 5.98881 123.688 5.75241 125.338 5.89031C127.421 6.06761 129.445 6.8556 131.076 7.88C131.194 6.7374 131.548 5.6342 132.098 4.6295C133.159 2.7186 135.222 0.7486 139.015 0L139.683 3.3293C136.873 3.88091 135.655 5.24021 135.065 6.28431V6.2646Z"
      fill="#066DFF"
    />
  </Svg>
);
export default RecentComboIcon;
