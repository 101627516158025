import Styles from 'src/components/Styles';
import {
  CustomTilesButtonStylesDescriptor,
  PickupLocationListStylesDescriptor,
  RecentOrdersButtonStylesDescriptor,
  ProductModifierStylesDescriptor,
  ProductModifierRadioButton,
  CheckoutBarProps,
} from 'src/components/elements/orderAhead/descriptor/DescriptorType';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  TouchableOpacity,
  PixelRatio,
} from 'react-native';
import RadioForm, {RadioButton} from 'react-native-simple-radio-button';
import CustomRadiobutton from '../../CustomRadioButton';
import {checkValue} from 'src/Util';
import Localized from 'src/constants/AppStrings';
import {CheckBox} from 'react-native-elements';
import {ProductModifierValue} from 'src/types/Menu';
import FontAwesome5Pro from '../../../icons/FontAwesomeIcon';
import ItemAddedFeedback from '../../cart/ItemAddedFeedback';
import MenuService from 'src/services/MenuService';
import TimeUtils from 'src/services/TimeUtils';

export function customTilesButtonStyles(): CustomTilesButtonStylesDescriptor {
  return StyleSheet.create({
    tileConatainer: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: Styles.lightGray,
      borderWidth: 1,
      borderRadius: Styles.Spacing.m2,
      height: Styles.Heights.h6,
      minWidth: 135,
      paddingHorizontal: Styles.Spacing.m2 + 4,
      justifyContent: 'flex-start',
      paddingVertical: Styles.Spacing.m3,
    },
    text: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      color: Styles.darkColor,
      paddingHorizontal: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    selectedView: {
      backgroundColor: Styles.primaryColor,
      borderColor: Styles.lightGray,
      borderWidth: 1,
    },
    selectedText: {
      color: Styles.white,
      fontWeight: '700',
    },
  });
}

export function pickupLocationListStyles(): PickupLocationListStylesDescriptor {
  return StyleSheet.create({
    cell: {
      padding: Styles.Spacing.m3,
      borderColor: Styles.lightGray,
      borderWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
      minHeight: Styles.Heights.touchTargetHeight2,
    },
    cellSelected: {
      flexDirection: 'row',
      borderColor: Styles.primaryColor,
      padding: Styles.Spacing.m3,
      borderWidth: 2.5,
      alignItems: 'center',
      borderRadius: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3,
      backgroundColor: Styles.white,
    },
    label: {
      color: Styles.darkColor,
      fontWeight: '400',
      fontSize: Styles.Fonts.f7,
      paddingLeft: Styles.Spacing.m2,
      paddingRight: Styles.Spacing.m4,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    selectedLabel: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.primaryColor,
      paddingLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
  });
}

export function productModifierStyles(): ProductModifierStylesDescriptor {
  return StyleSheet.create({
    customizationDivider: {
      borderBottomWidth: 1,
      borderColor: Styles.lightGray,
      borderWidth: 0,
      marginTop: Styles.Spacing.m1,
      borderRadius: null,
    },
    radioButtonWrapper: {
      padding: Styles.Spacing.m1,
      backgroundColor: Styles.white,
      marginLeft: Styles.Spacing.m0,
      justifyContent: 'center',
    },
    checkboxCalorieContainer: {
      flexDirection: 'column',
      flex: 3,
      alignItems: 'flex-end',
      marginRight: Styles.Spacing.m2,
      color: Styles.darkColor,
    },
    checkboxRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    checkboxNameContainer: {
      flexDirection: 'column',
      marginRight: Styles.Spacing.m2,
      alignItems: 'flex-start',
      flex: 7,
    },
    checkboxPriceContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 3,
    },
    titleText: {
      color: Styles.darkColor,
      fontWeight: '600',
      fontSize: Styles.Fonts.f1,
      flex: 0.8,
    },
    requiredText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.dangerColor,
      fontStyle: 'italic',
    },
    requiredViewStyles: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    options: {
      paddingVertical: Styles.Spacing.m2,
    },
    totalCaloriesLabel: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
    },
    sectionCalStyles: {
      fontSize: 16,
      marginRight: Styles.Spacing.m2,
      fontWeight: Styles.bold,
      color: Styles.darkColor,
    },
    productModifierContainer: {
      backgroundColor: Styles.bgColor,
    },
    checkBoxWrapper: {
      padding: Styles.Spacing.m1,
      backgroundColor: Styles.white,
      marginLeft: Styles.Spacing.m0,
      margin: null,
      justifyContent: 'center',
      minHeight: Styles.Heights.h3,
    },
    checkBoxText: {
      marginLeft: Styles.Spacing.m2,
      color: Styles.darkColor,
    },
    optionText: {
      fontSize: Styles.Fonts.f0,
      marginLeft: Styles.Spacing.m2,
      color: Styles.darkColor,
      marginTop: -Styles.Spacing.m1 / 2,
      marginBottom: 5,
    },
    helperText: {
      alignSelf: 'flex-start',
    },
    titleRow: {
      marginTop: Styles.Spacing.m3,
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  });
}
export function radioButtonProductModifier(
  buttons: ProductModifierRadioButton[],
  values: string[],
  showCalories: boolean,
  showUnitName: string,
  onPress,
): JSX.Element {
  return (
    <RadioForm>
      {buttons.map((obj, i) => {
        const isSelected = values.includes(obj.value);
        return (
          <View
            accessible={true}
            accessibilityRole="radio"
            accessibilityState={{
              selected: isSelected,
            }}
            key={obj.value + '-' + i}
            style={productModifierStyles().customizationDivider}
          >
            <RadioButton
              key={i}
              style={productModifierStyles().radioButtonWrapper}
            >
              <CustomRadiobutton
                selected={isSelected}
                accessibilityLabel={obj.label}
                aria-label={obj.label}
                onPress={() => onPress(obj.value)}
              />
              <View style={productModifierStyles().checkboxRow}>
                <View style={productModifierStyles().checkboxNameContainer}>
                  <Text
                    accessibilityLabel={`${obj.label}, `}
                    aria-label={`${obj.label}, `}
                    style={{
                      color: Styles.darkColor,
                      width: Platform.OS === 'web' ? '100%' : undefined,
                      marginLeft: Styles.Spacing.m1,
                    }}
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  >
                    {obj.label}
                  </Text>
                </View>

                <View style={productModifierStyles().checkboxPriceContainer}>
                  {obj.price !== null && (
                    <Text
                      accessibilityLabel={`${obj.price}, `}
                      aria-label={`${obj.price}, `}
                      style={{color: Styles.darkColor}}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                    >
                      {obj.price}
                    </Text>
                  )}
                </View>

                <View style={productModifierStyles().checkboxCalorieContainer}>
                  {showCalories && checkValue(obj.calories) && (
                    <Text
                      accessibilityLabel={`${
                        obj.calories
                      } ${Localized.Labels.calories.toLowerCase()}`}
                      aria-label={`${
                        obj.calories
                      } ${Localized.Labels.calories.toLowerCase()}`}
                      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                      style={{color: Styles.darkColor}}
                    >
                      {`${obj.calories} ${showUnitName}`}
                    </Text>
                  )}
                </View>
              </View>
            </RadioButton>
          </View>
        );
      })}
    </RadioForm>
  );
}

export function checkboxProductModifiers(
  value: ProductModifierValue,
  price: string,
  calories: string,
  modifierValues: string[],
  showUnitName: string,
  showCalories: boolean,
  onPress,
): JSX.Element {
  return (
    <CheckBox
      key={value.id}
      accessible={true}
      accessibilityLabel={`${value.name}, ${price}, ${calories}`}
      aria-label={`${value.name}, ${price}, ${calories}`}
      accessibilityRole="checkbox"
      accessibilityState={{
        checked: modifierValues.includes(value.id),
      }}
      aria-checked={modifierValues.includes(value.id)}
      role="checkbox"
      title={
        <View style={productModifierStyles().checkboxRow}>
          <View style={productModifierStyles().checkboxNameContainer}>
            <Text
              style={{
                marginLeft: Styles.Spacing.m1,
                color: Styles.darkColor,
              }}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            >
              {value.name}
            </Text>
          </View>
          {price !== null && (
            <View style={productModifierStyles().checkboxPriceContainer}>
              <Text
                style={{color: Styles.darkColor}}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              >
                {price}
              </Text>
            </View>
          )}
          {showCalories && checkValue(value.calories) && (
            <View style={productModifierStyles().checkboxCalorieContainer}>
              <Text
                style={{color: Styles.darkColor}}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              >
                {`${value?.calories} ${showUnitName}`}
              </Text>
            </View>
          )}
        </View>
      }
      checked={modifierValues.includes(value.id)}
      size={Styles.Fonts.f2}
      textStyle={[
        productModifierStyles().optionText,
        productModifierStyles().checkBoxText,
      ]}
      containerStyle={productModifierStyles().checkBoxWrapper}
      onPress={() => onPress(value.id)}
      checkedColor={Styles.lightGray}
      uncheckedColor={Styles.lightGray}
    />
  );
}
export function getCounterText(value, FREE_TEXT_MAX_LENGTH): string {
  return `${FREE_TEXT_MAX_LENGTH - (value?.length || 0)} ${
    Localized.Labels.characters_left
  }`;
}

export function recentOrderButtonStyle(): RecentOrdersButtonStylesDescriptor {
  return StyleSheet.create({
    marginStyle: {
      marginTop: 0,
      marginBottom:
        Platform.OS === 'android' ? Styles.Heights.h1 : Styles.Spacing.m2 + 1,
    },
    recentOrderText: {
      color: Styles.black,
      marginLeft: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f2,
      lineHeight: Styles.Fonts.f3,
    },
  });
}

export function checkoutBarUI({
  numItems,
  pickupTime,
  displayLocation,
  onPress,
}: CheckoutBarProps) {
  let formattedLabel = '';
  let topRow = (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
      style={styles.title}
    >
      {Localized.Labels.pickup_at}
    </Text>
  );

  if (pickupTime) {
    const timeLabel = TimeUtils.isDateGreaterThanToday(pickupTime.date)
      ? Localized.Labels.pickup_tomorrow
      : Localized.Labels.pickup_today;

    formattedLabel =
      Localized.Labels.formatString(timeLabel, pickupTime.time) +
      ` ${MenuService.getTimezoneDisplay()}`;

    topRow = (
      <View style={{flexDirection: 'column'}}>
        <View style={styles.row}>
          <Text
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            style={styles.title}
            numberOfLines={1}
            adjustsFontSizeToFit
          >
            {formattedLabel}
          </Text>
        </View>
      </View>
    );
  }

  // Set text for cart item
  const cartItemText = numItems > 1 ? 'items' : 'item';
  const cart = (
    <View style={styles.cart}>
      {numItems > 0 && (
        <Text
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
          style={styles.cartText}
        >
          View
        </Text>
      )}
      <View>
        <FontAwesome5Pro
          name="shopping-cart"
          color={Styles.white}
          size={Styles.Fonts.f4}
          solid
        />
        <View style={styles.badge}>
          {numItems > 0 && (
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              style={styles.badgeText}
            >
              {numItems}
            </Text>
          )}
        </View>
      </View>
      {numItems === 0 && <View style={styles.dummy} />}
    </View>
  );

  return (
    <TouchableOpacity
      accessible={true}
      accessibilityLabel={`${formattedLabel}, ${displayLocation}, View ${numItems} ${cartItemText} in cart`}
      aria-label={`${formattedLabel}, ${displayLocation}, View ${numItems} ${cartItemText} in cart`}
      accessibilityHint="Double tap to view your cart"
      accessibilityRole="button"
      role="button"
      accessibilityState={{
        disabled: numItems === 0 || !onPress,
      }}
      onPress={onPress}
      disabled={numItems === 0 || !onPress}
      style={styles.container}
    >
      <View style={styles.wrapper}>
        <View style={styles.content}>
          {topRow}
          <View style={styles.row}>
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
              style={styles.description}
            >
              {displayLocation}
            </Text>
          </View>
        </View>
        <View style={styles.itemAddedFeedbackView}>
          <ItemAddedFeedback strings={Localized} />
        </View>
      </View>
      {cart}
    </TouchableOpacity>
  );
}

export function requiredModifiersView(
  name: string,
  showCalories?: boolean,
  caloricSection?: JSX.Element,
): JSX.Element {
  return (
    <View style={productModifierStyles().requiredViewStyles}>
      <Text
        accessible={true}
        accessibilityLabel={`${name} section`}
        aria-label={`${name} section`}
        style={productModifierStyles().titleText}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
      >
        {name}
      </Text>
      <View>
        <Text
          accessible={true}
          accessibilityLabel="Selection is required"
          aria-label="Selection is required"
          style={styles.requiredText}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        >
          {Localized.Labels.required}
        </Text>

        <View style={styles.calorieSectionView}>
          {showCalories && caloricSection}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Styles.darkColor,
    flexDirection: 'row',
  },
  wrapper: {
    position: 'relative',
    flex: 3,
    flexDirection: 'column',
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  title: {
    color: Styles.white,
    fontSize: Styles.Fonts.f1,
    fontWeight: '600',
  },
  description: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  row: {
    flexDirection: 'row',
  },
  itemAddedFeedbackView: {
    position: 'absolute',
    top: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    right: Styles.Spacing.m0,
    bottom: Styles.Spacing.m0,
    flexDirection: 'row',
  },
  cart: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    backgroundColor: Styles.positiveColor,
    paddingHorizontal: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2 + PixelRatio.getFontScale() * 1.3,
    color: Styles.positiveColor,
  },
  cartText: {
    fontSize: Styles.Fonts.f1 * 1.1,
    fontWeight: '600',
    paddingRight: Styles.Spacing.m2,
    color: Styles.white,
  },
  badge: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: Styles.Spacing.m0,
    top: Styles.Spacing.m1,
    bottom: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    zIndex: 1,
  },
  badgeText: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    paddingTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m1,
    color: Styles.positiveColor,
  },
  dummy: {
    backgroundColor: Styles.darkColor,
    paddingTop: Styles.Spacing.m1,
    paddingBottom: Styles.Spacing.m3,
    paddingLeft: Styles.Spacing.m1,
    position: 'absolute',
    right: Styles.Spacing.m0,
    top: Styles.Spacing.m0,
    bottom: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    zIndex: 1,
  },
  requiredText: {
    fontSize: Styles.Fonts.f1,
    fontStyle: 'italic',
    color: Styles.dangerColor,
  },
  calorieSectionView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
});

export default {
  customTilesButtonStyles,
  pickupLocationListStyles,
  recentOrderButtonStyle,
  productModifierStyles,
  radioButtonProductModifier,
  checkboxProductModifiers,
  getCounterText,
  checkoutBarUI,
  requiredModifiersView,
};
