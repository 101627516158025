import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
type PersonLightProps = {
  size: number;
};
export default class PersonLight extends React.Component<PersonLightProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        id="PersonLight"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.504 11.928c0 4.373-2.92 7.928-6.504 7.928-3.577 0-6.504-3.555-6.504-7.928C9.496 7.554 12.416 4 16 4c3.584 0 6.504 3.554 6.504 7.928zm-1.951 0c0-3.304-2.036-5.946-4.553-5.946s-4.553 2.642-4.553 5.946c0 3.303 2.036 5.946 4.553 5.946s4.553-2.643 4.553-5.946zm.325 8.159a.985.985 0 011.073-.892c.072 0 7.382.846 7.382 8.912a.983.983 0 01-.975.991H3.642a.983.983 0 01-.975-.991c0-8.066 7.31-8.912 7.382-8.912a.985.985 0 011.073.892.992.992 0 01-.878 1.09c-3.016.38-5.35 2.867-5.58 5.946h22.673c-.211-3.087-2.555-5.585-5.58-5.946a.992.992 0 01-.879-1.09z"
          fill="#22212D"
        />
      </Svg>
    );
  }
}
