import React, {useState} from 'react';
import {
  View,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  PixelRatio,
} from 'react-native';
import Styles from '../Styles';
import type {MenuOption} from 'src/types/MenuOption';
import Settings from 'src/Settings';
import {TextInput, Menu, Text} from 'react-native-paper';
import AVText from './AVText';
import {useComponentSize} from '../Hooks';
import {getDescriber} from './descriptor/DescriptorType';
import ChevronDown from 'src/components/img/svg/ChevronDown';
import CustomTextInput from 'src/components/elements/CustomTextInput';

const maxWidth = Dimensions.get('screen').width - 30;
const maxHeight = Dimensions.get('screen').height * 0.5;
const {getDropDownMenuStyles, getDropDownMenuListStyle} = getDescriber();

type NBRDropdownProps = React.ComponentProps<typeof TextInput> & {
  onSelect: (value: string) => void;
  selectedValue?: string | number;
  options: Array<MenuOption>;
  label: string;
  dropDownHeader?: string;
  defaultLabel?: string;
  flexWidth?: boolean;
  anchorPosition?: string;
  accessibilityState?: string;
  accessibilityRole?: string;
  accessibilityLabel?: string;
  accessibilityValue?: {[key: string]: string};
  onVisibilityChange?: (visible: boolean) => void;
};

const NBRDropdown: React.FC<NBRDropdownProps> = (props) => {
  const [dropdownSize, onLayout] = useComponentSize();
  const [menuOpen, setMenu] = useState(false);
  const onOpen = () => {
    setMenu(true);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(true);
    }
  };

  const onClose = () => {
    setMenu(false);
    if (props.onVisibilityChange) {
      props.onVisibilityChange(false);
    }
  };

  const selectedOption = props.options.find(
    (option) => option.value == props.selectedValue,
  );
  const title = selectedOption?.text || props.defaultLabel || '';
  let baseText;

  if (props.flexWidth) {
    baseText = (
      <AVText
        style={[
          styles.flexWidthBase,
          Styles.Style.font500,
          {color: Styles.darkColor},
        ]}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityRole="text"
        aria-label={`${props.accessibilityLabel}, text`}
      >
        {title}
      </AVText>
    );
  } else {
    baseText = (
      <CustomTextInput
        pointerEvents="none"
        label={props.label}
        placeholder={props.placeholder}
        onChangeText={undefined}
        editable={false}
        value={title}
        style={[styles.txtInput]}
        accessible={true}
        accessibilityLabel={props.accessibilityLabel}
        accessibilityValue={{text: props.value}}
        accessibilityState={{disabled: true}}
        disabled={false}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
        showShadow={true}
      />
    );
  }

  const options = props.options.map(
    (
      item: {text: string; value: string; id: string; image: string},
      index: number,
    ) => {
      return (
        <View key={index}>
          <TouchableOpacity
            accessible={true}
            accessibilityRole="menuitem"
            accessibilityLabel={item.text}
            aria-label={item.text}
            role="menuitem"
            style={[
              getDropDownMenuListStyle(dropdownSize, maxWidth, props, index)[
                'optionContainer'
              ],
              getDropDownMenuListStyle(dropdownSize, maxWidth, props, index)[
                'options'
              ],
            ]}
            onPress={() => {
              props.onSelect(`${item.value}`);
              onClose();
            }}
            key={item.value}
          >
            <Text
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              style={[
                styles.optionText,
                item.value === props.selectedValue && {
                  color: Styles.primaryColor,
                },
              ]}
            >
              {item.text}
            </Text>
          </TouchableOpacity>
        </View>
      );
    },
  );
  return (
    <View
      accessible={true}
      importantForAccessibility={menuOpen ? 'yes' : 'no-hide-descendants'}
      accessibilityElementsHidden={menuOpen}
      accessibilityRole="menu"
      accessibilityLabel={props.accessibilityLabel}
      accessibilityState={{expanded: menuOpen}}
      role="menu"
      aria-label={props.accessibilityLabel}
      aria-expanded={menuOpen}
    >
      <Menu
        visible={menuOpen}
        onDismiss={onClose}
        style={getDropDownMenuStyles(props)['style']}
        contentStyle={getDropDownMenuStyles(props)['contentStyle']}
        anchor={
          <TouchableOpacity
            onLayout={onLayout}
            onPress={onOpen}
            style={[
              {
                color: Styles.darkColor,
                alignContent: 'center',
              },
              props.style,
            ]}
          >
            {baseText}
            <View
              style={[
                props.flexWidth
                  ? styles.flexWidthDropDownIcon
                  : styles.dropDownIcon,
                {bottom: 17},
              ]}
            >
              <ChevronDown />
            </View>
          </TouchableOpacity>
        }
      >
        {options}
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  dropDown: {
    maxHeight: maxHeight,
  },
  dropDownIcon: {
    position: 'absolute',
    right: 8,
    bottom: Styles.Spacing.m2 + PixelRatio.getFontScale() * 7,
    margin: Styles.Spacing.m1,
  },
  flexWidthBase: {
    alignSelf: 'center',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  flexDropDownTitle: {
    alignSelf: 'flex-start',
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.robotoRegular,
    paddingBottom: Styles.Spacing.m1,
  },
  flexWidthContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    textAlignVertical: 'center',
  },
  txtInput: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.black,
    fontWeight: '400',
    height: 56,
    marginTop: 14,
  },
  shadow: {
    elevation: 1,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 0.2,
    borderRadius: 8,
  },
  flexWidthDropDownIcon: {
    margin: Settings.isNewUI() ? Styles.Spacing.m0 : Styles.Spacing.m2,
    alignSelf: 'center',
  },
  option: {
    padding: Styles.Spacing.m2,
    flexDirection: 'row',
    alignItems: 'center',
    width: '65%',
    height: 60,
  },
  optionText: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  base: {
    paddingHorizontal: 0,
  },
  itemImageStyle: {
    marginTop: Styles.Spacing.m1,
    alignItems: 'center',
    height: Styles.Fonts.f3,
    marginRight: Styles.Spacing.m3,
    width: 40,
  },
});
export default NBRDropdown;
