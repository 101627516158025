import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import Settings from 'src/Settings';

type ReferralDetailsIconProps = {
  width?: number;
  height?: number;
  viewBox?: string;
};

function ReferralDetailsIcon({
  width = 54,
  height = 54,
  viewBox = '0 0 44 41 ',
}: ReferralDetailsIconProps) {
  return (
    <>
      {Settings.is365Pay() ? (
        <Svg width={width} height={height} viewBox={viewBox} fill="none">
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.708 27.253a5.805 5.805 0 00-5.54 7.546l-1.582.498a7.463 7.463 0 0111.109-8.548l.988-.989.663.664v2.985h-2.985l-.663-.663.786-.787a5.777 5.777 0 00-2.777-.706zm5.805 5.804c0-.608-.093-1.192-.266-1.741l1.583-.498a7.463 7.463 0 01-11.109 8.548l-.988.989-.664-.664v-2.985h2.986l.663.663-.787.787a5.805 5.805 0 008.582-5.1v.001zm-6.8-2.985h.332v-.995h1.327v.995h.332c.733 0 1.327.594 1.327 1.327v.332h-1.327v-.332h-1.99v.617l2.25.45c.62.124 1.067.668 1.067 1.3v.95c0 .733-.594 1.327-1.327 1.327h-.332v.995h-1.327v-.995h-.332a1.327 1.327 0 01-1.326-1.327v-.332h1.326v.332h1.99v-.948l-2.25-.45a1.326 1.326 0 01-1.066-1.3V31.4c0-.733.594-1.327 1.326-1.327z"
            fill="#066DFF"
          />
          <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.595.086c-5.566 0-10.08 4.513-10.08 10.079 0 5.567 4.514 10.08 10.08 10.08 5.567 0 10.08-4.514 10.08-10.08S28.161.085 22.594.085zm-1.213 22.152c-7.035 0-13.649 1.79-19.39 4.935l-1.11.603v9.845h25.924l-.353-3.178c-.235-2.115.178-4.271 1.275-6.096a9.748 9.748 0 013.191-3.248l.944-.59c-1.827-.75-6.646-2.275-10.481-2.271z"
            fill="#066DFF"
          />
        </Svg>
      ) : (
        <Svg width={width} height={height} viewBox={viewBox} fill="none">
          <Path
            fillRule="evenodd"
            d="M35.708 27.253a5.805 5.805 0 00-5.54 7.546l-1.582.498a7.463 7.463 0 0111.109-8.548l.988-.989.663.664v2.985h-2.985l-.663-.663.786-.787a5.777 5.777 0 00-2.777-.706zm5.805 5.804c0-.608-.093-1.192-.266-1.741l1.583-.498a7.463 7.463 0 01-11.109 8.548l-.988.989-.664-.664v-2.985h2.986l.663.663-.787.787a5.805 5.805 0 008.582-5.1v.001zm-6.8-2.985h.332v-.995h1.327v.995h.332c.733 0 1.327.594 1.327 1.327v.332h-1.327v-.332h-1.99v.617l2.25.45c.62.124 1.067.668 1.067 1.3v.95c0 .733-.594 1.327-1.327 1.327h-.332v.995h-1.327v-.995h-.332a1.327 1.327 0 01-1.326-1.327v-.332h1.326v.332h1.99v-.948l-2.25-.45a1.326 1.326 0 01-1.066-1.3V31.4c0-.733.594-1.327 1.326-1.327z"
            fill="#003359"
            clipRule="evenodd"
          />
          <Path
            clipRule="evenodd"
            d="M22.595.086c-5.566 0-10.08 4.513-10.08 10.079 0 5.567 4.514 10.08 10.08 10.08 5.567 0 10.08-4.514 10.08-10.08S28.161.085 22.594.085zm-1.213 22.152c-7.035 0-13.649 1.79-19.39 4.935l-1.11.603v9.845h25.924l-.353-3.178c-.235-2.115.178-4.271 1.275-6.096a9.748 9.748 0 013.191-3.248l.944-.59c-1.827-.75-6.646-2.275-10.481-2.271z"
            fillRule="evenodd"
            fill="#003359"
          />
        </Svg>
      )}
    </>
  );
}

export default ReferralDetailsIcon;
