/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 */
import * as React from 'react';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import type {NavigationState} from '@react-navigation/native';
import OneSignal from 'src/nativeModules/OneSignal';
import NavActions from 'src/actions/NavActions';
import AppNavigator from 'src/navigators/AppNavigator';
import AppRoutes from 'src/AppRoutes';
import type {RootRoutes} from 'src/AppRoutes';
import Localized from 'src/constants/AppStrings';
import Events from 'src/logging/Events';
import ModalSpinner from './dialogs/ModalSpinner';
import Styles from './Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import ScreenContext from './ScreenContext';
import type {NestedPushAfterLogin, ScreenProps} from 'src/types/Screen';
import {alertError, confirm} from './helpers/AlertHelper';
import {AccountFundingScreenProps} from './screens/funding/AccountFundingScreen';
import {getDescriber} from './descriptor/DescriptorType';
import {GestureHandlerRootView} from 'react-native-gesture-handler';

import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/fr-ca';
import 'moment/locale/fr-ch';
import 'moment/locale/it';
import 'moment/locale/it-ch';
import 'moment/locale/en-gb';
import 'moment/locale/en-au';
import 'moment/locale/en-ca';
import 'moment/locale/en-ie';
import 'moment/locale/es';
import 'moment/locale/es-us';
import 'moment/locale/nl';
import 'moment/locale/nl-be';
import 'moment/locale/de';
import 'moment/locale/de-ch';
import 'moment/locale/sv';
import 'moment/locale/da';
import 'moment/locale/nb';
import 'moment/locale/fi';

import {
  AppState,
  BackHandler,
  Platform,
  StatusBar,
  NativeEventSubscription,
} from 'react-native';
import PersistentStore from 'src/services/PersistentStoreService';
import Settings from 'src/Settings';
import DBHelper from 'src/services/DBService';
import SyncHelper from 'src/services/SyncService';
import AccountStore from 'src/stores/AccountStore';
import {filterPositions} from './screens/inbox/InboxScreen';
import {connect} from 'react-redux';
import {RootState, AppDispatch, store} from 'src/redux/store';
import {hideSpinner, ISpinner} from 'src/redux/slices/screenSlice';
import FirebaseAnalytic from '../nativeModules/FirebaseAnalytic';
import SuccessModal from 'src/components/elements/SuccessModal';
import {PaymentCredentials} from '../types/serverTypes/Account';
import {OSNotification} from 'react-native-onesignal';
import BuildTypeConstants from 'src/constants/BuildTypeConstants';
import AppLifecycleTracker from 'src/components/utils/AppLifecycleTracker';
import TagManager from 'src/components/utils/TagManager';

moment.locale([Settings.getLocale(), 'en']);
type AppStateType = {
  spinner: boolean;
  spinnerText: string;
  spinnerCancel: boolean;
  spinnerCallback?: () => void;
  spinnerCancelText: string;
  screenProps: ScreenProps;
  rootRoute: RootRoutes;
  showSuccessModal: boolean;
  modalDescription: string;
  modalDescriptionLine: number;
  modalIsAcceptSnack: boolean;
  modalIsMultipleBtn: boolean;
  modalHeaderTitle: string;
  modalButtonTitle: string;
  modalSeondaryButtonTitle?: string;
  modalButtonColor?: string;
  buttonCallback?: () => void;
  secondaryCustomAction?: () => void;
  isOnboardingError?: boolean;
};

const MessageType = {
  NewOffers: 'NewOffers',
  OfferUnredeemed: 'OfferUnredeemed',
  OfferRedeemed: 'OfferRedeemed',
};

interface IProps {
  paymentCredentials: PaymentCredentials;
  canAddFunds: boolean;
  spinner: ISpinner;
}
class App extends React.Component<IProps, AppStateType> {
  handleAppStateChangeSubscription: NativeEventSubscription;
  static setCurrentLockTime() {
    const lockRequiredTime = new Date().getTime() + 10 * 60 * 1000;
    PersistentStore.setLockTime(lockRequiredTime);
  }

  static getActiveRoute(
    navigationState: NavigationState,
  ): Record<string, string> | null {
    if (!navigationState) {
      return null;
    }

    const route = navigationState.routes[navigationState.index];

    if (route.state) {
      return App.getActiveRoute(route.state as NavigationState);
    }

    return route as unknown as Record<string, string>;
  }

  appState: string;
  showSpinnerTimeout: NodeJS.Timeout | null;
  backHandler: NativeEventSubscription;
  currentRoute: {[key: string]: string} | null;
  nestedPushAfterLogin: NestedPushAfterLogin | null = null;

  constructor(props: IProps) {
    super(props);
    this.appState = 'active';
    this.currentRoute = null;
    this.onSync = this.onSync.bind(this);
    this.showSpinner = this.showSpinner.bind(this);
    this.hideSpinner = this.hideSpinner.bind(this);
    this._handleAppStateChange = this._handleAppStateChange.bind(this);
    this.handleActiveState = this.handleActiveState.bind(this);
    this.onPasscodeScreen = this.onPasscodeScreen.bind(this);
    this.onNotificationReceived = this.onNotificationReceived.bind(this);
    this.onNotificationOpened = this.onNotificationOpened.bind(this);
    this.checkFirstLaunch = this.checkFirstLaunch.bind(this);
    this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
    this.changeRootRoute = this.changeRootRoute.bind(this);
    this.resetFilterType = this.resetFilterType.bind(this);
    this.navStateChanged = this.navStateChanged.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.hideModalAndNavigate = this.hideModalAndNavigate.bind(this);
    this.state = {
      spinner: false,
      spinnerCallback: undefined,
      spinnerCancel: false,
      spinnerCancelText: '',
      spinnerText: '',
      showSuccessModal: false,
      modalDescription: '',
      modalDescriptionLine: 1,
      modalIsAcceptSnack: false,
      modalIsMultipleBtn: false,
      modalHeaderTitle: '',
      modalButtonTitle: '',
      modalSeondaryButtonTitle: '',
      modalButtonColor: '',
      screenProps: {
        state: {},
        actions: {
          showSpinner: this.showSpinner,
          hideSpinner: this.hideSpinner,
          showModal: this.showModal,
          hideModal: this.hideModal,
          hideModalAndNavigate: this.hideModalAndNavigate,
          changeRootRoute: this.changeRootRoute,
          resetFilterType: this.resetFilterType,
          navigateToFunding: (
            isConnected: boolean,
            params?: AccountFundingScreenProps,
          ) => {
            const processor =
              Settings.processors[this.props.paymentCredentials.type];
            const canAddFunds = this.props.canAddFunds;
            if (canAddFunds && processor && isConnected) {
              NavActions.navigate(AppRoutes.MyModal, params);
            } else if (!processor) {
              alertError(
                Localized.Labels.account_instructions,
                '',
                undefined,
                Localized.Errors.funding_unavailable,
              );
            } else if (!canAddFunds) {
              confirm(
                Localized.Labels.in_order_to_reload_your_account,
                () => {
                  this.navigateToWalletScreen();
                },
                undefined,
                Localized.Labels.no_payment_method,
                Localized.Buttons.cancel,
                Localized.Buttons.go_to_payments,
              );
            }

            return;
          },
        },
      },
      rootRoute: AppRoutes.Loading,
    };
  }

  navigateToWalletScreen() {
    if (Settings.isNewUI()) {
      if (Settings.isRevolve()) {
        NavActions.push(AppRoutes.Cards, {hideBackArrow: false});
      } else if (Settings.isCanteen()) {
        NavActions.push(AppRoutes.WalletTabHome);
      } else {
        NavActions.push(AppRoutes.PDCards, {
          hideBackArrow: false,
        });
      }
    } else {
      NavActions.nestedPush(
        [AppRoutes.Main, AppRoutes.Settings],
        AppRoutes.Cards,
      );
    }
  }
  async componentDidMount() {
    FirebaseAnalytic.trackEvent('componentDidMount', 'App', {
      ...this.props,
      ...this.state,
    });

    let env = store.getState().environment?.env || 'PROD';
    env = env === 'QA' ? env : 'PROD';
    const OneSignalAppId = Settings.oneSignalAppId[env];
    OneSignal.setAppId(OneSignalAppId);
    Events.NotificationEvent.trackOneSignalId(OneSignalAppId);

    if (Platform.OS === 'ios') {
      // maybe move this to only happen after logging in?
      OneSignal.promptForPushNotificationsWithUserResponse((result) => {
        Events.NotificationEvent.trackUserResponseToNotificationPrompt(result);
        if (!result) {
          Events.PermissionDenied.trackEvent(
            'ios.permission.NOTIFICATIONS',
            'Denied',
          );
        }
      });
    }

    OneSignal.setNotificationWillShowInForegroundHandler(
      (notificationReceivedEvent) => {
        const notification = notificationReceivedEvent.getNotification();
        Events.NotificationReceived.trackEvent(
          notification as {
            payload?: {
              additionalData?: {
                fetchBalance?: boolean;
                transactionId?: boolean;
                transactionType?: boolean;
              };
              title?: string;
              body?: string;
            };
          },
        );

        notificationReceivedEvent.complete(notification);
        this.onNotificationReceived(notification);
      },
    );

    this.checkFirstLaunch();
    await DBHelper.initDb();
    SyncHelper.addOnSyncListener(this.onSync);
    SyncHelper.addOnSyncFinishedListener(this.hideSpinner);

    this.handleAppStateChangeSubscription = AppState.addEventListener(
      'change',
      this._handleAppStateChange,
    );

    this.handleActiveState();
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      const handled =
        this.currentRoute &&
        this.currentRoute.routeName !== AppRoutes.PrivacyPolicy &&
        NavActions.pop();
      return handled;
    });

    try {
      await AccountStore.waitForData();
    } catch (error) {

    }

    this.checkIfLoggedIn();

    OneSignal.setNotificationOpenedHandler((notification) => {
      Events.NotificationEvent.trackNotification(notification);
      this.onNotificationOpened(notification);
    });

    const wasForceClosed =
      await AppLifecycleTracker.wasAppForceClosedWithCart();
    if (wasForceClosed) {
      TagManager.updateDynamicTags('cart_abandoned');
      AppLifecycleTracker.clearCartState();
    }

    AppLifecycleTracker.startTracking();
  }

  componentWillUnmount() {
    this.handleAppStateChangeSubscription?.remove();

    if (!this.onPasscodeScreen()) {
      App.setCurrentLockTime();
    }

    if (this.backHandler) {
      this.backHandler.remove();
    }

    DBHelper.close();

    OneSignal.clearHandlers();
    if (this.showSpinnerTimeout) {
      clearTimeout(this.showSpinnerTimeout);
    }
  }

  checkIfLoggedIn() {
    const accountId = AccountStore.getAccountId();
    const firstName = AccountStore.getFirstName();

    FirebaseAnalytic.trackEvent('checkIfLoggedIn', 'App', {
      ...this.props,
      ...this.state,
      accountId,
      firstName,
    });

    if (accountId && accountId !== '-1' && firstName) {
      if (Platform.OS === 'web') {
        this.changeRootRoute(AppRoutes.App);
        NavActions.replace(AppRoutes.MainConsumer);
      } else {
        this.changeRootRoute(AppRoutes.AppProductTour);
      }
    } else {
      if (accountId) {
        ActionsFactory.getAccountActions().logout();
      }
      this.changeRootRoute(AppRoutes.Login);
    }
  }

  async checkFirstLaunch() {
    try {
      const hasLaunched = await PersistentStore.hasLaunched();
      FirebaseAnalytic.trackEvent('checkFirstLaunch', 'App', {
        ...this.props,
        ...this.state,
        hasLaunched,
      });

      if (!hasLaunched) {
        Events?.FirstLaunch?.trackEvent();
        PersistentStore.setHasLaunched();
      }
    } catch (error) {

    }
  }

  navStateChanged(currentState: NavigationState) {
    const prevRoute = this.currentRoute;
    this.currentRoute = App.getActiveRoute(currentState);
    FirebaseAnalytic.trackEvent('navStateChanged', 'App', {
      ...this.props,
      ...this.state,
      currentState,
    });
    if (
      this.currentRoute &&
      prevRoute &&
      this.currentRoute.name !== prevRoute.name
    ) {
      Events.ScreenView.trackEvent(this.currentRoute.name);

      if (
        (this.currentRoute?.name === AppRoutes.Promotions ||
          this.currentRoute?.name === AppRoutes.NewHome) &&
        this.nestedPushAfterLogin
      ) {
        NavActions.nestedPush(
          this.nestedPushAfterLogin.nestedNames,
          this.nestedPushAfterLogin.name,
          this.nestedPushAfterLogin.params,
        );
        this.nestedPushAfterLogin = null;
      }
    }
  }

  onSync(message?: string) {
    const isDebug = AccountStore.isDebug();
    FirebaseAnalytic.trackEvent('navStateChanged', 'App', {
      ...this.props,
      ...this.state,
      message,
      isDebug,
    });
    if (isDebug) {
      this.showSpinner(
        message ?? '',
        true,
        function () {
          return;
        },
        Localized.Labels.dismiss,
      );
    }
  }

  onPasscodeScreen(): boolean {
    return this.state.rootRoute === AppRoutes.Passcode;
  }

  onNotificationReceived(notification: OSNotification) {
    Events.NotificationReceived.trackEvent(
      notification as {
        payload?: {
          additionalData?: {
            fetchBalance?: boolean;
            transactionId?: boolean;
            transactionType?: boolean;
          };
          title?: string;
          body?: string;
        };
      },
    );
    const accountId = AccountStore.getAccountId();
    let data: {balance?: number} = {};

    const snackData = notification.additionalData as Record<string, string>;

    if (notification && notification.additionalData) {
      data = notification.additionalData;
    }

    if (accountId && accountId !== '-1') {
      try {
        ActionsFactory.getAccountActions().reloadConsumerData({
          accountId,
          accountBalanceId: AccountStore.getAccountBalanceId(),
          email: AccountStore.getEmail(),
          newBalance: data.balance,
        });
      } catch (error) {

      }
    }
    FirebaseAnalytic.trackEvent('onNotificationReceived', 'App', {
      ...this.props,
      ...this.state,
      data,
      notification,
      accountId,
    });
  }

  async onNotificationOpened(openResult: {notification: OSNotification}) {
    const accountId = AccountStore.getAccountId();
    FirebaseAnalytic.trackEvent('onNotificationOpened', 'App', {
      ...this.props,
      ...this.state,
      accountId,
      openResult,
    });
    if (
      openResult.notification &&
      openResult.notification.additionalData &&
      accountId &&
      accountId !== '-1'
    ) {
      const data = openResult.notification.additionalData as Record<
        string,
        string
      >;

      if (data.transactionId) {
        try {
          await ActionsFactory.getAccountActions().loadPurchaseHistory(
            accountId,
            1,
          );
          NavActions.navigate(AppRoutes.PurchaseHistoryDetail, {
            transactionId: data.transactionId,
            transactionType: data.transactionType,
          });
          Events.NotificationReceived.trackEvent(
            openResult as {
              payload?: {
                additionalData?: {
                  fetchBalance?: boolean;
                  transactionId?: boolean;
                  transactionType?: boolean;
                };
                title?: string;
                body?: string;
              };
            },
          );
        } catch (e) {

        }
      } else if (data.snackId && data.locationId) {
        if (['revolve', 'default'].indexOf(Settings.buildType) > -1) {
          AccountStore.setOTSNotification(data.snackId, data.locationId);
          setTimeout(() => NavActions.navigate(AppRoutes.Home), 500);
        }
      } else if (data.messageType) {
        // Handling Connect & Pay deep-links
        switch (data.messageType) {
          case MessageType.NewOffers:
            NavActions.navigate(AppRoutes.Inbox);
            break;
          case MessageType.OfferUnredeemed:
            const {screenProps} = this.state;
            if (!Settings.isRevolve()) {
              screenProps.state.filterType = filterPositions.active;
            }
            this.setState({screenProps});
            NavActions.navigate(AppRoutes.Inbox);
            break;
          case MessageType.OfferRedeemed:
            NavActions.navigate(AppRoutes.PurchaseHistory);
            break;
        }
      }
    }
  }

  _handleAppStateChange(currentAppState: string) {
    FirebaseAnalytic.trackEvent('_handleAppStateChange', 'App', {
      ...this.props,
      ...this.state,
      currentAppState,
    });
    if (currentAppState !== this.appState) {
      this.appState = currentAppState;

      if (currentAppState === 'active') {
        this.handleActiveState();
      } else if (!this.onPasscodeScreen()) {
        App.setCurrentLockTime();
      }
    }
  }

  changeRootRoute(
    route: RootRoutes,
    initialTabRoute?: AppRoutes,
    nestedPushAfterLogin?: NestedPushAfterLogin | null,
  ) {
    FirebaseAnalytic.trackEvent('changeRootRoute', 'App', {
      ...this.props,
      ...this.state,
      route,
      initialTabRoute,
      nestedPushAfterLogin,
    });
    this.nestedPushAfterLogin = nestedPushAfterLogin ?? null;
    const {screenProps} = this.state;
    screenProps.state.initialTabRoute = initialTabRoute;
    this.setState({
      rootRoute: route,
      screenProps,
    });
  }

  resetFilterType() {
    const {screenProps} = this.state;
    if (!Settings.isRevolve()) {
      screenProps.state.filterType = filterPositions.all;
    }
    this.setState({screenProps}, () => {
      FirebaseAnalytic.trackEvent('resetFilterType', 'App', {
        ...this.props,
        ...this.state,
      });
    });
  }

  showSpinner(
    spinnerText = '',
    cancel = false,
    cancelCallback: () => void,
    cancelText: string = Localized.Buttons.cancel,
  ) {
    this.setState(
      {
        spinner: true,
        spinnerCancel: cancel,
        spinnerCallback: cancelCallback,
        spinnerCancelText: cancelText,
        spinnerText,
      },
      () => {
        FirebaseAnalytic.trackEvent('showSpinner', 'App', {
          ...this.props,
          ...this.state,
        });
      },
    );

    if (this.showSpinnerTimeout) {
      clearTimeout(this.showSpinnerTimeout);
    }

    this.showSpinnerTimeout = setTimeout(() => {
      this.hideSpinner();
    }, 60 * 1000);
  }

  hideSpinner() {
    this.setState(
      {
        spinner: false,
        spinnerText: '',
        spinnerCancel: false,
        spinnerCallback: undefined,
        spinnerCancelText: '',
      },
      () => {
        FirebaseAnalytic.trackEvent('hideSpinner', 'App', {
          ...this.props,
          ...this.state,
        });
      },
    );

    if (this.showSpinnerTimeout) {
      clearTimeout(this.showSpinnerTimeout);
    }

    this.showSpinnerTimeout = null;
  }
  showModal(
    description: string,
    headerTitle: string,
    buttonTitle: string,
    buttonColor: string = Styles.primaryColor,
    descLines = 1,
    isAcceptSnack = false,
    isMultipleBtn = false,
    seondaryButtonTitle: string,
    buttonCallback?: () => void,
    secondaryCustomAction?: () => void,
    isOnboardingError?: boolean,
  ) {
    this.setState({
      showSuccessModal: true,
      modalDescription: description,
      modalDescriptionLine: descLines || 1,
      modalIsAcceptSnack: isAcceptSnack,
      modalIsMultipleBtn: isMultipleBtn,
      modalHeaderTitle: headerTitle,
      modalButtonTitle: buttonTitle,
      modalSeondaryButtonTitle: seondaryButtonTitle,
      modalButtonColor: buttonColor,
      buttonCallback: buttonCallback,
      secondaryCustomAction: secondaryCustomAction,
      isOnboardingError: isOnboardingError,
    });
  }
  hideModal() {
    this.setState({
      showSuccessModal: false,
    });
  }
  hideModalAndNavigate() {
    this.setState(
      {
        showSuccessModal: false,
      },
      () => {
        NavActions.pop();
      },
    );
  }

  async handleActiveState() {
    try {
      const currentRouteName = this.currentRoute
        ? this.currentRoute.routeName
        : 'No Route';

      if (
        currentRouteName === AppRoutes.CreditCardAdd ||
        currentRouteName === AppRoutes.AccountSetup
      ) {
        return;
      }

      SyncHelper.performSyncWithServer();
      const accountId = await PersistentStore.getAccountId();
      FirebaseAnalytic.trackEvent('handleActiveState', 'App', {
        ...this.props,
        ...this.state,
        accountId,
      });

      if (accountId && accountId !== '-1') {
        try {
          const [lockRequiredTime] = await Promise.all([
            PersistentStore.getLockTime(),
            AccountStore.waitForData(),
          ]);
          const currentTime = new Date().getTime();

          if (
            lockRequiredTime &&
            AccountStore.getPinRequired() &&
            currentTime > lockRequiredTime
          ) {
            this.setState({
              spinner: false,
              rootRoute: AppRoutes.Passcode,
            });
          } else {
            App.setCurrentLockTime();
          }
        } catch (error) {

        }
      }
    } catch (error) {

    }
  }

  render() {
    const {spinner} = this.props;
    const {statusBarStyleDescriptor, statusBarBackgroundColorDescriptor} =
      getDescriber();
    return (
      <GestureHandlerRootView style={Styles.Style.flex}>
        <StatusBar
          barStyle={statusBarStyleDescriptor()}
          backgroundColor={statusBarBackgroundColorDescriptor()}
          translucent={Settings.buildType === BuildTypeConstants.default}
        />
        <SafeAreaProvider>
          <ScreenContext.Provider value={this.state.screenProps}>
            <AppNavigator
              onStateChange={this.navStateChanged}
              rootRoute={this.state.rootRoute}
            />
          </ScreenContext.Provider>
        </SafeAreaProvider>
        {this.state.spinner && (
          <ModalSpinner
            text={this.state.spinnerText}
            cancel={this.state.spinnerCancel}
            cancelText={this.state.spinnerCancelText}
            callback={() => {
              this.hideSpinner();

              if (this.state.spinnerCallback) {
                this.state.spinnerCallback();
              }
            }}
          />
        )}
        {spinner.display && (
          <ModalSpinner
            text={spinner.spinnerText || ''}
            cancel={spinner.spinnerCancel || false}
            cancelText={spinner.spinnerCancelText || ''}
            callback={() => {
              hideSpinner();

              if (spinner.spinnerCallback) {
                spinner.spinnerCallback();
              }
            }}
          />
        )}
        {this.state.showSuccessModal && (
          <SuccessModal
            isModalVisible={this.state.showSuccessModal}
            headerTitle={this.state.modalHeaderTitle}
            description={this.state.modalDescription}
            buttonTitle={this.state.modalButtonTitle}
            seondaryButtonTitle={this.state.modalSeondaryButtonTitle}
            buttonColor={this.state.modalButtonColor}
            descLines={this.state.modalDescriptionLine}
            isAcceptSnack={this.state.modalIsAcceptSnack}
            isMultipleBtn={this.state.modalIsMultipleBtn}
            secondaryCustomAction={this.state.secondaryCustomAction}
            isOnboardingError={this.state.isOnboardingError}
            onModalClose={() => {
              this.hideModal();
              if (this.state.buttonCallback) {
                this.state.buttonCallback();
              }
            }}
            onModalCloseAndNavigate={() => {
              this.hideModalAndNavigate();
              if (this.state.buttonCallback) {
                this.state.buttonCallback();
              }
            }}
          />
        )}
      </GestureHandlerRootView>
    );
  }
}

export default connect(
  (state: RootState) => ({
    spinner: state.screen.spinner,
    paymentCredentials: state.account.paymentCredentials,
    canAddFunds: state.account.canAddFunds,
  }),
  (dispatch: AppDispatch) => ({
    hideSpinner: () => dispatch(hideSpinner()),
  }),
)(App);
