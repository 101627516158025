import React, {useContext} from 'react';
import {StyleSheet, View, ScrollView, PixelRatio} from 'react-native';
import Styles from '../../../Styles';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import type {PurchaseDetailType} from 'src/types/TransactionDetail';
import AVText from '../../AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import Util from 'src/Util';
import DashedLine from 'react-native-dashed-line';
import {PICKUP_LOCATIONS_KEYWORD} from 'src/stores/TransactionStore';
import SaleDetail from 'src/components/elements/account/SaleDetail';
import {getTaxDescription} from 'src/services/TranslationService';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import ActionsFactory from 'src/actions/ActionsFactory';
import {alertError} from 'src/components/helpers/AlertHelper';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import ScreenContext from 'src/components/ScreenContext';
import uuid from 'src/nativeModules/UUID';
import {formatCurrency} from 'src/components/helpers/HistoryHelper';
import SubtotalComponent from 'src/components/elements/account/newHistoryUI/SubTotalComponent';
import BottleDepositComponent from 'src/components/elements/account/newHistoryUI/BottleDeposit';
import TaxInfoComponent from 'src/components/elements/account/newHistoryUI/TaxInfoComponent';
import DiscountInfoComponent from 'src/components/elements/account/newHistoryUI/DiscountInfoComponent';
import Settings from 'src/Settings';
import CrashlyticsEvents from 'src/logging/Crashlytics';

import {getDescriber} from '../descriptor/DescriptorType';
type PurchaseDetailProps = {
  purchaseDetail: PurchaseDetailType;
  currency?: string;
  transactionId: string;
  transactionType: string;
  Balance: number;
};

const PurchaseDetail: React.FC<PurchaseDetailProps> = ({
  purchaseDetail,
  currency,
  transactionId,
  transactionType,
  Balance,
}) => {
  const context = useContext(ScreenContext);
  const subtotal = purchaseDetail?.Items?.reduce(
    (a, b) => parseFloat(a + (b.Price || '')),
    0,
  );

  const items = purchaseDetail?.Items?.filter(
    (item) => !item.Name?.toLowerCase().includes(PICKUP_LOCATIONS_KEYWORD),
  );
  const formattedTransDate = Util.formatDate(
    purchaseDetail?.DateString,
    getDescriber().PurchaseDetailDateFormatStyleDescriptor(),
  );
  const locationAddress = Util.getLocationAddress({
    address: purchaseDetail?.LocationAddress,
    city: purchaseDetail?.LocationCity,
    state: purchaseDetail?.LocationState,
  });

  /** Function to handle sending email receipt */
  const handleEmailReceipt = async () => {
    context.actions.showSpinner();
    try {
      const response = await ActionsFactory.getAccountActions().emailReceipt(
        AccountStore.getEmail(),
        transactionId,
        transactionType,
        AccountStore.getRegion(),
        Balance ?? 0,
      );
      context.actions.hideSpinner();
      if (response.statuscode === 200) {
        FirebaseAnalytic.trackEvent(
          'handleEmailReceipt',
          'HistoryDetailScreen',
          {
            purchaseDetail,
            currency,
            transactionId,
            transactionType,
            Balance,
          },
        );
        context.actions.showModal(
          Localized.Labels.an_email_was_sent_to_your_inbox,
          Localized.Success.success,
          Localized.Buttons.okay,
          Styles.darkGreenRevolve,
        );
      } else {
        throw new Error('Error sending email receipt');
      }
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      alertError(Localized.Errors.error_emailing_receipt, guid);
      CrashlyticsEvents.log(
        'Exception',
        'HistoryDetailScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'HistoryDetailScreen:HandleEmailReceipt',
        generateErrorMessage(error),
        guid,
      );
    }
  };

  const getTaxLabel = (label: string, number?: number): string => {
    return getTaxDescription(Localized, label, number);
  };

  const taxes = [
    {label: purchaseDetail.Tax1Label, amount: purchaseDetail.Tax1},
    {label: purchaseDetail.Tax2Label, amount: purchaseDetail.Tax2},
    {label: purchaseDetail.Tax3Label, amount: purchaseDetail.Tax3},
    {label: purchaseDetail.Tax4Label, amount: purchaseDetail.Tax4},
  ];

  // Filter out taxes that don't have a valid amount
  const validTaxes = taxes.filter((tax) => tax.label && (tax.amount || 0) > 0);

  const loyaltyPoints = Number(purchaseDetail?.Points);

  return (
    <View style={styles.container}>
      {purchaseDetail && purchaseDetail?.Id ? (
        <>
          <ScrollView style={styles.purchaseContainer}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`${Localized.Labels.date} ${formattedTransDate}`}
              aria-label={`${Localized.Labels.date} ${formattedTransDate}`}
              style={getDescriber().PurchaseDetailFormateedDateStyleDescriptor()}
            >
              {formattedTransDate}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`${Localized.Labels.transaction_id}`}
              aria-label={`${Localized.Labels.transaction_id}`}
              style={getDescriber().PurchaseDetailIdStyleDescriptor()}
            >
              {purchaseDetail?.Id}
            </AVText>
            <View style={styles.loactionDetails}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessible={true}
                accessibilityLabel={`${purchaseDetail?.Location}`}
                aria-label={`${purchaseDetail?.Location}`}
                style={styles.locationName}
              >
                {purchaseDetail?.Location}
              </AVText>
              {locationAddress && (
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  accessible={true}
                  accessibilityLabel={`${purchaseDetail?.LocationAddress} ${purchaseDetail?.LocationCity}, ${purchaseDetail?.LocationState}`}
                  aria-label={`${purchaseDetail?.LocationAddress} ${purchaseDetail?.LocationCity}, ${purchaseDetail?.LocationState}`}
                  style={styles.locationAddress}
                >
                  {locationAddress}
                </AVText>
              )}

              {!isNaN(loyaltyPoints) &&
                loyaltyPoints > 0 &&
                !Settings.isRevolve() && (
                  <AVText
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                    accessible={true}
                    accessibilityLabel={`${purchaseDetail?.Points}`}
                    aria-label={`${purchaseDetail?.Points}`}
                    style={styles.pointsText}
                  >
                    {`${purchaseDetail?.Points} ${Localized.Labels.points_earned}`}
                  </AVText>
                )}
            </View>
            <DashedLine
              dashLength={PixelRatio.roundToNearestPixel(5)}
              dashGap={PixelRatio.roundToNearestPixel(
                Settings.isRevolve() ? 1 : 4,
              )}
              dashColor={Styles.inActiveDotColor}
              dashThickness={PixelRatio.roundToNearestPixel(1)}
              style={[styles.dashStyle]}
            />
            <>
              {items &&
                items.map((item, index) => {
                  return (
                    <View key={index}>
                      <SaleDetail detail={item} currency={currency} />
                    </View>
                  );
                })}
            </>
            <DashedLine
              dashThickness={PixelRatio.roundToNearestPixel(1)}
              dashGap={PixelRatio.roundToNearestPixel(
                Settings.isRevolve() ? 1 : 4,
              )}
              dashColor={Styles.inActiveDotColor}
              dashLength={PixelRatio.roundToNearestPixel(5)}
              style={[styles.dashStyle, {marginBottom: Styles.Spacing.m2}]}
            />
            <SubtotalComponent subtotal={subtotal} currency={currency} />
            <BottleDepositComponent
              depositAmount={purchaseDetail.Deposit}
              currency={currency}
              bottleDepositLabel={Localized.Labels.deposit}
            />
            {validTaxes.length > 0 ? (
              validTaxes.map((tax, index) => (
                <TaxInfoComponent
                  key={index}
                  taxAmount={tax.amount || 0}
                  taxLabel={getTaxLabel(tax.label || '', index)}
                  currency={currency}
                />
              ))
            ) : (
              <TaxInfoComponent
                taxAmount={purchaseDetail?.Tax}
                taxLabel={Localized.Labels.tax}
                currency={currency}
              />
            )}
            <DiscountInfoComponent
              payments={purchaseDetail.Payments}
              currency={currency}
            />

            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(
                Settings.isRevolve() ? 1 : 4,
              )}
              dashLength={PixelRatio.roundToNearestPixel(5)}
              dashThickness={PixelRatio.roundToNearestPixel(1)}
              style={[styles.dashStyle, {marginTop: Styles.Spacing.m2}]}
              dashColor={Styles.inActiveDotColor}
            />
            <View style={[Styles.Style.revolveRow, styles.summary]}>
              <AVText
                accessible={true}
                accessibilityLabel={Localized.Labels.total}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                aria-label={Localized.Labels.total}
                style={styles.boldLabel}
              >
                {Localized.Labels.total}
              </AVText>

              <AVFormattedCurrency
                {...formatCurrency(
                  purchaseDetail.Amount,
                  `$${purchaseDetail.Amount}`,
                  currency,
                )}
                style={styles.boldLabel}
              />
            </View>
          </ScrollView>
          <RoundedButton
            buttonType={ButtonType.action}
            containerStyle={styles.buttonContainer}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            accessible={true}
            accessibilityLabel={Localized.Buttons.email_receipts}
            accessibilityRole="button"
            aria-label={Localized.Buttons.email_receipts}
            role="button"
            onPress={handleEmailReceipt}
            text={Localized.Buttons.email_receipts}
            color={Styles.darkGreenRevolve}
            textStyle={[
              styles.buttonText,
              Settings.isRevolve() && {textTransform: 'uppercase'},
            ]}
          />
        </>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Styles.tabBarBackgroundColor,
  },
  purchaseContainer: {
    marginTop: Styles.Heights.h2 + 4,
    marginBottom: Styles.Spacing.m5,
    marginLeft: Styles.Spacing.m3,
    flex: 1,
    marginRight: Styles.Heights.h2 + 4,
  },
  date: {
    fontSize: Styles.Fonts.f8,
    color: Styles.darkColor,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  transIdText: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m1,
    marginBottom: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  loactionDetails: {
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m3,
  },
  locationName: {
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    color: Styles.darkColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  locationAddress: {
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontSize: Settings.isRevolve() ? Styles.Fonts.f0 + 2 : Styles.Fonts.f0,
    fontWeight: '400',
    color: Styles.darkColor,
    paddingVertical: 4,
  },
  pointsText: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    color: '#2555D9',
    paddingVertical: 2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    overflow: 'hidden',
    width: '100%',
  },
  subTotalView: {
    color: Styles.darkColor,
    fontSize: Styles.Spacing.m4,
    fontWeight: '700',
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  summary: {
    marginBottom: Styles.Spacing.m1,
  },
  boldLabel: {
    color: Styles.darkColor,
    fontSize: Settings.isRevolve() ? Styles.Fonts.f9 + 2 : Styles.Fonts.f10,
    fontWeight: '700',
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m1,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
  summarySection: {
    flexDirection: 'row',
    marginTop: Styles.Spacing.m2,
  },
  buttonContainer: {
    marginBottom: Styles.Spacing.m2,
  },
  buttonText: {
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '700',
    fontSize: Settings.isRevolve() ? Styles.Fonts.f8 + 4 : Styles.Fonts.f8,
  },
});
export default PurchaseDetail;
