import Styles from '../../../../Styles';
import Localized from 'src/constants/AppStrings';

export function getItemContainerStyle(): {[key: string]: any} {
  return {
    itemContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: Styles.Spacing.m3,
      borderBottomColor: Styles.dividerColor,
      borderWidth: 1,
      borderColor: Styles.inputBgColor,
      borderBottomWidth: 1,
    },
    blockedName: {
      fontSize: Styles.Fonts.f1 + 2,
      alignSelf: 'center',
      fontWeight: '700',
      color: Styles.darkColor,
    },
  };
}

export function getUnBlockUserStyle(): {[key: string]: any} {
  return {
    color: Styles.darkGreenRevolve,
    btnUnblock: {
      fontSize: Styles.Fonts.f1,
      fontWeight: 'bold',
      color: Styles.darkGreenRevolve,
    },
    btnUnblockContainer: {
      borderRadius: Styles.Spacing.m4,
      borderWidth: 1,
      paddingVertical: Styles.Spacing.m3 + Styles.Spacing.m2,
      paddingHorizontal: 8,
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
}

export function getNoBlockedUsers(): {[key: string]: any} {
  return {
    noCardsContainer: {
      width: 300,
      height: 450,
      marginVertical: 'auto',
      padding: 120,
      top: 70,
    },
  };
}

export function getBlockedUserTitle() {
  return Localized.Labels.blocked_users_header;
}

export function getBlockedUsersList(): {[key: string]: any} {
  return {
    listWrapper: {
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
      paddingVertical: Styles.Spacing.m4,
      paddingHorizontal: Styles.Spacing.m3,
    },
    listStyle: {
      flex: 1,
    },
    listShadow: Styles.Style.shadow,
  };
}

export default {
  getItemContainerStyle,
  getUnBlockUserStyle,
  getBlockedUsersList,
  getBlockedUserTitle,
};
