import {View, StyleSheet} from 'react-native';
import AVText from 'src/components/elements/AVText';
import {ButtonType} from 'src/components/elements/RoundedButton';
import {
  ProductCategorySearchDescriberData,
  ScanDiscountCodeStylesDescriptor,
} from 'src/components/screens/cart/descriptor/Descriptor';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getDoneButtonColor(): string {
  return Styles.darkGreenRevolve;
}
export function getReportButtonColor() {
  return Styles.white;
}
export function getReportButtonType() {
  return ButtonType.innerWhite;
}

export function getProductSearchEmptyContent(
  isloading?: boolean,
  isEmpty?: number,
): JSX.Element {
  return (
    isloading &&
    isEmpty === 0 && (
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: Styles.Spacing.m3,
          marginHorizontal: Styles.Spacing.m3,
        }}
      >
        <AVText
          accessibilityRole="text"
          style={{
            fontSize: Styles.Fonts.f2,
            fontWeight: '400',
            fontFamily: Styles.FontFamily.figtreeRegular,
            textAlign: 'center',
            color: '#000',
          }}
        >
          {Localized.Labels.no_products_available}
        </AVText>
      </View>
    )
  );
}

export function searchTopContainerDescriptor(): {
  [index: string]: number | string;
} {
  return {top: -30};
}

export function getSelectedProductCategoryNameContent(): JSX.Element {
  return <View />;
}

export function productCategoryflatListStyleDescriptor(
  props: ProductCategorySearchDescriberData,
): {
  [index: string]: number | string;
} {
  return props.styles.revolveFlatListStyle;
}
export function getScanDiscountStyles(): ScanDiscountCodeStylesDescriptor {
  return StyleSheet.create({
    descriptionText: {
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f2,
    },
  });
}
export function getManualEntryButton() {
  return null;
}
export default {
  getDoneButtonColor,
  getReportButtonColor,
  getReportButtonType,
  getProductSearchEmptyContent,
  searchTopContainerDescriptor,
  getSelectedProductCategoryNameContent,
  productCategoryflatListStyleDescriptor,
  getScanDiscountStyles,
  getManualEntryButton,
};
