import * as React from 'react';
import {
  Animated,
  Text,
  StyleSheet,
  View,
  AccessibilityInfo,
} from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import TransactionStore from 'src/stores/TransactionStore';
import Settings from 'src/Settings';
import Styles from '../../Styles';
import {WithLocalizedProps} from 'src/types/PropTypes';
import Localized from 'src/constants/AppStrings';
type ItemAddedFeedbackState = {
  visible: boolean;
  text: string;
  width: Animated.Value;
  itemName: string;
  itemCount: number;
};
type ItemAddedFeedbackProps = WithLocalizedProps & {
  extraMargin: number;
};

class ItemAddedFeedback extends React.Component<
  ItemAddedFeedbackProps,
  ItemAddedFeedbackState
> {
  static defaultProps = {
    extraMargin: 0,
  };
  numItems: number;
  controlHeight: number;

  constructor(props: ItemAddedFeedbackProps) {
    super(props);
    this.state = {
      visible: false,
      text: '',
      itemName: '',
      itemCount: 1,
      width: new Animated.Value(0),
    };
    this.numItems = TransactionStore.getDisplayItems()?.length;
    this.controlHeight = Styles.Spacing.m2 * 4 + props.extraMargin;
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentWillUnmount() {
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const items = TransactionStore.getDisplayItems();
    const numItems = items.length;
    let visible = this.state.visible;
    let text = this.state.text;
    const lastItem = TransactionStore.getDisplayItems()[0];

    if (numItems > this.numItems) {
      ReactNativeHapticFeedback.trigger('impactLight', Settings.hapticOptions);
      visible = true;
      const diffItems = numItems - this.numItems;

      if (diffItems > 1) {
        this.setState({
          itemCount: diffItems,
        });
        text = this.props.strings.Labels.formatString(
          this.props.strings.Labels.items_added,
          diffItems,
        ) as string;
      } else {
        this.setState({itemCount: 1});
        text = this.props.strings.Labels.formatString(
          this.props.strings.Labels.item_added,
          lastItem?.Description ?? '',
        ) as string;
      }

      const textStringBasedOnItemAdded = `${
        lastItem?.Description ?? 'An item'
      } ${Localized.Labels.has_been_added}, Count: ${this.state.itemCount}`;

      AccessibilityInfo.announceForAccessibility(textStringBasedOnItemAdded);
      this.animate();
    }

    this.setState({
      visible,
      text,
      itemName: lastItem?.Description ?? '',
    });
    this.numItems = numItems;
  }

  animate() {
    Animated.sequence([
      Animated.timing(this.state.width, {
        toValue: this.controlHeight,
        duration: 20000,
        useNativeDriver: false,
      }),
    ]).start(() => {
      this.state.width.setValue(0);
      this.setState({
        visible: false,
      });
    });
  }

  render() {
    const {width, visible} = this.state;

    if (!visible) {
      return null;
    }
    return (
      <Animated.View
        style={{
          transform: [
            {
              translateX: width.interpolate({
                inputRange: [0, 0.3, 0.8, 1],
                outputRange: [-600, 0, 0, -600],
              }),
            },
          ],
          flex: 1,
          maxHeight: Styles.Heights.h5,
        }}
      >
        <View style={styles.container} accessible={true}>
          <View
            style={styles.wrapper}
            accessibilityLabel={`${this.state.itemName} ${Localized.Labels.has_been_added},`}
            aria-label={`${this.state.itemName} ${Localized.Labels.has_been_added},`}
          >
            <Text
              accessibilityElementsHidden={true}
              importantForAccessibility="no-hide-descendants"
              style={styles.title}
              numberOfLines={1}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            >
              {this.state.itemName}
            </Text>
            <Text
              accessibilityElementsHidden={true}
              importantForAccessibility="no-hide-descendants"
              style={styles.description}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            >
              {Localized.Labels.has_been_added}
            </Text>
          </View>
          <View
            style={styles.countWrapper}
            accessibilityLabel={`Count: ${this.state.itemCount},`}
            aria-label={`Count: ${this.state.itemCount},`}
          >
            <Text
              accessibilityElementsHidden={true}
              importantForAccessibility="no-hide-descendants"
              style={styles.count}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            >
              {this.state.itemCount}
            </Text>
          </View>
        </View>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    position: 'relative',
    top: Styles.Spacing.m0,
    left: Styles.Spacing.m0,
    backgroundColor: Settings.isRevolve()
      ? Styles.darkGreenRevolve
      : Styles.positiveColor,
    borderRightColor: Styles.white,
    borderRightWidth: 1,
    alignSelf: 'stretch',
    paddingTop: Styles.Spacing.m3,
    paddingHorizontal: Styles.Spacing.m3,
    paddingBottom: Styles.Spacing.m3,
    paddingVertical: Styles.Spacing.m2,
  },
  wrapper: {
    alignSelf: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: Styles.white,
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f1,
  },
  description: {
    color: Styles.white,
    fontSize: Styles.Fonts.f0,
  },
  countWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: Styles.Spacing.m1,
    width: Styles.Heights.h2,
    height: Styles.Heights.h2,
    borderRadius: Styles.Heights.h2 / 2,
    backgroundColor: Styles.white,
  },
  count: {
    color: Styles.positiveColor,
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    textAlign: 'center',
  },
});
export default ItemAddedFeedback;
