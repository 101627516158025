import * as React from 'react';
import Svg, {Mask, Path, G} from 'react-native-svg';
interface IProps {
  width?: number;
  height?: number;
  color?: string;

  style?: Record<string, any>;
}
function ChevronRight(props: IProps) {
  const {width = 24, height = 24, color = '#727179', style = null} = props;
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      {...(props as any)}
    >
      <Mask
        id="a"
        maskUnits="userSpaceOnUse"
        x={6}
        y={2}
        width={12}
        height={20}
      >
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.75 2v20H6.25V2h11.5z"
          fill="#fff"
        />
      </Mask>
      <G mask="url(#a)">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.69 21.585l8.637-8.569a1.431 1.431 0 000-2.032L8.69 2.415a1.431 1.431 0 10-2.017 2.032l6.59 6.537c.563.56.563 1.472 0 2.032l-6.59 6.536a1.431 1.431 0 102.016 2.032z"
          fill={color}
        />
      </G>
    </Svg>
  );
}

export default ChevronRight;
