import * as React from 'react';
import Svg, {Path, G} from 'react-native-svg';
import Settings from 'src/Settings';

type DealIconProps = {
  width: number;
  height: number;
  bgColor: string;
};

export default class DealIcon extends React.Component<DealIconProps> {
  render() {
    return (
      <>
        {Settings.isRevolve() ? (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 28 29"
            fill="none"
          >
            <G id="Icons/Tag">
              <Path
                id="&#240;&#159;&#142;&#168; Color"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25.1998 14.7806L14.2571 3.75554L14.179 3.67739H14.1581C13.7258 3.26577 13.2258 3.05215 12.6008 3.03652L7.40812 2.84373L7.17896 2.83331C6.59562 2.84373 6.02271 3.06778 5.58 3.51587L3.0175 6.07414C2.54875 6.54307 2.33521 7.1631 2.33521 7.77792C2.33521 7.77792 2.33521 7.77792 2.33521 7.78313L2.35083 8.00196L2.69979 13.0716C2.69979 13.0768 2.69979 13.082 2.69979 13.082V13.181C2.75187 13.6343 2.93416 14.0824 3.24146 14.4524L3.52791 14.7337L14.2727 25.6129L14.4342 25.7744C15.054 26.3215 15.9967 26.2954 16.5852 25.7015L25.1998 17.0211C25.8144 16.4062 25.8248 15.4006 25.1998 14.7806ZM8.16854 11.1646C6.78833 11.1646 5.66854 10.0444 5.66854 8.66368C5.66854 7.28294 6.78833 6.16272 8.16854 6.16272C9.54875 6.16272 10.6685 7.28294 10.6685 8.66368C10.6685 10.0444 9.54875 11.1646 8.16854 11.1646Z"
                fill={this.props.bgColor}
              />
            </G>
          </Svg>
        ) : (
          <Svg width="54" height="55" viewBox="0 0 54 55" fill="none">
            <Path
              d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
              fill="#066DFF"
            />
            <Path
              d="M13 19.8754V28.0512C13 28.9809 13.368 29.8723 14.0271 30.5285L23.6938 40.1535C25.0669 41.5207 27.2913 41.5207 28.6644 40.1535L35.9968 32.8528C37.3699 31.4856 37.3699 29.2707 35.9968 27.9035L26.3301 18.2785C25.671 17.6223 24.7758 17.2559 23.842 17.2559H15.6364C14.1809 17.2504 13 18.4262 13 19.8754ZM19.1515 21.6254C19.6177 21.6254 20.0647 21.8098 20.3943 22.138C20.7239 22.4662 20.9091 22.9113 20.9091 23.3754C20.9091 23.8395 20.7239 24.2847 20.3943 24.6128C20.0647 24.941 19.6177 25.1254 19.1515 25.1254C18.6854 25.1254 18.2383 24.941 17.9087 24.6128C17.5791 24.2847 17.3939 23.8395 17.3939 23.3754C17.3939 22.9113 17.5791 22.4662 17.9087 22.138C18.2383 21.8098 18.6854 21.6254 19.1515 21.6254Z"
              fill="#FEE0E6"
            />
          </Svg>
        )}
      </>
    );
  }
}
