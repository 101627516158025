import RevolveDescriptor from './funding-revolve';
import RedesignDescriptor from './funding-redesign';
import RevolveCardDetailsDescriptor from './cardDetails-revolve';
import RedesignCardDetailsDescriptor from './cardDetails-redesign';
import RevolveCreditCardDescriptor from './creditCard-revolve';
import RedesignCreditCardDescriptor from './creditCard-redesign';
import CanteenDescriptor from './funding-canteen';

import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';

const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: CanteenDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

const cardDetailsDescriptor = {
  [BuildTypes.revolve]: RevolveCardDetailsDescriptor,
  [BuildTypes.canteen]: RevolveCardDetailsDescriptor,
  [BuildTypes.default]: RedesignCardDetailsDescriptor,
};

const creditCardDescriptor = {
  [BuildTypes.revolve]: RevolveCreditCardDescriptor,
  [BuildTypes.canteen]: RevolveCreditCardDescriptor,
  [BuildTypes.default]: RedesignCreditCardDescriptor,
};

export function getFundingDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export function getCardDetailsDescriber() {
  return cardDetailsDescriptor[
    Settings.buildType as keyof typeof cardDetailsDescriptor
  ];
}

export function getCreditCardDescriber() {
  return creditCardDescriptor[
    Settings.buildType as keyof typeof creditCardDescriptor
  ];
}

export interface AccountFundingDescriberData {
  styles: {[index: string]: {[index: string]: number | string}};
  title?: string;
  onClose: () => void;
}
