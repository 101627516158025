import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import Styles from 'src/components/Styles';
import {DealDescriberData} from 'src/components/screens/inbox/descriptor/DescriptorType';
import DealIcon from 'src/components/img/svg/DealIcon';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import CheckCircle from 'src/components/img/svg/CheckCircle';
import {Dimensions} from 'react-native';

const width = Dimensions.get('window').width;

export function dealDescDescriptor(props: DealDescriberData): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
      style={props.styles.description}
      accessibilityLabel={props.deal.message}
    >
      {props.deal.message}
    </AVText>
  );
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor: Styles.darkBlueRevolveColor,
    color: Styles.white,
  };
}

export function titleDescriptor(): string {
  return Localized.Labels.offers;
}

export function getStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    content: {
      padding: Styles.Spacing.m3,
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
    },

    expiration: {
      fontSize: Styles.Fonts.f8,
      marginBottom: Styles.Spacing.m3,
    },

    topRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m1,
    },
  };
}

export function dealIconDescriptor(): JSX.Element {
  return (
    <DealIcon
      width={Styles.Heights.h4}
      height={Styles.Heights.h4}
      bgColor={Styles.darkGreenRevolve}
    />
  );
}

export function dealDefaultIconDescriptor(): JSX.Element {
  return dealIconDescriptor();
}

export function dealApplyBtn(applyDeal: () => void) {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      onPress={applyDeal}
      text={Localized.Buttons.apply_now}
      color={Styles.darkGreenRevolve}
    />
  );
}

export function dealAppliedBtn() {
  return (
    <RoundedButton
      buttonType={ButtonType.action}
      text={Localized.Labels.applied}
      color={'#9A9A9F'}
      icon={dealAppliedBtnIcon()}
      containerStyle={getDealDetailStyleDescriptor()['appliedButton']}
      textStyle={getDealDetailStyleDescriptor()['appliedButtonText']}
      onPress={() => {
        return false;
      }}
    />
  );
}

export function dealAppliedBtnIcon() {
  return <CheckCircle color={Styles.white} />;
}

export function dealAppliedContainer() {
  return null;
}

export function dealValidityKey() {
  return Localized.Labels.valid_until;
}

export function getDealEndDateTime(dealEndDateTime) {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
      accessibilityLabel={dealEndDateTime}
      style={getDealDetailStyleDescriptor()['expiration']}
    >
      {dealEndDateTime}
    </AVText>
  );
}

export function getDealDetailStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topView: {
      marginTop: Styles.Spacing.m3,
    },
    image: {
      width: width / 2.4,
      height: width / 2.4,
      borderRadius: Styles.Spacing.m2,
    },
    iconView: {
      width: 50,
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
    dealTitleRow: {
      alignItems: 'center',
      flexDirection: 'row',
      marginTop: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    validUntil: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    description: {
      fontSize: Styles.Fonts.f1,
      marginTop: Styles.Spacing.m3,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    redeemLimit: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.darkColor,
      marginTop: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
    },
    name: {
      color: Styles.black,
      flex: 1,
      fontSize: Styles.Fonts.headerFont,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontWeight: '700',
    },
    appliedButtonText: {
      textAlign: 'center',
      marginLeft: Styles.Spacing.m2,
      color: Styles.white,
      fontSize: Styles.Fonts.f8,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    expiration: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f8,
      marginTop: Styles.Spacing.m2,
    },

    appliedButton: {
      padding: Styles.Heights.h1,
      position: 'absolute',
      paddingHorizontal: 20,
      paddingVertical: 8,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    content: {
      padding: Styles.Spacing.m3,
      flex: 1,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
  };
}

export default {
  dealDescDescriptor,
  getStyleDescriptor,
  titleDescriptor,
  dealIconDescriptor,
  dealApplyBtn,
  dealValidityKey,
  dealAppliedBtn,
  dealAppliedBtnIcon,
  getDealDetailStyleDescriptor,
  dealAppliedContainer,
  backHeaderStyleDescriptor,
  getDealEndDateTime,
  dealDefaultIconDescriptor,
};
