import Styles from 'src/components/Styles';
import {
  CustomTilesButtonStylesDescriptor,
  PickupLocationListStylesDescriptor,
  RecentOrdersButtonStylesDescriptor,
  ProductModifierStylesDescriptor,
  ProductModifierRadioButton,
  CheckoutBarProps,
} from 'src/components/elements/orderAhead/descriptor/DescriptorType';
import {StyleSheet, View, Text, Platform} from 'react-native';
import {checkValue} from 'src/Util';
import Localized from 'src/constants/AppStrings';
import {ProductModifierValue} from 'src/types/Menu';
import React from 'react';
import CustomToggleComponent, {
  CustomToggleComponentType,
} from 'src/components/elements/CustomToggleComponent';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import CartImage from 'src/components/img/svg/CartImage';

export function customTilesButtonStyles(): CustomTilesButtonStylesDescriptor {
  return StyleSheet.create({
    tileConatainer: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: Styles.Colors.PayNew.darkHuesBase04,
      borderWidth: 1,
      borderRadius: Styles.Spacing.m2,
      minWidth: 135,
      paddingHorizontal: Styles.Spacing.m2 + 4,
      justifyContent: 'flex-start',
      paddingVertical: Styles.Spacing.m3,
      backgroundColor: Styles.Colors.PayNew.white01,
    },
    text: {
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      color: Styles.Colors.PayNew.black01,
      paddingHorizontal: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      lineHeight: 20,
    },
    selectedView: {
      backgroundColor: 'rgba(0, 109, 255, 0.15)',
      borderColor: Styles.Colors.PayNew.primary01,
      borderWidth: 2.5,
      paddingVertical:
        Platform.OS === 'ios' ? Styles.Spacing.m3 - 1.5 : Styles.Spacing.m3 - 3,
    },
    selectedText: {
      color: Styles.Colors.PayNew.black01,
      fontWeight: '700',
    },
  });
}

export function pickupLocationListStyles(): PickupLocationListStylesDescriptor {
  return StyleSheet.create({
    cell: {
      padding: Styles.Spacing.m3,
      borderColor: Styles.Colors.PayNew.darkHuesBase04,
      borderWidth: 1,
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
      minHeight: Styles.Heights.touchTargetHeight2,
      backgroundColor: Styles.Colors.PayNew.white01,
    },
    cellSelected: {
      flexDirection: 'row',
      borderColor: Styles.Colors.PayNew.primary01,
      padding: Styles.Spacing.m3,
      borderWidth: 2.5,
      alignItems: 'center',
      borderRadius: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m3,
      backgroundColor: 'rgba(0, 109, 255, 0.15)',
    },
    label: {
      color: Styles.Colors.PayNew.black01,
      fontWeight: '400',
      fontSize: Styles.Fonts.f7,
      paddingLeft: Styles.Spacing.m2,
      paddingRight: Styles.Spacing.m4,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    selectedLabel: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.Colors.PayNew.black01,
      paddingLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  });
}
export function productModifierStyles(): ProductModifierStylesDescriptor {
  return StyleSheet.create({
    customizationDivider: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingVertical: 4,
    },
    radioButtonWrapper: {
      padding: Styles.Spacing.m1,
      backgroundColor: Styles.white,
      marginLeft: Styles.Spacing.m0,
      justifyContent: 'center',
    },
    checkboxCalorieContainer: {
      alignItems: 'center',
    },
    checkboxRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    checkboxNameContainer: {
      flexDirection: 'column',
      marginRight: Styles.Spacing.m2,
      alignItems: 'flex-start',
      flex: 7,
    },
    checkboxPriceContainer: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      flex: 3,
    },
    titleText: {
      color: Styles.Colors.PayNew.black01,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
      flex: 0.8,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    requiredText: {
      fontSize: Styles.Fonts.f1,
      color: Styles.dangerColor,
      fontStyle: 'italic',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    requiredViewStyles: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2,
    },
    options: {
      paddingVertical: Styles.Spacing.m0,
    },
    totalCaloriesLabel: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      color: Styles.Colors.PayNew.black01,
    },
    sectionCalStyles: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f1,
      textAlign: 'right',
      fontWeight: '400',
      color: Styles.Colors.PayNew.black01,
    },
    productModifierContainer: {
      marginHorizontal: Styles.Spacing.m2 + Styles.Spacing.m3,
    },
    helperText: {
      alignSelf: 'flex-end',
      marginRight: Styles.Spacing.m2,
      marginTop: 1,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f8,
      color: Styles.Colors.PayNew.darkHuesBase04,
    },
    titleRow: {
      marginTop: Styles.Spacing.m3 - 2,
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  });
}

export function radioButtonProductModifier(
  buttons: ProductModifierRadioButton[],
  values: string[],
  showCalories: boolean,
  showUnitName: string,
  onPress,
): JSX.Element {
  return (
    <>
      {buttons.map((obj, i) => {
        const isSelected = values.includes(obj.value);
        return (
          <View
            accessible={true}
            accessibilityState={{
              selected: isSelected,
            }}
            key={obj.value + '-' + i}
            style={productModifierStyles().customizationDivider}
          >
            <View style={{marginTop: 2}}>
              <CustomToggleComponent
                accessibilityLabel={obj.label}
                selected={isSelected}
                type={CustomToggleComponentType.Radio}
                onPress={() => onPress(obj.value)}
              />
            </View>
            <View style={styles.modifierLeftView}>
              <Text
                accessibilityLabel={`${obj.label}, `}
                aria-label={`${obj.label}, `}
                style={styles.modifierText}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              >
                {obj.label}
              </Text>
              {obj.price !== null && (
                <Text
                  accessibilityLabel={`${obj.price}, `}
                  aria-label={`${obj.price}, `}
                  style={styles.priceText}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
                >
                  {obj.price}
                </Text>
              )}
            </View>
            <View style={productModifierStyles().checkboxCalorieContainer}>
              {showCalories && checkValue(obj.calories) && (
                <Text
                  accessibilityLabel={`${
                    obj.calories
                  } ${Localized.Labels.calories.toLowerCase()}`}
                  aria-label={`${
                    obj.calories
                  } ${Localized.Labels.calories.toLowerCase()}`}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
                  style={styles.calorieText}
                >
                  {`${obj.calories} ${showUnitName}`}
                </Text>
              )}
            </View>
          </View>
        );
      })}
    </>
  );
}

export function checkboxProductModifiers(
  value: ProductModifierValue,
  price: string,
  calories: string,
  modifierValues: string[],
  showUnitName: string,
  showCalories: boolean,
  onPress,
): JSX.Element {
  return (
    <View
      accessible={true}
      key={value.id}
      style={productModifierStyles().customizationDivider}
    >
      <View style={{marginTop: 2}}>
        <CustomToggleComponent
          accessibilityLabel={value.name}
          selected={modifierValues.includes(value.id)}
          type={CustomToggleComponentType.Checkbox}
          onPress={() => onPress(value.id)}
        />
      </View>
      <View style={styles.modifierLeftView}>
        <Text
          accessibilityLabel={`${value.name}, `}
          aria-label={`${value.name}, `}
          style={styles.modifierText}
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
        >
          {value.name}
        </Text>
        {price !== null && (
          <Text
            accessibilityLabel={`${price}, `}
            aria-label={`${price}, `}
            style={styles.priceText}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          >
            {price}
          </Text>
        )}
      </View>
      <View style={productModifierStyles().checkboxCalorieContainer}>
        {showCalories && checkValue(value.calories) && (
          <View style={productModifierStyles().checkboxCalorieContainer}>
            <Text
              style={styles.calorieText}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              accessibilityLabel={`${calories}, ${showUnitName}`}
              aria-label={`${calories}, ${showUnitName}`}
            >
              {`${value?.calories} ${showUnitName}`}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

export function getCounterText(value, FREE_TEXT_MAX_LENGTH): string {
  return `${value?.length || 0}/${FREE_TEXT_MAX_LENGTH}`;
}

export function recentOrderButtonStyle(): RecentOrdersButtonStylesDescriptor {
  return StyleSheet.create({
    marginStyle: {
      marginTop: 0,
      marginBottom: 0,
    },
    recentOrderText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontWeight: '400',
    },
  });
}

export function checkoutBarUI({numItems, onPress, label}: CheckoutBarProps) {
  return (
    <View>
      {numItems > 0 && (
        <RoundedAppButton
          buttonType={RoundedAppButtonType.Solid}
          titleText={label || Localized.Buttons.checkout}
          buttonIcon={
            <View>
              <CartImage width={24} height={24} />
              <View style={styles.badge}>
                {numItems > 0 && (
                  <Text
                    maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                    style={styles.badgeText}
                  >
                    {numItems}
                  </Text>
                )}
              </View>
            </View>
          }
          onPress={onPress}
          isDisabled={numItems === 0 || !onPress}
          buttonContainerStyle={styles.checkoutButtonContainer}
          buttonViewStyle={styles.checkoutButtonView}
        />
      )}
    </View>
  );
}

export function requiredModifiersView(name: string): JSX.Element {
  return (
    <View style={productModifierStyles().requiredViewStyles}>
      <Text
        accessible={true}
        accessibilityLabel={`${name} section`}
        aria-label={`${name} section`}
        style={productModifierStyles().titleText}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
      >
        {`${name}*`}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  outerRadioStyle: {
    height: 24,
    width: 24,
    borderRadius: Styles.Heights.h5,
    borderWidth: 2,
  },
  innerRadioStyle: {
    height: 15,
    width: 15,
    borderRadius: Styles.Heights.h5,
    backgroundColor: Styles.Colors.PayNew.primary01,
  },
  modifierLeftView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: Styles.Spacing.m1,
  },
  calorieText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    color: Styles.Colors.PayNew.black01,
    textAlign: 'right',
  },
  modifierText: {
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    color: Styles.Colors.PayNew.black01,
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    maxWidth: '65%',
  },
  priceText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    color: Styles.Colors.PayNew.black01,
    fontWeight: '400',
    marginLeft: Styles.Spacing.m1 - 2,
  },
  badge: {
    alignItems: 'center',
    position: 'absolute',
    right: Styles.Spacing.m0,
    top: Platform.OS === 'ios' ? Styles.Spacing.m1 - 2 : 0,
    bottom: 0,
    left: 2,
  },
  badgeText: {
    fontSize: Styles.Fonts.f0,
    fontWeight: 'bold',
    color: Styles.Colors.PayNew.primary01,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  checkoutButtonContainer: {
    right: Styles.Spacing.m3,
    position: 'absolute',
    bottom: Styles.Spacing.m3 + 6,
  },
  checkoutButtonView: {
    paddingHorizontal: Styles.Spacing.m3,
    minHeight: 42,
  },
  asterisk: {
    // position: 'absolute',
    // top: -5,
    // right: -10,
    fontSize: Styles.Fonts.f1,
    color: Styles.Colors.PayNew.black01,
  },
});

export default {
  customTilesButtonStyles,
  pickupLocationListStyles,
  productModifierStyles,
  radioButtonProductModifier,
  checkboxProductModifiers,
  getCounterText,
  recentOrderButtonStyle,
  checkoutBarUI,
  requiredModifiersView,
};
