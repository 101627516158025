import React from 'react';
import DashedLine from 'react-native-dashed-line';
import {withGlobalize} from 'react-native-globalize';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import AppRoutes from 'src/AppRoutes';
import {getTaxDescription} from 'src/services/TranslationService';
import AVFormattedCurrency from '../../elements/AVFormattedCurrency';
import ReceiptItem from '../../elements/cart/ReceiptItem';
import Styles from '../../Styles';
import BaseSummaryScreen from '../account/history/BaseSummaryScreen';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import {PixelRatio, StyleSheet, View} from 'react-native';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Localized from 'src/constants/AppStrings';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Events from 'src/logging/Events';

type ReceiptScreenProps = {
  returnRoute: AppRoutes;
  navigation?: NavigationProp<ReceiptScreen>;
  balance: number;
  ccDisplay: string;
  totalDue: number;
  subtotal: number;
  discount: number;
  taxes: Array<any>;
  deposit: number;
  items: Array<any>;
  points: number;
  split: boolean;
  insideTab: boolean;
  deviceId: string;
  supFee: number;
};

type ReceiptScreenState = {
  previousRoute: string | null;
};

class ReceiptScreen extends React.Component<
  ReceiptScreenProps,
  ReceiptScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props) {
    super(props);
    this.state = {
      previousRoute: null,
    };
    this.renderSummary = this.renderSummary.bind(this);
  }

  computeTotalAmount() {
    // Calculate total taxes
    const totalTaxes =
      this.props.taxes?.reduce(
        (acc, tax) => acc + parseFloat(tax.TaxAmount),
        0,
      ) || 0;

    // Calculate total amount
    const totalAmt =
      (this.props?.subtotal || 0) + totalTaxes - (this.props?.discount || 0);

    return totalAmt;
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'ReceiptScreen', {
      ...this.props,
      ...this.state,
    });

    Events.Info.trackEvent('ReceiptScreen', this.props);

    const previousRoute = getPreviousRouteName(
      this.props.navigation?.getState()?.routes,
    );

    this.setState({previousRoute});
  }

  renderSummary() {
    const labels: JSX.Element[] = [];
    const amounts: JSX.Element[] = [];
    if (this.props.supFee && this.props.supFee > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={[styles.labelStyle, styles.supFeeStyle]}
          key="sup"
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.sup_fee}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessible={true}
          accessibilityLabel={`${Localized.Labels.sup_fee} $${this.props.supFee}`}
          aria-label={`${Localized.Labels.sup_fee} $${this.props.supFee}, text`}
          value={this.props.supFee}
          key="sup"
          style={[styles.labelStyle, styles.supFeeStyle]}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.subtotal > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={styles.labelStyle}
          key="subtotal"
        >
          {Localized.Labels.subtotal}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessibilityLabel={`${Localized.Labels.subtotal} $${this.props.subtotal}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.subtotal} $${this.props.subtotal}, text`}
          accessible={true}
          key="subtotal"
          style={styles.labelStyle}
          value={this.props.subtotal}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.discount > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={[styles.labelStyle, styles.discount]}
          key="discount"
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
        >
          {Localized.Labels.discount}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessible={true}
          accessibilityLabel={`${Localized.Labels.discount} $${
            this.props.discount * -1
          }`}
          aria-label={`${Localized.Labels.discount} $${
            this.props.discount * -1
          }, text`}
          value={this.props.discount * -1}
          key="discount"
          style={[styles.labelStyle, styles.discount]}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.taxes && this.props.taxes.length > 0) {
      for (let i = 0; i < this.props.taxes.length; i++) {
        const tax = this.props.taxes[i];
        labels.push(
          <AVText
            maxFontSizeMultiplier={1.0}
            accessibilityElementsHidden={true}
            importantForAccessibility="no-hide-descendants"
            style={styles.labelStyle}
            key={`tax${i}`}
          >
            {getTaxDescription(Localized, tax.Description)}
          </AVText>,
        );
        amounts.push(
          <AVFormattedCurrency
            value={parseFloat(tax.TaxAmount)}
            style={styles.labelStyle}
            accessible={true}
            accessibilityLabel={`${getTaxDescription(
              Localized,
              tax.Description,
            )} $${parseFloat(tax.TaxAmount)}`}
            accessibilityRole="text"
            aria-label={`${getTaxDescription(
              Localized,
              tax.Description,
            )} $${parseFloat(tax.TaxAmount)}, text`}
            key={`tax${i}`}
            currency={AccountStore.getCurrency()}
          />,
        );
      }
    }

    if (this.props.supFee && this.props.supFee > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={styles.labelStyle}
          key="sup_fee"
        >
          {Localized.Labels.sup_fee}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          accessibilityLabel={`${Localized.Labels.sup_fee} $${this.props.supFee}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.sup_fee} $${this.props.supFee}, text`}
          accessible={true}
          key="sup_fee"
          style={styles.labelStyle}
          value={this.props.subtotal}
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.deposit > 0) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          style={styles.labelStyle}
          key="deposit"
        >
          {Localized.Labels.deposit}
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          style={styles.labelStyle}
          value={this.props.deposit}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.deposit} $${this.props.deposit}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.deposit} $${this.props.deposit}, text`}
          key="deposit"
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    if (this.props.split) {
      if (this.props.split) {
        let label = Localized.Labels.account_balance;

        if (Settings.buildType === BuildTypes.pantry) {
          label = Localized.Labels.remaining_credit;
        }

        labels.push(
          <AVText
            maxFontSizeMultiplier={1.0}
            accessibilityElementsHidden={true}
            importantForAccessibility="no-hide-descendants"
            style={styles.labelStyle}
            key="remaininBalance"
          >
            {label}
          </AVText>,
        );
        amounts.push(
          <AVFormattedCurrency
            style={styles.labelStyle}
            value={this.props.balance * -1}
            accessible={true}
            accessibilityLabel={`${label} $${this.props.balance * -1}`}
            accessibilityRole="text"
            aria-label={`${label} $${this.props.balance * -1}, text`}
            key="remaininBalance"
            currency={AccountStore.getCurrency()}
          />,
        );
      }
    }

    if (this.props.split) {
      labels.push(
        <AVText
          maxFontSizeMultiplier={1.0}
          style={styles.labelStyle}
          accessibilityElementsHidden={true}
          importantForAccessibility="no-hide-descendants"
          key="totalDue"
        >
          {Localized.Labels.total_due} ({this.props.ccDisplay})
        </AVText>,
      );
      amounts.push(
        <AVFormattedCurrency
          style={styles.labelStyle}
          value={this.props.totalDue}
          accessible={true}
          accessibilityLabel={`${Localized.Labels.total_due} (${this.props.ccDisplay}) $${this.props.totalDue}`}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.total_due} (${this.props.ccDisplay}) $${this.props.totalDue}, text`}
          key="totalDue"
          currency={AccountStore.getCurrency()}
        />,
      );
    }

    return (
      <View
        style={
          Settings.isRevolve() ? Styles.Style.revolveRow : styles.canteenRow
        }
      >
        <View>{labels}</View>
        <View>{amounts}</View>
      </View>
    );
  }

  render() {
    const currency = AccountStore.getCurrency();
    const totalAmt = this.computeTotalAmount();
    const receiptItems = this.props?.items?.map((item, index) => (
      <ReceiptItem
        desc={item.desc}
        amount={item.price}
        key={`${index}`}
        accessible={true}
        accessibilityLabel={`${item.name}`}
        aria-label={`${item.name}`}
      />
    ));
    return (
      <BaseSummaryScreen
        previousRoute={this.state.previousRoute}
        accessibilityLabel={Localized.Buttons.back_arrow}
        aria-label={Localized.Buttons.back_arrow}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        strings={Localized}
        titleText={Localized.Buttons.receipt}
        returnRoute={this.props.returnRoute}
        insideTab={this.props.insideTab}
        deviceId={this.props.deviceId}
        showReportBtn
      >
        {receiptItems}
        <DashedLine
          dashGap={PixelRatio.roundToNearestPixel(4)}
          dashLength={PixelRatio.roundToNearestPixel(5)}
          dashThickness={PixelRatio.roundToNearestPixel(1)}
          dashColor={Styles.inActiveDotColor}
          style={[
            styles.dashStyle,
            {
              marginTop: -1 * Styles.Spacing.m2,
            },
          ]}
        />
        {this.renderSummary()}
        {this.props.split ? null : (
          <>
            <DashedLine
              dashGap={PixelRatio.roundToNearestPixel(4)}
              dashLength={PixelRatio.roundToNearestPixel(5)}
              dashThickness={PixelRatio.roundToNearestPixel(1)}
              dashColor={Styles.inActiveDotColor}
              style={[styles.dashStyle]}
            />
            <View
              style={
                Settings.isRevolve()
                  ? Styles.Style.revolveRow
                  : styles.canteenRow
              }
            >
              <AVText
                maxFontSizeMultiplier={1.0}
                style={styles.totalLabel}
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                key="due"
              >
                {Localized.Labels.total}
              </AVText>

              <AVFormattedCurrency
                style={styles.totalLabel}
                accessible={true}
                accessibilityLabel={`${Localized.Labels.total} $${totalAmt}`}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.total} $${totalAmt}, text`}
                key="due"
                value={totalAmt}
                currency={currency}
              />
            </View>
            <View
              style={
                Settings.isRevolve()
                  ? Styles.Style.revolveRow
                  : styles.canteenRow
              }
            >
              <AVText
                maxFontSizeMultiplier={1.0}
                style={styles.remainingBalanceLabel}
                accessibilityElementsHidden={true}
                importantForAccessibility="no-hide-descendants"
                key="remainingBalance"
              >
                {Localized.Labels.remaining_balance}
              </AVText>

              <AVFormattedCurrency
                style={styles.remainingBalanceLabel}
                accessible={true}
                accessibilityLabel={`${Localized.Labels.remaining_balance} $${this.props.balance}`}
                accessibilityRole="text"
                aria-label={`${Localized.Labels.remaining_balance} $${this.props.balance}, text`}
                key="remainingBalance"
                value={this.props.balance - totalAmt}
                currency={currency}
              />
            </View>
          </>
        )}
      </BaseSummaryScreen>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    marginTop: Styles.Spacing.m4,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  discount: {
    color: Styles.positiveColor,
  },
  supFeeStyle: {
    color: Styles.positiveColor,
  },
  labelStyle: {
    color: Styles.darkColor,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    paddingVertical: 2,
  },
  totalLabel: {
    color: Styles.darkColor,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '700',
  },
  remainingBalanceLabel: {
    color: Styles.lightGray,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.aeonikRegular
      : Styles.FontFamily.robotoRegular,
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '400',
  },
  canteenRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
export default withForwardedNavigationParams<ReceiptScreenProps>()(
  withGlobalize(ReceiptScreen),
);
