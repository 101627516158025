import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import React from 'react';
import KeyboardAwareScrollView from '../../elements/KeyboardAwareScrollView';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Styles from '../../Styles';
import {StyleSheet, View, TouchableOpacity} from 'react-native';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import LinearGradient from 'react-native-linear-gradient';
import {getDescriber} from '../descriptor/DescriptorType';
import CircleExclamationOnboarding from 'src/components/img/svg/CircleExclamationPayNew';
import ActionsFactory from 'src/actions/ActionsFactory';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import Settings from 'src/Settings';
import BuildTypes from 'src/constants/BuildTypeConstants';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';
import {alertError} from 'src/components/helpers/AlertHelper';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import uuid from 'src/nativeModules/UUID';

type ExistingAccountWarningProps = {
  email: string;
  overrideEmail: string;
  onSuccess: () => void;
  code: string;
  accountId: string;
  pin?: string;
  locationId?: string;
};

const {getOnBoardingButton, actionLinkText, getBodyTextStyles} = getDescriber();

class ExistingAccountWarningScreen extends React.Component<ExistingAccountWarningProps> {
  scrollView: KeyboardAwareScrollView | null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: ExistingAccountWarningProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      pin: this.props.pin || '',
    };
  }

  async overrideExistingAccount() {
    this.context?.actions.showSpinner();
    try {
      if (!this.props.pin) {
        const response =
          await ActionsFactory.getAccountActions().validateAndMergeAccounts(
            this.props.code,
            true,
          );
        FirebaseAnalytic.trackEvent(
          'onOverrideEmail',
          'ExistingAccountWarning',
          {
            response,
            code: this.props.code,
          },
        );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'AddOrUpdateMarketCard',
          JSON.stringify({}),
          JSON.stringify(response),
        );
        if (!response) {
          alertError(Localized.Errors.please_scan_valid_market_card);
        }
        this.context?.actions.hideSpinner();
        if (response.statusCode === 300) {
          NavActions.push(AppRoutes.MarketCardNotUniqueScreen, {
            onSuccess: this.props.onSuccess,
            data: {id: this.props.accountId, email: this.props.overrideEmail},
            code: this.props.code,
          });
          return;
        }
        switch (response.status) {
          case 'MERGED':
            this.logoutAfterSuccessfulMerge();
            break;
          case 'NOT_MERGED':
            NavActions.push(AppRoutes.RegistrationEnterPinScreen, {
              onSuccess: this.props.onSuccess,
              data: {id: this.props.accountId, email: this.props.overrideEmail},
              code: this.props.code,
              overrideEmail: true,
            });
            break;
          default:
            throw new Error(Localized.Errors.invalid_market_card);
        }
      } else {
        const response =
          await ActionsFactory.getAccountActions().validatePinAndMergeAccounts(
            this.props.accountId,
            this.props.pin,
            this.props.code,
            this.props.locationId,
            true,
          );
        if (response.sourceAccountId === this.props.accountId) {
          this.logoutAfterSuccessfulMerge();
        } else {
          this.context.actions.showModal(
            Localized.Errors.merge_accounts_error_description,
            Localized.Errors.oops,
            Localized.Buttons.try_again,
            Settings.buildType === 'canteen'
              ? '#003349'
              : Styles.Colors.PayNew.primary01,
            3,
            false,
          );
        }
      }
      this.context?.actions.hideSpinner();
    } catch (error) {
      const guid = await uuid.getRandomUUID();

      this.context?.actions.hideSpinner();
      alertError(Localized.Errors.please_scan_valid_market_card);
      CrashlyticsEvents.log(
        'Exception',
        'ExistingAccountWarningScreen:onOverrideEmail',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'ExistingAccountWarningScreen:onOverrideEmail',
        generateErrorMessage(error),
        guid,
      );
      Logger.Log.LogAPIEvent(
        'AccountAPI',
        'ExistingAccountWarning',
        JSON.stringify({}),
        JSON.stringify(error),
      );
      FirebaseAnalytic.trackEvent(
        'onOverrideEmailError',
        'ExistingAccountWarning',
        {
          error: generateErrorMessage(error),
          guid,
        },
      );
    }
  }

  async logoutAfterSuccessfulMerge() {
    this.context?.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    this.context?.actions.hideSpinner();
    this.context?.actions.showModal(
      Localized.Success.entered_market_card,
      Localized.Success.success,
      Localized.Buttons.ok,
      Styles.Colors.PayNew.primary01,
      2,
      false,
      false,
      undefined,
      () => {
        NavActions.reset(AppRoutes.Welcome, {
          shouldAutoLogin: true,
          autoLoginEmail: this.props.overrideEmail,
        });
      },
    );
  }

  async logOutTapped() {
    this.context?.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    this.context?.actions.hideSpinner();
    NavActions.reset(AppRoutes.Welcome);
  }

  async handleClick() {
    this.overrideExistingAccount();
  }

  render() {
    const gradientProps =
      Settings.buildType === BuildTypes.canteen
        ? {
            colors: ['#FFFFFF', '#FBFFF3', '#E3F4C0', '#A5CF4F'],
            locations: [0, 0.3, 0.7, 1],
            start: {x: 0.5, y: 0},
            end: {x: 0.5, y: 1},
          }
        : {
            colors: ['#FEE0E6', '#E6ECFF'],
            start: {x: 0, y: 0},
            end: {x: 0, y: 1},
          };

    return (
      <LinearGradient {...gradientProps} style={styles.redesignContainer}>
        <View style={styles.mainContent}>
          <View style={styles.iconContainer}>
            <CircleExclamationOnboarding fill="#003349" />
          </View>
          <View style={styles.text}>
            <AVText
              style={{...getBodyTextStyles()['bodyText'], fontWeight: 'bold'}}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Labels.formatString(
                Localized.Labels.account_registered,
                this.props.email,
              )}
            </AVText>
            <AVText
              style={getBodyTextStyles()['bodyText']}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            >
              {Localized.Labels.formatString(
                Localized.Labels.account_registered_description,
                this.props.email,
              )}
            </AVText>
          </View>
          <View style={styles.buttonsAndLinks}>
            <View>{getOnBoardingButton('Log out', this.logOutTapped)}</View>
            <TouchableOpacity
              onPress={this.handleClick}
              style={styles.override}
            >
              <AVText style={actionLinkText()['actionLinkText']}>
                {Localized.Labels.continue_using_current_email}
              </AVText>
            </TouchableOpacity>
          </View>
        </View>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  redesignContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: 32,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  buttonsAndLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 16,
    alignSelf: 'stretch',
  },
  mainContent: {
    display: 'flex',
    width: 345,
    flexDirection: 'column',
    alignItems: 'center',
    gap: 48,
    marginBottom: 204,
  },
  skipLinkContainer: {
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 24,
  },
  skipLinkText: {
    color: '#007AFF',
    fontSize: 16,
    fontWeight: '600',
  },
  override: {
    height: Styles.Heights.h5,
    marginTop: Styles.Spacing.m2,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
});
export default withForwardedNavigationParams<ExistingAccountWarningProps>()(
  ExistingAccountWarningScreen,
);
