import React, {useContext} from 'react';
import {Dimensions, Platform, StyleSheet, View} from 'react-native';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import PaymentComboIcon from 'src/components/img/svg/PaymentComboIcon';
import AVText from 'src/components/elements/AVText';
import {getTotalDisplayAmount} from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import {useAppSelector} from 'src/redux/hooks';
import {useGlobalize} from 'react-native-globalize';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import MainConsumerContext from 'src/components/MainConsumerContext';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Util from 'src/Util';

const width = Dimensions.get('window').width;

const GreetingsAndBalanceView = () => {
  const context = useContext(MainConsumerContext);
  const {balances} = useAppSelector((state) => state.account.account);

  const getTotalBalance = () => {
    const totalAmount = getTotalDisplayAmount(balances);
    const {formatCurrency} = useGlobalize();
    return formatCurrency(totalAmount, AccountStore.getCurrency(), {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  };

  const onGreetingPress = () => {
    FirebaseAnalytic.trackEvent(
      '365PAY:onGreetingPress',
      'HomeScreen:GreetingsAndBalanceView',
    );
    context.actions.navigateToFunding(true, {
      transDate: Util.getCurrentDate(),
    });
  };

  return (
    <AVTouchableOpacity accessible onPress={onGreetingPress}>
      <View style={styles.container}>
        <View style={styles.iconView}>
          <PaymentComboIcon />
        </View>
        <View style={styles.textView}>
          <AVText
            style={styles.nameText}
            accessible={true}
            aria-label={`${AccountStore.getFirstName()}, text`}
            accessibilityLabel={AccountStore.getFirstName()}
            numberOfLines={1}
            adjustsFontSizeToFit={true}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
          >
            {Localized.Labels.formatString(
              Localized.Labels.hey_name,
              AccountStore.getFirstName(),
            )}
          </AVText>
          <AVText
            style={styles.balanceText}
            adjustsFontSizeToFit={true}
            accessible={true}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            accessibilityRole="text"
            accessibilityLabel={Localized.Labels.balance}
            numberOfLines={1}
            aria-label={`${Localized.Labels.balance}, text`}
          >
            {Localized.Labels.balance + ': ' + getTotalBalance()}
          </AVText>
        </View>
      </View>
    </AVTouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    marginHorizontal: Styles.Spacing.m3,
    width: width - Styles.Spacing.m3 * 2,
    marginTop: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    padding: 16,
    marginBottom: Styles.Spacing.m3,
    ...Styles.Style.shadow,
  },
  iconView: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    width: 60,
    backgroundColor: Styles.blueHuesBase07,
    borderRadius: Styles.Spacing.m2,
  },
  arrowView: {
    right: Styles.Spacing.m3,
    position: 'absolute',
  },
  textView: {
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    marginLeft: 16,
    height: 60,
  },
  nameText: {
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '700',
    color: Styles.blackBase01,
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  balanceText: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    color: Styles.blackBase01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    paddingBottom: Platform.OS === 'android' ? 4 : 0,
  },
});

export default GreetingsAndBalanceView;
