import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../../Styles';
import {
  FlatList,
  Platform,
  RefreshControl,
  TouchableOpacity,
  StyleSheet,
  View,
} from 'react-native';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import GiftBox from 'src/components/img/svg/GiftBox';
import AVText from 'src/components/elements/AVText';
import Card from 'src/components/img/svg/Card';
import CreditCard from 'src/models/CreditCard';
import {WithLocalizedProps} from 'src/types/PropTypes';
import CreditCardImage from 'src/components/elements/CreditCardImage';
import Star from 'src/components/img/svg/inbox/Star';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import Settings from 'src/Settings';
import AccountConstants from 'src/constants/AccountConstants';
import NoWalletCard from 'src/components/img/svg/NoWalletCard';

export function getWalletTopButtonsSection(callbacks): JSX.Element {
  return (
    <View style={getWalletStyles()['buttonsView']}>
      <TouchableOpacity
        style={[
          getWalletStyles()['buttonContainer'],
          {marginRight: Styles.Spacing.m1},
        ]}
        accessibilityRole="button"
        accessible={true}
        onPress={callbacks[0]}
        accessibilityLabel={Localized.Labels.add_egift_card}
        role="button"
        aria-label={Localized.Labels.add_egift_card}
        accessibilityHint="Double tap to add egift card using your device's camera"
      >
        <GiftBox
          height={25}
          width={22}
          color={Styles.darkGreenRevolve}
          style={getWalletStyles()['addBtnIcon']}
        />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          style={getWalletStyles()['addButtonText']}
        >
          {Localized.Labels.add_egift_card}
        </AVText>
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          getWalletStyles()['buttonContainer'],
          {marginLeft: Styles.Spacing.m1},
        ]}
        role="button"
        accessibilityLabel={Localized.Labels.add_card}
        accessible={true}
        accessibilityHint="Double tap to add new payment card"
        onPress={callbacks[1]}
        aria-label={Localized.Labels.add_card}
        accessibilityRole="button"
      >
        <Card
          height={25}
          width={23}
          color={Styles.darkGreenRevolve}
          style={getWalletStyles()['addBtnIcon']}
        />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
          style={getWalletStyles()['addButtonText']}
        >
          {Localized.Labels.add_card}
        </AVText>
      </TouchableOpacity>
    </View>
  );
}

export function getWalletStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
    },
    buttonsView: {
      flexDirection: 'row',
      marginHorizontal: Styles.Spacing.m3,
      marginVertical: Styles.Spacing.m3,
      height: 70,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    buttonContainer: {
      height: 55,
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      borderWidth: 1,
      borderColor: Styles.darkGreenRevolve,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    addButtonText: {
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f8,
      fontWeight: '700',
      color: Styles.black,
      textAlignVertical: 'center',
    },
    btnStyle: {
      alignSelf: 'center',
    },
    safeAreaView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },

    creditCardInfo: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    issuerImage: {
      alignSelf: 'flex-start',
      marginRight: Styles.Spacing.m2,
    },
    label: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
    },
    pending: {
      color: Styles.warningColor,
      marginRight: Styles.Spacing.m2,
    },
    pendingView: {
      flexDirection: 'row',
    },
    starIconView: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    starIcon: {
      bottom: 3,
    },
    subLabel: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f0,
    },
    blurBackground: {
      position: 'absolute',
      top: Styles.Spacing.m0,
      bottom: Styles.Spacing.m0,
      left: Styles.Spacing.m0,
      right: Styles.Spacing.m0,
      backgroundColor: Styles.overlay,
    },
    modal: {
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContainer: {
      width: Styles.ScreenWidth / 1.1,
      height:
        Platform.OS === 'ios'
          ? Styles.ScreenHeight / 3
          : Styles.ScreenHeight / 2,
      borderRadius: 10,
    },
    subModalContainer: {
      backgroundColor: Styles.addCardPopupColor,
      paddingHorizontal: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cancelText: {
      color: Styles.primaryColor,
    },
    boldText: {
      fontWeight: 'bold',
      color: Styles.darkColor,
    },
    cardForm: {
      height: Styles.ScreenHeight / 2,
      marginTop: Styles.Spacing.m3,
    },
    cardStyle: {
      backgroundColor: Styles.addCardPopupColor,
    },
    cardNumber: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
    },
    addView: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: Styles.Spacing.m3 + 8,
      marginLeft: Styles.Spacing.m2,
    },
    addViewWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: Styles.Spacing.m3,
    },
    addBtn: {
      marginRight: Styles.Spacing.m2 + 2,
      //paddingVertical: Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2,
    },
    nocardsContainer: {
      width: 300,
      height: 450,
      marginVertical: 'auto',
      padding: 120,
      top: -40,
    },
    addBtnIcon: {
      marginLeft: 8,
    },
    circle: {
      width: 50,
      height: 50,
      borderRadius: 100 / 2,
      backgroundColor: Styles.primaryColor,
    },
    revolveAddTxt: {fontWeight: '400', fontSize: 18, color: Styles.black},
    revolveMainTxt: {fontWeight: '700', fontSize: 18, color: Styles.black},
    revolvePendingRightView: {fontWeight: '400', fontSize: 16},
  };
}

const styles = StyleSheet.create({
  tileDesign: {
    width: '100%',
    height: 82,
    padding: Styles.Spacing.m3,
    flexDirection: 'row',
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
    justifyContent: 'space-between',
    display: 'flex',
    paddingHorizontal: 15,
    paddingVertical: 10,
    ...Styles.Style.shadow,
  },
});

export function getBalancesTitle(): JSX.Element {
  return null;
}

export function getBalancesList(): JSX.Element {
  return null;
}

export function getCardsTitle(): JSX.Element {
  return null;
}

export function getWalletBottomSection(creditCards, showPayroll): JSX.Element {
  return (
    (!creditCards || creditCards === 0) &&
    !showPayroll && (
      <View
        accessible={true}
        accessibilityLabel={Localized.Labels.logo_nocards}
        accessibilityRole="image"
        role="img"
        aria-label={Localized.Labels.logo_nocards}
        style={getWalletStyles()['nocardsContainer']}
      >
        <NoWalletCard />
      </View>
    )
  );
}

export function getWalletBalanceSection(): JSX.Element {
  return null;
}

export function getWalletCreditCardsSection(
  props,
  state,
  callbacks,
  onRefresh,
): JSX.Element {
  return (
    <FlatList
      data={props.creditCards}
      renderItem={(item) => renderCreditCard(item, callbacks)}
      automaticallyAdjustContentInsets={false}
      ListFooterComponent={() => renderPayroll(props, state, callbacks)}
      extraData={state}
      keyExtractor={(item) => item.id}
      contentContainerStyle={{
        paddingHorizontal: Settings.isNewUI() ? Styles.Spacing.m3 : 0,
      }}
      refreshControl={
        <RefreshControl
          refreshing={state.isRefreshing}
          onRefresh={() => onRefresh}
          tintColor={Styles.primaryColor}
          colors={[Styles.primaryColor]}
          titleColor={Styles.black}
        />
      }
    />
  );
}

function renderCreditCard({item}: {item: CreditCard}, callbacks) {
  return (
    <CreditCardCell
      key={item.id}
      strings={Localized}
      creditCard={item}
      onCreditCardClick={callbacks[0]}
    />
  );
}

type CreditCardCellProps = WithLocalizedProps & {
  onCreditCardClick: (creditCard) => void;
  creditCard: CreditCard;
};

const CreditCardCell: React.FC<CreditCardCellProps> = ({
  onCreditCardClick,
  creditCard,
  strings,
}) => (
  <AVTouchableOpacity
    accessible={true}
    accessibilityRole="button"
    accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
    role="button"
    onPress={() => onCreditCardClick(creditCard)}
    accessibilityLabel="onCreditCardClick"
    aria-label="onCreditCardClick"
  >
    <View style={[styles.tileDesign]}>
      <View style={[getWalletStyles()['creditCardInfo']]}>
        <View
          accessibilityLabel={`Issuer ${creditCard.cardIssuer}`}
          accessibilityRole="image"
          aria-label={`Issuer ${creditCard.cardIssuer}`}
          role="img"
          style={[]}
        >
          <CreditCardImage issuer={creditCard.cardIssuer} />
        </View>
        <View style={{padding: Styles.Spacing.m2 + 4}}>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={strings.Labels.credit_card}
            accessibilityRole="text"
            aria-label={`${strings.Labels.credit_card}, text`}
            style={[
              getWalletStyles()['label'],
              Settings.isRevolve() ? getWalletStyles()['revolveMainTxt'] : null,
            ]}
          >
            {strings.Labels.credit_card}
          </AVText>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
            accessibilityLabel={creditCard.name}
            accessibilityRole="text"
            aria-label={`${creditCard.name}, text`}
            style={[
              getWalletStyles()['cardNumber'],
              Settings.isRevolve() ? getWalletStyles()['revolveAddTxt'] : null,
            ]}
          >
            {creditCard.name}
          </AVText>
        </View>
      </View>
      {creditCard.isDefault && (
        <View style={[getWalletStyles()['starIconView'], {left: 25}]}>
          <View
            accessibilityLabel="Default payment method, "
            aria-label="Default payment method, "
            style={getWalletStyles()['starIcon']}
          >
            <Star width={Styles.Fonts.f3} height={Styles.Fonts.f3} />
          </View>
        </View>
      )}
      <View
        style={[
          getWalletStyles()['starIconView'],
          //creditCard.isDefault ? {left: 20} : {left: 40},
        ]}
      >
        {Settings.isRevolve() ? (
          <ChevronRight />
        ) : (
          <FontAwesome5Pro
            accessibilityLabel="Double tap to edit payment method"
            aria-label="Double tap to edit payment method"
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        )}
      </View>
    </View>
  </AVTouchableOpacity>
);

function renderPayroll(props, state, callbacks) {
  const footerComp: Array<JSX.Element> = [];

  if (state.showPayroll) {
    const chevron = Settings.isRevolve() ? (
      <View>
        <ChevronRight />
      </View>
    ) : (
      <FontAwesome5Pro
        accessibilityLabel="View payroll information"
        aria-label="View payroll information"
        name="chevron-right"
        color={Styles.lightGray}
        size={Styles.Fonts.f2}
        light
      />
    );
    let rightView;
    let additionalText;

    if (state.payrollPending) {
      additionalText = (
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          accessibilityLabel="Payroll pending"
          accessibilityRole="text"
          aria-label={`${'Payroll pending'}, text`}
          style={[
            getWalletStyles()['cardNumber'],
            Settings.isRevolve() ? getWalletStyles()['revolveAddTxt'] : null,
          ]}
        >
          {Localized.Labels.pending}
        </AVText>
      );
      rightView = (
        <View
          style={[
            getWalletStyles()['pendingView'],
            Settings.isRevolve() ? {margin: Styles.Spacing.m3} : null,
            {alignItems: 'center'},
          ]}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessibilityLabel={Localized.Labels.pending_upper_case}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.pending_upper_case}, text`}
            style={[
              getWalletStyles()['cardNumber'],
              getWalletStyles()['pending'],
              Settings.isRevolve()
                ? getWalletStyles()['revolvePendingRightView']
                : null,
              Settings.isRevolve() ? {left: 10} : null,
            ]}
          >
            {Localized.Labels.pending_upper_case}
          </AVText>
          {chevron}
        </View>
      );
    } else {
      const last4 = props.payrollId?.replace(/\d(?=\d{4})/g, 'x');
      additionalText = (
        <AVText
          accessibilityLabel={Localized.Labels.last_four_of_card}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.last_four_of_card}, text`}
          style={[
            getWalletStyles()['cardNumber'],
            Settings.isRevolve()
              ? getWalletStyles()['revolvePendingRightView']
              : null,
          ]}
        >
          {last4}
        </AVText>
      );
      rightView = (
        <View
          style={[
            getWalletStyles()['pendingView'],
            //Settings.isRevolve() ? {margin: Styles.Spacing.m4 + 4} : null,
            {alignItems: 'center'},
          ]}
        >
          {!Settings.isRevolve() ? (
            <>{chevron}</>
          ) : (
            <View
              style={{
                margin: Styles.Spacing.m3,
                alignContent: 'center',

                left: 15,
              }}
            >
              {chevron}
            </View>
          )}
        </View>
      );
    }

    footerComp.push(
      <TouchableOpacity
        accessible={true}
        onPress={callbacks[1]}
        style={styles.tileDesign}
        accessibilityLabel="payrollDeductButton"
        aria-label="payrollDeductButton"
        role="button"
      >
        <View style={getWalletStyles()['creditCardInfo']}>
          <View
            //style={{}}
            accessibilityLabel="Payroll image"
            accessibilityRole="image"
            aria-label="Payroll image"
            role="img"
          >
            <CreditCardImage
              issuer={AccountConstants.PAYROLL_TOKEN.toString()}
            />
          </View>
          <View style={{padding: Styles.Spacing.m2 + 4}}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              accessibilityLabel={Localized.Labels.payroll_deduct}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.payroll_deduct}, text`}
              style={[
                getWalletStyles()['label'],
                Settings.isRevolve()
                  ? getWalletStyles()['revolveMainTxt']
                  : null,
              ]}
            >
              {Localized.Labels.payroll_deduct}
            </AVText>
            {additionalText}
          </View>
        </View>
        {rightView}
      </TouchableOpacity>,
    );
  }

  return <>{footerComp}</>;
}

export default {
  getBalancesList,
  getBalancesTitle,
  getCardsTitle,
  getWalletStyles,
  getWalletTopButtonsSection,
  getWalletBottomSection,
  getWalletBalanceSection,
  getWalletCreditCardsSection,
};
