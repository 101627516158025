import {useContext, useEffect, useState} from 'react';
import Styles from 'src/components/Styles';
import {StyleSheet} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import MainConsumerContext from 'src/components/MainConsumerContext';
import Localized from 'src/constants/AppStrings';

const margin = Settings.isRevolve()
  ? Styles.Spacing.m3
  : Styles.Spacing.m3 + Styles.Spacing.m2;

const RewardsView = () => {
  const [isRewardsEnabled, setIsRewardsEnabled] = useState(false);
  const context = useContext(MainConsumerContext);
  useEffect(() => {
    checkRewardsStatus();
    FirebaseAnalytic.trackEvent('componentDidMount', 'RewardsView');
    AccountStore.addChangeListener(checkRewardsStatus);
    return () => {
      AccountStore.removeChangeListener(checkRewardsStatus);
    };
  }, []);

  //checkRewardsStatus based on User and Home location settings
  const checkRewardsStatus = () => {
    if (context?.state?.rewards > 0) {
      setIsRewardsEnabled(true);
    } else {
      setIsRewardsEnabled(false);
    }
  };

  //Send a snack action
  const onRewardsClick = () => {
    FirebaseAnalytic.trackEvent('onRewardsClick', 'RewardsView');
    NavActions.navigate(AppRoutes.Rewards);
  };
  const describerprops = {
    styles,
    stat:
      context?.state?.rewards &&
      Localized.Labels.formatString(
        Localized.Labels.balance_points,
        context?.state?.rewards,
      ),
    onRewardsClick,
  };

  return isRewardsEnabled
    ? getDescriber().rewardsViewDescriptor(describerprops)
    : null;
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: margin,
    justifyContent: 'center',
    paddingHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    marginTop: margin,
    ...Styles.Style.shadow,
  },
  iconView: {
    width: Styles.Spacing.m4,
    borderRadius: Styles.Spacing.m2,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Styles.primaryColor,
    height: Styles.Spacing.m4,
  },
  headerView: {
    justifyContent: 'flex-start',
    height: Styles.Heights.h4,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Styles.Spacing.m2,
  },
  snackButton: {
    marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
    borderWidth: 1,
  },

  titleText: {
    marginLeft: Styles.Spacing.m3,
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '600',
  },
  snackDescriptionText: {
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    textAlign: 'left',
    fontSize: Styles.Fonts.sectionHeader,
    fontWeight: '400',
    marginVertical: Styles.Spacing.m1,
  },

  snackButtonText: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '600',
  },
  revolveButtonStyle: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '600',
  },

  label: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
  },
  cardNumber: {
    fontSize: Styles.Fonts.f1,
    color: Styles.lightGray,
  },
  revolveAddTxt: {
    color: Styles.black,
    fontWeight: '400',
    fontSize: 18,
  },
  starIconView: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  revolveMainTxt: {
    fontWeight: '700',
    fontSize: 18,
    color: Styles.black,
    fontFamily: Styles.FontFamily.comodoVintage,
  },
});

export default RewardsView;
