import React from 'react';
import {StyleSheet, View} from 'react-native';
import Styles from '../../../Styles';
import AVText from '../../AVText';
import {GiftItem} from 'src/types/Snack';
import Util from 'src/Util';
import Localized from 'src/constants/AppStrings';
import {SnackStatus} from 'src/redux/slices/snackSlice';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';

import {getDescriber} from '../descriptor/DescriptorType';
type SnackDetailProps = {
  snackDetail: GiftItem;
};

const SnackDetail: React.FC<SnackDetailProps> = ({snackDetail}) => {
  const {status, senderAccountId, amount, email} = snackDetail;
  const isSender = senderAccountId === AccountStore.getAccountId();

  const formattedSnackDate = Util.formatDate(
    snackDetail?.lastUpdated,
    'MMM D, YYYY [at] h:mm A',
  );

  const getSnackStatusMessage = () => {
    if (status === SnackStatus.Accepted) {
      return (
        getDescriber().payPrefixTxt() + Localized.Labels.snack_has_been_accepted
      );
    } else if (status === SnackStatus.Cancelled) {
      return (
        getDescriber().payPrefixTxt() +
        Localized.Labels.snack_has_been_cancelled
      );
    } else if (status === SnackStatus.Expired) {
      return getDescriber().payPrefixTxt() + Localized.Labels.snack_has_expired;
    } else if (status === SnackStatus.Sent) {
      return Localized.Labels.your_snack_sent;
    }
  };
  const getSnackStatusForReceiver = () => {
    if (status === SnackStatus.Accepted) {
      return Localized.Labels.has_been_added_to_your_account;
    } else if (status === SnackStatus.Cancelled) {
      return Localized.Labels.gift_has_been_cancelled;
    } else if (status === SnackStatus.Sent) {
      return Localized.Labels.accept_snack_to_add_to_your_account;
    } else if (status === SnackStatus.Expired) {
      return Localized.Labels.gift_has_expired;
    }

    return '';
  };
  return (
    <View style={[styles.snackContainer, getDescriber().paySnackContainer()]}>
      {snackDetail?.externalServiceId || snackDetail?.historyId ? (
        <>
          <View style={[styles.row, getDescriber().paySnackRow()]}>
            {getDescriber().giftIconSnack()}
          </View>
          <View style={[styles.leftView]}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={snackDetail.BalanceTypeDisplay}
              aria-label={snackDetail.BalanceTypeDisplay}
              style={[styles.labelStyle, getDescriber().payBalanceType(styles)]}
            >
              {snackDetail.BalanceTypeDisplay}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={`${Localized.Labels.date} ${formattedSnackDate}`}
              aria-label={`${Localized.Labels.date} ${formattedSnackDate}`}
              style={[styles.snackDate, getDescriber().payDteDisplay(styles)]}
            >
              {formattedSnackDate}
            </AVText>
            {amount && (
              <View style={[!Settings.is365Pay() && styles.snackMessage]}>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  accessible={true}
                  accessibilityLabel={`${Localized.Labels.we_have_sent_your_snack_to} ${snackDetail.email}.`}
                  aria-label={`${Localized.Labels.we_have_sent_your_snack_to} ${snackDetail.email}.`}
                  style={[
                    styles.snackEmail,
                    getDescriber().paydescription(styles),
                    {
                      paddingBottom: 0,
                    },
                  ]}
                  numberOfLines={2}
                >
                  {isSender
                    ? `${Localized.Labels.we_have_sent_your_snack_to}`
                    : Localized.Labels.formatString(
                        Localized.Labels.you_have_received_a_snack_summary,
                        Util.numberWithCommas(
                          Util.formatCurrency(
                            amount,
                            amount,
                            AccountStore.getCurrency(),
                          ),
                        ),
                      )}
                </AVText>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  accessible={true}
                  accessibilityLabel={`${snackDetail.email}.`}
                  aria-label={`${snackDetail.email}.`}
                  style={[
                    styles.snackEmail,
                    getDescriber().paydescription(styles),
                    {
                      paddingTop: -1,
                    },
                  ]}
                  numberOfLines={1}
                >
                  {`${email}.`}
                </AVText>
                <AVText
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                  accessible={true}
                  accessibilityLabel={getSnackStatusMessage()}
                  aria-label={getSnackStatusMessage()}
                  style={[styles.snackStatus, getDescriber().paystatus(styles)]}
                  numberOfLines={2}
                >
                  {isSender
                    ? getSnackStatusMessage()
                    : `${Util.numberWithCommas(
                        Util.formatCurrency(
                          snackDetail,
                          amount,
                          AccountStore.getCurrency(),
                        ),
                      )} ${getSnackStatusForReceiver()}`}
                </AVText>
              </View>
            )}
          </View>
        </>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  snackContainer: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    marginTop: Styles.Heights.h2 + 4,
    paddingLeft: Styles.Heights.h2,
  },
  labelStyle: {
    color: Styles.black,
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    alignSelf: 'center',
    marginLeft: Styles.Spacing.m2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  snackDate: {
    fontSize: Styles.Fonts.f8,
    color: Styles.black,
    fontWeight: '400',
    paddingLeft: Styles.Heights.h2 + 4,
    marginTop: Styles.Spacing.m1 + 2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  snackMessage: {
    paddingLeft: Styles.Heights.h2 + 4,
    marginTop: Styles.Spacing.m3,
    width: 343,
  },
  snackEmail: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  snackStatus: {
    color: Styles.black,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    marginTop: Styles.Heights.h2,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  paybalanceTypeDisplay: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f2 - 1,
    fontWeight: '700',
    color: Styles.tabBarTextColor,
    alignSelf: 'flex-start',
  },
  leftView: {
    flex: 0.7,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    top: 10,
  },
  payDteDisplay: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1 - 2,
    fontWeight: '400',
    color: Styles.tabBarTextColor,
    paddingLeft: 8,
    marginTop: -1,
  },
  paydescription: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f1,
    fontWeight: '400',
    color: Styles.tabBarTextColor,
    paddingHorizontal: 8,
    paddingVertical: 14,
  },
});
export default SnackDetail;
