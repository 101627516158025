import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import Styles from '../../../../Styles';
import {
  FlatList,
  Platform,
  RefreshControl,
  ScrollView,
  StyleProp,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import FontAwesome5Pro from 'src/components/icons/FontAwesomeIcon';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import AVText from 'src/components/elements/AVText';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import CreditCard from 'src/models/CreditCard';
import {WithLocalizedProps} from 'src/types/PropTypes';
import CreditCardImage from 'src/components/elements/CreditCardImage';
import Star from 'src/components/img/svg/inbox/Star';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import Settings from 'src/Settings';
import AccountConstants from 'src/constants/AccountConstants';
import BalanceItem from 'src/components/elements/account/BalanceItem';
import {BalanceTypes} from 'src/types/serverTypes/Account';
import Util from 'src/Util';
import EmptyBalance from 'src/components/img/svg/cards/emptyBalance';
import EmptyCards from 'src/components/img/svg/cards/emptyCards';
import AccountStore from 'src/stores/AccountStore';

export function getWalletTopButtonsSection(callbacks): JSX.Element {
  const marketCard = AccountStore.getMarketCard();
  const marketCardLabel = marketCard
    ? Localized.Labels.edit_market_card
    : Localized.Labels.add_market_card;
  return (
    <ScrollView
      horizontal
      style={{flexGrow: 0}}
      contentContainerStyle={getWalletStyles()['scrollContainer']}
    >
      <View style={getWalletStyles()['buttonsView']}>
        {getRoundedAppButton(
          Localized.Labels.add_egift_card,
          'Double tap to add new egift card',
          callbacks[0],
        )}

        {getRoundedAppButton(
          marketCardLabel,
          'Double tap to add new market card',
          callbacks[2],
        )}

        {getRoundedAppButton(
          Localized.Labels.add_credit_card,
          'Double tap to add new credit card',
          callbacks[1],
          true,
        )}
      </View>
    </ScrollView>
  );
}

function getRoundedAppButton(label, hint, onPress, isLastItem?: boolean) {
  return (
    <RoundedAppButton
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
      accessibilityLabelValue={label}
      accessibilityHintValue={hint}
      aria-label={label}
      buttonViewStyle={[
        {borderWidth: 2, backgroundColor: Styles.white, height: 40},
      ]}
      buttonContainerStyle={[
        getWalletStyles()['addBtn'],
        isLastItem ? {marginRight: 25} : null,
      ]}
      buttonType={RoundedAppButtonType.Outline}
      titleText={label}
      onPress={onPress}
    />
  );
}
function getListCardsTitle(): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
      style={getWalletStyles()['balancesTitle']}
    >
      {Localized.Labels.cards_wallet}
    </AVText>
  );
}

function getListTitle(listIndex): JSX.Element {
  if (listIndex == 0) {
    return getListBalancesTitle();
  } else {
    return getListCardsTitle();
  }
}

function getListBalancesTitle(): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
      style={getWalletStyles()['balancesTitle']}
    >
      {Localized.Labels.balances}
    </AVText>
  );
}

function getCardsTitle(): JSX.Element {
  return (
    <AVText
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm0}
      style={getWalletStyles()['balancesTitle']}
    >
      {Localized.Labels.cards_wallet}
    </AVText>
  );
}

export function getBalancesList() {
  return null;
}

export function getWalletBottomSection(): JSX.Element {
  return null;
}

export function getWalletBalanceSection(): JSX.Element {
  return null;
}

function remodel(creditCards, balances) {
  if (balances?.length === 0) {
    balances = [
      {
        balance: 0,
        currency: 'USD',
        isDefault: true,
        isDisabled: false,
        isSubsidy: false,
        type: 'DEFAULT',
      },
    ];
  }
  return [getSortedOrder(balances), creditCards];
}

const BalanceOrder = [
  BalanceTypes.Default,
  BalanceTypes.Payroll,
  BalanceTypes.RollOver,
  BalanceTypes.TopOff,
];

function getSortedOrder(balances) {
  return balances.slice().sort((a, b) => {
    return BalanceOrder.indexOf(a.type) - BalanceOrder.indexOf(b.type);
  });
}

export function getWalletCreditCardsSection(
  props,
  state,
  callbacks,
  onRefresh,
): JSX.Element {
  return (
    <View style={{flex: 1}}>
      <FlatList
        data={remodel(props.creditCards, props.displayBalances)}
        renderItem={({item, index}) =>
          getSection(
            state,
            callbacks[0],
            item,
            index,
            props.displayBalances.length - 1,
          )
        }
        automaticallyAdjustContentInsets={false}
        ListFooterComponent={() => renderPayroll(props, state, callbacks[1])}
        extraData={state}
        keyExtractor={(item) => item}
        contentContainerStyle={{
          paddingHorizontal: Settings.isNewUI() ? Styles.Spacing.m3 : 0,
        }}
        refreshControl={
          <RefreshControl
            refreshing={state.isRefreshing}
            onRefresh={onRefresh}
            tintColor={Styles.primaryColor}
            colors={[Styles.primaryColor]}
            titleColor={Styles.black}
          />
        }
      />
    </View>
  );
}

function getListStyle(listIndex): {
  [index: string]: {[index: string]: number | string};
} {
  if (listIndex == 0) {
    // Show border only for Balances list
    return {
      style: {
        borderRadius: 8,
        backgroundColor: Styles.white,
        borderWidth: 1,
        borderColor: Styles.Colors.PayNew.neutralHuesBase11,
      },
    };
  } else {
    return {style: {}};
  }
}

function getBalanceItemBorder(
  itemIndex,
  balanceArrLength,
): {
  [index: string]: {[index: string]: number | string};
} {
  if (itemIndex < balanceArrLength) {
    return {
      style: {
        borderBottomColor: '#909090',
        borderBottomWidth: 0.2,
      },
    };
  } else {
    return {};
  }
}

function getSection(state, callback, listItem, listIndex, balanceArrLength) {
  return (
    <View>
      {getListTitle(listIndex)}
      {showListOrEmpty(state, callback, listItem, listIndex, balanceArrLength)}
    </View>
  );
}

function whichListType(listItem) {
  if (listItem && listItem[0]) {
    if (listItem[0].balance) {
      return 'Balance';
    } else {
      return 'Cards';
    }
  }
}

function showListOrEmpty(
  state,
  callback,
  listItem,
  listIndex,
  balanceArrLength,
) {
  const listType = whichListType(listItem);
  if (listItem.length > 0) {
    return (
      <View style={getListStyle(listIndex)['style']}>
        <FlatList
          data={listItem}
          renderItem={({item, index}) =>
            renderBalanceOrCreditCard(
              callback,
              item,
              balanceArrLength,
              listIndex,
              index,
            )
          }
          automaticallyAdjustContentInsets={true}
          extraData={state}
          keyExtractor={(item) => item.id}
          contentContainerStyle={{
            paddingHorizontal: Settings.isNewUI() ? Styles.Spacing.m3 : 0,
          }}
        />
      </View>
    );
  } else {
    if (listType == 'Balance') {
      return getBalancesEmptyList();
    } else {
      if (!state.showPayroll) return getCardsEmptyList();
    }
  }
}

function getBalancesEmptyList() {
  return (
    <View style={getWalletStyles()['noCardsOrBalancesImage']}>
      <EmptyBalance size={100}></EmptyBalance>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={getWalletStyles()['noCardsOrBalances']}
      >
        {Localized.Labels.no_balances}
      </AVText>
    </View>
  );
}

function getCardsEmptyList() {
  return (
    <View style={getWalletStyles()['noCardsOrBalancesImage']}>
      <EmptyCards size={100}></EmptyCards>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={[getWalletStyles()['noCardsOrBalances'], {marginTop: 0}]}
      >
        {Localized.Labels.no_cards_added}
      </AVText>
    </View>
  );
}

function renderBalanceOrCreditCard(
  callback,
  item,
  balanceArrLength,
  listIndex,
  itemIndex,
) {
  if (listIndex == 1) {
    return (
      <CreditCardCell
        key={item.id}
        strings={Localized}
        creditCard={item}
        onCreditCardClick={callback}
      />
    );
  } else if (listIndex == 0) {
    return (
      <View style={getBalanceItemBorder(itemIndex, balanceArrLength)['style']}>
        <BalanceItem balanceItem={item} />
      </View>
    );
  }
}

function getCardExpiredLayout(creditCard): JSX.Element {
  if (Util.isCreditCardExpired(creditCard)) {
    return (
      <View
        style={{
          borderRadius: 4,
          paddingHorizontal: 12,
          paddingVertical: 4,
          backgroundColor: '#F5DFE2',
        }}
      >
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={{
            fontSize: Styles.Fonts.f8,
            fontFamily: Styles.FontFamily.aeonikRegular,
            color: Styles.dangerColor,
          }}
        >
          {Localized.Labels.wallet_card_expired}
        </AVText>
      </View>
    );
  } else {
    return null;
  }
}

type CreditCardCellProps = WithLocalizedProps & {
  onCreditCardClick: (creditCard) => void;
  creditCard: CreditCard;
};

const CreditCardCell: React.FC<CreditCardCellProps> = ({
  onCreditCardClick,
  creditCard,
  strings,
}) => (
  <AVTouchableOpacity
    accessible={true}
    accessibilityRole="button"
    accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
    role="button"
    onPress={() => onCreditCardClick(creditCard)}
    accessibilityLabel="onCreditCardClick"
    aria-label="onCreditCardClick"
  >
    <View style={[getWalletStyles()['tileDesign']]}>
      <View style={[getWalletStyles()['creditCardInfo']]}>
        <View
          accessibilityLabel={`Issuer ${creditCard.cardIssuer}`}
          accessibilityRole="image"
          aria-label={`Issuer ${creditCard.cardIssuer}`}
          role="img"
          style={[
            getWalletStyles()['cardRoundedView'],
            getAmexExtraSVGPadding(creditCard.cardIssuer),
          ]}
        >
          <CreditCardImage
            issuer={creditCard.cardIssuer}
            style={getWidthAndHeightCombination(creditCard.cardIssuer)}
          />
        </View>
        <View style={{padding: Styles.Spacing.m2 + 4}}>
          <View style={getWalletStyles()['creditCardExpView']}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
              accessibilityLabel={strings.Labels.credit_card}
              accessibilityRole="text"
              aria-label={`${strings.Labels.credit_card}, text`}
              style={[
                getWalletStyles()['creditCardLabel'],
                Settings.isRevolve()
                  ? getWalletStyles()['revolveMainTxt']
                  : null,
              ]}
            >
              {strings.Labels.credit_card}
            </AVText>
            {getCardExpiredLayout(creditCard)}
          </View>
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessibilityLabel={creditCard.name}
            accessibilityRole="text"
            aria-label={`${creditCard.name}, text`}
            style={[
              getWalletStyles()['cardNumber'],
              Settings.isRevolve() ? getWalletStyles()['revolveAddTxt'] : null,
            ]}
          >
            {getCreditCardNumber(creditCard.name)}
          </AVText>
        </View>
      </View>
      {creditCard.isDefault && (
        <View style={[getWalletStyles()['starIconView'], {}]}>
          <View
            accessibilityLabel="Default payment method, "
            aria-label="Default payment method, "
            style={getWalletStyles()['starIcon']}
          >
            <Star
              fillColor={Styles.Colors.PayNew.primary01}
              width={24}
              height={24}
            />
          </View>
        </View>
      )}
      <View
        style={[
          getWalletStyles()['chevronView'],
          //creditCard.isDefault ? {left: 20} : {left: 40},
        ]}
      >
        {Settings.isRevolve() ? (
          <ChevronRight />
        ) : (
          <FontAwesome5Pro
            accessibilityLabel="Double tap to edit payment method"
            aria-label="Double tap to edit payment method"
            name="chevron-right"
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        )}
      </View>
    </View>
  </AVTouchableOpacity>
);

function getCreditCardNumber(ccNumber: string) {
  return 'x' + ccNumber?.slice(-4);
}

function renderPayroll(props, state, callback) {
  const footerComp: Array<JSX.Element> = [];
  if (state.showPayroll) {
    const chevron = Settings.isRevolve() ? (
      <View>
        <ChevronRight />
      </View>
    ) : (
      <FontAwesome5Pro
        accessibilityLabel="View payroll information"
        aria-label="View payroll information"
        name="chevron-right"
        color={Styles.lightGray}
        size={Styles.Fonts.f2}
        light
      />
    );
    let rightView;
    let additionalText;

    if (state.payrollPending) {
      additionalText = (
        <View
          style={[
            getWalletStyles()['pendingView'],
            //Settings.isRevolve() ? {margin: Styles.Spacing.m3} : null,
            {alignItems: 'center'},
          ]}
        >
          <AVText
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessibilityLabel={Localized.Labels.pending_upper_case}
            accessibilityRole="text"
            aria-label={`${Localized.Labels.pending_upper_case}, text`}
            style={[
              getWalletStyles()['cardNumber'],
              getWalletStyles()['pending'],
            ]}
          >
            {Localized.Labels.pending_upper_case}
          </AVText>
        </View>
      );
      rightView = <View style={{alignSelf: 'center'}}>{chevron}</View>;
    } else {
      additionalText = (
        <AVText
          accessibilityLabel={Localized.Labels.last_four_of_card}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.last_four_of_card}, text`}
          style={[
            getWalletStyles()['cardNumber'],
            Settings.isRevolve()
              ? getWalletStyles()['revolvePendingRightView']
              : null,
          ]}
        >
          {getCreditCardNumber(props.payrollId)}
        </AVText>
      );
      rightView = (
        <View
          style={[getWalletStyles()['pendingView'], {alignItems: 'center'}]}
        >
          {!Settings.isRevolve() ? (
            <>{chevron}</>
          ) : (
            <View
              style={{
                margin: Styles.Spacing.m3,
                alignContent: 'center',

                left: 15,
              }}
            >
              {chevron}
            </View>
          )}
        </View>
      );
    }

    footerComp.push(
      <TouchableOpacity
        accessible={true}
        onPress={callback}
        style={[
          Settings.isRevolve()
            ? getWalletStyles()['tileDesign']
            : getWalletStyles()['tileDesign'],
        ]}
        accessibilityLabel="payrollDeductButton"
        aria-label="payrollDeductButton"
        role="button"
      >
        <View style={getWalletStyles()['creditCardInfo']}>
          <View
            style={getWalletStyles()['cardRoundedView']}
            accessibilityLabel="Payroll image"
            accessibilityRole="image"
            aria-label="Payroll image"
            role="img"
          >
            <CreditCardImage
              issuer={AccountConstants.PAYROLL_TOKEN_REDESIGN.toString()}
              style={getWidthAndHeightCombination(
                AccountConstants.PAYROLL_TOKEN_REDESIGN.toString(),
              )}
            />
          </View>
          <View style={{padding: Styles.Spacing.m2 + 4}}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              accessibilityLabel={Localized.Labels.payroll_deduct}
              accessibilityRole="text"
              aria-label={`${Localized.Labels.payroll_deduct}, text`}
              style={[
                getWalletStyles()['label'],
                Settings.isRevolve()
                  ? getWalletStyles()['revolveMainTxt']
                  : null,
              ]}
            >
              {Localized.Labels.payroll_deduct}
            </AVText>
            {additionalText}
          </View>
        </View>
        {rightView}
      </TouchableOpacity>,
    );
  }

  return <>{footerComp}</>;
}

function getAmexExtraSVGPadding(issuer): StyleProp<ViewStyle> {
  switch (issuer.toLowerCase()) {
    case 'american-express':
    case 'american express':
    case 'americanexpress':
    case 'amex':
      return getWalletStyles()['amexSVGPadding'];
  }

  return null;
}

function getWidthAndHeightCombination(creditCardType: string): {
  [index: string]: {[index: string]: number | string};
} {
  switch (creditCardType.toLowerCase()) {
    case 'visa':
      return {size: {width: 30, height: 20}};
    case 'dinersclub':
      return {size: {width: 30, height: 20}};
    case 'jcb':
      return {size: {width: 30, height: 20}};
    case 'american-express':
    case 'american express':
    case 'americanexpress':
    case 'amex':
      return {size: {width: 35, height: 35}};

    case 'discover':
      return {size: {width: 30, height: 20}};

    case 'master-card':
    case 'mastercard':
      return {size: {width: 30, height: 20}};

    case AccountConstants.APPLE_PAY_TOKEN:
      return {size: {width: 30, height: 20}};

    case AccountConstants.GOOGLE_PAY_TOKEN:
      return {size: {width: 30, height: 20}};

    case AccountConstants.PAYROLL_TOKEN:
      return {size: {width: 30, height: 20}};
    case AccountConstants.PAYROLL_TOKEN_REDESIGN:
      return {size: {width: 30, height: 20}};
  }
}

export function getWalletStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
    },
    buttonsView: {
      flexDirection: 'row',
      alignItems: 'center',
      flexGrow: 0,
    },
    scrollContainer: {
      marginHorizontal: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m3 - 4,
    },
    buttonContainer: {
      height: 55,
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      borderWidth: 1,
      borderColor: Styles.darkGreenRevolve,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    balancesTitle: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      color: Styles.tabBarTextColor,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m2 - 2,
    },
    addButtonText: {
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
      fontSize: Styles.Fonts.f8,
      fontWeight: '700',
      color: Styles.black,
      textAlignVertical: 'center',
    },
    btnStyle: {
      alignSelf: 'center',
    },
    safeAreaView: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    creditCard: {
      alignItems: 'center',
      backgroundColor: Styles.white,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: Styles.Spacing.m3,
      paddingLeft: Styles.Spacing.m2,
      borderBottomColor: Styles.lightGray,
    },
    creditCardInfo: {
      alignItems: 'center',
      flexDirection: 'row',
    },
    cardNumber: {
      marginTop: Styles.Spacing.m1,
      color: Styles.tabBarTextColor,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: Styles.Fonts.f7,
    },
    issuerImage: {
      alignSelf: 'flex-start',
      marginRight: Styles.Spacing.m2,
    },
    label: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
    },
    pending: {
      color: '#AA4E00',
      fontFamily: Styles.FontFamily.aeonikRegular,
      marginRight: Styles.Spacing.m2,
    },
    pendingView: {
      flexDirection: 'row',
    },
    starIconView: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1,
      marginRight: Styles.Spacing.m2,
    },
    chevronView: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    starIcon: {
      bottom: 2,
    },
    subLabel: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f0,
    },
    blurBackground: {
      position: 'absolute',
      top: Styles.Spacing.m0,
      bottom: Styles.Spacing.m0,
      left: Styles.Spacing.m0,
      right: Styles.Spacing.m0,
      backgroundColor: Styles.overlay,
    },
    modal: {
      flex: 1,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContainer: {
      width: Styles.ScreenWidth / 1.1,
      height:
        Platform.OS === 'ios'
          ? Styles.ScreenHeight / 3
          : Styles.ScreenHeight / 2,
      borderRadius: 10,
    },
    subModalContainer: {
      backgroundColor: Styles.addCardPopupColor,
      paddingHorizontal: Styles.Spacing.m2,
      paddingVertical: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cancelText: {
      color: Styles.primaryColor,
    },
    boldText: {
      fontWeight: 'bold',
      color: Styles.darkColor,
    },
    cardForm: {
      height: Styles.ScreenHeight / 2,
      marginTop: Styles.Spacing.m3,
    },
    amexSVGPadding: {
      paddingRight: 4,
    },
    cardRoundedView: {
      padding: Styles.Spacing.m2,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 50,
      width: 50,
      height: 50,
      backgroundColor: Styles.Colors.PayNew.primary01,
    },
    cardStyle: {
      backgroundColor: Styles.addCardPopupColor,
    },
    addView: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: Styles.Spacing.m3 + 8,
      marginLeft: Styles.Spacing.m2,
    },
    addViewWrapper: {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginBottom: Styles.Spacing.m3,
    },
    addBtn: {
      marginRight: Styles.Spacing.m2 + 2,
      //paddingVertical: Styles.Spacing.m1,
      marginBottom: Styles.Spacing.m2,
    },
    nocardsContainer: {
      width: 300,
      height: 450,
      marginVertical: 'auto',
      padding: 120,
      top: -40,
    },
    addBtnIcon: {
      marginLeft: 8,
    },
    noCardsOrBalancesImage: {
      flex: 1,
      alignSelf: 'center',
      alignItems: 'center',
    },
    noCardsOrBalances: {
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.aeonikRegular,
      color: Styles.tabBarTextColor,
      marginTop: Styles.Spacing.m3,
    },
    circle: {
      width: 50,
      height: 50,
      borderRadius: 100 / 2,
      backgroundColor: Styles.primaryColor,
    },
    tileDesign: {
      width: '100%',
      height: 82,
      padding: Styles.Spacing.m3,
      flexDirection: 'row',
      backgroundColor: Styles.white,
      borderRadius: Styles.Spacing.m2,
      marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
      justifyContent: 'space-between',
      display: 'flex',
      paddingHorizontal: 15,
      paddingVertical: 10,
      ...Styles.Style.shadow,
    },
    revolveAddTxt: {fontWeight: '400', fontSize: 18, color: Styles.black},
    revolveMainTxt: {fontWeight: '700', fontSize: 18, color: Styles.black},
    revolvePendingRightView: {fontWeight: '400', fontSize: 16},
    creditCardLabel: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
      marginRight: Styles.Spacing.m3,
    },
    creditCardExpView: {
      flexDirection: 'row',
    },
  };
}

export default {
  getBalancesList,
  getWalletStyles,
  getCardsTitle,
  getWalletTopButtonsSection,
  getWalletBottomSection,
  getWalletBalanceSection,
  getWalletCreditCardsSection,
};
