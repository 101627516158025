import Styles from '../../../Styles';

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    linkLocationBtnStyle: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      color: Styles.primaryColor,
      textDecorationLine: 'underline',
    },
  };
}
export function backGroundColorHomeScreen(): string {
  return Styles.white;
}

export default {getCampusLocationStyles, backGroundColorHomeScreen};
