import AVText from 'src/components/elements/AVText';
import React from 'react';
import Styles from '../../../Styles';
import ChevronRight from 'src/components/img/svg/ChevronRight';
import {Dimensions, View, StyleSheet, Image} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {
  SendSnackDescriberData,
  AnnouncementStylesDescriptor,
  CampusLocationListStylesDescriptor,
  ReferralDescriberData,
  RewardskDescriberData,
  EngagementCarouselStylesDescriptor,
} from 'src/components/elements/home/descriptor/DescriptorType';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import GrpReferralRedesign from 'src/components/img/svg/GrpReferralredesign';
import RewardRedesignHome from 'src/components/img/svg/RewardRedesignHome';
const width = Dimensions.get('window').width;

export function getCampusLocationStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    recentLocationAddressText: {
      color: '#707070',
      fontSize: Styles.Fonts.f8,
      width: width - Styles.Spacing.m6,
      textAlignVertical: 'center',
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    recentLocationViewLocationNameText: {
      fontSize: Styles.Fonts.f1 + 2,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textAlignVertical: 'center',
      width: width - Styles.Spacing.m6,
      color: Styles.black,
    },
  };
}

export function snackViewDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  const {onSendASnackClick, styles} = props;
  return (
    <React.Fragment>
      <View
        style={[
          styles.headerView,
          {marginTop: -(Styles.Spacing.m3 + Styles.Spacing.m1)},
        ]}
      >
        <AVText
          accessibilityRole="text"
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          accessibilityLabel={Localized.Labels.gifting_title}
          style={{
            fontWeight: '700',
            paddingLeft: 16,
            fontSize: 22,
            paddingTop: 20,
            fontFamily: Styles.FontFamily.aeonikRegular,
            color: Styles.black,
          }}
          accessible={true}
          aria-label={`${Localized.Labels.gifting_title}, text`}
        >
          {Localized.Labels.gifting_title}
        </AVText>
      </View>
      <AVTouchableOpacity
        aria-label="onSnackGiftClick"
        accessible={true}
        accessibilityLabel="onSnackGiftClick"
        accessibilityHint="Double tap to navigate to the send a snack screen"
        accessibilityRole="button"
        role="button"
        onPress={onSendASnackClick}
      >
        <View
          style={[
            {marginLeft: 16, top: -10, marginRight: 16},
            styles.container,
          ]}
        >
          <View style={[{height: 100}, styles.starIconView]}>
            <View
              style={{top: 10}}
              aria-label={`SnackIcon`}
              role="img"
              accessibilityRole="image"
              accessibilityLabel={'SnackIcon'}
            >
              <Image
                accessibilityLabel="Snack gift"
                accessibilityRole="image"
                role="img"
                accessible={true}
                aria-label="Snack gift"
                style={{width: 70, height: 62}}
                resizeMode="contain"
                source={require('../../../img/paySnackGift.gif')}
              />
            </View>
            <View style={{padding: Styles.Spacing.m2 + 4, paddingBottom: -10}}>
              <AVText
                accessibilityRole="text"
                aria-label={`${Localized.Buttons.send_a_snack}, text`}
                style={[
                  {
                    fontSize: 20,
                    fontWeight: '700',
                    color: Styles.black,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    paddingVertical: 6,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                accessibilityLabel={Localized.Buttons.send_a_snack}
              >
                {Localized.Labels.send_a_snack_title}
              </AVText>

              <AVText
                accessibilityLabel={
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
                aria-label={
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
                style={[
                  {
                    color: Styles.black,
                    fontSize: 18,
                    width: '40%',
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontWeight: '400',
                  },
                ]}
                accessibilityRole="text"
              >
                {
                  Localized.Labels
                    .send_your_colleagues_tasty_snacks_to_brighten_their_day
                }
              </AVText>
            </View>
          </View>

          <View style={[{alignSelf: 'flex-end', top: -50}]}>
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function getScanAndPayTile(): {[key: string]: any} {
  return {
    iconViewBackground: {
      backgroundColor: Styles.primaryColor + '15',
      width: Styles.Heights.h5,
      height: Styles.Heights.h5,
      margin: Styles.Spacing.m3,
    },
    titleText: {
      color: Styles.black,
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.robotoRegular,
      paddingBottom: Styles.Spacing.m2,
    },
    nameText: {
      color: Styles.black,
      fontWeight: '400',
      fontSize: Styles.Fonts.f7,
      marginRight: Styles.Spacing.m6 - Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    rowSpace: {
      marginVertical: Styles.Spacing.m2,
    },
    listContainer: {
      marginTop: Styles.Spacing.m3,
    },
    textView: {
      marginVertical: Styles.Spacing.m3,
    },
  };
}

export function infoIconPrimaryColor() {
  return Styles.Colors.PayNew.primary01;
}
export function infoIconInnerColor() {
  return Styles.Colors.PayNew.support01;
}

export function announcemnentButtonColor() {
  return Styles.Colors.PayNew.primary01;
}

export function getAnnouncementStyles(): AnnouncementStylesDescriptor {
  return StyleSheet.create({
    container: {
      backgroundColor: Styles.Colors.PayNew.white01,
      marginTop: Styles.Spacing.m3 - 4,
      marginHorizontal: Styles.Spacing.m3,
      marginBottom: Styles.Spacing.m2 + 4,
      borderRadius: Styles.Spacing.m2,
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...Styles.Style.shadow,
    },
    titleText: {
      fontSize: Styles.Fonts.headerFont,
      width: '80%',
      fontWeight: '700',
      color: Styles.black,
      marginRight: Styles.Spacing.m3,
      marginLeft: Styles.Spacing.m2,
      textAlign: 'left',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    descriptionText: {
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '400',
      color: Styles.darkColor,
      marginRight: Styles.Spacing.m4,
      marginLeft: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
  });
}

export function getWidthImageAvailable() {
  return '75%';
}

export function getCampusLocationListStyles(): CampusLocationListStylesDescriptor {
  return StyleSheet.create({
    container: {
      marginTop: Styles.Spacing.m3,
      flex: 1,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      marginBottom: Styles.Spacing.m3,
    },
    campusLocationListView: {
      width: '100%',
    },
    headerMargin: {
      marginLeft: Styles.Spacing.m3,
      marginTop: -(Styles.Spacing.m3 + Styles.Spacing.m2),
    },
  });
}

export function referralViewDescriptor(
  props: ReferralDescriberData,
  amtLocalised?: string,
): JSX.Element {
  const {onInviteClick, styles, amount, isSnackEnabled} = props;
  const desc = Localized.Labels.formatString(
    Localized.Labels.receive_redesign_cash_to_spend,
    amtLocalised,
  );
  const title = Localized.Labels.formatString(
    Localized.Labels.give_get_redesign,
    amtLocalised,
    amtLocalised,
  );
  return (
    <React.Fragment>
      {!isSnackEnabled && (
        <View
          style={[
            styles.headerView,
            {
              marginTop: -(Styles.Spacing.m3 + Styles.Spacing.m1),
            },
          ]}
        >
          <AVText
            accessibilityRole="text"
            style={{
              fontWeight: '700',
              paddingLeft: 16,
              fontSize: 22,
              paddingTop: 20,
              fontFamily: Styles.FontFamily.aeonikRegular,
              color: Styles.black,
            }}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
            accessible={true}
            aria-label={`${Localized.Labels.recents}, text`}
            accessibilityLabel={Localized.Labels.recents}
          >
            {Localized.Labels.gifting_title}
          </AVText>
        </View>
      )}
      <AVTouchableOpacity
        accessible={true}
        onPress={onInviteClick}
        accessibilityLabel="onCreditCardClick"
        accessibilityHint="Double tap to navigate to the screen of the chosen card to update info or delete"
        role="button"
        aria-label="onCreditCardClick"
        accessibilityRole="button"
      >
        <View
          style={[
            styles.container,
            {
              top: -10,
              marginLeft: 16,
              marginRight: 16,
            },
          ]}
        >
          <View style={[styles.starIconView, {height: 100}]}>
            <View
              style={[{top: 10}]}
              role="img"
              accessibilityLabel={'grpreferralIcon'}
              accessibilityRole="image"
              aria-label={`grpreferralIcon`}
            >
              <GrpReferralRedesign
                width={56}
                height={53}
                gradColor1={Styles.Colors.PayNew.primary01}
                gradColor2={Styles.Colors.PayNew.support01}
              />
            </View>
            <View style={{padding: Styles.Spacing.m2 + 12, paddingBottom: -10}}>
              <AVText
                aria-label={`${title}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                accessibilityRole="text"
                style={[
                  {
                    fontWeight: '700',
                    paddingVertical: 6,
                    fontSize: 20,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    color: Styles.black,
                  },
                ]}
                accessibilityLabel={title as string}
              >
                {title}
              </AVText>

              <AVText
                accessibilityLabel={desc as string}
                aria-label={desc as string}
                accessibilityRole="text"
                style={[
                  {
                    width: '40%',
                    fontWeight: '400',
                    fontSize: 18,
                    color: Styles.black,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {desc}
              </AVText>
            </View>
          </View>

          <View style={[{top: -50, alignSelf: 'flex-end'}]}>
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function rewardsViewDescriptor(
  props: RewardskDescriberData,
): JSX.Element {
  const {onRewardsClick, styles, stat} = props;
  return (
    <React.Fragment>
      <AVTouchableOpacity
        aria-label="onRewardsClick"
        accessible={true}
        accessibilityLabel="onRewardsClick"
        accessibilityHint="Double tap to navigate to the rewards screen"
        accessibilityRole="button"
        role="button"
        onPress={onRewardsClick}
        style={{top: -20}}
      >
        <View
          style={[
            {marginLeft: 16, marginRight: 16, height: 85},
            styles.container,
          ]}
        >
          <View style={[styles.starIconView, {bottom: -6}]}>
            <View style={[{top: 8}]} accessibilityLabel={'SnackIcon'}>
              <RewardRedesignHome />
            </View>
            <View
              style={{
                paddingHorizontal: 16,
                paddingTop: 20,
              }}
            >
              <AVText
                accessibilityRole="text"
                style={[
                  {
                    color: Styles.black,
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontSize: 18,
                    paddingBottom: 10,

                    fontWeight: '700',
                  },
                ]}
                accessibilityLabel={Localized.Labels.rewards}
                aria-label={`${Localized.Labels.rewards}, text`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {Localized.Labels.rewards}
              </AVText>
              <AVText
                accessibilityRole="text"
                aria-label={`${stat}, text`}
                accessibilityLabel={`${stat}, text`}
                style={[
                  {
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontWeight: '700',
                    color: Styles.black,
                    paddingBottom: 10,
                    fontSize: 18,
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
              >
                {stat}
              </AVText>
            </View>
          </View>

          <View style={[{alignSelf: 'flex-end', top: -40}]}>
            <ChevronRight />
          </View>
        </View>
      </AVTouchableOpacity>
    </React.Fragment>
  );
}
export function engagementCarouselMargin(): number {
  return Styles.Spacing.m3;
}
export function engagementCarouselPaginationColor(): string {
  return Styles.Colors.PayNew.primary01;
}
export function engagementCarouselPaginationInactiveColor(): string {
  return Styles.Colors.PayNew.neutralHuesBase09;
}
export function engagementCarouselMarginBottom(): number {
  return -Styles.Spacing.m4 - Styles.Spacing.m4 + 4;
}
export function engagementCarouselMarginZeroLength(): number | undefined {
  return Styles.Spacing.m2;
}

export function getEngagementCarouselStyles(): EngagementCarouselStylesDescriptor {
  return StyleSheet.create({
    activeDotStyle: {
      width: 10,
      height: 10,
      borderRadius: 8,
    },
    inActiveDotStyle: {
      width: 10,
      height: 10,
      borderRadius: 8,
    },
    imageContentContainer: {
      paddingLeft: 0,
    },
    parralaxContainer: {
      backgroundColor: Styles.Colors.PayNew.neutralHuesBase09,
    },
  });
}

export default {
  getCampusLocationStyles,
  snackViewDescriptor,
  getScanAndPayTile,
  infoIconPrimaryColor,
  infoIconInnerColor,
  announcemnentButtonColor,
  getAnnouncementStyles,
  getWidthImageAvailable,
  getCampusLocationListStyles,
  referralViewDescriptor,
  rewardsViewDescriptor,
  engagementCarouselMargin,
  engagementCarouselPaginationColor,
  engagementCarouselPaginationInactiveColor,
  getEngagementCarouselStyles,
  engagementCarouselMarginBottom,
  engagementCarouselMarginZeroLength,
};
