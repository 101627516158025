import {ActivityIndicator, View, StyleSheet} from 'react-native';
import AVText from 'src/components/elements/AVText';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import {
  ManualEntryButtonProps,
  ProductCategorySearchDescriberData,
  ScanDiscountCodeStylesDescriptor,
} from 'src/components/screens/cart/descriptor/Descriptor';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getDoneButtonColor(): string {
  return Styles.primaryColor;
}
export function getReportButtonColor() {
  return null;
}
export function getReportButtonType() {
  return ButtonType.outline;
}

export function getReceiptStyles() {
  return {
    amount: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f1,
    },
    bold: {
      fontWeight: 'bold',
    },
    desc: {
      color: Styles.darkColor,
      flex: 1,
      fontSize: Styles.Fonts.f1,
    },
  };
}

export function getProductSearchEmptyContent(): JSX.Element {
  return (
    <View
      style={{
        marginTop: Styles.Spacing.m3,
      }}
    >
      <ActivityIndicator color={Styles.primaryColor} size="large" />
    </View>
  );
}
export function searchTopContainerDescriptor(): {
  [index: string]: number | string;
} {
  return {top: -30};
}

export function getSelectedProductCategoryNameContent(
  props: ProductCategorySearchDescriberData,
): JSX.Element {
  return (
    <View style={props.styles.banner}>
      <AVText style={props.styles.bannerText}>{props.category}</AVText>
    </View>
  );
}
export function productCategoryflatListStyleDescriptor(
  props: ProductCategorySearchDescriberData,
): {
  [index: string]: number | string;
} {
  return props.styles.list;
}

export function getScanDiscountStyles(): ScanDiscountCodeStylesDescriptor {
  return StyleSheet.create({
    descriptionText: {
      marginTop: Styles.Spacing.m2,
      marginHorizontal: Styles.Spacing.m3,
      fontSize: Styles.Fonts.f2,
    },
  });
}
export function getManualEntryButton(props: ManualEntryButtonProps) {
  return (
    <RoundedButton
      containerStyle={styles.btnManualEntry}
      buttonType={ButtonType.outline}
      onPress={props.handleManualEntry}
      accessible={true}
      accessibilityLabel={Localized.Labels.manual_entry}
      accessibilityHint="Double tap to enter your discount code manually on the next screen"
      aria-label={Localized.Labels.manual_entry}
      text={Localized.Labels.manual_entry}
    />
  );
}
const styles = StyleSheet.create({
  btnManualEntry: {
    alignSelf: 'flex-end',
    marginTop: Styles.Spacing.m3,
    marginRight: Styles.Spacing.m3,
  },
});
export default {
  getDoneButtonColor,
  getReportButtonColor,
  getReportButtonType,
  getReceiptStyles,
  getProductSearchEmptyContent,
  searchTopContainerDescriptor,
  getSelectedProductCategoryNameContent,
  productCategoryflatListStyleDescriptor,
  getScanDiscountStyles,
  getManualEntryButton,
};
