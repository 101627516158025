import FontAwesome5Pro from '../../icons/FontAwesomeIcon';
import {
  Animated,
  StyleSheet,
  View,
  Text,
  Platform,
  PixelRatio,
  TouchableWithoutFeedback,
  ColorValue,
} from 'react-native';
import Eyelid from 'src/components/img/svg/eyelid';
import EyelidOpen from 'src/components/img/svg/eyelid_open';
import {
  DealProps,
  PasswordInpProps,
  RoundedBtnDescriberProps,
  SnackItemDescriberProps,
  CollapsibleComponentProps,
  StylesDescriptor,
  NumberSpinnerStylesDescriptor,
  ReferralItemDescriberProps,
  RewardsSection,
} from 'src/components/elements/descriptor/DescriptorType';
import CirclePlusIcon from 'src/components/img/svg/CirclePlus';
import DealIcon from 'src/components/img/svg/DealIcon';
import GiftBox from 'src/components/img/svg/GiftBox';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import TimeUtils from 'src/services/TimeUtils';
import AccountStore from 'src/stores/AccountStore';
import Util from 'src/Util';
import ChevronDown from 'src/components/img/svg/ChevronDown';
import {ComponentSize} from '../../Hooks';
import {MenuOption} from '../../../types/MenuOption';
import CloseIcon from 'src/components/img/svg/CloseIcon';
import GiftUnReadIcon from 'src/components/img/svg/GiftBoxUnReadDot';
import DealUnreadIcon from 'src/components/img/svg/DealIconUnreadDot';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {WithGlobalizeProps} from 'react-native-globalize';
export function addNewPaymentTemplateDescriptor(): JSX.Element {
  return <CirclePlusIcon />;
}

export function roundedBtnColorDescriptor(props: RoundedBtnDescriberProps): {
  [index: string]: ColorValue;
} {
  const disabledColor = Styles.lightGray;
  let buttonColor = props.disabled
    ? disabledColor
    : props.color || Styles.primaryColor;
  let fillColor = Styles.primaryColor;
  let textColor = Styles.white;

  if (props.buttonType === 'outline') {
    fillColor = Styles.transparent;
    textColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
  } else if (props.buttonType === 'normal') {
    fillColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
    textColor = Styles.white;
  } else if (props.buttonType === 'normalWhite') {
    fillColor = props.disabled ? disabledColor : props.color || Styles.white;
    textColor = Styles.blueRevolve;
    buttonColor = Styles.blueRevolve;
  } else if (props.buttonType === 'innerWhite') {
    fillColor = props.disabled ? disabledColor : props.color || Styles.white;
    textColor = Styles.darkGreenRevolve;
    buttonColor = Styles.darkGreenRevolve;
  } else {
    textColor = Styles.white;
    fillColor = props.disabled
      ? disabledColor
      : props.color || Styles.primaryColor;
  }
  return {textColor, fillColor, buttonColor};
}
export function RewardPointStyleDescriptor(props: RewardsSection): {
  [index: string]: number | string;
} {
  return props.styles.pointNumberLabel;
}

export function stylePayAvailOption(styles) {
  return styles.availableOption;
}

export function getuserInitialsStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    initialsBtn: {
      flexDirection: 'row',
      justifyContent: 'center',
      margin: PixelRatio.roundToNearestPixel(0.5),
      backgroundColor: Styles.blueRevolve,
      borderRadius: PixelRatio.roundToNearestPixel(Styles.Spacing.m3),
      height: 72,
      width: 72,
    },
    text: {
      alignSelf: 'center',
      color: Styles.primaryColor,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    initialsText: {
      fontSize: Styles.Fonts.f2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}

export function getStyleWithColorOnly(): {[index: string]: string} {
  return {
    backgroundColor: '#7FBC0340',
    color: '#003349',
  };
}

export function getSettingsMenusStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    switchWithOptionText: {
      paddingHorizontal: Styles.Spacing.m3,
      marginVertical:
        Platform.OS == 'ios' ? Styles.Spacing.m3 : Styles.Spacing.m2,
    },
    optionText: {
      textAlign: 'center',
      paddingTop: 3,
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      minHeight: Styles.Heights.touchTargetHeight0,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    divider: {
      backgroundColor: Styles.dividerColor,
      height: 1,
    },
    enclosureContainer: {
      marginHorizontal: Styles.Spacing.m3,
      borderColor: Styles.inputBgColor,
      borderWidth: 1,
    },
    listShadow: Styles.Style.shadow,
  };
}

export function settingsMenuFontColorDescriptor(): string {
  return Styles.lightGrayRevolve;
}

type Formatted = number | string | JSX.Element;
export function snackMessageDescriptor(
  props?: SnackItemDescriberProps & WithGlobalizeProps,
): string | Formatted[] {
  const {Labels} = Localized;
  return Labels.formatString(
    Labels.you_have_received_a_snack,
    Util.formatCurrency(
      props,
      props?.snack.amount ?? 0,
      AccountStore.getCurrency(),
    ),
    props?.snack.senderName ?? '',
  );
}

export function getSnackItemStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      paddingVertical: Styles.Spacing.m3,
      justifyContent: 'center',
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
    },
    icon: {
      borderRadius: 32,
      width: 50,
      height: 50,
      backgroundColor: Styles.darkBlueRevolveColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    message: {
      color: Styles.black,
      flex: 1,
      fontSize: Styles.Fonts.f8,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    messageLeft: {
      flex: 1,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
    },
    subject: {
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      fontWeight: '600',
      marginBottom: Styles.Spacing.m2,
      marginEnd: Styles.Spacing.m1,
      marginTop: Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    chevronRight: {
      width: 24,
      height: 24,
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    arrow: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    topRight: {
      left: 10,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    date: {
      color: Styles.lightGray,
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    from: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontWeight: 'bold',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    referralStatusText: {
      fontSize: 14,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}
export function switchColor(): string {
  return null;
}

export function snackItemGiftBoxTemplateDescriptor(
  snackProps?: SnackItemDescriberProps,
): JSX.Element {
  return (
    <View style={getSnackItemStyleDescriptor()['arrow']}>
      {snackProps.snack.isRead && (
        <View style={getSnackItemStyleDescriptor()['icon']}>
          <GiftBox
            height={Styles.Fonts.f3}
            width={Styles.Fonts.f3}
            color={Styles.lightGreenRevolve}
          />
        </View>
      )}
      {!snackProps.snack.isRead && (
        <GiftUnReadIcon
          height={Styles.Fonts.f5}
          width={Styles.Fonts.f5}
          color={Styles.lightGreenRevolve}
        />
      )}
    </View>
  );
}

export function referralImageTemplateDescriptor(): JSX.Element {
  return (
    <View style={getSnackItemStyleDescriptor()['arrow']}>
      <FontAwesome5Pro
        name="star"
        color={Styles.goldColor}
        size={Styles.Fonts.f2}
        solid
      />
    </View>
  );
}

export function snackItemTitleDescriptor(): string {
  return Localized.Labels.send_a_snack_title;
}

export function snackItemDateDescriptor(
  props?: SnackItemDescriberProps,
): string {
  return TimeUtils.getTime(props?.snack.snackSent);
}
export function referralItemDateDescriptor(
  props?: ReferralItemDescriberProps,
): string {
  return TimeUtils.formatInboxDate(props.referral.dateCreated);
}
export function dealRedeemCount(text: string): JSX.Element {
  return (
    <Text
      style={[
        dealStyleDescriptor()['dealTarget'],
        dealStyleDescriptor()['successText'],
      ]}
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
    >
      {text}
    </Text>
  );
}

export function dealRenderExpirationTxtTemplate(
  props?: DealProps,
): JSX.Element {
  const time = TimeUtils.getTimeWithAMorPM(props?.deal?.endDate);
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
      style={dealStyleDescriptor()['expirationDate']}
      accessibilityLabel={time}
    >
      {time}
    </Text>
  );
}

export function dealRenderDealIcon(props?: DealProps): JSX.Element {
  return (
    <View style={props?.styles?.arrow}>
      {props.deal.isRead && (
        <View style={dealStyleDescriptor()['icon']}>
          <DealIcon
            width={Styles.Fonts.f3}
            height={Styles.Fonts.f3}
            bgColor={Styles.lightGreenRevolve}
          />
        </View>
      )}

      {!props.deal.isRead && (
        <DealUnreadIcon
          width={Styles.Fonts.f5}
          height={Styles.Fonts.f5}
          bgColor={Styles.lightGreenRevolve}
        />
      )}
    </View>
  );
}

export function dealRenderDealNameDesc(props?: DealProps): JSX.Element {
  return (
    <View>
      <Text
        style={dealStyleDescriptor()['dealDesc']}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm10}
        accessibilityLabel={props?.deal?.name}
      >
        {props?.deal?.name}
      </Text>
      <Text style={dealStyleDescriptor()['message']} numberOfLines={2}>
        {props?.deal?.message}
      </Text>
    </View>
  );
}

export function scanScreenQRCodeStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    btnAddFund: {},
    balanceText: {
      textTransform: 'uppercase',
      color: Styles.white,
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },

    btnAddToWallet: {},
    addFundsText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
      color: '#003349',
    },
    addToWalletText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontWeight: '700',
      fontSize: Styles.Fonts.f1,
      color: '#003349',
    },
    addFundsButtonContainer: {
      marginTop: Styles.Spacing.m3,
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
}

export function scanScreenQRCodeHeaderText(): string {
  return Localized.Labels.pay_at_kiosk.toUpperCase();
}

export function dealRenderDealTopSection(): string {
  return Localized.Labels.offers;
}
export function sendSnackOTSNBtnStyleDescriptor(): string {
  return Styles.darkGreenRevolve;
}

export function getSuccessDialogDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return null;
}

export function getSuccessDialogBtn() {
  return null;
}

export function dealStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    arrow: {
      marginVertical: Styles.Spacing.m3,
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m3,
      paddingHorizontal: Styles.Spacing.m3,
      paddingTop: Styles.Spacing.m3,
      paddingBottom: Styles.Spacing.m3,
      borderRadius: Styles.Spacing.m2,
    },
    icon: {
      borderRadius: 32,
      width: 50,
      height: 50,
      backgroundColor: Styles.darkBlueRevolveColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    expirationDate: {
      fontSize: Styles.Fonts.f1,
      left: 10,
      color: Styles.lightGray,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    dealName: {
      fontSize: Styles.Fonts.f7,
      marginBottom: Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
      flex: 1,
      marginRight: Styles.Spacing.m2,
      fontWeight: 'bold',
      color: Styles.darkColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    dealDesc: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '600',
      fontFamily: Styles.FontFamily.figtreeRegular,
      marginBottom: Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
      color: Styles.darkColor,
    },
    message: {
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      color: Styles.black,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.figtreeRegular,
      flex: 1,
    },
    successText: {
      marginTop: Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m2 + Styles.Spacing.m1,
      color: Styles.blueRevolve,
      fontWeight: '500',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    dealTarget: {
      fontSize: Styles.Fonts.f8,
      alignSelf: 'flex-start',
    },
  };
}
export function snackItemNumberDescriptor(): number {
  return 2;
}
export function dealItemNumberDescriptor(): number {
  return 2;
}
export function historyHeaderName(): string {
  return null;
}
export function headerContainerStyleDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      flex: 1,
      flexDirection: 'column',
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    safeArea: {
      backgroundColor: Styles.primaryColor,
    },
    headerTextStyle: {
      textAlign: 'left',
      fontSize: Styles.Fonts.f9,
      color: Styles.white,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.comodoVintage,
      height: Styles.Heights.touchTargetHeight1,
      marginTop: Platform.OS === 'ios' ? Styles.Spacing.m1 : Styles.Spacing.m0,
    },
  };
}
export function historyListStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    historyHeaderTitle: {
      fontWeight: '700',
      fontSize: Styles.Fonts.sectionHeader,
      paddingBottom: Styles.Spacing.m3,
      paddingTop: Styles.Heights.h2,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    historyHeaderTitleDes: {
      alignSelf: 'flex-start',
      color: Styles.black,
      flex: 2,
      fontSize: Styles.Fonts.f7,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    historyTabFontFamily: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
  };
}
export function historyDateBtnColor(): string {
  return Styles.lightGray;
}
export function fundingAmountColor(): string {
  return Styles.darkGreenRevolve;
}
export function sendSnackOTSNotificationStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    acceptSnackHeaderText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    acceptSnackText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    acceptSnackSubDescText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    saveContainer: {},
    acceptedSnactSubDesc: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
    },
    acceptedSnactHeaderText: {
      fontFamily: Styles.FontFamily.figtreeRegular,
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader + 2,
      fontWeight: '700',
      marginLeft: Styles.Spacing.m2,
    },
  };
}
export function referralTransactionId(): {
  [index: string]: number | string;
} {
  return {};
}

export function toggleColor() {
  return ['#707070', '#022649'];
}
export function collapsibleComponentDescriptor(): CollapsibleComponentProps {
  return {
    toValue: 180,
    toDegree: '180deg',
    content: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingHorizontal: Styles.Spacing.m3,
      marginHorizontal: Styles.Spacing.m3,
      marginVertical: Styles.Spacing.m2,
      ...Styles.Style.shadow,
    },
    parentContainer: {
      flex: 1,
      flexDirection: 'row',
      paddingVertical: Styles.Spacing.m2,
      justifyContent: 'flex-end',
    },
  };
}

export function getAnimatedViewDescriptor(
  rotate: Animated.AnimatedInterpolation<string>,
  props: {header: React.ReactNode},
): React.ReactNode {
  return (
    <>
      <View style={{flex: 3}}>{props.header}</View>
      <Animated.View
        style={[
          styles.chevron,
          {
            transform: [{rotate}],
          },
        ]}
      >
        <View style={{flex: 0.5}}>
          <FontAwesome5Pro
            name={'chevron-down'}
            color={Styles.lightGray}
            size={Styles.Fonts.f2}
            light
          />
        </View>
      </Animated.View>
    </>
  );
}

// allinput
const diffInputPadding = Platform.OS == 'ios' ? Styles.Spacing.m2 : 0;
const bottomPaddingInlineImage = Platform.OS == 'ios' ? 50 : 47;

export function getStyles(): StylesDescriptor {
  return {
    shadow: {
      shadowOffset: {
        width: 1,
        height: 1,
      },
      borderBottomWidth: 0.6,
      borderRadius: 8,
      elevation: 1,
      shadowRadius: 2,
      shadowOpacity: 0.3,
    },
    container: {
      marginTop: Styles.Spacing.m3,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    paymentDropDownInputContainer: {
      height: 55,
      padding: 2,
      flexDirection: 'row',
      borderColor: Styles.lightGray,
      marginBottom: Styles.Spacing.m2,
      borderWidth: 1,
      borderRadius: 10,
      backgroundColor: Styles.white,
      overflow: 'hidden',
    },
    paymentInput: {
      fontSize: Styles.Fonts.f7,
      color: Styles.darkColor,
      fontWeight: '700',
      flex: 1,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    input: {
      paddingHorizontal: Styles.Spacing.m1,
      paddingTop: Styles.Spacing.m3 + diffInputPadding,
      paddingBottom:
        Platform.OS == 'ios'
          ? Styles.Spacing.m2
          : Styles.Spacing.m1 + PixelRatio.getFontScale() * 2,
      borderWidth: 1,
      borderRadius: 8,
      fontSize: Styles.Fonts.f7,
      fontWeight: '500',
      marginBottom: 5,
      backgroundColor: Styles.white,
    },
    imageContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      right: 10,
      bottom: bottomPaddingInlineImage,
    },
    labelContainer: {
      position: 'absolute',
      paddingHorizontal: 8,
      backgroundColor: Styles.tabBarBackgroundColor,
    },
    label: {
      fontSize: 16,
      color: Styles.lightGray,
      fontFamily: Styles.FontFamily.figtreeRegular,
      backgroundColor: Styles.transparent,
    },
    error: {
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m1,
      fontSize: 12,
      color: Styles.dangerColor,
    },
    help: {
      marginTop: Styles.Spacing.m1,
      marginLeft: Styles.Spacing.m1,
      fontSize: Styles.Fonts.f8,
      color: Styles.lightGray,
    },
    leftIcon: {
      marginRight: 4,
      paddingEnd: 2,
      paddingVertical: 2,
      backgroundColor: Styles.white,
      borderRadius: 6,
    },
  };
}

export function getTopPadding(): {
  [index: string]: number | string;
} {
  return {paddingTop: 22};
}

export function getColor(
  isFocused: boolean,
  hasError: boolean,
  isSnack?: boolean,
) {
  if (hasError) {
    return isSnack ? '#D7263D' : Styles.dangerColor;
  }
  return isFocused ? Styles.lightGray : Styles.lightGray;
}

export function getBackgroundColor(
  isFocused: boolean,
  value: string | undefined,
) {
  if (isFocused || value) {
    return Styles.white;
  }
  return Styles.white;
}

export function getAnimationConfig(label?: string, iconName?: string) {
  return {
    translateY: [18, !iconName ? 2 : 9],
    translateX: [2, (label || '').length >= 11 ? 7 - (label || '').length : 2],
    scale: [1, 0.8],
  };
}

export function getDropDownMenuStyles(props: {dropDownHeader?: string}): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    touchableOpacity: {backgroundColor: Styles.white},
    style: {
      marginTop: props.dropDownHeader ? -10 : 15,
      left: props.dropDownHeader ? 99 : 115,
      width: Platform.OS !== 'web' ? '65%' : 'auto',
    },
    contentStyle: {
      borderRadius: 10,
      backgroundColor: Styles.white,
    },
  };
}
export function referralSummaryTotal(): {
  [index: string]: number | string;
} {
  return {};
}
export function payOverlayTxt() {
  return null;
}
export function getTextInputHeight(multiline?: boolean): {
  [index: string]: number | string;
} {
  return {
    textAlignVertical: multiline ? 'top' : 'center',
    inputHeight: !multiline ? null : 98,
  };
}

export function stylePayUnAvailOption(styles) {
  return styles.unavailableOption;
}

export function getDropDownMenuListStyle(
  dropDownSize: ComponentSize,
  maxWidth: number,
  props: {options: Array<MenuOption>; label: string},
  index: number,
) {
  return {
    options: {
      borderBottomWidth: index < props.options.length - 1 ? 0.5 : 0,
      padding: Styles.Spacing.m2,
      flexDirection: 'row',
      width: '100%',
      height: 60,
      borderBottomColor: Styles.darkColor,
      alignItems: 'center',
    },
    optionImageExtraMargins: {
      marginTop: Styles.Spacing.m2 + 7,
      marginRight: Styles.Spacing.m3 + 9,
      marginLeft: Styles.Spacing.m2 + 4,
      width: 37,
      height: 26,
      bottom: 12,
    },

    optionTextStylingForPayment: {
      top: -5,
      padding: 1,
    },
    optionTextStyling: {
      fontWeight: '700',
      paddingTop: Styles.Spacing.m1,
      color: Styles.black,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    amexSpecificMargin: {
      marginBottom: 12,
    },
    optionPaymentText: {
      padding: 1,
      top: -5,
    },
    optionContainer: {},
    parentContainer: {
      dropDownSize,
      maxWidth,
    },
  };
}

export function getDropDownMenuIcon(
  props: {flexWidth?: boolean},
  styles: Record<string, object>,
) {
  return (
    <View
      style={[
        {bottom: 22},
        props.flexWidth ? styles.flexWidthDropDownIcon : styles.dropDownIcon,
      ]}
    >
      <ChevronDown />
    </View>
  );
}

export function getIcon(
  props: {
    hasIcon?: boolean;
    iconName?: string;
    iconLabel?: string;
    iconPressed?: () => void;
  },
  isFocused?: boolean,
): JSX.Element | null {

  if (props.hasIcon) {
    return (
      <TouchableWithoutFeedback
        accessible={true}
        accessibilityLabel={props.iconLabel}
        aria-label={props.iconLabel}
        accessibilityRole="button"
        role="button"
        onPress={props.iconPressed}
      >
        <View style={styles.iconContainer}>
          {props.iconName == 'eye-slash' && <Eyelid size={24} />}
          {props.iconName == 'eye' && <EyelidOpen size={24} />}
          {props.iconName === 'closecircleo' && (
            <CloseIcon color={'#727179'} width={28} height={28} />
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
  return null;
}
export function renderpointsLbl(styles) {
  return (
    <Text
      maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      accessibilityElementsHidden={true}
      importantForAccessibility="no-hide-descendants"
      style={styles.pointsLabel}
    >
      {Localized.Labels.points}
    </Text>
  );
}
export function rewardsPointLbl(): string {
  return '';
}

export function navigateToWalletScreen() {
  NavActions.push(AppRoutes.PDCards, {hideBackArrow: false});
}

export function PasswordInputMaxFontSizeMultipleDescriptor(): number {
  return Styles.FontSizeMultiplier.maxfm1;
}

export function PasswordInputmaxlength(props?: PasswordInpProps): number {
  return props?.maxLength ? props?.maxLength : 24;
}
export function referralDetailBgColorDescriptor(): string {
  return Styles.tabBarBackgroundColor;
}

export function rendercommonSecNewStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      minHeight: Styles.Heights.touchTargetHeight0,
      marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
    },
    headerContainer: {
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      flexDirection: 'row',
      marginBottom: Styles.Spacing.m2,
      justifyContent: 'space-between',
    },
    header: {
      fontSize: Styles.Fonts.f7,
      color: Styles.headerTextColor,
      fontFamily: Styles.FontFamily.figtreeRegular,
      letterSpacing: 1,
      fontWeight: '600',
    },
  };
}
export function rewardPoints(styles, i, rewardOptions) {
  return (
    <View style={styles.graph}>
      <View style={[styles.line, styles.flex15]}>{rewardOptions}</View>
      <View style={styles.line}>
        <View key={i++} style={[styles.rewardOption, styles.flex1]}>
          <View
            style={[
              styles.unavailableOption,
              {
                flex: Styles.Spacing.m1,
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
}

export function textInputColor(): string {
  return Styles.darkColor;
}

const styles = StyleSheet.create({
  chevron: {
    alignSelf: 'baseline',
    paddingLeft: Styles.Spacing.m3,
  },
  iconContainer: {
    position: 'absolute',
    justifyContent: 'flex-end',
    top: 15,
    right: 16,
  },
});
export function successModalSnackGifDescriptor() {
  return require('../../img/SnackGift.gif');
}
export function fontStyleSuccessModal() {
  return null;
}
export function commonutilsRenderSwitchTemplate() {
  return null;
}
export function snackBarColorDescriptor(): string {
  return Styles.blueRevolve;
}

export function checkEmailSuccessMsg(): string {
  return Localized.Success.receipt_send_success;
}
export function checkEmailreceiptBtnTxt(): string {
  return Localized.Labels.email_receipt;
}
export function checkRoundedBtnColor(): string {
  return Styles.darkGreenRevolve;
}
export function numberSpinnerStyles(): NumberSpinnerStylesDescriptor {
  return StyleSheet.create({
    num: {
      alignItems: 'center',
      paddingHorizontal: Styles.Spacing.m3,
      justifyContent: 'center',
      marginTop: 5,
    },
    numText: {
      textAlign: 'center',
    },
  });
}
export function rewardAmtTxt(_styles) {
  return _styles.availableText;
}

export function numberSpinnerColor(): string {
  return Styles.white;
}
export function claimBtnColorDescriptor(): string {
  return null;
}
export function claimedBtnColorDescriptor(): string {
  return null;
}
export function claimeSucessdBtnColorDescriptor(): string {
  return null;
}
export function claimedErrorBtnColorDescriptor(): string {
  return null;
}
export function activeTabShadowColor(): string {
  return null;
}
export function renderReferralStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    referralSuccErrorText: {},
    referralHederText: {},
    referralDescText: {},
  };
}
export function bottomTabBtnColor(): string {
  return '#066DFF';
}
export function referrallocationName(): {
  [index: string]: number | string;
} {
  return {};
}
export function referralDate(): {
  [index: string]: number | string;
} {
  return {};
}

export function rewardPointTxt(_styles) {
  return {..._styles.availablePoint, ..._styles.overlayPoint};
}

export function tabBarRoundedBtnColor(): string {
  return Styles.darkGreenRevolve;
}

export function getInboxTabStyles(): {
  [index: string]: number | string;
} {
  return {
    marginTop: null,
  };
}

export function getNotificationScreenStyles(): {
  [index: string]: {[index: string]: number | string};
} {
  return {};
}

export function eGiftCardSuccesNavigation() {
  return NavActions.popToRoute(AppRoutes.Cards);
}

export default {
  getInboxTabStyles,
  addNewPaymentTemplateDescriptor,
  getNotificationScreenStyles,
  roundedBtnColorDescriptor,
  getuserInitialsStyleDescriptor,
  getSettingsMenusStyleDescriptor,
  getSuccessDialogDescriptor,
  historyDateBtnColor,
  getSuccessDialogBtn,
  settingsMenuFontColorDescriptor,
  snackMessageDescriptor,
  PasswordInputMaxFontSizeMultipleDescriptor,
  getSnackItemStyleDescriptor,
  snackItemGiftBoxTemplateDescriptor,
  snackItemTitleDescriptor,
  snackItemDateDescriptor,
  snackItemNumberDescriptor,
  historyHeaderName,
  dealItemNumberDescriptor,
  referralDetailBgColorDescriptor,
  dealRenderExpirationTxtTemplate,
  dealRenderDealIcon,
  dealRenderDealTopSection,
  collapsibleComponentDescriptor,
  snackBarColorDescriptor,
  dealRenderDealNameDesc,
  sendSnackOTSNBtnStyleDescriptor,
  getColor,
  scanScreenQRCodeStyleDescriptor,
  dealRedeemCount,
  headerContainerStyleDescriptor,
  rewardsPointLbl,
  getAnimatedViewDescriptor,
  getStyles,
  PasswordInputmaxlength,
  getBackgroundColor,
  fundingAmountColor,
  getIcon,
  getAnimationConfig,
  getStyleWithColorOnly,
  rendercommonSecNewStyleDescriptor,
  commonutilsRenderSwitchTemplate,
  textInputColor,
  getDropDownMenuStyles,
  getDropDownMenuListStyle,
  getTextInputHeight,
  historyListStyleDescriptor,
  dealStyleDescriptor,
  renderReferralStyleDescriptor,
  getTopPadding,
  scanScreenQRCodeHeaderText,
  sendSnackOTSNotificationStyleDescriptor,
  checkRoundedBtnColor,
  numberSpinnerStyles,
  numberSpinnerColor,
  bottomTabBtnColor,
  referralImageTemplateDescriptor,
  renderpointsLbl,
  referralItemDateDescriptor,
  claimBtnColorDescriptor,
  rewardPoints,
  claimedBtnColorDescriptor,
  claimeSucessdBtnColorDescriptor,
  fontStyleSuccessModal,
  stylePayAvailOption,
  claimedErrorBtnColorDescriptor,
  referralSummaryTotal,
  rewardAmtTxt,
  referralTransactionId,
  referrallocationName,
  stylePayUnAvailOption,
  rewardPointTxt,
  activeTabShadowColor,
  referralDate,
  successModalSnackGifDescriptor,
  tabBarRoundedBtnColor,
  payOverlayTxt,
  RewardPointStyleDescriptor,
  checkEmailSuccessMsg,
  switchColor,
  toggleColor,
  navigateToWalletScreen,
  eGiftCardSuccesNavigation,
  checkEmailreceiptBtnTxt,
};
