import React, {useState} from 'react';
import {
  Dimensions,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from 'src/components/elements/AVText';
import BackSubheader from 'src/components/elements/BackSubheader';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import {getPreviousRouteName} from '../../../Util';
import {useNavigation} from '@react-navigation/native';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
import Logger from 'src/logging/Logger';

import {getDescriber} from './descriptor/DescriptorType';
import Settings from 'src/Settings';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import CancelIcon from 'src/components/img/svg/CancelIcon';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
const screenWidth = Dimensions.get('window').width;
const multipler = Platform.OS === 'web' ? 0.3 : 0.9;
const modalWidth = screenWidth * multipler;

const DeleteAccountScreen = () => {
  const navigation = useNavigation();
  const [showDeleteMdl, setShowDeleteMdl] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const previousRoute = getPreviousRouteName(navigation?.getState()?.routes);
  const {getDeleteAcntStyleDescriptor} = getDescriber();
  const openDeleteMdl = () => {
    setShowDeleteMdl(true);
  };
  const handleContentLayout = (event) => {
    const {height} = event.nativeEvent.layout;

    setContentHeight(height);
  };
  const deletePressed = async () => {
    const {deleteAccount, logout} = ActionsFactory.getAccountActions();
    const deleteResponse = await deleteAccount(
      AccountStore.getAccountId(),
      AccountStore.getEmail(),
    );
    Logger.Log.LogAPIEvent(
      'AppAPI',
      'DeleteAccount',
      JSON.stringify({
        accountId: AccountStore.getAccountId(),
        email: AccountStore.getEmail(),
      }),
      JSON.stringify(deleteResponse),
    );
    FirebaseAnalytic.trackEvent('deletePressed', 'DeleteAccountScreen', {
      acocuntId: AccountStore.getAccountId(),
      email: AccountStore.getEmail(),
    });

    logout();
    hideModal();
  };
  const renderModal = (): React.ReactNode => {
    return (
      <Modal visible={showDeleteMdl} onDismiss={hideModal} transparent={true}>
        <View style={styles.deleteAcntModalMain}>
          <View
            style={[
              styles.deleteAcntModalContainer,
              {height: contentHeight + 180},
            ]}
          >
            <AVTouchableOpacity
              aria-label={`X icon, Double tap to ${Localized.Buttons.cancel} offer details`}
              role="button"
              style={styles.closeModalTitleContainer}
              onPress={hideModal}
              accessible
              accessibilityLabel={Localized.Buttons.cancel}
              accessibilityRole="button"
            >
              <CancelIcon />
            </AVTouchableOpacity>
            <View
              style={styles.deleteAcntModalContentContainer}
              onLayout={handleContentLayout}
            >
              <AVText
                aria-label={`${Localized.Success.delete_Acnt} label, text`}
                accessibilityLabel={`${Localized.Success.delete_Acnt} label`}
                style={styles.deleteAcntTitle}
                accessible={true}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Success.delete_Acnt}
              </AVText>
              <AVText
                accessible={true}
                accessibilityLabel={`${Localized.Success.delete_acnt_desc} label`}
                aria-label={`${Localized.Success.delete_acnt_desc} label, text`}
                style={styles.deleteAcntDesc}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm7}
              >
                {Localized.Success.delete_acnt_desc}
              </AVText>
            </View>

            <View>
              <RoundedAppButton
                buttonType={RoundedAppButtonType.Solid}
                onPress={() => deletePressed()}
                buttonViewStyle={{
                  overflow: 'hidden',
                  borderWidth: 2,
                  borderRadius: 32,
                  backgroundColor: '#D7263D',
                  borderTopColor: '#D7263D',
                  borderBottomColor: '#D7263D',
                  borderLeftColor: '#D7263D',
                  borderRightColor: '#D7263D',
                  borderColor: '#D7263D',
                  width: 276,
                  height: 42,
                }}
                buttonContainerStyle={{
                  alignSelf: 'center',
                }}
                accessibilityLabelValue={`${Localized.Buttons.okay}`}
                titleText={Localized.Buttons.deletAcntBtn}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                textStyle={styles.btndelete}
              />
              <RoundedAppButton
                buttonType={RoundedAppButtonType.Outline}
                onPress={() => hideModal()}
                buttonViewStyle={{
                  overflow: 'hidden',
                  borderWidth: 2,
                  borderRadius: 32,
                  borderTopColor: '#D7263D',
                  borderBottomColor: '#D7263D',
                  borderLeftColor: '#D7263D',
                  borderRightColor: '#D7263D',
                  borderColor: '#D7263D',
                  width: 276,
                  height: 42,
                }}
                buttonContainerStyle={{
                  alignSelf: 'center',
                  marginTop: 13,
                }}
                accessibilityLabelValue={`${Localized.Buttons.takemeBck}`}
                titleText={Localized.Buttons.takemeBck}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
                textStyle={styles.btndeleteTakeMe}
              />
            </View>
          </View>
        </View>
      </Modal>
    );
  };
  const hideModal = () => {
    setShowDeleteMdl(false);
  };
  const isRedesign = getDescriber().getDeleteAcntDisclaimer(
    Settings.is365Pay(),
  );
  return (
    <BackSubheader
      previousRoute={previousRoute}
      accessibilityLabel={'Back arrow'}
      accessibilityHint={`Press to navigate back to the ${previousRoute} screen`}
      title={Localized.Labels.delete_account}
    >
      <ScrollView style={[styles.content, Settings.is365Pay && {padding: 24}]}>
        <AVText
          style={
            getDeleteAcntStyleDescriptor() &&
            getDeleteAcntStyleDescriptor()['disclaimerTextBold']
          }
        >
          {Localized.Labels[isRedesign]}
        </AVText>
        <AVText
          style={
            getDeleteAcntStyleDescriptor() &&
            getDeleteAcntStyleDescriptor()['disclaimerText']
          }
        >
          {Localized.Labels.voluntary_deletion_2}
        </AVText>
      </ScrollView>
      <RoundedButton
        buttonType={ButtonType.action}
        color={Styles.dangerColor}
        onPress={openDeleteMdl}
        accessibilityLabel="Continue with Account Deletion"
        text={Localized.Buttons.delete}
        textStyle={
          getDeleteAcntStyleDescriptor() &&
          getDeleteAcntStyleDescriptor()['deleteBtnTxt']
        }
        wrapperStyle={
          getDeleteAcntStyleDescriptor() &&
          getDeleteAcntStyleDescriptor()['deleteBtn']
        }
      />
      <>{showDeleteMdl && renderModal()}</>
    </BackSubheader>
  );
};

const styles = StyleSheet.create({
  content: {
    padding: Styles.Spacing.m3,
  },
  disclaimerText: {
    marginBottom: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f1,
    color: Styles.primaryColor,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  disclaimerTextBold: {
    marginBottom: Styles.Spacing.m2,
    fontWeight: 'bold',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  closeModalTitleContainer: {
    justifyContent: 'flex-end',
    position: 'absolute',
    top: Styles.Heights.h1,
    right: Styles.Heights.h1,
  },
  deleteAcntModalMain: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  deleteAcntModalContainer: {
    width: modalWidth,
    backgroundColor: Styles.white,
    borderRadius: Styles.Spacing.m3,
    paddingHorizontal: 15,
  },
  deleteAcntModalContentContainer: {
    marginTop: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteAcntTitle: {
    fontSize: 22,
    fontWeight: '700',
    color: '#111',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
  },
  deleteAcntDesc: {
    paddingTop: 16,
    fontSize: 16,
    fontWeight: '400',
    color: '#111',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
    paddingBottom: 25,
  },
  btndelete: {
    fontSize: 16,
    fontWeight: '700',
    color: '#fff',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
  },
  btndeleteTakeMe: {
    fontSize: 16,
    fontWeight: '700',
    color: '#D7263D',
    fontFamily: Styles.FontFamily.aeonikRegular,
    textAlign: 'center',
    paddingHorizontal: 24,
  },
});

export default DeleteAccountScreen;
