import * as React from 'react';
import Svg, {Path, G, Mask} from 'react-native-svg';

type EyelidProps = {
  size: number;
}; // tslint:disable

export default class Eyelid extends React.Component<EyelidProps> {
  render() {
    return (
      <Svg
        width={this.props.size}
        height={this.props.size}
        viewBox="0 0 24 24"
        fill="none"
      >
        <G id="Icons/show">
          <G id="Group 6">
            <G id="Group 3">
              <Mask
                id="mask0_23_10158"
                maskUnits="userSpaceOnUse"
                x="2"
                y="6"
                width="20"
                height="12"
              >
                <Path
                  id="Clip 2"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M2 6.5H22V17.5H2V6.5Z"
                  fill="white"
                />
              </Mask>
              <G mask="url(#mask0_23_10158)">
                <Path
                  id="Fill 1"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 15.9792C10.3212 15.9792 8.86369 15.5564 7.41322 14.6486C6.31228 13.9595 5.26888 13.0277 4.19978 12.0088C6.96228 9.46612 9.24722 8.02077 12 8.02077C13.6865 8.02077 15.3091 8.5404 16.9605 9.60924C18.0562 10.3185 19.0284 11.1845 19.8488 12.0057C17.8294 13.885 15.2176 15.9792 12 15.9792ZM12 6.49999C8.34375 6.49999 5.49553 8.5969 2 12.0001C5.00891 14.909 7.53572 17.5 12 17.5C16.4598 17.5 19.7411 14.2172 22 12.0602C19.6875 9.53359 16.4107 6.49999 12 6.49999Z"
                  fill="#9A9A9F"
                />
              </G>
            </G>
            <Path
              id="Fill 4"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 8.33577C9.97628 8.33577 8.33572 9.97624 8.33572 12.0001C8.33572 14.0238 9.97628 15.6643 12 15.6643C14.0237 15.6643 15.6643 14.0238 15.6643 12.0001C15.6643 9.97624 14.0237 8.33577 12 8.33577Z"
              fill="#9A9A9F"
            />
          </G>
        </G>
      </Svg>
    );
  }
}
