import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';
import Settings from 'src/Settings';
type GiftBoxUnReadProps = {
  width: number;
  height: number;
  color?: string;
  style?: Record<string, unknown>;
};
const GiftUnReadIcon = (props: GiftBoxUnReadProps) => (
  <>
    {Settings.isRevolve() ? (
      <Svg
        width={props.width}
        height={props.height}
        viewBox="0 0 54 55"
        fill="none"
        {...props}
      >
        <Path
          d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
          fill="#022649"
        />
        <Path
          d="M21.9297 21.725L23.5609 24.5H23.5H20.125C19.0891 24.5 18.25 23.6609 18.25 22.625C18.25 21.5891 19.0891 20.75 20.125 20.75H20.2281C20.9266 20.75 21.5781 21.1203 21.9297 21.725ZM16 22.625C16 23.3 16.1641 23.9375 16.45 24.5H14.5C13.6703 24.5 13 25.1703 13 26V29C13 29.8297 13.6703 30.5 14.5 30.5H35.5C36.3297 30.5 37 29.8297 37 29V26C37 25.1703 36.3297 24.5 35.5 24.5H33.55C33.8359 23.9375 34 23.3 34 22.625C34 20.3469 32.1531 18.5 29.875 18.5H29.7719C28.2766 18.5 26.8891 19.2922 26.1297 20.5813L25 22.5078L23.8703 20.5859C23.1109 19.2922 21.7234 18.5 20.2281 18.5H20.125C17.8469 18.5 16 20.3469 16 22.625ZM31.75 22.625C31.75 23.6609 30.9109 24.5 29.875 24.5H26.5H26.4391L28.0703 21.725C28.4266 21.1203 29.0734 20.75 29.7719 20.75H29.875C30.9109 20.75 31.75 21.5891 31.75 22.625ZM14.5 32V40.25C14.5 41.4922 15.5078 42.5 16.75 42.5H23.5V32H14.5ZM26.5 42.5H33.25C34.4922 42.5 35.5 41.4922 35.5 40.25V32H26.5V42.5Z"
          fill="#21AD5A"
        />
        <Circle fill="#A4CCF4" cy="10.5" r="10" cx="44" />
      </Svg>
    ) : (
      <Svg fill="none" height="55" viewBox="0 0 54 55" width="54">
        <Path
          d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
          fill="#066DFF"
        />
        <Path
          d="M21.9297 20.725L23.5609 23.5H23.5H20.125C19.0891 23.5 18.25 22.6609 18.25 21.625C18.25 20.5891 19.0891 19.75 20.125 19.75H20.2281C20.9266 19.75 21.5781 20.1203 21.9297 20.725ZM16 21.625C16 22.3 16.1641 22.9375 16.45 23.5H14.5C13.6703 23.5 13 24.1703 13 25V28C13 28.8297 13.6703 29.5 14.5 29.5H35.5C36.3297 29.5 37 28.8297 37 28V25C37 24.1703 36.3297 23.5 35.5 23.5H33.55C33.8359 22.9375 34 22.3 34 21.625C34 19.3469 32.1531 17.5 29.875 17.5H29.7719C28.2766 17.5 26.8891 18.2922 26.1297 19.5813L25 21.5078L23.8703 19.5859C23.1109 18.2922 21.7234 17.5 20.2281 17.5H20.125C17.8469 17.5 16 19.3469 16 21.625ZM31.75 21.625C31.75 22.6609 30.9109 23.5 29.875 23.5H26.5H26.4391L28.0703 20.725C28.4266 20.1203 29.0734 19.75 29.7719 19.75H29.875C30.9109 19.75 31.75 20.5891 31.75 21.625ZM14.5 31V39.25C14.5 40.4922 15.5078 41.5 16.75 41.5H23.5V31H14.5ZM26.5 41.5H33.25C34.4922 41.5 35.5 40.4922 35.5 39.25V31H26.5V41.5Z"
          fill="#FEE0E6"
        />
        <Circle cx="44" cy="10.5" r="10" fill="#E2607F" />
      </Svg>
    )}
  </>
);
export default GiftUnReadIcon;
