import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import CustomToggleSwitch from 'src/components/elements/CustomToggleSwitch';
import CreditCardInput from 'src/components/elements/funding/CreditCardInput';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function getAddCardDescriptionView(): JSX.Element {
  return (
    <React.Fragment>
      <Text style={styles.descriptionText}>
        {Localized.Labels.add_credit_card_and_use_it_to_fund_your_account}
      </Text>
    </React.Fragment>
  );
}

export function getHeaderTitle(): string {
  return Localized.Labels.add_credit_card;
}

export function getAddCardInputView(
  cardNumber: string,
  _expMonth: string,
  _expYear: string,
  cvv: string,
  zipCode: string,
  cardNumberError: string | null,
  expDateError: string | null,
  cvvError: string | null,
  zipCodeError: string | null,
  cardNumberAction: (value: string) => void,
  expMonthAction: (value: string) => void,
  _expYearAction: (value: string) => void,
  cvvAction: (value: string) => void,
  zipAction: (value: string) => void,
  expiryDate?: string,
  _allowChangeText?: boolean,
  _changeTxtAction?: (value: boolean) => void,
): JSX.Element {
  return (
    <React.Fragment>
      <CreditCardInput
        accessibilityLabel={Localized.Labels.card_number}
        onChangeText={(text) => cardNumberAction(text)}
        onSubmitEditing={() => this.focusInput(this.month)}
        label={Localized.Labels.card_number}
        value={cardNumber}
        textStyle={styles.txtInput}
        errorTextStyle={styles.errorText}
        hasErrorMsg={cardNumberError ? true : false}
        errorMsg={cardNumberError}
        style={[{marginTop: -8}]}
        outlineStyle={[
          styles.outline,
          cardNumberError && {borderColor: Styles.Colors.PayNew.error},
          {elevation: 1},
        ]}
        labelStyle={{
          fontSize: Styles.Spacing.m3 + 2,
          fontFamily: Styles.FontFamily.aeonikRegular,
          fontWeight: '400',
          color: '#707070',
        }}
      />
      <View style={styles.row}>
        <View style={styles.inputRowLeft}>
          <CustomTextInput
            keyboardType="numeric"
            label={Localized.Labels.expires_mm_yy}
            value={expiryDate}
            accessible={true}
            accessibilityLabel={Localized.Labels.expires_mm_yy}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            onChangeText={(text) => {
              expMonthAction(text);
            }}
            maxLength={5}
            style={[styles.txtInput]}
            outlineStyle={[
              styles.outline,
              expDateError && {borderColor: Styles.Colors.PayNew.error},
              {elevation: 1},
            ]}
            errorTextStyle={styles.errorText}
            hasErrorMsg={expDateError ? true : false}
            errorMsg={expDateError}
            labelStyle={{
              fontSize: Styles.Spacing.m3,
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontWeight: '400',
              color: '#707070',
            }}
            showShadow={true}
          />
        </View>
        <View style={styles.inputRowRight}>
          <CustomTextInput
            value={cvv}
            label={Localized.Labels.security_code}
            onChangeText={(text) => cvvAction(text)}
            accessibilityLabel={Localized.Labels.security_code}
            keyboardType="numeric"
            accessible={true}
            accessibilityValue={{text: cvv}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
            style={[styles.txtInput]}
            outlineStyle={[
              styles.outline,
              cvvError && {borderColor: Styles.Colors.PayNew.error},
              {elevation: 1},
            ]}
            errorTextStyle={styles.errorText}
            hasErrorMsg={cvvError ? true : false}
            errorMsg={cvvError}
            labelStyle={{
              fontSize: Styles.Spacing.m3,
              fontFamily: Styles.FontFamily.aeonikRegular,
              fontWeight: '400',
              color: '#707070',
            }}
            showShadow={true}
          />
        </View>
      </View>
      <CustomTextInput
        keyboardType="numeric"
        label={Localized.Labels.zip_postal_code}
        value={zipCode}
        accessible={true}
        accessibilityLabel={Localized.Labels.zip_postal_code}
        accessibilityValue={{text: zipCode}}
        onChangeText={(text) => zipAction(text)}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
        style={[styles.txtInput]}
        outlineStyle={[
          styles.outline,
          zipCodeError && {borderColor: Styles.Colors.PayNew.error},
          {elevation: 1},
        ]}
        errorTextStyle={styles.errorText}
        hasErrorMsg={zipCodeError ? true : false}
        errorMsg={zipCodeError}
        labelStyle={{
          fontSize: Styles.Spacing.m3,
          fontFamily: Styles.FontFamily.aeonikRegular,
          fontWeight: '400',
          color: '#707070',
        }}
        showShadow={true}
      />
    </React.Fragment>
  );
}

export function getDefaultSwitchStatusView(
  isDefaultTokenDisabled: boolean,
  defaultTokenStatus: boolean,
  switchAction: (value: boolean) => void,
): JSX.Element {
  return (
    <View style={[styles.defaultStatusView]}>
      <Text
        accessible={true}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm12}
        accessibilityLabel={Localized.Labels.set_as_default}
        accessibilityRole="text"
        aria-label={`${Localized.Labels.set_as_default} label, text`}
        style={styles.setDefaultLabel}
      >
        {Localized.Labels.set_as_default}
      </Text>

      <CustomToggleSwitch
        accessibilityState={{
          checked: defaultTokenStatus,
          disabled: isDefaultTokenDisabled,
        }}
        accessibilityLabel={Localized.Labels.set_as_default}
        testID="setAsDefaultNewCard"
        value={defaultTokenStatus}
        isCreditCardDefault={isDefaultTokenDisabled}
        isDisabled={isDefaultTokenDisabled}
        onValueChange={(value) => switchAction(value)}
        containerStyle={{flex: 0, justifyContent: 'flex-end', padding: 0}}
      />
    </View>
  );
}

export function getCardSaveButton(
  buttonAction: () => void,
  _buttonText: string,
  _isDisabled: boolean,
  _isActive: boolean,
): JSX.Element {
  return (
    <React.Fragment>
      <RoundedAppButton
        accessibilityLabelValue={Localized.Buttons.save}
        accessibilityHintValue="Double tap to save your card information"
        aria-label={Localized.Buttons.save}
        buttonType={RoundedAppButtonType.Solid}
        onPress={buttonAction}
        titleText={Localized.Buttons.save}
        buttonContainerStyle={{
          right: 16,
          bottom: 24,
          position: 'absolute',
        }}
        textStyle={{
          fontFamily: Styles.FontFamily.aeonikRegular,
          fontSize: 16,
          fontWeight: '700',
          color: Styles.white,
          paddingHorizontal: 16,
        }}
        buttonViewStyle={{
          backgroundColor: _isActive
            ? Styles.Colors.PayNew.primary01
            : Styles.Colors.PayNew.darkHuesBase04,
          height: 42,
        }}
      />
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  inputRowLeft: {
    paddingRight: Styles.Spacing.m2,
    flex: 0.5,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  descriptionText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 17,
    fontWeight: '700',
    marginHorizontal: 24,
    marginTop: 24,
    color: Styles.black,
  },
  zipCode: {
    marginHorizontal: 24,
  },
  inputRowRight: {
    flex: 0.5,
    paddingLeft: Styles.Spacing.m1,
  },
  txtInput: {
    fontSize: 14,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.black,
    fontWeight: '400',
    height: 56,
    marginTop: 12,
  },
  shadow: {
    elevation: 1,
    shadowOffset: {
      height: 1,
      width: 1,
    },
    shadowRadius: 2,
    shadowOpacity: 0.2,
    borderRadius: 8,
  },
  outline: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Styles.neutralHues,
  },
  defaultStatusView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
  },
  defaultSwitch: {
    transform: [
      {
        scaleX: 0.85,
      },
      {
        scaleY: 0.85,
      },
    ],
  },
  setDefaultLabel: {
    fontSize: Styles.Fonts.f1,
    marginRight: Styles.Spacing.m2,
    color: Styles.black,
    marginLeft: Styles.Spacing.m1,
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
  },
  errorText: {
    fontWeight: '400',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: 14,
    alignContent: 'flex-start',
    alignSelf: 'flex-start',
    paddingHorizontal: 8,
  },
});

export default {
  getAddCardDescriptionView,
  getAddCardInputView,
  getHeaderTitle,
  getDefaultSwitchStatusView,
  getCardSaveButton,
};
