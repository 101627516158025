import moment from 'moment';
import * as React from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import BirthMonthDropdown from '../../elements/account/BirthMonthDropdown';
import BirthYearDropdown from '../../elements/account/BirthYearDropdown';
import GenderDropdown from '../../elements/account/GenderDropdown';
import AVTextInput from '../../elements/AVTextInput';
import RoundedButton, {ButtonType} from '../../elements/RoundedButton';
import withScrollToElement from '../../hoc/withScrollToElement';
import Styles from '../../Styles';
import BaseAccountSetupScreen from '../BaseAccountSetupScreen';
import type {ScrollToElementProps} from 'src/types/Screen';
import type {SetupModel} from 'src/types/SetupModel';
import type {Referral} from 'src/types/Referral';
import Settings from 'src/Settings';
import AVText from '../../elements/AVText';
import CreateAccountHandler from '../../elements/createAccount/CreateAccountHandler';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import FirebaseAnalytic from '../../../nativeModules/FirebaseAnalytic';
type DemographicScreenProps = ScrollToElementProps & {
  setupModel: SetupModel;
  email: string;
  pinRequired: boolean;
  referral: Referral | null | undefined;
};
type DemographicScreenState = {
  keyboardSpace: number;
  gender: string;
  birthMonth: string;
  birthYear: string;
  jobTitle: string;
  genderMenuVisible: boolean;
  birthMonthMenuVisible: boolean;
  birthYearMenuVisible: boolean;
};

class DemographicScreen extends React.Component<
  DemographicScreenProps,
  DemographicScreenState
> {
  scrollView: ScrollView | null;
  jobTitle: AVTextInput | null;
  startTime: moment.Moment;
  createAccountHandler: CreateAccountHandler | null | undefined;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: DemographicScreenProps) {
    super(props);
    this.state = {
      keyboardSpace: 0,
      jobTitle: '',
      gender: '',
      birthMonth: '',
      birthYear: '',
      genderMenuVisible: false,
      birthMonthMenuVisible: false,
      birthYearMenuVisible: false,
    };
    this.startTime = moment();
    this.inputFocused = this.inputFocused.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.focusInput = this.focusInput.bind(this);
    this.genderSelected = this.genderSelected.bind(this);
    this.birthMonthSelected = this.birthMonthSelected.bind(this);
    this.birthYearSelected = this.birthYearSelected.bind(this);
  }

  handleMenuVisibilityChange = (visible: boolean, whichMenu: string) => {
    FirebaseAnalytic.trackEvent(
      'handleMenuVisibilityChange',
      'DemographicScreen',
      {
        ...this.props,
        ...this.state,
        visible,
        whichMenu,
      },
    );

    switch (whichMenu) {
      case 'gender':
        this.setState({genderMenuVisible: visible});
        break;
      case 'birthMonth':
        this.setState({birthMonthMenuVisible: visible});
        break;
      case 'birthYear':
        this.setState({birthYearMenuVisible: visible});
        break;
      default:
        break;
    }
  };

  inputFocused(ref: AVTextInput | null) {
    this.props.scrollToElement(this.scrollView, ref);
  }

  focusInput(input: AVTextInput | null) {
    if (input) {
      input.focus();
    }
  }

  genderSelected(gender: string) {
    FirebaseAnalytic.trackEvent('genderSelected', 'DemographicScreen', {
      ...this.props,
      ...this.state,
      gender,
    });
    this.setState({
      gender: gender,
      genderMenuVisible: false,
    });
  }

  birthMonthSelected(birthMonth: string) {
    FirebaseAnalytic.trackEvent('birthMonthSelected', 'DemographicScreen', {
      ...this.props,
      ...this.state,
      birthMonth,
    });
    this.setState({
      birthMonth: birthMonth,
      birthMonthMenuVisible: false,
    });
  }

  birthYearSelected(birthYear: string) {
    FirebaseAnalytic.trackEvent('birthYearSelected', 'DemographicScreen', {
      ...this.props,
      ...this.state,
      birthYear,
    });
    this.setState({
      birthYear: birthYear,
      birthYearMenuVisible: false,
    });
  }

  userAgreementPressed() {
    const url = Settings.getTermsUrl(
      Settings.getLocale(),
      AccountStore.getPrivacyTermsType(),
    );
    const backupUrl = Settings.getTermsUrl(
      Settings.getDefaultLocale(),
      AccountStore.getPrivacyTermsType(),
    );

    FirebaseAnalytic.trackEvent('userAgreementPressed', 'DemographicScreen', {
      ...this.props,
      ...this.state,
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
      navigate: AppRoutes.WebContent,
    });
    NavActions.push(AppRoutes.WebContent, {
      title: Localized.Labels.terms,
      url,
      backupUrl,
      strings: Localized,
    });
  }

  handleBack() {
    Events.AccountCreation.trackEvent(this.startTime, 'VerifyEmail');
  }

  async handleClick() {
    const {gender, birthMonth, birthYear, jobTitle} = this.state;
    const setupModel: SetupModel = {
      ...this.props.setupModel,
      gender,
      birthMonth: parseInt(birthMonth, 10),
      birthYear: parseInt(birthYear, 10),
      jobTitle,
    };

    FirebaseAnalytic.trackEvent('handleClick', 'DemographicScreen', {
      ...this.props,
      ...this.state,
    });

    if (this.createAccountHandler) {
      this.createAccountHandler.handleCreateAccount(
        setupModel,
        this.props.email,
        this.props.pinRequired,
      );
    }
  }

  render() {
    return (
      <BaseAccountSetupScreen
        headlineText={Localized.Labels.demographics}
        instructionText={Localized.Labels.enter_information}
        onBackSelect={this.handleBack}
      >
        <CreateAccountHandler
          context={this.context}
          ref={(createAccountHandler) => {
            this.createAccountHandler = createAccountHandler;
          }}
        />
        <AVText
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
          style={styles.optional}
        >
          {Localized.Labels.optional}
        </AVText>
        <ScrollView
          style={styles.scrollView}
          ref={(ref) => {
            this.scrollView = ref;
          }}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={styles.inputsContainer}>
            <View
              accessible={true}
              accessibilityLabel="Gender"
              accessibilityRole="menu"
              role="menu"
              accessibilityValue={{text: this.state.gender}}
              accessibilityState={{expanded: this.state.genderMenuVisible}}
              accessibilityElementsHidden={
                this.state.birthMonthMenuVisible ||
                this.state.birthYearMenuVisible
              }
              importantForAccessibility={
                this.state.birthMonthMenuVisible ||
                this.state.birthYearMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <GenderDropdown
                selectedValue={this.state.gender}
                onSelect={this.genderSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.genderMenuVisible,
                    'gender',
                  )
                }
              />
            </View>
            <View
              accessible={true}
              accessibilityLabel="Birth Month"
              accessibilityRole="menu"
              role="menu"
              accessibilityValue={{text: this.state.birthMonth}}
              accessibilityState={{expanded: this.state.birthMonthMenuVisible}}
              accessibilityElementsHidden={
                this.state.genderMenuVisible || this.state.birthYearMenuVisible
              }
              importantForAccessibility={
                this.state.genderMenuVisible || this.state.birthYearMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <BirthMonthDropdown
                selectedValue={this.state.birthMonth}
                onSelect={this.birthMonthSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.birthMonthMenuVisible,
                    'birthMonth',
                  )
                }
                showNumbers={false}
              />
            </View>
            <View
              accessible={true}
              accessibilityLabel="Birth Year"
              accessibilityRole="menu"
              role="menu"
              accessibilityValue={{text: this.state.birthYear}}
              accessibilityState={{expanded: this.state.birthYearMenuVisible}}
              accessibilityElementsHidden={
                this.state.genderMenuVisible || this.state.birthMonthMenuVisible
              }
              importantForAccessibility={
                this.state.genderMenuVisible || this.state.birthMonthMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <BirthYearDropdown
                selectedValue={this.state.birthYear}
                onSelect={this.birthYearSelected}
                onVisibilityChange={() =>
                  this.handleMenuVisibilityChange(
                    !this.state.birthYearMenuVisible,
                    'birthYear',
                  )
                }
              />
            </View>
            <View
              style={styles.bottomRow}
              accessibilityElementsHidden={
                this.state.genderMenuVisible ||
                this.state.birthMonthMenuVisible ||
                this.state.birthYearMenuVisible
              }
              importantForAccessibility={
                this.state.genderMenuVisible ||
                this.state.birthMonthMenuVisible ||
                this.state.birthYearMenuVisible
                  ? 'no-hide-descendants'
                  : 'yes'
              }
            >
              <AllyTextInput
                label={Localized.Labels.job_title}
                value={this.state.jobTitle}
                accessible={true}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                accessibilityLabel={'Job Title'}
                accessibilityValue={{text: this.state.jobTitle}}
                onChangeText={(text) =>
                  this.setState({
                    jobTitle: text,
                  })
                }
                autoCapitalize="words"
                onSubmitEditing={() => this.handleClick()}
                returnKeyType="done"
                onFocus={() => this.inputFocused(this.jobTitle)}
                maxLength={250}
              />
            </View>
          </View>
        </ScrollView>
        <View style={styles.buttonsContainerBottomRight}>
          <RoundedButton
            accessibilityElementsHidden={
              this.state.genderMenuVisible ||
              this.state.birthMonthMenuVisible ||
              this.state.birthYearMenuVisible
            }
            importantForAccessibility={
              this.state.genderMenuVisible ||
              this.state.birthMonthMenuVisible ||
              this.state.birthYearMenuVisible
                ? 'no-hide-descendants'
                : 'yes'
            }
            buttonType={ButtonType.outline}
            onPress={this.handleClick}
            containerStyle={{
              marginHorizontal: Styles.Spacing.m3,
            }}
            accessibilityLabel="Add demographics information later"
            text={Localized.Labels.add_later}
          />
          <RoundedButton
            accessibilityElementsHidden={
              this.state.genderMenuVisible ||
              this.state.birthMonthMenuVisible ||
              this.state.birthYearMenuVisible
            }
            importantForAccessibility={
              this.state.genderMenuVisible ||
              this.state.birthMonthMenuVisible ||
              this.state.birthYearMenuVisible
                ? 'no-hide-descendants'
                : 'yes'
            }
            buttonType={ButtonType.normal}
            accessibilityLabel="Next screen"
            onPress={this.handleClick}
            text={Localized.Buttons.next}
            textStyle={styles.nextButtonText}
          />
        </View>
      </BaseAccountSetupScreen>
    );
  }
}

const styles = StyleSheet.create({
  bottomRow: {
    marginBottom: Styles.Spacing.m5,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginTop: Styles.Spacing.m2,
  },
  jobTitle: {
    marginBottom: Styles.Spacing.m5,
  },
  optional: {
    color: Styles.primaryColor,
    fontStyle: 'italic',
    fontSize: Styles.Fonts.f2,
  },
  scrollView: {
    alignSelf: 'stretch',
  },
  buttonsContainerBottomRight: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams()(
  withScrollToElement(DemographicScreen),
);
