import React, {useEffect, useRef, useState} from 'react';
import {
  View,
  StyleSheet,
  Animated,
  TouchableOpacity,
  Easing,
  ViewProps,
} from 'react-native';
import {getDescriber} from 'src/components/elements/descriptor/DescriptorType';

type CustomToggleSwitchProps = ViewProps & {
  testID?: string;
  value: boolean;
  accessibilityLabel?: string;
  nativeID?: string;
  isDisabled?: boolean;
  isCreditCardDefault?: boolean;
  onValueChange?: (value: boolean) => void;
  containerStyle?:
    | {[index: string]: number | string}
    | {
        [index: string]:
          | number
          | string
          | Array<{scaleX?: number; scaleY?: number}>;
      }
    | {[index: string]: {[index: string]: number | string}};
};

export default function CustomToggleSwitch(props: CustomToggleSwitchProps) {
  const {isDisabled = false, isCreditCardDefault = false} = props;
  const positionButton = useRef(new Animated.Value(0)).current;
  const [isOn, setIsOn] = useState(props.value);

  const isOnRef = useRef(true);
  const checkIsDefault = isDisabled && isCreditCardDefault;
  useEffect(() => {
    isOnRef.current = isOn;
    if (isOn) {
      startAnimToOn();
    } else {
      startAnimToOff();
    }
  }, []);

  const startAnimToOff = () => {
    Animated.timing(positionButton, {
      toValue: 0,
      duration: 500,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  };

  const startAnimToOn = () => {
    Animated.timing(positionButton, {
      toValue: 1,
      duration: 500,
      easing: Easing.ease,
      useNativeDriver: false,
    }).start();
  };

  const positionInterPol =
    positionButton &&
    positionButton.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 30],
    });

  const backgroundColorAnim =
    positionButton &&
    positionButton.interpolate({
      inputRange: [0, 1],
      outputRange:
        getDescriber() &&
        getDescriber()['toggleColor'] &&
        getDescriber()['toggleColor'](checkIsDefault),
    });

  const onPress = () => {
    if (isOnRef.current) {
      startAnimToOff();
      isOnRef.current = false;
      props.onValueChange(false);
      setIsOn(false);
    } else {
      startAnimToOn();
      isOnRef.current = true;
      props.onValueChange(true);
      setIsOn(true);
    }
  };

  return (
    <View
      style={[styles.container, props.containerStyle]}
      nativeID={props.nativeID}
    >
      <TouchableOpacity
        style={[
          {height: 30, width: 50},
          //isDisabled && isCreditCardDefault && {opacity: 0.5},
        ]}
        activeOpacity={0.9}
        onPress={(e) => {
          if (!isDisabled && !isCreditCardDefault) {
            onPress();
          } else {
            e.preventDefault();
          }
        }}
        accessibilityLabel={props.accessibilityLabel}
        disabled={isDisabled && !isCreditCardDefault}
        testID={props.testID}
      >
        <Animated.View
          style={[
            styles.mainStyes,
            {
              backgroundColor: backgroundColorAnim,
            },
          ]}
        >
          <Animated.View
            style={[
              styles.basicStyle,
              {
                transform: [
                  {
                    translateX: positionInterPol,
                  },
                ],
              },
              !isOn && {marginLeft: 3},
            ]}
          />
        </Animated.View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: 8,
    top: 3,
  },
  basicStyle: {
    height: 20,
    width: 20,
    borderRadius: 10,
    backgroundColor: '#FFF',
    marginTop: 3,
    marginLeft: -7, // toggel active
  },
  mainStyes: {
    borderRadius: 30,
    height: 26,
    width: 46,
  },
});
