import {useEffect, useState} from 'react';
import Styles from 'src/components/Styles';
import {Platform, StyleSheet} from 'react-native';
import AccountStore from 'src/stores/AccountStore';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import Localized from 'src/constants/AppStrings';
import NavActions from 'src/actions/NavActions';
import AppRoutes from 'src/AppRoutes';
import {useGlobalize} from 'react-native-globalize';

const margin = Styles.Spacing.m3 + Styles.Spacing.m2;

const ReferralView = () => {
  const [referralCampaign, setIsReferralCampaign] = useState(false);
  const [referralStat, setIsReferralStat] = useState(false);
  const [amount, setAmount] = useState(0);
  const [referral, setReferral] = useState(0);
  const [signups, setSignups] = useState(0);
  const {formatCurrency} = useGlobalize();

  useEffect(() => {
    checkReferralStatus();
    FirebaseAnalytic.trackEvent('componentDidMount', 'ReferralView');
    AccountStore.addChangeListener(checkReferralStatus);
    return () => {
      AccountStore.removeChangeListener(checkReferralStatus);
    };
  }, []);

  //Checks Send A Snack Status based on User and Home location settings
  const checkReferralStatus = () => {
    if (
      AccountStore?.getReferralCampaign() &&
      AccountStore?.getReferralStat()
    ) {
      setIsReferralCampaign(true);
      setIsReferralStat(true);
      setAmount(AccountStore.getReferralCampaign().amount);
      setReferral(AccountStore.getReferralStat().referrals);
      setSignups(AccountStore.getReferralStat().signUps);
    } else {
      setIsReferralCampaign(false);
      setIsReferralStat(false);
      setAmount(0);
      setReferral(0);
      setSignups(0);
    }
  };

  //invite action
  const onInviteClick = () => {
    FirebaseAnalytic.trackEvent('onInviteClick', 'ReferralView');
    NavActions.navigate(AppRoutes.Referral, {
      referralCampaign: AccountStore.getReferralCampaign(),
      referralStat: AccountStore.getReferralStat(),
    });
  };
  const describerprops = {
    styles,
    onInviteClick,
    title: Localized.Labels.formatString(
      Localized.Labels.invite_frnd_referral,
      formatCurrency(amount, AccountStore.getCurrency())?.split('.')[0],
    ),

    stat: Localized.Labels.formatString(
      Localized.Labels.refer_sign_count,
      referral,
      signups,
    ),
    amount,
    isSnackEnabled:
      AccountStore.isConsumerSendSnackEnabled() &&
      AccountStore.isSendSnackEnabledForLocation(),
  };

  return referralCampaign && referralStat
    ? getDescriber().referralViewDescriptor(
        describerprops,
        formatCurrency(
          describerprops.amount,
          AccountStore.getCurrency(),
        )?.split('.')[0],
      )
    : null;
};

const styles = StyleSheet.create({
  headerView: {
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Styles.Spacing.m2,
    height: Styles.Heights.h4,
  },

  container: {
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    paddingHorizontal: Styles.Spacing.m3,
    borderRadius: Styles.Spacing.m2,
    marginTop: margin,
    shadowRadius: 2,
    marginHorizontal: margin,
    justifyContent: 'center',
    marginBottom: Styles.Spacing.m3,
    ...Styles.Style.shadow,
  },
  titleText: {
    fontSize: Styles.Fonts.headerFont,
    fontWeight: '600',
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    marginLeft: Styles.Spacing.m3,
  },

  snackButtonText: {
    fontFamily: Styles.FontFamily.figtreeRegular,

    fontSize: Styles.Fonts.f7,
    fontWeight: '600',
  },
  revolveButtonStyle: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontWeight: '600',
    fontSize: Styles.Fonts.f7,
  },
  snackButton: {
    marginBottom: Styles.Spacing.m2 + Styles.Spacing.m1,
    borderWidth: 1,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m1,
  },

  revolveMainTxt: {
    fontWeight: '700',
    fontSize: 18,
    color: Styles.black,
    fontFamily: Styles.FontFamily.comodoVintage,
  },
  cardNumber: {
    color: Styles.lightGray,
    fontSize: Styles.Fonts.f1,
  },
  revolveAddTxt: {
    fontWeight: '400',
    fontSize: 18,
    color: Styles.black,
  },

  starIconView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  invite: {
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.5 : 2,
    paddingVertical: 10,
    paddingHorizontal: 16,
    marginTop: 20,
    marginBottom: 16,
    overflow: 'hidden',
  },
  iconView: {
    width: Styles.Spacing.m4,
    backgroundColor: Styles.primaryColor,
    height: Styles.Spacing.m4,
    alignItems: 'center',
    borderRadius: Styles.Spacing.m2,
    justifyContent: 'center',
  },
  snackDescriptionText: {
    fontWeight: '400',
    marginVertical: Styles.Spacing.m1,
    color: Settings.isRevolve() ? Styles.primaryColor : Styles.darkColor,
    fontSize: Styles.Fonts.sectionHeader,
    textAlign: 'left',
  },
  label: {
    fontSize: Styles.Fonts.f1,
    color: Styles.darkColor,
  },
});

export default ReferralView;
