import React, {useContext} from 'react';
import {StyleSheet, View, TouchableOpacity, Dimensions} from 'react-native';
import Styles from '../../Styles';
import AVText from 'src/components/elements/AVText';
import ShopIcon from 'src/components/img/svg/ShopIcon';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import {useAppSelector} from 'src/redux/hooks';
import {store} from 'src/redux/store';
import {
  fetchRecentCampusesAndLocations,
  swicthCurrentCampusesAndLocations,
} from 'src/redux/slices/campusLocationSlice';
import NavActions from 'src/actions/NavActions';
import Events from 'src/logging/Events';
import ScreenContext from 'src/components/ScreenContext';
import Settings from 'src/Settings';
import {getDescriber} from './descriptor/DescriptorType';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import ShopIconPay from 'src/components/img/svg/ShopIconpay';

export type RecentLocationType = {
  name: string;
  locationId?: string;
  campusId?: string;
  displayName: string;
  lastUpdated: string;
  city: string;
  state: string;
};

type RecentLocationViewProps = {
  recentLocation: RecentLocationType;
  showBorder?: boolean;
};

const width = Dimensions.get('window').width;
const {getCampusLocationStyles} = getDescriber();

const RecentLocationView = (props: RecentLocationViewProps) => {
  const accountId = useAppSelector((s) => s.account.account.id);
  const context = useContext(ScreenContext);

  //Select recent location/campus and fetch all the locations in the selected campus
  const onClickRecentLocation = async () => {
    try {
      context.actions.showSpinner();
      await store.dispatch(
        swicthCurrentCampusesAndLocations({
          accountId,
          locationId: props.recentLocation.locationId,
        }),
      );
      await store.dispatch(fetchRecentCampusesAndLocations(accountId));
      NavActions.pop();
      FirebaseAnalytic.trackEvent(
        'onClickRecentLocation',
        'RecentLocationView',
        {
          ...props,
        },
      );
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'RecentLocationView:onClickRecentLocation',
        generateErrorMessage(error),
      );
      Events.Error.trackEvent(
        'Exception',
        'RecentLocationView:onClickRecentLocation',
        generateErrorMessage(error),
      );
    } finally {
      context.actions.hideSpinner();
    }
  };

  //Returns address with city and state
  const recentLocationAddress = () => {
    let recentAddress = '';
    if (props.recentLocation.city) {
      recentAddress = props.recentLocation.city;
    }
    if (props.recentLocation.state) {
      recentAddress = recentAddress
        ? recentAddress + ', ' + props.recentLocation.state
        : props.recentLocation.state;
    }
    return recentAddress;
  };

  return (
    <View
      style={[
        styles.container,
        {
          borderColor: Styles.Colors.PayNew.neutralHuesBase09,
        },
      ]}
    >
      <TouchableOpacity
        style={[
          styles.content,
          Settings.isRevolveAnd365Pay() && styles.contentMargin,
        ]}
        onPress={onClickRecentLocation}
        accessible={true}
        accessibilityHint={`Double tap to select ${props.recentLocation.displayName}`}
        accessibilityLabel={`RecentLocation${props.recentLocation.displayName}`}
        aria-label={`RecentLocation${props.recentLocation.displayName}`}
        accessibilityRole="button"
      >
        {Settings.is365Pay() ? (
          <View style={{paddingLeft: 8}}>
            <ShopIconPay size={25} />
          </View>
        ) : (
          <ShopIcon size={32} />
        )}

        <View style={styles.detailsView}>
          <AVText
            style={
              getCampusLocationStyles()['recentLocationViewLocationNameText']
            }
            accessibilityLabel={props.recentLocation.displayName}
            aria-label={`${props.recentLocation.displayName}, text`}
            accessibilityRole="text"
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            numberOfLines={1}
          >
            {props.recentLocation.displayName}
          </AVText>
          {recentLocationAddress() !== '' && (
            <AVText
              numberOfLines={1}
              style={getCampusLocationStyles()['recentLocationAddressText']}
              accessibilityLabel={recentLocationAddress()}
              aria-label={`${recentLocationAddress()}, text`}
              accessibilityRole="text"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
            >
              {recentLocationAddress()}
            </AVText>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: width - Styles.Spacing.m3 * 2,
    justifyContent: 'center',
  },
  content: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  contentMargin: {
    marginLeft: Styles.Spacing.m2,
  },
  border: {
    height: 1,
  },
  borderMargin: {
    marginLeft: Styles.Spacing.m4 + Styles.Spacing.m3,
    marginRight: Styles.Spacing.m1,
  },
  detailsView: {
    marginRight: Styles.Spacing.m2,
    marginLeft: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m2,
    height: Styles.Heights.h2 + Styles.Spacing.m3 + Styles.Spacing.m2,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
});
export default RecentLocationView;
