import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function RightArrowRound(props) {
  return (
    <Svg width={45} height={44} viewBox="0 0 45 44" fill="none" {...props}>
      <Path
        d="M.5 22c0-12.15 9.85-22 22-22s22 9.85 22 22-9.85 22-22 22-22-9.85-22-22z"
        fill="#025D33"
        fillOpacity={0.15}
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.29 14.533l5.699 5.966c.34.33.511.778.511 1.272 0 .495-.17.938-.511 1.273l-5.7 5.989a1.68 1.68 0 01-2.454 0 1.88 1.88 0 010-2.569l2.739-2.863h-12.33c-.966 0-1.744-.813-1.744-1.818 0-1.006.778-1.819 1.739-1.819h12.33l-2.74-2.863a1.88 1.88 0 010-2.568 1.687 1.687 0 012.46 0z"
        fill="#025D33"
      />
    </Svg>
  );
}

export default RightArrowRound;
