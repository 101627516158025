import Styles from '../../../Styles';
import {StyleSheet, View} from 'react-native';
import Localized from 'src/constants/AppStrings';
import React from 'react';
import CreditCardInput from 'src/components/elements/funding/CreditCardInput';
import CustomTextInput from 'src/components/elements/CustomTextInput';
import {CreditCard} from 'src/models/CreditCard';
import AVText from 'src/components/elements/AVText';
import Switch from 'src/components/elements/Switch';
import WarningIconSnackDetails from 'src/components/img/svg/WarningIconSnackDetails';
import StarIcon from 'src/components/img/svg/StarIcon';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';

export function getCardDetailsTitle(): string {
  return Localized.Labels.card_details;
}

export function getCardDetailsView(props: CreditCard): JSX.Element {
  const expDate =
    props.expirationMonth.toString() +
    '/' +
    props.expirationYear.toString().slice(2);
  return (
    <React.Fragment>
      <View style={styles.container}>
        <View style={styles.row}>
          <CreditCardInput
            accessibilityLabel={Localized.Labels.card_number}
            label={Localized.Labels.card_number}
            editable={false}
            issuer={props.cardIssuer}
            value={props.name}
            style={{marginBottom: 32}}
            textStyle={styles.textInput}
            outlineStyle={{
              borderRadius: 8,
              borderWidth: 1,
              borderColor: Styles.neutralHues,
            }}
          />
        </View>
        <View style={{marginTop: -30}}>
          <CustomTextInput
            label={Localized.Labels.expires_mm_yy}
            value={expDate}
            defaultValue={expDate}
            editable={false}
            accessible={true}
            accessibilityLabel={Localized.Labels.expires_mm_yy}
            accessibilityValue={{
              text: expDate,
            }}
            accessibilityState={{disabled: true}}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
            textStyle={styles.textInput}
            showShadow={true}
          />
        </View>
      </View>
    </React.Fragment>
  );
}

export function getCardExpiredView(_isExpired: boolean): JSX.Element {
  const expiredView = _isExpired ? (
    <React.Fragment>
      <View style={styles.expiredStatusView}>
        <WarningIconSnackDetails />
        <AVText
          accessibilityLabel={Localized.Labels.this_card_has_expired}
          accessible={true}
          accessibilityRole="text"
          aria-label={`${Localized.Labels.this_card_has_expired}, text`}
          style={[styles.defaultLabel, {marginLeft: 8}]}
        >
          {Localized.Labels.this_card_has_expired}
        </AVText>
      </View>
    </React.Fragment>
  ) : null;
  return expiredView;
}

export function getDefaultStatusView(
  props: CreditCard,
  defaultTokenStatus: boolean,
  switchAction: (value: boolean) => void,
  _isExpired: boolean,
): JSX.Element {
  if (props.isDefault) {
    return (
      <React.Fragment>
        <View style={styles.statusView}>
          <StarIcon />
          <AVText
            accessible={true}
            accessibilityLabel={
              Localized.Labels.this_card_is_set_as_your_default_and_is_starred
            }
            accessibilityRole="text"
            aria-label={`${Localized.Labels.this_card_is_set_as_your_default_and_is_starred}, text`}
            style={styles.defaultLabel}
          >
            {Localized.Labels.this_card_is_set_as_your_default_and_is_starred}
          </AVText>
        </View>
      </React.Fragment>
    );
  } else {
    if (_isExpired) {
      return null;
    }
    return (
      <React.Fragment>
        <View
          style={[
            styles.defaultStatusView,
            {marginTop: Styles.Spacing.m0, justifyContent: 'flex-end'},
          ]}
        >
          <Switch
            accessibilityLabel={Localized.Labels.set_as_default}
            testID="setAsDefault"
            text={Localized.Labels.set_as_default}
            accessibilityState={{checked: defaultTokenStatus}}
            value={defaultTokenStatus}
            onValueChange={(value) => switchAction(value)}
          />
        </View>
      </React.Fragment>
    );
  }
}

export function getDeleteButton(deleteAction: () => void): JSX.Element {
  return (
    <RoundedAppButton
      buttonType={RoundedAppButtonType.Solid}
      accessibilityLabelValue={Localized.Buttons.delete_card}
      aria-label={Localized.Buttons.delete_card}
      onPress={deleteAction}
      titleText={Localized.Buttons.delete_card}
      buttonViewStyle={styles.deleteButton}
      buttonContainerStyle={styles.deleteButtonContainer}
      textStyle={styles.deleteText}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
    marginHorizontal: Styles.Spacing.m3,
    marginBottom: 16,
  },
  leftInput: {
    flexDirection: 'column',
    flex: 0.5,
    marginRight: Styles.Spacing.m1,
  },
  rightInput: {
    flexDirection: 'column',
    flex: 0.5,
    marginLeft: Styles.Spacing.m1,
  },
  row: {
    flexDirection: 'row',
  },
  expiredStatusView: {
    marginHorizontal: Styles.Spacing.m3,
    marginVertical: Styles.Spacing.m1,
    backgroundColor: 'rgba(215, 38, 61, 0.15)',
    paddingVertical: 16,
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 12,
  },
  statusView: {
    marginVertical: Styles.Spacing.m2,
    backgroundColor: 'rgba(6, 109, 255, 0.15)',
    paddingVertical: 24,
    borderRadius: 8,
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: Styles.Spacing.m4 + Styles.Spacing.m2,
    paddingLeft: 12,
  },
  defaultStatusView: {
    //flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  defaultLabel: {
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    marginLeft: 12,
    marginRight: Styles.Spacing.m1,
    fontFamily: Styles.FontFamily.aeonikRegular,
    color: Styles.black,
  },
  textInput: {
    fontSize: Styles.Fonts.f1 + 2,
    color: Styles.black,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '400',
    height: 56,
  },
  deleteButton: {
    backgroundColor: Styles.Colors.PayNew.error,
    height: 42,
  },
  deleteButtonContainer: {
    right: 16,
    bottom: 32,
    position: 'absolute',
  },
  deleteText: {
    paddingHorizontal: 12,
    textAlignVertical: 'center',
    fontSize: Styles.Fonts.f1,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
  },
});

export default {
  getCardDetailsView,
  getCardExpiredView,
  getDefaultStatusView,
  getDeleteButton,
  getCardDetailsTitle,
};
