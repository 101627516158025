import React from 'react';
import {PixelRatio, ScrollView, StyleSheet, View} from 'react-native';
import DashedLine from 'react-native-dashed-line';
import {withGlobalize, WithGlobalizeProps} from 'react-native-globalize';
import {getTaxDescription} from 'src/services/TranslationService';
import TransactionStore from 'src/stores/TransactionStore';
import AVText from '../../elements/AVText';
import Styles from '../../Styles';
import {
  BundleType,
  TransactionDetail,
  CartItem,
} from 'src/types/TransactionDetail';
import Localized from 'src/constants/AppStrings';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import CartTypes from 'src/constants/cart/CartTypes';
import Util from 'src/Util';
import CartService from 'src/services/CartService';
import AccountConstants from 'src/constants/AccountConstants';
import ScreenContext from '../../ScreenContext';
import {MenuProductType} from 'src/types/Menu';
import {CreditCard} from 'src/models/CreditCard';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PreparationMethodValues} from 'src/types/PreparationMethods';
import {Identifier} from 'src/types/serverTypes/Account';
import {LocationType} from 'src/types/Location';

import PromotionTypes from 'src/constants/cart/PromotionTypes';
import CartProductItem from './CartProductItem';
import ListCartEmpty from './ListCartEmpty';
import CartItemBundle from './CartItemBundle';
import DiscountCode from './DiscountCode';
import RoundedAppButton, {
  RoundedAppButtonType,
} from '../../elements/RoundedAppButton';
import AVTouchableOpacity from 'src/components/elements/AVTouchableOpacity';
import MenuService from 'src/services/MenuService';
import {getDescriber} from 'src/components/screens/orderAhead/descriptor/DescriptorType';
import TagLightIcon from 'src/components/img/svg/TagLightIcon';

const {
  getTransactionUpsellName,
  getTransactionUpsellProductsList,
  renderTenderDiscount,
} = getDescriber();

export type ListScanItemsProps = {
  balance: number;
  trashClick?: (itemNumbers: Array<number>) => void;
  editClicked?: (item: CartItem) => void;
  showGrandTotal: boolean;
  hideYourItems: boolean;
  cartType: CartTypes;
  checkoutDone: boolean;
  onAddItem?: () => void;
  locationId?: string;
  noteView?: React.ReactNode;
  preparationMethod: PreparationMethodValues;
  creditCards?: Array<CreditCard>;
  hasConsumerRoles: boolean;
  consumerRoleButton?: string;
  validateDiscountCode?: () => void;
  addDiscountToCart?: () => void;
  location?: LocationType;
  handleCheckoutClick?: () => void;
  discountCodeButtonAction?: () => void;
  saveDiscountCode?: (discountCode: string | null) => void;
  discountCodeSuccess?: boolean;
  discountCodeError?: boolean;
  changeToDefaultDiscount?: boolean;
  onChangeBtnPress?: () => void;
} & WithGlobalizeProps;

type ListScanItemsState = {
  transaction: TransactionDetail | null;
  displayItems: Array<CartItem>;
  consumerRoleIdentifier: Identifier | null;
};

class ListScanItems extends React.Component<
  ListScanItemsProps,
  ListScanItemsState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  cartScroller: ScrollView | null;

  constructor(props: ListScanItemsProps) {
    super(props);
    this.state = {
      transaction: TransactionStore.getTransaction(),
      displayItems: TransactionStore.getDisplayItems(),
      consumerRoleIdentifier: AccountStore.getConsumerIdentifier(),
    };
    this.onTransactionStoreChanged = this.onTransactionStoreChanged.bind(this);
    this.onAccountStoreChanged = this.onAccountStoreChanged.bind(this);
    this.onAddItemClick = this.onAddItemClick.bind(this);
    this.updateCart = this.updateCart.bind(this);
    this.getTitle = this.getTitle.bind(this);
    this.renderTransactionLevelUpsells =
      this.renderTransactionLevelUpsells.bind(this);
  }

  componentDidMount() {
    AccountStore.addChangeListener(this.onAccountStoreChanged);
    TransactionStore.addChangeListener(this.onTransactionStoreChanged);
  }

  componentWillUnmount() {
    AccountStore.removeChangeListener(this.onAccountStoreChanged);
    TransactionStore.removeChangeListener(this.onTransactionStoreChanged);
  }

  onTransactionStoreChanged() {
    const transaction = TransactionStore.getTransaction();
    const displayItems = TransactionStore.getDisplayItems();
    if (!this.props.checkoutDone) {
      this.setState({
        transaction,
        displayItems,
      });
    }
  }
  onAccountStoreChanged() {
    const consumerRoleIdentifier = AccountStore.getConsumerIdentifier();
    if (!this.props.checkoutDone) {
      this.setState({
        consumerRoleIdentifier,
      });
    }
  }

  renderCartItem = (item: CartItem) => {
    return (
      <CartProductItem
        key={item.LineNumber}
        item={item}
        trashClick={this.props.trashClick}
        editClicked={this.props.editClicked}
        canEdit={this.props.cartType === CartTypes.OrderAhead}
        canDelete={this.props.cartType !== CartTypes.TouchlessCoffee}
      />
    );
  };
  getCartItems = (): Array<JSX.Element> => {
    let items: JSX.Element[] = [];
    const nonBundledItems = this.state.displayItems.filter(
      (item) => !item.IsInBundle,
    );

    if (nonBundledItems && nonBundledItems.length > 0) {
      items = nonBundledItems.map((item) => this.renderCartItem(item));
    }

    return items;
  };
  getBundles = (bundledItems: Array<CartItem>): Array<BundleType> => {
    const bundles: Array<BundleType> = [];
    bundledItems.forEach((item) => {
      const bundle = bundles.find(
        (innerBundle) => innerBundle.id === item.DiscountHeaderId,
      );

      if (!bundle) {
        let id = item.DiscountHeaderId;
        let title = item.DiscountDisplayName;

        if (!id) {
          id = 'id';
        }

        if (!title) {
          title = 'title';
        }

        bundles.push({
          id,
          items: [item],
          lineNumber: item.LineNumber || 0,
          title,
        });
      } else {
        bundle.items.push(item);
      }
    });
    return bundles;
  };
  renderBundle = (bundle: BundleType) => {
    return (
      <CartItemBundle
        key={bundle.lineNumber}
        bundle={bundle}
        trashClick={this.props.trashClick}
      />
    );
  };
  getCartBundles = () => {
    let renderedBundles: JSX.Element[] = [];
    const bundledItems = this.state.displayItems.filter(
      (item) => item.IsInBundle,
    );

    if (bundledItems && bundledItems.length > 0) {
      const bundles = this.getBundles(bundledItems);
      renderedBundles = bundles.map((bundle) => this.renderBundle(bundle));
    }

    return renderedBundles;
  };

  getContainerStyle = () => {
    if (this.props.cartType === CartTypes.OrderAhead) {
      return <View style={{marginTop: -Styles.Spacing.m2}}></View>;
    }
    return <View style={{marginTop: Styles.Spacing.m3}}></View>;
  };

  getCartContents = (): Array<JSX.Element> => {
    const items = this.getCartItems();
    const bundles = this.getCartBundles();
    return bundles.concat(items);
  };

  async updateCart(
    items: Array<CartItem>,
    scancode?: string,
    discountCode?: string,
  ) {
    this.context.actions.showSpinner();
    await CartService.updateCart(
      this.props.locationId ?? '',
      AccountStore.getAccountId(),
      [],
      items,
      AccountConstants.SOS_LOCATION_TYPE,
      scancode || '',
      TransactionStore.getPickupTime(),
      this.props.preparationMethod,
      this.context,
      discountCode,
    );
    this.context.actions.hideSpinner();
  }

  // This is called only for Upsell items
  onAddItemClick(item: MenuProductType) {
    const items = [...TransactionStore.getAllItems()];
    items.unshift({
      BarCode: item.scancode,
      Modifiers: [],
      PrintGroups: item.printgroups,
      Upsell: true,
    });
    const discountCode =
      this.props.location?.locationFeatures.is365PayAfterTaxPromo &&
      TransactionStore.isAfterTaxPromoApplied()
        ? PromotionTypes.AfterTaxPromo
        : null;
    this.updateCart(items, item.scancode, discountCode || '');
  }

  getTitle(item: MenuProductType) {
    if (item.price) {
      const price = item.price;

      if (price > 0) {
        return `${item.name} (+${Util.formatCurrency(
          this.props,
          price,
          AccountStore.getCurrency(),
        )})`;
      }
    }

    return item.name;
  }

  getPrice(item: MenuProductType) {
    if (item.price) {
      const price = Util.formatCurrency(
        this.props,
        item.price,
        AccountStore.getCurrency(),
      );
      return price;
    }
    return 0;
  }

  getLocationName() {
    if (this.props.cartType == CartTypes.ScanAndPay) {
      return (
        <AVText
          accessible={true}
          accessibilityLabel={this.props.location.name}
          aria-label={this.props.location.name}
          style={styles.locationName}
        >
          {this.props.location.name}
        </AVText>
      );
    } else {
      return null;
    }
  }

  getDashedLine() {
    return (
      <DashedLine
        dashGap={PixelRatio.roundToNearestPixel(2)}
        dashLength={PixelRatio.roundToNearestPixel(3)}
        dashThickness={PixelRatio.roundToNearestPixel(1)}
        dashColor={Styles.Colors.PayNew.neutralHuesBase09}
        style={styles.dashStyle}
      />
    );
  }

  getTotalTaxes = () => {
    const {transaction} = this.state;

    if (!transaction?.Taxes) return 0;

    return transaction.Taxes.reduce((total, cur) => {
      return total + Number(cur.TaxAmount);
    }, 0);
  };

  getAfterTaxTotal(discount?: number) {
    const currentTaxes = this.getTotalTaxes();
    const totalTaxes = currentTaxes - (discount ?? 0);
    return (
      <AVText
        style={[
          {
            fontFamily: Styles.FontFamily.aeonikRegular,
            fontSize: 18,
            fontWeight: '400',
            textAlign: 'right',
            textDecorationLine: 'none',
          },
        ]}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
      >
        {Util.formatCurrency(
          this.props,
          totalTaxes > 0 ? totalTaxes : 0,
          AccountStore.getCurrency(),
        )}
      </AVText>
    );
  }

  renderAfterTaxPromo(discount: number) {
    const afterTaxPromoApplied = TransactionStore.isAfterTaxPromoApplied();
    const discountString = `(${Util.formatCurrency(
      this.props,
      discount * -1,
      AccountStore.getCurrency(),
    )})`;
    return (
      <AVTouchableOpacity
        onPress={this.props.addDiscountToCart}
        testID="payRollAgreement"
      >
        <View
          style={
            !afterTaxPromoApplied && {
              alignSelf: 'flex-start',
              borderBottomColor: '#138665',
              borderBottomWidth: 1,
            }
          }
        >
          <View style={styles.subTotalView}>
            <View style={{flexDirection: 'row', gap: Styles.Spacing.m1}}>
              {afterTaxPromoApplied && <TagLightIcon />}
              <AVText
                style={[
                  {
                    fontFamily: Styles.FontFamily.aeonikRegular,
                    fontSize: 18,
                    fontWeight: '400',
                    color: '#138665',
                    textAlign: 'left',
                    textDecorationLine: 'none',
                  },
                ]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm8}
              >
                {Localized.Labels.formatString(
                  afterTaxPromoApplied
                    ? Localized.Labels.after_tax_promo
                    : Localized.Labels.after_tax_lbl,
                  afterTaxPromoApplied ? discountString : '',
                )}
              </AVText>
            </View>

            {afterTaxPromoApplied && this.getAfterTaxTotal(discount)}
          </View>
        </View>
      </AVTouchableOpacity>
    );
  }

  renderTransactionLevelUpsells() {
    const transUpsellItems = TransactionStore.getUpsellItems();

    if (
      transUpsellItems &&
      transUpsellItems.length > 0 &&
      this.props.cartType === CartTypes.OrderAhead
    ) {
      const upsellProductsInMenu = transUpsellItems
        .reduce<MenuProductType[]>((result, item) => {
          const productItem = MenuService.getProductByScanCode(item.ScanCode);
          if (productItem) {
            result?.push(productItem);
          }
          return result;
        }, [])
        ?.slice(0, TransactionStore.getUpsellMaxItems());

      return (
        <View>
          {getTransactionUpsellName(TransactionStore.getUpsellName())}
          {getTransactionUpsellProductsList(
            upsellProductsInMenu,
            this.onAddItemClick,
            this.props.location?.locationFeatures?.dna,
            this.props,
          )}
          {this.getDashedLine()}
        </View>
      );
    }
    return null;
  }

  render() {
    const items = this.getCartContents();
    const afterTaxPromoApplied = TransactionStore.isAfterTaxPromoApplied();

    if (items.length === 0 && !this.props.checkoutDone) {
      return <ListCartEmpty cartType={this.props.cartType} />;
    }

    let due = 0;

    if (this.state.transaction && this.state.transaction.Due) {
      due = parseFloat(this.state.transaction.Due);
    }

    const subtotal = parseFloat(this.state.transaction?.SubTotal ?? '');

    const afterTaxDiscount = parseFloat(
      this.state.transaction?.AfterTaxDiscount ?? '',
    );

    return (
      <>
        <View style={styles.container}>
          {this.getLocationName()}
          {this.getContainerStyle()}
          <View>{items}</View>
        </View>
        {!this.props.location?.locationFeatures?.is365PayAfterTaxPromo && (
          <DiscountCode
            location={this.props.location}
            consumerRoleButton={this.props.consumerRoleButton}
            addDiscountToCart={this.props.addDiscountToCart}
            hasConsumerRoles={this.props.hasConsumerRoles}
            consumerRoleIdentifier={this.state.consumerRoleIdentifier}
            validateDiscountCode={this.props.validateDiscountCode}
            discountCodeButtonAction={this.props.discountCodeButtonAction}
            saveDiscountCode={this.props.saveDiscountCode}
            showSuccessMsg={this.props.discountCodeSuccess}
            showErrorMsg={this.props.discountCodeError}
            changeToDefaultDiscount={this.props.changeToDefaultDiscount}
            onChangeBtnPress={this.props.onChangeBtnPress}
          />
        )}

        <View style={styles.container}>
          {this.getDashedLine()}
          {this.renderTransactionLevelUpsells()}

          <View style={styles.subTotalView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.subtotal}
              aria-label={Localized.Labels.subtotal}
              style={styles.transactionItemsSubtotal}
            >
              {Localized.Labels.subtotal}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.subtotal}
              aria-label={Localized.Labels.subtotal}
              style={styles.transactionItemsSubtotal}
            >
              {Util.formatCurrency(
                this.props,
                subtotal,
                AccountStore.getCurrency(),
              )}
            </AVText>
          </View>

          {this.state.transaction?.Taxes.map((cur, i) => (
            <View style={styles.transactionItemsView} key={i}>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                accessible={true}
                accessibilityRole="text"
                accessibilityLabel={'Tax'}
                aria-label={'Tax'}
                style={styles.transactionItemsSubtotal}
              >
                {getTaxDescription(Localized, cur.Description)}
              </AVText>
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
                accessible={true}
                accessibilityRole="text"
                accessibilityLabel={'Sub total'}
                aria-label={'Sub total'}
                style={[
                  styles.transactionItemsSubtotal,
                  afterTaxPromoApplied && {textDecorationLine: 'line-through'},
                ]}
              >
                {Util.formatCurrency(
                  this.props,
                  Number(cur.TaxAmount),
                  AccountStore.getCurrency(),
                )}
              </AVText>
            </View>
          ))}
          {Settings.is365Pay() &&
            this.props.location?.locationFeatures?.is365PayAfterTaxPromo &&
            this.renderAfterTaxPromo(afterTaxDiscount)}
          {this.getDashedLine()}

          <View style={styles.transactionTotalView}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={Localized.Labels.total}
              aria-label={Localized.Labels.total}
              style={styles.transactionTotal}
            >
              {Localized.Labels.total}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
              accessible={true}
              accessibilityRole="text"
              accessibilityLabel={'Sub total'}
              aria-label={'Sub total'}
              style={styles.transactionTotal}
            >
              {Util.formatCurrency(this.props, due, AccountStore.getCurrency())}
            </AVText>
          </View>
        </View>
        {renderTenderDiscount(this.state.transaction, this.props)}

        <View style={styles.bottom} />
        {items.length > 0 && (
          <RoundedAppButton
            buttonType={RoundedAppButtonType.Solid}
            titleText={
              'Pay ' +
              Util.formatCurrency(this.props, due, AccountStore.getCurrency())
            }
            onPress={this.props.handleCheckoutClick}
            buttonContainerStyle={styles.checkoutButtonContainer}
          />
        )}
      </>
    );
  }
}

const styles = StyleSheet.create({
  checkoutButtonContainer: {
    right: Styles.Spacing.m3,
    position: 'absolute',
    bottom: Styles.Spacing.m3,
  },
  transactionTotalView: {
    flexDirection: 'row',
    marginTop: -Styles.Spacing.m1,
    justifyContent: 'space-between',
  },
  subTotalView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  transactionItemsView: {
    flexDirection: 'row',
    paddingTop: Styles.Spacing.m1,
    justifyContent: 'space-between',
  },
  transactionPromoView: {
    marginTop: Styles.Spacing.m1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  transactionItemsText: {
    color: Styles.blackBase01,
    fontSize: Styles.Fonts.f7,
  },
  transactionPromoText: {
    color: Styles.Colors.PayNew.success,
    fontSize: Styles.Fonts.f7,
  },
  transactionItemsSubtotal: {
    color: Styles.Colors.PayNew.black01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f7,
  },
  transactionTotal: {
    fontWeight: 'bold',
    color: Styles.blackBase01,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.headerFont,
  },
  locationName: {
    marginTop: Styles.Spacing.m3,
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f2,
    color: Styles.Colors.PayNew.black01,
    fontWeight: 'bold',
  },

  revolveTextLabelTotal: {
    fontSize: 32,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '400',
  },
  bottom: {
    height: Styles.Spacing.m5 + Styles.Spacing.m3,
  },
  container: {
    marginHorizontal: Styles.Spacing.m3,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
    overflow: 'hidden',
    width: '100%',
  },
  discountLabel: {
    color: Settings.isRevolve() ? '#22212D' : Styles.darkColor,
    fontSize: Settings.isRevolve() ? 18 : Styles.Fonts.f1,
    fontWeight: Settings.isRevolve() ? '400' : '300',
  },
  discountSection: {
    flex: 1,
  },
  healthGrade: {},
  emptyContent: {
    alignSelf: 'stretch',
    flex: 1,
  },
  summaryContainer: {
    backgroundColor: 'red',
  },
  summaryTextContainer: {
    flex: 1,
    marginTop: Styles.Spacing.m1,
  },
  upsellContainer: {
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
  upsellTitleText: {
    fontWeight: '600',
    fontSize: Styles.Fonts.f2,
    paddingLeft: Styles.Spacing.m2,
  },
  upsellItem: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: Styles.Spacing.m1,
    paddingLeft: Styles.Spacing.m2,
  },
  upsellText: {
    marginRight: Styles.Spacing.m2,
    flex: 1,
    fontSize: Settings.isRevolve() ? 18 : Styles.Fonts.f1,
    fontWeight: Settings.isRevolve() ? '400' : '300',
    color: Settings.isRevolve() ? '#22212D' : Styles.darkColor,
  },
  icon: {
    marginRight: Styles.Spacing.m2,
  },
  btnView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnDiscountCode: {
    alignSelf: 'flex-start',
    marginTop: Styles.Spacing.m2,
    marginRight: Styles.Spacing.m2,
  },
  btnEditCodeText: {
    color: Styles.primaryColor,
    marginLeft: Styles.Spacing.m1,
    textAlign: 'center',
    marginTop: Styles.Spacing.m2,
  },
  defaultLabel: {
    marginBottom: Styles.Spacing.m1,
    color: '#22212D',
    fontSize: 18,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '400',
  },
  textFont: {
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
  columnLabel: {
    flex: 4,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  columnAmount: {
    flex: 1,
    alignItems: 'flex-end',
  },
  itemsListBottom: {
    // paddingBottom: Styles.Spacing.m2,
  },
});

const ConnectedListScanItems = connect((state: RootState) => ({
  creditCards: state.account.creditCards,
  consumerRoleIdentifier: state.account.account.consumerRoleIdentifier,
}))(ListScanItems);

export default withGlobalize<ListScanItemsProps>(ConnectedListScanItems);
