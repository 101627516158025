import {createAsyncThunk} from '@reduxjs/toolkit';
import Events, {FundStatus} from 'src/logging/Events';
import {alertError} from 'src/components/helpers/AlertHelper';
import {hideSpinner, showSpinner} from 'src/redux/slices/screenSlice';
import Localized from 'src/constants/AppStrings';
import {AppDispatch, RootState} from '../../store';
import Settings from 'src/Settings';
import Util from 'src/Util';
import AppApi from 'src/api/AppApi';
import {adjustDefaultBalance} from 'src/redux/slices/accountSlice';
import {handleNextAction, initStripe} from 'src/nativeModules/Stripe';
import ActionsFactory from 'src/actions/ActionsFactory';
import AccountStore from 'src/stores/AccountStore';
import {PaymentCredentials} from '../../../models/PaymentCredentials';
import Logger from 'src/logging/Logger';
import {generateErrorMessage} from '../../../logging/generateErrorMessage';
import CrashlyticsEvents from 'src/logging/Crashlytics';

const PAYROLL_LIMIT_ERROR = 'insufficient-payroll-balance';

function addFundsFailed(msg?: string) {
  let errorMessage = Localized.Errors.failed_to_add_funds;

  if (msg === PAYROLL_LIMIT_ERROR) {
    errorMessage = Localized.Errors.reached_payroll_deduct_limit;
  }

  alertError(errorMessage);
}

async function addFundsSuccess({
  amount,
  cardToken,
  paymentCredentials,
  dispatch,
}: {
  amount: number;
  cardToken: string;
  paymentCredentials: PaymentCredentials;
  dispatch: AppDispatch;
  accountId: string;
}) {
  Events.AddFunds.trackEvent(
    FundStatus.Success,
    amount,
    cardToken,
    paymentCredentials.type,
  );
  // TODO will remove
  await ActionsFactory.getAccountActions().getBalance(
    AccountStore.getAccountId(),
    true,
  );
  await dispatch(
    adjustDefaultBalance({
      amount,
      reason: Localized.Labels.add_funds,
    }),
  );
}

export interface IAddFundsCardParams {
  amount: number;
  tokenId: string;
  addFundSuccess?: () => void;
}

export const addFundsCard = createAsyncThunk<
  void,
  IAddFundsCardParams,
  {dispatch: AppDispatch; state: RootState}
>(
  'funding/addFundsCard',
  async ({amount, tokenId, addFundSuccess}, {dispatch, getState}) => {
    dispatch(
      showSpinner({
        spinnerText: `${Localized.Labels.adding_funds}...`,
      }),
    );

    try {
      const {paymentCredentials, account} = getState().account;
      const balanceId = account?.defaultBalance?.id;
      const processor = Settings.processors[paymentCredentials.type];

      const result = (await AppApi.addFundsToken(
        account.id,
        balanceId,
        tokenId,
        amount,
        Util.getCurrentDate(),
      )) as {
        accountId: string;
        message: string;
        clientSecret?: string;
        transactionId: string;
        status: string;
      };
      Logger.Log.LogAPIEvent(
        'AppAPI',
        'AddFundsToken',
        JSON.stringify({
          accountId: account.id,
          balanceId,
          tokenId,
          amount,
          transDate: Util.getCurrentDate(),
        }),
        JSON.stringify(result),
      );

      /** if clientsecret is returned */
      if (processor === Settings.processors.stripe && result?.clientSecret) {
        await initStripe({
          publishableKey: paymentCredentials.key,
        });

        const {error, paymentIntent} = await handleNextAction(
          result?.clientSecret,
        );

        if (error) {
          CrashlyticsEvents.log(
            'Exception',
            'FundingService:AddFunds',
            generateErrorMessage(error),
          );
          Events.Error.trackEvent(
            'Exception',
            'FundingService:AddFunds',
            generateErrorMessage(error),
          );
          addFundsFailed();
        } else {
          const confirmPaymentRes = await AppApi.confirmPaymentIntent(
            account.id,
            balanceId,
            paymentIntent.id,
            result.transactionId,
            tokenId,
          );
          Logger.Log.LogAPIEvent(
            'PaymentAPI',
            'ConfirmPaymentIntent',
            JSON.stringify({
              transId: result.transactionId,
              accountId: account.id,
            }),
            JSON.stringify(confirmPaymentRes),
          );
          await addFundsSuccess({
            amount,
            cardToken: tokenId,
            paymentCredentials,
            dispatch,
            accountId: account.id,
          });
          addFundSuccess && addFundSuccess();
        }
      } else if (result && result.accountId) {
        await addFundsSuccess({
          amount,
          cardToken: tokenId,
          paymentCredentials,
          dispatch,
          accountId: account.id,
        });
        addFundSuccess && addFundSuccess();
      } else {
        CrashlyticsEvents.log(
          'Exception',
          'FundingService:AddFunds',
          generateErrorMessage(result.message),
        );
        Events.Error.trackEvent(
          'Exception',
          'FundingService:AddFunds',
          generateErrorMessage(result.message),
        );
        addFundsFailed();
        throw new Error(result.message);
      }
    } catch (exception) {
      CrashlyticsEvents.log(
        'Exception',
        'FundingService:AddFunds',
        generateErrorMessage(exception),
      );
      Events.Error.trackEvent(
        'Exception',
        'FundingService:AddFunds',
        generateErrorMessage(exception),
      );
      addFundsFailed();
    } finally {
      dispatch(hideSpinner());
    }
  },
);
