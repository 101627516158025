import Localized from 'src/constants/AppStrings';
import Styles from 'src/components/Styles';
import BackSubheader from 'src/components/elements/BackSubheader';
import React from 'react';
import {SendSnackDescriberData} from 'src/components/screens/sendSnack/descriptor/sendasnack/DescriptorType';
import Util from 'src/Util';
import AccountStore from 'src/stores/AccountStore';
import SnackDetailsRedesign from 'src/components/img/svg/SnackDetailsRedesign';
import {ButtonType} from 'src/components/elements/RoundedButton';
import AVText from 'src/components/elements/AVText';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {Platform} from 'react-native';
import {WithGlobalizeProps} from 'react-native-globalize';

export function getBackSubHeaderDescriptor(): JSX.Element {
  return <BackSubheader title="" />;
}

export function renderSnackMsgDescriptor(
  props: SendSnackDescriberData & WithGlobalizeProps,
): string | string[] {
  const {amount, senderName} = props;
  const str = Localized.Labels.you_have_received_a_snack;
  return Localized.Labels.formatString(
    str,
    Util.formatCurrency(props, amount, AccountStore.getCurrency()),
    senderName,
  );
}
const getSenderTitle = (_props) => {
  return ['e', 'c'].indexOf(_props.status.toLowerCase()) > -1
    ? Localized.Labels.my_account
    : Localized.Labels.your_snack_sent;
};
export function getTopHeaderSnackDescriptor(
  props: SendSnackDescriberData,
): JSX.Element {
  return (
    <React.Fragment>
      <SnackDetailsRedesign />
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        style={getSendSnackStyleDescriptor()['appTitleStyle']}
      >
        {props.isSender
          ? getSenderTitle(props)
          : Localized.Labels.formatString(
              Localized.Labels.sent_you_snack_desc,
              props.senderName,
            )}
      </AVText>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
        style={getSendSnackStyleDescriptor()['dateStyle']}
      >
        {props.snackDate}
      </AVText>
    </React.Fragment>
  );
}

export function getSnackStickerDescriptor(): JSX.Element {
  return null;
}

export function titleDescriptor(): string | string[] {
  return Localized.Labels.send_a_snack_title;
}
export function snackDetailsBtnFontSTyle() {
  return {fontFamily: Styles.FontFamily.aeonikRegular};
}

export function backHeaderStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    backgroundColor:
      Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
  };
}

export function unblockButtonType() {
  return ButtonType.outline;
}
export function acceptButtonColor() {
  return Styles.Colors.PayNew.primary01;
}
export function blockUnblockButtonColor() {
  return Styles.white;
}

export function getAcceptSnackBtn(props, appContext) {
  return (
    <RoundedAppButton
      accessibilityLabelValue="Accept Snack"
      buttonType={RoundedAppButtonType.Solid}
      titleText={Localized.Buttons.accept_snack_redesign}
      onPress={() =>
        props.acceptSnack({
          snackDetails: props.snackDetails,
          context: appContext,
        })
      }
      buttonContainerStyle={{
        alignSelf: 'flex-end',
        marginRight: Styles.Spacing.m3,
        marginBottom: Styles.Spacing.m3,
      }}
    />
  );
}

export function blockUserBtnStyle() {
  return Styles.white;
}

export function getSendSnackStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    topRow: {
      alignItems: 'flex-start',
      flexDirection: 'column',
      marginBottom: Styles.Spacing.m3,
    },
    content: {
      padding: Styles.Spacing.m3 + Styles.Spacing.m2,
      flex: 1,
      backgroundColor:
        Platform.OS === 'web' ? Styles.white : Styles.tabBarBackgroundColor,
    },
    senderSnackStatusText: {
      color: Styles.black,
      flex: 1,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    appTitleStyle: {
      color: Styles.black,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      flex: 1,
    },
    dateStyle: {
      color: Styles.black,
      fontSize: Styles.Fonts.f8,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
      top: 2,
    },
    description: {
      flex: 1,
      marginTop: Styles.Spacing.m1,
      color: Styles.black,
      fontSize: Styles.Fonts.f1,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    message: {
      color: Styles.black,
      flex: 1,
      marginTop: Styles.Spacing.m2,
      fontSize: Styles.Fonts.f1,
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    receiverSnackStatusText: {
      flex: 1,
      textAlign: 'center',
      color: Styles.black,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    amount: {
      color: Styles.black,
      fontSize: Styles.Fonts.f11 + 8,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    btnBlockUser: {
      alignSelf: 'center',
      marginTop: Styles.Spacing.m3,
      paddingVertical: 8,
      paddingHorizontal: 18,
      borderWidth: 2,
    },
    btnBlockUserText: {
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
    },
    btnAcceptSnack: {
      paddingVertical: 8,
      paddingHorizontal: 18,
    },
  };
}

export default {
  getBackSubHeaderDescriptor,
  getTopHeaderSnackDescriptor,
  getSnackStickerDescriptor,
  renderSnackMsgDescriptor,
  getSendSnackStyleDescriptor,
  getAcceptSnackBtn,
  blockUserBtnStyle,
  backHeaderStyleDescriptor,
  unblockButtonType,
  titleDescriptor,
  blockUnblockButtonColor,
  acceptButtonColor,
  snackDetailsBtnFontSTyle,
};
