import * as React from 'react';
import Svg, {Path, Circle} from 'react-native-svg';
import Settings from 'src/Settings';

type DealUnreadIconProps = {
  width: number;
  height: number;
  bgColor?: string;
};

export default class DealUnreadIcon extends React.Component<DealUnreadIconProps> {
  render() {
    return (
      <>
        {Settings.isRevolve() ? (
          <Svg
            width={this.props.width}
            height={this.props.height}
            viewBox="0 0 54 55"
            fill="none"
          >
            <Path
              d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
              fill="#022649"
            />
            <Path
              d="M13 20.875V29.0508C13 29.9805 13.368 30.8719 14.0271 31.5282L23.6938 41.1532C25.0669 42.5204 27.2913 42.5204 28.6644 41.1532L35.9968 33.8524C37.3699 32.4852 37.3699 30.2704 35.9968 28.9032L26.3301 19.2782C25.671 18.6219 24.7758 18.2555 23.842 18.2555H15.6364C14.1809 18.25 13 19.4258 13 20.875ZM19.1515 22.625C19.6177 22.625 20.0647 22.8094 20.3943 23.1376C20.7239 23.4658 20.9091 23.9109 20.9091 24.375C20.9091 24.8392 20.7239 25.2843 20.3943 25.6125C20.0647 25.9407 19.6177 26.125 19.1515 26.125C18.6854 26.125 18.2383 25.9407 17.9087 25.6125C17.5791 25.2843 17.3939 24.8392 17.3939 24.375C17.3939 23.9109 17.5791 23.4658 17.9087 23.1376C18.2383 22.8094 18.6854 22.625 19.1515 22.625Z"
              fill="#21AD5A"
            />
            <Circle cx="44" cy="10.5" r="10" fill="#A4CCF4" />
          </Svg>
        ) : (
          <Svg width="54" height="55" viewBox="0 0 54 55" fill="none">
            <Path
              d="M0 29.5C0 15.6929 11.1929 4.5 25 4.5C38.8071 4.5 50 15.6929 50 29.5C50 43.3071 38.8071 54.5 25 54.5C11.1929 54.5 0 43.3071 0 29.5Z"
              fill="#066DFF"
            />
            <Path
              d="M13 19.8754V28.0512C13 28.9809 13.368 29.8723 14.0271 30.5285L23.6938 40.1535C25.0669 41.5207 27.2913 41.5207 28.6644 40.1535L35.9968 32.8528C37.3699 31.4856 37.3699 29.2707 35.9968 27.9035L26.3301 18.2785C25.671 17.6223 24.7758 17.2559 23.842 17.2559H15.6364C14.1809 17.2504 13 18.4262 13 19.8754ZM19.1515 21.6254C19.6177 21.6254 20.0647 21.8098 20.3943 22.138C20.7239 22.4662 20.9091 22.9113 20.9091 23.3754C20.9091 23.8395 20.7239 24.2847 20.3943 24.6128C20.0647 24.941 19.6177 25.1254 19.1515 25.1254C18.6854 25.1254 18.2383 24.941 17.9087 24.6128C17.5791 24.2847 17.3939 23.8395 17.3939 23.3754C17.3939 22.9113 17.5791 22.4662 17.9087 22.138C18.2383 21.8098 18.6854 21.6254 19.1515 21.6254Z"
              fill="#FEE0E6"
            />
            <Circle cx="44" cy="10.5" r="10" fill="#E2607F" />
          </Svg>
        )}
      </>
    );
  }
}
