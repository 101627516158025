import AVText from 'src/components/elements/AVText';
import Styles from '../../Styles';
import {Platform} from 'react-native';

import Localized from 'src/constants/AppStrings';
import AVFormattedCurrency from 'src/components/elements/AVFormattedCurrency';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import React from 'react';

export function getFocusAwareStatusBar() {
  return null;
}
export function linkExistingAccountStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      flex: 1,
      padding: Styles.Spacing.m3,
    },
    title: {
      fontSize: Styles.Fonts.f2,
      color: Styles.darkColor,
    },
  };
}

export function getWelcomeViewBckGrndStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    overlay: {},
    view: {
      flex: 1,
    },
  };
}

export function getWelcomeOverLayImage() {
  return null;
}

export function renderYourBalanceRewardLbl(_styles, _accountStr) {
  return (
    <>
      <AVText
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
        style={_styles.payBalance}
      >
        {`${Localized.Labels.your_balance}: `}
        <AVFormattedCurrency
          testID={'balanceFormattedCurrency'}
          accessibilityLabel={
            _accountStr.getAccountBalance()
              ? `$${_accountStr.getAccountBalance().toString()}`
              : '0'
          }
          maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm1}
          style={_styles.payBalance}
          value={_accountStr.getAccountBalance() ?? 0}
          currency={_accountStr.getCurrency()}
        />
      </AVText>
    </>
  );
}

export function getOrTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    orText: {
      fontSize: Styles.Fonts.f1,
      textTransform: 'uppercase',
    },
    solidLine: {
      backgroundColor: '#999',
    },
  };
}

export function getLineContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    lineContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginVertical: Styles.Spacing.m1,
    },
  };
}
export function getWelcomeStatusBarStyleDescriptor(): string {
  return Styles.loginBackground;
}
export function getVersionTextDescriptor(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    versionText: {
      color: Styles.Colors.PayNew.primary01,
      marginLeft: Styles.Spacing.m3,
    },
  };
}
export function getLogoContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    logoContainer: {
      alignSelf: 'center',
      height: Styles.Heights.h3,
      justifyContent: 'center',
      paddingTop: Styles.Spacing.m1,
      marginTop: -80,
      marginBottom: -20,
      flex: 1,
    },
  };
}

export function actionLinkText(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    actionLinkText: {
      color: '#066DFF',
      fontSize: 16,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.aeonikRegular,
      textDecorationLine: 'underline',
    },
  };
}

export function getWelcomeButtonStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    topButton: {
      marginTop: Styles.Spacing.m3,
      alignSelf: 'auto',
      paddingVertical: Styles.Spacing.m1,
    },
  };
}

export function getContainer(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    container: {
      marginTop: 120,
    },
  };
}

export function locationCodebgColor(): string {
  return '#066DFF';
}

export function renderPointsToNextRewardLbl(_styles, nextPoint) {
  const morePointsThanHigher = nextPoint < 0 ? true : false;
  return (
    <>
      <AVText
        style={_styles.pointsToNextRwd}
        maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
        testID={Localized.Labels.redeem}
        adjustsFontSizeToFit={true}
      >
        {!morePointsThanHigher
          ? Localized.Labels.formatString(
              Localized.Labels.points_next_rewrd,
              nextPoint,
            )
          : Localized.Labels.more_points_than_available}
      </AVText>
    </>
  );
}
export function getOnBoardingButton(
  buttonText: string,
  handleClick: () => void,
  disabled?: boolean,
): JSX.Element {
  return (
    <RoundedButton
      buttonType={ButtonType.normal}
      accessible={true}
      accessibilityLabel={buttonText}
      aria-label={buttonText}
      onPress={handleClick}
      text={buttonText}
      textStyle={{paddingHorizontal: Styles.Spacing.m2}}
      color={Styles.Colors.PayNew.primary01}
      disabled={disabled}
      containerStyle={{
        display: 'flex',
        width: 315,
        padding: 14,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Styles.white,
      }}
    />
  );
}

export function getBodyTextStyles(): {
  [key: string]: {[key: string]: string | number};
} {
  return {
    bodyText: {
      color: Styles.Colors.PayNew.black01,
      fontSize: 18,
      fontWeight: '400',
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontStyle: 'normal',
    },
    supportingText: {
      fontFamily: Styles.FontFamily.aeonikRegular,
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: '400',
      color: Styles.Colors.PayNew.darkHuesBase03,
      marginBottom: Styles.Spacing.m2,
      marginTop: Styles.Spacing.m2,
      paddingStart: Styles.Spacing.m3 + Styles.Spacing.m2,
    },
  };
}

export function baseAccountContainerDescriptor(): {
  [key: string]: {[key: string]: number | string};
} {
  return {
    container: {
      backgroundColor: Styles.white,
      paddingBottom: Platform.OS === 'android' ? 10 : 0,
    },
  };
}

export default {
  getOnBoardingButton,
  linkExistingAccountStyleDescriptor,
  getWelcomeStatusBarStyleDescriptor,
  getWelcomeViewBckGrndStyleDescriptor,
  baseAccountContainerDescriptor,
  getOrTextDescriptor,
  getVersionTextDescriptor,
  getLogoContainer,
  getContainer,
  getWelcomeOverLayImage,
  renderPointsToNextRewardLbl,
  getFocusAwareStatusBar,
  getLineContainer,
  getWelcomeButtonStyles,
  locationCodebgColor,
  renderYourBalanceRewardLbl,
  actionLinkText,
  getBodyTextStyles,
};
