import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import React from 'react';
import KeyboardAwareScrollView from '../../elements/KeyboardAwareScrollView';
import ScreenContext from '../../ScreenContext';
import NavActions from 'src/actions/NavActions';
import Styles from '../../Styles';
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  View,
  Linking,
} from 'react-native';
import Localized from 'src/constants/AppStrings';
import AVText from 'src/components/elements/AVText';
import AppRoutes from 'src/AppRoutes';
import AllyTextInput from 'src/components/elements/AllyTextInput';
import ActionsFactory from 'src/actions/ActionsFactory';
import BaseAccountSetupScreenRedesign from 'src/components/screens/BaseAccountSetupScreenRedesign';
import {getDescriber} from '../descriptor/DescriptorType';
import {CampusLocationType} from 'src/components/elements/home/CampusLocationView';
import BluetoothManager, {
  BluetoothEvents,
  BluetoothStates,
} from 'src/services/bluetooth/BluetoothManager';
import MachineStore from 'src/stores/MachineStore';
import BluetoothDeviceTypes from 'src/constants/BluetoothDeviceTypes';
import MachineAction from 'src/actions/MachineActions';
import VendorsExchangeDevice from 'src/services/bluetooth/vendorsExchange/VendorsExchangeDevice';
import BeaconDevice from 'src/services/bluetooth/BeaconDevice';
import TSFInsideDevice from 'src/services/bluetooth/tsfInside/TSFInsideDevice';
import AccountStore from 'src/stores/AccountStore';
import Settings from 'src/Settings';
import LocationHandler from 'src/handlers/LocationHandler';
import LocationErrorStatusOnboarding, {
  LocationErrorStatusType,
} from 'src/components/elements/account/LocationErrorStatusOnboarding';
import {NearbyLocationType} from 'src/components/elements/home/NearbyLocationView';
import NearbyLocationViewOnboarding from 'src/components/elements/account/NearbyLocationViewOnboarding';

const {getOnBoardingButton, actionLinkText, getBodyTextStyles} = getDescriber();

type SelectMarketCardLocationProps = {
  code: string;
  data: {id: string; email: string};
  onSuccess: () => void;
};

interface SelectMarketCardLocationState {
  locationCode: string;
  isManuallyLinkingLocation: boolean;
  locations: Array<CampusLocationType>;
  bluetoothState: unknown | null;
  locationSelected: number | null;
  isLocationPermissionEnabled: boolean;
}
class SelectMarketCardLocationScreen extends React.Component<
  SelectMarketCardLocationProps,
  SelectMarketCardLocationState
> {
  pollInterval: NodeJS.Timeout | null = null;
  scrollView: KeyboardAwareScrollView | null = null;
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;
  isLocationPermissionEnabledRef = false;

  constructor(props: SelectMarketCardLocationProps) {
    super(props);
    this.state = {
      locationCode: '',
      isManuallyLinkingLocation: false,
      locations: [],
      bluetoothState: BluetoothStates.PoweredOff,
      locationSelected: null,
      isLocationPermissionEnabled: false,
    };
    this.linkLocation = this.linkLocation.bind(this);
    this.setLocationId = this.setLocationId.bind(this);
    this.checkPermissions = this.checkPermissions.bind(this);
    this.isLocationPermissionEnabledRef = false;

    MachineStore.addChangeListener(this.onMachineStoreChange.bind(this));
    BluetoothManager.addListener(
      BluetoothEvents.onDeviceFound,
      this.bluetoothDeviceFound.bind(this),
    );
    BluetoothManager.addListener(
      BluetoothEvents.onBTStateUpdate,
      this.bluetoothStateUpdated.bind(this),
    );
  }

  componentDidMount() {
    // // Do not remove - use for testing beacon simulation
    // this.bluetoothDeviceFound({
    //   type: BluetoothDeviceTypes.beacon,
    //   beaconId: 'fffffffffffd',
    // });
    LocationHandler.getCurrentLocation();
    const newPermissionState = LocationHandler.isLocationPermissionEnabled;

    this.isLocationPermissionEnabledRef = newPermissionState;
    this.setState({isLocationPermissionEnabled: newPermissionState});

    this.updateLocations();
  }

  async updatePermissions() {
    LocationHandler.getCurrentLocation();

    const newPermissionState = LocationHandler.isLocationPermissionEnabled;

    if (newPermissionState !== this.isLocationPermissionEnabledRef) {
      this.isLocationPermissionEnabledRef = newPermissionState;
      this.setState({isLocationPermissionEnabled: newPermissionState});
    }
  }

  async checkPermissions() {
    this.context.actions.showSpinner();
    await this.updatePermissions();
    setTimeout(async () => {
      this.context.actions.hideSpinner();
      await this.updatePermissions();
    }, 3000); // short delay for workaround to get the correct location permission status
  }

  componentWillUnmount() {
    MachineStore.removeChangeListener(this.onMachineStoreChange.bind(this));
    BluetoothManager.removeListener(
      BluetoothEvents.onDeviceFound,
      this.bluetoothDeviceFound.bind(this),
    );
    BluetoothManager.removeListener(
      BluetoothEvents.onBTStateUpdate,
      this.bluetoothStateUpdated.bind(this),
    );
    if (this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  async bluetoothDeviceFound(device: {type: string}) {
    if (device.type === BluetoothDeviceTypes.vendorsExchange) {
      MachineAction.deviceAddedVE(device as VendorsExchangeDevice);
    } else if (device.type === BluetoothDeviceTypes.beacon) {
      const beacon = device as BeaconDevice;
      MachineAction.marketScanned(
        beacon.beaconId,
        AccountStore.getAccountId(),
        Settings.buildType,
      );
    } else if (device.type === BluetoothDeviceTypes.tsfInside) {
      const tsfInside = device as TSFInsideDevice;
      if (tsfInside.deviceName) {
        MachineAction.machineAdded(tsfInside);
      } else {
        MachineAction.deviceAdded(tsfInside);
      }
    }
  }

  async checkBluetoothState() {
    const currentState = await BluetoothManager.getBluetoothState();
    if (currentState !== this.state.bluetoothState) {
      this.setState({bluetoothState: currentState});
      if (currentState === BluetoothStates?.PoweredOn) {
        BluetoothManager.startScan();
      } else {
        BluetoothManager.stopScan();
        MachineAction.clearMarkets();
        if (NavActions.getLastRoute() === AppRoutes.NewHome) {
          MachineAction.clearMachines();
          MachineAction.clearDevices();
        }
      }
    }
  }

  bluetoothStateUpdated() {
    this.checkBluetoothState();
  }

  updateLocations() {
    const machinesList = MachineStore.getMachines();
    const marketsList = MachineStore.getMarkets();
    this.setState({
      locations: [
        ...machinesList,
        ...marketsList,
      ] as unknown as CampusLocationType[],
    });
  }

  onMachineStoreChange() {
    this.updateLocations();
  }

  setLocationId(text: string) {
    this.setState({locationCode: text});
  }

  async linkLocation() {
    const {locationCode} = this.state;
    const {id: accountId} = this.props.data;

    try {
      const location = await ActionsFactory.getAccountActions().getLocationV1(
        locationCode,
      );
      const locationId = location?.data.id;

      const response = await ActionsFactory.getAccountActions().linkLocation(
        accountId,
        locationId,
      );

      if (response.success && response.data === '') {
        await this.linkLocationSuccess(locationId);
      } else {
        throw new Error(Localized.Errors.failed);
      }
    } catch (error) {
      this.context.actions.showModal(
        Localized.Errors.market_card_location_error_description,
        Localized.Errors.market_card_location_error,
        Localized.Buttons.try_again,
        Settings.buildType === 'canteen'
          ? '#003349'
          : Styles.Colors.PayNew.primary01,
        5,
        false,
        true,
        Localized.Labels.skip_for_now,
        null,
        this.props.onSuccess,
        true,
      );
    }
  }

  async linkLocationSuccess(locationId: string) {
    const locationName = this.state.isManuallyLinkingLocation
      ? await ActionsFactory.getAccountActions()
          .getAccountLocation(locationId)
          .then((response) => response.displayName)
      : this.state.locations[this.state.locationSelected].displayName;
    const successMessage = Localized.Success.formatString(
      Localized.Success.market_card_link_success_description,
      locationName,
    );
    this.context.actions.showModal(
      successMessage,
      Localized.Success.market_card_link_success,
      Localized.Buttons.okay,
      Settings.buildType === 'canteen'
        ? '#003349'
        : Styles.Colors.PayNew.primary01,
      4,
      false,
      false,
    );
    NavActions.push(AppRoutes.RegistrationEnterPinScreen, {
      onSuccess: this.props.onSuccess,
      data: {id: this.props.data.id, email: this.props.data.email},
      code: this.props.code,
      locationId: locationId,
    });
  }

  renderStandardLinking() {
    return (
      <View style={styles.selectLocation}>
        <View style={styles.selectLocationContent}>
          {this.state.isLocationPermissionEnabled ? (
            this.state.locations.length > 0 ? (
              <ScrollView style={styles.scrollView}>
                <View style={styles.gridContainer}>
                  {this.state.locations.map((location, index) => (
                    <TouchableOpacity
                      key={index}
                      onPress={() => {
                        this.setState((prevState) => ({
                          locationCode: location.locationId,
                          locationSelected:
                            prevState.locationSelected === index ? null : index,
                        }));
                      }}
                      style={styles.locationContent}
                    >
                      <NearbyLocationViewOnboarding
                        nearbyLocation={
                          {
                            locationId: location.locationId,
                            campusId: undefined,
                            displayName: location.displayName,
                            distance: 0,
                            units: undefined,
                          } as NearbyLocationType
                        }
                        selected={this.state.locationSelected === index}
                      />
                    </TouchableOpacity>
                  ))}
                </View>
              </ScrollView>
            ) : (
              <LocationErrorStatusOnboarding
                locationErrorStatusType={
                  LocationErrorStatusType.NoNearbyLocations
                }
              />
            )
          ) : (
            <LocationErrorStatusOnboarding
              locationErrorStatusType={
                LocationErrorStatusType.LocationPermission
              }
            />
          )}
        </View>
        <View style={styles.buttonsAndLinks}>
          {!LocationHandler.isLocationPermissionEnabled ? (
            <View style={{gap: 16}}>
              {getOnBoardingButton(
                Localized.Buttons.go_to_settings,
                Linking.openSettings,
              )}
              {getOnBoardingButton(
                Localized.Buttons.check_permissions_again,
                this.checkPermissions,
              )}
            </View>
          ) : this.state.locations.length < 1 ? (
            <View>
              {getOnBoardingButton(
                Localized.Buttons.manually_link_your_location,
                () =>
                  this.setState((prevState) => ({
                    isManuallyLinkingLocation:
                      !prevState.isManuallyLinkingLocation,
                  })),
              )}
            </View>
          ) : (
            <View>
              {getOnBoardingButton(
                'Next',
                this.linkLocation,
                this.state.locationSelected === null,
              )}
            </View>
          )}
          {(this.state.locations.length > 0 &&
            LocationHandler.isLocationPermissionEnabled) ||
          !LocationHandler.isLocationPermissionEnabled ? (
            <TouchableOpacity
              onPress={() => {
                this.setState((prevState) => ({
                  locationCode: '',
                  locationSelected: null,
                  isManuallyLinkingLocation:
                    !prevState.isManuallyLinkingLocation,
                }));
              }}
              style={styles.skipLinkContainer}
            >
              <AVText style={actionLinkText()['actionLinkText']}>
                {Localized.Buttons.manually_link_your_location}
              </AVText>
            </TouchableOpacity>
          ) : null}
        </View>
      </View>
    );
  }

  renderManuallyLinking() {
    return (
      <View style={styles.manuallyLinkLocation}>
        <ScrollView
          style={styles.scrollView}
          keyboardDismissMode="interactive"
          automaticallyAdjustContentInsets={false}
          keyboardShouldPersistTaps="handled"
        >
          <View style={{marginHorizontal: Styles.Spacing.m3}}>
            <AllyTextInput
              label={Localized.Labels.location_code}
              value={this.state.locationCode}
              accessible={true}
              accessibilityLabel={Localized.Labels.market_card}
              accessibilityValue={{text: this.state.locationCode}}
              onChangeText={(text) => this.setLocationId(text)}
              autoCapitalize="none"
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm11}
              style={styles.input}
            />
          </View>
          <AVText style={[getBodyTextStyles()['supportingText']]}>
            {Localized.Labels.manually_link_location_description}
          </AVText>
        </ScrollView>
        <View>
          {getOnBoardingButton(
            'Save',
            this.linkLocation,
            this.state.locationCode === '',
          )}
        </View>
      </View>
    );
  }

  onBackSelect = () => {
    if (this.state.isManuallyLinkingLocation) {
      this.setState({isManuallyLinkingLocation: false});
      return;
    } else {
      NavActions.pop();
    }
  };

  render() {
    return (
      <BaseAccountSetupScreenRedesign
        headlineText={
          this.state.isManuallyLinkingLocation
            ? Localized.Labels.link_location
            : Localized.Labels.select_location
        }
        instructionText={
          this.state.isManuallyLinkingLocation
            ? Localized.Labels.link_location_description
            : Localized.Labels.select_location_description
        }
        onBackSelect={this.onBackSelect}
        pop={false}
      >
        {this.state.isManuallyLinkingLocation
          ? this.renderManuallyLinking()
          : this.renderStandardLinking()}
      </BaseAccountSetupScreenRedesign>
    );
  }
}

const styles = StyleSheet.create({
  locationContent: {
    width: '42%',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 121,
    maxHeight: 100,
    marginLeft: Styles.Spacing.m4,
    marginTop: 24,
    elevation: 2,
  },
  selectLocation: {
    display: 'flex',
    flexDirection: 'column',
    width: 345,
    alignItems: 'center',
    gap: 40,
    flex: 1,
  },
  manuallyLinkLocation: {
    display: 'flex',
    flexDirection: 'column',
    width: 345,
    alignItems: 'center',
    gap: 16,
  },
  spacing: {
    marginBottom: Styles.Spacing.m3,
  },
  selectLocationContent: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    gap: 15,
    alignSelf: 'stretch',
  },
  buttonsAndLinks: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    alignSelf: 'stretch',
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: Styles.Spacing.m2,
  },
  skipLinkContainer: {
    alignItems: 'center',
    marginTop: 16,
    marginBottom: 24,
  },
  skipLinkText: {
    color: '#007AFF',
    fontSize: 16,
    fontWeight: '600',
  },
  input: {
    alignSelf: 'stretch',
    backgroundColor: Styles.bgColor,
    borderColor: Styles.white,
    borderRadius: 8,
    borderWidth: 1,
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    height: Styles.Heights.h4,
    paddingHorizontal: Styles.Spacing.m3,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {width: 0, height: 2},
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  inputsContainer: {
    flexDirection: 'column',
    marginBottom: Styles.Spacing.m5,
    marginHorizontal: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m2,
  },
  negativeBtn: {
    borderColor: Styles.white,
    borderWidth: 1,
    height: Styles.Heights.h5,
    marginVertical: Styles.Spacing.m2,
  },
  override: {
    height: Styles.Heights.h5,
    marginTop: Styles.Spacing.m2,
  },
  scrollView: {
    alignSelf: 'stretch',
    maxHeight: 400,
    marginBottom: Styles.Spacing.m2,
  },
  nextButtonText: {
    paddingHorizontal: Styles.Spacing.m2,
  },
});
export default withForwardedNavigationParams<SelectMarketCardLocationProps>()(
  SelectMarketCardLocationScreen,
);
