import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

function TagLightIcon(props) {
  return (
    <Svg width={19} height={23} viewBox="0 0 19 23" fill="none" {...props}>
      <Path
        d="M1.31 10.361V3.937c0-.378.294-.687.654-.687h6.118c.348 0 .68.146.925.404l7.202 7.562c.512.537.512 1.41 0 1.947l-5.463 5.732a1.268 1.268 0 01-1.854 0L1.69 11.332a1.408 1.408 0 01-.385-.971h.005zm-1.31 0c0 .73.274 1.43.765 1.946l7.203 7.563a2.53 2.53 0 003.703 0l5.463-5.736c1.023-1.075 1.023-2.815 0-3.89L9.932 2.683a2.558 2.558 0 00-1.854-.803H1.964C.88 1.875 0 2.799 0 3.937v6.424zm4.583-2.643c.26 0 .51-.108.695-.302.184-.193.287-.455.287-.729 0-.273-.103-.536-.287-.73a.959.959 0 00-.695-.301c-.26 0-.51.109-.694.302a1.058 1.058 0 00-.288.73c0 .273.104.535.288.728a.96.96 0 00.694.302z"
        fill="#138665"
      />
    </Svg>
  );
}

export default TagLightIcon;
