import RevolveDescriptor from './common-elements-revolve';
import RedesignDescriptor from './common-elements-redesign';
import connectDescriptor from './common-elements-canteen';
import BuildTypes from 'src/constants/BuildTypeConstants';
import Settings from 'src/Settings';
import {ButtonType} from 'src/components/elements/RoundedButton';
import {GiftItem} from 'src/types/Snack';
// import DealModel from 'src/models/Moblico/Deal';
import {StyleProp, ViewStyle, TextStyle} from 'react-native';
import {Referral} from 'src/types/Referral';
const typeDescriber = {
  [BuildTypes.revolve]: RevolveDescriptor,
  [BuildTypes.canteen]: connectDescriptor,
  [BuildTypes.default]: RedesignDescriptor,
};

export function getDescriber() {
  return typeDescriber[Settings.buildType as keyof typeof typeDescriber];
}

export interface RoundedBtnDescriberProps {
  disabled?: boolean;
  color?: string;
  buttonType: ButtonType;
}

export interface SnackItemDescriberProps {
  snack: GiftItem;
}

export interface ReferralItemDescriberProps {
  referral: Referral;
  isSender: boolean;
}

export interface DealProps {
  expired?: boolean;
  styles?: {[index: string]: {[index: string]: number | string}};
  deal?: GiftItem;
}

export interface PasswordInpProps {
  maxLength?: number;
}
export interface CollapsibleComponentProps {
  toValue: number;
  toDegree: string;
  content: StyleProp<ViewStyle>;
  parentContainer: StyleProp<ViewStyle>;
}
export interface StylesDescriptor {
  container: StyleProp<ViewStyle>;
  paymentDropDownInputContainer: StyleProp<ViewStyle>;
  paymentInput: StyleProp<TextStyle>;
  input: StyleProp<TextStyle>;
  imageContainer: StyleProp<ViewStyle>;
  labelContainer: StyleProp<ViewStyle>;
  label: StyleProp<TextStyle>;
  error: StyleProp<TextStyle>;
  leftIcon: StyleProp<ViewStyle>;
  help: StyleProp<TextStyle>;
  shadow?: StyleProp<ViewStyle>;
}

export interface NumberSpinnerStylesDescriptor {
  num: StyleProp<ViewStyle>;
  numText: StyleProp<TextStyle>;
}

export interface CommonUtilsSwitchProps {
  switchStatus?: boolean;
  title?: string;
  onSwitchToggle: () => void;
}

export interface RewardsSection {
  styles?: {[index: string]: {[index: string]: number | string}};
}
