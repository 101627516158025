import * as React from 'react';
import DashedLine from 'react-native-dashed-line';
import Util from 'src/Util';
import Styles from '../../../Styles';
import AVFormattedCurrency from '../../AVFormattedCurrency';
import type {Funding} from 'src/types/TransactionDetail';
import {PixelRatio, StyleSheet, View, ScrollView} from 'react-native';
import AVText from '../../AVText';
import AccountStore from 'src/stores/AccountStore';
import {WithLocalizedProps} from 'src/types/PropTypes';
import RoundedButton, {ButtonType} from 'src/components/elements/RoundedButton';
import Localized from 'src/constants/AppStrings';

import {getDescriber} from '../descriptor/DescriptorType';
import Settings from 'src/Settings';
type FundingDetailsProps = WithLocalizedProps & {
  fundDetail: Funding;
  currency?: string;
  handleEmailReceipt: (transId: string) => void;
};

const FundingDetails = (props: FundingDetailsProps) => {
  const fundingFormattedDate = Util.formatDate(
    props.fundDetail.DateString,
    getDescriber().PurchaseDetailDateFormatStyleDescriptor(),
  );
  const fundingCurrency = props.currency ?? AccountStore.getCurrency();

  return (
    <View style={styles.container}>
      {props.fundDetail && props.fundDetail?.Id ? (
        <>
          <ScrollView style={styles.fundingContainer}>
            <AVText
              style={getDescriber().PurchaseDetailFormateedDateStyleDescriptor()}
              accessible={true}
              accessibilityLabel={`${props.strings.Labels.date} ${fundingFormattedDate}`}
              aria-label={`${props.strings.Labels.date} ${fundingFormattedDate}`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            >
              {fundingFormattedDate}
            </AVText>
            <AVText
              accessible={true}
              style={getDescriber().PurchaseDetailIdStyleDescriptor()}
              accessibilityLabel={`${props.strings.Labels.transaction_id}`}
              aria-label={`${props.strings.Labels.transaction_id}`}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
            >
              {props.fundDetail.Id}
            </AVText>
            <DashedLine
              dashColor={Styles.inActiveDotColor}
              dashLength={PixelRatio.roundToNearestPixel(5)}
              style={styles.dashStyle}
              dashGap={PixelRatio.roundToNearestPixel(
                Settings.isRevolve() ? 1 : 4,
              )}
              dashThickness={PixelRatio.roundToNearestPixel(1)}
            />
            <View style={[styles.row]}>
              <AVText
                accessible={true}
                accessibilityLabel={props.strings.Labels.account_funding}
                aria-label={props.strings.Labels.account_funding}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                style={styles.largeLabel}
              >
                {props.strings.Labels.account_funding}
              </AVText>
              <AVFormattedCurrency
                value={props.fundDetail.Amount}
                currency={fundingCurrency}
                accessibilityLabel={`$${props.fundDetail.Amount}`}
                aria-label={`$${props.fundDetail.Amount}`}
                style={[styles.largeLabel, {paddingRight: Styles.Spacing.m3}]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              />
            </View>

            <DashedLine
              dashThickness={PixelRatio.roundToNearestPixel(1)}
              dashGap={PixelRatio.roundToNearestPixel(
                Settings.isRevolve() ? 1 : 4,
              )}
              style={styles.dashStyle}
              dashLength={PixelRatio.roundToNearestPixel(5)}
              dashColor={Styles.inActiveDotColor}
            />
            <View
              accessible={true}
              style={[Styles.Style.revolveRow, {marginTop: Styles.Spacing.m2}]}
            >
              <AVText
                style={[styles.total]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessibilityLabel={props.strings.Labels.total}
                aria-label={props.strings.Labels.total}
              >
                {props.strings.Labels.total}
              </AVText>

              <AVFormattedCurrency
                value={props.fundDetail.Amount}
                accessibilityLabel={`$${props.fundDetail.Amount}`}
                aria-label={`$${props.fundDetail.Amount}`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                currency={fundingCurrency}
                style={[styles.total, styles.marginStyle]}
              />
            </View>
            <View
              accessible={true}
              style={[Styles.Style.revolveRow, {marginTop: Styles.Spacing.m1}]}
            >
              <AVText
                accessibilityLabel={props.strings.Labels.total}
                aria-label={props.strings.Labels.total}
                style={[styles.creditText]}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              >
                {props.strings.Labels.credit}
              </AVText>

              <AVFormattedCurrency
                style={[styles.creditText, styles.marginStyle]}
                value={-1 * props.fundDetail.Amount}
                aria-label={`$${props.fundDetail.Amount}`}
                accessibilityLabel={`$${props.fundDetail.Amount}`}
                currency={fundingCurrency}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              />
            </View>
            <View
              accessible={true}
              style={[Styles.Style.revolveRow, {marginTop: Styles.Spacing.m1}]}
            >
              <AVText
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
                accessibilityLabel={props.strings.Labels.total}
                aria-label={props.strings.Labels.total}
                style={[styles.creditText]}
              >
                {props.strings.Labels.new_balance}
              </AVText>

              <AVFormattedCurrency
                currency={fundingCurrency}
                style={[styles.creditText, styles.marginStyle]}
                value={props.fundDetail.Balance}
                accessibilityLabel={`$${props.fundDetail.Balance}`}
                aria-label={`$${props.fundDetail.Balance}`}
                maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              />
            </View>
          </ScrollView>
          {AccountStore.getRegion() === 'USA' &&
          !Util.isDatePastNinetyDays(props.fundDetail.DateString) ? (
            <RoundedButton
              buttonType={ButtonType.action}
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm3}
              accessible={true}
              accessibilityLabel={Localized.Buttons.email_receipts}
              accessibilityRole="button"
              aria-label={Localized.Buttons.email_receipts}
              role="button"
              onPress={() => props.handleEmailReceipt(props.fundDetail.Id)}
              text={Localized.Buttons.email_receipts}
              color={Styles.darkGreenRevolve}
              textStyle={[
                styles.buttonTextStyle,
                Settings.isRevolve() && {textTransform: 'uppercase'},
              ]}
              containerStyle={styles.buttonContainerStyle}
            />
          ) : null}
        </>
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dashStyle: {
    borderRadius: 100,
    height: PixelRatio.roundToNearestPixel(2),
    overflow: 'hidden',
    marginRight: Styles.Spacing.m3,
  },
  date: {
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f8,
    color: Styles.darkColor,
    fontWeight: '400',
  },
  disabledRefundButton: {
    opacity: 0.5,
  },
  fundingContainer: {
    flex: 1,
    marginTop: Styles.Heights.h2 + 4,
    marginBottom: Styles.Spacing.m5,
    marginLeft: Styles.Spacing.m3,
  },
  largeLabel: {
    fontSize: Styles.Fonts.f7,
    fontFamily: Styles.FontFamily.figtreeRegular,
    color: Styles.darkColor,
    fontWeight: '400',
  },
  row: {
    flexDirection: 'row',
    marginTop: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m2,
    justifyContent: 'space-between',
  },
  total: {
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    color: Styles.darkColor,
    fontSize: Settings.isRevolve() ? Styles.Fonts.f9 + 2 : Styles.Fonts.f9,
    fontWeight: '700',
    marginBottom: Styles.Spacing.m1,
    marginTop: Styles.Spacing.m2,
  },
  transId: {
    color: Styles.lightGray,
    fontFamily: Styles.FontFamily.figtreeRegular,
    fontSize: Styles.Fonts.f0,
    marginTop: Styles.Spacing.m2,
    marginBottom: Styles.Spacing.m3,
    marginRight: Styles.Spacing.m3,
  },
  creditText: {
    color: Settings.isRevolve() ? Styles.darkColor : Styles.lightGray,
    fontSize: Styles.Fonts.f7,
    fontWeight: '400',
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
  },
  totalView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  marginStyle: {
    paddingHorizontal: Styles.Spacing.m3,
  },
  buttonTextStyle: {
    fontSize: Settings.isRevolve() ? Styles.Fonts.f8 + 4 : Styles.Fonts.f8,
    fontFamily: Settings.isRevolve()
      ? Styles.FontFamily.figtreeRegular
      : Styles.FontFamily.robotoRegular,
    fontWeight: '700',
  },
  buttonContainerStyle: {
    marginBottom: Styles.Spacing.m2,
  },
});
export default FundingDetails;
