import React, {useContext, useState, useEffect, useRef} from 'react';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import {StyleSheet, ScrollView, View, Platform, Linking} from 'react-native';
import MainConsumerContext from '../../../MainConsumerContext';
import AppRoutes from 'src/AppRoutes';
import Events from 'src/logging/Events';
import {generateErrorMessage} from 'src/logging/generateErrorMessage';
import PassKit from 'src/nativeModules/PassKit';
import SettingsMenuOption from '../../../elements/newui/SettingsMenuOption';
import SettingsMenuSection from '../../../elements/newui/SettingsMenuSection';
import Styles from '../../../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import AVText from '../../../elements/AVText';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError} from '../../../helpers/AlertHelper';
import {connect} from 'react-redux';
import {RootState} from 'src/redux/store';
import {PaymentCredentials} from 'src/models/PaymentCredentials';
import uuid from '../../../../nativeModules/UUID';
import UserProfileInitials from 'src/components/elements/UserProfileInitials';
import CommonUtils from './commonutils';
import FirebaseAnalytic from 'src/nativeModules/FirebaseAnalytic';
import Settings from 'src/Settings';
import {authStore} from 'src/init';
import {getDescriber} from '../descriptor/DescriptorType';
import BaseScreen from 'src/components/screens/BaseScreen';
import CrashlyticsEvents from 'src/logging/Crashlytics';
import RoundedAppButton, {
  RoundedAppButtonType,
} from 'src/components/elements/RoundedAppButton';
import {useGlobalize} from 'react-native-globalize';

const AccountScreenNewUI: React.FC<AccountScreenNewUIProps> = (props) => {
  const context = useContext(MainConsumerContext);
  const [email, setEmail] = useState(AccountStore.getEmail());

  const {formatCurrency} = useGlobalize();

  const [name, setName] = useState(AccountStore.getFullName());
  const [fullVersion, setFullVersion] = useState('');

  const [locationName, setLocationName] = useState(
    AccountStore.getLocationName(),
  );
  const [sendSnackStatus, setSendSnackStatus] = useState(
    AccountStore.isConsumerSendSnackEnabled(),
  );
  const [isSSO, setIsSSO] = useState(false);
  const currentPolicy = useRef('');
  const [optionItems, setOptionItems] = useState<
    Record<
      string,
      Array<{
        accessibilityLabel: string;
        accessibilityHint: string;
        onPressed: () => void;
        label: string;
      }>
    >
  >({});
  const currentPolicyType = useRef('');

  useEffect(() => {
    initComponentDidMount();
    initOptionItems();
  }, []);

  useEffect(() => {
    if (props.emailChanged && Settings.is365Pay()) {
      onRefresh();
      context.actions.showModal(
        Localized.Success.your_new_email_has_been_updated_in_the_app,
        Localized.Success.success,
        Localized.Buttons.okay,
        Styles.Colors.PayNew.primary01,
        2,
      );
    }
  }, [props.emailChanged]);

  const onRefresh = async () => {
    FirebaseAnalytic.trackEvent('onRefresh', 'AccountScreenNewUI', {
      ...props,
    });
    try {
      await ActionsFactory.getAccountActions().reloadConsumerData({
        accountId: AccountStore.getAccountId(),
        accountBalanceId: AccountStore.getAccountBalanceId(),
        email: AccountStore.getEmail(),
        userInitiated: true,
      });
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreenNewUI:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreenNewUI:onRefresh',
        generateErrorMessage(error),
        guid,
        {
          ...props,
        },
      );
    }
  };

  const addPassClicked = async () => {
    context?.actions.showSpinner();
    try {
      const success = await PassKit.addPass(context?.state.passUrl ?? '');
      if (success) {
        const passExists = await PassKit.doesPassExist(
          context?.state.passUrl ?? '',
        );

        if (passExists) {
          if (context?.actions.passAdded) {
            context?.actions.passAdded();
          }

          Events.Pass.trackEvent();
        }
      } else {
        alertError(Localized.Errors.failed_to_add_pass);
      }
    } finally {
      context?.actions.hideSpinner();
    }
  };

  const onAccountChange = () => {
    let response;
    try {
      response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        currentPolicyType.current = response.type;
        currentPolicy.current = response.version;
      }
      setName(AccountStore.getFullName());
      setEmail(AccountStore.getEmail());
      setLocationName(AccountStore.getLocationName());
      setSendSnackStatus(AccountStore.isConsumerSendSnackEnabled());
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreen:onAccountChange',
        generateErrorMessage(error),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreen:onAccountChange',
        generateErrorMessage(error),
      );
    }
  };

  useEffect(() => {
    const fetchIsSSO = async () => {
      try {
        const isSSOValue = await authStore.getIsSSO();
        setIsSSO(isSSOValue);
      } catch (error) {
        CrashlyticsEvents.log(
          'Exception',
          'AccountScreen:fetchIsSSO',
          generateErrorMessage(error),
        );
        Events.Error.trackEvent(
          'Exception',
          'AccountScreen:fetchIsSSO',
          generateErrorMessage(error),
        );
      }
    };
    fetchIsSSO();
  });

  const handlePrivacyPolicyUrl = () => {
    CommonUtils.handlePrivacyPolicy(
      currentPolicy.current,
      currentPolicyType.current,
    );
  };

  const {
    getSettingsOptions,
    getHistoryOptions,
    getHelpAndOtherOptions,
    getSecurityOptions,
    getPaymentOptions,
    getHelpAndOtherStr,
    getParentContainerStyle,
    showIcon,
    getUserProfileTextStyleDescriptors,
    getGiftingOptions,
    getLinkAccountsOptions,
    getBrandTitle,
    getBrandWebPage,
    getSignOutButton,
  } = getDescriber();

  const brandWebPage = getBrandWebPage();
  const brandTitle = getBrandTitle();

  const initComponentDidMount = async () => {
    FirebaseAnalytic.trackEvent('componentDidMount', 'AccountScreen', {
      ...props,
    });

    const version = await Settings.getFullDisplayVersion(Platform.OS);
    setFullVersion(version);
    try {
      const response = AccountStore.getPrivacyVersionResponse();

      if (response && response.status === 'ok') {
        currentPolicy.current = response.version;
        currentPolicyType.current = response.type;
      }

      AccountStore.addChangeListener(onAccountChange);
    } catch (error) {
      const guid = await uuid.getRandomUUID();
      CrashlyticsEvents.log(
        'Exception',
        'AccountScreenNewUI:componentDidMount',
        generateErrorMessage(error),
        guid,
      );
      Events.Error.trackEvent(
        'Exception',
        'AccountScreenNewUI:componentDidMount',
        generateErrorMessage(error),
        guid,
      );
    }
    setIsSSO(false);
  };

  const openWebPage = () => {
    Linking.openURL(brandWebPage);
  };

  const initOptionItems = () => {
    const referralAmount = formatCurrency(
      AccountStore.getReferralCampaign()?.amount ?? 0,
      AccountStore.getCurrency(),
    );
    const giftingOptions = getGiftingOptions(referralAmount) as {
      accessibilityLabel: string;
      accessibilityHint: string;
      onPressed: () => void;
      label: string;
    }[];

    const settings = getSettingsOptions(
      handlePrivacyPolicyUrl,
      context,
      addPassClicked,
    );
    const historyOptions = getHistoryOptions() || [];
    const paymentOptions = getPaymentOptions(context, addPassClicked) || [];
    const helpAndOther = getHelpAndOtherOptions(handlePrivacyPolicyUrl);
    const security = getSecurityOptions() || [];
    const linkAccountsOptions = getLinkAccountsOptions() || [];
    const allOptions = {
      giftingOptions,
      historyOptions,
      paymentOptions,
      settings,
      security,
      helpAndOther,
      linkAccountsOptions,
    };
    setOptionItems(allOptions);
  };

  const onLogoutSelect = async () => {
    context?.actions.showSpinner();
    Events.Logout.trackEvent();
    await ActionsFactory.getAccountActions().logout();
    context?.actions.hideSpinner();
    context?.actions.changeRootRoute(AppRoutes.Login);
  };

  return (
    <BaseScreen
      title={Localized.Labels.account}
      accessibilityLabel={Localized.Labels.account}
      hideBack={true}
    >
      <ScrollView style={getParentContainerStyle()}>
        <View style={styles.container}>
          <UserProfileInitials
            accessibilityLabel={`Name Initials: ${name}, text`}
            accessibilityRole="text"
            aria-label={`Initials Name: ${name}, text`}
            text={name}
          ></UserProfileInitials>
          <View style={styles.content}>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              accessible={true}
              accessibilityLabel={`Full Name: ${name}, text`}
              accessibilityRole="text"
              ellipsizeMode="tail"
              numberOfLines={1}
              aria-label={`Full Name: ${name}, text`}
              style={[
                styles.nameText,
                getUserProfileTextStyleDescriptors()['name'],
              ]}
            >
              {name}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm13}
              accessible={true}
              accessibilityLabel={`Email: ${email}`}
              accessibilityRole="text"
              numberOfLines={1}
              aria-label={`Email: ${email}, text`}
              style={[
                styles.emailText,
                getUserProfileTextStyleDescriptors()['emailLocationText'],
              ]}
            >
              {email}
            </AVText>
            <AVText
              maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm2}
              accessible={true}
              accessibilityLabel={`Market Location: ${locationName}`}
              accessibilityRole="text"
              numberOfLines={1}
              aria-label={`Market Location: ${locationName}, text`}
              style={[
                styles.locationText,
                getUserProfileTextStyleDescriptors()['emailLocationText'],
                getUserProfileTextStyleDescriptors()['locationView'],
              ]}
            >
              {locationName}
            </AVText>
          </View>
        </View>

        {optionItems['giftingOptions'] &&
          optionItems['giftingOptions'].length > 0 && (
            <SettingsMenuSection
              title={
                Settings.isRevolve()
                  ? Localized.Labels.gift_title.toUpperCase()
                  : Localized.Labels.gift_title
              }
            >
              <SettingsMenuOption
                listItems={optionItems['giftingOptions']}
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {Settings.is365Pay() &&
          optionItems['paymentOptions'] &&
          optionItems['paymentOptions'].length > 0 && (
            <>
              <SettingsMenuSection
                title={Localized.Labels.payment_account_section}
              >
                <SettingsMenuOption
                  listItems={optionItems['paymentOptions']}
                ></SettingsMenuOption>
              </SettingsMenuSection>
              {!context?.state.passExists && context?.state.canAddPass && (
                <RoundedAppButton
                  buttonViewStyle={{
                    borderWidth: 2,
                    backgroundColor: Styles.white,
                  }}
                  buttonContainerStyle={{
                    marginLeft: 16,
                    marginTop: 20,
                  }}
                  textStyle={styles.roundButtonText}
                  titleText={Localized.Buttons.add_to_phone_wallet}
                  accessibilityLabelValue={
                    Localized.Buttons.add_to_phone_wallet
                  }
                  buttonType={RoundedAppButtonType.Outline}
                  onPress={() => addPassClicked()}
                />
              )}
            </>
          )}

        {Settings.isRevolve() ||
          (Settings.is365Pay() &&
            optionItems['historyOptions'] &&
            optionItems['historyOptions'].length > 0 && (
              <SettingsMenuSection
                title={
                  Settings.isRevolve()
                    ? Localized.Labels.history.toUpperCase()
                    : Localized.Labels.transaction_history
                }
              >
                <SettingsMenuOption
                  listItems={optionItems['historyOptions']}
                ></SettingsMenuOption>
              </SettingsMenuSection>
            ))}

        {Settings.is365Pay() &&
          optionItems['linkAccountsOptions'] &&
          optionItems['linkAccountsOptions'].length > 0 && (
            <SettingsMenuSection title={Localized.Labels.link_accounts}>
              <SettingsMenuOption
                listItems={optionItems['linkAccountsOptions']}
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {Settings.isRevolve() &&
          optionItems['paymentOptions'] &&
          optionItems['paymentOptions'].length > 0 && (
            <SettingsMenuSection
              title={Localized.Labels.payment_account_section.toUpperCase()}
            >
              <SettingsMenuOption
                listItems={optionItems['paymentOptions']}
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {Settings.is365Pay() &&
          optionItems['helpAndOther'] &&
          optionItems['helpAndOther'].length > 0 && (
            <SettingsMenuSection title={getHelpAndOtherStr()}>
              <SettingsMenuOption
                listItems={
                  isSSO
                    ? optionItems['helpAndOther'].filter(
                        (item) => item.label !== Localized.Labels.change_email,
                      )
                    : optionItems['helpAndOther']
                }
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {!Settings.isRevolve() &&
          optionItems['settings'] &&
          optionItems['settings'].length > 0 && (
            <SettingsMenuSection title={Localized.Labels.settings}>
              <SettingsMenuOption
                listItems={optionItems['settings']}
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {!Settings.isRevolve() &&
          optionItems['security'] &&
          optionItems['security'].length > 0 && (
            <SettingsMenuSection title={Localized.Labels.security}>
              <SettingsMenuOption
                listItems={
                  isSSO
                    ? optionItems['security'].filter(
                        (item) =>
                          item.label !== Localized.Labels.change_password,
                      )
                    : optionItems['security']
                }
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {!Settings.is365Pay() &&
          optionItems['helpAndOther'] &&
          optionItems['helpAndOther'].length > 0 && (
            <SettingsMenuSection title={getHelpAndOtherStr()}>
              <SettingsMenuOption
                listItems={
                  isSSO
                    ? optionItems['helpAndOther'].filter(
                        (item) =>
                          item.label !== Localized.Labels.change_password &&
                          item.label !== Localized.Labels.change_email,
                      )
                    : optionItems['helpAndOther']
                }
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        {Settings.isRevolve() &&
          optionItems['settings'] &&
          optionItems['settings'].length > 0 && (
            <SettingsMenuSection
              title={Localized.Labels.settings.toUpperCase()}
            >
              <SettingsMenuOption
                listItems={optionItems['settings']}
              ></SettingsMenuOption>
            </SettingsMenuSection>
          )}

        <View style={styles.signoutBtn}>
          {getSignOutButton(onLogoutSelect)}
          {showIcon(openWebPage, brandTitle)}
          <AVText
            style={styles.appVersionText}
            accessible={true}
            maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm5}
            accessibilityLabel={`Application version: ${fullVersion}`}
            accessibilityRole="text"
            aria-label={`Application version: ${fullVersion}, text`}
          >
            {fullVersion}
          </AVText>
        </View>
      </ScrollView>
    </BaseScreen>
  );
};

// Set default props if necessary
AccountScreenNewUI.defaultProps = {
  passExists: false,
  canAddPass: false,
  rewards: 0,
  passUrl: '',
};

type AccountScreenNewUIProps = Record<string, unknown> & {
  paymentCredentials?: PaymentCredentials;
  emailChanged?: boolean;
};

const styles = StyleSheet.create({
  notificationLogo: {
    right: Styles.Spacing.m2,
    width: Styles.Heights.touchTargetHeight1,
    height: Styles.Heights.touchTargetHeight1,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: Styles.Heights.h1 - 5,
  },
  container: {
    flexDirection: 'row',
    marginHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  content: {
    paddingLeft: 22,
    flex: 1,
  },
  signoutBtn: {
    paddingHorizontal: Styles.Spacing.m3,
    marginTop: Styles.Spacing.m3,
    marginBottom: Styles.Spacing.m3,
  },
  emailText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f8,
    paddingTop: 6,
    fontWeight: '400',
  },
  locationText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f8,
    fontWeight: '400',
    paddingTop: 6,
  },
  nameText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    fontWeight: '600',
    marginTop: Styles.Spacing.m1,
  },
  appVersionText: {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f1,
    marginBottom: Styles.Spacing.m2,
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  revolveMarketSingleLineLogo: {
    marginTop: Styles.Spacing.m3 + Styles.Spacing.m2,
  },
  fiveStarLink: {
    marginTop: Styles.Spacing.m1,
    fontSize: Styles.Fonts.f7,
    textDecorationLine: 'underline',
    color: '#2C6CD3',
    fontWeight: '600',
  },
  roundButtonText: {
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontWeight: '700',
    fontSize: Styles.Fonts.f1,
  },
});
const ConnectedAccount = connect((state: RootState) => ({
  paymentCredentials: state.account.paymentCredentials,
}))(AccountScreenNewUI);
export default withForwardedNavigationParams<AccountScreenNewUIProps>()(
  ConnectedAccount,
);
