import React from 'react';
import {View, FlatList} from 'react-native';
import Localized from 'src/constants/AppStrings';
import CampusLocationView, {
  CampusLocationType,
  CampusLocationCategory,
} from 'src/components/elements/home/CampusLocationView';
import {IsConnectedProps} from 'src/types/Screen';
import Settings from 'src/Settings';
import OrderAheadView from 'src/components/elements/home/OrderAheadView';
import {getDescriber} from '../home/descriptor/DescriptorType';
import HomeScreenTitles from 'src/components/elements/home/HomeScreenTitles';

type CampusLocationsListProps = IsConnectedProps & {
  locations: CampusLocationType[];
  categoryType: CampusLocationCategory;
  onLocationClick: (
    machine: CampusLocationType,
    autoAdvanced?: boolean,
  ) => Promise<void>;
};

const {getCampusLocationListStyles} = getDescriber();

const CampusLocationsList = (props: CampusLocationsListProps) => {
  //Returns Campus Location Item UI
  const renderItem = ({item}: {item: CampusLocationType}) => {
    return (
      <>
        {Settings.buildType === 'default' ? (
          <OrderAheadView
            location={item}
            onLocationClick={props.onLocationClick}
            isNotScrollable={props.locations.length === 1}
          />
        ) : (
          <CampusLocationView
            location={item}
            locationCategory={props.categoryType}
            onLocationClick={props.onLocationClick}
          />
        )}
      </>
    );
  };

  //Returns location category based on type
  const headerTitle = () => {
    switch (props.categoryType) {
      case CampusLocationCategory.Dining:
        return Localized.Labels.dining;
      case CampusLocationCategory.Vending:
        return Localized.Labels.vending;
    }
  };

  return (
    <View style={getCampusLocationListStyles().container}>
      <HomeScreenTitles
        label={headerTitle()}
        isConnected
        style={getCampusLocationListStyles().headerMargin}
      />
      <FlatList
        renderItem={renderItem}
        scrollEnabled={false}
        style={getCampusLocationListStyles().campusLocationListView}
        data={props.locations}
        keyExtractor={(item, index) =>
          props.categoryType + item?.locationId + index
        }
        horizontal={Settings.buildType === 'default'}
      />
    </View>
  );
};
export default CampusLocationsList;
