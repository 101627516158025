import * as React from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  Text,
  Platform,
  PixelRatio,
  TouchableOpacity,
} from 'react-native';
import {withForwardedNavigationParams} from 'react-navigation-props-mapper';
import ScreenContext from '../ScreenContext';
import BackSubheader from '../elements/BackSubheader';
import KeyboardAvoidingView from '../elements/365KeyboardAvoidingView';
import RoundedButton, {ButtonType} from '../elements/RoundedButton';
import Styles from '../Styles';
import ActionsFactory from 'src/actions/ActionsFactory';
import NavActions from 'src/actions/NavActions';
import AccountStore from 'src/stores/AccountStore';
import Localized from 'src/constants/AppStrings';
import {alertError, confirm} from '../helpers/AlertHelper';
import {NavigationProp} from '@react-navigation/native';
import {getPreviousRouteName} from 'src/Util';
import FirebaseAnalytic from '../../nativeModules/FirebaseAnalytic';
import {
  fetchRecentCampusesAndLocations,
  swicthCurrentCampusesAndLocations,
} from 'src/redux/slices/campusLocationSlice';
import {connect} from 'react-redux';
import withIsConnected from 'src/components/hoc/withIsConnected';
import {AppDispatch, RootState} from 'src/redux/store';
import AppRoutes from 'src/AppRoutes';
import Settings from 'src/Settings';
import Logger from 'src/logging/Logger';
import AVText from 'src/components/elements/AVText';
import {getDescriber} from './descriptor/DescriptorType';
import CustomTextInput from 'src/components/elements/CustomTextInput';

const LOCATION_CODE_MAX_LENGTH = 12;
const LOCATION_CODE_MIN_LENGTH = 1;

type LocationCodeScreenProps = {
  navigation: NavigationProp<LocationCodeScreen>;
  dispatch: AppDispatch;
  accountId: string;
  isFromHome: boolean;
};

type LocationCodeScreenState = {
  locationCode: string;
  previousRoute: string | null;
};
const {locationCodebgColor} = getDescriber();

class LocationCodeScreen extends React.Component<
  LocationCodeScreenProps,
  LocationCodeScreenState
> {
  static contextType = ScreenContext;
  declare context: React.ContextType<typeof ScreenContext>;

  constructor(props: LocationCodeScreenProps) {
    super(props);
    this.state = {
      locationCode: '',
      previousRoute: null,
    };
    this.saveClick = this.saveClick.bind(this);
    this.updateSelectedLocationOrCampus =
      this.updateSelectedLocationOrCampus.bind(this);
    this.isLocationCodeValid = this.isLocationCodeValid.bind(this);
  }

  componentDidMount(): void {
    FirebaseAnalytic.trackEvent('componentDidMount', 'LocationCodeScreen', {
      ...this.props,
      ...this.state,
    });
    const previousRoute = getPreviousRouteName(
      this.props.navigation.getState().routes,
    );
    this.setState({previousRoute});
  }

  async updateSelectedLocationOrCampus(locationId: string) {
    if (Platform.OS === 'web') {
      NavActions.pop();
    } else {
      await this.props.dispatch(
        swicthCurrentCampusesAndLocations({
          accountId: this.props.accountId,
          locationId,
        }),
      );
      await this.props.dispatch(
        fetchRecentCampusesAndLocations(this.props.accountId),
      );
      this.props.isFromHome
        ? NavActions.popToTop()
        : NavActions.navigate(
            Settings.isRevolve() ? AppRoutes.RevolveHome : AppRoutes.HomeTab,
          );
    }
  }

  async saveClick() {
    FirebaseAnalytic.trackEvent('saveClick', 'LocationCodeScreen', {
      ...this.props,
      ...this.state,
    });
    if (
      this.state.locationCode &&
      this.state.locationCode.length >= LOCATION_CODE_MIN_LENGTH
    ) {
      const homeLocation = AccountStore.getLocationId();

      // this is a working around to get the subsidy balance into a new account without the API changes.
      if (!homeLocation) {
        const res =
          await ActionsFactory.getAccountActions().getLocationByBeacon(
            this.state.locationCode,
            AccountStore.getAccountId(),
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'GetLocationByBeacon',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            locationCode: this.state.locationCode,
          }),
          JSON.stringify(res),
        );
        const deviceLookUpResponse =
          await ActionsFactory.getAccountActions().deviceLookUp(
            res.locationId,
            AccountStore.getAccountId(),
          );
        Logger.Log.LogAPIEvent(
          'AccountAPI',
          'DeviceLookUp',
          JSON.stringify({
            accountId: AccountStore.getAccountId(),
            locationId: res.locationId,
          }),
          JSON.stringify(deviceLookUpResponse),
        );
      }

      const response = await ActionsFactory.getAccountActions().getLocationV1(
        this.state.locationCode,
      );
      const location = response;

      FirebaseAnalytic.trackEvent(
        'saveClick getLocationV1',
        'LocationCodeScreen',
        {
          ...this.props,
          ...this.state,
          response,
          homeLocation,
        },
      );

      if (!location || !location?.data?.name) {
        alertError(
          Localized.Errors.dont_know_location_code,
          null,
          undefined,
          Localized.Errors.invalid_location_code,
        );
      } else if (location?.data.hasOrderAhead) {
        confirm(
          this.getLocationAddressDisplay(location),
          async () => {
            if (!homeLocation) {
              // Make the location the user's home location
              const resp =
                await ActionsFactory.getAccountActions().getLocationV1(
                  this.state.locationCode,
                  AccountStore.getAccountId(),
                );
              if (resp && resp.status === 'ok') {
                await ActionsFactory.getAccountActions().getLocation(
                  resp.data.id,
                );
              }
            }

            ActionsFactory.getAccountActions().addLocationLink(
              AccountStore.getAccountId(),
              location.data.id,
            );
            this.updateSelectedLocationOrCampus(location?.data?.id);
          },
          undefined,
          location.data.name,
          Localized.Labels.back,
          Localized.Buttons.confirm,
        );
      } else if (!location?.data.hasOrderAhead) {
        if (Settings.isRevolve()) {
          this.context.actions.showSpinner();
          await this.updateSelectedLocationOrCampus(location?.data?.id);
          this.context.actions.hideSpinner();
        } else {
          // alertError(
          //   Localized.Errors.location_has_no_orderAhead,
          //   null,
          //   undefined,
          //   Localized.Errors.sorry,
          // );
          confirm(
            this.getLocationAddressDisplay(location),
            async () => {
              // Make the location the user's home location
              const resp =
                await ActionsFactory.getAccountActions().getLocationV1(
                  this.state.locationCode,
                  AccountStore.getAccountId(),
                );
              if (resp && resp.status === 'ok') {
                await ActionsFactory.getAccountActions().getLocation(
                  resp.data.id,
                );
              }
              this.updateSelectedLocationOrCampus(resp?.data?.id);
            },
            undefined,
            location.data.name,
            Localized.Labels.back,
            Localized.Buttons.confirm,
          );
        }
      } else {
        confirm(
          this.getLocationAddressDisplay(location),
          async () => {
            // Make the location the user's home location
            const resp = await ActionsFactory.getAccountActions().getLocationV1(
              this.state.locationCode,
              AccountStore.getAccountId(),
            );
            if (resp && resp.status === 'ok') {
              await ActionsFactory.getAccountActions().getLocation(
                resp.data.id,
              );
            }
            this.updateSelectedLocationOrCampus(resp?.data?.id);
          },
          undefined,
          location.data.name,
          Localized.Labels.back,
          Localized.Buttons.confirm,
        );
      }
    } else {
      alertError(
        Localized.Errors.dont_know_location_code,
        null,
        undefined,
        Localized.Errors.invalid_location_code,
      );
    }
  }

  getLocationAddressDisplay(location) {
    FirebaseAnalytic.trackEvent(
      'getLocationAddressDisplay',
      'LocationCodeScreen',
      {
        ...this.props,
        ...this.state,
        location,
      },
    );
    if (location.data.city && location.data.state) {
      return `${location.data.city}, ${location.data.state}`;
    } else if (location.data.city) {
      return location.data.city;
    } else if (location.data.state) {
      return location.data.state;
    }

    return '';
  }

  isLocationCodeValid = () => {
    const locationCode = this.state.locationCode.trim();
    if (Settings.buildType !== 'canteen') {
      if (locationCode && locationCode.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    const locationNote =
      Settings.buildType !== 'canteen'
        ? `${Localized.Labels.please_enter_location_code}\n\n${Localized.Labels.if_you_do_not_have_a_code}`
        : Localized.Labels.please_enter_location_code;
    return (
      <BackSubheader
        previousRoute={this.state.previousRoute}
        accessibilityLabel={'Back arrow'}
        accessibilityHint={`Press to navigate back to the ${this.state.previousRoute} screen`}
        title={Localized.Labels.link_new_location}
      >
        <KeyboardAvoidingView behavior="height" insideTab>
          <ScrollView
            style={[
              {
                backgroundColor:
                  Settings.isRevolve() && Styles.tabBarBackgroundColor,
              },
            ]}
          >
            <View
              style={[
                styles.content,
                {
                  backgroundColor:
                    Settings.isRevolveAnd365Pay() && Platform.OS !== 'web'
                      ? Styles.tabBarBackgroundColor
                      : Styles.white,
                },
              ]}
            >
              <Text
                style={styles.headerText}
                accessible={true}
                aria-label="Enter the location code, text"
                accessibilityLabel="Enter the location code"
                maxFontSizeMultiplier={4}
              >
                {Localized.Labels.enter_your_location_code}
              </Text>

              <View style={{width: '100%'}}>
                <CustomTextInput
                  label={Localized.Labels.location_code}
                  value={this.state.locationCode}
                  accessible={true}
                  accessibilityLabel={Localized.Labels.location_code}
                  accessibilityHint={
                    Localized.Labels.please_enter_location_code
                  }
                  onChangeText={(text: string) => {
                    this.setState({
                      locationCode: text,
                    });
                  }}
                  accessibilityValue={{text: this.state.locationCode}}
                  autoCapitalize="none"
                  showHelpText={true}
                  helpText={locationNote}
                  maxLength={LOCATION_CODE_MAX_LENGTH}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm4}
                  helperStyle={
                    Settings.buildType === 'default'
                      ? {
                          fontFamily: Styles.FontFamily.aeonikRegular,
                          fontSize: 14,
                          fontWeight: '400',
                          left: 10,
                        }
                      : null
                  }
                  showShadow={true}
                />
              </View>
            </View>
            {PixelRatio.getFontScale() > 1 && (
              <View style={{height: Styles.Heights.headerHeight}} />
            )}
          </ScrollView>
          {Settings.buildType === 'default' ? (
            <View
              style={{
                alignSelf: 'flex-end',
              }}
            >
              <TouchableOpacity
                style={[
                  styles.saveBtn,
                  !this.isLocationCodeValid()
                    ? {
                        backgroundColor: locationCodebgColor(),
                        borderTopColor: locationCodebgColor(),
                        borderBottomColor: locationCodebgColor(),
                        borderLeftColor: locationCodebgColor(),
                        borderRightColor: locationCodebgColor(),
                      }
                    : {
                        backgroundColor: '#707070',
                        borderTopColor: '#707070',
                        borderBottomColor: '#707070',
                        borderLeftColor: '#707070',
                        borderRightColor: '#707070',
                      },
                ]}
                onPress={this.saveClick}
                role="button"
                testID="save"
                accessible={true}
                accessibilityLabel={Localized.Buttons.save}
                accessibilityRole="button"
                aria-label={Localized.Buttons.save}
                disabled={this.isLocationCodeValid()}
              >
                <AVText
                  style={styles.saveButtonTextdefault}
                  maxFontSizeMultiplier={Styles.FontSizeMultiplier.maxfm6}
                  testID="save"
                >
                  {Localized.Buttons.save}
                </AVText>
              </TouchableOpacity>
            </View>
          ) : (
            <RoundedButton
              disabled={this.isLocationCodeValid()}
              accessible={true}
              accessibilityLabel={Localized.Buttons.save}
              accessibilityRole="button"
              role="button"
              aria-label={Localized.Buttons.save}
              buttonType={ButtonType.action}
              text={Localized.Buttons.save}
              onPress={this.saveClick}
              textStyle={styles.saveButtonText}
              color={Settings.isRevolve() && Styles.darkGreenRevolve}
            />
          )}
        </KeyboardAvoidingView>
      </BackSubheader>
    );
  }
}

const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: !Settings.isNewUI()
      ? Styles.Spacing.m3
      : Styles.Spacing.m3 + Styles.Spacing.m2,
    ...(Platform.OS === 'web' && {
      alignSelf: 'center',
    }),
  },
  headerText: Settings.isRevolve()
    ? {
        marginTop: Styles.Spacing.m4,
        fontSize: Styles.Fonts.sectionHeader,
        fontWeight: '700',
        color: Styles.darkColor,
        fontFamily: Styles.FontFamily.figtreeRegular,
        marginBottom: Styles.Spacing.m1,
      }
    : {
        marginTop: Styles.Spacing.m3,
        fontSize: Styles.Fonts.f2,
        fontWeight: 'bold',
        color: Styles.darkColor,
      },
  scrollView: {
    backgroundColor: Settings.isRevolve() && Styles.tabBarBackgroundColor,
  },
  saveBtn: {
    borderRadius: 32,
    borderWidth: Platform.OS === 'android' ? 1.2 : 1,
    paddingVertical: 10,
    paddingHorizontal: 22,
    bottom: 20,
    left: -20,
    overflow: 'hidden',
  },
  saveButtonTextdefault: {
    fontWeight: Platform.OS === 'android' ? 'bold' : '700',
    fontFamily: Styles.FontFamily.aeonikRegular,
    fontSize: Styles.Fonts.f0 + 4,
    color: '#FFF',
  },

  saveButtonText: {
    fontSize: Styles.Fonts.f7,
    fontWeight: '700',
    fontFamily: Styles.FontFamily.figtreeRegular,
  },
});

const ConnectedLocationCodeScreen = connect(
  (state: RootState) => ({
    accountId: state.account.account.id,
  }),
  (dispatch: AppDispatch) => ({
    dispatch,
  }),
)(LocationCodeScreen);

export default withForwardedNavigationParams()(
  withIsConnected(ConnectedLocationCodeScreen),
);
