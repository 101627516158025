import MenuService from 'src/services/MenuService';
import TransactionAction from 'src/actions/TransactionActions';
import type {MenuProductType, MenuType, TimeSlotType} from 'src/types/Menu';
import PlatformApi from 'src/api/PlatformApi';
import Events from 'src/logging/Events';
import {LocationType} from 'src/types/Location';
import {ProductNutrition} from 'src/types/ProductType';
import Logger from 'src/logging/Logger';
import CrashlyticsEvents from 'src/logging/Crashlytics';

class MenuActions {
  async getAvailableTimeSlots(
    location: LocationType,
    pickupLocationId: string,
  ): Promise<Array<TimeSlotType>> {
    let response;
    try {
      //fetch today's availabel timeslots
      response = await PlatformApi.fetchOrderAheadAvailableTimes(
        MenuService.getPickupDateTime(),
        location.timezone,
        location.locationId,
        pickupLocationId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchOrderAheadAvailableTimes',
        JSON.stringify({
          getPickupDateTime: MenuService.getPickupDateTime(),
          timezone: location.timezone,
          locationId: location.locationId,
          pickupLocationId,
        }),
        JSON.stringify(response),
      );
      const availableTimeSlots = MenuService.getTimesSlotsFromTimes(
        response,
        MenuService.getLocationDayOfWeek(),
        MenuService.getPickupDate(),
      );

      //fetch tomorrow's available timeslots
      const advanceResponse = await PlatformApi.fetchOrderAheadAvailableTimes(
        MenuService.getTomorrowPickupDateTime(),
        location.timezone,
        location.locationId,
        pickupLocationId,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchOrderAheadAvailableTimes',
        JSON.stringify({
          getPickupDateTime: MenuService.getTomorrowPickupDateTime(),
          timezone: location.timezone,
          locationId: location.locationId,
          pickupLocationId,
        }),
        JSON.stringify(response),
      );
      const advanceAvailableTimeSlots = MenuService.getTimesSlotsFromTimes(
        advanceResponse,
        MenuService.getLocationTomorrowDayOfWeek(),
        MenuService.getTomorrowPickupDate(),
      );

      const timeSlots = availableTimeSlots.concat(advanceAvailableTimeSlots);
      TransactionAction.availableTimesUpdated(timeSlots);
      return timeSlots;
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuActions:getAvailableTimeSlots',
        e.message ?? e.toString(),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuActions:getAvailableTimeSlots',
        e.message ?? e.toString(),
        response,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchOrderAheadAvailableTimes',
        JSON.stringify({
          pickupLocationId,
          timezone: location.timezone,
          locationId: location.locationId,
        }),
        JSON.stringify(e),
      );
      return [];
    }
  }

  async getMenuByTimeSlot(
    location: string,
    pickupLocationId: string,
    timeSlot: TimeSlotType,
  ): Promise<MenuType> {
    try {
      const response = await PlatformApi.fetchMenu(
        timeSlot.timeString,
        location,
        pickupLocationId,
        timeSlot.dayOfWeek,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchMenu',
        JSON.stringify({
          time: timeSlot.timeString,
          location,
          pickupLocationId,
          dayOfWeek: timeSlot.dayOfWeek,
        }),
        JSON.stringify(response),
      );

      const menus: MenuType[] = response.map((menu: any) => ({
        ...menu,
        tts: menu.timeToStart,
        ttl: menu.timeToLive,
        service: menu.serviceType,
        menutimelist: [],
        type: 'MENU',
        data: menu.menuItems.map((item: any) => ({
          longname: item.longName,
          parent: item.parent,
          level: item.level,
          id: item.id,
          name: item.name,
          isautoadd: item.autoAdd ? 'Y' : 'N',
          default: item.default,
          products:
            item.menuProducts?.map((p: any) => ({
              smallimageurl: p.smallImageUrl,
              largeimageurl: p.largeImageUrl,
              hasmodifier: p.hasModifier ? 'Y' : 'N',
              tabs: p?.productTabs?.map((t: any) => t.tabId) ?? [],
              id: p.productId,
              guid: p.menuProductId,
              pickupLocations: [],
              printgroups: p.printGroups,
              scancode: p.scancode,
              name: p.name,
              description: p.description,
              price: p.price,
              featuredproduct: p.featuredProduct ? 'Y' : 'N',
              weigh: p.weigh ? 'Y' : 'N',
              um: p.um,
              calories: p.calories,
            })) ?? [],
        })),
      }));

      return menus?.[0];
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuActions:getMenuByTimeSlot',
        e.message ?? e.toString(),
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuActions:getMenuByTimeSlot',
        e.message ?? e.toString(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchMenu-Error',
        JSON.stringify({
          location,
          time: timeSlot.timeString,
          dayOfWeek: timeSlot.dayOfWeek,
          pickupLocationId,
        }),
        JSON.stringify(e),
      );
      return null;
    }
  }

  /**
   * @throws exceptions when api call fails
   */
  async getModifiers(
    locationId: string,
    product: MenuProductType,
  ): Promise<void> {
    let response;
    try {
      response = await PlatformApi.fetchProductModifiers(
        locationId,
        product.id,
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchProductModifiers',
        JSON.stringify({locationId, productId: product.id}),
        JSON.stringify(response),
      );

      const tabs = response.map((tab: any) => {
        return {
          ...tab,
          id: tab.tabId,
          desc: tab.description,
          modifiers: tab.modifier
            ? [
                {
                  ...tab.modifier,
                  id: tab.modifier.modifierId,
                  sdesc: tab.modifier.shortDescription,
                  forced: tab.modifier.forced ? 'Y' : 'N',
                  maxsel: tab.modifier.maxSelections,
                  vals:
                    tab.modifier.modifierValues?.map((value: any) => ({
                      ...value,
                      id: value.modifierValueId,
                      seq: value.sequenceNumber,
                      isdefault: value.default ? 'Y' : 'N',
                      product: value.productId,
                    })) ?? [],
                },
              ]
            : [],
        };
      });

      MenuService.setCurrentTabs(tabs);
    } catch (e) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuActions:getModifiers',
        e.message ?? e.toString(),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuActions:getModifiers',
        e.message ?? e.toString(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchProductModifiers',
        JSON.stringify({locationId, productId: product.id}),
        JSON.stringify(e),
      );
      throw e;
    }
  }

  async getNutrition(
    locationId: string,
    productId: string,
  ): Promise<ProductNutrition> {
    let response;
    try {
      response = await PlatformApi.fetchProductNutrition(locationId, productId);
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchProductNutrition',
        JSON.stringify({locationId, productId}),
        JSON.stringify(response),
      );
      return response;
    } catch (error) {
      CrashlyticsEvents.log(
        'Exception',
        'MenuActions:getNutrition',
        error.message ?? error.toString(),
        response,
      );
      Events.Error.trackEvent(
        'Exception',
        'MenuActions:getNutrition',
        error.message ?? error.toString(),
      );
      Logger.Log.LogAPIEvent(
        'PlatformAPI',
        'FetchProductNutrition',
        JSON.stringify({locationId, productId}),
        JSON.stringify(error),
      );
      return null;
    }
  }
}

export default new MenuActions();
