import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

interface IProps {
  size?: number;
  color?: string;
}

function AccountRevolveIcon({size = 28, color = '#727179'}: IProps) {
  return (
    <Svg width={size} height={size} viewBox="0 0 34 34" fill="none">
      <G clipPath="url(#clip0_8661_10714)">
        <Path
          d="M22.25 10.5a4.875 4.875 0 10-9.75 0 4.875 4.875 0 009.75 0zm-11.375 0a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zm-3.25 17.875h19.5a7.428 7.428 0 00-7.43-7.313h-4.64c-4.063 0-7.364 3.266-7.43 7.313zM6 28.492a9.052 9.052 0 019.054-9.055h4.642a9.052 9.052 0 019.054 9.055c0 .833-.675 1.508-1.508 1.508H7.508A1.508 1.508 0 016 28.492z"
          fill={color}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_8661_10714">
          <Path fill="#fff" transform="translate(6 4)" d="M0 0H22.75V26H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default AccountRevolveIcon;
