import React from 'react';
import {Platform, StyleProp, ViewStyle} from 'react-native';
import Settings from 'src/Settings';
import Styles from 'src/components/Styles';
import Localized from 'src/constants/AppStrings';

export function fundAutoReloadTxtStyleDescriptor(): {
  [index: string]: number | string;
} {
  return {
    color: Styles.darkColor,
    fontSize: Styles.Fonts.f7,
    textDecorationLine: 'underline',
  };
}
export function getFundAccountTabContainerStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      paddingHorizontal: Styles.Spacing.m3,
      backgroundColor: Styles.white,
      height: Settings.isNewUI() ? 450 : 360,
      ...(Platform.OS === 'web' && {
        flex: 1,
      }),
      paddingBottom: Styles.Spacing.m4,
      paddingTop: Settings.isNewUI() ? Styles.Spacing.m2 : Styles.Spacing.m1,
    },
  };
}
export function closeExpressFundingTemplateDescriptor(): JSX.Element {
  return <React.Fragment></React.Fragment>;
}

export function fundSwitchStyleDescriptor(): {
  [index: string]: number | string | Array<{scaleX?: number; scaleY?: number}>;
} {
  return {};
}

export function fundingSwitchTrackColorDescriptor(): string {
  return Styles.blueRevolve;
}

export function getModalTexts(): {
  [index: string]: number | string;
} {
  return {
    fundModalReloadHeader: Localized.Labels.auto_reload,
  };
}

export function getExpressFundingStyleDescriptor(): {
  [index: string]: {[index: string]: number | string};
} {
  return {
    container: {
      ...(Platform.OS === 'web' && {
        flex: 1,
      }),
      backgroundColor: Styles.white,
      height: Settings.isNewUI() ? 560 : 410,
      paddingHorizontal: Styles.Spacing.m3,
      paddingTop: Settings.isNewUI() ? Styles.Spacing.m3 : Styles.Spacing.m1,
    },

    toggleLabel: {
      alignSelf: 'center',
      color: Styles.darkColor,
      fontSize: Styles.Fonts.f7,
      fontFamily: Styles.FontFamily.figtreeRegular,
    },
    topContainer: {
      marginLeft: Styles.Spacing.m0,
    },
    paymentNewContainer: {
      marginHorizontal: Styles.Spacing.m3,
    },
    saveButton: {
      zIndex: 1,
      right: 15,
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 10,
      paddingRight: 10,
    },
    backButton: {
      zIndex: 1,
      paddingTop: 4,
      paddingBottom: 4,
      paddingLeft: 10,
      paddingRight: 10,
    },
    bottomBtnContainer: {
      flex: 1,
      justifyContent: 'space-between',
      flexDirection: 'row',
    },

    headerNewUIText: {
      color: Styles.darkColor,
      fontSize: Styles.Fonts.sectionHeader,
      fontWeight: '700',
      fontFamily: Styles.FontFamily.figtreeRegular,
      textTransform: 'uppercase',
    },
  };
}

export function getAmericanExpressRightIcon(
  _issuer: string,
): StyleProp<ViewStyle> {
  return null;
}
export default {
  fundAutoReloadTxtStyleDescriptor,
  getFundAccountTabContainerStyleDescriptor,
  fundSwitchStyleDescriptor,
  fundingSwitchTrackColorDescriptor,
  getExpressFundingStyleDescriptor,
  getModalTexts,
  getAmericanExpressRightIcon,
};
