import {
  PickupLocationStylesDescriptor,
  PickupTimeStylesDescriptor,
  ProductDetailsStylesDescriptor,
  MenuListStylesDescriptor,
  FlashListPropsType,
  Identifiable,
  ProductComponentProps,
} from 'src/components/screens/orderAhead/descriptor/DescriptorType';
import Styles from 'src/components/Styles';
import {
  StyleSheet,
  View,
  Image,
  Dimensions,
  Platform,
  PixelRatio,
} from 'react-native';
import Localized from 'src/constants/AppStrings';
import {ensureHttps} from 'src/services/StringUtils';
import AVText from 'src/components/elements/AVText';
import SearchProduct from 'src/components/elements/cart/SearchProduct';
import {MenuProductType} from 'src/types/Menu';
import {DNA} from 'src/types/dnaTypes';
import {TransactionDetail} from 'src/types/TransactionDetail';
import {ListScanItemsProps} from 'src/components/screens/scanAndPay/ListScanItems';

const width = Dimensions.get('window').width;

export function getPickupLocationStyles(): PickupLocationStylesDescriptor {
  return StyleSheet.create({
    buttonContainerStyle: {
      position: 'absolute',
      alignSelf: 'center',
      bottom: 32,
    },
    buttonText: {
      fontFamily: Styles.FontFamily.robotoRegular,
      fontSize: Styles.Fonts.f7,
      fontWeight: '400',
    },
    headerText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.darkColor,
      marginBottom: Styles.Spacing.m1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    errorText: {
      fontWeight: '700',
      fontSize: Styles.Fonts.f7,
      color: Styles.dangerColor,
      paddingHorizontal: Styles.Spacing.m1,
      alignItems: 'center',
      marginBottom: 1,
      fontFamily: Styles.FontFamily.robotoRegular,
    },
    errorContainer: {
      flexDirection: 'row',
      paddingVertical: Styles.Heights.h1,
      alignItems: 'center',
    },
  });
}

export function getInactiveButtonColor() {
  return Styles.borderColor;
}

export function fontFamilyStyle() {
  return Styles.FontFamily.robotoRegular;
}

export function textColor() {
  return Styles.darkColor;
}
export function getPickupTimeStyles(): PickupTimeStylesDescriptor {
  return StyleSheet.create({
    dateView: {
      marginTop: Styles.Spacing.m4 - Styles.Spacing.m2,
      marginLeft: Styles.Spacing.m3,
    },
    timeView: {
      marginTop: Styles.Spacing.m4 - Styles.Spacing.m2,
    },
    pickupTimeContainer: {
      flex: 1,
      backgroundColor: Styles.white,
      marginTop: Styles.Heights.h2,
    },
    pickupLocationHeaderView: {
      flexDirection: 'row',
      width: '100%',
      minHeight: Styles.Heights.touchTargetHeight2,
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingRight: Styles.Heights.h1,
      marginLeft: Styles.Spacing.m3,
    },
    customTileButtonTime: {
      minWidth: 105,
    },
    tilesStyle: {
      paddingRight: Styles.Spacing.m3,
      marginTop: Styles.Spacing.m3,
    },
    errorContainer: {
      flexDirection: 'row',
      marginTop: Styles.Spacing.m2,
      alignItems: 'center',
    },
    buttonTextStyle: {
      fontWeight: '400',
      fontSize: Styles.Fonts.f1,
      paddingHorizontal: Styles.Spacing.m1,
      marginBottom: 1,
      fontFamily: Styles.FontFamily.robotoRegular,
      color: Styles.primaryColor,
    },
    editButton: {
      flexDirection: 'row',
      alignItems: 'center',
      borderColor: Styles.primaryColor,
      borderRadius: 32,
      borderWidth: 1,
      paddingVertical: 5,
      paddingHorizontal: 8,
      marginRight: Styles.Spacing.m3,
    },
  });
}

export function getActiveButtonColor() {
  return Styles.primaryColor;
}
export function locationErrorMessage() {
  return Localized.Errors.please_select_pickup_location;
}
export function dayErrorMessage() {
  return Localized.Errors.error_please_select_a_day;
}
export function timeErrorMessage() {
  return Localized.Errors.error_please_select_a_time;
}

export function productDetailsTitle() {
  return Localized.Labels.item_info;
}

export function productDetailsImageView(imageUri: string): JSX.Element {
  return (
    <>
      {imageUri && (
        <View style={{width: width}}>
          <Image
            accessible={true}
            accessibilityLabel="Product image"
            accessibilityRole="image"
            aria-label="Product image"
            role="img"
            style={{aspectRatio: 1.3}}
            source={{uri: ensureHttps(imageUri)}}
            resizeMode={'cover'}
          />
        </View>
      )}
    </>
  );
}

export function productDetailsStyles(): ProductDetailsStylesDescriptor {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: Styles.white,
      marginHorizontal: 0,
      marginVertical: 0,
      paddingHorizontal: Styles.Spacing.m2,
    },
    content: {
      marginVertical: Styles.Spacing.m1,
      paddingVertical: Styles.Spacing.m2,
    },
    title: {
      fontWeight: '600',
      fontSize: Styles.Fonts.f3,
      color: Styles.darkColor,
      marginBottom: Styles.Spacing.m2,
    },
    infoContainer: {
      paddingHorizontal: Styles.Spacing.m3,
      paddingVertical: 10,
      flexDirection: 'column',
    },
    productPrice: {
      fontWeight: '600',
      marginTop: 1,
      fontSize: Styles.Fonts.f2,
      color: Styles.darkColor,
    },
    calories: {
      fontWeight: '600',
      fontSize: Styles.Fonts.f2,
      color: Styles.darkColor,
    },
    calorieText: {
      fontSize: 11,
      fontWeight: '400',
      color: Styles.darkColor,
      marginLeft: 5,
      marginTop: 7.5,
    },
    healthGrade: {
      marginLeft: 10,
      paddingTop: 5,
    },
    verticalLine: {
      marginHorizontal: 8,
      backgroundColor: Styles.lightGray,
      borderLeftWidth: 1,
    },
    nutritionButtonView: {
      flexDirection: 'row',
      marginVertical: Styles.Spacing.m2,
    },
    outlined: {
      width: Platform.OS !== 'web' ? width / 2 : width / 5,
      flexDirection: 'row',
      justifyContent: 'center',
      borderRadius: 60,
      borderColor: Styles.primaryColor,
      marginTop: Styles.Spacing.m2,
      padding: Styles.Spacing.m2,
      borderWidth: 2,
    },
    outlinedButtonText: {
      color: Styles.primaryColor,
      fontSize: Styles.Fonts.f1,
      fontWeight: '700',
    },
    descriptionContainer: {
      marginHorizontal: 17.5,
    },
    description: {
      fontSize: Styles.Fonts.f1,
      color: Styles.lightGray,
      marginTop: Styles.Spacing.m2,
    },
    customizationSection: {
      marginHorizontal: 17.5,
      marginTop: 15,
      flex: 1,
      flexDirection: 'column',
    },
    quantityContainer: {
      alignItems: 'center',
    },
    quantitySelectionView: {
      padding: 0,
    },
    dashStyle: {
      borderRadius: 100,
      height: PixelRatio.roundToNearestPixel(2),
      marginVertical: Styles.Spacing.m3,
      overflow: 'hidden',
      width: '100%',
    },
    quantityLabel: {
      fontSize: Styles.Fonts.f1,
      color: Styles.darkColor,
      marginBottom: Styles.Spacing.m1 * -1,
    },
  });
}

export function roundedButtonColor() {
  return Styles.primaryColor;
}
export function dashLineColor() {
  return '#999';
}

export function menuListStyles(): MenuListStylesDescriptor {
  return StyleSheet.create({
    content: {
      flex: 1,
      backgroundColor: Styles.white,
    },
    searchContainer: {
      marginHorizontal: Styles.Spacing.m2,
    },
    categories: {
      marginVertical: Styles.Spacing.m2,
    },
  });
}
export function getFlashListProps<T extends Identifiable>({
  data,
  renderItem,
}: FlashListPropsType<T>) {
  return {
    data,
    keyExtractor: (item: T) => item.id,
    renderItem,
    ListEmptyComponent: (
      <View style={{justifyContent: 'center', alignItems: 'center'}}>
        <AVText style={{fontSize: Styles.Fonts.f2}}>
          {Localized.Labels.no_products_available}
        </AVText>
      </View>
    ),
    estimatedItemSize: 72,
  };
}

export function getProductComponent(props: ProductComponentProps) {
  return (
    <SearchProduct
      dnaConfiguration={props?.dnaConfiguration}
      onPress={props?.onPress}
      name={props?.name}
      item={props?.item}
      price={props?.price}
      imageUrl={props?.imageUrl}
      isProductCat={props?.isProductCat}
    />
  );
}

export function getTransactionUpsellName(_name: string) {
  return null;
}

export function getTransactionUpsellProductsList(
  _products: MenuProductType[],
  _addItem: (item: MenuProductType) => void,
  _dna?: DNA,
  _props?: ListScanItemsProps,
) {
  return null;
}

export function renderTenderDiscount(
  _transaction: TransactionDetail | null,
  _props: ListScanItemsProps,
) {
  return null;
}

export default {
  getPickupLocationStyles,
  getInactiveButtonColor,
  textColor,
  fontFamilyStyle,
  getPickupTimeStyles,
  getActiveButtonColor,
  locationErrorMessage,
  dayErrorMessage,
  timeErrorMessage,
  productDetailsTitle,
  productDetailsImageView,
  productDetailsStyles,
  roundedButtonColor,
  dashLineColor,
  menuListStyles,
  getFlashListProps,
  getProductComponent,
  getTransactionUpsellName,
  getTransactionUpsellProductsList,
  renderTenderDiscount,
};
